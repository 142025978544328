import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';
import { IStandingOrderCancellation } from '../../models';

function getStandingOrderCancellation(
  StandingOrderCancellationId: string
): Promise<IStandingOrderCancellation> {
  return externalApi.get(`StandingOrderCancellation/${StandingOrderCancellationId}`).json();
}

function useStandingOrderCancellation() {
  const queryClient = useQueryClient();
  return useMutation(
    (StandingOrderCancellationId: string) =>
      getStandingOrderCancellation(StandingOrderCancellationId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['StandingOrderCancellationsByStandingOrder']);
      },
    }
  );
}

export { useStandingOrderCancellation };
