import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type NewStandingOrderRequest = {
  patientId: string;
  isAMDelivery: boolean;
  weekdayId: string;
  liquid: boolean;
  notes: string;
  lastUpdatedUserId: string;
};

type NewStandingOrderResponse = {
  standingOrderId: number;
};

function newStandingOrder(request: NewStandingOrderRequest): Promise<NewStandingOrderResponse> {
  return externalApi.post(`StandingOrder/new`, { json: request }).json();
}

function useNewStandingOrder() {
  const queryClient = useQueryClient();
  return useMutation((request: NewStandingOrderRequest) => newStandingOrder(request), {
    onSuccess: () => {
      queryClient.invalidateQueries(['StandingOrdersByWeekday']);
    },
  });
}
export { useNewStandingOrder };
