import React from 'react';
import { useQuery } from 'react-query';
import externalApi from '../../api';
import { IUser } from '../../models';

function fetchAdmins(): Promise<IUser[]> {
  return externalApi.get(`User/admins`).json();
}

export function useAdmins() {
  return useQuery(['Admins'], () => fetchAdmins());
}
