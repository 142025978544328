import { Box, Text } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import {
  EquipmentCreateCard,
  EquipmentEditQuantityCard,
  EquipmentDetailCard,
  EquipmentEditSerialNumberCard,
  DialogModal,
  TrackedEquipmentNotesModal,
} from '..';
import {
  useDeletePatientEquipment,
  useDeleteTrackedPatientEquipment,
  useEditPatientEquipmentQuantity,
  useEditSerialNumber,
  useNewPatientEquipment,
  useNewPatientEquipmentWithSerialNumber,
  useUserId,
} from '../../hooks';

type PatientEquipmentCardProps = {
  patientEquipmentId: string;
  patientId: string;
  equipmentId?: string;
  equipmentName: string;
  equipmentTypeName: string;
  quantity: number;
  serialNumber?: string;
  deliveredDate?: Date;
  hasSerialNumber?: boolean;
  lastUpdatedUserId?: string;
  lastUpdatedDateTime?: Date;
  isEditQuantity?: boolean;
  isEditSerialNumber?: boolean;
  isCreate?: boolean;
  isTracked?: boolean;
  hasNotes?: boolean;
  onEditSerialNumber?: (isEdited: boolean, isError: boolean) => void;
  onEditQuantity?: (isEdited: boolean, isError: boolean) => void;
  onViewNotes?: (trackedPatientEquipmentId: string) => void;
  onClear?: (isCleared: boolean, isError: boolean) => void;
  onReturn?: (trackedPatientEquipmentId: string) => void;
  onDelete?: (isDeleted: boolean, isError: boolean) => void;
  onCreate?: (isCreated: boolean, isError: boolean) => void;
  onCreateCancel?: () => void;
  onOpen?: () => void;
};

const PatientEquipmentCard = (props: PatientEquipmentCardProps) => {
  const currentUserId = useUserId();

  const [detailCardOpen, setDetailCardOpen] = useState<boolean>(
    props.isCreate ? false : props.isEditQuantity ? false : true
  );
  const [createCardOpen, setCreateCardOpen] = useState<boolean>(props.isCreate ?? false);
  const [editQuantityCardOpen, setEditQuantityCardOpen] = useState<boolean>(
    props.isEditQuantity ?? false
  );
  const [editSerialNumberCardOpen, setEditSerialNumberCardOpen] = useState<boolean>(
    props.isEditSerialNumber ?? false
  );
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const editPatientEquipmentQuantity = useEditPatientEquipmentQuantity();
  const editPatientEquipmentSerialNumber = useEditSerialNumber();
  const newPatientEquipment = useNewPatientEquipment();
  const newPatientEquipmentWithSerialNumber = useNewPatientEquipmentWithSerialNumber();
  const deletePatientEquipment = useDeletePatientEquipment();
  const deleteTrackedPatientEquipment = useDeleteTrackedPatientEquipment();

  const handleQuantityUpdate = (newQuantity: number) => {
    editPatientEquipmentQuantity.mutate({
      patientEquipmentId: props.patientEquipmentId,
      quantity: newQuantity,
      lastUpdatedUserId: currentUserId.data!.userId,
    });
  };

  const handleSerialNumberUpdate = (newSerialNumber: string) => {
    if (newSerialNumber.length > 0) {
      editPatientEquipmentSerialNumber.mutate({
        patientEquipmentId: props.patientEquipmentId,
        serialNumberValue: newSerialNumber,
        lastUpdatedUserId: currentUserId.data!.userId,
      });
    } else {
      if (props.onEditSerialNumber) {
        props.onEditSerialNumber(false, true);
      }
    }
  };

  const handleCreate = (newQuantity: number) => {
    if (props.equipmentId) {
      newPatientEquipment.mutate({
        patientId: props.patientId,
        equipmentId: props.equipmentId,
        quantity: newQuantity,
        lastUpdatedUserId: currentUserId.data!.userId,
      });
    }
  };

  const handleCreateWithSerialNumber = (newSerialNumber: string) => {
    if (props.equipmentId) {
      newPatientEquipmentWithSerialNumber.mutate({
        patientId: props.patientId,
        equipmentId: props.equipmentId,
        serialNumber: newSerialNumber,
        lastUpdatedUserId: currentUserId.data!.userId,
      });
    }
  };

  const handleDelete = () => {
    if (props.isTracked) {
      deleteTrackedPatientEquipment.mutate({ trackedPatientEquipmentId: props.patientEquipmentId });
    } else {
      deletePatientEquipment.mutate({ patientEquipmentId: props.patientEquipmentId });
    }
  };

  useEffect(() => {
    if (props.onOpen) {
      props.onOpen();
    }
  }, [detailCardOpen, editQuantityCardOpen, createCardOpen]);

  useEffect(() => {
    if (props.onEditQuantity) {
      if (editPatientEquipmentQuantity.isSuccess) {
        props.onEditQuantity(true, false);
      }
      if (editPatientEquipmentQuantity.isError) {
        props.onEditQuantity(false, true);
      }
    }
  }, [editPatientEquipmentQuantity.status]);

  useEffect(() => {
    if (props.onEditSerialNumber) {
      if (editPatientEquipmentSerialNumber.isSuccess) {
        props.onEditSerialNumber(true, false);
      }
      if (editPatientEquipmentSerialNumber.isError) {
        props.onEditSerialNumber(false, true);
      }
    }
  }, [editPatientEquipmentSerialNumber.status]);

  useEffect(() => {
    if (props.onCreate) {
      if (newPatientEquipment.isSuccess) {
        props.onCreate(true, false);
      }
      if (newPatientEquipment.isError) {
        props.onCreate(false, true);
      }
    }
  }, [newPatientEquipment.status]);

  useEffect(() => {
    if (props.onCreate) {
      if (newPatientEquipmentWithSerialNumber.isSuccess) {
        props.onCreate(true, false);
      }
      if (newPatientEquipmentWithSerialNumber.isError) {
        props.onCreate(false, true);
      }
    }
  }, [newPatientEquipmentWithSerialNumber.status]);

  useEffect(() => {
    if (props.onDelete) {
      if (deletePatientEquipment.isSuccess) {
        props.onDelete(true, false);
      }
      if (deletePatientEquipment.isError) {
        props.onDelete(false, true);
      }
    }
  }, [deletePatientEquipment.status]);

  useEffect(() => {
    if (props.onDelete) {
      if (deleteTrackedPatientEquipment.isSuccess) {
        props.onDelete(true, false);
      }
      if (deleteTrackedPatientEquipment.isError) {
        props.onDelete(false, true);
      }
    }
  }, [deleteTrackedPatientEquipment.status]);

  return (
    <Box mr="sm">
      {detailCardOpen && (
        <EquipmentDetailCard
          equipmentName={props.equipmentName}
          equipmentTypeName={props.equipmentTypeName}
          quantity={props.quantity}
          serialNumber={props.serialNumber}
          showSerialNumber
          deliveredDate={props.deliveredDate}
          lastUpdatedUserId={props.lastUpdatedUserId}
          lastUpdatedDateTime={props.lastUpdatedDateTime}
          showInfoButton
          isTracked={props.isTracked}
          onEditQuantity={() => {
            if (!props.isTracked) {
              setDetailCardOpen(false);
              setEditQuantityCardOpen(true);
            }
          }}
          onEditSerialNumber={() => {
            if (!props.isTracked) {
              setDetailCardOpen(false);
              setEditSerialNumberCardOpen(true);
            }
          }}
          onViewNotes={() => {
            if (props.isTracked && props.onViewNotes) {
              props.onViewNotes(props.patientEquipmentId);
            }
          }}
          onClear={() => {
            if (props.onReturn) {
              props.onReturn(props.patientEquipmentId);
            }
          }}
          onDelete={() => {
            setDeleteModalOpen(true);
          }}
        />
      )}
      {editQuantityCardOpen && (
        <EquipmentEditQuantityCard
          equipmentName={props.equipmentName}
          equipmentTypeName={props.equipmentTypeName}
          quantity={props.quantity}
          onClose={() => {
            setEditQuantityCardOpen(false);
            setDetailCardOpen(true);
          }}
          onSave={(newQuantity) => {
            handleQuantityUpdate(newQuantity);
            setEditQuantityCardOpen(false);
            setDetailCardOpen(true);
          }}
        />
      )}
      {editSerialNumberCardOpen && (
        <EquipmentEditSerialNumberCard
          equipmentName={props.equipmentName}
          equipmentTypeName={props.equipmentTypeName}
          serialNumber={props.serialNumber ?? ''}
          onClose={() => {
            setEditSerialNumberCardOpen(false);
            setDetailCardOpen(true);
          }}
          onSave={(newSerialNumber) => {
            handleSerialNumberUpdate(newSerialNumber);
            setEditSerialNumberCardOpen(false);
            setDetailCardOpen(true);
          }}
        />
      )}
      {createCardOpen && !props.hasSerialNumber && (
        <EquipmentCreateCard
          equipmentName={props.equipmentName}
          equipmentTypeName={props.equipmentTypeName}
          quantity={props.quantity}
          onClose={() => {
            if (props.onCreateCancel) {
              props.onCreateCancel();
            }
          }}
          onSave={(newQuantity) => {
            handleCreate(newQuantity);
          }}
        />
      )}
      {createCardOpen && props.hasSerialNumber && (
        <EquipmentCreateCard
          equipmentName={props.equipmentName}
          equipmentTypeName={props.equipmentTypeName}
          quantity={1}
          hasSerialNumber={props.hasSerialNumber}
          onClose={() => {
            if (props.onCreateCancel) {
              props.onCreateCancel();
            }
          }}
          onSave={(newQuantity, newSerialNumber) => {
            handleCreateWithSerialNumber(newSerialNumber!);
          }}
        />
      )}
      <DialogModal
        isOpen={deleteModalOpen}
        isNegative
        titleText="Delete Equipment?"
        body={
          <Text align="center" color="dimmed" size="lg" mb="xs">
            Are you sure you want to delete this Equipment from this Patient?
          </Text>
        }
        confirmText="Delete Equipment"
        onConfirm={() => {
          setDeleteModalOpen(false);
          handleDelete();
        }}
        onClose={() => {
          setDeleteModalOpen(false);
        }}
      />
    </Box>
  );
};

export default PatientEquipmentCard;
