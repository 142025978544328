import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type EditTrackedEquipmentServiceRequest = {
  trackedEquipmentServiceId: string;
  percentOxygen: number;
  flowRate: number;
  lastUpdatedUserId: string;
};

type EditTrackedEquipmentServiceResponse = {
  trackedEquipmentServiceId: number;
};

function editTrackedEquipmentService(
  request: EditTrackedEquipmentServiceRequest
): Promise<EditTrackedEquipmentServiceResponse> {
  return externalApi.patch(`TrackedEquipmentService/patch/metrics`, { json: request }).json();
}

function useEditTrackedEquipmentServiceMetrics() {
  const queryClient = useQueryClient();
  return useMutation(
    (request: EditTrackedEquipmentServiceRequest) => editTrackedEquipmentService(request),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['AllTrackedEquipmentService']);
      },
    }
  );
}
export { useEditTrackedEquipmentServiceMetrics };
