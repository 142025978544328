import React from 'react';
import { useQuery } from 'react-query';
import externalApi from '../../api';
import { IEquipmentType } from '../../models';

function fetchEquipmentTypes(): Promise<IEquipmentType[]> {
  return externalApi.get(`EquipmentType/all`).json();
}

export function useEquipmentTypes() {
  return useQuery(['EquipmentTypes'], () => fetchEquipmentTypes());
}
