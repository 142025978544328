import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type NewStandingOrderCancellationRequest = {
  standingOrderId: string;
  startDate: Date;
  endDate: Date;
  lastUpdatedUserId: string;
};

type NewStandingOrderCancellationResponse = {
  standingOrderCancellationId: number;
};

function newStandingOrderCancellation(
  request: NewStandingOrderCancellationRequest
): Promise<NewStandingOrderCancellationResponse> {
  return externalApi.post(`StandingOrderCancellation/new`, { json: request }).json();
}

function useNewStandingOrderCancellation() {
  const queryClient = useQueryClient();
  return useMutation(
    (request: NewStandingOrderCancellationRequest) => newStandingOrderCancellation(request),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'StandingOrderCancellationsByStandingOrder',
          'StandingOrderCancelledCount',
        ]);
      },
    }
  );
}
export { useNewStandingOrderCancellation };
