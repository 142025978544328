import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type EditTrackedEquipmentRequest = {
  trackedEquipmentId: string;
  onHours: number;
  offHours: number;
  lastUpdatedUserId: string;
};

type EditTrackedEquipmentResponse = {
  trackedEquipmentId: number;
};

function editTrackedEquipment(
  request: EditTrackedEquipmentRequest
): Promise<EditTrackedEquipmentResponse> {
  return externalApi.patch(`TrackedEquipment/patch/hours`, { json: request }).json();
}

function useEditTrackedEquipmentHours() {
  const queryClient = useQueryClient();
  return useMutation((request: EditTrackedEquipmentRequest) => editTrackedEquipment(request), {
    onSuccess: () => {
      queryClient.invalidateQueries(['AllTrackedEquipment']);
    },
  });
}
export { useEditTrackedEquipmentHours };
