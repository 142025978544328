import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type EditTrackedEquipmentRepairRequest = {
  trackedEquipmentRepairId: string;
  notes: string;
  lastUpdatedUserId: string;
};

type EditTrackedEquipmentRepairResponse = {
  trackedEquipmentRepairId: number;
};

function editTrackedEquipmentRepair(
  request: EditTrackedEquipmentRepairRequest
): Promise<EditTrackedEquipmentRepairResponse> {
  return externalApi.patch(`TrackedEquipmentRepair/patch/notes`, { json: request }).json();
}

function useEditTrackedEquipmentRepairNotes() {
  const queryClient = useQueryClient();
  return useMutation(
    (request: EditTrackedEquipmentRepairRequest) => editTrackedEquipmentRepair(request),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['AllTrackedEquipmentRepair']);
      },
    }
  );
}
export { useEditTrackedEquipmentRepairNotes };
