import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type DeleteTrackedEquipmentServiceRequest = {
  trackedEquipmentServiceId: string;
};

type DeleteTrackedEquipmentServiceResponse = {
  trackedEquipmentServiceId: number;
};

function deleteTrackedEquipmentService(
  request: DeleteTrackedEquipmentServiceRequest
): Promise<DeleteTrackedEquipmentServiceResponse> {
  return externalApi
    .delete(`TrackedEquipmentService/delete/${request.trackedEquipmentServiceId}`)
    .json();
}

function useDeleteTrackedEquipmentService() {
  const queryClient = useQueryClient();
  return useMutation(
    (request: DeleteTrackedEquipmentServiceRequest) => deleteTrackedEquipmentService(request),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('TrackedEquipmentServiceByTrackedEquipment');
        queryClient.invalidateQueries('AllTrackedEquipment');
      },
    }
  );
}
export { useDeleteTrackedEquipmentService };
