import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type EditDeliveryNotesRequest = {
  deliveryId: string;
  notes: string;
};

type EditDeliveryNotesResponse = {
  deliveryId: number;
};

function editDeliveryNotes(request: EditDeliveryNotesRequest): Promise<EditDeliveryNotesResponse> {
  return externalApi.patch(`Delivery/patch/notes`, { json: request }).json();
}

function useEditDeliveryNotes() {
  const queryClient = useQueryClient();
  return useMutation((request: EditDeliveryNotesRequest) => editDeliveryNotes(request), {
    onSuccess: () => {},
  });
}
export { useEditDeliveryNotes };
