import {
  ActionIcon,
  Box,
  createStyles,
  Divider,
  Group,
  Select,
  Stack,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React, { useEffect, useState } from 'react';
import { Edit, DeviceFloppy, X, Check } from 'tabler-icons-react';
import { IEquipment, ITrackedEquipment } from '../../../models';
import dayjs from 'dayjs';
import { useEditTrackedEquipmentDetails, useEquipmentForTracking, useUserId } from '../../../hooks';
import { DatePickerInput } from '@mantine/dates';

const useStyles = createStyles((theme) => ({
  notesCard: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    paddingLeft: theme.spacing.sm,
    paddingRight: theme.spacing.sm,
    paddingTop: 3,
    width: '100%',
    height: '125px',
  },
  infoBox: {
    color: `${theme.colors.gray[6]}`,
    border: `1px solid ${theme.colors.gray[6]}`,
    borderRadius: theme.radius.lg,
    width: 'fit-content',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  groupLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.sm,
    whiteSpace: 'pre',
  },
  editGroupLabel: {
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,
    whiteSpace: 'pre',
  },
  fieldLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
  },
  editFieldLabel: {
    fontSize: theme.fontSizes.xs,
    fontWeight: 500,
    whiteSpace: 'pre',
  },
  detail: {
    fontSize: theme.fontSizes.md,
    paddingLeft: 10,
  },
  fieldGroup: {
    width: '48%',
  },
  clickableIcon: {
    color: `${theme.colors.blue[6]}`,
    padding: '0 !important',
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

type TrackedEquipmentDetailSectionProps = {
  trackedEquipment?: ITrackedEquipment;
  readOnly?: boolean;
  onEdited?: () => void;
  onError?: () => void;
};

const TrackedEquipmentDetailSection = (props: TrackedEquipmentDetailSectionProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const currentUserId = useUserId();

  const equipment = useEquipmentForTracking();
  const editTrackedEquipmentDetails = useEditTrackedEquipmentDetails();

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [invalidEquipment, setInvalidEquipment] = useState<boolean>(false);
  const [invalidSerialNumber, setInvalidSerialNumber] = useState<boolean>(false);

  const [underWarranty, setUnderWarranty] = useState<boolean>(
    props.trackedEquipment?.underWarranty ?? false
  );

  // display values
  const [selectedEquipment, setSelectedEquipment] = useState<IEquipment>();
  const [serialNumber, setSerialNumber] = useState<string>(
    props.trackedEquipment?.serialNumber ?? ''
  );
  const [warrantyExpiryDate, setWarrantyExpiryDate] = useState<Date | undefined>(
    props.trackedEquipment?.warrantyExpiryDate
  );

  // controlled editing values
  const [editSelectedEquipment, setEditSelectedEquipment] = useState<IEquipment>();
  const [editSerialNumber, setEditSerialNumber] = useState<string>(
    props.trackedEquipment?.serialNumber ?? ''
  );
  const [editWarrantyExpiryDate, setEditWarrantyExpiryDate] = useState<Date | undefined>(
    props.trackedEquipment?.warrantyExpiryDate
  );

  useEffect(() => {
    if (equipment.isSuccess) {
      setSelectedEquipment(
        equipment.data?.find(
          (a) => a.equipmentId.toString() === props.trackedEquipment?.equipmentId.toString()
        )
      );
    }
  }, [props.trackedEquipment, equipment.status]);

  function SaveChanges() {
    if (props.onEdited) {
      let valid: boolean = true;

      if (!editSelectedEquipment) {
        valid = false;
        setInvalidEquipment(true);
      }
      if (editSerialNumber.length > 50) {
        valid = false;
        setInvalidSerialNumber(true);
      }

      if (valid) {
        editTrackedEquipmentDetails.mutate({
          trackedEquipmentId: props.trackedEquipment!.trackedEquipmentId,
          equipmentId: editSelectedEquipment?.equipmentId ?? props.trackedEquipment!.equipmentId,
          serialNumber: editSerialNumber,
          warrantyExpiryDate: editWarrantyExpiryDate,
          lastUpdatedUserId: currentUserId.data!.userId,
        });
      }
    }
  }

  useEffect(() => {
    if (editTrackedEquipmentDetails.isSuccess) {
      setSelectedEquipment(editSelectedEquipment);
      setSerialNumber(editSerialNumber);
      setWarrantyExpiryDate(editWarrantyExpiryDate);

      setIsEditing(false);

      if (props.onEdited) {
        props.onEdited();
      }
    }
    if (editTrackedEquipmentDetails.isError) {
      if (props.onError) {
        props.onError();
      }
    }
  }, [editTrackedEquipmentDetails.status]);

  useEffect(() => {
    if (!isEditing) {
      setInvalidEquipment(false);
      setInvalidSerialNumber(false);
    }
  }, [isEditing]);

  useEffect(() => {
    if (warrantyExpiryDate) {
      let newWarrantyExpiryDate = new Date(warrantyExpiryDate);
      setUnderWarranty(
        newWarrantyExpiryDate.getFullYear() >= new Date().getFullYear() &&
          newWarrantyExpiryDate.getMonth() >= new Date().getMonth() &&
          newWarrantyExpiryDate.getDate() >= new Date().getDate()
      );
    }
  }, [warrantyExpiryDate]);

  return (
    <Box>
      {!isEditing && (
        <Stack spacing={0}>
          <Group spacing={0} position="apart" noWrap>
            <Text className={classes.groupLabel}>Equipment Details</Text>
            {!props.readOnly && !props.trackedEquipment?.retired && (
              <ActionIcon
                variant="transparent"
                className={classes.clickableIcon}
                onClick={() => {
                  setEditSelectedEquipment(
                    equipment.data?.find(
                      (a) => a.equipmentId.toString() === selectedEquipment?.equipmentId.toString()
                    )
                  );
                  setEditSerialNumber(serialNumber);
                  setEditWarrantyExpiryDate(warrantyExpiryDate);
                  setIsEditing(true);
                }}
              >
                <Edit style={{ marginBottom: 4 }} />
              </ActionIcon>
            )}
          </Group>
          <Divider mb={`calc(${theme.spacing.xs} / 2)`} />
          <Group spacing={theme.spacing.sm} mb={theme.spacing.xs} noWrap>
            <Stack spacing={0} style={{ width: '50%' }}>
              <Text className={classes.fieldLabel}>Equipment Name</Text>
              <Text className={classes.detail}>{`${selectedEquipment?.name}`}</Text>
            </Stack>
            <Stack spacing={0}>
              <Text className={classes.fieldLabel}>Equipment Category</Text>
              <Text className={classes.detail}>{`${selectedEquipment?.equipmentTypeName}`}</Text>
            </Stack>
          </Group>
          <Group spacing={theme.spacing.sm} noWrap>
            <Stack spacing={0} style={{ width: '50%' }}>
              <Text className={classes.fieldLabel}>Serial Number</Text>
              <Text className={classes.detail}>{`${serialNumber}`}</Text>
            </Stack>
            <Stack spacing={0}>
              <Text className={classes.fieldLabel}>
                {underWarranty ? 'Warranty Expires' : 'Warranty Expired'}
              </Text>
              <Group spacing={0}>
                <Text className={classes.detail}>
                  {warrantyExpiryDate ? dayjs(warrantyExpiryDate).format('YYYY-MM-DD') : '-'}
                </Text>
                {underWarranty && (
                  <Check
                    size={theme.fontSizes.sm}
                    strokeWidth={3}
                    color={theme.colors.blue[6]}
                    style={{ marginLeft: 3 }}
                  />
                )}
                {!underWarranty && (
                  <X
                    size={theme.fontSizes.sm}
                    strokeWidth={3}
                    color={theme.colors.red[6]}
                    style={{ marginLeft: 3 }}
                  />
                )}
              </Group>
            </Stack>
          </Group>
        </Stack>
      )}
      {isEditing && (
        <Stack spacing={0}>
          <Group spacing={0} position="apart" noWrap>
            <Text className={classes.editGroupLabel}>Equipment Details</Text>
            <Group spacing={0}>
              <ActionIcon
                variant="transparent"
                className={classes.clickableIcon}
                onClick={() => {
                  SaveChanges();
                }}
              >
                <DeviceFloppy style={{ marginBottom: 4 }} />
              </ActionIcon>
              <ActionIcon
                variant="transparent"
                className={classes.clickableIcon}
                onClick={() => {
                  setIsEditing(false);
                }}
              >
                <X style={{ marginBottom: 4 }} />
              </ActionIcon>
            </Group>
          </Group>
          <Divider mb={`calc(${theme.spacing.xs} / 2)`} />
          <Stack spacing={0} mb={theme.spacing.xs}>
            <Text className={classes.editFieldLabel}>Equipment Name & Category</Text>
            <Select
              data={
                equipment.data
                  ?.sort((a, b) => parseInt(a.equipmentTypeId) - parseInt(b.equipmentTypeId))
                  .map((a) => ({
                    value: `${a.equipmentId}`,
                    label: `${a.name} - ${a.equipmentTypeName}`,
                  })) ?? []
              }
              placeholder="Select Equipment"
              searchable={!isSmallMobile}
              value={editSelectedEquipment?.equipmentId.toString()}
              onChange={(e) => {
                setEditSelectedEquipment(
                  equipment.data?.find((a) => a.equipmentId.toString() === e?.toString()) ??
                    undefined
                );
              }}
              error={invalidEquipment ? 'You must select a type of Equipment' : undefined}
            />
          </Stack>
          <Group spacing={theme.spacing.sm} mb={theme.spacing.xs} align="top" grow noWrap>
            <Stack spacing={0}>
              <Text className={classes.editFieldLabel}>Serial Number</Text>
              <TextInput
                value={editSerialNumber}
                onChange={(a) => setEditSerialNumber(a.target.value)}
                error={
                  invalidSerialNumber
                    ? 'Serial Number must be a maximum of 50 characters'
                    : undefined
                }
              />
            </Stack>
            <Stack spacing={0} align="stretch">
              <Text className={classes.editFieldLabel}>Warranty Expiry Date</Text>
              <DatePickerInput
                defaultValue={editWarrantyExpiryDate ? new Date(editWarrantyExpiryDate) : undefined}
                dropdownType="modal"
                valueFormat="YYYY-MM-DD"
                firstDayOfWeek={0}
                clearable
                modalProps={{
                  title: 'Warranty Expiry Date',
                  withCloseButton: true,
                  closeButtonProps: { style: { position: 'fixed', right: 10 } },
                  yOffset: '15vh',
                  styles: { title: { fontWeight: 500, textAlign: 'center', width: '100%' } },
                }}
                onChange={(a) => {
                  setEditWarrantyExpiryDate(a ?? undefined);
                }}
              />
            </Stack>
          </Group>
        </Stack>
      )}
    </Box>
  );
};

export default TrackedEquipmentDetailSection;
