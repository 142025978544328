import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type ReturnTrackedPatientEquipmentRequest = {
  trackedPatientEquipmentId: string;
  returnedDateTime: Date;
  notes?: string;
  lastUpdatedUserId: string;
};

type ReturnTrackedPatientEquipmentResponse = {
  trackedPatientEquipmentId: number;
};

function returnTrackedPatientEquipment(
  request: ReturnTrackedPatientEquipmentRequest
): Promise<ReturnTrackedPatientEquipmentResponse> {
  return externalApi.patch(`TrackedPatientEquipment/return`, { json: request }).json();
}

function useReturnTrackedPatientEquipment() {
  const queryClient = useQueryClient();
  return useMutation(
    (request: ReturnTrackedPatientEquipmentRequest) => returnTrackedPatientEquipment(request),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'TrackedPatientEquipmentByPatient',
          'TrackedPatientEquipmentByTrackedEquipment',
        ]);
      },
    }
  );
}
export { useReturnTrackedPatientEquipment };
