import {
  Box,
  Button,
  Center,
  Checkbox,
  createStyles,
  Group,
  Modal,
  ScrollArea,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { Edit } from 'tabler-icons-react';
import { IEquipment } from '../../../models';
import { InfoButton } from '../../../components';

const useStyles = createStyles((theme) => ({
  notesCard: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    paddingLeft: theme.spacing.sm,
    paddingRight: theme.spacing.sm,
    paddingTop: 3,
    width: '100%',
    height: '125px',
  },
  standingEquipmentBox: {
    color: `${theme.colors.gray[6]}`,
    bequipment: `1px solid ${theme.colors.gray[6]}`,
    borderRadius: theme.radius.lg,
    width: '60%',
  },
  label: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
  },
  detail: {
    fontSize: theme.fontSizes.md,
    paddingLeft: 10,
    wordWrap: 'break-word',
  },
  fieldGroup: {
    width: '48%',
  },
  clickableIcon: {
    color: `${theme.colors.blue[6]}`,
    padding: '0 !important',
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

type EquipmentDetailModalProps = {
  equipment: IEquipment;
  opened: boolean;
  isCreated: boolean;
  isUpdated: boolean;
  isDeleted: boolean;
  isError: boolean;
  onClose: () => void;
  onEdit: () => void;
};

const EquipmentDetailModal = (props: EquipmentDetailModalProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  return (
    <Modal
      title={
        props.isUpdated ? (
          <Text color="green">Equipment Successfully Updated!</Text>
        ) : props.isError ? (
          <Text color="red">Something went wrong, Equipment not Updated</Text>
        ) : props.isCreated ? (
          <Text color="green">Equipment Successfully Created!</Text>
        ) : props.isDeleted ? (
          <Text color="green">This Equipment has been Successfully Deleted!</Text>
        ) : (
          ''
        )
      }
      opened={props.opened}
      size={isSmallMobile ? 'xs' : 'md'}
      onClose={() => {
        props.onClose();
      }}
    >
      <Box>
        <Center>
          <Text size="xl" weight={500} align="center">
            Complete Equipment Information
          </Text>
          <InfoButton
            userId={props.equipment.lastUpdatedUserId}
            date={props.equipment.lastUpdatedDateTime}
            ml={5}
          />
        </Center>

        <Stack align="stretch" style={{ padding: 5 }}>
          <Group noWrap spacing={0} align="top">
            <Stack spacing={0} style={{ width: '50%' }}>
              <Text className={classes.label}>Equipment Name</Text>
              <Text className={classes.detail}>{`${props.equipment.name}`}</Text>
            </Stack>
            <Stack spacing={0} style={{ width: '50%' }}>
              <Text className={classes.label}>Category</Text>
              <Text className={classes.detail}>{`${props.equipment.equipmentTypeName}`}</Text>
            </Stack>
          </Group>

          <Stack spacing={0}>
            <Text className={classes.label}>Notes</Text>
            <ScrollArea className={classes.notesCard}>
              <Text>{`${props.equipment.notes}`}</Text>
            </ScrollArea>
          </Stack>
        </Stack>

        <Group noWrap spacing={10} ml={5} mt={5}>
          <Checkbox checked={props.equipment.hasSerialNumber} readOnly />
          <Text className={classes.label}>Does This Equipment have a Tracked Serial Number?</Text>
        </Group>

        {!props.isDeleted && (
          <Group position="apart" grow noWrap mt="md">
            <Button
              variant="outline"
              onClick={() => {
                props.onEdit();
              }}
            >
              <Edit style={{ marginRight: 5 }} />{' '}
              <Text className={classes.buttonText}>Edit Equipment</Text>
            </Button>
          </Group>
        )}
      </Box>
    </Modal>
  );
};

export default EquipmentDetailModal;
