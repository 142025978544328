import { Box, useMantineTheme, createStyles, Text, Group } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React from 'react';
import { BoxOff, Check, Clock, X } from 'tabler-icons-react';

const useStyles = createStyles((theme, _params) => ({
  status: {
    borderRadius: theme.radius.xl,
    textAlign: 'center',
    [`@media (max-width: ${theme.breakpoints.xs})`]: {
      width: 'fit-content',
    },
    [`@media (min-width: ${theme.breakpoints.xs})`]: {
      width: '85px',
    },
  },
  statusPending: {
    color: theme.colors.blue[6],
    background: 'transparent',
    border: `1px solid ${theme.colors.blue[6]}`,
  },
  statusDelivered: {
    color: 'white',
    backgroundColor: theme.colors.blue[6],
    border: `1px solid ${theme.colors.blue[6]}`,
  },
  statusUndelivered: {
    color: theme.colors.red[6],
    background: 'transparent',
    border: `1px solid ${theme.colors.red[6]}`,
  },
  statusIncomplete: {
    color: theme.colors.orange[4],
    backgroundColor: 'transparent',
    border: `1px solid ${theme.colors.orange[4]}`,
  },
  statusText: {
    fontWeight: 500,
    fontSize: 10,
    paddingLeft: '1px',
    paddingRight: '1px',
    overflow: 'visible',
  },
  smallTextIcon: {
    marginBottom: -3,
    marginLeft: -5,
    height: '15px',
  },
}));

type StatusCounterProps = {
  count: number;
  statusType: 'pending' | 'delivered' | 'undelivered' | 'incomplete';
};

const StatusCounter = (props: StatusCounterProps) => {
  const { classes, cx } = useStyles();
  const theme = useMantineTheme();
  const isExtraSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  return (
    <Box>
      {props.statusType === 'pending' && props.count > 0 && (
        <Box className={cx(classes.status, classes.statusPending)}>
          {!isExtraSmallMobile && (
            <Text className={classes.statusText}>Pending: {props.count.toString()}</Text>
          )}
          {isExtraSmallMobile && (
            <Group spacing={0} noWrap>
              <Text className={classes.statusText} ml={5}>
                {props.count.toString()}
              </Text>
              <Clock
                className={classes.smallTextIcon}
                size="15px"
                style={{ marginLeft: 0, marginTop: -4 }}
              />
            </Group>
          )}
        </Box>
      )}
      {props.statusType === 'delivered' && props.count > 0 && (
        <Box className={cx(classes.status, classes.statusDelivered)}>
          {!isExtraSmallMobile && (
            <Text className={classes.statusText}>Delivered: {props.count.toString()}</Text>
          )}
          {isExtraSmallMobile && (
            <Group spacing={0} noWrap>
              <Text className={classes.statusText} ml={5}>
                {props.count.toString()}
              </Text>
              <Check
                className={classes.smallTextIcon}
                size="15px"
                style={{ marginLeft: 0, marginTop: -4 }}
              />
            </Group>
          )}
        </Box>
      )}
      {props.statusType === 'undelivered' && props.count > 0 && (
        <Box className={cx(classes.status, classes.statusUndelivered)}>
          {!isExtraSmallMobile && (
            <Text className={classes.statusText}>Undelivered: {props.count.toString()}</Text>
          )}
          {isExtraSmallMobile && (
            <Group spacing={0} noWrap>
              <Text className={classes.statusText} ml={5}>
                {props.count.toString()}
              </Text>
              <X
                className={classes.smallTextIcon}
                size="15px"
                style={{ marginLeft: 0, marginTop: -4 }}
              />
            </Group>
          )}
        </Box>
      )}
      {props.statusType === 'incomplete' && props.count > 0 && (
        <Box className={cx(classes.status, classes.statusIncomplete)}>
          {!isExtraSmallMobile && (
            <Text className={classes.statusText}>Incomplete: {props.count.toString()}</Text>
          )}
          {isExtraSmallMobile && (
            <Group spacing={0} noWrap>
              <Text className={classes.statusText} ml={5}>
                {props.count.toString()}
              </Text>
              <BoxOff
                className={classes.smallTextIcon}
                size="15px"
                style={{ marginLeft: 0, marginTop: -4 }}
              />
            </Group>
          )}
        </Box>
      )}
    </Box>
  );
};

export default StatusCounter;
