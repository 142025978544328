import React from 'react';
import { useQuery } from 'react-query';
import externalApi from '../../api';
import { ITrackedEquipmentRepair } from '../../models';
import { useIsAdmin, useIsTechnician } from '..';

function fetchTrackedEquipmentRepairByTrackedEquipment(
  trackedEquipmentId: string
): Promise<ITrackedEquipmentRepair[]> {
  return externalApi.get(`TrackedEquipmentRepair/ByTrackedEquipment/${trackedEquipmentId}`).json();
}

export function useTrackedEquipmentRepairByTrackedEquipment(trackedEquipmentId: string) {
  const isAdmin = useIsAdmin().data?.isAdmin;
  const isTechnician = useIsTechnician().data?.isTechnician;

  return useQuery(
    ['TrackedEquipmentRepairByTrackedEquipment'],
    () => fetchTrackedEquipmentRepairByTrackedEquipment(trackedEquipmentId),
    {
      enabled: isTechnician || isAdmin,
    }
  );
}
