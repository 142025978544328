import {
  Box,
  Button,
  Center,
  Checkbox,
  Chip,
  createStyles,
  Divider,
  Group,
  Loader,
  ScrollArea,
  Stack,
  Table,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { Search, UserPlus } from 'tabler-icons-react';
import { PageHeader } from '../common/Page/Header/PageHeader';
import { Page } from '../common/Page/Page';
import {
  CityDisplayModal,
  FundingTypeDisplayModal,
  InfiniteScrollArea,
  PatientCreateModal,
  PatientDetailModal,
  PatientEditModal,
  PatientEquipmentHistoryModal,
  PatientEquipmentModal,
  Th,
  TrackedEquipmentNotesModal,
  TrackedEquipmentPatientAssignModal,
  TrackedEquipmentReturnModal,
  TrackedEquipmentSelectModal,
  TrackedPatientEquipmentDetailModal,
} from '../components';
import { usePageStore, usePatients } from '../hooks';
import { IEquipment, ILocationState, IPatient, ITrackedPatientEquipment } from '../models';
import { useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';

const useStyles = createStyles((theme, _params) => ({
  card: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.md,
    padding: `${theme.spacing.sm} ${theme.spacing.lg}`,
  },
}));

function filterData(data: IPatient[], search: string, visibleKeys: string[]) {
  const keys = visibleKeys;
  const query = search.toLowerCase().trim();
  return data.filter((item) =>
    keys.some((key) => (item[key] ?? '-').toString().toLowerCase().includes(query))
  );
}

function sortData(
  data: IPatient[],
  payload: { sortBy: keyof IPatient; reversed: boolean; search: string },
  visibleKeys: string[]
) {
  if (!payload.sortBy) {
    return filterData(data, payload.search, visibleKeys);
  }

  return filterData(
    [...data].sort((a, b) => {
      if (payload.reversed) {
        return (b[payload.sortBy] ?? '000ForceToBottom')
          .toString()
          .localeCompare((a[payload.sortBy] ?? '000ForceToBottom').toString());
      }

      return (a[payload.sortBy] ?? 'zzzForceToBottom')
        .toString()
        .localeCompare((b[payload.sortBy] ?? 'zzzForceToBottom').toString());
    }),
    payload.search,
    visibleKeys
  );
}

type Props = {};

const PatientsPage = (props: Props) => {
  let patients = usePatients();

  const queryClient = useQueryClient();
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const state = useLocation().state as ILocationState;

  const pageRef = useRef<HTMLDivElement | null>(null);
  const [pageWidth, setPageWidth] = useState<number>(0);

  const [clickTracker, setClickTracker] = useState<boolean>(false);

  const [patientCreated, setPatientCreated] = useState<boolean>(false);
  const [patientUpdated, setPatientUpdated] = useState<boolean>(false);
  const [patientDeleted, setPatientDeleted] = useState<boolean>(false);
  const [patientReactivated, setPatientReactivated] = useState<boolean>(false);
  const [patientDeactivated, setPatientDeactivated] = useState<boolean>(false);
  const [patientUpdateError, setPatientUpdateError] = useState<boolean>(false);
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [equipmentModalOpen, setEquipmentModalOpen] = useState<boolean>(false);
  const [selectedEquipment, setSelectedEquipment] = useState<IEquipment>({
    equipmentId: '0',
    equipmentTypeId: '',
    equipmentTypeName: '',
    name: '',
    notes: '',
    hasSerialNumber: true,
    lastUpdatedUserId: '',
    lastUpdatedDateTime: new Date(),
  });
  const [equipmentSelectModalOpen, setEquipmentSelectModalOpen] = useState<boolean>(false);
  const [selectedTrackedEquipmentId, setSelectedTrackedEquipmentId] = useState<string>('0');
  const [equipmentAssignModalOpen, setEquipmentAssignModalOpen] = useState<boolean>(false);
  const [equipmentReturnModaOpen, setEquipmentReturnModalOpen] = useState<boolean>(false);
  const [selectedTrackedPatientEquipmentId, setSelectedTrackedPatientEquipmentId] =
    useState<string>('0');
  const [equipmentReturned, setEquipmentReturned] = useState<boolean>(false);
  const [equipmentNotesModalOpen, setEquipmentNotesModalOpen] = useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [detailModalOpen, setDetailModalOpen] = useState<boolean>(false);
  const [cityModalOpen, setCityModalOpen] = useState<boolean>(false);
  const [cityModalOpenedBy, setCityModalOpenedBy] = useState<'create' | 'edit'>('create');
  const [fundingTypeModalOpen, setFundingTypeModalOpen] = useState<boolean>(false);
  const [fundingTypeModalOpenedBy, setFundingTypeModalOpenedBy] = useState<'create' | 'edit'>(
    'create'
  );
  const [equipmentHistoryModalOpen, setEquipmentHistoryModalOpen] = useState<boolean>(false);
  const [detailPatient, setDetailPatient] = useState<IPatient>({
    patientId: '0',
    firstName: '',
    lastName: '',
    fullName: '',
    fullNameFormatted: '',
    lastFourHCN: '',
    claimNumber: '',
    phoneNumber: '',
    phoneNumberFormatted: '',
    streetAddress: '',
    cityId: '',
    cityName: '',
    fundingTypeId: '',
    fundingTypeName: '',
    yearlyAppointment: false,
    covid: false,
    lakeridgeGardens: false,
    hospicePT: false,
    startDate: new Date(),
    dischargeDate: undefined,
    deathDate: undefined,
    notes: '',
    active: true,
    lastUpdatedUserId: '',
    lastUpdatedDateTime: new Date(),
  });
  const [detailTrackedPatientEquipment, setDetailTrackedPatientEquipment] =
    useState<ITrackedPatientEquipment>({
      trackedPatientEquipmentId: '0',
      patientId: '0',
      patientName: '',
      trackedEquipmentId: '0',
      equipmentName: '',
      equipmentTypeName: '',
      serialNumber: '',
    });
  const [trackedPatientEquipmentModalOpen, setTrackedPatientEquipmentModalOpen] =
    useState<boolean>(false);

  const filterValues = usePageStore((state) => state.patientsFilterValues);
  const setFilterValues = usePageStore((state) => state.setPatientsFilterValues);

  const [search, setSearch] = useState('');
  const [sortedData, setSortedData] = useState(patients.data);
  const [sortBy, setSortBy] = useState<keyof IPatient>(null!);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);

  const defaultKeys: string[] = [
    'firstName',
    'lastName',
    'fullName',
    'fullNameFormatted',
    'lastFourHCN',
    'claimNumber',
    'active',
    'startDate',
    'dischargeDate',
    'deathDate',
  ];

  const selectedKeys = usePageStore((state) => state.patientsSelectedKeys);
  const setSelectedKeys = usePageStore((state) => state.setPatientsSelectedKeys);

  const setSorting = (field: keyof IPatient) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setSortedData(
      sortData(
        patients.data!,
        { sortBy: field, reversed, search },
        defaultKeys.concat(selectedKeys)
      )
    );
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSearch(value);
    setSortedData(
      sortData(
        patients.data!,
        { sortBy, reversed: reverseSortDirection, search: value },
        defaultKeys.concat(selectedKeys)
      )
    );
  };

  const rows = sortedData?.map((row) => {
    let newRow = (
      <tr
        key={row.patientId}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          setDetailPatient(row);
          setDetailModalOpen(true);
        }}
      >
        <td
          style={{ width: 180, wordWrap: 'break-word' }}
        >{`${row.lastName}, ${row.firstName}`}</td>
        <td style={{ width: 120, wordWrap: 'break-word' }}>{`#${row.lastFourHCN}`}</td>
        <td style={{ width: 120, wordWrap: 'break-word' }}>{`#${row.claimNumber}`}</td>
        {selectedKeys.includes('phoneNumber') && (
          <td
            style={{ width: 120, wordWrap: 'break-word' }}
          >{`(${row.phoneNumber[0]}${row.phoneNumber[1]}${row.phoneNumber[2]}) ${row.phoneNumber[3]}${row.phoneNumber[4]}${row.phoneNumber[5]}-${row.phoneNumber[6]}${row.phoneNumber[7]}${row.phoneNumber[8]}${row.phoneNumber[9]}`}</td>
        )}
        {selectedKeys.includes('streetAddress') && (
          <td style={{ width: 120, wordWrap: 'break-word' }}>{row.streetAddress}</td>
        )}
        {selectedKeys.includes('cityName') && <td style={{ width: 120 }}>{row.cityName}</td>}
        {selectedKeys.includes('fundingTypeName') && (
          <td style={{ width: 120, wordWrap: 'break-word' }}>{row.fundingTypeName}</td>
        )}
        {selectedKeys.includes('yearlyAppointment') && (
          <td style={{ width: 130, wordWrap: 'break-word' }}>
            <Checkbox checked={row.yearlyAppointment} readOnly />
          </td>
        )}
        {selectedKeys.includes('covid') && (
          <td style={{ width: 120, wordWrap: 'break-word' }}>
            <Checkbox checked={row.covid} readOnly />
          </td>
        )}
        {selectedKeys.includes('lakeridgeGardens') && (
          <td style={{ width: 120, wordWrap: 'break-word' }}>
            <Checkbox checked={row.lakeridgeGardens} readOnly />
          </td>
        )}
        {selectedKeys.includes('hospicePT') && (
          <td style={{ width: 120, wordWrap: 'break-word' }}>
            <Checkbox checked={row.hospicePT} readOnly />
          </td>
        )}
        <td style={{ width: 120, wordWrap: 'break-word' }}>
          <Checkbox checked={row.active} readOnly />
        </td>
        {row.startDate && (
          <td style={{ width: 120, wordWrap: 'break-word' }}>
            {dayjs(row.startDate).format('YYYY-MM-DD')}
          </td>
        )}
        {!row.startDate && <td style={{ width: 120, wordWrap: 'break-word' }}>-</td>}
        {row.dischargeDate && (
          <td style={{ width: 120, wordWrap: 'break-word' }}>
            {dayjs(row.dischargeDate).format('YYYY-MM-DD')}
          </td>
        )}
        {!row.dischargeDate && <td style={{ width: 120, wordWrap: 'break-word' }}>-</td>}
        {row.deathDate && (
          <td style={{ width: 120, wordWrap: 'break-word' }}>
            {dayjs(row.deathDate).format('YYYY-MM-DD')}
          </td>
        )}
        {!row.deathDate && <td style={{ width: 120, wordWrap: 'break-word' }}>-</td>}
      </tr>
    );

    let test = 0;
    let target = filterValues.length;

    if (filterValues.includes('active') && filterValues.includes('inactive')) {
      target--;
    }

    if (filterValues.includes('active') && row.active) {
      test++;
      if (!filterValues.includes('deceased') && row.deathDate) {
        target--;
      }
    }
    if (filterValues.includes('inactive') && !row.active) {
      test++;
      if (!filterValues.includes('deceased') && row.deathDate) {
        target--;
      }
    }
    if (filterValues.includes('deceased') && row.deathDate) {
      test++;
    }

    if (test === target) {
      return newRow;
    }
  });

  useEffect(() => {
    if (selectedKeys.includes('phoneNumber')) {
      setSelectedKeys(selectedKeys.concat(['phoneNumberFormatted']));
    } else if (selectedKeys.includes('phoneNumberFormatted')) {
      setSelectedKeys(selectedKeys.filter((a) => a !== 'phoneNumberFormatted'));
    }
  }, [clickTracker]);

  useEffect(() => {
    if (!patients.data) {
      setSortedData(patients.data);
    } else {
      setSortedData(
        sortData(
          patients.data,
          { sortBy, reversed: reverseSortDirection, search },
          defaultKeys.concat(selectedKeys)
        )
      );
    }
    queryClient.invalidateQueries('Patients');
  }, [patients.data, detailPatient, reverseSortDirection, search, sortBy, selectedKeys]);

  useEffect(() => {
    if (pageRef.current) setPageWidth(pageRef.current.clientWidth);
  }, [pageRef.current]);

  useEffect(() => {
    function handleResize() {
      if (pageRef.current) setPageWidth(pageRef.current.clientWidth);
    }
    window.addEventListener('resize', handleResize);
  });

  useEffect(() => {
    if (state) {
      if (state.patient) {
        setDetailPatient(state.patient);
        setDetailModalOpen(true);
      }
    }
  }, []);

  return (
    <Page
      header={
        <PageHeader
          title="Manage Patients"
          subtitle="View, Add, and Edit Patients"
          stickyContent={null}
        />
      }
    >
      <Stack spacing={0} style={{ height: '100%' }}>
        <Text size="xl" weight={500} align="center" mb="lg">
          Click on a record to view it in full
        </Text>
        <Stack spacing="sm" mb="sm">
          <Center>
            <Group
              position="apart"
              className={classes.card}
              p="sm"
              mb={`calc(${theme.spacing.md} - 1)`}
            >
              <Text style={{ width: 'fit-content' }} ml="md">
                Show Records:
              </Text>
              <Center>
                <Chip.Group multiple value={filterValues} onChange={setFilterValues}>
                  <Group spacing={`calc(${theme.spacing.xs} / 2)`}>
                    <Chip variant="filled" value="active">
                      Active
                    </Chip>
                    <Chip variant="filled" value="inactive">
                      Inactive
                    </Chip>
                    <Chip variant="filled" value="deceased">
                      Deceased
                    </Chip>
                  </Group>
                </Chip.Group>
              </Center>
            </Group>
          </Center>
          <Center>
            <Group
              position="apart"
              className={classes.card}
              p="sm"
              mb={`calc(${theme.spacing.md} - 1)`}
            >
              <Text style={{ width: 'fit-content' }} ml="sm">
                Include Fields:
              </Text>
              <Center>
                <Chip.Group
                  multiple
                  value={selectedKeys}
                  onChange={(e) => {
                    setClickTracker(!clickTracker);
                    setSelectedKeys(e);
                  }}
                >
                  <Group spacing={`calc(${theme.spacing.xs} / 2)`}>
                    <Chip variant="filled" value="phoneNumber">
                      Phone Number
                    </Chip>
                    <Chip variant="filled" value="streetAddress">
                      Address
                    </Chip>
                    <Chip variant="filled" value="cityName">
                      City
                    </Chip>
                    <Chip variant="filled" value="fundingTypeName">
                      Funding Type
                    </Chip>
                    <Chip variant="filled" value="yearlyAppointment">
                      Yearly Appointment
                    </Chip>
                    <Chip variant="filled" value="covid">
                      Covid
                    </Chip>
                    <Chip variant="filled" value="lakeridgeGardens">
                      Lakeridge Gardens
                    </Chip>
                    <Chip variant="filled" value="hospicePT">
                      Hospice PT
                    </Chip>
                  </Group>
                </Chip.Group>
              </Center>
            </Group>
          </Center>
        </Stack>
        <Box ref={pageRef} style={{ height: '100%' }}>
          <Stack spacing={0} style={{ height: '100%' }}>
            <TextInput
              placeholder="Search by any field"
              mb="md"
              icon={<Search size={14} />}
              value={search}
              onChange={handleSearchChange}
            />
            <Divider />
            <ScrollArea type="hover" style={{ width: pageWidth }}>
              <Table style={{ width: '100%', tableLayout: 'fixed', overflow: 'hidden' }}>
                <thead>
                  <tr>
                    <Th
                      sorted={sortBy === 'lastName'}
                      reversed={reverseSortDirection}
                      onSort={() => setSorting('lastName')}
                      style={{ width: 180 }}
                    >
                      Patient Name
                    </Th>
                    <Th
                      sorted={sortBy === 'lastFourHCN'}
                      reversed={reverseSortDirection}
                      onSort={() => setSorting('lastFourHCN')}
                      style={{ width: 120 }}
                    >
                      Last 4 HCN
                    </Th>
                    <Th
                      sorted={sortBy === 'claimNumber'}
                      reversed={reverseSortDirection}
                      onSort={() => setSorting('claimNumber')}
                      style={{ width: 120 }}
                    >
                      Unique Number
                    </Th>
                    {selectedKeys.includes('phoneNumber') && (
                      <Th
                        sorted={sortBy === 'phoneNumber'}
                        reversed={reverseSortDirection}
                        onSort={() => setSorting('phoneNumber')}
                        style={{ width: 120 }}
                      >
                        Phone Number
                      </Th>
                    )}
                    {selectedKeys.includes('streetAddress') && (
                      <Th
                        sorted={sortBy === 'streetAddress'}
                        reversed={reverseSortDirection}
                        onSort={() => setSorting('streetAddress')}
                        style={{ width: 120 }}
                      >
                        Address
                      </Th>
                    )}
                    {selectedKeys.includes('cityName') && (
                      <Th
                        sorted={sortBy === 'cityName'}
                        reversed={reverseSortDirection}
                        onSort={() => setSorting('cityName')}
                        style={{ width: 120 }}
                      >
                        City
                      </Th>
                    )}
                    {selectedKeys.includes('fundingTypeName') && (
                      <Th
                        sorted={sortBy === 'fundingTypeName'}
                        reversed={reverseSortDirection}
                        onSort={() => setSorting('fundingTypeName')}
                        style={{ width: 120 }}
                      >
                        Funding Type
                      </Th>
                    )}
                    {selectedKeys.includes('yearlyAppointment') && (
                      <Th
                        sorted={sortBy === 'yearlyAppointment'}
                        reversed={reverseSortDirection}
                        onSort={() => setSorting('yearlyAppointment')}
                        style={{ width: 130 }}
                      >
                        Yearly Appointment
                      </Th>
                    )}
                    {selectedKeys.includes('covid') && (
                      <Th
                        sorted={sortBy === 'covid'}
                        reversed={reverseSortDirection}
                        onSort={() => setSorting('covid')}
                        style={{ width: 120 }}
                      >
                        Covid
                      </Th>
                    )}
                    {selectedKeys.includes('lakeridgeGardens') && (
                      <Th
                        sorted={sortBy === 'lakeridgeGardens'}
                        reversed={reverseSortDirection}
                        onSort={() => setSorting('lakeridgeGardens')}
                        style={{ width: 120 }}
                      >
                        Lakeridge Gardens
                      </Th>
                    )}
                    {selectedKeys.includes('hospicePT') && (
                      <Th
                        sorted={sortBy === 'hospicePT'}
                        reversed={reverseSortDirection}
                        onSort={() => setSorting('hospicePT')}
                        style={{ width: 120 }}
                      >
                        Hospice PT
                      </Th>
                    )}
                    <Th
                      sorted={sortBy === 'active'}
                      reversed={reverseSortDirection}
                      onSort={() => setSorting('active')}
                      style={{ width: 120 }}
                    >
                      Active
                    </Th>
                    <Th
                      sorted={sortBy === 'startDate'}
                      reversed={reverseSortDirection}
                      onSort={() => setSorting('startDate')}
                      style={{ width: 120 }}
                    >
                      Start Date
                    </Th>
                    <Th
                      sorted={sortBy === 'dischargeDate'}
                      reversed={reverseSortDirection}
                      onSort={() => setSorting('dischargeDate')}
                      style={{ width: 120 }}
                    >
                      Discharge Date
                    </Th>
                    <Th
                      sorted={sortBy === 'deathDate'}
                      reversed={reverseSortDirection}
                      onSort={() => setSorting('deathDate')}
                      style={{ width: 120 }}
                    >
                      Death Date
                    </Th>
                  </tr>
                </thead>
              </Table>
              {patients.data && <InfiniteScrollArea rows={rows} height={350} />}
              {!patients.data && patients.isLoading && (
                <Stack style={{ height: '350px' }} align="center" justify="center">
                  <Loader size={84} />
                  <Text size="lg" weight={500}>
                    Loading...
                  </Text>
                </Stack>
              )}
            </ScrollArea>
            <Divider />
            <Group noWrap grow position="apart" mt="lg">
              <Button
                variant="outline"
                onClick={() => {
                  setCreateModalOpen(true);
                }}
              >
                <UserPlus style={{ marginRight: 5 }} />
                Add a New Patient
              </Button>
            </Group>
          </Stack>
        </Box>
      </Stack>
      <PatientCreateModal
        opened={createModalOpen}
        onClose={() => {
          setCreateModalOpen(false);
        }}
        onCreate={(isCreated, newPatient) => {
          setPatientCreated(isCreated);
          setDetailPatient(newPatient);
          setCreateModalOpen(false);
          setDetailModalOpen(true);
        }}
        onAddCity={() => {
          setCreateModalOpen(false);
          setCityModalOpenedBy('create');
          setCityModalOpen(true);
        }}
        onAddFundingType={() => {
          setCreateModalOpen(false);
          setFundingTypeModalOpenedBy('create');
          setFundingTypeModalOpen(true);
        }}
      />
      <PatientDetailModal
        patient={detailPatient}
        opened={detailModalOpen}
        isCreated={patientCreated}
        isUpdated={patientUpdated}
        isReactivated={patientReactivated}
        isDeactivated={patientDeactivated}
        isError={patientUpdateError}
        onClose={() => {
          setPatientUpdated(false);
          setPatientUpdateError(false);
          setPatientCreated(false);
          setPatientDeleted(false);
          setPatientReactivated(false);
          setPatientDeactivated(false);
          setDetailModalOpen(false);
        }}
        onEdit={() => {
          setDetailModalOpen(false);
          setEditModalOpen(true);
        }}
        onViewEquipment={() => {
          setDetailModalOpen(false);
          setEquipmentModalOpen(true);
        }}
      />
      <PatientEditModal
        patient={detailPatient}
        opened={editModalOpen}
        onClose={() => {
          setEditModalOpen(false);
          setDetailModalOpen(true);
        }}
        onEdited={(isUpdated, isError, updatedPatient) => {
          setEditModalOpen(false);
          setDetailModalOpen(true);
          setPatientUpdated(isUpdated);
          setPatientUpdateError(isError);
          setDetailPatient(updatedPatient);
        }}
        onDeleted={() => {
          setEditModalOpen(false);
        }}
        onReactivated={() => {
          setEditModalOpen(false);
          setDetailModalOpen(true);
          setPatientReactivated(true);
        }}
        onDeactivated={() => {
          setEditModalOpen(false);
          setDetailModalOpen(true);
          setPatientDeactivated(true);
        }}
        onAddCity={() => {
          setEditModalOpen(false);
          setCityModalOpenedBy('edit');
          setCityModalOpen(true);
        }}
        onAddFundingType={() => {
          setEditModalOpen(false);
          setFundingTypeModalOpenedBy('edit');
          setFundingTypeModalOpen(true);
        }}
      />
      <PatientEquipmentModal
        patient={detailPatient}
        opened={equipmentModalOpen}
        equipmentReturned={equipmentReturned}
        onViewNotes={(trackedPatientEquipmentId) => {
          setSelectedTrackedPatientEquipmentId(trackedPatientEquipmentId);
          setEquipmentModalOpen(false);
          setEquipmentNotesModalOpen(true);
        }}
        onNewTrackedEquipment={(selectedEquipment) => {
          setEquipmentReturned(false);
          setSelectedEquipment(selectedEquipment);
          setEquipmentModalOpen(false);
          setEquipmentSelectModalOpen(true);
        }}
        onReturnTrackedEquipment={(trackedPatientEquipmentId) => {
          setEquipmentReturned(false);
          setSelectedTrackedPatientEquipmentId(trackedPatientEquipmentId);
          setEquipmentModalOpen(false);
          setEquipmentReturnModalOpen(true);
        }}
        onClose={() => {
          setEquipmentReturned(false);
          setEquipmentModalOpen(false);
          setDetailModalOpen(true);
        }}
        onViewEquipmentHistory={() => {
          setEquipmentModalOpen(false);
          setEquipmentHistoryModalOpen(true);
        }}
      />
      <PatientEquipmentHistoryModal
        patient={detailPatient}
        opened={equipmentHistoryModalOpen}
        onViewNotes={(trackedPatientEquipmentId) => {
          setSelectedTrackedPatientEquipmentId(trackedPatientEquipmentId);
          setEquipmentHistoryModalOpen(false);
          setEquipmentNotesModalOpen(true);
        }}
        onClose={() => {
          setEquipmentHistoryModalOpen(false);
          setEquipmentModalOpen(true);
        }}
        onSelect={(trackedPatientEquipment) => {
          setDetailTrackedPatientEquipment(trackedPatientEquipment);
          setEquipmentHistoryModalOpen(false);
          setTrackedPatientEquipmentModalOpen(true);
        }}
      />
      <TrackedPatientEquipmentDetailModal
        trackedPatientEquipment={detailTrackedPatientEquipment}
        opened={trackedPatientEquipmentModalOpen}
        readOnly
        onClose={() => {
          setTrackedPatientEquipmentModalOpen(false);
          setEquipmentHistoryModalOpen(true);
        }}
      />
      <CityDisplayModal
        opened={cityModalOpen}
        onClose={() => {
          setCityModalOpen(false);
          if (cityModalOpenedBy === 'create') {
            setCreateModalOpen(true);
          } else {
            setEditModalOpen(true);
          }
        }}
      />
      <FundingTypeDisplayModal
        opened={fundingTypeModalOpen}
        onClose={() => {
          setFundingTypeModalOpen(false);
          if (fundingTypeModalOpenedBy === 'create') {
            setCreateModalOpen(true);
          } else {
            setEditModalOpen(true);
          }
        }}
      />
      <TrackedEquipmentSelectModal
        opened={equipmentSelectModalOpen}
        equipmentId={selectedEquipment.equipmentId}
        onSelect={(trackedEquipment) => {
          setSelectedTrackedEquipmentId(trackedEquipment.trackedEquipmentId);
          setEquipmentSelectModalOpen(false);
          setEquipmentAssignModalOpen(true);
        }}
        onClose={() => {
          setEquipmentSelectModalOpen(false);
          setEquipmentModalOpen(true);
        }}
      />
      <TrackedEquipmentPatientAssignModal
        opened={equipmentAssignModalOpen}
        trackedEquipmentId={selectedTrackedEquipmentId}
        patientId={detailPatient.patientId}
        onCreate={() => {
          setEquipmentAssignModalOpen(false);
          setEquipmentModalOpen(true);
        }}
        onClose={() => {
          setEquipmentAssignModalOpen(false);
          setEquipmentSelectModalOpen(true);
        }}
      />
      <TrackedEquipmentReturnModal
        opened={equipmentReturnModaOpen}
        trackedPatientEquipmentId={selectedTrackedPatientEquipmentId}
        patientName={detailPatient.fullNameFormatted}
        patientClaimNumber={detailPatient.claimNumber}
        onReturn={() => {
          setEquipmentReturned(true);
          setEquipmentReturnModalOpen(false);
          setEquipmentModalOpen(true);
        }}
        onClose={() => {
          setEquipmentReturnModalOpen(false);
          setEquipmentModalOpen(true);
        }}
      />
      <TrackedEquipmentNotesModal
        opened={equipmentNotesModalOpen}
        trackedPatientEquipmentId={selectedTrackedPatientEquipmentId}
        patientName={detailPatient.fullNameFormatted}
        patientClaimNumber={detailPatient.claimNumber}
        onClose={() => {
          setEquipmentNotesModalOpen(false);
          setEquipmentModalOpen(true);
        }}
      />
    </Page>
  );
};

export default PatientsPage;
