export enum ITrackedEquipmentFilter {
  AllActive,
  InStock,
  ReadyForCleaning,
  InNeedOfRepair,
  OutForRepair,
  ReadyForDelivery,
  Unready,
  CheckedOut,
  Retired,
}
