import {
  Box,
  Button,
  Center,
  Checkbox,
  Chip,
  createStyles,
  Divider,
  Group,
  Loader,
  Pagination,
  ScrollArea,
  Stack,
  Table,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FilePlus, Search } from 'tabler-icons-react';
import {
  OrderDetailModal,
  OrderEditModal,
  OrderCreateModal,
  Th,
  PatientSelectModal,
  OrderEquipmentModal,
  DeliveryEquipmentModal,
  InfiniteScrollArea,
} from '../../components';
import { useOrders, useOrdersCount, usePageStore } from '../../hooks';
import { IFilteredPatient, ILocationState, IOrder, IOrderFilter } from '../../models';
import InfiniteScroll from 'react-infinite-scroller';
import { useDebouncedValue } from '@mantine/hooks';

const useStyles = createStyles((theme, _params) => ({
  card: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.md,
    padding: `${theme.spacing.sm} ${theme.spacing.lg}`,
  },
}));

type Props = {};

const OnDemandOrdersSubPage = (props: Props) => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const state = useLocation().state as ILocationState;

  const PAGE_SIZE = 5;

  const filterValues = usePageStore((state) => state.onDemandOrdersFilterValues);
  const setFilterValues = usePageStore((state) => state.setOnDemandOrdersFilterValues);

  const [selectedPatient, setSelectedPatient] = useState<IFilteredPatient | undefined>(
    state ? state.filteredPatient : undefined
  );
  const [patientSelected, setPatientSelected] = useState<boolean>(false);

  const defaultOrder = {
    orderId: '',
    patientId: '',
    patientFirstName: '',
    patientLastName: '',
    patientFullName: '',
    patientFullNameFormatted: '',
    patientPhoneNumber: '',
    patientAddress: '',
    patientCity: '',
    patientNotes: '',
    isAMDelivery: false,
    deliveryTimeName: '',
    requestedDeliveryDate: new Date(),
    liquid: false,
    notes: '',
    isUrgent: false,
    standingOrderId: undefined,
    standingOrderReschedule: false,
    deliveryReschedule: false,
    fulfilled: false,
    lastUpdatedUserId: '',
    lastUpdatedDateTime: new Date(),
  };

  const [detailOrder, setDetailOrder] = useState<IOrder>(
    state ? (state.order ? state.order : defaultOrder) : defaultOrder
  );
  const [orderCreated, setOrderCreated] = useState<boolean>(false);
  const [orderUpdated, setOrderUpdated] = useState<boolean>(false);
  const [orderDeleted, setOrderDeleted] = useState<boolean>(false);
  const [orderUpdateError, setOrderUpdateError] = useState<boolean>(false);
  const [patientSelectModalOpen, setPatientSelectModalOpen] = useState<boolean>(false);
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(selectedPatient !== undefined);
  const [equipmentModalOpen, setEquipmentModalOpen] = useState<boolean>(false);
  const [deliveryEquipmentModalOpen, setDeliveryEquipmentModalOpen] = useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [detailModalOpen, setDetailModalOpen] = useState<boolean>(detailOrder.orderId !== '');

  let orders = useOrders();
  let ordersCount = useOrdersCount();

  const [pageNumber, setPageNumber] = useState<number>(1);

  const [search, setSearch] = useState('');
  const [searchValue] = useDebouncedValue(search, 200);

  useEffect(() => {
    orders.mutate({
      pageNumber: pageNumber,
      pageSize: PAGE_SIZE,
      filters: filterValues,
      search: searchValue,
    });
  }, [filterValues, searchValue, pageNumber]);

  useEffect(() => {
    ordersCount.mutate({ filters: filterValues, search: searchValue });
  }, [filterValues, searchValue]);

  useEffect(() => {
    setPageNumber(1);
  }, [ordersCount.data?.count]);

  const rows = orders.data?.map((row) => {
    let newRow = (
      <tr
        key={row.orderId}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          setDetailOrder(row);
          setDetailModalOpen(true);
        }}
      >
        <td
          style={{ width: 120, wordWrap: 'break-word' }}
        >{`${row.patientLastName}, ${row.patientFirstName}`}</td>
        <td style={{ width: 100, wordWrap: 'break-word' }}>
          {dayjs(row.requestedDeliveryDate).format('YYYY-MM-DD')}
        </td>
        <td style={{ width: 90, wordWrap: 'break-word' }}>{`${row.deliveryTimeName}`}</td>
        <td style={{ width: 120, wordWrap: 'break-word' }}>{`${row.patientAddress}`}</td>
        <td style={{ width: 100, wordWrap: 'break-word' }}>{`${row.patientCity}`}</td>
        <td style={{ width: 80, wordWrap: 'break-word' }}>
          <Checkbox checked={row.liquid} readOnly />
        </td>
        <td style={{ width: 80, wordWrap: 'break-word' }}>
          <Checkbox checked={row.isUrgent} readOnly />
        </td>
        <td style={{ width: 120, wordWrap: 'break-word' }}>
          <Checkbox checked={row.standingOrderReschedule} readOnly />
        </td>
        <td style={{ width: 120, wordWrap: 'break-word' }}>
          <Checkbox checked={row.fulfilled} readOnly />
        </td>
      </tr>
    );
    return newRow;
  });

  return (
    <Box style={{ height: '100%' }}>
      <Box style={{ height: '100%' }}>
        <Center mb="sm">
          <Group
            position="apart"
            p="sm"
            className={classes.card}
            mb={`calc(${theme.spacing.md} - 1)`}
          >
            <Text style={{ width: 'fit-content' }} ml="sm">
              Filters:
            </Text>
            <Center>
              <Chip.Group
                multiple
                value={filterValues.map((a) => a.toString())}
                onChange={(a) => setFilterValues(a.map((b) => parseInt(b)))}
              >
                <Group spacing={`calc(${theme.spacing.xs} / 2)`}>
                  <Chip variant="filled" value={IOrderFilter.Unfulfilled.toString()}>
                    Unfulfilled
                  </Chip>
                  <Chip variant="filled" value={IOrderFilter.Fulfilled.toString()}>
                    Fulfilled
                  </Chip>
                  <Chip variant="filled" value={IOrderFilter.AM.toString()}>
                    AM
                  </Chip>
                  <Chip variant="filled" value={IOrderFilter.PM.toString()}>
                    PM
                  </Chip>
                  <Chip variant="filled" value={IOrderFilter.RescheduledStandingOrders.toString()}>
                    Rescheduled Standing Orders
                  </Chip>
                  <Chip variant="filled" value={IOrderFilter.Urgent.toString()}>
                    Urgent
                  </Chip>
                </Group>
              </Chip.Group>
            </Center>
          </Group>
        </Center>
        <TextInput
          placeholder="Search by any field"
          mb="md"
          icon={<Search size={14} />}
          value={search}
          onChange={(a) => setSearch(a.target.value)}
        />
        <Divider />
        <ScrollArea type="hover" style={{ width: '100%' }}>
          <Table style={{ width: '100%', tableLayout: 'fixed' }}>
            <thead>
              <tr>
                <Th style={{ width: 120 }}>Patient Name</Th>
                <Th style={{ width: 100 }}>Requested Delivery Date</Th>
                <Th style={{ width: 90 }}>Delivery Time</Th>
                <Th style={{ width: 120 }}>Address</Th>
                <Th style={{ width: 100 }}>City</Th>
                <Th style={{ width: 80 }}>Liquid</Th>
                <Th style={{ width: 80 }}>Urgent</Th>
                <Th style={{ width: 120 }}>Rescheduled Standing Order</Th>
                <Th style={{ width: 120 }}>Order Fulfilled</Th>
              </tr>
            </thead>
          </Table>
          {orders.data && <InfiniteScrollArea rows={rows} />}
          {!orders.data && orders.isLoading && (
            <Stack style={{ height: 350 }} align="center" justify="center">
              <Loader size={84} />
              <Text size="lg" weight={500}>
                Loading...
              </Text>
            </Stack>
          )}
        </ScrollArea>
        <Divider />
        <Stack spacing="sm" mt="sm">
          <Center>
            {ordersCount.data && (
              <Pagination
                value={pageNumber}
                onChange={setPageNumber}
                total={
                  Math.floor(ordersCount.data.count / PAGE_SIZE) +
                  (ordersCount.data.count % PAGE_SIZE > 0 ? 1 : 0)
                }
                siblings={1}
              />
            )}
          </Center>
          <Group noWrap grow position="apart" mt="lg">
            <Button
              variant="outline"
              mb="lg"
              onClick={() => {
                setCreateModalOpen(true);
              }}
            >
              <FilePlus style={{ marginRight: 5 }} />
              Add a New Order
            </Button>
          </Group>
        </Stack>
      </Box>

      <OrderCreateModal
        opened={createModalOpen}
        selectedPatient={selectedPatient}
        onSelectPatient={() => {
          setCreateModalOpen(false);
          setPatientSelectModalOpen(true);
        }}
        onDeSelectPatient={() => {
          setSelectedPatient(undefined);
        }}
        onClose={() => {
          setCreateModalOpen(false);
          setSelectedPatient(undefined);
        }}
        onCreate={(isCreated, newOrder) => {
          setOrderCreated(isCreated);
          setDetailOrder(newOrder);
          setCreateModalOpen(false);
          setSelectedPatient(undefined);
          setDetailModalOpen(true);
        }}
      />
      <PatientSelectModal
        opened={patientSelectModalOpen}
        onClose={() => {
          setPatientSelectModalOpen(false);
          setCreateModalOpen(true);
        }}
        onSelect={(isSelected, selectedPatient) => {
          setPatientSelected(isSelected);
          setSelectedPatient(selectedPatient);
          setPatientSelectModalOpen(false);
          setCreateModalOpen(true);
        }}
      />
      <OrderDetailModal
        order={detailOrder}
        opened={detailModalOpen}
        isCreated={orderCreated}
        isUpdated={orderUpdated}
        isDeleted={orderDeleted}
        isError={orderUpdateError}
        onClose={() => {
          setOrderUpdated(false);
          setOrderUpdateError(false);
          setOrderCreated(false);
          setOrderDeleted(false);
          setDetailModalOpen(false);
        }}
        onEdit={() => {
          setEditModalOpen(true);
          setDetailModalOpen(false);
        }}
        onViewEquipment={() => {
          if (!detailOrder.fulfilled) {
            setEquipmentModalOpen(true);
          } else {
            setDeliveryEquipmentModalOpen(true);
          }
          setDetailModalOpen(false);
        }}
      />
      <OrderEditModal
        order={detailOrder}
        opened={editModalOpen}
        onClose={() => {
          setEditModalOpen(false);
          setDetailModalOpen(true);
        }}
        onEdited={(isUpdated, isError, updatedOrder) => {
          setEditModalOpen(false);
          setDetailModalOpen(true);
          setOrderUpdated(isUpdated);
          setOrderUpdateError(isError);
          setDetailOrder(updatedOrder);
        }}
        onDeleted={() => {
          setEditModalOpen(false);
          setDetailModalOpen(true);
          setOrderDeleted(true);
        }}
      />
      <OrderEquipmentModal
        order={detailOrder}
        opened={equipmentModalOpen}
        onClose={() => {
          setEquipmentModalOpen(false);
          setDetailModalOpen(true);
        }}
      />
      <DeliveryEquipmentModal
        recordId={detailOrder.fulfilledDeliveryId ?? '0'}
        recordType={'delivery'}
        opened={deliveryEquipmentModalOpen}
        onClose={() => {
          setDeliveryEquipmentModalOpen(false);
          setDetailModalOpen(true);
        }}
      />
    </Box>
  );
};

export default OnDemandOrdersSubPage;
