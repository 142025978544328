import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type NewStandingOrderEquipmentRequest = {
  standingOrderId: string;
  equipmentId: string;
  quantity: number;
  lastUpdatedUserId: string;
};

type NewStandingOrderEquipmentResponse = {
  standingOrderEquipmentId: number;
};

function newStandingOrderEquipment(
  request: NewStandingOrderEquipmentRequest
): Promise<NewStandingOrderEquipmentResponse> {
  return externalApi.post(`StandingOrderEquipment/new`, { json: request }).json();
}

function useNewStandingOrderEquipment() {
  const queryClient = useQueryClient();
  return useMutation(
    (request: NewStandingOrderEquipmentRequest) => newStandingOrderEquipment(request),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['StandingOrderEquipmentByStandingOrder']);
      },
    }
  );
}
export { useNewStandingOrderEquipment };
