import { createStyles, Stack, Text, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React from 'react';
import { HelpBox } from '../../../components';

const useStyles = createStyles((theme, _params) => ({
  card: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.md,
    padding: `${theme.spacing.sm} ${theme.spacing.lg}`,
  },
}));

type Props = {};

const ReportsHelpSubPage = (props: Props) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  return (
    <Stack align="stretch" p={25}>
      <Text color="dimmed" weight={500} size="lg" mb={-10}>
        Delveries Report
      </Text>
      <HelpBox title="Data Displayed by Deliveries Report">
        <Text style={{ whiteSpace: 'nowrap' }}>
          The Deliveries Report displays all of the delivery records for a given timeframe,
          delineated by delivery state (Pending, Undelivered, Delivered) and any equipment
          delivered.
        </Text>
      </HelpBox>
      <HelpBox title="Selecting a Report Target">
        <Text style={{ whiteSpace: 'nowrap' }}>
          The Report Target is the date and time of day targeted by the report.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Click "Select Report Target" at the top-middle of the page to select a date and either
          "AM", "PM", or "Full Day".
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Clicking the "Generate" button will generate a report of the selected Report Target.
        </Text>
      </HelpBox>
      <HelpBox title="Explaining Report Organization vs. Delivery Schedule">
        <Text style={{ whiteSpace: 'nowrap' }}>
          Unlike the Delivery Schedule, which displays delivery records based on original
          schedulling, the Deliveries Report display delivery records based on actual delivery date
          and time
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          for completed deliveries, the date and time the delivery was marked as undelivered for
          undelivered deliveries, and the the requested delivery date and time for pending
          deliveries.
        </Text>
      </HelpBox>
      <HelpBox title="Generating a PDF">
        <Text style={{ whiteSpace: 'nowrap' }}>
          To generate a PDF of a report, click "Generate PDF" in the top-right of the page.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          This will generate a PDF of the report in a new tab that can be downloaded, saved, and
          printed.
        </Text>
      </HelpBox>
    </Stack>
  );
};

export default ReportsHelpSubPage;
