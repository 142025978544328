import React from 'react';
import { MantineProvider } from '@mantine/core';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ModalsProvider } from '@mantine/modals';
import { PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { MsalProvider, MsalAuthenticationTemplate } from '@azure/msal-react';
import { msalConfig, loginRequest } from './authConfig';
import { PageRoutes } from './common/Layout/PageRoutes';

const msalInstance = new PublicClientApplication(msalConfig);
const authRequest = {
  ...loginRequest,
};

const queryClient = new QueryClient();

const App = () => (
  <MsalProvider instance={msalInstance}>
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
    >
      <MantineProvider withNormalizeCSS withGlobalStyles theme={{ primaryColor: 'blue' }}>
        <QueryClientProvider client={queryClient}>
          <ModalsProvider>
            <BrowserRouter>
              <PageRoutes />
            </BrowserRouter>
          </ModalsProvider>
        </QueryClientProvider>
      </MantineProvider>
    </MsalAuthenticationTemplate>
  </MsalProvider>
);

export default App;
