import {
  ActionIcon,
  Box,
  Button,
  Center,
  createStyles,
  Divider,
  Group,
  Modal,
  ScrollArea,
  Stack,
  Table,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from '@mantine/hooks';
import { useFundingTypes, useEditFundingType, useNewFundingType } from '../../../hooks';
import { IFundingType } from '../../../models';
import { Edit, Plus, X } from 'tabler-icons-react';

const useStyles = createStyles((theme) => ({
  label: {
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
    fontWeight: 500,
  },
  fieldGroup: {
    width: '48%',
  },
  tableTitle: {
    borderTop: `1px solid ${theme.colors.gray[4]}`,
    borderBottom: `1px solid ${theme.colors.gray[4]}`,
    backgroundColor: `${theme.colors.gray[1]}`,
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

type FundingTypeDisplayModalProps = {
  opened: boolean;
  onClose: () => void;
};

const FundingTypeDisplayModal = (props: FundingTypeDisplayModalProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const [isError, setIsError] = useState<boolean>(false);
  const [flashMessage, setFlashMessage] = useState<string>('');

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [fundingTypeId, setFundingTypeId] = useState<string>('');
  const [fundingTypeName, setFundingTypeName] = useState<string>('');

  const fundingTypes = useFundingTypes();

  const newFundingType = useNewFundingType();
  const editFundingType = useEditFundingType();

  const fundingTypeRows = (fundingTypes.data ?? []).map((a: IFundingType) => {
    return (
      <tr key={a.fundingTypeId}>
        <td style={{ width: '50%', wordWrap: 'break-word', textAlign: 'center' }}>{a.name}</td>
        <td>
          <Group noWrap position="center" spacing={35}>
            <ActionIcon
              onClick={() => {
                setIsError(false);
                setFlashMessage('');
                setIsEditing(true);
                setFundingTypeId(a.fundingTypeId);
                setFundingTypeName(a.name);
              }}
            >
              <Edit style={{ color: theme.colors.blue[6] }} />
            </ActionIcon>
          </Group>
        </td>
      </tr>
    );
  });

  useEffect(() => {
    if (newFundingType.isSuccess) {
      setIsError(false);
      setFlashMessage('Funding Type Successfully Added!');
      setIsEditing(false);
      setFundingTypeId('');
      setFundingTypeName('');
    }
    if (newFundingType.isError) {
      setIsError(true);
      setFlashMessage('Something went wrong, Funding Type not Added. Please try again');
    }
  }, [newFundingType.status]);

  useEffect(() => {
    if (editFundingType.isSuccess) {
      setIsError(false);
      setFlashMessage('Funding Type Successfully Updated!');
      setIsEditing(false);
      setFundingTypeId('');
      setFundingTypeName('');
    }
    if (editFundingType.isError) {
      setIsError(true);
      setFlashMessage('Something went wrong, Funding Type not Updated. Please try again');
    }
  }, [editFundingType.status]);

  return (
    <Modal
      title={<Text style={{ color: `${isError ? 'red' : 'green'}` }}>{flashMessage}</Text>}
      opened={props.opened}
      size={isSmallMobile ? 'xs' : 'md'}
      onClose={() => {
        setIsError(false);
        setFlashMessage('');
        setIsEditing(false);
        setFundingTypeId('');
        setFundingTypeName('');
        props.onClose();
      }}
    >
      <Box>
        <Center>
          <Text size="xl" weight={500} align="center">
            Funding Types
          </Text>
        </Center>
        <Divider mt={10} />
        <ScrollArea type="hover" style={{ height: '250px', tableLayout: 'fixed' }}>
          {fundingTypeRows[0] && (
            <Table style={{ tableLayout: 'fixed', width: '100%' }}>
              <tbody>{fundingTypeRows}</tbody>
            </Table>
          )}
          {!fundingTypeRows[0] && (
            <Text weight={500} align="center">
              Nothing Found
            </Text>
          )}
        </ScrollArea>
        <Divider mb={10} />
        <Stack align="stretch" spacing={5}>
          <TextInput
            value={fundingTypeName}
            onChange={(e) => {
              setFundingTypeName(e.target.value);
            }}
            rightSection={
              <ActionIcon
                size={20}
                onClick={() => {
                  setIsEditing(false);
                  setFundingTypeId('');
                  setFundingTypeName('');
                  setIsError(false);
                  setFlashMessage('');
                }}
              >
                <X />
              </ActionIcon>
            }
          />
          {!isEditing && (
            <Button
              variant="outline"
              onClick={() => {
                if (fundingTypeName.length > 0) newFundingType.mutate({ name: fundingTypeName });
              }}
            >
              <Plus /> Add a New Funding Type
            </Button>
          )}
          {isEditing && (
            <Button
              variant="outline"
              onClick={() => {
                if (fundingTypeName.length > 0)
                  editFundingType.mutate({ fundingTypeId: fundingTypeId, name: fundingTypeName });
              }}
            >
              <Edit /> Update Funding Type Name
            </Button>
          )}
        </Stack>
      </Box>
    </Modal>
  );
};

export default FundingTypeDisplayModal;
