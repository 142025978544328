import { useQuery } from 'react-query';
import externalApi from '../../api';
import { IDelivery } from '../../models';

type IncompleteDeliveriesByDateRequest = {
  date: Date;
};

function incompleteDeliveriesByDate(
  request: IncompleteDeliveriesByDateRequest
): Promise<IDelivery[]> {
  return externalApi.post(`Delivery/ByDate/IncompleteDeliveries`, { json: request }).json();
}

function useIncompleteDeliveriesByDate(request: IncompleteDeliveriesByDateRequest) {
  return useQuery(['IncompleteByDate'], () => incompleteDeliveriesByDate(request));
}
export { useIncompleteDeliveriesByDate };
