import {
  ActionIcon,
  Box,
  Button,
  Center,
  Checkbox,
  createStyles,
  Group,
  Menu,
  Modal,
  Select,
  Stack,
  Text,
  Textarea,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { DatePickerInput } from '@mantine/dates';
import NumberFormat from 'react-number-format';
import React, { useEffect, useState } from 'react';
import { Dots, Pencil, Plus, Trash, UserCheck, UserX, X } from 'tabler-icons-react';
import {
  useCities,
  useFundingTypes,
  useEditPatient,
  useDeletePatient,
  useReactivatePatient,
  useUserId,
  useDeactivatePatient,
  useIsAdmin,
} from '../../../hooks';
import { IPatient } from '../../../models';
import { useMediaQuery } from '@mantine/hooks';
import { useModals } from '@mantine/modals';
import { DialogModal } from '../..';

const useStyles = createStyles((theme) => ({
  label: {
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
    fontWeight: 500,
  },
  fieldGroup: {
    width: '48%',
  },
  checkFieldGroup: {
    width: 80,
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      '&:last-of-type': {
        marginTop: 5,
      },
      '&:nth-last-of-type(2n)': {
        marginTop: 5,
      },
    },
    [`@media (min-width: ${theme.breakpoints.sm})`]: {
      '&:first-of-type': {
        marginRight: 25,
      },
    },
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

type PatientEditModalProps = {
  patient: IPatient;
  opened: boolean;
  onClose: () => void;
  onEdited: (isUpdated: boolean, isError: boolean, initialPatient: IPatient) => void;
  onDeleted: () => void;
  onReactivated: () => void;
  onDeactivated: () => void;
  onAddCity: () => void;
  onAddFundingType: () => void;
};

const PatientEditModal = (props: PatientEditModalProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const modals = useModals();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);
  const is333px = useMediaQuery(`(max-width: 333px)`);

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const currentUserId = useUserId();
  const isAdmin = useIsAdmin();

  const editPatient = useEditPatient();

  const cities = useCities();
  const fundingTypes = useFundingTypes();

  const deletePatient = useDeletePatient();
  const deactivatePatient = useDeactivatePatient();
  const reactivatePatient = useReactivatePatient();

  const openReactivateModal = () =>
    modals.openConfirmModal({
      title: 'Reactivate Patient',
      centered: true,
      children: <Text size="sm">Are you sure you want to Reactivate this Patient?</Text>,
      labels: { confirm: 'Reactivate', cancel: "No, don't Reactivate" },
      confirmProps: { color: 'blue' },
      onCancel: () => {},
      onConfirm: () => {
        handleReactivate();
      },
      zIndex: 999,
    });

  const openDeactivateModal = () =>
    modals.openConfirmModal({
      title: 'Deactivate Patient',
      centered: true,
      children: <Text size="sm">Are you sure you want to Deactivate this Patient?</Text>,
      labels: { confirm: 'Deactivate', cancel: "No, don't Deactivate" },
      confirmProps: { color: 'blue' },
      onCancel: () => {},
      onConfirm: () => {
        handleDeactivate();
      },
      zIndex: 999,
    });

  const handleDeactivate = () => {
    deactivatePatient.mutate({
      patientId: props.patient.patientId,
      lastUpdatedUserId: currentUserId.data!.userId,
    });
  };

  const handleDelete = () => {
    deletePatient.mutate({
      patientId: props.patient.patientId,
      lastUpdatedUserId: currentUserId.data!.userId,
    });
  };

  const handleReactivate = () => {
    reactivatePatient.mutate({ patientId: props.patient.patientId });
  };

  const [submitClicked, setSubmitClicked] = useState<boolean>(false);
  const initialPatient = {
    patientId: props.patient.patientId,
    firstName: props.patient.firstName,
    lastName: props.patient.lastName,
    fullName: props.patient.fullName,
    fullNameFormatted: props.patient.fullNameFormatted,
    phoneNumber: props.patient.phoneNumber,
    phoneNumberFormatted: props.patient.phoneNumberFormatted,
    streetAddress: props.patient.streetAddress,
    cityId: props.patient.cityId.toString(),
    cityName: props.patient.cityName,
    lastFourHCN: props.patient.lastFourHCN,
    claimNumber: props.patient.claimNumber,
    yearlyAppointment: props.patient.yearlyAppointment,
    covid: props.patient.covid,
    lakeridgeGardens: props.patient.lakeridgeGardens,
    hospicePT: props.patient.hospicePT,
    fundingTypeId: props.patient.fundingTypeId.toString(),
    fundingTypeName: props.patient.fundingTypeName,
    startDate: new Date(props.patient.startDate ?? new Date()),
    dischargeDate: new Date(props.patient.dischargeDate ?? new Date()),
    deathDate: new Date(props.patient.deathDate ?? new Date()),
    notes: props.patient.notes,
    active: props.patient.active,
    lastUpdatedUserId: props.patient.lastUpdatedUserId,
    lastUpdatedDateTime: props.patient.lastUpdatedUserDateTime,
  };

  const editForm = useForm<IPatient>({
    initialValues: initialPatient,
    validate: {
      firstName: (a) =>
        a.length > 75 ? "Patient's First Name must be a maximum of 75 characters" : null,
      lastName: (a) =>
        a.length > 75 ? "Patient's Last Name must be a maximum of 75 characters" : null,
      phoneNumber: (a) => (a.includes('_') ? "Patient's Phone Number must be 10 digits" : null),
      streetAddress: (a) =>
        a.length > 150 ? "Patient's Street Address must be a maximum of 150 characters" : null,
      lastFourHCN: (a) =>
        a.length !== 4
          ? "Must only be the Last Four Digits of the Patient's Health Card Number"
          : null,
      claimNumber: (a) =>
        a.length > 9 ? "Patient's Unique Number must be a maximum of 9 characters" : null,
    },
  });

  async function submitPatientUpdate() {
    setSubmitClicked(true);

    editPatient.mutate({
      patientId: editForm.values.patientId,
      firstName: editForm.values.firstName,
      lastName: editForm.values.lastName,
      phoneNumber: editForm.values.phoneNumber.replace(/\D/g, ''),
      streetAddress: editForm.values.streetAddress,
      cityId: editForm.values.cityId,
      lastFourHCN: editForm.values.lastFourHCN,
      claimNumber: editForm.values.claimNumber,
      yearlyAppointment: editForm.values.yearlyAppointment,
      covid: editForm.values.covid,
      lakeridgeGardens: editForm.values.lakeridgeGardens,
      hospicePT: editForm.values.hospicePT,
      fundingTypeId: editForm.values.fundingTypeId,
      startDate: editForm.values.startDate,
      dischargeDate: editForm.values.dischargeDate,
      deathDate: editForm.values.deathDate,
      notes: editForm.values.notes,
      lastUpdatedUserId: currentUserId.data!.userId,
    });
  }

  useEffect(() => {
    editForm.setValues(props.patient);
  }, [props.patient, props.opened]);

  useEffect(() => {
    if (submitClicked) {
      if (editPatient.isSuccess) {
        setSubmitClicked(false);
        setIsError(false);
        setErrorMessage('');
        props.onEdited(true, false, {
          patientId: editForm.values.patientId,
          firstName: editForm.values.firstName,
          lastName: editForm.values.lastName,
          fullName: `${editForm.values.firstName} ${editForm.values.lastName}`,
          fullNameFormatted: `${editForm.values.lastName}, ${editForm.values.firstName}`,
          phoneNumber: editForm.values.phoneNumber.replace(/\D/g, ''),
          phoneNumberFormatted: '',
          streetAddress: editForm.values.streetAddress,
          cityId: editForm.values.cityId,
          cityName: cities.data!.find(
            (a) => a.cityId.toString() === editForm.values.cityId.toString()
          )!.name,
          lastFourHCN: editForm.values.lastFourHCN,
          claimNumber: editForm.values.claimNumber,
          yearlyAppointment: editForm.values.yearlyAppointment,
          covid: editForm.values.covid,
          lakeridgeGardens: editForm.values.lakeridgeGardens,
          hospicePT: editForm.values.hospicePT,
          fundingTypeId: editForm.values.fundingTypeId,
          fundingTypeName: fundingTypes.data!.find(
            (a) => a.fundingTypeId.toString() === editForm.values.fundingTypeId.toString()
          )!.name,
          startDate: editForm.values.startDate,
          dischargeDate: editForm.values.dischargeDate,
          deathDate: editForm.values.deathDate,
          notes: editForm.values.notes,
          active: props.patient.active,
          lastUpdatedUserId: currentUserId.data!.userId,
          lastUpdatedDateTime: new Date(),
        });
      } else if (editPatient.isError) {
        setSubmitClicked(false);
        setIsError(false);
        setErrorMessage('');
        props.onEdited(false, true, initialPatient);
      }
    }
  }, [editPatient.status]);

  useEffect(() => {
    if (deletePatient.isSuccess) {
      setIsError(false);
      setErrorMessage('');
      props.onDeleted();
    }
    if (deletePatient.isError) {
      setIsError(true);
      setErrorMessage('Something went wrong, Patient not Deleted. Please try again');
    }
  }, [deletePatient.status]);

  useEffect(() => {
    if (deactivatePatient.isSuccess) {
      setIsError(false);
      setErrorMessage('');
      props.onDeactivated();
    }
    if (deactivatePatient.isError) {
      setIsError(true);
      setErrorMessage('Something went wrong, Patient not Deactivated. Please try again');
    }
  }, [deactivatePatient.status]);

  useEffect(() => {
    if (reactivatePatient.isSuccess) {
      setIsError(false);
      setErrorMessage('');
      props.onReactivated();
    }
    if (reactivatePatient.isError) {
      setIsError(true);
      setErrorMessage('Something went wrong, Patient not Reactivated. Please try again');
    }
  }, [reactivatePatient.status]);

  return (
    <Modal
      title={isError ? <Text color="red">{errorMessage}</Text> : ''}
      opened={props.opened}
      size={isSmallMobile ? 'xs' : 'md'}
      onClose={() => {
        setIsError(false);
        setErrorMessage('');
        props.onClose();
      }}
    >
      <Box>
        <Center>
          <Group noWrap spacing={!is333px ? 'md' : 0} ml={!isSmallMobile ? 25 : 0}>
            <Text size="xl" weight={500} align="center">
              Edit Patient Information
            </Text>
            <Menu>
              <Menu.Target>
                <ActionIcon>
                  <Dots />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item
                  icon={<Plus color={`${theme.colors.blue[6]}`} />}
                  onClick={() => {
                    props.onAddCity();
                  }}
                >
                  Add City
                </Menu.Item>
                <Menu.Item
                  icon={<Plus color={`${theme.colors.blue[6]}`} />}
                  onClick={() => {
                    props.onAddFundingType();
                  }}
                >
                  Add Funding Type
                </Menu.Item>
                {props.patient.active && (
                  <Menu.Item
                    icon={<UserX color={`${theme.colors.red[6]}`} />}
                    onClick={() => {
                      openDeactivateModal();
                    }}
                  >
                    Mark Patient Inactive
                  </Menu.Item>
                )}
                {!props.patient.active && (
                  <Menu.Item
                    icon={<UserCheck color={`${theme.colors.blue[6]}`} />}
                    onClick={() => {
                      openReactivateModal();
                    }}
                  >
                    Reactivate Patient
                  </Menu.Item>
                )}
                {isAdmin.data?.isAdmin && (
                  <Menu.Item
                    icon={<Trash color={`${theme.colors.red[6]}`} />}
                    onClick={() => {
                      setDeleteModalOpen(true);
                    }}
                  >
                    Delete Patient
                  </Menu.Item>
                )}
              </Menu.Dropdown>
            </Menu>
          </Group>
        </Center>
        <form onSubmit={editForm.onSubmit(() => submitPatientUpdate())} autoComplete="off">
          <Stack align="stretch" spacing={5} style={{ padding: 5 }}>
            <Group noWrap>
              <Stack spacing={0} className={classes.fieldGroup}>
                <Text className={classes.label}>Patient Last Name</Text>
                <TextInput
                  placeholder={!isSmallMobile ? "Patient's Last Name" : 'Last Name'}
                  required
                  autoComplete="nope"
                  {...editForm.getInputProps('lastName')}
                />
              </Stack>
              <Stack spacing={0} className={classes.fieldGroup}>
                <Text className={classes.label}>Patient First Name</Text>
                <TextInput
                  placeholder={!isSmallMobile ? "Patient's First Name" : 'First Name'}
                  required
                  autoComplete="nope"
                  {...editForm.getInputProps('firstName')}
                />
              </Stack>
            </Group>

            <Group noWrap>
              <Stack spacing={0} className={classes.fieldGroup}>
                <Text className={classes.label}>Last 4 Digits of{`\n`}Health Card Number</Text>
                <Group noWrap spacing={1}>
                  <Text size="md" color="dimmed">
                    #
                  </Text>
                  <TextInput
                    placeholder={!isSmallMobile ? 'Last Four HCN Digits' : 'Last 4 HCN'}
                    required
                    {...editForm.getInputProps('lastFourHCN')}
                  />
                </Group>
              </Stack>
              <Stack spacing={0} className={classes.fieldGroup}>
                <Text className={classes.label}>{`\n`}Unique Number</Text>
                <Group noWrap spacing={1}>
                  <Text size="md" color="dimmed">
                    #
                  </Text>
                  <TextInput
                    placeholder={!isSmallMobile ? "Patient's Unique Number" : 'Unique #'}
                    required
                    {...editForm.getInputProps('claimNumber')}
                  />
                </Group>
              </Stack>
            </Group>

            <Stack spacing={0} className={classes.fieldGroup}>
              <Text className={classes.label}>Phone Number</Text>
              <NumberFormat
                format="(###) ###-####"
                allowEmptyFormatting
                mask="_"
                customInput={TextInput}
                autoComplete="nope"
                required
                {...editForm.getInputProps('phoneNumber')}
              />
            </Stack>

            <Group noWrap>
              <Stack spacing={0} className={classes.fieldGroup}>
                <Text className={classes.label}>Street Address</Text>
                <TextInput
                  placeholder={!isSmallMobile ? "Patient's Street Address" : 'Address'}
                  required
                  autoComplete="nope"
                  {...editForm.getInputProps('streetAddress')}
                />
              </Stack>
              <Stack spacing={0} className={classes.fieldGroup}>
                <Text className={classes.label}>City</Text>
                <Select
                  data={
                    cities.data?.map((a) => ({
                      value: `${a.cityId}`,
                      label: `${a.name}`,
                    })) ?? []
                  }
                  defaultValue={props.patient.cityId.toString()}
                  placeholder={!isSmallMobile ? "Select Patient's City" : 'City'}
                  searchable={!isSmallMobile}
                  required
                  autoComplete="nope"
                  onChange={(a) => {
                    editForm.setFieldValue('cityId', a ?? '0');
                  }}
                />
              </Stack>
            </Group>

            <Stack spacing={0} className={classes.fieldGroup}>
              <Text className={classes.label}>Funding Type</Text>
              <Select
                data={
                  fundingTypes.data?.map((a) => ({
                    value: `${a.fundingTypeId}`,
                    label: `${a.name}`,
                  })) ?? []
                }
                defaultValue={props.patient.fundingTypeId.toString()}
                placeholder={!isSmallMobile ? 'Select Funding Type' : 'Funding'}
                searchable={!isSmallMobile}
                required
                onChange={(a) => {
                  editForm.setFieldValue('fundingTypeId', a ?? '0');
                }}
              />
            </Stack>

            <Group position="center" noWrap={!isSmallMobile} spacing={!isSmallMobile ? 25 : 0}>
              <Group position="center" grow noWrap spacing={!isSmallMobile ? 0 : 25}>
                <Stack align="stretch" spacing={5} className={classes.checkFieldGroup}>
                  <Text
                    className={classes.label}
                    align="center"
                    style={{ marginLeft: `${!isSmallMobile ? '' : '-4px'}` }}
                  >
                    Yearly{!isSmallMobile ? `\n` : ' '}Appointment
                  </Text>
                  <Center>
                    <Checkbox
                      defaultChecked={props.patient.yearlyAppointment}
                      {...editForm.getInputProps('yearlyAppointment')}
                    />
                  </Center>
                </Stack>
                <Stack align="stretch" spacing={5} className={classes.checkFieldGroup}>
                  <Text className={classes.label} align="center">
                    {!isSmallMobile ? `\n` : ''}Covid
                  </Text>
                  <Center>
                    <Checkbox
                      defaultChecked={props.patient.covid}
                      {...editForm.getInputProps('covid')}
                    />
                  </Center>
                </Stack>
              </Group>
              <Group position="center" grow noWrap spacing={!isSmallMobile ? 0 : 25}>
                <Stack align="stretch" spacing={5} className={classes.checkFieldGroup}>
                  <Text className={classes.label} align="center">
                    Lakeridge{!isSmallMobile ? `\n` : ' '}Gardens
                  </Text>
                  <Center>
                    <Checkbox
                      defaultChecked={props.patient.lakeridgeGardens}
                      {...editForm.getInputProps('lakeridgeGardens')}
                    />
                  </Center>
                </Stack>
                <Stack align="stretch" spacing={5} className={classes.checkFieldGroup}>
                  <Text className={classes.label} align="center">
                    {!isSmallMobile ? `\n` : ''}Hospice PT
                  </Text>
                  <Center>
                    <Checkbox
                      defaultChecked={props.patient.hospicePT}
                      {...editForm.getInputProps('hospicePT')}
                    />
                  </Center>
                </Stack>
              </Group>
            </Group>

            <Group position="apart" spacing={0}>
              <Stack spacing={0} className={classes.fieldGroup}>
                <Text className={classes.label}>Start Date</Text>
                <DatePickerInput
                  valueFormat="YYYY-MM-DD"
                  firstDayOfWeek={0}
                  defaultValue={
                    props.patient.startDate ? new Date(props.patient.startDate) : undefined
                  }
                  required
                  dropdownType="modal"
                  modalProps={{
                    title: 'Start Date',
                    withCloseButton: true,
                    closeButtonProps: { style: { position: 'fixed', right: 10 } },
                    yOffset: '15vh',
                    styles: { title: { fontWeight: 500, textAlign: 'center', width: '100%' } },
                  }}
                  onChange={(a) => {
                    editForm.setFieldValue('startDate', a !== null ? a : undefined);
                  }}
                />
              </Stack>
              <Stack spacing={0} className={classes.fieldGroup}>
                <Text className={classes.label}>Discharge Date</Text>
                <DatePickerInput
                  valueFormat="YYYY-MM-DD"
                  firstDayOfWeek={0}
                  defaultValue={
                    props.patient.dischargeDate ? new Date(props.patient.dischargeDate) : undefined
                  }
                  clearable
                  dropdownType="modal"
                  modalProps={{
                    title: 'Discharge Date',
                    withCloseButton: true,
                    closeButtonProps: { style: { position: 'fixed', right: 10 } },
                    yOffset: '15vh',
                    styles: { title: { fontWeight: 500, textAlign: 'center', width: '100%' } },
                  }}
                  onChange={(a) => {
                    editForm.setFieldValue('dischargeDate', a !== null ? a : undefined);
                  }}
                />
              </Stack>
              <Stack spacing={0} className={classes.fieldGroup}>
                <Text className={classes.label}>Death Date</Text>
                <DatePickerInput
                  valueFormat="YYYY-MM-DD"
                  firstDayOfWeek={0}
                  clearable
                  dropdownType="modal"
                  modalProps={{
                    title: 'Death Date',
                    withCloseButton: true,
                    closeButtonProps: { style: { position: 'fixed', right: 10 } },
                    yOffset: '15vh',
                    styles: { title: { fontWeight: 500, textAlign: 'center', width: '100%' } },
                  }}
                  defaultValue={
                    props.patient.deathDate ? new Date(props.patient.deathDate) : undefined
                  }
                  onChange={(a) => {
                    editForm.setFieldValue('deathDate', a !== null ? a : undefined);
                  }}
                />
              </Stack>
            </Group>

            <Stack align="stretch" spacing={0}>
              <Text className={classes.label}>Notes</Text>
              <Textarea minRows={4} {...editForm.getInputProps('notes')} />
            </Stack>
          </Stack>

          <Group position="apart" grow noWrap mt="md">
            <Button variant="outline" type="submit">
              <Pencil style={{ marginRight: 5 }} />{' '}
              <Text className={classes.buttonText}>{`Update ${
                !isSmallMobile ? 'Patient' : ''
              }`}</Text>
            </Button>
            <Button
              variant="outline"
              color="red"
              onClick={() => {
                setIsError(false);
                setErrorMessage('');
                props.onClose();
              }}
            >
              <X style={{ marginRight: 5 }} /> <Text className={classes.buttonText}>Cancel</Text>
            </Button>
          </Group>
        </form>
      </Box>
      <DialogModal
        isOpen={deleteModalOpen}
        isNegative
        titleText="Delete Patient?"
        body={
          <Text align="center" color="dimmed" size="lg" mb="xs">
            Are you sure you want to Delete this Patient?
          </Text>
        }
        confirmText="Delete"
        onConfirm={() => {
          setDeleteModalOpen(false);
          handleDelete();
        }}
        onClose={() => {
          setDeleteModalOpen(false);
        }}
      />
    </Modal>
  );
};

export default PatientEditModal;
