import {
  Box,
  Button,
  Center,
  createStyles,
  Divider,
  Modal,
  ScrollArea,
  Stack,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from '@mantine/hooks';
import { Search, X } from 'tabler-icons-react';
import { EquipmentDetailCard } from '../../../components';
import {
  useDelivery,
  useDeliveryEquipmentByDeliveryMutate,
  useOrderEquipmentByOrderMutate,
  useStandingOrderEquipmentByStandingOrderMutate,
  useTrackedPatientEquipmentByDeliveryMutate,
  useUndeliveredEquipmentByDeliveryMutate,
} from '../../../hooks';
import dayjs from 'dayjs';

const useStyles = createStyles((theme) => ({
  equipmentBox: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    paddingLeft: 3,
    paddingTop: 3,
    paddingBottom: 3,
    paddingRight: theme.spacing.xs,
    width: '100%',
    height: '70',
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

type DeliveryEquipmentModalProps = {
  recordId: string;
  recordType: 'order' | 'standingOrder' | 'delivery' | undefined;
  opened: boolean;
  onClose: () => void;
};

const DeliveryEquipmentModal = (props: DeliveryEquipmentModalProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const [isClosing, setIsClosing] = useState<boolean>(false);

  const [search, setSearch] = useState<string>('');
  const [filteredCards, setFilteredCards] = useState<JSX.Element[]>([]);

  const orderEquipment = useOrderEquipmentByOrderMutate();
  const standingOrderEquipment = useStandingOrderEquipmentByStandingOrderMutate();
  const deliveryEquipment = useDeliveryEquipmentByDeliveryMutate();
  const trackedDeliveryEquipment = useTrackedPatientEquipmentByDeliveryMutate();
  const undeliveredEquipment = useUndeliveredEquipmentByDeliveryMutate();

  const delivery = useDelivery();

  const orderCards = (orderEquipment.data ?? []).map((a) => {
    return (
      <EquipmentDetailCard
        key={`${a.orderEquipmentId}o`}
        equipmentName={a.equipmentName}
        equipmentTypeName={a.equipmentTypeName}
        quantity={a.quantity}
        readOnly
      />
    );
  });

  const standingOrderCards = (standingOrderEquipment.data ?? []).map((a) => {
    return (
      <EquipmentDetailCard
        key={`${a.standingOrderEquipmentId}so`}
        equipmentName={a.equipmentName}
        equipmentTypeName={a.equipmentTypeName}
        quantity={a.quantity}
        readOnly
      />
    );
  });

  const untrackedDeliveryCards = (deliveryEquipment.data ?? []).map((a) => {
    return (
      <EquipmentDetailCard
        key={`${a.deliveryEquipmentId}ut`}
        equipmentName={a.equipmentName}
        equipmentTypeName={a.equipmentTypeName}
        quantity={a.quantity}
        serialNumber={a.serialNumber}
        showSerialNumber
        readOnly
      />
    );
  });

  const trackedDeliveryCards = (trackedDeliveryEquipment.data ?? []).map((a) => {
    return (
      <EquipmentDetailCard
        key={`${a.trackedPatientEquipmentId}t`}
        equipmentName={a.equipmentName}
        equipmentTypeName={a.equipmentTypeName}
        quantity={1}
        notes={a.deliveryNotes}
        serialNumber={a.serialNumber}
        deliveredDate={a.deliveredDateTime}
        trackedPatientEquipmentId={a.trackedPatientEquipmentId}
        isTracked
        showSerialNumber
        showNotesButton
        readOnly
      />
    );
  });

  const undeliveredCards = (undeliveredEquipment.data ?? []).map((a) => {
    return (
      <EquipmentDetailCard
        key={`${a.undeliveredEquipmentId}ud`}
        equipmentName={a.equipmentName}
        equipmentTypeName={a.equipmentTypeName}
        quantity={1}
        notes={a.notes}
        serialNumber="Not Delivered"
        undeliveredEquipmentId={a.undeliveredEquipmentId}
        showSerialNumber
        showNotesButton
        readOnly
        isUndelivered
      />
    );
  });

  const deliveryCards = [
    ...untrackedDeliveryCards,
    ...trackedDeliveryCards,
    ...undeliveredCards,
  ].sort((a, b) => {
    return (
      new Date(b.props.lastUpdatedDateTime as string).valueOf() -
      new Date(a.props.lastUpdatedDateTime as string).valueOf()
    );
  });

  const getCards = () => {
    if (props.recordType === 'delivery') {
      if (delivery.data?.isDelivered) {
        return deliveryCards;
      } else {
        if (delivery.data?.orderId) {
          return orderCards;
        }
        if (delivery.data?.standingOrderId) {
          return standingOrderCards;
        }
      }
    }
    if (props.recordType === 'order') {
      return orderCards;
    }
    if (props.recordType === 'standingOrder') {
      return standingOrderCards;
    }

    return [];
  };

  const cards = getCards();

  const filterCards = (value: string) => {
    setFilteredCards(
      cards.filter((a) =>
        `${a.props.equipmentName} ${a.props.equipmentTypeName} ${a.props.serialNumber ?? ''} ${
          a.props.deliveredDate ? dayjs(a.props.deliveredDate).format('YYYY-MM-DD') : ''
        } ${!a.props.isTracked ? a.props.quantity : ''}`
          .toLowerCase()
          .includes(value.toLowerCase())
      )
    );
  };

  useEffect(() => {
    setIsClosing(false);

    if (props.recordType === 'order') {
      orderEquipment.mutate(props.recordId);
    }
    if (props.recordType === 'standingOrder') {
      standingOrderEquipment.mutate(props.recordId);
    }
    if (props.recordType === 'delivery') {
      delivery.mutate(props.recordId);
      deliveryEquipment.mutate(props.recordId);
      trackedDeliveryEquipment.mutate(props.recordId);
      undeliveredEquipment.mutate(props.recordId);
    }
  }, [props.opened, props.recordId, props.recordType]);

  useEffect(() => {
    if (delivery.data?.orderId) {
      orderEquipment.mutate(delivery.data.orderId);
    }
    if (delivery.data?.standingOrderId) {
      standingOrderEquipment.mutate(delivery.data.standingOrderId);
    }
  }, [delivery.status]);

  return (
    <Modal
      opened={props.opened}
      size={isSmallMobile ? 'xs' : 'md'}
      onClose={() => {
        setIsClosing(true);
        props.onClose();
      }}
      styles={{
        content: {
          border: `${
            props.recordType !== 'delivery'
              ? ''
              : isClosing
              ? ''
              : delivery.data
              ? delivery.data.isDelivered
                ? `3px solid ${theme.colors.blue[6]}`
                : `3px solid ${theme.colors.red[5]}`
              : ''
          }`,
        },
      }}
    >
      <Box>
        <Center>
          <Text size="xl" weight={500} align="center">
            Delivery Equipment
          </Text>
        </Center>
        <Divider my="xs" />
        <TextInput
          placeholder="Search by any field"
          mb="xs"
          icon={<Search size={14} />}
          value={search}
          onChange={(e) => {
            setSearch(e.currentTarget.value);
            filterCards(e.currentTarget.value);
          }}
        />
        <Stack align="stretch" style={{ width: '100%' }} mt="sm">
          <ScrollArea style={{ height: '300px' }} pr="sm">
            {cards[0] && search.length > 0 && filteredCards}
            {cards[0] && search.length === 0 && cards}
            {!cards[0] && search.length === 0 && (
              <Text weight={500} align="center">
                Nothing Found
              </Text>
            )}
            {!filteredCards[0] && search.length > 0 && (
              <Text weight={500} align="center">
                Nothing Found
              </Text>
            )}
          </ScrollArea>
          <Divider mt="xs" />
          <Button
            variant="outline"
            onClick={() => {
              setIsClosing(true);
              props.onClose();
            }}
          >
            <X style={{ marginRight: 5 }} />
            <Text className={classes.buttonText}>Close</Text>
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default DeliveryEquipmentModal;
