import { Box, Text } from '@mantine/core';
import { useModals } from '@mantine/modals';
import React, { useEffect, useState } from 'react';
import {
  EquipmentCreateCard,
  EquipmentEditQuantityCard,
  EquipmentDetailCard,
  DialogModal,
} from '..';
import {
  useDeleteOrderEquipment,
  useEditOrderEquipmentQuantity,
  useNewOrderEquipment,
  useUserId,
} from '../../hooks';

type OrderEquipmentCardProps = {
  orderEquipmentId: string;
  orderId: string;
  equipmentId: string;
  equipmentName: string;
  equipmentTypeName: string;
  quantity: number;
  lastUpdatedUserId?: string;
  lastUpdatedDateTime?: Date;
  isEdit?: boolean;
  isCreate?: boolean;
  readOnly?: boolean;
  onEdit?: (isEdited: boolean, isError: boolean) => void;
  onDelete?: (isDeleted: boolean, isError: boolean) => void;
  onCreate?: (isCreated: boolean, isError: boolean) => void;
  onCreateCancel?: () => void;
  onOpen?: () => void;
};

const OrderEquipmentCard = (props: OrderEquipmentCardProps) => {
  const modals = useModals();

  const currentUserId = useUserId();

  const [detailCardOpen, setDetailCardOpen] = useState<boolean>(
    props.readOnly ? true : props.isCreate ? false : props.isEdit ? false : true
  );
  const [createCardOpen, setCreateCardOpen] = useState<boolean>(props.isCreate ?? false);
  const [editQuantityCardOpen, setEditQuantityCardOpen] = useState<boolean>(props.isEdit ?? false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const editOrderEquipmentQuantity = useEditOrderEquipmentQuantity();
  const newOrderEquipment = useNewOrderEquipment();
  const deleteOrderEquipment = useDeleteOrderEquipment();

  const handleUpdate = (newQuantity: number) => {
    editOrderEquipmentQuantity.mutate({
      orderEquipmentId: props.orderEquipmentId,
      quantity: newQuantity,
      lastUpdatedUserId: currentUserId.data!.userId,
    });
  };

  const handleCreate = (newQuantity: number) => {
    newOrderEquipment.mutate({
      orderId: props.orderId,
      equipmentId: props.equipmentId,
      quantity: newQuantity,
      lastUpdatedUserId: currentUserId.data!.userId,
    });
  };

  const handleDelete = () => {
    deleteOrderEquipment.mutate({ orderEquipmentId: props.orderEquipmentId });
  };

  useEffect(() => {
    if (props.onOpen) {
      props.onOpen();
    }
  }, [detailCardOpen, editQuantityCardOpen, createCardOpen]);

  useEffect(() => {
    if (props.onEdit) {
      if (editOrderEquipmentQuantity.isSuccess) {
        props.onEdit(true, false);
      }
      if (editOrderEquipmentQuantity.isError) {
        props.onEdit(false, true);
      }
    }
  }, [editOrderEquipmentQuantity.status]);

  useEffect(() => {
    if (props.onCreate) {
      if (newOrderEquipment.isSuccess) {
        props.onCreate(true, false);
      }
      if (newOrderEquipment.isError) {
        props.onCreate(false, true);
      }
    }
  }, [newOrderEquipment.status]);

  useEffect(() => {
    if (props.onDelete) {
      if (deleteOrderEquipment.isSuccess) {
        props.onDelete(true, false);
      }
      if (deleteOrderEquipment.isError) {
        props.onDelete(false, true);
      }
    }
  }, [deleteOrderEquipment.status]);

  return (
    <Box mr="sm">
      {detailCardOpen && (
        <EquipmentDetailCard
          equipmentName={props.equipmentName}
          equipmentTypeName={props.equipmentTypeName}
          quantity={props.quantity}
          lastUpdatedUserId={props.lastUpdatedUserId}
          lastUpdatedDateTime={props.lastUpdatedDateTime}
          showInfoButton
          onEditQuantity={() => {
            setDetailCardOpen(false);
            setEditQuantityCardOpen(true);
          }}
          onDelete={() => {
            setDeleteModalOpen(true);
          }}
          readOnly={props.readOnly}
        />
      )}
      {editQuantityCardOpen && (
        <EquipmentEditQuantityCard
          equipmentName={props.equipmentName}
          equipmentTypeName={props.equipmentTypeName}
          quantity={props.quantity}
          onClose={() => {
            setEditQuantityCardOpen(false);
            setDetailCardOpen(true);
          }}
          onSave={(newQuantity) => {
            handleUpdate(newQuantity);
            setEditQuantityCardOpen(false);
            setDetailCardOpen(true);
          }}
        />
      )}
      {createCardOpen && (
        <EquipmentCreateCard
          equipmentName={props.equipmentName}
          equipmentTypeName={props.equipmentTypeName}
          quantity={props.quantity}
          onClose={() => {
            if (props.onCreateCancel) {
              props.onCreateCancel();
            }
          }}
          onSave={(newQuantity) => {
            handleCreate(newQuantity);
          }}
        />
      )}
      <DialogModal
        isOpen={deleteModalOpen}
        isNegative
        titleText={'Delete Equipment?'}
        body={
          <Text align="center" color="dimmed" size="lg" mb="xs">
            Are you sure you want to delete this Equipment from this Order?
          </Text>
        }
        confirmText={'Delete Equipment'}
        onConfirm={() => {
          setDeleteModalOpen(false);
          handleDelete();
        }}
        onClose={() => {
          setDeleteModalOpen(false);
        }}
      />
    </Box>
  );
};

export default OrderEquipmentCard;
