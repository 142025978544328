import React from 'react';
import { useQuery } from 'react-query';
import externalApi from '../../api';
import { IWeekday } from '../../models';

function fetchWeekdays(): Promise<IWeekday[]> {
  return externalApi.get(`Weekday/all`).json();
}

export function useWeekdays() {
  return useQuery(['Weekdays'], () => fetchWeekdays());
}
