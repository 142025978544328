import React from 'react';
import { useQuery } from 'react-query';
import externalApi from '../../api';
import { IEquipment } from '../../models';

function fetchEquipmentForTracking(): Promise<IEquipment[]> {
  return externalApi.get(`Equipment/all/tracked`).json();
}

export function useEquipmentForTracking() {
  return useQuery(['EquipmentForTracking'], () => fetchEquipmentForTracking());
}
