import React from 'react';
import { useQuery } from 'react-query';
import externalApi from '../../api';
import { IPatientEquipment } from '../../models';

function fetchPatientEquipmentByPatient(patientId: string): Promise<IPatientEquipment[]> {
  return externalApi.get(`PatientEquipment/ByPatient/${patientId}`).json();
}

export function usePatientEquipmentByPatient(patientId: string) {
  return useQuery(['PatientEquipmentByPatient'], () => fetchPatientEquipmentByPatient(patientId));
}
