import {
  Box,
  Center,
  createStyles,
  useMantineTheme,
  Text,
  Group,
  Checkbox,
  ScrollArea,
  Button,
  Divider,
  Stack,
} from '@mantine/core';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { Check, ClipboardList, FileInvoice, X } from 'tabler-icons-react';
import { useOrder, usePageStore } from '../../../../hooks';
import { InfoButton, PatientNotes } from '../../../../components';
import { useNavigate } from 'react-router-dom';

const useStyles = createStyles((theme) => ({
  label: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
  },
  detail: {
    fontSize: theme.fontSizes.md,
    paddingLeft: 10,
  },
  fieldGroup: {
    width: '48%',
  },
  notesCard: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    paddingLeft: theme.spacing.sm,
    paddingRight: theme.spacing.sm,
    paddingTop: 3,
    width: '100%',
    height: '125px',
  },
  infoBox: {
    color: `${theme.colors.gray[6]}`,
    border: `1px solid ${theme.colors.gray[6]}`,
    borderRadius: theme.radius.lg,
    width: 'fit-content',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

type DeliveryOrderDetailModalContentProps = {
  selectedDate: Date;
  orderId: string;
  onClose: () => void;
  onViewEquipment: () => void;
  onDelivered: () => void;
  onUndelivered: () => void;
};

const DeliveryOrderDetailModalContent = (props: DeliveryOrderDetailModalContentProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const setOrdersSubPage = usePageStore((state) => state.setOrdersSubPage);

  const order = useOrder();

  useEffect(() => {
    order.mutate(props.orderId);
  }, [props.orderId]);

  return (
    <Box>
      {order.data && (
        <Box>
          <Center>
            <Text size="xl" weight={500} align="center">
              Pending Delivery Information
            </Text>
            <InfoButton
              userId={order.data.lastUpdatedUserId}
              date={order.data.lastUpdatedDateTime}
              ml={5}
            />
          </Center>
          {order.data.standingOrderReschedule && (
            <Center>
              <Group className={classes.infoBox} position="center" spacing="xs" my={5}>
                <Text weight={500} size="xs" align="center">
                  This is a Rescheduled Standing Order
                </Text>
              </Group>
            </Center>
          )}
          {order.data.deliveryReschedule && (
            <Center>
              <Group className={classes.infoBox} position="center" spacing="xs" my={5}>
                <Text weight={500} size="xs" align="center">
                  This is a Rescheduled Delivery
                </Text>
              </Group>
            </Center>
          )}
          {order.data.isUrgent && (
            <Center>
              <Group className={classes.infoBox} position="center" spacing="xs" my={5}>
                <Text weight={500} size="xs" align="center">
                  This is an Urgent Order
                </Text>
              </Group>
            </Center>
          )}

          <Stack align="stretch" style={{ padding: 5 }}>
            <Group>
              <Stack spacing={0} style={{ width: '50%' }}>
                <Text className={classes.label}>Patient Name</Text>
                <Group spacing={5} noWrap>
                  <Text
                    className={classes.detail}
                  >{`${order.data.patientLastName}, ${order.data.patientFirstName}`}</Text>
                </Group>
              </Stack>
              <Stack spacing={0}>
                <Text className={classes.label}>Phone number</Text>
                <Text className={classes.detail}>{`${order.data.patientPhoneNumber}`}</Text>
              </Stack>
            </Group>
            <Group>
              <Stack spacing={0} style={{ width: '50%' }}>
                <Text className={classes.label}>Delivery Address</Text>
                <Text className={classes.detail}>{`${order.data.patientAddress}`}</Text>
              </Stack>
              <Stack spacing={0}>
                <Text className={classes.label}>City</Text>
                <Text className={classes.detail}>{`${order.data.patientCity}`}</Text>
              </Stack>
            </Group>
            <PatientNotes notes={order.data.patientNotes} />

            <Group>
              <Stack spacing={0} style={{ width: '50%' }}>
                <Text className={classes.label}>Requested Delivery Date</Text>
                <Text className={classes.detail}>{`${dayjs(order.data.requestedDeliveryDate).format(
                  'YYYY-MM-DD'
                )}`}</Text>
              </Stack>
              <Stack spacing={0}>
                <Text className={classes.label}>Delivery Time</Text>
                <Text className={classes.detail}>{`${order.data.isAMDelivery ? 'AM' : 'PM'}`}</Text>
              </Stack>
            </Group>

            <Group spacing="xs" pl="xs">
              <Checkbox checked={order.data.liquid} readOnly />
              <Text className={classes.label}>Liquid</Text>
            </Group>

            <Stack spacing={0}>
              <Text className={classes.label}>Order Notes</Text>
              <ScrollArea className={classes.notesCard}>
                <Text>{`${order.data.notes}`}</Text>
              </ScrollArea>
            </Stack>
          </Stack>

          <Stack align="stretch" spacing={5} mt="md">
            <Button
              variant="outline"
              onClick={() => {
                props.onViewEquipment();
              }}
            >
              <FileInvoice style={{ marginRight: 5 }} />{' '}
              <Text className={classes.buttonText}>View Equipment</Text>
            </Button>
            <Group position="apart" spacing={5} grow noWrap>
              <Button
                onClick={() => {
                  props.onDelivered();
                }}
              >
                <Check style={{ marginRight: 5 }} />{' '}
                <Text className={classes.buttonText}>Delivered</Text>
              </Button>
              <Button
                color="red"
                onClick={() => {
                  props.onUndelivered();
                }}
              >
                <X style={{ marginRight: 5 }} />{' '}
                <Text className={classes.buttonText}>Undelivered</Text>
              </Button>
            </Group>
            <Divider my="xs" />
            <Button
              variant="outline"
              onClick={() => {
                setOrdersSubPage('orders');
                navigate('../orders', {
                  state: {
                    order: order.data,
                  },
                });
              }}
            >
              <ClipboardList style={{ marginRight: 5 }} />{' '}
              <Text className={classes.buttonText}>Go to Order</Text>
            </Button>
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default DeliveryOrderDetailModalContent;
