import {
  ActionIcon,
  Box,
  Button,
  Center,
  createStyles,
  Group,
  Modal,
  Popover,
  ScrollArea,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React from 'react';
import { Edit } from 'tabler-icons-react';
import { IEquipmentType } from '../../../models';
import { InfoButton } from '../../../components';

const useStyles = createStyles((theme) => ({
  notesCard: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    paddingLeft: theme.spacing.sm,
    paddingRight: theme.spacing.sm,
    paddingTop: 3,
    width: '100%',
    height: '125px',
  },
  label: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
  },
  detail: {
    fontSize: theme.fontSizes.md,
    paddingLeft: 10,
  },
  fieldGroup: {
    width: '48%',
  },
  clickableIcon: {
    color: `${theme.colors.blue[6]}`,
    padding: '0 !important',
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

type EquipmentTypeDetailModalProps = {
  equipmentType: IEquipmentType;
  opened: boolean;
  isCreated: boolean;
  isUpdated: boolean;
  isDeleted: boolean;
  isError: boolean;
  onClose: () => void;
  onEdit: () => void;
};

const EquipmentTypeDetailModal = (props: EquipmentTypeDetailModalProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  return (
    <Modal
      title={
        props.isUpdated ? (
          <Text color="green">Category Successfully Updated!</Text>
        ) : props.isError ? (
          <Text color="red">Something went wrong, Category not Updated</Text>
        ) : props.isCreated ? (
          <Text color="green">Category Successfully Created!</Text>
        ) : props.isDeleted ? (
          <Text color="green">This Category has been Successfully Deleted!</Text>
        ) : (
          ''
        )
      }
      opened={props.opened}
      size={isSmallMobile ? 'xs' : 'md'}
      onClose={() => {
        props.onClose();
      }}
    >
      <Box>
        <Center>
          <Text size="xl" weight={500} align="center">
            Complete Equipment Category Information
          </Text>
          <InfoButton
            userId={props.equipmentType.lastUpdatedUserId}
            date={props.equipmentType.lastUpdatedDateTime}
            ml={5}
          />
        </Center>

        <Stack align="stretch" style={{ padding: 5 }}>
          <Stack spacing={0} style={{ width: '50%' }}>
            <Text className={classes.label}>Category Name</Text>
            <Text className={classes.detail}>{`${props.equipmentType.name}`}</Text>
          </Stack>
        </Stack>

        {!props.isDeleted && (
          <Group position="apart" grow noWrap mt="md">
            <Button
              variant="outline"
              onClick={() => {
                props.onEdit();
              }}
            >
              <Edit style={{ marginRight: 5 }} />{' '}
              <Text className={classes.buttonText}>Edit Category</Text>
            </Button>
          </Group>
        )}
      </Box>
    </Modal>
  );
};

export default EquipmentTypeDetailModal;
