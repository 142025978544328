import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type NewTrackedPatientEquipmentRequest = {
  trackedEquipmentId: string;
  patientId: string;
  deliveryId?: string;
  deliveredDateTime: Date;
  lastUpdatedUserId: string;
};

type NewTrackedPatientEquipmentResponse = {
  trackedPatientEquipmentId: number;
};

function newTrackedPatientEquipment(
  request: NewTrackedPatientEquipmentRequest
): Promise<NewTrackedPatientEquipmentResponse> {
  return externalApi.post(`TrackedPatientEquipment/new`, { json: request }).json();
}

function useNewTrackedPatientEquipment() {
  const queryClient = useQueryClient();
  return useMutation(
    (request: NewTrackedPatientEquipmentRequest) => newTrackedPatientEquipment(request),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'TrackedPatientEquipmentByPatient',
          'TrackedPatientEquipmentByTrackedEquipment',
        ]);
      },
    }
  );
}
export { useNewTrackedPatientEquipment };
