import {
  Title,
  Text,
  Anchor,
  createStyles,
  Group,
  Image,
  useMantineTheme,
  Box,
  Stack,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React, { useEffect, useState } from 'react';
import {
  Users,
  ClipboardList,
  FileAnalytics,
  FileInvoice,
  BuildingWarehouse,
  CalendarEvent,
} from 'tabler-icons-react';
import { PageHeader } from '../common/Page/Header/PageHeader';
import { Page } from '../common/Page/Page';
import { PageButton, PageButtonMobile } from '../components';

const useStyles = createStyles((theme) => ({
  wrapper: {
    textAlign: 'center',
  },

  subtitle: {
    fontWeight: 600,
    color: theme.colors.blue[5],
    fontSize: theme.spacing.xl,
    marginBottom: -10,
  },
}));

type Props = {};

const HomePage = (props: Props) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const [horizontalMargin, setHorizontalMargin] = useState<string>('20%');

  useEffect(() => {
    if (isMobile) {
      setHorizontalMargin('10%');
    }
  }, [isMobile]);

  return (
    <Page
      header={<PageHeader title="Home Page" subtitle="Select an Option" stickyContent={null} />}
    >
      <Box style={{ height: '50%' }}>
        <Stack
          align="stretch"
          className={classes.wrapper}
          mr={horizontalMargin}
          ml={horizontalMargin}
        >
          <Stack mt={10} mb={20} spacing={0}>
            <Group spacing={10} position="center">
              {isSmallMobile && (
                <Image src="logo192.png" width="85px" alt="Lakeridge Health logo" />
              )}
              {isSmallMobile && (
                <Stack spacing={0}>
                  <Text weight={500} size="lg">
                    Community Respiratory Services
                  </Text>
                  <Title mb={5}>
                    O<sub>2</sub> Delivery Management
                  </Title>
                </Stack>
              )}
              {!isSmallMobile && (
                <Image src="logo192.png" width="55px" alt="Lakeridge Health logo" />
              )}
              {!isSmallMobile && (
                <Stack align="left" spacing={0}>
                  <Text weight={500} size="lg">
                    Community Respiratory Services
                  </Text>
                  <Title mb={5}>
                    O<sub>2</sub> Delivery Management
                  </Title>
                </Stack>
              )}
            </Group>
            <Text size="xs">
              <i>
                If you encounter any issues, or have any concerns regarding this website, please
                report them{' '}
                <Anchor href="mailto:lmawdsley@lh.ca" size="xs">
                  here.
                </Anchor>
              </i>
            </Text>
          </Stack>

          {!isSmallMobile && (
            <Stack align="stretch">
              <Group position="center" grow noWrap>
                <PageButton icon={Users} prompt="Patients" link="../patients" />
                <PageButton icon={ClipboardList} prompt="Orders" link="../orders" />
              </Group>
              <Group position="center" grow noWrap>
                <PageButton icon={BuildingWarehouse} prompt="Equipment" link="../equipment" />
                <PageButton
                  icon={CalendarEvent}
                  prompt={`Delivery\nSchedule`}
                  link="../deliveries"
                />
              </Group>
              <Group position="center" grow noWrap mx={140}>
                <PageButton icon={FileAnalytics} prompt="Reports" link="../reports" />
              </Group>
            </Stack>
          )}

          {isSmallMobile && (
            <Stack align="stretch">
              <PageButtonMobile icon={Users} prompt="Patients" link="../patients" />
              <PageButtonMobile icon={ClipboardList} prompt="Orders" link="../orders" />
              <PageButtonMobile icon={BuildingWarehouse} prompt="Equipment" link="../equipment" />
              <PageButtonMobile
                icon={CalendarEvent}
                prompt={`Delivery Schedule`}
                link="../deliveries"
              />
              <PageButtonMobile icon={FileAnalytics} prompt="Reports" link="../reports" />
            </Stack>
          )}
        </Stack>
      </Box>
    </Page>
  );
};

export default HomePage;
