import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type EditStandingOrderCancellationRequest = {
  standingOrderCancellationId: string;
  startDate: Date;
  endDate: Date;
  lastUpdatedUserId: string;
};

type EditStandingOrderCancellationResponse = {
  standingOrderCancellationId: number;
};

function editStandingOrderCancellation(
  request: EditStandingOrderCancellationRequest
): Promise<EditStandingOrderCancellationResponse> {
  return externalApi.patch(`StandingOrderCancellation/patch`, { json: request }).json();
}

function useEditStandingOrderCancellation() {
  const queryClient = useQueryClient();
  return useMutation(
    (request: EditStandingOrderCancellationRequest) => editStandingOrderCancellation(request),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['StandingOrderCancellationsByStandingOrder']);
      },
    }
  );
}
export { useEditStandingOrderCancellation };
