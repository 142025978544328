import { Route, Routes } from 'react-router-dom';
import {
  DeliveriesPage,
  DeliveriesReportPage,
  EquipmentPage,
  HelpPage,
  HomePage,
  ManageUsersPage,
  NothingFoundPage,
  OrdersPage,
  PatientsPage,
  ReportsPage,
} from '../../pages';
import { useIsAdmin } from '../../hooks';
import Layout from './Layout';

export const PageRoutes = () => {
  const isAdmin = useIsAdmin();

  return (
    <Layout>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/patients" element={<PatientsPage />} />
        <Route path="/orders" element={<OrdersPage />} />
        <Route path="/equipment" element={<EquipmentPage />} />
        <Route path="/deliveries" element={<DeliveriesPage />} />
        <Route path="/reports" element={<ReportsPage />} />
        <Route path="/reports/deliveries" element={<DeliveriesReportPage />} />
        {isAdmin.data?.isAdmin && <Route path="/manage/users" element={<ManageUsersPage />} />}
        <Route path="/help" element={<HelpPage />} />
        <Route path="*" element={<NothingFoundPage />} />
      </Routes>
    </Layout>
  );
};
