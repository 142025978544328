import {
  Anchor,
  createStyles,
  Group,
  useMantineTheme,
  Box,
  Center,
  ScrollArea,
  Divider,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { CalendarEvent, Printer, ArrowLeft, Repeat } from 'tabler-icons-react';
import { PageHeader } from '../../common/Page/Header/PageHeader';
import { Page } from '../../common/Page/Page';
import { DeliveriesReport, ReportPeriodModal } from '../../components';
import dayjs from 'dayjs';

const useStyles = createStyles((theme) => ({}));

type Props = {};

const DeliveriesReportPage = (props: Props) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const pageRef = useRef<HTMLDivElement | null>(null);
  const [pageWidth, setPageWidth] = useState<number>(0);

  const [reportPeriodModalOpen, setReportPeriodModalOpen] = useState<boolean>(false);
  const [reportRefreshing, setReportRefreshing] = useState<boolean>(false);
  const [forceRefresh, setForceRefresh] = useState<boolean>(false);

  const [reportedDate, setReportedDate] = useState<Date>(new Date());
  const [reportedTimeframe, setReportedTimeframe] = useState<'AM' | 'PM' | 'Full Day'>('Full Day');

  useEffect(() => {
    setReportRefreshing(false);
    setForceRefresh(false);
  }, [reportedDate, reportedTimeframe, forceRefresh]);

  function printDocument() {
    const input = document.getElementById('printArea');
    const pdf = new jsPDF('p', 'px', 'letter');
    pdf
      .html(input!, {
        html2canvas: { scale: 0.383, logging: false },
      })
      .then(() => {
        window.open(pdf.output('bloburi'));
      });
  }

  useEffect(() => {
    if (pageRef.current) setPageWidth(pageRef.current.clientWidth);
  }, [pageRef.current]);

  useEffect(() => {
    function handleResize() {
      if (pageRef.current) setPageWidth(pageRef.current.clientWidth);
    }
    window.addEventListener('resize', handleResize);
  });

  return (
    <Page
      header={
        <PageHeader
          title="Deliveries Report"
          subtitle="Order and Delivery Information"
          stickyContent={null}
        />
      }
    >
      <Box ref={pageRef}>
        <Group position="apart" grow noWrap spacing={5}>
          <Anchor
            size="xs"
            align="left"
            onClick={() => {
              navigate('../reports');
            }}
          >
            <ArrowLeft size={15} style={{ marginRight: 3, marginBottom: -3 }} />
            <u>Back to Report Selection</u>
          </Anchor>
          <Anchor
            size="xs"
            align="center"
            onClick={() => {
              setReportPeriodModalOpen(true);
            }}
          >
            <CalendarEvent size={15} style={{ marginRight: 3, marginBottom: -3 }} />
            <u>Select Report Target</u>
          </Anchor>
          <Anchor
            size="xs"
            align="right"
            onClick={() => {
              printDocument();
            }}
          >
            <Printer size={15} style={{ marginRight: 3, marginBottom: -3 }} />
            <u>Generate PDF</u>
          </Anchor>
        </Group>
        <Divider my="xs" />
        <Group position="apart" grow noWrap spacing={5}>
          <Anchor
            size="xs"
            align="center"
            onClick={() => {
              setReportRefreshing(true);
              setForceRefresh(true);
            }}
          >
            <Repeat size={15} style={{ marginRight: 3, marginBottom: -3 }} />
            <u>Refresh Report</u>
          </Anchor>
        </Group>
        <ScrollArea mt="md" style={{ width: pageWidth }}>
          <Center>
            {!reportRefreshing && (
              <DeliveriesReport reportedDate={reportedDate} timeFrame={reportedTimeframe} />
            )}
          </Center>
        </ScrollArea>
      </Box>

      <ReportPeriodModal
        opened={reportPeriodModalOpen}
        selectedDate={reportedDate}
        selectedTimeframe={reportedTimeframe}
        onClose={() => {
          setReportPeriodModalOpen(false);
        }}
        onUpdate={(newDate, newTimeframe) => {
          if (newDate !== reportedDate || newTimeframe !== reportedTimeframe)
            setReportRefreshing(true);
          setReportPeriodModalOpen(false);
          setReportedDate(newDate);
          setReportedTimeframe(newTimeframe);
        }}
      />
    </Page>
  );
};

export default DeliveriesReportPage;
