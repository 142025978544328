import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type EditFundingTypeRequest = {
  fundingTypeId: string;
  name: string;
};

type EditFundingTypeResponse = {
  fundingTypeId: number;
};

function editFundingType(request: EditFundingTypeRequest): Promise<EditFundingTypeResponse> {
  return externalApi.put(`FundingType/put`, { json: request }).json();
}

function useEditFundingType() {
  const queryClient = useQueryClient();
  return useMutation((request: EditFundingTypeRequest) => editFundingType(request), {
    onSuccess: () => {
      queryClient.invalidateQueries(['FundingTypes']);
    },
  });
}
export { useEditFundingType };
