import {
  Button,
  Center,
  createStyles,
  Divider,
  Group,
  Modal,
  ScrollArea,
  Stack,
  Table,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { IPatient, ITrackedPatientEquipment } from '../../../models';
import { useMediaQuery } from '@mantine/hooks';
import { ArrowLeft, Search } from 'tabler-icons-react';
import { InfiniteScrollArea, Th } from '../../../components';
import { useHistoricTrackedPatientEquipmentByPatient } from '../../../hooks';
import dayjs from 'dayjs';

const useStyles = createStyles((theme) => ({
  equipmentBox: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    paddingLeft: 3,
    paddingTop: 3,
    paddingBottom: 3,
    paddingRight: theme.spacing.xs,
    width: '100%',
    height: '70',
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
  groupLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.sm,
    whiteSpace: 'pre',
  },
  detailLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
  },
  detail: {
    fontSize: theme.fontSizes.sm,
    paddingLeft: 10,
  },
}));

function filterData(data: ITrackedPatientEquipment[], search: string) {
  if (data && data.length > 0) {
    const keys = Object.keys(data[0]);
    const query = search.toLowerCase().trim();
    return data.filter((item) =>
      keys.some((key) => (item[key] ?? '-').toString().toLowerCase().includes(query))
    );
  } else {
    return [];
  }
}

function sortData(
  data: ITrackedPatientEquipment[],
  payload: { sortBy: keyof ITrackedPatientEquipment; reversed: boolean; search: string }
) {
  if (!payload.sortBy) {
    return filterData(data, payload.search);
  }

  return filterData(
    [...data].sort((a, b) => {
      if (payload.reversed) {
        return (b[payload.sortBy] ?? '000ForceToBottom')
          .toString()
          .localeCompare((a[payload.sortBy] ?? '000ForceToBottom').toString());
      }

      return (a[payload.sortBy] ?? '!!!ForceToTop').localeCompare(
        (b[payload.sortBy] ?? '!!!ForceToTop').toString()
      );
    }),
    payload.search
  );
}

type PatientEquipmentHistoryModalProps = {
  patient: IPatient;
  opened: boolean;
  onViewNotes: (trackedPatientEquipmentId: string) => void;
  onClose: () => void;
  onSelect: (trackedPatientEquipment: ITrackedPatientEquipment) => void;
};

const PatientEquipmentHistoryModal = (props: PatientEquipmentHistoryModalProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const trackedPatientEquipment = useHistoricTrackedPatientEquipmentByPatient(
    props.patient.patientId
  );

  const [search, setSearch] = useState('');
  const [sortedData, setSortedData] = useState(trackedPatientEquipment.data);
  const [sortBy, setSortBy] = useState<keyof ITrackedPatientEquipment>(null!);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);

  const setSorting = (field: keyof ITrackedPatientEquipment) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setSortedData(sortData(trackedPatientEquipment.data!, { sortBy: field, reversed, search }));
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSearch(value);
    setSortedData(
      sortData(trackedPatientEquipment.data!, {
        sortBy,
        reversed: reverseSortDirection,
        search: value,
      })
    );
  };

  const rows = (sortedData ?? []).map((a: ITrackedPatientEquipment) => {
    return (
      <tr
        key={a.trackedPatientEquipmentId}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          props.onSelect(a);
        }}
      >
        <td style={{ wordWrap: 'break-word', width: '30%' }}>{a.equipmentName}</td>
        <td style={{ wordWrap: 'break-word', width: '30%' }}>{a.serialNumber}</td>
        <td style={{ wordWrap: 'break-word', width: '30%' }}>
          {a.returnedDateTime ? dayjs(a.returnedDateTime).format('YYYY-MM-DD') : '-'}
        </td>
      </tr>
    );
  });

  useEffect(() => {
    if (!trackedPatientEquipment.data) {
      setSortedData(trackedPatientEquipment.data);
    } else {
      setSortedData(
        sortData(trackedPatientEquipment.data, { sortBy, reversed: reverseSortDirection, search })
      );
    }
  }, [trackedPatientEquipment.data, reverseSortDirection, search, sortBy]);

  useEffect(() => {
    if (props.opened) {
      trackedPatientEquipment.refetch();
    }
  }, [props.patient, props.opened]);

  return (
    <Modal
      opened={props.opened}
      size={isSmallMobile ? 'xs' : 'md'}
      onClose={() => {
        props.onClose();
      }}
    >
      <Stack align="stretch" spacing={0} mt="sm">
        <Center>
          <Text size="xl" weight={500} align="center">
            Patient Equipment History
          </Text>
        </Center>
        <Stack align="stretch" style={{ padding: 5 }}>
          <Group>
            <Stack spacing={0} style={{ width: '50%' }}>
              <Text className={classes.detailLabel}>Patient Name</Text>
              <Text
                className={classes.detail}
              >{`${props.patient.lastName}, ${props.patient.firstName}`}</Text>
            </Stack>
            <Stack spacing={0}>
              <Text className={classes.detailLabel}>Street Address, City</Text>
              <Text
                className={classes.detail}
              >{`${props.patient.streetAddress}, ${props.patient.cityName}`}</Text>
            </Stack>
          </Group>

          <Group>
            <Stack spacing={0} style={{ width: '50%' }}>
              <Text className={classes.detailLabel}>Last 4 Digits of{`\n`}Health Card Number</Text>
              <Text className={classes.detail}>{`#${props.patient.lastFourHCN}`}</Text>
            </Stack>
            <Stack spacing={0}>
              <Text className={classes.detailLabel}>{`\n`}Unique Number</Text>
              <Text className={classes.detail}>{`#${props.patient.claimNumber}`}</Text>
            </Stack>
          </Group>
        </Stack>
        <Text className={classes.groupLabel} mt="xs">
          Equipment History
        </Text>
        <Divider />
        <TextInput
          placeholder="Search by any field"
          my="xs"
          icon={<Search size={14} />}
          value={search}
          onChange={handleSearchChange}
        />
        <Divider />
        <Table style={{ width: '100%', tableLayout: 'fixed' }}>
          <thead>
            <tr>
              <Th
                sorted={sortBy === 'equipmentName'}
                reversed={reverseSortDirection}
                onSort={() => setSorting('equipmentName')}
                style={{ width: '30%' }}
              >
                Equipment Name
              </Th>
              <Th
                sorted={sortBy === 'serialNumber'}
                reversed={reverseSortDirection}
                onSort={() => setSorting('serialNumber')}
                style={{ width: '30%' }}
              >
                Serial Number
              </Th>
              <Th
                sorted={sortBy === 'returnedDateTime'}
                reversed={reverseSortDirection}
                onSort={() => setSorting('returnedDateTime')}
                style={{ width: '30%' }}
              >
                Returned On
              </Th>
            </tr>
          </thead>
        </Table>
        <InfiniteScrollArea rows={rows} height={250} />
        <Divider mt="xs" />
        <Button
          variant="outline"
          onClick={() => {
            props.onClose();
          }}
          mt={`calc(${theme.spacing.xs} /2)`}
        >
          <ArrowLeft style={{ marginRight: 5 }} />
          <Text className={classes.buttonText}>Return to Patient Equipment</Text>
        </Button>
      </Stack>
    </Modal>
  );
};

export default PatientEquipmentHistoryModal;
