import {
  ActionIcon,
  Box,
  Button,
  Center,
  Checkbox,
  createStyles,
  Divider,
  Group,
  Modal,
  ScrollArea,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { CalendarEvent, Edit, FileInvoice } from 'tabler-icons-react';
import { IOrder } from '../../../models';
import { PatientNotes, InfoButton } from '../../../components';
import { useNavigate } from 'react-router-dom';
import { useDeliveryByOrder } from '../../../hooks';

const useStyles = createStyles((theme) => ({
  notesCard: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    paddingLeft: theme.spacing.sm,
    paddingRight: theme.spacing.sm,
    paddingTop: 3,
    width: '100%',
    height: '125px',
  },
  infoBox: {
    color: `${theme.colors.gray[6]}`,
    border: `1px solid ${theme.colors.gray[6]}`,
    borderRadius: theme.radius.lg,
    width: 'fit-content',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  label: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
  },
  detail: {
    fontSize: theme.fontSizes.md,
    paddingLeft: 10,
  },
  fieldGroup: {
    width: '48%',
  },
  clickableIcon: {
    color: `${theme.colors.blue[6]}`,
    padding: '0 !important',
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

type OrderDetailModalProps = {
  order: IOrder;
  opened: boolean;
  isCreated: boolean;
  isUpdated: boolean;
  isDeleted: boolean;
  isError: boolean;
  onClose: () => void;
  onEdit: () => void;
  onViewEquipment: () => void;
};

const OrderDetailModal = (props: OrderDetailModalProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const delivery = useDeliveryByOrder();

  useEffect(() => {
    if (delivery.isSuccess) {
      navigate('../deliveries', {
        state: {
          delivery: delivery.data,
        },
      });
    }
  }, [delivery.status]);

  return (
    <Modal
      title={
        props.isUpdated ? (
          <Text color="green">Order Successfully Updated!</Text>
        ) : props.isError ? (
          <Text color="red">Something went wrong, Order not Updated</Text>
        ) : props.isCreated ? (
          <Text color="green">Order Successfully Created!</Text>
        ) : props.isDeleted ? (
          <Text color="green">This Order has been Successfully Deleted!</Text>
        ) : (
          ''
        )
      }
      opened={props.opened}
      size={isSmallMobile ? 'xs' : 'md'}
      onClose={() => {
        props.onClose();
      }}
    >
      <Box>
        <Center>
          <Text size="xl" weight={500} align="center">
            Complete Order Information
          </Text>
          <InfoButton
            userId={props.order.lastUpdatedUserId}
            date={props.order.lastUpdatedDateTime}
            ml={5}
          />
        </Center>
        {props.order.standingOrderReschedule && (
          <Center>
            <Group className={classes.infoBox} position="center" spacing="xs" my={5}>
              <Text weight={500} size="xs">
                This is a Rescheduled Standing Order
              </Text>
            </Group>
          </Center>
        )}
        {props.order.fulfilled && (
          <Center>
            <Group
              className={classes.infoBox}
              position="center"
              spacing="xs"
              my={5}
              style={{ borderColor: theme.colors.blue[6] }}
            >
              <Text weight={500} size="xs" style={{ color: theme.colors.blue[6] }}>
                This Order has been Fulfilled
              </Text>
            </Group>
          </Center>
        )}

        <Stack align="stretch" style={{ padding: 5 }}>
          <Group>
            <Stack spacing={0} style={{ width: '50%' }}>
              <Text className={classes.label}>Patient Name</Text>
              <Group spacing={5} noWrap>
                <Text
                  className={classes.detail}
                >{`${props.order.patientLastName}, ${props.order.patientFirstName}`}</Text>
              </Group>
            </Stack>
            <Stack spacing={0}>
              <Text className={classes.label}>Phone number</Text>
              <Text className={classes.detail}>{`${props.order.patientPhoneNumber}`}</Text>
            </Stack>
          </Group>
          <Group>
            <Stack spacing={0} style={{ width: '50%' }}>
              <Text className={classes.label}>Delivery Address</Text>
              <Text className={classes.detail}>{`${props.order.patientAddress}`}</Text>
            </Stack>
            <Stack spacing={0}>
              <Text className={classes.label}>City</Text>
              <Text className={classes.detail}>{`${props.order.patientCity}`}</Text>
            </Stack>
          </Group>
          <PatientNotes notes={props.order.patientNotes} />

          <Group>
            <Stack spacing={0} style={{ width: '50%' }}>
              <Text className={classes.label}>Requested Delivery Date</Text>
              <Text className={classes.detail}>{`${dayjs(props.order.requestedDeliveryDate).format(
                'YYYY-MM-DD'
              )}`}</Text>
            </Stack>
            <Stack spacing={0}>
              <Text className={classes.label}>Delivery Time</Text>
              <Text className={classes.detail}>{`${props.order.isAMDelivery ? 'AM' : 'PM'}`}</Text>
            </Stack>
          </Group>

          <Group spacing="xs" pl="xs">
            <Checkbox checked={props.order.liquid} readOnly />
            <Text className={classes.label}>Liquid</Text>
          </Group>

          <Stack spacing={0}>
            <Text className={classes.label}>Notes</Text>
            <ScrollArea className={classes.notesCard}>
              <Text>{`${props.order.notes}`}</Text>
            </ScrollArea>
          </Stack>
        </Stack>

        {!props.isDeleted && (
          <Stack align="stretch" spacing={5}>
            <Group position="apart" grow noWrap mt="md">
              {!props.order.fulfilled && (
                <Button
                  variant="outline"
                  onClick={() => {
                    props.onEdit();
                  }}
                  style={{ padding: 0 }}
                >
                  <Edit style={{ marginRight: 5 }} />{' '}
                  <Text className={classes.buttonText}>Edit Order</Text>
                </Button>
              )}
              <Button
                variant="outline"
                onClick={() => {
                  props.onViewEquipment();
                }}
                style={{ padding: 0 }}
              >
                <FileInvoice style={{ marginRight: 5 }} />
                <Text className={classes.buttonText}>{`${
                  !isSmallMobile ? (!props.order.fulfilled ? 'Order' : 'Delivered') : ''
                } Equipment`}</Text>
              </Button>
            </Group>
            <Divider my="xs" />
            <Button
              variant="outline"
              onClick={() => {
                if (props.order.fulfilled) {
                  delivery.mutate(props.order.orderId);
                } else {
                  navigate('../deliveries', {
                    state: {
                      order: props.order,
                    },
                  });
                }
              }}
            >
              <CalendarEvent style={{ marginRight: 5 }} />{' '}
              <Text className={classes.buttonText}>Find on Calendar</Text>
            </Button>
          </Stack>
        )}
      </Box>
    </Modal>
  );
};

export default OrderDetailModal;
