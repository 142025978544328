import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';
import { IDelivery } from '../../models';

function getDeliveryByOrder(orderId: string): Promise<IDelivery> {
  return externalApi.get(`Delivery/ByOrder/${orderId}`).json();
}

function useDeliveryByOrder() {
  const queryClient = useQueryClient();
  return useMutation((orderId: string) => getDeliveryByOrder(orderId), {
    onSuccess: () => {},
  });
}

export { useDeliveryByOrder };
