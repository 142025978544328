import {
  Box,
  Button,
  Center,
  Checkbox,
  createStyles,
  Group,
  Modal,
  ScrollArea,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import dayjs from 'dayjs';
import React from 'react';
import { Edit, FileInvoice } from 'tabler-icons-react';
import { IUser } from '../../../models';

const useStyles = createStyles((theme) => ({
  label: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
  },
  detail: {
    fontSize: theme.fontSizes.md,
    paddingLeft: 10,
  },
  fieldGroup: {
    width: '48%',
  },
  checkFieldGroup: {
    width: 80,
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      '&:last-of-type': {
        marginTop: 5,
      },
      '&:nth-last-of-type(2n)': {
        marginTop: 5,
      },
    },
    [`@media (min-width: ${theme.breakpoints.sm})`]: {
      '&:first-of-type': {
        marginRight: 25,
      },
    },
  },
  notesCard: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    paddingLeft: theme.spacing.sm,
    paddingRight: theme.spacing.sm,
    paddingTop: 3,
    width: '100%',
    height: '125px',
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

type UserDetailModalProps = {
  user: IUser;
  opened: boolean;
  isCreated: boolean;
  isUpdated: boolean;
  isError: boolean;
  onClose: () => void;
  onEdit: () => void;
};

const UserDetailModal = (props: UserDetailModalProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  return (
    <Modal
      title={
        props.isUpdated ? (
          <Text color="green">User Successfully Updated!</Text>
        ) : props.isError ? (
          <Text color="red">Something went wrong, User not Updated</Text>
        ) : props.isCreated ? (
          <Text color="green">User Successfully Created!</Text>
        ) : (
          ''
        )
      }
      opened={props.opened}
      size={isSmallMobile ? 'xs' : 'md'}
      onClose={() => {
        props.onClose();
      }}
    >
      <Box>
        <Center>
          <Text size="xl" weight={500} align="center">
            Complete User Information
          </Text>
        </Center>
        <Stack align="stretch" style={{ padding: 5 }}>
          <Group>
            <Stack spacing={0} style={{ width: '50%' }}>
              <Text className={classes.label}>Full Name</Text>
              <Text className={classes.detail}>{props.user.userFullNameFormatted}</Text>
            </Stack>
            <Stack spacing={0}>
              <Text className={classes.label}>Email Address (Login)</Text>
              <Text className={classes.detail}>{props.user.userEmailAddress}</Text>
            </Stack>
          </Group>

          <Stack spacing={0} style={{ width: '50%' }}>
            <Text className={classes.label}>User Type</Text>
            <Text className={classes.detail}>{props.user.userTypeName}</Text>
          </Stack>

          <Group position="apart" grow noWrap mt="md">
            <Button
              variant="outline"
              disabled={props.user.isAdmin}
              onClick={() => {
                props.onEdit();
              }}
            >
              <Edit style={{ marginRight: 5 }} />{' '}
              <Text className={classes.buttonText}>Edit User</Text>
            </Button>
          </Group>
        </Stack>
      </Box>
    </Modal>
  );
};

export default UserDetailModal;
