import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type CompleteDeliveryRequest = {
  patientId: string;
  orderId: string | undefined;
  standingOrderId: string | undefined;
  deliveredDate: Date;
  deliveredTime: Date;
  notes: string;
  forDate: Date;
  trackedEquipmentIds: string[];
  trackedEquipmentNotes: { trackedEquipmentId: string; notes: string }[];
  undeliveredEquipmentIds: { equipmentId: string; index: number }[];
  undeliveredEquipmentNotes: { index: number; notes: string }[];
  deliveringUserId: string;
};

type CompleteDeliveryResponse = {
  deliveryId: number;
};

function newDelivery(request: CompleteDeliveryRequest): Promise<CompleteDeliveryResponse> {
  return externalApi.post(`Delivery/new/delivered`, { json: request }).json();
}

function useNewCompleteDelivery() {
  const queryClient = useQueryClient();
  return useMutation((request: CompleteDeliveryRequest) => newDelivery(request), {
    onSuccess: () => {
      queryClient.invalidateQueries('DeliveredByDate');
      queryClient.invalidateQueries('IncompleteByDate');
      queryClient.invalidateQueries('PendingOrdersByDate');
      queryClient.invalidateQueries('PendingStandingOrdersByDate');
      queryClient.invalidateQueries('UndeliveredByDate');
      queryClient.invalidateQueries('DeliveriesCountByDate');
      queryClient.invalidateQueries('DeliveriesCountByDateRange');
    },
  });
}
export { useNewCompleteDelivery };
