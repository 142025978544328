import {
  ActionIcon,
  Box,
  Button,
  Center,
  createStyles,
  Divider,
  Flex,
  Group,
  Menu,
  Modal,
  ScrollArea,
  Select,
  Stack,
  Text,
  Textarea,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { ITrackedEquipment, ITrackedEquipmentRepair } from '../../../../models';
import { useEffect, useRef, useState } from 'react';
import {
  useIsTechnician,
  useNewTrackedEquipmentRepair,
  useRepairLocations,
  useUserId,
} from '../../../../hooks';
import { useForm } from '@mantine/form';
import {
  TrackedEquipmentDetailSection,
  TrackedEquipmentPatientSection,
} from '../../../../components';
import { DatePickerInput, TimeInput } from '@mantine/dates';
import { Clock, Dots, MapPin, Tool, X } from 'tabler-icons-react';
import dayjs from 'dayjs';

const useStyles = createStyles((theme) => ({
  notesCard: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    paddingLeft: theme.spacing.sm,
    paddingRight: theme.spacing.sm,
    paddingTop: 3,
    width: '100%',
    height: '125px',
  },
  infoBox: {
    color: `${theme.colors.gray[6]}`,
    border: `1px solid ${theme.colors.gray[6]}`,
    borderRadius: theme.radius.lg,
    width: 'fit-content',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  groupLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.sm,
    whiteSpace: 'pre',
  },
  fieldLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
  },
  editLabel: {
    fontSize: theme.fontSizes.xs,
    fontWeight: 500,
    whiteSpace: 'pre',
  },
  detail: {
    fontSize: theme.fontSizes.md,
    paddingLeft: 10,
  },
  fieldGroup: {
    width: '48%',
  },
  clickableIcon: {
    color: `${theme.colors.blue[6]}`,
    padding: '0 !important',
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

type TrackedEquipmentRepairCreateModalProps = {
  trackedEquipment: ITrackedEquipment;
  opened: boolean;
  onClose: () => void;
  onCreate: () => void;
  onAddRepairLocation: () => void;
};

const TrackedEquipmentRepairCreateModal = (props: TrackedEquipmentRepairCreateModalProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const timeInputRef = useRef<any | null>(null);

  const currentUserId = useUserId();
  const isTechnician = useIsTechnician();
  const repairLocations = useRepairLocations();

  const newRepair = useNewTrackedEquipmentRepair();

  const [isError, setIsError] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [submitClicked, setSubmitClicked] = useState<boolean>(false);
  const [buttonsLocked, setButtonsLocked] = useState<boolean>(false);

  const [sentDate, setSentDate] = useState<Date>(new Date());
  const [sentTime, setSentTime] = useState<Date>(new Date());

  const createForm = useForm<ITrackedEquipmentRepair>({
    initialValues: {
      trackedEquipmentRepairId: '',
      trackedEquipmentId: '',
      repairLocationId: undefined,
      expectedReturnDate: undefined,
      sentDateTime: new Date(),
      notes: '',
    },
    validate: {},
  });

  async function submitRepairCreate() {
    setSubmitClicked(true);
    let newSentDate: Date = new Date(sentDate);
    let newSentTime: Date = new Date(sentTime);
    let sentDateTime: Date = new Date(
      new Date(
        Date.UTC(
          newSentDate.getFullYear(),
          newSentDate.getMonth(),
          newSentDate.getDate(),
          newSentTime.getHours(),
          newSentTime.getMinutes(),
          newSentTime.getSeconds()
        )
      )
    );

    newRepair.mutate({
      trackedEquipmentId: props.trackedEquipment.trackedEquipmentId,
      repairLocationId: createForm.values.repairLocationId,
      expectedReturnDate: createForm.values.expectedReturnDate,
      sentDateTime: sentDateTime,
      notes: createForm.values.notes,
      lastUpdatedUserId: currentUserId.data!.userId,
    });
  }

  useEffect(() => {
    if (submitClicked) {
      if (newRepair.isSuccess) {
        setSubmitClicked(false);
        createForm.reset();
        props.onCreate();
      } else if (newRepair.isError) {
        setMessage('Something went wrong, Repair Record not added. Please try again');
        setSubmitClicked(false);
        setIsError(true);
        setButtonsLocked(false);
      }
    }
  }, [newRepair.status]);

  useEffect(() => {
    if (props.opened) {
      setIsError(false);
      setMessage('');
      setButtonsLocked(false);
      setSentDate(new Date());
      setSentTime(new Date());
    }
  }, [props.opened]);

  return (
    <Modal
      title={<Text color={isError ? 'red' : 'green'}>{message}</Text>}
      opened={props.opened}
      size={isSmallMobile ? 'xs' : 'md'}
      onClose={() => {
        props.onClose();
      }}
    >
      <Stack spacing={0} style={{ position: 'relative' }}>
        <Center>
          <Text size="xl" weight={500} align="center">
            Equipment Repair
          </Text>
          <Flex
            direction={isSmallMobile ? 'column' : 'row'}
            align="center"
            style={{ position: 'absolute', right: -5 }}
          >
            <Menu>
              <Menu.Target>
                <ActionIcon>
                  <Dots />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item
                  icon={<MapPin color={`${theme.colors.blue[6]}`} />}
                  onClick={() => {
                    props.onAddRepairLocation();
                  }}
                >
                  Add Repair Location
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Flex>
        </Center>
        {props.trackedEquipment.patientId && (
          <TrackedEquipmentPatientSection
            trackedEquipment={props.trackedEquipment}
            header="Current Patient"
          />
        )}
        <TrackedEquipmentDetailSection trackedEquipment={props.trackedEquipment} readOnly />
        <form
          onSubmit={createForm.onSubmit(() => {
            setButtonsLocked(true);
            setIsError(false);
            submitRepairCreate();
          })}
          autoComplete="off"
        >
          <Stack spacing={0}>
            <Text className={classes.groupLabel}>Repair Details</Text>
            <Divider mb={`calc(${theme.spacing.xs} / 2)`} />

            <Stack spacing={0}>
              <Text className={classes.editLabel}>Repair Location</Text>
              <Select
                data={(repairLocations.data ?? []).map((a) => ({
                  value: a.repairLocationId,
                  label: a.name,
                }))}
                placeholder="Select a Location"
                searchable={!isSmallMobile}
                {...createForm.getInputProps('repairLocationId')}
              />
            </Stack>
            <Group
              spacing={theme.spacing.sm}
              mt={`calc(${theme.spacing.xs} / 2)`}
              mb={theme.spacing.xs}
              grow
              noWrap
            >
              <Stack spacing={0} align="stretch">
                <Group spacing={0} noWrap>
                  <Text className={classes.editLabel}>Date Sent for Repair</Text>
                  <Text size={theme.fontSizes.xs} color={theme.colors.red[6]}>
                    *
                  </Text>
                </Group>
                <DatePickerInput
                  dropdownType="modal"
                  valueFormat="YYYY-MM-DD"
                  firstDayOfWeek={0}
                  clearable
                  modalProps={{
                    title: 'Repair Date',
                    withCloseButton: true,
                    closeButtonProps: { style: { position: 'fixed', right: 10 } },
                    yOffset: '15vh',
                    styles: { title: { fontWeight: 500, textAlign: 'center', width: '100%' } },
                  }}
                  value={sentDate}
                  onChange={(e) => {
                    setSentDate(e as Date);
                  }}
                />
              </Stack>
              <Stack spacing={0} align="stretch">
                <Group spacing={0} noWrap>
                  <Text className={classes.editLabel}>Time Sent for Repair</Text>
                  <Text size={theme.fontSizes.xs} color={theme.colors.red[6]}>
                    *
                  </Text>
                </Group>
                <TimeInput
                  ref={timeInputRef}
                  rightSection={
                    <ActionIcon onClick={() => timeInputRef.current!.showPicker()}>
                      <Clock size="1rem" />
                    </ActionIcon>
                  }
                  required
                  ml={5}
                  value={dayjs(sentTime).format('HH:mm')}
                  onChange={(e) => {
                    let inputTime = new Date();
                    inputTime.setHours(parseInt(e.currentTarget.value.split(':')[0]));
                    inputTime.setMinutes(parseInt(e.currentTarget.value.split(':')[1]));
                    setSentTime(inputTime);
                  }}
                />
              </Stack>
            </Group>
            <Stack spacing={0} align="stretch" style={{ width: '50%' }}>
              <Text className={classes.editLabel}>Expected Return Date</Text>
              <DatePickerInput
                dropdownType="modal"
                valueFormat="YYYY-MM-DD"
                firstDayOfWeek={0}
                clearable
                modalProps={{
                  title: 'Repair Date',
                  withCloseButton: true,
                  closeButtonProps: { style: { position: 'fixed', right: 10 } },
                  yOffset: '15vh',
                  styles: { title: { fontWeight: 500, textAlign: 'center', width: '100%' } },
                }}
                mr={`calc(${theme.spacing.xs} / 2)`}
                {...createForm.getInputProps('expectedReturnDate')}
              />
            </Stack>
          </Stack>
          <Stack spacing={0} mt="xs">
            <Text className={classes.editLabel}>Repairing Notes</Text>
            <Box style={{ position: 'relative', padding: 0 }} className={classes.notesCard}>
              <ScrollArea style={{ height: '100%' }}>
                <Textarea
                  variant="unstyled"
                  mx="sm"
                  autosize
                  minRows={4}
                  styles={{ input: { padding: 0 } }}
                  {...createForm.getInputProps('notes')}
                />
              </ScrollArea>
            </Box>
          </Stack>
          <Group position="apart" grow noWrap mt="md">
            <Button variant="outline" disabled={buttonsLocked} type="submit">
              <Tool style={{ marginRight: 5 }} />{' '}
              <Text className={classes.buttonText}>{`Repair ${
                !isSmallMobile ? 'Equipment' : ''
              }`}</Text>
            </Button>
            <Button
              variant="outline"
              color="red"
              onClick={() => {
                setIsError(false);
                createForm.reset();
                props.onClose();
              }}
            >
              <X style={{ marginRight: 5 }} /> <Text className={classes.buttonText}>Cancel</Text>
            </Button>
          </Group>
        </form>
      </Stack>
    </Modal>
  );
};

export default TrackedEquipmentRepairCreateModal;
