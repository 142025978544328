import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type EditUserRequest = {
  userId: string;
  userFirstName: string;
  userLastName: string;
  userEmailAddress: string;
  userTypeIds: string[];
};

type EditUserResponse = {
  userId: number;
};

function editUser(request: EditUserRequest): Promise<EditUserResponse> {
  return externalApi.patch(`User/patch`, { json: request }).json();
}

function useEditUser() {
  const queryClient = useQueryClient();
  return useMutation((request: EditUserRequest) => editUser(request), {
    onSuccess: () => {
      queryClient.invalidateQueries(['Users']);
    },
  });
}
export { useEditUser };
