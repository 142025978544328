import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type EditDeliveryStatusRequest = {
  deliveryId: string;
  deliveredDate: Date;
  deliveredTime: Date;
  notes: string;
  trackedEquipmentIds: string[];
  trackedEquipmentNotes: { trackedEquipmentId: string; notes: string }[];
  undeliveredEquipmentIds: { equipmentId: string; index: number }[];
  undeliveredEquipmentNotes: { index: number; notes: string }[];
  deliveringUserId: string;
};

type EditDeliveryStatusResponse = {
  deliveryId: number;
};

function editDeliveryStatus(
  request: EditDeliveryStatusRequest
): Promise<EditDeliveryStatusResponse> {
  return externalApi.patch(`Delivery/patch/status`, { json: request }).json();
}

function useEditDeliveryStatus() {
  const queryClient = useQueryClient();
  return useMutation((request: EditDeliveryStatusRequest) => editDeliveryStatus(request), {
    onSuccess: () => {
      queryClient.invalidateQueries('UndeliveredByDate');
      queryClient.invalidateQueries('DeliveredByDate');
      queryClient.invalidateQueries('DeliveriesCountByDateRange');
    },
  });
}
export { useEditDeliveryStatus };
