import {
  ActionIcon,
  Box,
  createStyles,
  Group,
  ScrollArea,
  Stack,
  Text,
  Textarea,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React, { useEffect, useState } from 'react';
import { Edit, DeviceFloppy, X } from 'tabler-icons-react';
import { ITrackedEquipment } from '../../../models';
import { useEditTrackedEquipmentNotes, useUserId } from '../../../hooks';

const useStyles = createStyles((theme) => ({
  notesCard: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    paddingLeft: theme.spacing.sm,
    paddingRight: theme.spacing.sm,
    paddingTop: 3,
    width: '100%',
    height: '125px',
  },
  infoBox: {
    color: `${theme.colors.gray[6]}`,
    border: `1px solid ${theme.colors.gray[6]}`,
    borderRadius: theme.radius.lg,
    width: 'fit-content',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  groupLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.sm,
    whiteSpace: 'pre',
  },
  fieldLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
  },
  editLabel: {
    fontSize: theme.fontSizes.xs,
    fontWeight: 500,
    whiteSpace: 'pre',
  },
  detail: {
    fontSize: theme.fontSizes.md,
    paddingLeft: 10,
  },
  fieldGroup: {
    width: '48%',
  },
  clickableIcon: {
    color: `${theme.colors.blue[6]}`,
    padding: '0 !important',
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

type TrackedEquipmentNoteSectionProps = {
  trackedEquipment: ITrackedEquipment;
  onEdited: () => void;
  onError: () => void;
};

const TrackedEquipmentNoteSection = (props: TrackedEquipmentNoteSectionProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const currentUserId = useUserId();

  const editTrackedEquipmentNotes = useEditTrackedEquipmentNotes();

  const [isEditing, setIsEditing] = useState<boolean>(false);

  // display values
  const [notes, setNotes] = useState<string>(props.trackedEquipment.notes);

  // controlled editing values
  const [editNotes, setEditNotes] = useState<string>(props.trackedEquipment.notes);

  function SaveChanges() {
    editTrackedEquipmentNotes.mutate({
      trackedEquipmentId: props.trackedEquipment.trackedEquipmentId,
      notes: editNotes,
      lastUpdatedUserId: currentUserId.data!.userId,
    });
  }

  useEffect(() => {
    if (editTrackedEquipmentNotes.isSuccess) {
      setNotes(editNotes);

      props.onEdited();
    }
    if (editTrackedEquipmentNotes.isError) {
      props.onError();
    }
  }, [editTrackedEquipmentNotes.status]);

  return (
    <Box>
      {!isEditing && (
        <Stack spacing={0}>
          <Text className={classes.fieldLabel}>Notes</Text>
          <Box style={{ position: 'relative' }} className={classes.notesCard}>
            <ScrollArea>
              <Text>{notes}</Text>
            </ScrollArea>
            <ActionIcon
              variant="transparent"
              className={classes.clickableIcon}
              style={{ position: 'absolute', bottom: 0, right: 0 }}
              onClick={() => {
                setEditNotes(notes);
                setIsEditing(true);
              }}
            >
              <Edit />
            </ActionIcon>
          </Box>
        </Stack>
      )}
      {isEditing && (
        <Stack spacing={0}>
          <Text className={classes.editLabel}>Notes</Text>
          <Box style={{ position: 'relative', padding: 0 }} className={classes.notesCard}>
            <ScrollArea style={{ height: '100%' }}>
              <Textarea
                variant="unstyled"
                mx="sm"
                autosize
                minRows={4}
                value={editNotes}
                onChange={(a) => {
                  setEditNotes(a.target.value);
                }}
                styles={{ input: { padding: 0 } }}
              />
            </ScrollArea>
            <Group spacing={0} style={{ position: 'absolute', bottom: 0, right: 0 }}>
              <ActionIcon
                variant="transparent"
                className={classes.clickableIcon}
                onClick={() => {
                  setIsEditing(false);
                  SaveChanges();
                }}
              >
                <DeviceFloppy style={{ marginBottom: 4 }} />
              </ActionIcon>
              <ActionIcon
                variant="transparent"
                className={classes.clickableIcon}
                onClick={() => {
                  setIsEditing(false);
                }}
              >
                <X style={{ marginBottom: 4 }} />
              </ActionIcon>
            </Group>
          </Box>
        </Stack>
      )}
    </Box>
  );
};

export default TrackedEquipmentNoteSection;
