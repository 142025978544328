import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type NewPatientEquipmentWithSerialNumberRequest = {
  patientId: string;
  equipmentId: string;
  serialNumber: string;
  lastUpdatedUserId: string;
};

type NewPatientEquipmentWithSerialNumberResponse = {
  patientEquipmentId: number;
};

function newPatientEquipment(
  request: NewPatientEquipmentWithSerialNumberRequest
): Promise<NewPatientEquipmentWithSerialNumberResponse> {
  return externalApi.post(`PatientEquipment/new/withSerialNumber`, { json: request }).json();
}

function useNewPatientEquipmentWithSerialNumber() {
  const queryClient = useQueryClient();
  return useMutation(
    (request: NewPatientEquipmentWithSerialNumberRequest) => newPatientEquipment(request),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['PatientEquipmentByPatient']);
      },
    }
  );
}
export { useNewPatientEquipmentWithSerialNumber };
