import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type DeleteTrackedPatientEquipmentRequest = {
  trackedPatientEquipmentId: string;
};

type DeleteTrackedPatientEquipmentResponse = {
  trackedPatientEquipmentId: number;
};

function deleteTrackedPatientEquipment(
  request: DeleteTrackedPatientEquipmentRequest
): Promise<DeleteTrackedPatientEquipmentResponse> {
  return externalApi
    .delete(`TrackedPatientEquipment/delete/${request.trackedPatientEquipmentId}`)
    .json();
}

function useDeleteTrackedPatientEquipment() {
  const queryClient = useQueryClient();
  return useMutation(
    (request: DeleteTrackedPatientEquipmentRequest) => deleteTrackedPatientEquipment(request),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'TrackedPatientEquipmentByPatient',
          'TrackedPatientEquipmentByTrackedEquipment',
        ]);
      },
    }
  );
}
export { useDeleteTrackedPatientEquipment };
