import { Box, Text } from '@mantine/core';
import { useModals } from '@mantine/modals';
import React, { useEffect, useState } from 'react';
import {
  EquipmentCreateCard,
  EquipmentEditQuantityCard,
  EquipmentDetailCard,
  DialogModal,
} from '..';
import {
  useDeleteStandingOrderEquipment,
  useEditStandingOrderEquipmentQuantity,
  useNewStandingOrderEquipment,
  useUserId,
} from '../../hooks';

type StandingOrderEquipmentCardProps = {
  standingOrderEquipmentId: string;
  standingOrderId: string;
  equipmentId: string;
  equipmentName: string;
  equipmentTypeName: string;
  quantity: number;
  lastUpdatedUserId?: string;
  lastUpdatedDateTime?: Date;
  isEdit?: boolean;
  isCreate?: boolean;
  onEdit?: (isEdited: boolean, isError: boolean) => void;
  onDelete?: (isDeleted: boolean, isError: boolean) => void;
  onCreate?: (isCreated: boolean, isError: boolean) => void;
  onCreateCancel?: () => void;
  onOpen?: () => void;
};

const StandingOrderEquipmentCard = (props: StandingOrderEquipmentCardProps) => {
  const modals = useModals();

  const currentUserId = useUserId();

  const [detailCardOpen, setDetailCardOpen] = useState<boolean>(
    props.isCreate ? false : props.isEdit ? false : true
  );
  const [createCardOpen, setCreateCardOpen] = useState<boolean>(props.isCreate ?? false);
  const [editQuantityCardOpen, setEditQuantityCardOpen] = useState<boolean>(props.isEdit ?? false);
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const editStandingOrderEquipmentQuantity = useEditStandingOrderEquipmentQuantity();
  const newStandingOrderEquipment = useNewStandingOrderEquipment();
  const deleteStandingOrderEquipment = useDeleteStandingOrderEquipment();

  const openDeleteModal = () =>
    modals.openConfirmModal({
      title: 'Delete Equipment',
      centered: true,
      children: (
        <Text size="sm">
          Are you sure you want to delete this Equipment from this Standing Order?
        </Text>
      ),
      labels: { confirm: 'Delete Equipment', cancel: "No, don't delete it" },
      confirmProps: { color: 'red' },
      onCancel: () => {},
      onConfirm: () => {
        handleDelete();
      },
      zIndex: 999,
    });

  const handleUpdate = (newQuantity: number) => {
    editStandingOrderEquipmentQuantity.mutate({
      standingOrderEquipmentId: props.standingOrderEquipmentId,
      quantity: newQuantity,
      lastUpdatedUserId: currentUserId.data!.userId,
    });
  };

  const handleCreate = (newQuantity: number) => {
    newStandingOrderEquipment.mutate({
      standingOrderId: props.standingOrderId,
      equipmentId: props.equipmentId,
      quantity: newQuantity,
      lastUpdatedUserId: currentUserId.data!.userId,
    });
  };

  const handleDelete = () => {
    deleteStandingOrderEquipment.mutate({
      standingOrderEquipmentId: props.standingOrderEquipmentId,
    });
  };

  useEffect(() => {
    if (props.onOpen) {
      props.onOpen();
    }
  }, [detailCardOpen, editQuantityCardOpen, createCardOpen]);

  useEffect(() => {
    if (props.onEdit) {
      if (editStandingOrderEquipmentQuantity.isSuccess) {
        props.onEdit(true, false);
      }
      if (editStandingOrderEquipmentQuantity.isError) {
        props.onEdit(false, true);
      }
    }
  }, [editStandingOrderEquipmentQuantity.status]);

  useEffect(() => {
    if (props.onCreate) {
      if (newStandingOrderEquipment.isSuccess) {
        props.onCreate(true, false);
      }
      if (newStandingOrderEquipment.isError) {
        props.onCreate(false, true);
      }
    }
  }, [newStandingOrderEquipment.status]);

  useEffect(() => {
    if (props.onDelete) {
      if (deleteStandingOrderEquipment.isSuccess) {
        props.onDelete(true, false);
      }
      if (deleteStandingOrderEquipment.isError) {
        props.onDelete(false, true);
      }
    }
  }, [deleteStandingOrderEquipment.status]);

  return (
    <Box mr="sm">
      {detailCardOpen && (
        <EquipmentDetailCard
          equipmentName={props.equipmentName}
          equipmentTypeName={props.equipmentTypeName}
          quantity={props.quantity}
          lastUpdatedUserId={props.lastUpdatedUserId}
          lastUpdatedDateTime={props.lastUpdatedDateTime}
          showInfoButton
          onEditQuantity={() => {
            setDetailCardOpen(false);
            setEditQuantityCardOpen(true);
          }}
          onDelete={() => {
            setDeleteModalOpen(true);
          }}
        />
      )}
      {editQuantityCardOpen && (
        <EquipmentEditQuantityCard
          equipmentName={props.equipmentName}
          equipmentTypeName={props.equipmentTypeName}
          quantity={props.quantity}
          onClose={() => {
            setEditQuantityCardOpen(false);
            setDetailCardOpen(true);
          }}
          onSave={(newQuantity) => {
            handleUpdate(newQuantity);
            setEditQuantityCardOpen(false);
            setDetailCardOpen(true);
          }}
        />
      )}
      {createCardOpen && (
        <EquipmentCreateCard
          equipmentName={props.equipmentName}
          equipmentTypeName={props.equipmentTypeName}
          quantity={props.quantity}
          onClose={() => {
            if (props.onCreateCancel) {
              props.onCreateCancel();
            }
          }}
          onSave={(newQuantity) => {
            handleCreate(newQuantity);
          }}
        />
      )}
      <DialogModal
        isOpen={deleteModalOpen}
        isNegative
        titleText="Delete Equipment?"
        body={
          <Text align="center" color="dimmed" size="lg" mb="xs">
            Are you sure you want to delete this Equipment from this Standing Order?
          </Text>
        }
        confirmText="Delete Equipment"
        onConfirm={() => {
          setDeleteModalOpen(false);
          handleDelete();
        }}
        onClose={() => {
          setDeleteModalOpen(false);
        }}
      />
    </Box>
  );
};

export default StandingOrderEquipmentCard;
