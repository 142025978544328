import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type IncompleteDeliveryRequest = {
  orderId: string | undefined;
  standingOrderId: string | undefined;
  notes: string;
  forDate: Date;
  deliveringUserId: string;
};

type IncompleteDeliveryResponse = {
  deliveryId: number;
};

function newDelivery(request: IncompleteDeliveryRequest): Promise<IncompleteDeliveryResponse> {
  return externalApi.post(`Delivery/new/undelivered`, { json: request }).json();
}

function useNewIncompleteDelivery() {
  const queryClient = useQueryClient();
  return useMutation((request: IncompleteDeliveryRequest) => newDelivery(request), {
    onSuccess: () => {
      queryClient.invalidateQueries('UndeliveredByDate');
      queryClient.invalidateQueries('PendingOrdersByDate');
      queryClient.invalidateQueries('PendingStandingOrdersByDate');
      queryClient.invalidateQueries('DeliveriesCountByDateRange');
    },
  });
}
export { useNewIncompleteDelivery };
