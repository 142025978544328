import {
  createStyles,
  Modal,
  useMantineTheme,
  Text,
  Group,
  SegmentedControl,
  Textarea,
  Button,
  Center,
  Divider,
  Checkbox,
  Stack,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useMediaQuery } from '@mantine/hooks';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { CalendarEvent, X } from 'tabler-icons-react';
import { useDelivery, useRescheduleUndeliveredDelivery } from '../../../hooks';

const useStyles = createStyles((theme) => ({
  label: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
  },
  detail: {
    fontSize: theme.fontSizes.md,
    paddingLeft: 10,
  },
  fieldGroup: {
    width: '48%',
  },
  detailLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
  },
  notesCard: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    paddingLeft: theme.spacing.sm,
    paddingRight: theme.spacing.sm,
    paddingTop: 3,
    width: '100%',
    height: '125px',
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

type UndeliveredDeliveryRescheduleModalProps = {
  deliveryId: string;
  selectedDate: Date;
  opened: boolean;
  onClose: () => void;
  onRescheduled: (orderId: string) => void;
};

const UndeliveredDeliveryRescheduleModal = (props: UndeliveredDeliveryRescheduleModalProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const delivery = useDelivery();

  useEffect(() => {
    if (props.opened) delivery.mutate(props.deliveryId);
  }, [props.opened]);

  const [isAMDelivery, setIsAMDelivery] = useState<boolean>(delivery?.data?.isAMDelivery ?? false);
  const [liquid, setLiquid] = useState<boolean>(delivery?.data?.liquid ?? false);
  const [requestedDeliveryDate, setRequestedDeliveryDate] = useState<Date>(new Date());
  const [notes, setNotes] = useState<string>(delivery?.data?.notes ?? '');

  const newOrder = useRescheduleUndeliveredDelivery();

  const reschedule = () => {
    if (delivery.data) {
      newOrder.mutate({
        patientId: delivery.data.patientId,
        isAMDelivery: isAMDelivery,
        requestedDeliveryDate: requestedDeliveryDate,
        liquid: liquid,
        notes: notes,
        DeliveryId: delivery.data.deliveryId,
      });
    }
  };

  useEffect(() => {
    if (newOrder.isSuccess) {
      props.onRescheduled(newOrder.data.orderId.toString());
    }
  }, [newOrder.status]);

  return (
    <Modal
      opened={props.opened}
      size={isSmallMobile ? 'xs' : 'md'}
      onClose={() => {
        props.onClose();
      }}
      styles={{
        content: {
          border: `3px solid ${theme.colors.red[5]}`,
        },
      }}
    >
      <Center>
        <Text size="xl" weight={500} align="center">
          Confirm Rescheduled Order Information
        </Text>
      </Center>
      {delivery.data && (
        <Stack align="stretch" style={{ padding: 5 }}>
          <Group>
            <Stack spacing={0} style={{ width: '50%' }}>
              <Text className={classes.label}>Patient Name</Text>
              <Group spacing={5} noWrap>
                <Text
                  className={classes.detail}
                >{`${delivery.data.patientLastName}, ${delivery.data.patientFirstName}`}</Text>
              </Group>
            </Stack>
            <Stack spacing={0}>
              <Text className={classes.label}>Phone number</Text>
              <Text className={classes.detail}>{`${delivery.data.patientPhoneNumber}`}</Text>
            </Stack>
          </Group>
          <Group>
            <Stack spacing={0} style={{ width: '50%' }}>
              <Text className={classes.label}>Delivery Address</Text>
              <Text className={classes.detail}>{`${delivery.data.deliveryAddress}`}</Text>
            </Stack>
            <Stack spacing={0}>
              <Text className={classes.detailLabel}>City</Text>
              <Text className={classes.detail}>{`${delivery.data.deliveryCity}`}</Text>
            </Stack>
          </Group>
          <Group>
            <Stack spacing={0} style={{ width: '50%' }}>
              <Text className={classes.detailLabel}>Original Delivery Date</Text>
              <Text className={classes.detail}>{`${dayjs(props.selectedDate).format(
                'YYYY-MM-DD'
              )}`}</Text>
            </Stack>
            <Stack spacing={0}>
              <Text className={classes.detailLabel}>Original Delivery Time</Text>
              <Text className={classes.detail}>{`${
                delivery.data.isAMDelivery ? 'AM' : 'PM'
              }`}</Text>
            </Stack>
          </Group>

          <Divider />

          <Stack align="stretch" style={{ padding: 5 }}>
            <Group noWrap>
              <Stack spacing={0} className={classes.fieldGroup}>
                <Text className={classes.label}>Requested Delivery Date</Text>
                <DatePickerInput
                  valueFormat="YYYY-MM-DD"
                  firstDayOfWeek={0}
                  required
                  dropdownType="modal"
                  modalProps={{
                    title: 'Requested Delivery Date',
                    withCloseButton: true,
                    closeButtonProps: { style: { position: 'fixed', right: 10 } },
                    yOffset: '15vh',
                    styles: { title: { fontWeight: 500, textAlign: 'center', width: '100%' } },
                  }}
                  style={{ width: '125px' }}
                  value={requestedDeliveryDate}
                  onChange={(e) => {
                    setRequestedDeliveryDate(e ?? new Date());
                  }}
                />
              </Stack>
              <Stack spacing={0} className={classes.fieldGroup} ml={15}>
                <Text className={classes.label}>Delivery Time</Text>
                <SegmentedControl
                  color="blue"
                  data={[
                    { label: 'AM', value: 'AM' },
                    { label: 'PM', value: 'PM' },
                  ]}
                  defaultValue={isAMDelivery ? 'AM' : 'PM'}
                  ml={10}
                  onChange={(e) => {
                    e === 'AM' ? setIsAMDelivery(true) : setIsAMDelivery(false);
                  }}
                />
              </Stack>
            </Group>

            <Group position="left" spacing="xs" pl="xs">
              <Checkbox
                checked={liquid}
                onChange={(e) => {
                  setLiquid(e.target.checked);
                }}
              />
              <Text className={classes.label}>Liquid</Text>
            </Group>

            <Stack align="stretch" spacing={0}>
              <Text className={classes.label}>Notes</Text>
              <Textarea
                minRows={4}
                value={notes}
                onChange={(e) => {
                  setNotes(e.target.value);
                }}
              />
            </Stack>

            <Group position="apart" grow noWrap mt="xs">
              <Button
                variant="outline"
                onClick={() => {
                  reschedule();
                }}
              >
                <CalendarEvent style={{ marginRight: 5 }} />{' '}
                <Text className={classes.buttonText}>Reschedule</Text>
              </Button>
              <Button
                variant="outline"
                color="red"
                onClick={() => {
                  props.onClose();
                }}
              >
                <X style={{ marginRight: 5 }} /> <Text className={classes.buttonText}>Cancel</Text>
              </Button>
            </Group>
          </Stack>
        </Stack>
      )}
    </Modal>
  );
};

export default UndeliveredDeliveryRescheduleModal;
