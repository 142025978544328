import {
  ActionIcon,
  Box,
  createStyles,
  Divider,
  Group,
  Select,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React, { useEffect, useRef, useState } from 'react';
import { Edit, DeviceFloppy, X, Clock } from 'tabler-icons-react';
import { ITrackedEquipmentService } from '../../../../models';
import dayjs from 'dayjs';
import {
  useEditTrackedEquipmentServiceDetails,
  useIsAdmin,
  useIsTechnician,
  useTechnicians,
  useUserId,
} from '../../../../hooks';
import { DatePickerInput, TimeInput } from '@mantine/dates';

const useStyles = createStyles((theme) => ({
  notesCard: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    paddingLeft: theme.spacing.sm,
    paddingRight: theme.spacing.sm,
    paddingTop: 3,
    width: '100%',
    height: '125px',
  },
  infoBox: {
    color: `${theme.colors.gray[6]}`,
    border: `1px solid ${theme.colors.gray[6]}`,
    borderRadius: theme.radius.lg,
    width: 'fit-content',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  groupLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.sm,
    whiteSpace: 'pre',
  },
  editGroupLabel: {
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,
    whiteSpace: 'pre',
  },
  fieldLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
  },
  editFieldLabel: {
    fontSize: theme.fontSizes.xs,
    fontWeight: 500,
    whiteSpace: 'pre',
  },
  detail: {
    fontSize: theme.fontSizes.md,
    paddingLeft: 10,
  },
  fieldGroup: {
    width: '48%',
  },
  clickableIcon: {
    color: `${theme.colors.blue[6]}`,
    padding: '0 !important',
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

type TrackedEquipmentServiceDetailSectionProps = {
  service?: ITrackedEquipmentService;
  readOnly?: boolean;
  onEdited?: () => void;
  onError?: () => void;
};

const TrackedEquipmentServiceDetailSection = (props: TrackedEquipmentServiceDetailSectionProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const isAdmin = useIsAdmin().data?.isAdmin;
  const isTechnician = useIsTechnician().data?.isTechnician;

  const timeInputRef = useRef<any | null>(null);

  const currentUserId = useUserId();
  const technicians = useTechnicians();
  const editServiceDetails = useEditTrackedEquipmentServiceDetails();

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [invalidTechnician, setInvalidTechnician] = useState<boolean>(false);
  const [invalidDate, setInvalidDate] = useState<boolean>(false);
  const [invalidTime, setInvalidTime] = useState<boolean>(false);

  // display values
  const [selectedTechnicianId, setSelectedTechnicianId] = useState<string>();
  const [technicianName, setTechnicianName] = useState<string>('');
  const [serviceDate, setServiceDate] = useState<Date>(
    props.service?.serviceDateTime ?? new Date()
  );
  const [serviceTime, setServiceTime] = useState<Date>(
    props.service?.serviceDateTime ?? new Date()
  );

  // controlled editing values
  const [editSelectedTechnicianId, setEditSelectedTechnicianId] = useState<string>();
  const [editServiceDate, setEditServiceDate] = useState<Date>(
    props.service?.serviceDateTime ?? new Date()
  );
  const [editServiceTime, setEditServiceTime] = useState<Date>(
    props.service?.serviceDateTime ?? new Date()
  );

  useEffect(() => {
    if (technicians.isSuccess) {
      setSelectedTechnicianId(
        technicians.data
          ?.find((a) => a.userId.toString() === props.service?.technicianUserId.toString())
          ?.userId.toString()
      );
      setTechnicianName(
        technicians.data?.find(
          (a) => a.userId.toString() === props.service?.technicianUserId.toString()
        )?.userFullNameFormatted ?? ''
      );
    }
  }, [props.service, technicians.status]);

  function SaveChanges() {
    if (props.onEdited) {
      let valid: boolean = true;

      if (!editSelectedTechnicianId) {
        valid = false;
        setInvalidTechnician(true);
      }
      if (!editServiceDate) {
        valid = false;
        setInvalidDate(true);
      }
      if (!editServiceTime) {
        valid = false;
        setInvalidTime(true);
      }

      if (valid) {
        let newServiceDate: Date = new Date(editServiceDate);
        let newServiceTime: Date = new Date(editServiceTime);
        let serviceDateTime: Date = new Date(
          new Date(
            Date.UTC(
              newServiceDate.getFullYear(),
              newServiceDate.getMonth(),
              newServiceDate.getDate(),
              newServiceTime.getHours(),
              newServiceTime.getMinutes(),
              newServiceTime.getSeconds()
            )
          )
        );

        editServiceDetails.mutate({
          trackedEquipmentServiceId: props.service!.trackedEquipmentServiceId,
          technicianUserId: editSelectedTechnicianId ?? props.service!.technicianUserId,
          serviceDateTime: serviceDateTime,
          lastUpdatedUserId: currentUserId.data!.userId,
        });
      }
    }
  }

  useEffect(() => {
    if (editServiceDetails.isSuccess) {
      setSelectedTechnicianId(editSelectedTechnicianId);
      setTechnicianName(
        technicians.data?.find((a) => a.userId.toString() === editSelectedTechnicianId)
          ?.userFullNameFormatted ?? ''
      );
      setServiceDate(editServiceDate);
      setServiceTime(editServiceTime);

      setIsEditing(false);

      if (props.onEdited) {
        props.onEdited();
      }
    }
    if (editServiceDetails.isError) {
      if (props.onError) {
        props.onError();
      }
    }
  }, [editServiceDetails.status]);

  useEffect(() => {
    if (!isEditing) {
      setInvalidTechnician(false);
      setInvalidDate(false);
      setInvalidTime(false);
    }
  }, [isEditing]);

  return (
    <Box>
      {!isEditing && (
        <Stack spacing={0}>
          <Group spacing={0} position="apart" noWrap>
            <Text className={classes.groupLabel}>Service Details</Text>
            {!props.readOnly && (isAdmin || isTechnician) && (
              <ActionIcon
                variant="transparent"
                className={classes.clickableIcon}
                onClick={() => {
                  setEditSelectedTechnicianId(selectedTechnicianId);
                  setEditServiceDate(serviceDate);
                  setEditServiceTime(serviceTime);
                  setIsEditing(true);
                }}
              >
                <Edit style={{ marginBottom: 4 }} />
              </ActionIcon>
            )}
          </Group>
          <Divider mb={`calc(${theme.spacing.xs} / 2)`} />
          <Stack spacing={0} mb={theme.spacing.xs} style={{ width: '50%' }}>
            <Text className={classes.fieldLabel}>Servicing Technician</Text>
            <Text className={classes.detail}>{`${technicianName}`}</Text>
          </Stack>
          <Group spacing={theme.spacing.sm} noWrap>
            <Stack spacing={0} style={{ width: '50%' }}>
              <Text className={classes.fieldLabel}>Serviced On</Text>
              <Text className={classes.detail}>{`${
                serviceDate ? dayjs(serviceDate).format('YYYY-MM-DD') : '-'
              }`}</Text>
            </Stack>
            <Stack spacing={0}>
              <Text className={classes.fieldLabel}>Serviced At</Text>
              <Text className={classes.detail}>
                {serviceTime ? dayjs(serviceTime).format('h:mm A') : '-'}
              </Text>
            </Stack>
          </Group>
        </Stack>
      )}
      {isEditing && (isAdmin || isTechnician) && (
        <Stack spacing={0}>
          <Group spacing={0} position="apart" noWrap>
            <Text className={classes.editGroupLabel}>Service Details</Text>
            <Group spacing={0}>
              <ActionIcon
                variant="transparent"
                className={classes.clickableIcon}
                onClick={() => {
                  SaveChanges();
                }}
              >
                <DeviceFloppy style={{ marginBottom: 4 }} />
              </ActionIcon>
              <ActionIcon
                variant="transparent"
                className={classes.clickableIcon}
                onClick={() => {
                  setIsEditing(false);
                }}
              >
                <X style={{ marginBottom: 4 }} />
              </ActionIcon>
            </Group>
          </Group>
          <Divider mb={`calc(${theme.spacing.xs} / 2)`} />
          <Stack spacing={0} mb={theme.spacing.xs}>
            <Text className={classes.editFieldLabel}>Servicing Technician</Text>
            {isAdmin && (
              <Select
                data={
                  technicians.data
                    ?.sort(
                      (a, b) =>
                        parseInt(a.userFullNameFormatted) - parseInt(b.userFullNameFormatted)
                    )
                    .map((a) => ({
                      value: `${a.userId}`,
                      label: `${a.userFullNameFormatted}`,
                    })) ?? []
                }
                placeholder="Select Equipment"
                searchable={!isSmallMobile}
                value={editSelectedTechnicianId}
                onChange={(e) => {
                  setEditSelectedTechnicianId(e?.toString());
                }}
                error={invalidTechnician ? 'You must select a Technician' : undefined}
              />
            )}
            {!isAdmin && <Text className={classes.detail}>{technicianName}</Text>}
          </Stack>
          <Group spacing={theme.spacing.sm} mb={theme.spacing.xs} align="top" grow noWrap>
            <Stack spacing={0} align="stretch">
              <Text className={classes.editFieldLabel}>Service Date</Text>
              <DatePickerInput
                dropdownType="modal"
                valueFormat="YYYY-MM-DD"
                firstDayOfWeek={0}
                modalProps={{
                  title: 'Service Date',
                  withCloseButton: true,
                  closeButtonProps: { style: { position: 'fixed', right: 10 } },
                  yOffset: '15vh',
                  styles: { title: { fontWeight: 500, textAlign: 'center', width: '100%' } },
                }}
                value={new Date(editServiceDate)}
                onChange={(a) => {
                  setEditServiceDate(new Date(a!.toISOString()));
                }}
                error={invalidDate ? 'You must select a Date' : undefined}
              />
            </Stack>
            <Stack spacing={0} align="stretch">
              <Text className={classes.editFieldLabel}>Service Time</Text>
              <TimeInput
                ref={timeInputRef}
                rightSection={
                  <ActionIcon onClick={() => timeInputRef.current!.showPicker()}>
                    <Clock size="1rem" />
                  </ActionIcon>
                }
                required
                ml={5}
                value={dayjs(editServiceTime).format('HH:mm')}
                onChange={(e) => {
                  let inputTime = new Date();
                  inputTime.setHours(parseInt(e.currentTarget.value.split(':')[0]));
                  inputTime.setMinutes(parseInt(e.currentTarget.value.split(':')[1]));
                  setEditServiceTime(inputTime);
                }}
                error={invalidTime ? 'You must select a Time' : undefined}
              />
            </Stack>
          </Group>
        </Stack>
      )}
    </Box>
  );
};

export default TrackedEquipmentServiceDetailSection;
