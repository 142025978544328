import React from 'react';
import { useQuery } from 'react-query';
import externalApi from '../../api';
import { ITrackedPatientEquipment } from '../../models';

function fetchHistoricTrackedPatientEquipmentByPatient(
  patientId: string
): Promise<ITrackedPatientEquipment[]> {
  return externalApi.get(`TrackedPatientEquipment/Historic/ByPatient/${patientId}`).json();
}

export function useHistoricTrackedPatientEquipmentByPatient(patientId: string) {
  return useQuery(['HistoricTrackedPatientEquipmentByPatient'], () =>
    fetchHistoricTrackedPatientEquipmentByPatient(patientId)
  );
}
