import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import externalApi from '../../api';
import { IOrder } from '../../models';

type PendingOrdersByDateRequest = {
  date: Date;
};

function pendingOrdersByDate(request: PendingOrdersByDateRequest): Promise<IOrder[]> {
  return externalApi.post(`Order/PendingByDate`, { json: request }).json();
}

function usePendingOrdersByDate(request: PendingOrdersByDateRequest) {
  // const queryClient = useQueryClient();
  // return useMutation((request: PendingOrdersByDateRequest) => pendingOrdersByDate(request), {
  //   onSuccess: () => {
  //     queryClient.invalidateQueries(['PendingOrdersByDate']);
  //   },
  // });
  return useQuery(['PendingOrdersByDate'], () => pendingOrdersByDate(request));
}
export { usePendingOrdersByDate };
