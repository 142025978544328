import { Box, createStyles, useMantineTheme, Text, ActionIcon, Center, Stack } from '@mantine/core';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { ArrowsMaximize, BoxOff, Check, Clock, ExclamationMark, X } from 'tabler-icons-react';

const useStyles = createStyles((theme, _params) => ({
  deliveryCard: {
    height: '80px',
    minWidth: '135px',
    maxWidth: 'fit-content',
    marginTop: '5px',
    marginLeft: '5px',
    border: '1px solid',
    borderRadius: theme.radius.md,
    paddingTop: '5px',
    position: 'relative',
  },
  pendingCard: {
    color: theme.colors.blue[6],
    background: 'transparent',
    borderColor: `${theme.colors.blue[6]} !important`,
  },
  urgentCard: {
    color: theme.colors.blue[6],
    borderStyle: 'dashed',
    background: 'transparent',
    borderWidth: 2,
    borderColor: `${theme.colors.blue[6]} !important`,
  },
  deliveredCard: {
    color: 'white',
    background: theme.colors.blue[6],
    borderColor: `${theme.colors.blue[6]} !important`,
  },
  undeliveredCard: {
    color: 'white',
    background: theme.colors.red[6],
    borderColor: `${theme.colors.red[6]} !important`,
  },
  incompleteCard: {
    color: 'white',
    background: theme.colors.orange[4],
    borderColor: `${theme.colors.orange[4]} !important`,
  },
  deliveryCardText: {
    fontSize: 10,
    fontWeight: 500,
    marginLeft: 5,
  },
  smallTextIcon: {
    marginBottom: -3,
    marginLeft: -5,
    height: '15px',
  },
  deliveryCardExpandIcon: {
    color: 'inherit',
    marginLeft: '5px',
    position: 'absolute',
    right: 4,
    bottom: 4,
  },
  deliveryCardBottomText: {
    position: 'absolute',
    bottom: 5,
  },
}));

type DeliveryCardProps = {
  selectedDate: Date;
  firstName: string;
  lastName: string;
  address: string;
  deliveredDate?: Date;
  deliveredTime?: string;
  deliveryStatus: 'pending' | 'delivered' | 'undelivered' | 'incomplete';
  isUrgent?: boolean;
  recordId: string;
  recordType: 'order' | 'standingOrder' | 'delivery';
  onOpen: () => void;
};

const DeliveryCard = (props: DeliveryCardProps) => {
  const { classes, cx } = useStyles();
  const theme = useMantineTheme();

  const [deliveryClassName, setDeliveryClassName] = useState<string>(
    props.deliveryStatus === 'pending'
      ? classes.pendingCard
      : props.deliveryStatus === 'delivered'
      ? classes.deliveredCard
      : props.deliveryStatus === 'incomplete'
      ? classes.incompleteCard
      : classes.undeliveredCard
  );

  useEffect(() => {
    if (props.deliveryStatus === 'pending') {
      if (props.isUrgent) setDeliveryClassName(classes.urgentCard);
      else setDeliveryClassName(classes.pendingCard);
    }
    if (props.deliveryStatus === 'delivered') setDeliveryClassName(classes.deliveredCard);
    if (props.deliveryStatus === 'incomplete') setDeliveryClassName(classes.incompleteCard);
    if (props.deliveryStatus === 'undelivered') setDeliveryClassName(classes.undeliveredCard);
  }, [props.deliveryStatus]);

  return (
    <Box className={cx(classes.deliveryCard, deliveryClassName)}>
      <Stack spacing={0}>
        <Text
          className={classes.deliveryCardText}
          style={{
            marginRight: `${
              props.deliveryStatus === 'undelivered' || props.deliveryStatus === 'incomplete'
                ? '17%'
                : ''
            }`,
          }}
        >{`${props.lastName}, ${props.firstName}`}</Text>
        <Text
          className={classes.deliveryCardText}
          style={{
            marginRight: `${
              props.deliveryStatus === 'undelivered' || props.deliveryStatus === 'incomplete'
                ? '17%'
                : ''
            }`,
          }}
        >
          {props.address}
        </Text>
        {(props.deliveryStatus === 'delivered' || props.deliveryStatus === 'incomplete') &&
          props.deliveredTime &&
          props.deliveredDate && (
            <Text className={classes.deliveryCardText}>
              <i>{`${props.deliveredTime} ${
                props.selectedDate.getDate() !== props.deliveredDate.getDate() ||
                props.selectedDate.getMonth() !== props.deliveredDate.getMonth() ||
                props.selectedDate.getFullYear() !== props.deliveredDate.getFullYear()
                  ? `(${dayjs(props.deliveredDate).format('YYYY-MM-DD')})`
                  : ''
              }`}</i>
            </Text>
          )}
      </Stack>
      {(props.deliveryStatus === 'undelivered' ||
        props.deliveryStatus === 'incomplete' ||
        props.isUrgent) && (
        <Center ml={5}>
          <ExclamationMark
            size={30}
            style={{ position: 'absolute', top: 4, right: 4 }}
            color={props.isUrgent ? theme.colors.red[6] : 'white'}
          />
        </Center>
      )}
      {props.deliveryStatus === 'pending' && (
        <Text className={cx(classes.deliveryCardText, classes.deliveryCardBottomText)}>
          Pending {props.isUrgent ? '(Urgent)' : ''} <Clock className={classes.smallTextIcon} />
        </Text>
      )}
      {props.deliveryStatus === 'delivered' && (
        <Text className={cx(classes.deliveryCardText, classes.deliveryCardBottomText)}>
          Delivered <Check className={classes.smallTextIcon} />
        </Text>
      )}
      {props.deliveryStatus === 'undelivered' && (
        <Text className={cx(classes.deliveryCardText, classes.deliveryCardBottomText)}>
          Undelivered <X className={classes.smallTextIcon} />
        </Text>
      )}
      {props.deliveryStatus === 'incomplete' && (
        <Text className={cx(classes.deliveryCardText, classes.deliveryCardBottomText)}>
          Incomplete <BoxOff className={classes.smallTextIcon} />
        </Text>
      )}
      <ActionIcon
        className={classes.deliveryCardExpandIcon}
        variant="transparent"
        onClick={() => props.onOpen()}
      >
        <ArrowsMaximize />
      </ActionIcon>
    </Box>
  );
};

export default DeliveryCard;
