import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type EditPatientEquipmentQuantityRequest = {
  patientEquipmentId: string;
  quantity: number;
  lastUpdatedUserId: string;
};

type EditPatientEquipmentQuantityResponse = {
  patientEquipmentId: number;
};

function editPatientEquipmentQuantity(
  request: EditPatientEquipmentQuantityRequest
): Promise<EditPatientEquipmentQuantityResponse> {
  return externalApi.patch(`PatientEquipment/patch/quantity`, { json: request }).json();
}

function useEditPatientEquipmentQuantity() {
  const queryClient = useQueryClient();
  return useMutation(
    (request: EditPatientEquipmentQuantityRequest) => editPatientEquipmentQuantity(request),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['PatientEquipmentByPatient']);
      },
    }
  );
}
export { useEditPatientEquipmentQuantity };
