import {
  ActionIcon,
  Box,
  Button,
  Center,
  createStyles,
  Divider,
  Group,
  Modal,
  ScrollArea,
  Select,
  Stack,
  Text,
  Textarea,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useMediaQuery } from '@mantine/hooks';
import { ITrackedEquipment, ITrackedEquipmentClean } from '../../../../models';
import { TrackedEquipmentPatientSection, TrackedEquipmentDetailSection } from '../../..';
import { Clock, Tool, X } from 'tabler-icons-react';
import { useEffect, useRef, useState } from 'react';
import NumberFormat from 'react-number-format';
import { DatePickerInput, TimeInput } from '@mantine/dates';
import {
  useIsTechnician,
  useNewTrackedEquipmentClean,
  useTechnicians,
  useUserId,
} from '../../../../hooks';
import dayjs from 'dayjs';

const useStyles = createStyles((theme) => ({
  notesCard: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    paddingLeft: theme.spacing.sm,
    paddingRight: theme.spacing.sm,
    paddingTop: 3,
    width: '100%',
    height: '125px',
  },
  infoBox: {
    color: `${theme.colors.gray[6]}`,
    border: `1px solid ${theme.colors.gray[6]}`,
    borderRadius: theme.radius.lg,
    width: 'fit-content',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  groupLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.sm,
    whiteSpace: 'pre',
  },
  fieldLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
  },
  editLabel: {
    fontSize: theme.fontSizes.xs,
    fontWeight: 500,
    whiteSpace: 'pre',
  },
  detail: {
    fontSize: theme.fontSizes.md,
    paddingLeft: 10,
  },
  fieldGroup: {
    width: '48%',
  },
  clickableIcon: {
    color: `${theme.colors.blue[6]}`,
    padding: '0 !important',
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

type TrackedEquipmentCleanCreateModalProps = {
  trackedEquipment: ITrackedEquipment;
  opened: boolean;
  onClose: () => void;
  onCreate: () => void;
};

const TrackedEquipmentCleanCreateModal = (props: TrackedEquipmentCleanCreateModalProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const timeInputRef = useRef<any | null>(null);

  const currentUserId = useUserId();
  const isTechnician = useIsTechnician();
  const technicians = useTechnicians();

  const newClean = useNewTrackedEquipmentClean();

  const [isError, setIsError] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [submitClicked, setSubmitClicked] = useState<boolean>(false);
  const [buttonsLocked, setButtonsLocked] = useState<boolean>(false);

  const [cleanDate, setCleanDate] = useState<Date>(new Date());
  const [cleanTime, setCleanTime] = useState<Date>(new Date());

  const createForm = useForm<ITrackedEquipmentClean>({
    initialValues: {
      trackedEquipmentCleanId: '',
      trackedEquipmentId: '',
      technicianUserId: '',
      technicianNameFormatted: '',
      cleanDateTime: new Date(),
      notes: '',
    },
    validate: {},
  });

  async function submitCleanCreate() {
    setSubmitClicked(true);
    let newCleanDate: Date = new Date(cleanDate);
    let newCleanTime: Date = new Date(cleanTime);
    let cleanDateTime: Date = new Date(
      new Date(
        Date.UTC(
          newCleanDate.getFullYear(),
          newCleanDate.getMonth(),
          newCleanDate.getDate(),
          newCleanTime.getHours(),
          newCleanTime.getMinutes(),
          newCleanTime.getSeconds()
        )
      )
    );

    newClean.mutate({
      trackedEquipmentId: props.trackedEquipment.trackedEquipmentId,
      technicianUserId: createForm.values.technicianUserId,
      cleanDateTime: cleanDateTime,
      notes: createForm.values.notes,
      lastUpdatedUserId: currentUserId.data!.userId,
    });
  }

  useEffect(() => {
    if (submitClicked) {
      if (newClean.isSuccess) {
        setSubmitClicked(false);
        createForm.reset();
        props.onCreate();
      } else if (newClean.isError) {
        setMessage('Something went wrong, Cleaning Record not added. Please try again');
        setSubmitClicked(false);
        setIsError(true);
        setButtonsLocked(false);
      }
    }
  }, [newClean.status]);

  useEffect(() => {
    if (props.opened) {
      setIsError(false);
      setMessage('');
      setButtonsLocked(false);
      setCleanDate(new Date());
      setCleanTime(new Date());
      if (isTechnician.data?.isTechnician && currentUserId.data?.userId) {
        createForm.setFieldValue('technicianUserId', currentUserId.data.userId);
      }
    }
  }, [props.opened]);

  useEffect(() => {
    if (isTechnician.isSuccess && currentUserId.isSuccess) {
      if (isTechnician.data?.isTechnician) {
        createForm.setFieldValue('technicianUserId', currentUserId.data.userId);
      }
    }
  }, [isTechnician.status, currentUserId.status]);

  return (
    <Modal
      title={<Text color={isError ? 'red' : 'green'}>{message}</Text>}
      opened={props.opened}
      size={isSmallMobile ? 'xs' : 'md'}
      onClose={() => {
        props.onClose();
      }}
    >
      <Stack spacing={0} style={{ position: 'relative' }}>
        <Center>
          <Text size="xl" weight={500} align="center">
            Equipment Cleaning
          </Text>
        </Center>
        {props.trackedEquipment.patientId && (
          <TrackedEquipmentPatientSection
            trackedEquipment={props.trackedEquipment}
            header="Current Patient"
          />
        )}
        <TrackedEquipmentDetailSection trackedEquipment={props.trackedEquipment} readOnly />
        <form
          onSubmit={createForm.onSubmit(() => {
            setButtonsLocked(true);
            setIsError(false);
            submitCleanCreate();
          })}
          autoComplete="off"
        >
          <Stack spacing={0}>
            <Text className={classes.groupLabel}>Cleaning Details</Text>
            <Divider mb={`calc(${theme.spacing.xs} / 2)`} />

            <Stack spacing={0}>
              <Text className={classes.editLabel}>Technician</Text>
              <Select
                data={(technicians.data ?? []).map((a) => ({
                  value: a.userId,
                  label: a.userFullNameFormatted,
                }))}
                placeholder="Select Technician"
                searchable={!isSmallMobile}
                {...createForm.getInputProps('technicianUserId')}
              />
            </Stack>
            <Group
              spacing={theme.spacing.sm}
              mt={`calc(${theme.spacing.xs} / 2)`}
              mb={theme.spacing.xs}
              grow
              noWrap
            >
              <Stack spacing={0} align="stretch">
                <Text className={classes.editLabel}>Cleaning Date</Text>
                <DatePickerInput
                  dropdownType="modal"
                  valueFormat="YYYY-MM-DD"
                  firstDayOfWeek={0}
                  clearable
                  modalProps={{
                    title: 'Clean Date',
                    withCloseButton: true,
                    closeButtonProps: { style: { position: 'fixed', right: 10 } },
                    yOffset: '15vh',
                    styles: { title: { fontWeight: 500, textAlign: 'center', width: '100%' } },
                  }}
                  value={cleanDate}
                  onChange={(e) => {
                    setCleanDate(e as Date);
                  }}
                />
              </Stack>
              <Stack spacing={0} align="stretch">
                <Text className={classes.editLabel}>Cleaning Time</Text>
                <TimeInput
                  ref={timeInputRef}
                  rightSection={
                    <ActionIcon onClick={() => timeInputRef.current!.showPicker()}>
                      <Clock size="1rem" />
                    </ActionIcon>
                  }
                  required
                  ml={5}
                  value={dayjs(cleanTime).format('HH:mm')}
                  onChange={(e) => {
                    let inputTime = new Date();
                    inputTime.setHours(parseInt(e.currentTarget.value.split(':')[0]));
                    inputTime.setMinutes(parseInt(e.currentTarget.value.split(':')[1]));
                    setCleanTime(inputTime);
                  }}
                />
              </Stack>
            </Group>
          </Stack>
          <Stack spacing={0} mt="xs">
            <Text className={classes.editLabel}>Cleaning Notes</Text>
            <Box style={{ position: 'relative', padding: 0 }} className={classes.notesCard}>
              <ScrollArea style={{ height: '100%' }}>
                <Textarea
                  variant="unstyled"
                  mx="sm"
                  autosize
                  minRows={4}
                  styles={{ input: { padding: 0 } }}
                  {...createForm.getInputProps('notes')}
                />
              </ScrollArea>
            </Box>
          </Stack>
          <Group position="apart" grow noWrap mt="md">
            <Button variant="outline" disabled={buttonsLocked} type="submit">
              <Tool style={{ marginRight: 5 }} />{' '}
              <Text className={classes.buttonText}>{`Clean ${
                !isSmallMobile ? 'Equipment' : ''
              }`}</Text>
            </Button>
            <Button
              variant="outline"
              color="red"
              onClick={() => {
                setIsError(false);
                createForm.reset();
                props.onClose();
              }}
            >
              <X style={{ marginRight: 5 }} /> <Text className={classes.buttonText}>Cancel</Text>
            </Button>
          </Group>
        </form>
      </Stack>
    </Modal>
  );
};

export default TrackedEquipmentCleanCreateModal;
