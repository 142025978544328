import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type EditTrackedPatientEquipmentDeliveryRequest = {
  trackedPatientEquipmentId: string;
  deliveryNotes: string;
  lastUpdatedUserId: string;
};

type EditTrackedPatientEquipmentDeliveryResponse = {
  trackedPatientEquipmentId: number;
};

function editTrackedPatientEquipmentDelivery(
  request: EditTrackedPatientEquipmentDeliveryRequest
): Promise<EditTrackedPatientEquipmentDeliveryResponse> {
  return externalApi
    .patch(`TrackedPatientEquipment/patch/notes/delivery`, { json: request })
    .json();
}

function useEditTrackedPatientEquipmentDeliveryNotes() {
  const queryClient = useQueryClient();
  return useMutation(
    (request: EditTrackedPatientEquipmentDeliveryRequest) =>
      editTrackedPatientEquipmentDelivery(request),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'TrackedPatientEquipmentByPatient',
          'TrackedPatientEquipmentByTrackedEquipment',
        ]);
      },
    }
  );
}
export { useEditTrackedPatientEquipmentDeliveryNotes };
