import {
  Box,
  Button,
  Center,
  createStyles,
  Group,
  Modal,
  SegmentedControl,
  Stack,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useMediaQuery } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { Pencil, X } from 'tabler-icons-react';
import { useNewGeneralUser, useNewTechnicianUser } from '../../../hooks';
import { IUser } from '../../../models';

const useStyles = createStyles((theme) => ({
  label: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
  },
  detail: {
    fontSize: theme.fontSizes.md,
    paddingLeft: 10,
  },
  fieldGroup: {
    width: '48%',
  },
  checkFieldGroup: {
    width: 80,
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      '&:last-of-type': {
        marginTop: 5,
      },
      '&:nth-last-of-type(2n)': {
        marginTop: 5,
      },
    },
    [`@media (min-width: ${theme.breakpoints.sm})`]: {
      '&:first-of-type': {
        marginRight: 25,
      },
    },
  },
  notesCard: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    paddingLeft: theme.spacing.sm,
    paddingRight: theme.spacing.sm,
    paddingTop: 3,
    width: '100%',
    height: '125px',
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

type UserCreateModalProps = {
  opened: boolean;
  onClose: () => void;
  onCreated: (newUser: IUser) => void;
};

const UserCreateModal = (props: UserCreateModalProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const [isError, setIsError] = useState<boolean>(false);
  const [submitClicked, setSubmitClicked] = useState<boolean>(false);
  const [buttonsLocked, setButtonsLocked] = useState<boolean>(false);

  const [userType, setUserType] = useState<'general' | 'technician'>('general');

  const initialUser = {
    userId: '',
    userFirstName: '',
    userLastName: '',
    userFullName: '',
    userFullNameFormatted: '',
    userEmailAddress: '',
    userTypeName: '',
    userTypeIds: [],
    isAdmin: false,
  };

  const newGeneralUser = useNewGeneralUser();
  const newTechnicianUser = useNewTechnicianUser();

  const createForm = useForm<IUser>({
    initialValues: initialUser,
    validate: {
      userFirstName: (a) =>
        a.length > 75 ? "User's First Name must be a maximum of 75 characters" : null,
      userLastName: (a) =>
        a.length > 75 ? "User's Last Name must be a maximum of 75 characters" : null,
      userEmailAddress: (a) =>
        /^\S+@\S+$/.test(a) ? null : 'Email Address (Login) must be a valid Email Address',
    },
  });

  async function submitUser() {
    setSubmitClicked(true);
    setIsError(false);

    if (userType === 'general')
      newGeneralUser.mutate({
        userFirstName: createForm.values.userFirstName,
        userLastName: createForm.values.userLastName,
        userEmailAddress: createForm.values.userEmailAddress,
      });

    if (userType === 'technician')
      newTechnicianUser.mutate({
        userFirstName: createForm.values.userFirstName,
        userLastName: createForm.values.userLastName,
        userEmailAddress: createForm.values.userEmailAddress,
      });
  }

  useEffect(() => {
    if (submitClicked) {
      if (newGeneralUser.isSuccess) {
        setSubmitClicked(false);
        props.onCreated({
          userId: newGeneralUser.data.userId.toString(),
          userFirstName: createForm.values.userFirstName,
          userLastName: createForm.values.userLastName,
          userFullName: `${createForm.values.userFirstName} ${createForm.values.userLastName}`,
          userFullNameFormatted: ` ${createForm.values.userLastName}, ${createForm.values.userFirstName}`,
          userEmailAddress: createForm.values.userEmailAddress,
          userTypeName: 'General',
          userTypeIds: ['1'],
          isAdmin: false,
        });
        createForm.reset();
      } else if (newGeneralUser.isError) {
        setIsError(true);
        setSubmitClicked(false);
        setButtonsLocked(false);
      }
    }
  }, [newGeneralUser.status]);

  useEffect(() => {
    if (submitClicked) {
      if (newTechnicianUser.isSuccess) {
        setSubmitClicked(false);
        props.onCreated({
          userId: newTechnicianUser.data.userId.toString(),
          userFirstName: createForm.values.userFirstName,
          userLastName: createForm.values.userLastName,
          userFullName: `${createForm.values.userFirstName} ${createForm.values.userLastName}`,
          userFullNameFormatted: ` ${createForm.values.userLastName}, ${createForm.values.userFirstName}`,
          userEmailAddress: createForm.values.userEmailAddress,
          userTypeName: 'Technician',
          userTypeIds: ['2'],
          isAdmin: false,
        });
        createForm.reset();
      } else if (newTechnicianUser.isError) {
        setIsError(true);
        setSubmitClicked(false);
        setButtonsLocked(false);
      }
    }
  }, [newTechnicianUser.status]);

  useEffect(() => {
    setButtonsLocked(false);
  }, [props.opened]);

  return (
    <Modal
      title={
        isError ? (
          <Text color="red">Something went wrong, User not Added. Please try again</Text>
        ) : (
          ''
        )
      }
      opened={props.opened}
      size={isSmallMobile ? 'xs' : 'md'}
      onClose={() => {
        setIsError(false);
        props.onClose();
      }}
    >
      <Box>
        <Center>
          <Group noWrap>
            <Text size="xl" weight={500} align="center">
              Create New User
            </Text>
          </Group>
        </Center>
        <form
          onSubmit={createForm.onSubmit(() => {
            setButtonsLocked(true);
            submitUser();
          })}
          autoComplete="off"
        >
          <Stack align="stretch" spacing={5} style={{ padding: 5 }}>
            <Group noWrap>
              <Stack spacing={0} className={classes.fieldGroup}>
                <Text className={classes.label}>User Last Name</Text>
                <TextInput
                  placeholder={!isSmallMobile ? "User's Last Name" : 'Last Name'}
                  required
                  autoComplete="nope"
                  {...createForm.getInputProps('userLastName')}
                />
              </Stack>
              <Stack spacing={0} className={classes.fieldGroup}>
                <Text className={classes.label}>User First Name</Text>
                <TextInput
                  placeholder={!isSmallMobile ? "User's First Name" : 'First Name'}
                  required
                  autoComplete="nope"
                  {...createForm.getInputProps('userFirstName')}
                />
              </Stack>
            </Group>

            <Stack spacing={0} className={classes.fieldGroup}>
              <Text className={classes.label}>Email Address (Login)</Text>
              <TextInput
                placeholder={!isSmallMobile ? 'Email Address' : 'Email'}
                required
                {...createForm.getInputProps('userEmailAddress')}
              />
            </Stack>

            <Stack spacing={0} mt="xs">
              <Text className={classes.label}>User Type</Text>

              <SegmentedControl
                color="blue"
                style={{ border: `1px solid ${theme.colors.gray[4]}` }}
                data={[
                  { label: 'General', value: 'general' },
                  { label: 'Technician', value: 'technician' },
                ]}
                defaultValue={'general'}
                onChange={(e) => {
                  setUserType(e.toString() as 'general' | 'technician');
                }}
              />
            </Stack>
          </Stack>

          <Group position="apart" grow noWrap mt="md">
            <Button variant="outline" disabled={buttonsLocked} type="submit">
              <Pencil style={{ marginRight: 5 }} />{' '}
              <Text className={classes.buttonText}>{`Add ${!isSmallMobile ? 'User' : ''}`}</Text>
            </Button>
            <Button
              variant="outline"
              color="red"
              onClick={() => {
                setIsError(false);
                props.onClose();
              }}
            >
              <X style={{ marginRight: 5 }} /> <Text className={classes.buttonText}>Cancel</Text>
            </Button>
          </Group>
        </form>
      </Box>
    </Modal>
  );
};

export default UserCreateModal;
