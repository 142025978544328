import {
  Center,
  Text,
  Modal,
  useMantineTheme,
  Stack,
  createStyles,
  Group,
  Divider,
  Box,
  ScrollArea,
  ActionIcon,
  Textarea,
  Button,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import {
  useEditTrackedPatientEquipmentDeliveryNotes,
  useTrackedPatientEquipment,
  useUserId,
} from '../../../../hooks';
import { useEffect, useState } from 'react';
import { ArrowLeft, DeviceFloppy, Edit, X } from 'tabler-icons-react';

const useStyles = createStyles((theme) => ({
  notesCard: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    paddingLeft: theme.spacing.sm,
    paddingRight: theme.spacing.sm,
    paddingTop: 3,
    width: '100%',
    height: '125px',
  },
  infoBox: {
    color: `${theme.colors.gray[6]}`,
    border: `1px solid ${theme.colors.gray[6]}`,
    borderRadius: theme.radius.lg,
    width: 'fit-content',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  groupLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.sm,
    whiteSpace: 'pre',
  },
  fieldLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
  },
  editLabel: {
    fontSize: theme.fontSizes.xs,
    fontWeight: 500,
    whiteSpace: 'pre',
  },
  detail: {
    fontSize: theme.fontSizes.sm,
    paddingLeft: 10,
  },
  fieldGroup: {
    width: '48%',
  },
  clickableIcon: {
    color: `${theme.colors.blue[6]}`,
    padding: '0 !important',
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

type Props = {
  opened: boolean;
  trackedPatientEquipmentId: string;
  patientName: string;
  patientClaimNumber: string;
  isReturn?: boolean;
  isDelivery?: boolean;
  onClose: () => void;
};

const TrackedEquipmentNotesModal = (props: Props) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const currentUserId = useUserId();
  const trackedPatientEquipment = useTrackedPatientEquipment(props.trackedPatientEquipmentId);
  const editTrackedPatientEquipmentDeliveryNotes = useEditTrackedPatientEquipmentDeliveryNotes();

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');

  const [notes, setNotes] = useState<string>('');
  const [editNotes, setEditNotes] = useState<string>('');

  useEffect(() => {
    if (trackedPatientEquipment.data?.deliveryNotes) {
      setNotes(trackedPatientEquipment.data?.deliveryNotes);
    }
  }, [trackedPatientEquipment.data?.deliveryNotes]);

  function SaveChanges() {
    editTrackedPatientEquipmentDeliveryNotes.mutate({
      trackedPatientEquipmentId: props.trackedPatientEquipmentId,
      deliveryNotes: editNotes,
      lastUpdatedUserId: currentUserId.data!.userId,
    });
  }

  useEffect(() => {
    if (editTrackedPatientEquipmentDeliveryNotes.isSuccess) {
      setNotes(editNotes);
      setIsError(false);
      setMessage('Equipment Delivery Notes Successfully Updated!');
    }
    if (editTrackedPatientEquipmentDeliveryNotes.isError) {
      setIsError(true);
      setMessage('Something went wrong, Equipment Delivery Notes not Updated');
    }
  }, [editTrackedPatientEquipmentDeliveryNotes.status]);

  return (
    <Modal
      title={<Text color={isError ? 'red' : 'green'}>{message}</Text>}
      opened={props.opened}
      size={isSmallMobile ? 'xs' : 'md'}
      onClose={() => {
        props.onClose();
      }}
    >
      <Stack spacing={0}>
        <Center>
          <Text size="xl" weight={500} align="center" mb={`calc(${theme.spacing.xs} / 2)`}>
            Equipment Notes
          </Text>
        </Center>
        <Group spacing={theme.spacing.sm} mb={theme.spacing.xs} noWrap>
          <Stack spacing={0} style={{ width: '50%' }}>
            <Text className={classes.fieldLabel}>Patient Name</Text>
            <Text className={classes.detail}>{props.patientName}</Text>
          </Stack>
          <Stack spacing={0}>
            <Text className={classes.fieldLabel}>Unique Number</Text>
            <Text className={classes.detail}>{props.patientClaimNumber}</Text>
          </Stack>
        </Group>
        <Divider mb={theme.spacing.xs} />
        <Group>
          <Stack spacing={0} style={{ width: '50%' }}>
            <Text className={classes.fieldLabel}>Equipment Name</Text>
            <Text className={classes.detail}>{trackedPatientEquipment.data?.equipmentName}</Text>
          </Stack>
          <Stack spacing={0}>
            <Text className={classes.fieldLabel}>Equipment Category</Text>
            <Text className={classes.detail}>
              {trackedPatientEquipment.data?.equipmentTypeName}
            </Text>
          </Stack>
        </Group>

        <Stack spacing={0}>
          <Text className={classes.fieldLabel}>Serial Number</Text>
          <Text className={classes.detail}>{trackedPatientEquipment.data?.serialNumber}</Text>
        </Stack>
        <Divider my={theme.spacing.xs} />
        <Box mb={theme.spacing.xs}>
          {!isEditing && (
            <Stack spacing={0}>
              <Text className={classes.fieldLabel}>Delivery Notes</Text>
              <Box style={{ position: 'relative' }} className={classes.notesCard}>
                <ScrollArea>
                  <Text size="sm">{notes}</Text>
                </ScrollArea>
                <ActionIcon
                  variant="transparent"
                  className={classes.clickableIcon}
                  style={{ position: 'absolute', bottom: 0, right: 0 }}
                  onClick={() => {
                    setEditNotes(notes);
                    setIsEditing(true);
                  }}
                >
                  <Edit />
                </ActionIcon>
              </Box>
            </Stack>
          )}
          {isEditing && (
            <Stack spacing={0}>
              <Text className={classes.editLabel}>Delivery Notes</Text>
              <Box style={{ position: 'relative', padding: 0 }} className={classes.notesCard}>
                <ScrollArea style={{ height: '100%' }}>
                  <Textarea
                    variant="unstyled"
                    mx="sm"
                    autosize
                    minRows={4}
                    value={editNotes}
                    onChange={(a) => {
                      setEditNotes(a.target.value);
                    }}
                    styles={{ input: { padding: 0 } }}
                  />
                </ScrollArea>
                <Group spacing={0} style={{ position: 'absolute', bottom: 0, right: 0 }}>
                  <ActionIcon
                    variant="transparent"
                    className={classes.clickableIcon}
                    onClick={() => {
                      setIsEditing(false);
                      SaveChanges();
                    }}
                  >
                    <DeviceFloppy style={{ marginBottom: 4 }} />
                  </ActionIcon>
                  <ActionIcon
                    variant="transparent"
                    className={classes.clickableIcon}
                    onClick={() => {
                      setIsEditing(false);
                    }}
                  >
                    <X style={{ marginBottom: 4 }} />
                  </ActionIcon>
                </Group>
              </Box>
            </Stack>
          )}
        </Box>
        <Button
          variant="outline"
          onClick={() => {
            props.onClose();
          }}
        >
          <ArrowLeft style={{ marginRight: 5 }} />
          Return to Patient Equipment
        </Button>
      </Stack>
    </Modal>
  );
};

export default TrackedEquipmentNotesModal;
