import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type DeletePatientRequest = {
  patientId: string;
  lastUpdatedUserId: string;
};

type DeletePatientResponse = {
  patientId: number;
};

function deletePatient(request: DeletePatientRequest): Promise<DeletePatientResponse> {
  return externalApi.delete(`Patient/delete/`, { json: request }).json();
}

function useDeletePatient() {
  const queryClient = useQueryClient();
  return useMutation((request: DeletePatientRequest) => deletePatient(request), {
    onSuccess: () => {
      queryClient.invalidateQueries(['Patients']);
    },
  });
}
export { useDeletePatient };
