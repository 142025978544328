import {
  Box,
  useMantineTheme,
  createStyles,
  Text,
  Grid,
  Center,
  Group,
  Stack,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import dayjs from 'dayjs';
import React from 'react';

const useStyles = createStyles((theme, _params) => ({
  reportHeader: {
    borderBottom: `1px solid ${theme.colors.gray[5]}`,
    padding: 10,
  },
}));

type DeliveriesReportHeaderProps = {
  reportedDate: Date;
  timeFrame: 'AM' | 'PM' | 'Full Day';
  pageNumber: number;
  pageTotal: number;
};

const DeliveriesReportHeader = (props: DeliveriesReportHeaderProps) => {
  const { classes, cx } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  return (
    <Box className={classes.reportHeader}>
      <Grid>
        <Grid.Col span={10} offset={1}>
          <Center>
            <Stack spacing={0}>
              <Text size="lg">
                <i>Community Respiratory Services - Oxygen Delivery Management</i>
              </Text>
              <Text size="xl" weight={500}>
                {`Deliveries Report - ${dayjs(props.reportedDate).format('YYYY-MM-DD')} (${
                  props.timeFrame
                })`}
              </Text>
            </Stack>
          </Center>
        </Grid.Col>
        <Grid.Col span={1}>
          <Text size="xs" align="right">
            {`Page ${props.pageNumber} of ${props.pageTotal}`}
          </Text>
        </Grid.Col>
      </Grid>
    </Box>
  );
};

export default DeliveriesReportHeader;
