import {
  Center,
  Text,
  Modal,
  useMantineTheme,
  Stack,
  createStyles,
  Anchor,
  Group,
  Divider,
  Box,
  ScrollArea,
  ActionIcon,
  Textarea,
  Button,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import {
  useEditTrackedPatientEquipmentDeliveryNotes,
  useTrackedPatientEquipment,
  useUserId,
} from '../../../hooks';
import { useEffect, useState } from 'react';
import { ITrackedPatientEquipment } from '../../../models';
import { ArrowLeft, DeviceFloppy, Edit, X } from 'tabler-icons-react';

const useStyles = createStyles((theme) => ({
  notesCard: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    paddingLeft: theme.spacing.sm,
    paddingRight: theme.spacing.sm,
    paddingTop: 3,
    width: '100%',
    height: '125px',
  },
  infoBox: {
    color: `${theme.colors.gray[6]}`,
    border: `1px solid ${theme.colors.gray[6]}`,
    borderRadius: theme.radius.lg,
    width: 'fit-content',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  groupLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.sm,
    whiteSpace: 'pre',
  },
  fieldLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
  },
  editLabel: {
    fontSize: theme.fontSizes.xs,
    fontWeight: 500,
    whiteSpace: 'pre',
  },
  detail: {
    fontSize: theme.fontSizes.sm,
    paddingLeft: 10,
  },
  fieldGroup: {
    width: '48%',
  },
  clickableIcon: {
    color: `${theme.colors.blue[6]}`,
    padding: '0 !important',
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

type Props = {
  opened: boolean;
  header?: string;
  currentNotes: string;
  onSave: (updatedNotes: string) => void;
  onClose: () => void;
};

const DeliveryNotesModal = (props: Props) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const [notes, setNotes] = useState<string>(props.currentNotes);

  useEffect(() => {
    if (props.opened) setNotes(props.currentNotes);
  }, [props.currentNotes, props.opened]);

  return (
    <Modal
      opened={props.opened}
      size="xs"
      onClose={() => {
        props.onClose();
      }}
      yOffset="15vh"
      withOverlay={false}
    >
      <Stack spacing={0}>
        <Center>
          <Text size="xl" weight={500} align="center" mb={`calc(${theme.spacing.xs} / 2)`}>
            {props.header ?? ''}
          </Text>
        </Center>
        <Stack align="stretch" spacing={0}>
          <Text className={classes.fieldLabel}>Edit Notes</Text>
          <Textarea
            minRows={4}
            ml={5}
            value={notes}
            onChange={(a) => {
              setNotes(a.target.value);
            }}
          />
        </Stack>
        <Group position="apart" grow noWrap mt={theme.spacing.xs}>
          <Button
            variant="outline"
            onClick={() => {
              props.onSave(notes);
            }}
          >
            <DeviceFloppy style={{ marginRight: 5 }} />
            Save Notes
          </Button>
          <Button
            variant="outline"
            color="red"
            onClick={() => {
              props.onClose();
            }}
            px={0}
          >
            <X style={{ marginRight: 5 }} />
            Close
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};

export default DeliveryNotesModal;
