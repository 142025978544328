import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import externalApi from '../../api';
import { IOrder } from '../../models';

function fetchRescheduledStandingOrders(standingOrderId: string): Promise<IOrder[]> {
  return externalApi.get(`Order/OrdersByStandingOrder/${standingOrderId}`).json();
}

export function useRescheduledStandingOrders(standingOrderId: string) {
  return useQuery(['RescheduledStandingOrdersByStandingOrder'], () =>
    fetchRescheduledStandingOrders(standingOrderId)
  );
}
