import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type NewTrackedEquipmentRepairRequest = {
  trackedEquipmentId: string;
  repairLocationId?: string;
  expectedReturnDate?: Date;
  sentDateTime: Date;
  notes: string;
  lastUpdatedUserId: string;
};

type NewTrackedEquipmentRepairResponse = {
  trackedEquipmentRepairId: number;
};

function newTrackedEquipmentRepair(
  request: NewTrackedEquipmentRepairRequest
): Promise<NewTrackedEquipmentRepairResponse> {
  return externalApi.post(`TrackedEquipmentRepair/new`, { json: request }).json();
}

function useNewTrackedEquipmentRepair() {
  const queryClient = useQueryClient();
  return useMutation(
    (request: NewTrackedEquipmentRepairRequest) => newTrackedEquipmentRepair(request),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('TrackedEquipmentRepairByTrackedEquipment');
        queryClient.invalidateQueries('AllTrackedEquipment');
      },
    }
  );
}
export { useNewTrackedEquipmentRepair };
