import {
  ActionIcon,
  Box,
  Button,
  Center,
  createStyles,
  Divider,
  Group,
  Menu,
  Modal,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { Calendar, DatePicker, DatesRangeValue } from '@mantine/dates';
import React, { useEffect, useState } from 'react';
import { Dots, Edit, Trash, X } from 'tabler-icons-react';
import { useMediaQuery } from '@mantine/hooks';
import {
  useDeleteStandingOrderCancellation,
  useEditStandingOrderCancellation,
  useStandingOrderCancellation,
  useUserId,
} from '../../../hooks';
import { IStandingOrder } from '../../../models';
import { useModals } from '@mantine/modals';
import DialogModal from '../DialogModal';

const useStyles = createStyles((theme) => ({
  label: {
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
    fontWeight: 500,
  },
  fieldGroup: {
    width: '48%',
  },
  detailLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
  },
  detail: {
    fontSize: theme.fontSizes.md,
    paddingLeft: 10,
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

type OrderEditModalProps = {
  standingOrder: IStandingOrder;
  standingOrderCancellationId: string;
  opened: boolean;
  onClose: () => void;
  onEdited: (isError: boolean) => void;
  onDeleted: () => void;
};

const StandingOrderCancellationEditModal = (props: OrderEditModalProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const modals = useModals();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const currentUserId = useUserId();

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const [dateRange, setDateRange] = useState<DatesRangeValue>([new Date(), new Date()]);

  const [isError, setIsError] = useState<boolean>(false);
  const [flashMessage, setFlashMessage] = useState<string>('');

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [submitClicked, setSubmitClicked] = useState<boolean>(false);

  const editStandingOrderCancellation = useEditStandingOrderCancellation();
  const standingOrderCancellation = useStandingOrderCancellation();

  const deleteStandingOrderCancellation = useDeleteStandingOrderCancellation();

  const handleDelete = () => {
    if (standingOrderCancellation.data) {
      deleteStandingOrderCancellation.mutate({
        standingOrderCancellationId: standingOrderCancellation.data.standingOrderCancellationId,
      });
    }
  };

  const cancellationForm = useForm({
    initialValues: { startDate: dateRange[0], endDate: dateRange[1] },
    validate: {},
  });

  async function submitOrderUpdate() {
    setSubmitClicked(true);

    editStandingOrderCancellation.mutate({
      standingOrderCancellationId: props.standingOrderCancellationId,
      startDate: dateRange[0] ?? new Date(),
      endDate: dateRange[1] ?? new Date(),
      lastUpdatedUserId: currentUserId.data!.userId,
    });
  }

  useEffect(() => {
    if (props.standingOrderCancellationId !== '') {
      setIsLoading(true);
      standingOrderCancellation.mutate(props.standingOrderCancellationId);
    }
  }, [props.standingOrderCancellationId]);

  useEffect(() => {
    if (standingOrderCancellation.isSuccess) {
      cancellationForm.setValues({
        startDate: standingOrderCancellation.data.startDate,
        endDate: standingOrderCancellation.data.endDate,
      });
      setDateRange([
        new Date(standingOrderCancellation.data.startDate),
        new Date(standingOrderCancellation.data.endDate),
      ]);
      setIsLoading(false);
    }
  }, [standingOrderCancellation.status]);

  useEffect(() => {
    if (submitClicked) {
      if (editStandingOrderCancellation.isSuccess) {
        setSubmitClicked(false);
        setIsError(false);
        setFlashMessage('');
        props.onEdited(false);
      }
      if (editStandingOrderCancellation.isError) {
        setSubmitClicked(false);
        setIsError(true);
        props.onEdited(true);
      }
    }
  }, [editStandingOrderCancellation.status]);

  useEffect(() => {
    if (deleteStandingOrderCancellation.isSuccess) {
      setIsError(false);
      setFlashMessage('');
      props.onDeleted();
    }

    if (deleteStandingOrderCancellation.isError) {
      setIsError(true);
      setFlashMessage('Something went wrong, Standing Order not Deleted. Please try again');
    }
  }, [deleteStandingOrderCancellation.status]);

  return (
    <Modal
      title={<Text style={{ color: `${isError ? 'red' : 'green'}` }}>{flashMessage}</Text>}
      opened={props.opened}
      size={isSmallMobile ? 'xs' : 'md'}
      onClose={() => {
        setIsError(false);
        setFlashMessage('');
        props.onClose();
      }}
    >
      <Box>
        <Center>
          <Group noWrap spacing={!isSmallMobile ? 'md' : 0} ml={!isSmallMobile ? 25 : 0}>
            <Text size="xl" weight={500} align="center">
              Edit Standing Order Suspension
            </Text>
            <Menu>
              <Menu.Target>
                <ActionIcon>
                  <Dots />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item
                  icon={<Trash color={`${theme.colors.red[6]}`} />}
                  onClick={() => {
                    setDeleteModalOpen(true);
                  }}
                >
                  Delete Suspension
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>
        </Center>
        <form onSubmit={cancellationForm.onSubmit(() => submitOrderUpdate())} autoComplete="off">
          <Stack align="stretch" style={{ padding: 5 }}>
            <Stack spacing={0} style={{ width: '50%' }}>
              <Text className={classes.detailLabel}>Patient Name</Text>
              <Group spacing={5} noWrap>
                <Text className={classes.detail}>
                  {props.standingOrder.patientFullNameFormatted}
                </Text>
              </Group>
            </Stack>
            <Group>
              <Stack spacing={0} style={{ width: '50%' }}>
                <Text className={classes.detailLabel}>Delivery Address</Text>
                <Text className={classes.detail}>{`${props.standingOrder.patientAddress}`}</Text>
              </Stack>
              <Stack spacing={0}>
                <Text className={classes.detailLabel}>City</Text>
                <Text className={classes.detail}>{`${props.standingOrder.patientCity}`}</Text>
              </Stack>
            </Group>

            <Group>
              <Stack spacing={0} style={{ width: '50%' }}>
                <Text className={classes.detailLabel}>Delivery Weekday</Text>
                <Text className={classes.detail}>{`${props.standingOrder.weekdayName}`}</Text>
              </Stack>
              <Stack spacing={0}>
                <Text className={classes.detailLabel}>Delivery Time</Text>
                <Text className={classes.detail}>{`${
                  props.standingOrder.isAMDelivery ? 'AM' : 'PM'
                }`}</Text>
              </Stack>
            </Group>

            <Divider />
            <Stack spacing={0}>
              <Text weight={200} size="md" align="center">
                Select a Date Range for the Standing Order to be Suspended
              </Text>
              {!isLoading && (
                <DatePicker
                  firstDayOfWeek={0}
                  type="range"
                  value={dateRange}
                  defaultDate={dateRange[0] ?? new Date()}
                  onChange={(e) => {
                    setDateRange(e);
                  }}
                />
              )}
              {isLoading && <Calendar firstDayOfWeek={0} />}
            </Stack>
            <Divider />

            <Group position="apart" grow noWrap mt="xs">
              <Button variant="outline" type="submit">
                <Edit style={{ marginRight: 5 }} />{' '}
                <Text className={classes.buttonText}>Update</Text>
              </Button>
              <Button
                variant="outline"
                color="red"
                onClick={() => {
                  setIsError(false);
                  setFlashMessage('');
                  props.onClose();
                }}
              >
                <X style={{ marginRight: 5 }} /> <Text className={classes.buttonText}>Cancel</Text>
              </Button>
            </Group>
          </Stack>
        </form>
      </Box>
      <DialogModal
        isOpen={deleteModalOpen}
        isNegative
        titleText="Delete Standing Order Suspension?"
        body={
          <Text align="center" color="dimmed" size="lg" mb="xs">
            Are you sure you want to delete this Standing Order Suspension?
          </Text>
        }
        confirmText="Delete Suspension"
        onConfirm={() => {
          setDeleteModalOpen(false);
          handleDelete();
        }}
        onClose={() => {
          setDeleteModalOpen(false);
        }}
      />
    </Modal>
  );
};

export default StandingOrderCancellationEditModal;
