import { Text, useMantineTheme, Stack, createStyles, Box, Group } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { Check, X } from 'tabler-icons-react';
import { InfoButton } from '../../components';
import { useEffect, useState } from 'react';
import { ITrackedEquipment } from '../../models';
import dayjs from 'dayjs';

const useStyles = createStyles((theme) => ({
  equipmentBox: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    paddingLeft: 3,
    paddingTop: 3,
    paddingBottom: 3,
    paddingRight: theme.spacing.md,
    width: '100%',
    height: '70',
  },
  equipmentBoxClicked: {
    border: `1px solid ${theme.colors.blue[6]}`,
    borderRadius: theme.radius.sm,
    background: theme.colors.blue[0],
    paddingLeft: 3,
    paddingTop: 3,
    paddingBottom: 3,
    paddingRight: theme.spacing.md,
    width: '100%',
    height: '70',
  },
  label: {
    fontSize: `calc(${theme.fontSizes.xs} * 0.9)`,
    color: 'dimgray',
    textAlign: 'right',
  },
  detail: {
    fontSize: `calc(${theme.fontSizes.xs} * 0.9)`,
    color: 'black',
    fontWeight: 700,
    textAlign: 'left',
  },
}));

type Props = {
  trackedEquipment: ITrackedEquipment;
  isClicked?: boolean;
  onClicked: () => void;
};

const TrackedEquipmentDetailCard = (props: Props) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const [isClicked, setIsClicked] = useState<boolean>(props.isClicked ?? false);

  useEffect(() => {
    setIsClicked(props.isClicked ?? false);
  }, [props.isClicked]);

  return (
    <Box className={isClicked ? classes.equipmentBoxClicked : classes.equipmentBox} mb={5}>
      <Group position="apart" noWrap spacing={0}>
        <Group
          position="apart"
          noWrap
          spacing={0}
          style={{ width: '100%', cursor: 'pointer' }}
          onClick={() => {
            setIsClicked((a) => !a);
            props.onClicked();
          }}
        >
          <Stack pl="sm" spacing={0} style={{ width: '50%', userSelect: 'none' }}>
            <Text weight={500}>{props.trackedEquipment.serialNumber}</Text>
            {props.trackedEquipment.underWarranty && (
              <Group spacing={1} mt={-5}>
                <Text size="xs" color="dimmed">
                  Under Warranty
                </Text>
                <Check
                  size={theme.fontSizes.sm}
                  strokeWidth={3}
                  color={theme.colors.blue[6]}
                  style={{ marginLeft: 3 }}
                />
              </Group>
            )}
            {!props.trackedEquipment.underWarranty && (
              <Group spacing={1} mt={-5}>
                <Text size="xs" color="dimmed">
                  Warranty Expired
                </Text>
                <X
                  size={theme.fontSizes.sm}
                  strokeWidth={3}
                  color={theme.colors.red[6]}
                  style={{ marginLeft: 3 }}
                />
              </Group>
            )}
          </Stack>
          <Stack align="stretch" spacing={0} style={{ width: `100%`, userSelect: 'none' }}>
            <Group spacing={`calc(${theme.spacing.xs} / 2)`} grow noWrap>
              <Text className={classes.label}>Last Cleaned:</Text>
              <Text className={classes.detail}>
                {props.trackedEquipment.lastCleanedDateTime
                  ? dayjs(props.trackedEquipment.lastCleanedDateTime).format('YYYY-MM-DD')
                  : '-'}
              </Text>
            </Group>
            <Group spacing={`calc(${theme.spacing.xs} / 2)`} grow noWrap>
              <Text className={classes.label}>Last Serviced:</Text>
              <Text className={classes.detail}>
                {props.trackedEquipment.lastServicedDateTime
                  ? dayjs(props.trackedEquipment.lastServicedDateTime).format('YYYY-MM-DD')
                  : '-'}
              </Text>
            </Group>
            <Group spacing={`calc(${theme.spacing.xs} / 2)`} grow noWrap>
              <Text className={classes.label}>
                {props.trackedEquipment.underWarranty ? 'Warranty Ends:' : 'Warranty Ended:'}
              </Text>
              <Text className={classes.detail}>
                {props.trackedEquipment.warrantyExpiryDate
                  ? dayjs(props.trackedEquipment.warrantyExpiryDate).format('YYYY-MM-DD')
                  : '-'}
              </Text>
            </Group>
          </Stack>
        </Group>

        <Stack align="stretch" spacing={0}>
          <InfoButton userId="3" date={props.trackedEquipment.lastUpdatedDateTime} transparent />
        </Stack>
      </Group>
    </Box>
  );
};

export default TrackedEquipmentDetailCard;
