import React from 'react';
import { useQuery } from 'react-query';
import externalApi from '../../api';
import { IUser } from '../../models';

function fetchUsers(): Promise<IUser[]> {
  return externalApi.get(`User/all`).json();
}

export function useUsers() {
  return useQuery(['Users'], () => fetchUsers());
}
