import { createStyles, Divider, Stack, Text, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React from 'react';
import { HelpBox } from '../../../components';

const useStyles = createStyles((theme, _params) => ({
  card: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.md,
    padding: `${theme.spacing.sm} ${theme.spacing.lg}`,
  },
}));

type Props = {};

const PatientsHelpSubPage = (props: Props) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  return (
    <Stack align="stretch" p={25}>
      <HelpBox title={`\"Show Records\" Filter`}>
        <Stack spacing={5}>
          <Text style={{ whiteSpace: 'nowrap' }}>
            Determines the types of patient records that will display in the table.
          </Text>
          <Text style={{ whiteSpace: 'nowrap' }}>
            "Active" will display all patient records marked as Active.
          </Text>
          <Text style={{ whiteSpace: 'nowrap' }}>
            "Inactive" will display all patient records marked as Inactive.
          </Text>
          <Text style={{ whiteSpace: 'nowrap' }}>
            "Deceased" will display all deceased patients.
          </Text>
          <Text style={{ whiteSpace: 'nowrap' }}>
            Whether a patient record is Active or Inactive will be displayed in the table column
            titled "Active", containing a checkbox.
          </Text>
          <Text style={{ whiteSpace: 'nowrap' }}>
            If this checkbox is checked the record is is Active, if unchecked the record is
            Inactive.
          </Text>
          <Text style={{ whiteSpace: 'nowrap' }}>
            These filters can be combined to determine exactly which patients are being displayed.
          </Text>
        </Stack>
      </HelpBox>

      <HelpBox title="Include Fields">
        <Text style={{ whiteSpace: 'nowrap' }}>
          To keep the table at a manageable size without needing to scroll to see everything, most
          columns are hidden by default.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          These columns can be included by selecting options from the "Include Fields" selector.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Clicking on any of the items in the "Include Fields" selector will display that column in
          the table and make that column searchable (will be checked against search bar input).
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Clicking them again will remove the column from the table, and remove it from the search.
        </Text>
      </HelpBox>

      <HelpBox title="Table Navigation">
        <Text style={{ whiteSpace: 'nowrap' }}>
          There are several ways to find the records you're looking for in the patients table.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          By default the table is sorted Alphabetically by patient name (Last Name, First Name).
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          You can change the table sort by clicking on any of the column headers.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          When a column header has two arrows, one pointing up and one pointing down, it is not
          currently the column records are being sorted by.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          When a column header contains one arrow and it's pointing down, records are being sorted
          by that column, ascending (smallest to largest, A to Z, 0 to 9).
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          When a column header contains one arrow and it's pointing up, records are being sorted by
          that column, descending (largest to smallest, Z to A, 9 to 0).
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          You can also use the search bar above the table to search for a record that contains your
          search term in any of it's visible columns.
        </Text>
      </HelpBox>

      <HelpBox title="Viewing Patient Details">
        <Text style={{ whiteSpace: 'nowrap' }}>
          To view patient details, click on the row of the table containing the record you wish to
          view the details of.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Clicking the "i" button next to the title of the "Complete Patient Information" screen
          will display the last person to edit this record, and when it was edited.
        </Text>
      </HelpBox>

      <HelpBox title="Edit Patient">
        <Text style={{ whiteSpace: 'nowrap' }}>
          To edit patient information, click on the "Edit Patient" button on the "Complete Patient
          Information" screen.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          This will bring up a form containing the details of the patient you've selected to edit.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Change the values as needed, then click "Update Patient".
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          If you've missed any required fields you should recieve a prompt to include them. After
          filling them in, try clicking "Update Patient" again.
        </Text>
      </HelpBox>

      <HelpBox title="Mark Patient Inactive/Active">
        <Text style={{ whiteSpace: 'nowrap' }}>
          To mark a patient as Inactive, click the "..." button next to the title of the "Edit
          Patient Information" screen. This will open a menu with three options.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          The bottom option is important here. Click the "Mark Patient Inactive" option and then
          "Mark Inactive" on the popup to mark the patient as Inactive.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Only an Active patient can be marked as Inactive.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          To reactive a patient, the same steps can be followed, except the options will instead be
          "Reactivate Patient" and "Reactivate".
        </Text>
      </HelpBox>

      <HelpBox title="Add City">
        <Text style={{ whiteSpace: 'nowrap' }}>
          To add a city, click the "..." button next next to the title of the "Edit Patient
          Information" screen or the "New Patient Information" screen.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Click the first option on the menu, "Add City". This will open the "Cities" screen.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          To add a new city, type the name in the text box below the list of cities and click "Add a
          New City".
        </Text>
      </HelpBox>

      <HelpBox title="Edit City">
        <Text style={{ whiteSpace: 'nowrap' }}>
          To edit a city, click on the edit button next to an existing city record on the "Cities"
          screen.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          This will populate the text box below the list of cities with the city you've selected to
          edit.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Change the city name as needed, then click "Update City Name". This will update the
          selected city.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          To cancel the edit, click the "x" button on the right of the text box.
        </Text>
      </HelpBox>

      <HelpBox title="Add Funding Type">
        <Text style={{ whiteSpace: 'nowrap' }}>
          To add a funding type, click the "..." button next next to the title of the "Edit Patient
          Information" screen or the "New Patient Information" screen.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Click the first option on the menu, "Add Funding Type". This will open the "Funding Types"
          screen.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          To add a new funding type, type the name in the text box below the list of funding types
          and click "Add a New Funding Type".
        </Text>
      </HelpBox>

      <HelpBox title="Edit Funding Type">
        <Text style={{ whiteSpace: 'nowrap' }}>
          To edit a funding type, click on the edit button next to an existing funding type record
          on the "Funding Types" screen.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          This will populate the text box below the list of funding types with the funding type
          you've selected to edit.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Change the funding type name as needed, then click "Update Funding Type Name". This will
          update the selected funding type.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          To cancel the edit, click the "x" button on the right of the text box.
        </Text>
      </HelpBox>

      <HelpBox title="Managing Patient Equipment">
        <Text style={{ whiteSpace: 'nowrap' }}>
          To view and manage the equipment in a patient's possesion, click the "Patient Equipment"
          button on the "Complete Patient Information" screen.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          This will bring up a list of all equipment recorded to be in the given patient's
          posiession.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Equipment is automatically added to this inventory when a delivery is made. The equipment
          added will be the equipment assigned to the order that was delivered.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Equipment can also be added to the patient's invetory directly in the event that an item
          is not recorded as part of a delivery.
        </Text>
      </HelpBox>

      <HelpBox title="Adding Patient Equipment">
        <Text style={{ whiteSpace: 'nowrap' }}>
          To add equipment to a patient's inventory, select the equipment from the dropdown menu
          below the equipment list. Then click "Add Equipment".
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          There are 2 types of equipment that can be added, Tracked and Untracked.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          You will know which type of equipment you are attempting to add after clicking "Add
          Equipment".
        </Text>
        <Divider my={5} />
        <Text style={{ whiteSpace: 'nowrap' }}>
          If the equipment is Untracked, you will be prompted to enter a quantity for the equipment.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          When you are satisfied with your input (quantity), click the "+" button on the top-right
          of the input card.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          If you do not wish to add the equipment, click the "x" button on the bottom-right of the
          input card.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Upon clicking the "+" button, your new equipment will be added.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          If it is the first time this equipment is appearing on this patient's inventory list, a
          new card will be created at the bottom of the list.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          If the equipment was previously on the list, the new equipment will instead be added to
          the quantity of the existing record.
        </Text>
        <Divider my={5} />
        <Text style={{ whiteSpace: 'nowrap' }}>
          If the equipment is Tracked, you will be presented with a list of Tracked equipment of the
          selected type that are currently ready to be delivered.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          To select a piece of Tracked equipment, click on the card representing the equipment you
          want to select. It will be highlighted.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Once a piece of Tracked equipment is selected, you can click the "Add Selected Equipment"
          button.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Clicking the "Add Selected Equipment" button will bring you to a form for providing some
          basic delivery information.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          The form will default to the current date and time for when the Tracked equipment was
          delivered.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Ensure that the date and time forms are accurate to when this piece of Tracked equipment
          was delivered.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          To complete the process of adding a piece of Tracked equipment directly to a patient,
          click the "Add Selected Equipment" button again.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          When the button is clicked, the Tracked equipment will be marked as being in the patient's
          possession.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          The process of adding Tracked equipment to a patient can be exited at any time by clicking
          the "X" in the top right corner of the form until you return to the desired view.
        </Text>
      </HelpBox>

      <HelpBox title="Editing Patient Equipment">
        <Text style={{ whiteSpace: 'nowrap' }}>You can only edit Untracked equipment.</Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          To edit equipment in a patient's inventory, click the "..." button on the top-right of the
          card containing the equipment you wish to edit. This will open a menu with two options.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          You can then select to edit the quantity of the equipment, depending on the equipment
          selected.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Clicking the "i" button on the bottom-right of any equipment card will display the last
          person to edit this record, and when it was edited.
        </Text>
      </HelpBox>

      <HelpBox title="Returning Patient Equipment">
        <Text style={{ whiteSpace: 'nowrap' }}>You can only return Tracked equipment.</Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          To return equipment from a patient's inventory, click the "..." button on the top-right of
          the card containing the equipment you wish to return. This will open a menu with three
          options.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Clicking the "Mark as Returned" option will open a form requiring details of when the
          equipment is being returned.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Fill this form out, ensuring the fields are accurate, and click the "Return Selected
          Equipment" button to mark the equipment as returned.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          This process can be ended at any time by clicking the "X" in the top-right of the return
          form.
        </Text>
      </HelpBox>

      <HelpBox title="Add a New Patient">
        <Text style={{ whiteSpace: 'nowrap' }}>
          Clicking the "Add a New Patient" button below the table will bring up a form allowing you
          to create a new patient record.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Fill out each of the fields as required and click "Create Patient". This will add your new
          patient to the table.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          If you've missed any required fields you should recieve a prompt to include them. After
          filling them in, try clicking "Create Patient" again.
        </Text>
      </HelpBox>

      <HelpBox title="Create Order from Patient">
        <Text style={{ whiteSpace: 'nowrap' }}>
          When a pending order or an order marked as undelivered on the "Delivery Schedule" page is
          delivered, the corresponding on-demand order record will be marked as fulfilled.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          This removes the ability to edit the order or associated equipment, but is still available
          for viewing.
        </Text>
      </HelpBox>
    </Stack>
  );
};

export default PatientsHelpSubPage;
