import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';
import { ITrackedEquipment, ITrackedEquipmentFilter } from '../../models';

function fetchAllTrackedEquipment(request: {
  pageNumber: number;
  pageSize: number;
  filter: number;
  search: string;
}): Promise<ITrackedEquipment[]> {
  return externalApi.post(`TrackedEquipment/all`, { json: request }).json();
}

export function useAllTrackedEquipment() {
  const queryClient = useQueryClient();
  return useMutation(
    (request: {
      pageNumber: number;
      pageSize: number;
      filter: ITrackedEquipmentFilter;
      search: string;
    }) => fetchAllTrackedEquipment(request),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('AllTrackedEquipment');
      },
    }
  );
}
