import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type ReactivatePatientRequest = {
  patientId: string;
};

type ReactivatePatientResponse = {
  patientId: number;
};

function reactivatePatient(request: ReactivatePatientRequest): Promise<ReactivatePatientResponse> {
  return externalApi.patch(`Patient/reactivate/${request.patientId}`).json();
}

function useReactivatePatient() {
  const queryClient = useQueryClient();
  return useMutation((request: ReactivatePatientRequest) => reactivatePatient(request), {
    onSuccess: () => {
      queryClient.invalidateQueries(['Patients']);
    },
  });
}
export { useReactivatePatient };
