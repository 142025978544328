import React from 'react';
import {
  Burger,
  Header,
  HeaderProps,
  Text,
  createStyles,
  Group,
  Box,
  useMantineTheme,
  Image,
} from '@mantine/core';

const useStyles = createStyles((theme) => ({
  header: {
    backgroundColor: theme.colors.dark[5],
    border: 'none',
    padding: theme.spacing.md,
    zIndex: 99,
  },

  version: {
    background: theme.colors.gray[5],
    color: theme.white,
  },

  inner: {
    color: '#FFF',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  burger: {
    [theme.fn.largerThan('md')]: {
      display: 'none',
    },
  },
}));

type HeaderSimpleProps = Omit<HeaderProps, 'children'> & {
  isOpen: boolean;
  setOpen(): void;
};

export const HeaderSimple = (props: HeaderSimpleProps) => {
  const { isOpen, setOpen, ...rest } = props;

  const theme = useMantineTheme();
  const { classes } = useStyles();

  return (
    <Header {...rest} className={classes.header}>
      <Box className={classes.inner}>
        <Group spacing={10} noWrap>
          <Image src="../logo192.png" width={30} alt="Lakeridge Health logo" />
          <Text weight={500} size="xl" color={theme.white}>
            O<sub>2</sub> Delivery Managment
          </Text>
        </Group>
        <Burger opened={isOpen} onClick={setOpen} color={theme.colors.gray[0]} size="sm" />
      </Box>
    </Header>
  );
};
