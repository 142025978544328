import {
  Box,
  Button,
  Center,
  createStyles,
  Divider,
  Group,
  Modal,
  ScrollArea,
  Select,
  Stack,
  Text,
  Textarea,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useMediaQuery } from '@mantine/hooks';
import React, { useEffect, useState } from 'react';
import { IEquipment, ITrackedEquipment } from '../../../models';
import { useEquipmentForTracking, useNewTrackedEquipment, useUserId } from '../../../hooks';
import { DatePickerInput } from '@mantine/dates';
import NumberFormat from 'react-number-format';
import { SquarePlus, X } from 'tabler-icons-react';

const useStyles = createStyles((theme) => ({
  notesCard: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    paddingLeft: theme.spacing.sm,
    paddingRight: theme.spacing.sm,
    paddingTop: 3,
    width: '100%',
    height: '125px',
  },
  infoBox: {
    color: `${theme.colors.gray[6]}`,
    border: `1px solid ${theme.colors.gray[6]}`,
    borderRadius: theme.radius.lg,
    width: 'fit-content',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  groupLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.sm,
    whiteSpace: 'pre',
  },
  fieldLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
  },
  editLabel: {
    fontSize: theme.fontSizes.xs,
    fontWeight: 500,
    whiteSpace: 'pre',
  },
  detail: {
    fontSize: theme.fontSizes.md,
    paddingLeft: 10,
  },
  fieldGroup: {
    width: '48%',
  },
  clickableIcon: {
    color: `${theme.colors.blue[6]}`,
    padding: '0 !important',
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

type TrackedEquipmentCreateModalProps = {
  opened: boolean;
  onCreate: (isCreated: boolean, trackedEquipment: ITrackedEquipment) => void;
  onClose: () => void;
};

const TrackedEquipmentCreateModal = (props: TrackedEquipmentCreateModalProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const currentUserId = useUserId();

  const equipment = useEquipmentForTracking();

  const newTrackedEquipment = useNewTrackedEquipment();

  const [isError, setIsError] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [submitClicked, setSubmitClicked] = useState<boolean>(false);
  const [buttonsLocked, setButtonsLocked] = useState<boolean>(false);

  const [percentOxygenInput, setPecrcentOxygenInput] = useState<number | undefined>();
  const [flowRateInput, setFlowRateInput] = useState<number | undefined>();
  const [onHoursInput, setOnHoursInput] = useState<number | undefined>();
  const [offHoursInput, setOffHoursInput] = useState<number | undefined>();

  function ResetInputs() {
    setPecrcentOxygenInput(undefined);
    setFlowRateInput(undefined);
    setOnHoursInput(undefined);
    setOffHoursInput(undefined);
  }

  const createForm = useForm<ITrackedEquipment>({
    initialValues: {
      trackedEquipmentId: '',
      equipmentId: '',
      equipmentName: '',
      equipmentTypeName: '',
      serialNumber: '',
      warrantyExpiryDate: undefined,
      underWarranty: false,
      percentOxygen: 0,
      flowRate: 0,
      onHours: 0,
      offHours: 0,
      notes: '',
      retired: false,
      readyForClean: false,
      inNeedOfRepair: false,
      outForRepairs: false,
      ready: false,
      lastServicedTechnicianId: undefined,
      lastServicedTechnicianName: undefined,
      lastServicedDateTime: undefined,
      lastCleanedTechnicianId: undefined,
      lastCleanedTechnicianName: undefined,
      lastCleanedDateTime: undefined,
      checkedOutDate: undefined,
      patientId: undefined,
      patientName: undefined,
      lastReturnedDate: undefined,
      lastUpdatedUserId: '',
      lastUpdatedDateTime: undefined,
    },
    validate: {
      equipmentId: (a) => (a === undefined ? 'You must select a type of Equipment' : null),
      serialNumber: (a) =>
        a.length > 50 ? 'Serial Number must be a maximum of 50 characters' : null,
    },
  });

  async function submitTrackedEquipmentCreate() {
    setSubmitClicked(true);

    newTrackedEquipment.mutate({
      equipmentId: createForm.values.equipmentId,
      serialNumber: createForm.values.serialNumber,
      warrantyExpiryDate: createForm.values.warrantyExpiryDate,
      percentOxygen: createForm.values.percentOxygen,
      flowRate: createForm.values.flowRate,
      onHours: createForm.values.onHours,
      offHours: createForm.values.offHours,
      notes: createForm.values.notes,
      lastUpdatedUserId: currentUserId.data!.userId,
    });
  }

  useEffect(() => {
    if (submitClicked) {
      if (newTrackedEquipment.isSuccess) {
        const selectedEquipment: IEquipment = equipment.data!.find(
          (a) => a.equipmentId.toString() === createForm.values.equipmentId.toString()
        )!;

        let underWarranty: boolean = false;
        if (createForm.values.warrantyExpiryDate) {
          if (
            createForm.values.warrantyExpiryDate.getFullYear() >= new Date().getFullYear() &&
            createForm.values.warrantyExpiryDate.getMonth() >= new Date().getMonth() &&
            createForm.values.warrantyExpiryDate.getDate() >= new Date().getDate()
          ) {
            underWarranty = true;
          }
        }

        setSubmitClicked(false);
        props.onCreate(true, {
          trackedEquipmentId: newTrackedEquipment.data.trackedEquipmentId.toString(),
          equipmentId: createForm.values.equipmentId,
          equipmentName: selectedEquipment.name,
          equipmentTypeName: selectedEquipment.equipmentTypeName,
          serialNumber: createForm.values.serialNumber,
          warrantyExpiryDate: createForm.values.warrantyExpiryDate,
          underWarranty: underWarranty,
          percentOxygen: createForm.values.percentOxygen,
          flowRate: createForm.values.flowRate,
          onHours: createForm.values.onHours,
          offHours: createForm.values.offHours,
          notes: createForm.values.notes,
          retired: false,
          readyForClean: false,
          inNeedOfRepair: false,
          outForRepairs: false,
          ready: false,
          lastUpdatedUserId: currentUserId.data!.userId,
          lastUpdatedDateTime: new Date(),
        });
        createForm.reset();
        ResetInputs();
      } else if (newTrackedEquipment.isError) {
        setMessage(
          'Something went wrong, Equipment not Added. Please try again (Check the equipment is not already tracked)'
        );
        setSubmitClicked(false);
        setIsError(true);
        setButtonsLocked(false);
      }
    }
  }, [newTrackedEquipment.status]);

  useEffect(() => {
    if (props.opened) {
      setIsError(false);
      setMessage('');
      setButtonsLocked(false);
    }
  }, [props.opened]);

  return (
    <Modal
      title={<Text color={isError ? 'red' : 'green'}>{message}</Text>}
      opened={props.opened}
      size={isSmallMobile ? 'xs' : 'md'}
      onClose={() => {
        props.onClose();
      }}
    >
      <Stack spacing={0}>
        <Center>
          <Text size="xl" weight={500} align="center">
            New Equipment Information
          </Text>
        </Center>
        <form
          onSubmit={createForm.onSubmit(() => {
            setButtonsLocked(true);
            setIsError(false);
            submitTrackedEquipmentCreate();
          })}
          autoComplete="off"
        >
          <Stack spacing={0}>
            <Text className={classes.groupLabel}>Equipment Details</Text>
            <Divider mb={`calc(${theme.spacing.xs} / 2)`} />
            <Stack spacing={0} mb={theme.spacing.xs}>
              <Text className={classes.editLabel}>Equipment Name & Category</Text>
              <Select
                data={
                  equipment.data
                    ?.sort((a, b) => parseInt(a.equipmentTypeId) - parseInt(b.equipmentTypeId))
                    .map((a) => ({
                      value: `${a.equipmentId}`,
                      label: `${a.name} - ${a.equipmentTypeName}`,
                    })) ?? []
                }
                placeholder="Select Equipment"
                searchable={!isSmallMobile}
                {...createForm.getInputProps('equipmentId')}
              />
            </Stack>
            <Group spacing={theme.spacing.sm} mb={theme.spacing.xs} grow noWrap>
              <Stack spacing={0}>
                <Text className={classes.editLabel}>Serial Number</Text>
                <TextInput {...createForm.getInputProps('serialNumber')} />
              </Stack>
              <Stack spacing={0} align="stretch">
                <Text className={classes.editLabel}>Warranty Expires</Text>
                <DatePickerInput
                  dropdownType="modal"
                  valueFormat="YYYY-MM-DD"
                  firstDayOfWeek={0}
                  clearable
                  modalProps={{
                    title: 'Warranty Expiry Date',
                    withCloseButton: true,
                    closeButtonProps: { style: { position: 'fixed', right: 10 } },
                    yOffset: '15vh',
                    styles: { title: { fontWeight: 500, textAlign: 'center', width: '100%' } },
                  }}
                  {...createForm.getInputProps('warrantyExpiryDate')}
                />
              </Stack>
            </Group>
          </Stack>
          <Group spacing={theme.spacing.xl} align="top" grow noWrap>
            <Stack spacing={0}>
              <Text className={classes.groupLabel}>Metrics</Text>
              <Divider mb={`calc(${theme.spacing.xs} / 2)`} />
              <Group spacing={5} noWrap>
                <Stack spacing={0}>
                  <Text className={classes.editLabel}>
                    %O<sub>2</sub>
                  </Text>
                  <NumberFormat
                    allowEmptyFormatting
                    customInput={TextInput}
                    isAllowed={({ floatValue }) => (floatValue ?? 0) <= 100}
                    autoComplete="nope"
                    rightSection="%"
                    styles={{
                      rightSection: {
                        fontSize: `${theme.fontSizes.sm}`,
                        color: `${theme.colors.gray[5]}`,
                        marginRight: `calc((${theme.spacing.xs} / 2) * -1)`,
                        marginBottom: -2,
                      },
                    }}
                    value={percentOxygenInput}
                    onValueChange={(a) => {
                      setPecrcentOxygenInput(a.floatValue);
                      createForm.setFieldValue('percentOxygen', a.floatValue ?? 0);
                    }}
                  />
                </Stack>
                <Stack spacing={0}>
                  <Text className={classes.editLabel}>Flow</Text>
                  <NumberFormat
                    allowEmptyFormatting
                    customInput={TextInput}
                    autoComplete="nope"
                    rightSection="L/min"
                    styles={{
                      rightSection: {
                        fontSize: `${theme.fontSizes.sm}`,
                        color: `${theme.colors.gray[5]}`,
                        marginRight: `calc(${theme.spacing.xs} / 2)`,
                        marginBottom: -2,
                      },
                    }}
                    value={flowRateInput}
                    onValueChange={(a) => {
                      setFlowRateInput(a.floatValue);
                      createForm.setFieldValue('flowRate', a.floatValue ?? 0);
                    }}
                  />
                </Stack>
              </Group>
            </Stack>
            <Stack spacing={0}>
              <Text className={classes.groupLabel}>Hours Active</Text>
              <Divider mb={`calc(${theme.spacing.xs} / 2)`} />
              <Group spacing={5} noWrap>
                <Stack spacing={0}>
                  <Text className={classes.editLabel}>On Hours</Text>
                  <NumberFormat
                    allowEmptyFormatting
                    customInput={TextInput}
                    decimalScale={0}
                    autoComplete="nope"
                    value={onHoursInput}
                    onValueChange={(a) => {
                      setOnHoursInput(a.floatValue);
                      createForm.setFieldValue('onHours', a.floatValue ?? 0);
                    }}
                  />
                </Stack>
                <Stack spacing={0}>
                  <Text className={classes.editLabel}>Off Hours</Text>
                  <NumberFormat
                    allowEmptyFormatting
                    customInput={TextInput}
                    decimalScale={0}
                    autoComplete="nope"
                    value={offHoursInput}
                    onValueChange={(a) => {
                      setOffHoursInput(a.floatValue);
                      createForm.setFieldValue('offHours', a.floatValue ?? 0);
                    }}
                  />
                </Stack>
              </Group>
            </Stack>
          </Group>
          <Stack spacing={0} mt="xs">
            <Text className={classes.editLabel}>Notes</Text>
            <Box style={{ position: 'relative', padding: 0 }} className={classes.notesCard}>
              <ScrollArea style={{ height: '100%' }}>
                <Textarea
                  variant="unstyled"
                  mx="sm"
                  autosize
                  minRows={4}
                  styles={{ input: { padding: 0 } }}
                  {...createForm.getInputProps('notes')}
                />
              </ScrollArea>
            </Box>
          </Stack>
          <Group position="apart" grow noWrap mt="md">
            <Button variant="outline" disabled={buttonsLocked} type="submit">
              <SquarePlus style={{ marginRight: 5 }} />{' '}
              <Text className={classes.buttonText}>{`Create ${
                !isSmallMobile ? 'Equipment' : ''
              }`}</Text>
            </Button>
            <Button
              variant="outline"
              color="red"
              onClick={() => {
                setIsError(false);
                createForm.reset();
                ResetInputs();
                props.onClose();
              }}
            >
              <X style={{ marginRight: 5 }} /> <Text className={classes.buttonText}>Cancel</Text>
            </Button>
          </Group>
        </form>
      </Stack>
    </Modal>
  );
};

export default TrackedEquipmentCreateModal;
