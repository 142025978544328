import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import externalApi from '../../api';
import { IOrderEquipment } from '../../models';

function fetchOrderEquipmentByOrder(orderId: string): Promise<IOrderEquipment[]> {
  return externalApi.get(`OrderEquipment/ByOrder/${orderId}`).json();
}

export function useOrderEquipmentByOrderMutate() {
  const queryClient = useQueryClient();
  return useMutation((orderId: string) => fetchOrderEquipmentByOrder(orderId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['OrderEquipmentByOrder']);
    },
  });
}
