import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type RetireTrackedEquipmentRequest = {
  trackedEquipmentId: string;
  retiredNotes?: string;
  lastUpdatedUserId: string;
};

type RetireTrackedEquipmentResponse = {
  trackedEquipmentId: number;
};

function retireTrackedEquipment(
  request: RetireTrackedEquipmentRequest
): Promise<RetireTrackedEquipmentResponse> {
  return externalApi.patch(`TrackedEquipment/retire`, { json: request }).json();
}

function useRetireTrackedEquipment() {
  const queryClient = useQueryClient();
  return useMutation((request: RetireTrackedEquipmentRequest) => retireTrackedEquipment(request), {
    onSuccess: () => {
      queryClient.invalidateQueries(['AllTrackedEquipment']);
    },
  });
}
export { useRetireTrackedEquipment };
