import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type RescheduleUndeliveredDeliveryRequest = {
  patientId: string;
  isAMDelivery: boolean;
  requestedDeliveryDate: Date;
  liquid: boolean;
  notes: string;
  DeliveryId: string;
};

type RescheduleUndeliveredDeliveryResponse = {
  orderId: number;
};

function rescheduleUndeliveredDelivery(
  request: RescheduleUndeliveredDeliveryRequest
): Promise<RescheduleUndeliveredDeliveryResponse> {
  return externalApi.post(`Order/Reschedule/Undelivered`, { json: request }).json();
}

function useRescheduleUndeliveredDelivery() {
  const queryClient = useQueryClient();
  return useMutation(
    (request: RescheduleUndeliveredDeliveryRequest) => rescheduleUndeliveredDelivery(request),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('Orders');
        queryClient.invalidateQueries('DeliveredByDate');
        queryClient.invalidateQueries('PendingOrdersByDate');
        queryClient.invalidateQueries('PendingStandingOrdersByDate');
        queryClient.invalidateQueries('IncompleteByDate');
        queryClient.invalidateQueries('UndeliveredByDate');
        queryClient.invalidateQueries('DeliveriesCountByDate');
        queryClient.invalidateQueries('DeliveriesCountByDateRange');
      },
    }
  );
}
export { useRescheduleUndeliveredDelivery };
