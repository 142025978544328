import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type EditSerialNumberRequest = {
  patientEquipmentId: string;
  serialNumberValue: string;
  lastUpdatedUserId: string;
};

type EditSerialNumberResponse = {
  serialNumberId: string;
};

function editSerialNumber(request: EditSerialNumberRequest): Promise<EditSerialNumberResponse> {
  return externalApi.patch(`SerialNumber/patch`, { json: request }).json();
}

function useEditSerialNumber() {
  const queryClient = useQueryClient();
  return useMutation((request: EditSerialNumberRequest) => editSerialNumber(request), {
    onSuccess: () => {
      queryClient.invalidateQueries(['PatientEquipmentByPatient']);
      queryClient.invalidateQueries(['DeliveryEquipmentByDelivery']);
    },
  });
}
export { useEditSerialNumber };
