import {
  Box,
  Button,
  Center,
  Checkbox,
  createStyles,
  Group,
  Modal,
  Select,
  Stack,
  Text,
  Textarea,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { useEffect, useState } from 'react';
import { Pencil, X } from 'tabler-icons-react';
import { useMediaQuery } from '@mantine/hooks';
import { IEquipment } from '../../../models';
import { useNewEquipment, useEquipmentTypes, useUserId } from '../../../hooks';

const useStyles = createStyles((theme) => ({
  label: {
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'break-spaces',
    fontWeight: 500,
  },
  fieldGroup: {
    width: '48%',
  },
  detailLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
  },
  detail: {
    fontSize: theme.fontSizes.md,
    paddingLeft: 10,
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

type EquipmentCreateModalProps = {
  opened: boolean;
  onClose: () => void;
  onCreate: (isCreated: boolean, newEquipment: IEquipment) => void;
};

const EquipmentCreateModal = (props: EquipmentCreateModalProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const initialEquipment = {
    equipmentId: '',
    equipmentTypeId: '',
    equipmentTypeName: '',
    name: '',
    notes: '',
    hasSerialNumber: false,
    lastUpdatedUserId: '',
    lastUpdatedDateTime: new Date(),
  };

  const currentUserId = useUserId();

  const [isError, setIsError] = useState<boolean>(false);
  const [submitClicked, setSubmitClicked] = useState<boolean>(false);
  const [buttonsLocked, setButtonsLocked] = useState<boolean>(false);

  const newEquipment = useNewEquipment();
  const equipmentTypes = useEquipmentTypes();

  const createForm = useForm<IEquipment>({
    initialValues: initialEquipment,
    validate: {
      name: (a) => (a.length > 50 ? 'Equipment Name must be a maximum of 50 characters' : null),
    },
  });

  async function submitEquipment() {
    setSubmitClicked(true);
    setIsError(false);

    newEquipment.mutate({
      equipmentTypeId: createForm.values.equipmentTypeId,
      name: createForm.values.name,
      notes: createForm.values.notes,
      hasSerialNumber: createForm.values.hasSerialNumber,
      lastUpdatedUserId: currentUserId.data!.userId,
    });
  }

  useEffect(() => {
    if (submitClicked) {
      if (newEquipment.isSuccess) {
        setSubmitClicked(false);
        props.onCreate(true, {
          equipmentId: newEquipment.data.equipmentId.toString(),
          equipmentTypeId: createForm.values.equipmentTypeId,
          equipmentTypeName: equipmentTypes.data!.find(
            (a) => a.equipmentTypeId.toString() === createForm.values.equipmentTypeId.toString()
          )!.name,
          name: createForm.values.name,
          notes: createForm.values.notes,
          hasSerialNumber: createForm.values.hasSerialNumber,
          lastUpdatedUserId: currentUserId.data!.userId,
          lastUpdatedDateTime: new Date(),
        });
        createForm.reset();
      } else if (newEquipment.isError) {
        setSubmitClicked(false);
        setIsError(true);
        setButtonsLocked(false);
      }
    }
  }, [newEquipment.status]);

  useEffect(() => {
    setButtonsLocked(false);
  }, [props.opened]);

  return (
    <Modal
      title={
        isError ? (
          <Text color="red">Something went wrong, Equipment not Added. Please try again</Text>
        ) : (
          ''
        )
      }
      opened={props.opened}
      size={isSmallMobile ? 'xs' : 'md'}
      onClose={() => {
        setIsError(false);
        createForm.reset();
        props.onClose();
      }}
    >
      <Box>
        <Center>
          <Text size="xl" weight={500} align="center">
            New Equipment Information
          </Text>
        </Center>
        <form
          onSubmit={createForm.onSubmit(() => {
            setIsError(false);
            setButtonsLocked(true);
            submitEquipment();
          })}
          autoComplete="off"
        >
          <Stack align="stretch" style={{ padding: 5 }}>
            <Stack align="stretch" spacing={0}>
              <Text className={classes.label}>Equipment Name</Text>
              <TextInput
                placeholder="Equipment Name"
                required
                {...createForm.getInputProps('name')}
              />
            </Stack>

            <Stack align="stretch" spacing={0}>
              <Text className={classes.label}>Category</Text>
              <Select
                placeholder="Select a Category"
                data={
                  equipmentTypes.data?.map((a) => ({
                    value: `${a.equipmentTypeId}`,
                    label: `${a.name}`,
                  })) ?? []
                }
                defaultValue="-1"
                searchable={!isSmallMobile}
                required
                {...createForm.getInputProps('equipmentTypeId')}
              />
            </Stack>

            <Stack align="stretch" spacing={0}>
              <Text className={classes.label}>Notes</Text>
              <Textarea minRows={4} {...createForm.getInputProps('notes')} />
            </Stack>

            <Group noWrap spacing={10}>
              <Checkbox {...createForm.getInputProps('hasSerialNumber')} />
              <Text className={classes.label}>
                Does This Equipment have a Tracked Serial Number?
              </Text>
            </Group>

            <Group position="apart" grow noWrap mt="xs">
              <Button variant="outline" disabled={buttonsLocked} type="submit" px={10}>
                <Pencil style={{ marginRight: 5 }} />{' '}
                <Text className={classes.buttonText}>{`Create ${
                  !isSmallMobile ? 'Equipment' : ''
                }`}</Text>
              </Button>
              <Button
                variant="outline"
                color="red"
                onClick={() => {
                  setIsError(false);
                  createForm.reset();
                  props.onClose();
                }}
              >
                <X style={{ marginRight: 5 }} /> <Text className={classes.buttonText}>Cancel</Text>
              </Button>
            </Group>
          </Stack>
        </form>
      </Box>
    </Modal>
  );
};

export default EquipmentCreateModal;
