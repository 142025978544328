import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type EditTrackedEquipmentRequest = {
  trackedEquipmentId: string;
  equipmentId: string;
  serialNumber: string;
  warrantyExpiryDate?: Date;
  lastUpdatedUserId: string;
};

type EditTrackedEquipmentResponse = {
  trackedEquipmentId: number;
};

function editTrackedEquipment(
  request: EditTrackedEquipmentRequest
): Promise<EditTrackedEquipmentResponse> {
  return externalApi.patch(`TrackedEquipment/patch/details`, { json: request }).json();
}

function useEditTrackedEquipmentDetails() {
  const queryClient = useQueryClient();
  return useMutation((request: EditTrackedEquipmentRequest) => editTrackedEquipment(request), {
    onSuccess: () => {
      queryClient.invalidateQueries(['AllTrackedEquipment']);
    },
  });
}
export { useEditTrackedEquipmentDetails };
