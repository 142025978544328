import React from 'react';
import { useQuery } from 'react-query';
import externalApi from '../../../api';

type ReportDeliveriesCountByDateRequest = {
  date: Date;
  isAMTimeframe: boolean;
  isFullDayTimeframe: boolean;
};

type ReportDeliveriesCountByDateResponse = {
  currentDate: Date;
  pendingCount: number;
  deliveredCount: number;
  undeliveredCount: number;
};

function reportReportDeliveriesCountByDate(
  request: ReportDeliveriesCountByDateRequest
): Promise<ReportDeliveriesCountByDateResponse> {
  return externalApi.post(`Delivery/Report/ByDate/Count`, { json: request }).json();
}

function useReportDeliveriesCountByDate(request: ReportDeliveriesCountByDateRequest) {
  return useQuery(['ReportDeliveriesCountByDate'], () =>
    reportReportDeliveriesCountByDate(request)
  );
}
export { useReportDeliveriesCountByDate };
