import React from 'react';
import { useQuery } from 'react-query';
import externalApi from '../../api';
import { IStandingOrderEquipment } from '../../models';

function fetchStandingOrderEquipmentByStandingOrder(
  standingOrderId: string
): Promise<IStandingOrderEquipment[]> {
  return externalApi.get(`StandingOrderEquipment/ByStandingOrder/${standingOrderId}`).json();
}

export function useStandingOrderEquipmentByStandingOrder(standingOrderId: string) {
  return useQuery(['StandingOrderEquipmentByStandingOrder'], () =>
    fetchStandingOrderEquipmentByStandingOrder(standingOrderId)
  );
}
