import {
  ActionIcon,
  Button,
  Center,
  createStyles,
  Divider,
  Group,
  Modal,
  ScrollArea,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { ITrackedEquipment, ITrackedPatientEquipment } from '../../../models';
import TrackedEquipmentDetailSection from './TrackedEquipmentDetailSection';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { usePatient, useTrackedEquipment } from '../../../hooks';
import { ArrowLeft, User } from 'tabler-icons-react';

const useStyles = createStyles((theme) => ({
  notesCard: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    paddingLeft: theme.spacing.sm,
    paddingRight: theme.spacing.sm,
    paddingTop: 3,
    width: '100%',
    height: '100px',
  },
  infoBox: {
    color: `${theme.colors.gray[6]}`,
    border: `1px solid ${theme.colors.gray[6]}`,
    borderRadius: theme.radius.lg,
    width: 'fit-content',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  groupLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.sm,
    whiteSpace: 'pre',
  },
  fieldLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
  },
  detail: {
    fontSize: theme.fontSizes.md,
    paddingLeft: 10,
  },
  fieldGroup: {
    width: '48%',
  },
  clickableIcon: {
    color: `${theme.colors.blue[6]}`,
    padding: '0 !important',
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

type TrackedPatientEquipmentDetailModalProps = {
  trackedEquipment?: ITrackedEquipment;
  trackedPatientEquipment?: ITrackedPatientEquipment;
  opened: boolean;
  readOnly?: boolean;
  onClose: () => void;
};

const TrackedPatientEquipmentDetailModal = (props: TrackedPatientEquipmentDetailModalProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const patient = usePatient(props.trackedPatientEquipment?.patientId ?? '0');
  const fetchedTrackedEquipment = useTrackedEquipment(
    props.trackedPatientEquipment?.trackedEquipmentId ?? '0'
  );

  const [isFetched, setIsFetched] = useState<boolean>(false);

  useEffect(() => {
    patient.refetch();
  }, [props.trackedPatientEquipment?.patientId]);

  useEffect(() => {
    if (!props.trackedEquipment && props.trackedPatientEquipment && props.opened) {
      setIsFetched(false);
      fetchedTrackedEquipment.refetch().then(() => {
        setIsFetched(true);
      });
    }
  }, [props.trackedPatientEquipment, props.opened]);

  return (
    <Modal
      opened={props.opened}
      size={isSmallMobile ? 'xs' : 'md'}
      onClose={() => {
        props.onClose();
      }}
    >
      <Stack spacing={0} style={{ position: 'relative' }}>
        <Center>
          <Text size="xl" weight={500} align="center">
            Equipment Patient History
          </Text>
        </Center>
        {props.trackedEquipment && (
          <TrackedEquipmentDetailSection trackedEquipment={props.trackedEquipment} readOnly />
        )}
        {props.trackedPatientEquipment && isFetched && (
          <TrackedEquipmentDetailSection trackedEquipment={fetchedTrackedEquipment.data} readOnly />
        )}
        <Text size="sm" color={theme.colors.gray[5]} align="left" mt={theme.spacing.xs}>
          Instance Details
        </Text>
        <Divider mb={`calc(${theme.spacing.xs} / 2)`} />
        <Stack spacing={theme.spacing.xs}>
          <Group spacing={theme.spacing.sm} mb={-3} noWrap>
            <Stack spacing={0}>
              <Text className={classes.fieldLabel}>Patient Name</Text>
              <Group spacing={theme.spacing.xs}>
                <Text
                  className={classes.detail}
                >{`${props.trackedPatientEquipment?.patientName}`}</Text>
                {!props.readOnly && (
                  <ActionIcon
                    variant="transparent"
                    className={classes.clickableIcon}
                    onClick={() => {
                      if (patient.isSuccess) {
                        navigate('../patients', {
                          state: {
                            patient: patient.data,
                          },
                        });
                      }
                    }}
                  >
                    <User style={{ marginBottom: 4 }} />
                  </ActionIcon>
                )}
              </Group>
            </Stack>
          </Group>
          <Group spacing={theme.spacing.sm} noWrap>
            <Stack spacing={0} style={{ width: '50%' }}>
              <Text className={classes.fieldLabel}>Delivered On</Text>
              <Text className={classes.detail}>
                {props.trackedPatientEquipment?.deliveredDateTime
                  ? dayjs(props.trackedPatientEquipment?.deliveredDateTime).format('YYYY-MM-DD')
                  : '-'}
              </Text>
            </Stack>
            <Stack spacing={0}>
              <Text className={classes.fieldLabel}>Delivered At</Text>
              <Text className={classes.detail}>
                {props.trackedPatientEquipment?.deliveredDateTime
                  ? dayjs(props.trackedPatientEquipment?.deliveredDateTime).format('h:mm A')
                  : '-'}
              </Text>
            </Stack>
          </Group>
          <Group spacing={theme.spacing.sm} noWrap>
            <Stack spacing={0} style={{ width: '50%' }}>
              <Text className={classes.fieldLabel}>Returned On</Text>
              <Text className={classes.detail}>
                {props.trackedPatientEquipment?.returnedDateTime
                  ? dayjs(props.trackedPatientEquipment?.returnedDateTime).format('YYYY-MM-DD')
                  : '-'}
              </Text>
            </Stack>
            <Stack spacing={0}>
              <Text className={classes.fieldLabel}>Returned At</Text>
              <Text className={classes.detail}>
                {props.trackedPatientEquipment?.returnedDateTime
                  ? dayjs(props.trackedPatientEquipment?.returnedDateTime).format('h:mm A')
                  : '-'}
              </Text>
            </Stack>
          </Group>
        </Stack>
        <Text size="sm" color={theme.colors.gray[5]} align="left" mt={theme.spacing.xs}>
          Notes
        </Text>
        <Divider mb={`calc(${theme.spacing.xs} / 2)`} />
        {!props.trackedPatientEquipment?.deliveryNotes &&
          !props.trackedPatientEquipment?.returnNotes && (
            <Center>
              <Text color={theme.colors.gray[5]}>
                <i>No Notes</i>
              </Text>
            </Center>
          )}
        {props.trackedPatientEquipment?.deliveryNotes && (
          <Stack spacing={0}>
            <Text className={classes.fieldLabel}>Delivery Notes</Text>
            <ScrollArea className={classes.notesCard}>
              <Text>{`${props.trackedPatientEquipment?.deliveryNotes}`}</Text>
            </ScrollArea>
          </Stack>
        )}
        {props.trackedPatientEquipment?.returnNotes && (
          <Stack spacing={0}>
            <Text className={classes.fieldLabel}>Returned Notes</Text>
            <ScrollArea className={classes.notesCard}>
              <Text>{`${props.trackedPatientEquipment?.returnNotes}`}</Text>
            </ScrollArea>
          </Stack>
        )}
        <Button
          variant="outline"
          mt={theme.spacing.xs}
          onClick={() => {
            props.onClose();
          }}
        >
          <ArrowLeft style={{ marginRight: 5 }} />
          Return to History
        </Button>
      </Stack>
    </Modal>
  );
};

export default TrackedPatientEquipmentDetailModal;
