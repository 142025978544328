import React, { useEffect, useState } from 'react';
import { Box, Button, Divider, Group, ScrollArea, Table, Text, TextInput } from '@mantine/core';
import { Page } from '../common/Page/Page';
import { PageHeader } from '../common/Page/Header/PageHeader';
import { IUser } from '../models';
import { useUsers } from '../hooks';
import { Crown, Search, UserCircle, UserPlus } from 'tabler-icons-react';
import { Th, UserCreateModal, UserDetailModal, UserEditModal } from '../components';

function filterData(data: IUser[], search: string, visibleKeys: string[]) {
  const keys = visibleKeys;
  const query = search.toLowerCase().trim();
  return data.filter((item) =>
    keys.some((key) => (item[key] ?? '-').toString().toLowerCase().includes(query))
  );
}

function sortData(
  data: IUser[],
  payload: { sortBy: keyof IUser; reversed: boolean; search: string },
  visibleKeys: string[]
) {
  if (!payload.sortBy) {
    return filterData(data, payload.search, visibleKeys);
  }

  return filterData(
    [...data].sort((a, b) => {
      if (payload.reversed) {
        return (b[payload.sortBy] ?? '000ForceToBottom')
          .toString()
          .localeCompare((a[payload.sortBy] ?? '000ForceToBottom').toString());
      }

      return (a[payload.sortBy] ?? 'zzzForceToBottom')
        .toString()
        .localeCompare((b[payload.sortBy] ?? 'zzzForceToBottom').toString());
    }),
    payload.search,
    visibleKeys
  );
}

type Props = {};

const ManageUsersPage = (props: Props) => {
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [userCreated, setUserCreated] = useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [userUpdated, setUserUpdated] = useState<boolean>(false);
  const [updateError, setUpdateError] = useState<boolean>(false);
  const [detailModalOpen, setDetailModalOpen] = useState<boolean>(false);
  const [detailUser, setDetailUser] = useState<IUser>({
    userId: '',
    userFirstName: '',
    userLastName: '',
    userFullName: '',
    userFullNameFormatted: '',
    userEmailAddress: '',
    userTypeName: '',
    userTypeIds: [],
    isAdmin: false,
  });

  const defaultKeys: string[] = [
    'userFullName',
    'userFullNameFormatted',
    'userTypeName',
    'userEmailAddress',
  ];

  const users = useUsers();

  const [search, setSearch] = useState('');
  const [sortedData, setSortedData] = useState(users.data);
  const [sortBy, setSortBy] = useState<keyof IUser>(null!);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);

  const setSorting = (field: keyof IUser) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setSortedData(sortData(users.data!, { sortBy: field, reversed, search }, defaultKeys));
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSearch(value);
    setSortedData(
      sortData(users.data!, { sortBy, reversed: reverseSortDirection, search: value }, defaultKeys)
    );
  };

  const rows = sortedData?.map((row) => (
    <tr
      key={row.userId}
      style={{ cursor: 'pointer' }}
      onClick={() => {
        setDetailUser(row);
        setDetailModalOpen(true);
      }}
    >
      <td style={{ width: '30%', wordWrap: 'break-word' }}>{`${row.userFullNameFormatted}`}</td>
      <td style={{ width: '30%', wordWrap: 'break-word' }}>{`${row.userEmailAddress}`}</td>
      <td style={{ width: '30%', wordWrap: 'break-word' }}>{row.userTypeName}</td>
    </tr>
  ));

  useEffect(() => {
    if (!users.data) {
      setSortedData(users.data);
    } else {
      setSortedData(
        sortData(users.data, { sortBy, reversed: reverseSortDirection, search }, defaultKeys)
      );
    }
  }, [users.data, reverseSortDirection, search, sortBy]);

  return (
    <Page header={<PageHeader title={'Manage Users'} subtitle={'View, Add, and Edit Users'} />}>
      <Box style={{ height: '100%' }}>
        <Text size="xl" weight={500} align="center" mb="lg">
          Click on a record to view it in full
        </Text>
        {users.data && (
          <Box style={{ height: '100%' }}>
            <TextInput
              placeholder="Search by any field"
              mb="md"
              icon={<Search size={14} />}
              value={search}
              onChange={handleSearchChange}
            />
            <Divider />
            <ScrollArea type="hover" style={{ width: '100%', height: 550, tableLayout: 'fixed' }}>
              <Table style={{ width: '100%', tableLayout: 'fixed' }}>
                <thead>
                  <tr>
                    <Th
                      sorted={sortBy === 'userFullNameFormatted'}
                      reversed={reverseSortDirection}
                      onSort={() => setSorting('userFullNameFormatted')}
                      style={{ width: '30%' }}
                    >
                      Name
                    </Th>
                    <Th
                      sorted={sortBy === 'userEmailAddress'}
                      reversed={reverseSortDirection}
                      onSort={() => setSorting('userEmailAddress')}
                      style={{ width: '30%' }}
                    >
                      Email Address (Login)
                    </Th>
                    <Th
                      sorted={sortBy === 'userTypeName'}
                      reversed={reverseSortDirection}
                      onSort={() => setSorting('userTypeName')}
                      style={{ width: '30%' }}
                    >
                      <Group noWrap spacing={0}>
                        User Type
                      </Group>
                    </Th>
                  </tr>
                </thead>
              </Table>
              <Table
                highlightOnHover
                horizontalSpacing="md"
                verticalSpacing="xs"
                style={{ tableLayout: 'fixed', width: '100%' }}
              >
                <tbody>
                  {rows && rows.length > 0 ? (
                    rows
                  ) : (
                    <tr>
                      <td colSpan={Object.keys(users.data).length}>
                        <Text weight={500} align="center">
                          Nothing found
                        </Text>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </ScrollArea>
            <Divider />
            <Group noWrap grow position="apart" mt="lg">
              <Button
                variant="outline"
                mb="lg"
                onClick={() => {
                  setCreateModalOpen(true);
                }}
              >
                <UserPlus style={{ marginRight: 5 }} />
                Add a New User
              </Button>
            </Group>
          </Box>
        )}
      </Box>
      <UserDetailModal
        user={detailUser}
        opened={detailModalOpen}
        isCreated={userCreated}
        isUpdated={userUpdated}
        isError={updateError}
        onClose={() => {
          setDetailModalOpen(false);
          setUpdateError(false);
          setUserUpdated(false);
          setUserCreated(false);
        }}
        onEdit={() => {
          setUserCreated(false);
          setDetailModalOpen(false);
          setEditModalOpen(true);
        }}
      />
      <UserEditModal
        user={detailUser}
        opened={editModalOpen}
        onClose={() => {
          setEditModalOpen(false);
          setDetailModalOpen(true);
        }}
        onEdited={(isError, updatedUser) => {
          setEditModalOpen(false);
          setDetailModalOpen(true);
          setUpdateError(isError);
          setUserUpdated(!isError);
          setDetailUser(updatedUser);
        }}
      />
      <UserCreateModal
        opened={createModalOpen}
        onClose={() => {
          setCreateModalOpen(false);
        }}
        onCreated={(newUser) => {
          setUserCreated(true);
          setDetailUser(newUser);
          setCreateModalOpen(false);
          setDetailModalOpen(true);
        }}
      />
    </Page>
  );
};

export default ManageUsersPage;
