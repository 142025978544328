import {
  Box,
  Button,
  Center,
  createStyles,
  Group,
  Modal,
  ScrollArea,
  Stack,
  Text,
  Textarea,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React, { useEffect, useState } from 'react';
import { ITrackedEquipment } from '../../../models';
import { useRetireTrackedEquipment, useUserId } from '../../../hooks';
import { Archive, X } from 'tabler-icons-react';
import TrackedEquipmentDetailSection from './TrackedEquipmentDetailSection';

const useStyles = createStyles((theme) => ({
  notesCard: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    paddingLeft: theme.spacing.sm,
    paddingRight: theme.spacing.sm,
    paddingTop: 3,
    width: '100%',
    height: '125px',
  },
  infoBox: {
    color: `${theme.colors.gray[6]}`,
    border: `1px solid ${theme.colors.gray[6]}`,
    borderRadius: theme.radius.lg,
    width: 'fit-content',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  groupLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.sm,
    whiteSpace: 'pre',
  },
  fieldLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
  },
  editLabel: {
    fontSize: theme.fontSizes.xs,
    fontWeight: 500,
    whiteSpace: 'pre',
  },
  detail: {
    fontSize: theme.fontSizes.md,
    paddingLeft: 10,
  },
  fieldGroup: {
    width: '48%',
  },
  clickableIcon: {
    color: `${theme.colors.blue[6]}`,
    padding: '0 !important',
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

type TrackedEquipmentRetireModalProps = {
  opened: boolean;
  trackedEquipment: ITrackedEquipment;
  onRetire: (notes: string) => void;
  onClose: () => void;
};

const TrackedEquipmentRetireModal = (props: TrackedEquipmentRetireModalProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const currentUserId = useUserId();

  const retireTrackedEquipment = useRetireTrackedEquipment();

  const [isError, setIsError] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');

  const [notes, setNotes] = useState<string>('');

  useEffect(() => {
    if (retireTrackedEquipment.isSuccess) {
      props.onRetire(notes);
    }
    if (retireTrackedEquipment.isError) {
      setMessage('Something went wrong, Equipment not Retired. Please try again');
      setIsError(true);
    }
  }, [retireTrackedEquipment.status]);

  useEffect(() => {
    if (props.opened) {
      setIsError(false);
      setMessage('');
      setNotes('');
    }
  }, [props.opened]);

  return (
    <Modal
      title={<Text color={isError ? 'red' : 'green'}>{message}</Text>}
      opened={props.opened}
      size={isSmallMobile ? 'xs' : 'md'}
      onClose={() => {
        props.onClose();
      }}
    >
      <Stack spacing={0}>
        <Center>
          <Text size="xl" weight={500} align="center">
            Retire Equipment
          </Text>
        </Center>
        <TrackedEquipmentDetailSection trackedEquipment={props.trackedEquipment} readOnly />
      </Stack>
      <Stack spacing={0}>
        <Text className={classes.editLabel}>Retirement Notes</Text>
        <Box style={{ position: 'relative', padding: 0 }} className={classes.notesCard}>
          <ScrollArea style={{ height: '100%' }}>
            <Textarea
              variant="unstyled"
              mx="sm"
              autosize
              minRows={4}
              value={notes}
              onChange={(a) => {
                setNotes(a.target.value);
              }}
              styles={{ input: { padding: 0 } }}
            />
          </ScrollArea>
        </Box>
      </Stack>
      <Group grow noWrap mt={theme.spacing.xs}>
        <Button
          variant="outline"
          onClick={() => {
            retireTrackedEquipment.mutate({
              trackedEquipmentId: props.trackedEquipment.trackedEquipmentId,
              retiredNotes: notes.length > 0 ? notes : undefined,
              lastUpdatedUserId: currentUserId.data!.userId,
            });
          }}
        >
          <Archive style={{ marginRight: 5 }} />
          <Text className={classes.buttonText}>Retire</Text>
        </Button>
        <Button
          variant="outline"
          onClick={() => {
            props.onClose();
          }}
        >
          <X style={{ marginRight: 5 }} />
          <Text className={classes.buttonText}>Cancel</Text>
        </Button>
      </Group>
    </Modal>
  );
};

export default TrackedEquipmentRetireModal;
