import { ActionIcon, Box, Group, ScrollArea, Text, useMantineTheme } from '@mantine/core';
import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'tabler-icons-react';

type HelpBoxProps = {
  title: string;
  children?: React.ReactNode;
};

const HelpBox = (props: HelpBoxProps) => {
  const theme = useMantineTheme();

  const [collapsed, setCollapsed] = useState<boolean>(true);

  return (
    <Box style={{ border: `1px solid ${theme.colors.gray[6]}`, borderRadius: theme.radius.sm }}>
      <Box py={5} onClick={() => setCollapsed(!collapsed)} style={{ cursor: 'pointer' }}>
        <Group position="apart" px={15}>
          <Text color={theme.colors.gray[7]} weight={500}>
            {props.title}
          </Text>
          {collapsed && <ChevronDown color={theme.colors.gray[7]} />}
          {!collapsed && <ChevronUp color={theme.colors.gray[7]} />}
        </Group>
      </Box>
      {!collapsed && (
        <Box style={{ borderTop: `1px solid ${theme.colors.gray[6]}` }}>
          <ScrollArea style={{ width: '100%' }} py={5} px={15}>
            {props.children}
          </ScrollArea>
        </Box>
      )}
    </Box>
  );
};

export default HelpBox;
