import React from 'react';
import { Group, Stack, Text } from '@mantine/core';
import { Page } from '../common/Page/Page';
import { PageHeader } from '../common/Page/Header/PageHeader';

type Props = {};

const NothingFoundPage = (props: Props) => {
  return (
    <Page header={<PageHeader title={'Nothing Found'} subtitle={'There is no page at this URL'} />}>
      <Stack>
        <Text size="xl" weight={500}>
          Nothing Here
        </Text>
      </Stack>
    </Page>
  );
};

export default NothingFoundPage;
