import {
  ActionIcon,
  Box,
  createStyles,
  Group,
  useMantineTheme,
  Text,
  Popover,
  TextInput,
  Button,
  Anchor,
  Select,
  Grid,
  LoadingOverlay,
  Stack,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useMediaQuery } from '@mantine/hooks';
import React, { useEffect, useState } from 'react';
import { CalendarEvent, ChevronLeft, ChevronRight } from 'tabler-icons-react';
import { useDeliveriesCountByDateRange } from '../../hooks';
import CalendarDate from './CalendarDate';

const useStyles = createStyles((theme, _params) => ({
  pageBoundary: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      marginLeft: -10,
      marginRight: -10,
    },
  },
  calendar: {
    border: `1px solid ${theme.colors.dark[8]}`,
    borderRadius: theme.radius.md,
  },
  weekday: {
    background: theme.colors.blue[1],
    height: '35px',
    width: '15%',
    '&:not(:last-of-type)': {
      borderRight: `1px solid ${theme.colors.dark[8]}`,
    },
  },
  weekdayLabel: {
    paddingTop: '5px',
    textAlign: 'center',
    fontWeight: 500,
  },
  week: {
    '&:last-of-type': {
      borderBottomLeftRadius: `calc(${theme.radius.sm} * 1.5)`,
      borderBottomRightRadius: `calc(${theme.radius.sm} * 1.5)`,
    },
  },
  month: {
    background: theme.colors.blue[5],
    borderTopLeftRadius: `calc(${theme.radius.sm} * 1.5)`,
    borderTopRightRadius: `calc(${theme.radius.sm} * 1.5)`,
    borderBottom: `1px solid ${theme.colors.dark[8]}`,
    width: '100%',
  },
  monthIcon: {
    color: 'white',
    '&:first-of-type': {
      [`@media (min-width: ${theme.breakpoints.sm})`]: {
        marginRight: 100,
      },
      [`@media (max-width: ${theme.breakpoints.sm})`]: {
        marginRight: 15,
      },
    },
    '&:last-of-type': {
      [`@media (min-width: ${theme.breakpoints.sm})`]: {
        marginLeft: 100,
      },
      [`@media (max-width: ${theme.breakpoints.sm})`]: {
        marginLeft: 15,
      },
    },
  },
}));

function getCalendarDates(year: number, month: number): Date[] {
  let firstOfMonth = new Date(Date.UTC(year, month));

  let preDates: Date[] = [];
  let monthDates: Date[] = [];
  let postDates: Date[] = [];
  let calendarDates: Date[] = [];

  for (let i = 1; i <= firstOfMonth.getUTCDay(); i++) {
    preDates.push(new Date(new Date(Date.UTC(year, month, firstOfMonth.getUTCDate() - i))));
  }
  preDates.reverse();

  let monthDate = new Date(Date.UTC(year, month));
  while (monthDate.getUTCMonth() === month) {
    monthDates.push(
      new Date(
        Date.UTC(monthDate.getUTCFullYear(), monthDate.getUTCMonth(), monthDate.getUTCDate())
      )
    );
    monthDate.setUTCDate(monthDate.getUTCDate() + 1);
  }

  const lastOfMonth = new Date(Date.UTC(year, firstOfMonth.getUTCMonth() + 1, 0));
  const firstOfNextMonth = new Date(Date.UTC(year, firstOfMonth.getUTCMonth() + 1, 1));
  for (let i = lastOfMonth.getUTCDay() + 1; i <= 7; i++) {
    postDates.push(
      new Date(
        Date.UTC(
          firstOfMonth.getUTCMonth() !== 11 ? year : year + 1,
          firstOfNextMonth.getUTCMonth(),
          firstOfNextMonth.getUTCDate() + (i - lastOfMonth.getUTCDay() - 1)
        )
      )
    );
  }

  calendarDates = calendarDates.concat(preDates, monthDates, postDates);

  return calendarDates;
}

function getWeeks(dates: Date[]) {
  const weekLength: number = 7;
  let weeks: Date[][] = [];

  for (let i = 0; i < dates.length - 1; i += weekLength) {
    weeks.push(dates.slice(i, i + weekLength));
  }

  return weeks;
}

type CalendarProps = {
  initialDate: Date;
  onDateSelected: (selcetedDate: Date) => void;
};

const Calendar = (props: CalendarProps) => {
  const { classes, cx } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const monthNames: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const [year, setYear] = useState<number>(new Date(props.initialDate).getFullYear());
  const [month, setMonth] = useState<number>(new Date(props.initialDate).getMonth());

  const [dateMenuOpen, setDateMenuOpen] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date(props.initialDate));

  const monthForm = useForm({
    initialValues: { month: month.toString(), year: year.toString() },
    validate: {
      year: (a) => (a.length !== 4 && !isNaN(parseInt(a)) ? 'Must be a valid year' : null),
    },
  });

  const dates = getCalendarDates(year, month);

  const deliveriesCountByDateRange = useDeliveriesCountByDateRange({
    startDate: dates[0],
    endDate: dates[dates.length - 1],
  });

  useEffect(() => {
    deliveriesCountByDateRange.refetch();
  }, []);

  let weeks = getWeeks(dates).map((a, ai) => {
    return (
      <Group
        key={`${a[0].getUTCMonth()}-${a[0].getUTCDate()}`}
        position="apart"
        spacing={0}
        noWrap
        className={classes.week}
      >
        {a.map((b, bi) => {
          let i = 7 * ai + bi;
          return (
            <CalendarDate
              key={`${b.getUTCMonth()}-${b.getUTCDate()}`}
              date={b.getUTCDate()}
              month={b.getUTCMonth()}
              year={b.getUTCFullYear()}
              pendingCount={
                deliveriesCountByDateRange.data
                  ? deliveriesCountByDateRange.data[i]
                    ? deliveriesCountByDateRange.data[i].pendingCount
                    : 0
                  : 0
              }
              deliveredCount={
                deliveriesCountByDateRange.data
                  ? deliveriesCountByDateRange.data[i]
                    ? deliveriesCountByDateRange.data[i].deliveredCount
                    : 0
                  : 0
              }
              undeliveredCount={
                deliveriesCountByDateRange.data
                  ? deliveriesCountByDateRange.data[i]
                    ? deliveriesCountByDateRange.data[i].undeliveredCount
                    : 0
                  : 0
              }
              incompleteCount={
                deliveriesCountByDateRange.data
                  ? deliveriesCountByDateRange.data[i]
                    ? deliveriesCountByDateRange.data[i].incompleteCount
                    : 0
                  : 0
              }
              anyUrgent={
                deliveriesCountByDateRange.data
                  ? deliveriesCountByDateRange.data[i]
                    ? deliveriesCountByDateRange.data[i].anyUrgent
                    : false
                  : false
              }
              isToday={
                new Date().getUTCDate() === b.getUTCDate() &&
                new Date().getUTCMonth() === b.getUTCMonth() &&
                new Date().getUTCFullYear() === b.getUTCFullYear()
              }
              selectedDate={selectedDate}
              onClick={() => {
                setSelectedDate(new Date(b.getUTCFullYear(), b.getUTCMonth(), b.getUTCDate()));
              }}
            />
          );
        })}
      </Group>
    );
  });

  async function submitMonthChange() {
    setDateMenuOpen(false);
    deliveriesCountByDateRange.remove();
    setMonth(parseInt(monthForm.values.month));
    setYear(parseInt(monthForm.values.year));
  }

  useEffect(() => {
    monthForm.setFieldValue('month', month.toString());
    deliveriesCountByDateRange.refetch();
  }, [month]);

  useEffect(() => {
    monthForm.setFieldValue('year', year.toString());
    deliveriesCountByDateRange.refetch();
  }, [year]);

  useEffect(() => {
    props.onDateSelected(selectedDate);
  }, [selectedDate]);

  return (
    <Box className={classes.calendar}>
      <Group className={classes.month} position="center">
        <ActionIcon
          className={classes.monthIcon}
          variant="transparent"
          onClick={() => {
            deliveriesCountByDateRange.remove();
            if (month === 0) {
              setYear(year - 1);
              setMonth(11);
            } else {
              setMonth(month - 1);
            }
          }}
        >
          <ChevronLeft />
        </ActionIcon>
        <Group position="center" spacing={5} style={{ width: 150 }}>
          <Popover
            opened={dateMenuOpen}
            onClose={() => {
              setDateMenuOpen(false);
            }}
            withArrow
            position="bottom"
          >
            <Popover.Target>
              <Button
                variant="subtle"
                style={{ background: 'transparent' }}
                onClick={() => setDateMenuOpen((a) => !a)}
              >
                <CalendarEvent color="white" style={{ marginRight: 5 }} />
                <Text color="white" weight={500}>
                  {`${monthNames[month]} ${year.toString()}`}
                </Text>
              </Button>
            </Popover.Target>
            <Popover.Dropdown>
              <form
                onSubmit={monthForm.onSubmit(() => {
                  submitMonthChange();
                })}
                autoComplete="off"
              >
                <Stack>
                  <Grid>
                    <Grid.Col span={3}>
                      <Text align="right" mt={5}>
                        Month:
                      </Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <Select
                        data={monthNames.map((a) => {
                          return { value: monthNames.indexOf(a).toString(), label: a };
                        })}
                        {...monthForm.getInputProps('month')}
                      />
                    </Grid.Col>
                  </Grid>
                  <Grid>
                    <Grid.Col span={3}>
                      <Text align="right" mt={5}>
                        Year:
                      </Text>
                    </Grid.Col>
                    <Grid.Col span={9}>
                      <TextInput {...monthForm.getInputProps('year')} />
                    </Grid.Col>
                  </Grid>
                  <Group position="apart">
                    <Anchor
                      onClick={() => {
                        setDateMenuOpen(false);
                        monthForm.reset();
                      }}
                    >
                      Cancel
                    </Anchor>
                    <Button type="submit" variant="outline">
                      <CalendarEvent style={{ marginRight: 5 }} />
                      Update
                    </Button>
                  </Group>
                </Stack>
              </form>
            </Popover.Dropdown>
          </Popover>
        </Group>

        <ActionIcon
          className={classes.monthIcon}
          variant="transparent"
          onClick={() => {
            deliveriesCountByDateRange.remove();
            if (month === 11) {
              setYear(year + 1);
              setMonth(0);
            } else {
              setMonth(month + 1);
            }
          }}
        >
          <ChevronRight />
        </ActionIcon>
      </Group>
      <Box style={{ position: 'relative' }}>
        <Group position="apart" spacing={0} noWrap>
          <Box className={classes.weekday}>
            <Text className={classes.weekdayLabel}>{!isSmallMobile ? 'Sunday' : 'Sun'}</Text>
          </Box>
          <Box className={classes.weekday}>
            <Text className={classes.weekdayLabel}>{!isSmallMobile ? 'Monday' : 'Mon'}</Text>
          </Box>
          <Box className={classes.weekday}>
            <Text className={classes.weekdayLabel}>{!isSmallMobile ? 'Tuesday' : 'Tues'}</Text>
          </Box>
          <Box className={classes.weekday}>
            <Text className={classes.weekdayLabel}>{!isSmallMobile ? 'Wednesday' : 'Wed'}</Text>
          </Box>
          <Box className={classes.weekday}>
            <Text className={classes.weekdayLabel}>{!isSmallMobile ? 'Thursday' : 'Thurs'}</Text>
          </Box>
          <Box className={classes.weekday}>
            <Text className={classes.weekdayLabel}>{!isSmallMobile ? 'Friday' : 'Fri'}</Text>
          </Box>
          <Box className={cx(classes.weekday)}>
            <Text className={classes.weekdayLabel}>{!isSmallMobile ? 'Saturday' : 'Sat'}</Text>
          </Box>
        </Group>
        <LoadingOverlay visible={!deliveriesCountByDateRange.isSuccess} zIndex={1} />
        {weeks}
      </Box>
    </Box>
  );
};

export default Calendar;
