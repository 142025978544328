import {
  Button,
  Center,
  createStyles,
  Divider,
  Modal,
  ScrollArea,
  Stack,
  Table,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { ITrackedEquipment, ITrackedPatientEquipment } from '../../../../models';
import {
  TrackedEquipmentPatientSection,
  TrackedEquipmentDetailSection,
  Th,
  InfiniteScrollArea,
} from '../../../../components';
import { ArrowLeft, Note, Search } from 'tabler-icons-react';
import { useEffect, useState } from 'react';
import { useTrackedPatientEquipmentByTrackedEquipment } from '../../../../hooks';
import dayjs from 'dayjs';

const useStyles = createStyles((theme) => ({
  notesCard: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    paddingLeft: theme.spacing.sm,
    paddingRight: theme.spacing.sm,
    paddingTop: 3,
    width: '100%',
    height: '125px',
  },
  infoBox: {
    color: `${theme.colors.gray[6]}`,
    border: `1px solid ${theme.colors.gray[6]}`,
    borderRadius: theme.radius.lg,
    width: 'fit-content',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  groupLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.sm,
    whiteSpace: 'pre',
  },
  fieldLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
  },
  detail: {
    fontSize: theme.fontSizes.md,
    paddingLeft: 10,
  },
  fieldGroup: {
    width: '48%',
  },
  clickableIcon: {
    color: `${theme.colors.blue[6]}`,
    padding: '0 !important',
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

function filterData(data: ITrackedPatientEquipment[], search: string) {
  if (data && data.length > 0) {
    const keys = Object.keys(data[0]);
    const query = search.toLowerCase().trim();
    return data.filter((item) =>
      keys.some((key) => (item[key] ?? '-').toString().toLowerCase().includes(query))
    );
  } else {
    return [];
  }
}

function sortData(
  data: ITrackedPatientEquipment[],
  payload: { sortBy: keyof ITrackedPatientEquipment; reversed: boolean; search: string }
) {
  if (!payload.sortBy) {
    return filterData(data, payload.search);
  }

  return filterData(
    [...data].sort((a, b) => {
      if (payload.reversed) {
        return (b[payload.sortBy] ?? '000ForceToBottom')
          .toString()
          .localeCompare((a[payload.sortBy] ?? '000ForceToBottom').toString());
      }

      return (a[payload.sortBy] ?? '!!!ForceToTop').localeCompare(
        (b[payload.sortBy] ?? '!!!ForceToTop').toString()
      );
    }),
    payload.search
  );
}

type TrackedEquipmentPatientHistoryModalProps = {
  trackedEquipment: ITrackedEquipment;
  opened: boolean;
  onSelect: (trackedPatientEquipment: ITrackedPatientEquipment) => void;
  onClose: () => void;
};

const TrackedEquipmentPatientHistoryModal = (props: TrackedEquipmentPatientHistoryModalProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const trackedPatientEquipment = useTrackedPatientEquipmentByTrackedEquipment(
    props.trackedEquipment.trackedEquipmentId
  );

  const [search, setSearch] = useState('');
  const [sortedData, setSortedData] = useState(trackedPatientEquipment.data);
  const [sortBy, setSortBy] = useState<keyof ITrackedPatientEquipment>(null!);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);

  const setSorting = (field: keyof ITrackedPatientEquipment) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setSortedData(sortData(trackedPatientEquipment.data!, { sortBy: field, reversed, search }));
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSearch(value);
    setSortedData(
      sortData(trackedPatientEquipment.data!, {
        sortBy,
        reversed: reverseSortDirection,
        search: value,
      })
    );
  };

  const rows = (sortedData ?? []).map((a: ITrackedPatientEquipment) => {
    return (
      <tr
        key={a.trackedPatientEquipmentId}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          props.onSelect(a);
        }}
      >
        <td style={{ wordWrap: 'break-word', width: '25%' }}>{a.patientName}</td>
        <td style={{ wordWrap: 'break-word', width: '25%' }}>
          {dayjs(a.deliveredDateTime).format('YYYY-MM-DD')}
        </td>
        <td style={{ wordWrap: 'break-word', width: '25%' }}>
          {a.returnedDateTime ? dayjs(a.returnedDateTime).format('YYYY-MM-DD') : '-'}
        </td>
        <td style={{ wordWrap: 'break-word', width: '15%' }}>
          {(a.deliveryNotes || a.returnNotes) && <Note className={classes.clickableIcon} />}
        </td>
      </tr>
    );
  });

  useEffect(() => {
    if (!trackedPatientEquipment.data) {
      setSortedData(trackedPatientEquipment.data);
    } else {
      setSortedData(
        sortData(trackedPatientEquipment.data, { sortBy, reversed: reverseSortDirection, search })
      );
    }
  }, [trackedPatientEquipment.data, reverseSortDirection, search, sortBy]);

  useEffect(() => {
    if (props.opened) {
      trackedPatientEquipment.refetch();
    }
  }, [props.trackedEquipment, props.opened]);

  return (
    <Modal
      opened={props.opened}
      size={isSmallMobile ? 'xs' : 'md'}
      onClose={() => {
        props.onClose();
      }}
    >
      <Stack spacing={0} style={{ position: 'relative' }}>
        <Center>
          <Text size="xl" weight={500} align="center">
            Equipment Patient History
          </Text>
        </Center>
        {props.trackedEquipment.checkedOutDate && !props.trackedEquipment.outForRepairs && (
          <TrackedEquipmentPatientSection
            trackedEquipment={props.trackedEquipment}
            header="Current Patient"
          />
        )}
        <TrackedEquipmentDetailSection trackedEquipment={props.trackedEquipment} readOnly />
        <Center mt={theme.spacing.xs}>
          <Text size="md" weight={500} align="center">
            Patient History
          </Text>
        </Center>
        <Divider />
        <TextInput
          placeholder="Search by any field"
          my="xs"
          icon={<Search size={14} />}
          value={search}
          onChange={handleSearchChange}
        />
        <Divider />
        <Table style={{ width: '100%', tableLayout: 'fixed' }}>
          <thead>
            <tr>
              <Th
                sorted={sortBy === 'patientName'}
                reversed={reverseSortDirection}
                onSort={() => setSorting('patientName')}
                style={{ width: '25%' }}
              >
                Patient
              </Th>
              <Th
                sorted={sortBy === 'deliveredDateTime'}
                reversed={reverseSortDirection}
                onSort={() => setSorting('deliveredDateTime')}
                style={{ width: '25%' }}
              >
                Delivered
              </Th>
              <Th
                sorted={sortBy === 'returnedDateTime'}
                reversed={reverseSortDirection}
                onSort={() => setSorting('returnedDateTime')}
                style={{ width: '25%' }}
              >
                Returned
              </Th>
              <th
                style={{
                  width: '15%',
                  fontWeight: 500,
                  fontSize: theme.fontSizes.sm,
                  color: 'black',
                }}
              >
                Notes
              </th>
            </tr>
          </thead>
        </Table>
        <InfiniteScrollArea rows={rows} height={250} />
        <Divider />
        <Button
          variant="outline"
          mt={theme.spacing.xs}
          onClick={() => {
            props.onClose();
          }}
        >
          <ArrowLeft style={{ marginRight: 5 }} />
          Return to Details
        </Button>
      </Stack>
    </Modal>
  );
};

export default TrackedEquipmentPatientHistoryModal;
