import { Box, createStyles, Divider, Group, Stack, Text, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React, { useState } from 'react';
import { msalInstance } from '../common/AzureAD';
import { PageHeader } from '../common/Page/Header/PageHeader';
import { Page } from '../common/Page/Page';
import { useAdmins } from '../hooks';

const useStyles = createStyles((theme, _params) => ({}));

type Props = {};

const InvalidUserPage = (props: Props) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const [clickedOnDemand, setClickedOnDemand] = useState<boolean>(true);
  const [clickedStanding, setClickedStanding] = useState<boolean>(false);

  const admins = useAdmins();
  const currentLogin = msalInstance.getAllAccounts()[0].username;

  const adminList = (admins.data ?? []).map((a) => {
    return (
      <Text key={a.userId}>
        {a.userFullNameFormatted} - {a.userEmailAddress}
      </Text>
    );
  });

  return (
    <Page
      header={
        <PageHeader
          title="Invalid User"
          subtitle="You are not a registered User for this application"
        />
      }
    >
      <Box style={{ height: '50vh' }}>
        <Stack>
          <Stack spacing={0}>
            <Text size="xl" weight={500}>
              "{currentLogin}" is not a registered User
            </Text>
            <Text size="md" weight={500}>
              You cannot use this application without being a registered User
            </Text>
          </Stack>
          <Divider />
          <Stack spacing="sm">
            <Text size="md" weight={500}>
              If you should have access to this application, here is a list of Administrators to
              contact for access:
            </Text>
            <Stack>{adminList}</Stack>
          </Stack>
        </Stack>
      </Box>
    </Page>
  );
};

export default InvalidUserPage;
