import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import externalApi from '../../api';
import { IPatient } from '../../models';

function fetchPatient(patientId: string): Promise<IPatient> {
  return externalApi.get(`Patient/${patientId}`).json();
}

export function usePatient(patientId: string) {
  return useQuery(['Patients', 'Patient'], () => fetchPatient(patientId));
}
