import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type EditPatientRequest = {
  patientId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  streetAddress: string;
  cityId: string;
  lastFourHCN: string;
  claimNumber: string;
  yearlyAppointment: boolean;
  covid: boolean;
  lakeridgeGardens: boolean;
  hospicePT: boolean;
  fundingTypeId: string;
  startDate: Date | undefined;
  dischargeDate: Date | undefined;
  deathDate: Date | undefined;
  notes: string;
  lastUpdatedUserId: string;
};

type EditPatientResponse = {
  patientId: number;
};

function editPatient(request: EditPatientRequest): Promise<EditPatientResponse> {
  return externalApi.put(`Patient/put`, { json: request }).json();
}

function useEditPatient() {
  const queryClient = useQueryClient();
  return useMutation((request: EditPatientRequest) => editPatient(request), {
    onSuccess: () => {
      queryClient.invalidateQueries(['Patients']);
    },
  });
}
export { useEditPatient };
