import {
  Center,
  Text,
  Modal,
  useMantineTheme,
  Stack,
  Divider,
  createStyles,
  ScrollArea,
  Button,
  TextInput,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { BoxOff, Plus, Search } from 'tabler-icons-react';
import { TrackedEquipmentDetailCard } from '../../../../components';
import { useEquipment, useReadyTrackedEquipmentByEquipment } from '../../../../hooks';
import { useEffect, useState } from 'react';
import { IEquipment, ITrackedEquipment } from '../../../../models';

const useStyles = createStyles((theme) => ({
  equipmentBox: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    paddingLeft: 3,
    paddingTop: 3,
    paddingBottom: 3,
    paddingRight: theme.spacing.md,
    width: '100%',
    height: '70',
  },
  label: {
    fontSize: `calc(${theme.fontSizes.xs} * 0.9)`,
    color: 'dimgray',
    textAlign: 'right',
  },
  detail: {
    fontSize: `calc(${theme.fontSizes.xs} * 0.9)`,
    color: 'black',
    fontWeight: 700,
    textAlign: 'left',
  },
}));

type Props = {
  opened: boolean;
  equipmentId: string;
  excludedTrackedEquipment?: ITrackedEquipment[];
  hasUndelivered?: boolean;
  onSelect: (trackedEquipment: ITrackedEquipment) => void;
  onUndelivered?: (equipment: IEquipment) => void;
  onClose: () => void;
};

const TrackedEquipmentSelectModal = (props: Props) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const equipment = useEquipment(props.equipmentId ?? '0');
  const trackedEquipment = useReadyTrackedEquipmentByEquipment(props.equipmentId);

  const [selectedTrackedEquipment, setSelectedTrackedEquipment] = useState<
    ITrackedEquipment | undefined
  >();

  const [search, setSearch] = useState<string>('');
  const [filteredCards, setFilteredCards] = useState<(JSX.Element | undefined)[]>([]);

  const cards = (trackedEquipment.data ?? []).map((a) => {
    if (!props.excludedTrackedEquipment?.some((b) => b.trackedEquipmentId === a.trackedEquipmentId))
      return (
        <TrackedEquipmentDetailCard
          key={a.trackedEquipmentId}
          trackedEquipment={a}
          isClicked={selectedTrackedEquipment?.trackedEquipmentId === a.trackedEquipmentId}
          onClicked={() => {
            if (selectedTrackedEquipment?.trackedEquipmentId !== a.trackedEquipmentId) {
              setSelectedTrackedEquipment(a);
            } else {
              setSelectedTrackedEquipment(undefined);
            }
          }}
        />
      );
  });

  const filterCards = (value: string) => {
    setFilteredCards(
      cards.filter((a) =>
        a?.props.trackedEquipment.serialNumber.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  useEffect(() => {
    equipment.refetch();
    trackedEquipment.refetch();
    if (props.opened) {
      setSelectedTrackedEquipment(undefined);
      setSearch('');
    }
  }, [props.equipmentId, props.opened]);

  return (
    <Modal
      opened={props.opened}
      size={isSmallMobile ? 'xs' : 'md'}
      onClose={() => {
        props.onClose();
      }}
    >
      <Stack spacing={0}>
        <Center>
          {equipment.data && (
            <Text size="xl" weight={500} align="center" mb={`calc(${theme.spacing.xs} / 2)`}>
              Available <i>{equipment.data?.name ?? '-'}</i>
            </Text>
          )}
        </Center>
        <TextInput
          placeholder="Search by Serial Number"
          mb="xs"
          icon={<Search size={14} />}
          value={search}
          onChange={(e) => {
            setSearch(e.currentTarget.value);
            filterCards(e.currentTarget.value);
          }}
        />
        <Divider mb={theme.spacing.xs} />
        <ScrollArea style={{ height: '300px' }}>
          {cards.find((a) => a !== undefined) && search.length === 0 && cards}
          {filteredCards.find((a) => a !== undefined) && search.length > 0 && filteredCards}
          {!cards.find((a) => a !== undefined) && search.length === 0 && (
            <Text weight={500} align="center">
              Nothing Found
            </Text>
          )}
          {!filteredCards.find((a) => a !== undefined) && search.length > 0 && (
            <Text weight={500} align="center">
              Nothing Found
            </Text>
          )}
        </ScrollArea>
        <Divider my={theme.spacing.xs} />
        <Button
          variant="outline"
          disabled={!selectedTrackedEquipment}
          onClick={() => {
            if (selectedTrackedEquipment) props.onSelect(selectedTrackedEquipment);
          }}
        >
          <Plus style={{ marginRight: 5 }} />
          Add Selected Equipment
        </Button>
        {equipment.data && props.hasUndelivered && (
          <Button
            variant="outline"
            mt={`calc(${theme.spacing.xs} / 2)`}
            onClick={() => {
              if (props.hasUndelivered && props.onUndelivered) props.onUndelivered(equipment.data);
            }}
          >
            <BoxOff style={{ marginRight: 5 }} />
            Can't Deliver Equipment
          </Button>
        )}
      </Stack>
    </Modal>
  );
};

export default TrackedEquipmentSelectModal;
