import React from 'react';
import { ActionIcon, Box, Group, Stack, Text, Title, useMantineTheme } from '@mantine/core';
import { Home } from 'tabler-icons-react';
import { useNavigate } from 'react-router-dom';
import { HomePage } from '../../../pages';

type PageHeaderProps = {
  title: string;
  subtitle: string;
  stickyContent?: React.ReactNode;
};

export const PageHeader = (props: PageHeaderProps) => {
  const { title, subtitle, stickyContent } = props;
  const theme = useMantineTheme();
  const navigate = useNavigate();

  return (
    <Stack>
      <Group position="apart" grow>
        <Box
          style={{ background: theme.colors.blue[5], paddingLeft: 16, height: 60, paddingTop: 7 }}
        >
          <Group position="apart" noWrap mr={10}>
            <Stack spacing={0}>
              <Title order={1} sx={{ fontSize: 18, color: 'white' }}>
                {title}
              </Title>
              <Text color="white">{subtitle}</Text>
            </Stack>
            <ActionIcon
              variant="transparent"
              onClick={() => {
                navigate('/');
              }}
            >
              <Home color="white" />
            </ActionIcon>
          </Group>
        </Box>
      </Group>
      {stickyContent && (
        <Box mb="md" style={{ paddingLeft: 16, paddingRight: 16 }}>
          {stickyContent}
        </Box>
      )}
    </Stack>
  );
};
