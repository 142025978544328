import {
  Box,
  Group,
  ScrollArea,
  Text,
  createStyles,
  Chip,
  useMantineTheme,
  LoadingOverlay,
  Stack,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { BoxOff, Check, Clock, ExclamationMark, X } from 'tabler-icons-react';
import {
  useDeliveredByDate,
  useIncompleteDeliveriesByDate,
  usePageStore,
  usePendingOrdersByDate,
  usePendingStandingOrdersByDate,
  useUndeliveredByDate,
} from '../../hooks';
import {
  DeliveryConvertModal,
  DeliveryCreateModal,
  DeliveryDetailModal,
  DeliveryEditModal,
  DeliveryEquipmentModal,
  UndeliveredDeliveryRescheduleModal,
  DeliveryCard,
  TrackedEquipmentSelectModal,
  IncompleteDeliveryRescheduleModal,
} from '../../components';
import { useLocation } from 'react-router-dom';
import { IEquipment, ILocationState, ITrackedEquipment } from '../../models';

const useStyles = createStyles((theme, _params) => ({
  detailDay: {
    border: `1px solid ${theme.colors.dark[8]}`,
    borderRadius: theme.radius.md,
  },
  detailTime: {
    textAlign: 'center',
    height: '100px',
    width: '100px',
    background: theme.colors.blue[6],
    borderRight: `1px solid ${theme.colors.dark[8]}`,
    [`@media (min-width: ${theme.breakpoints.xs})`]: {
      width: '50px',
    },
    '&:first-of-type': {
      borderTopLeftRadius: `calc(${theme.radius.md} - 1)`,
      borderBottom: `1px solid ${theme.colors.dark[8]}`,
    },
    '&:last-of-type': {
      borderBottomLeftRadius: `calc(${theme.radius.md} - 1)`,
    },
    overflow: 'hidden',
  },
  detailTimeLabel: {
    paddingTop: '37px',
    fontWeight: 500,
    color: 'white',
  },
  detailTimeContent: {
    height: '100px',
    width: '100%',
    '&:first-of-type': {
      borderBottom: `1px solid ${theme.colors.dark[8]}`,
    },
  },
  cardScroll: {
    height: '97px',
  },
}));

type DetailDayProps = {
  selectedDate: Date;
};

const DetailDay = (props: DetailDayProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const state = useLocation().state as ILocationState;
  const isExtraSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const [recordId, setRecordId] = useState<string>(
    state
      ? state.order
        ? state.order.orderId
        : state.delivery
        ? state.delivery.deliveryId
        : '0'
      : '0'
  );
  const [recordType, setRecordType] = useState<'order' | 'standingOrder' | 'delivery' | undefined>(
    state ? (state.order ? 'order' : state.delivery ? 'delivery' : undefined) : undefined
  );

  const [detailModalOpen, setDetailModalOpen] = useState<boolean>(recordId !== '0');
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [trackedEquipmentSelectModalOpen, setTrackedEquipmentSelectModalOpen] =
    useState<boolean>(false);
  const [openedByCreateModal, setOpenedByCreateModal] = useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [convertModalOpen, setConvertModalOpen] = useState<boolean>(false);
  const [equipmentModalOpen, setEquipmentModalOpen] = useState<boolean>(false);
  const [undeliveredRescheduleModalOpen, setUndeliveredRescheduleModalOpen] =
    useState<boolean>(false);
  const [incompleteRescheduleModalOpen, setIncompleteRescheduleModalOpen] =
    useState<boolean>(false);

  const [isNewDeliveryStandingOrder, setIsNewDeliveryStandingOrder] = useState<boolean>(false);
  const [isDelivered, setIsDelivered] = useState<boolean>(false);
  const [isRecordDelivery, setIsRecordDelivery] = useState<boolean>(false);

  const [equipmentId, setEquipmentId] = useState<string>('0');
  const [trackedEquipmentIndex, setTrackedEquipmentIndex] = useState<number>(0);
  const [trackedEquipment, setTrackedEquipment] = useState<
    { trackedEquipment: ITrackedEquipment; index: number }[]
  >([]);
  const [undeliveredEquipment, setUndeliveredEquipment] = useState<
    { equipment: IEquipment; index: number }[]
  >([]);

  const [flashMessage, setFlashMessage] = useState<string>('');

  const pendingOrders = usePendingOrdersByDate({ date: props.selectedDate });
  const pendingStandingOrders = usePendingStandingOrdersByDate({ date: props.selectedDate });
  const undelivered = useUndeliveredByDate({ date: props.selectedDate });
  const delivered = useDeliveredByDate({ date: props.selectedDate });
  const incomplete = useIncompleteDeliveriesByDate({ date: props.selectedDate });

  const filterValues = usePageStore((state) => state.deliveryScheduleFilterValues);
  const setFilterValues = usePageStore((state) => state.setDeliveryScheduleFilterValues);

  const pendingOrderAMCards = (pendingOrders.data ?? [])
    .filter((a) => !a.isUrgent && a.isAMDelivery)
    .map((a) => {
      return (
        <DeliveryCard
          key={a.orderId}
          selectedDate={props.selectedDate}
          firstName={a.patientFirstName}
          lastName={a.patientLastName}
          address={a.patientAddress}
          deliveryStatus={'pending'}
          recordId={a.orderId}
          recordType="order"
          onOpen={() => {
            setRecordId(a.orderId);
            setRecordType('order');
            setIsRecordDelivery(false);
            setDetailModalOpen(true);
          }}
        />
      );
    });
  const urgentOrderAMCards = (pendingOrders.data ?? [])
    .filter((a) => a.isUrgent && a.isAMDelivery)
    .map((a) => {
      return (
        <DeliveryCard
          key={a.orderId}
          selectedDate={props.selectedDate}
          firstName={a.patientFirstName}
          lastName={a.patientLastName}
          address={a.patientAddress}
          deliveryStatus={'pending'}
          isUrgent
          recordId={a.orderId}
          recordType="order"
          onOpen={() => {
            setRecordId(a.orderId);
            setRecordType('order');
            setIsRecordDelivery(false);
            setDetailModalOpen(true);
          }}
        />
      );
    });

  const pendingStandingOrderAMCards = (pendingStandingOrders.data ?? [])
    .filter((a) => a.isAMDelivery)
    .map((a) => {
      return (
        <DeliveryCard
          key={a.standingOrderId}
          selectedDate={props.selectedDate}
          firstName={a.patientFirstName}
          lastName={a.patientLastName}
          address={a.patientAddress}
          deliveryStatus={'pending'}
          recordId={a.standingOrderId}
          recordType="standingOrder"
          onOpen={() => {
            setRecordId(a.standingOrderId);
            setRecordType('standingOrder');
            setIsRecordDelivery(false);
            setDetailModalOpen(true);
          }}
        />
      );
    });
  const pendingOrderPMCards = (pendingOrders.data ?? [])
    .filter((a) => !a.isUrgent && !a.isAMDelivery)
    .map((a) => {
      return (
        <DeliveryCard
          key={a.orderId}
          selectedDate={props.selectedDate}
          firstName={a.patientFirstName}
          lastName={a.patientLastName}
          address={a.patientAddress}
          deliveryStatus={'pending'}
          recordId={a.orderId}
          recordType="order"
          onOpen={() => {
            setRecordId(a.orderId);
            setRecordType('order');
            setIsRecordDelivery(false);
            setDetailModalOpen(true);
          }}
        />
      );
    });
  const urgentOrderPMCards = (pendingOrders.data ?? [])
    .filter((a) => a.isUrgent && !a.isAMDelivery)
    .map((a) => {
      return (
        <DeliveryCard
          key={a.orderId}
          selectedDate={props.selectedDate}
          firstName={a.patientFirstName}
          lastName={a.patientLastName}
          address={a.patientAddress}
          deliveryStatus={'pending'}
          isUrgent
          recordId={a.orderId}
          recordType="order"
          onOpen={() => {
            setRecordId(a.orderId);
            setRecordType('order');
            setIsRecordDelivery(false);
            setDetailModalOpen(true);
          }}
        />
      );
    });

  const pendingStandingOrderPMCards = (pendingStandingOrders.data ?? [])
    .filter((a) => !a.isAMDelivery)
    .map((a) => {
      return (
        <DeliveryCard
          key={a.standingOrderId}
          selectedDate={props.selectedDate}
          firstName={a.patientFirstName}
          lastName={a.patientLastName}
          address={a.patientAddress}
          deliveryStatus={'pending'}
          recordId={a.standingOrderId}
          recordType="standingOrder"
          onOpen={() => {
            setRecordId(a.standingOrderId);
            setRecordType('standingOrder');
            setIsRecordDelivery(false);
            setDetailModalOpen(true);
          }}
        />
      );
    });
  const undeliveredAMCards = (undelivered.data ?? [])
    .filter((a) => a.isAMDelivery)
    .map((a) => {
      return (
        <DeliveryCard
          key={a.deliveryId}
          selectedDate={props.selectedDate}
          firstName={a.patientFirstName}
          lastName={a.patientLastName}
          address={a.deliveryAddress}
          deliveryStatus={'undelivered'}
          recordId={a.deliveryId}
          recordType="delivery"
          onOpen={() => {
            setRecordId(a.deliveryId);
            setRecordType('delivery');
            setIsRecordDelivery(true);
            setDetailModalOpen(true);
          }}
        />
      );
    });

  const undeliveredPMCards = (undelivered.data ?? [])
    .filter((a) => !a.isAMDelivery)
    .map((a) => {
      return (
        <DeliveryCard
          key={a.deliveryId}
          selectedDate={props.selectedDate}
          firstName={a.patientFirstName}
          lastName={a.patientLastName}
          address={a.deliveryAddress}
          deliveryStatus={'undelivered'}
          recordId={a.deliveryId}
          recordType="delivery"
          onOpen={() => {
            setRecordId(a.deliveryId);
            setRecordType('delivery');
            setIsRecordDelivery(true);
            setDetailModalOpen(true);
          }}
        />
      );
    });

  const deliveredAMCards = (delivered.data ?? [])
    .filter((a) => a.isAMDelivery)
    .map((a) => {
      return (
        <DeliveryCard
          key={a.deliveryId}
          selectedDate={props.selectedDate}
          firstName={a.patientFirstName}
          lastName={a.patientLastName}
          address={a.deliveryAddress}
          deliveredTime={dayjs(a.deliveredDateTime).format('h:mm A')}
          deliveredDate={new Date(a.deliveredDateTime ?? '')}
          deliveryStatus={'delivered'}
          recordId={a.deliveryId}
          recordType="delivery"
          onOpen={() => {
            setRecordId(a.deliveryId);
            setRecordType('delivery');
            setIsRecordDelivery(true);
            setDetailModalOpen(true);
          }}
        />
      );
    });

  const deliveredPMCards = (delivered.data ?? [])
    .filter((a) => !a.isAMDelivery)
    .map((a) => {
      return (
        <DeliveryCard
          key={a.deliveryId}
          selectedDate={props.selectedDate}
          firstName={a.patientFirstName}
          lastName={a.patientLastName}
          address={a.deliveryAddress}
          deliveredTime={dayjs(a.deliveredDateTime).format('h:mm A')}
          deliveredDate={new Date(a.deliveredDateTime ?? '')}
          deliveryStatus={'delivered'}
          recordId={a.deliveryId}
          recordType="delivery"
          onOpen={() => {
            setRecordId(a.deliveryId);
            setRecordType('delivery');
            setIsRecordDelivery(true);
            setDetailModalOpen(true);
          }}
        />
      );
    });

  const incompleteAMCards = (incomplete.data ?? [])
    .filter((a) => a.isAMDelivery)
    .map((a) => {
      return (
        <DeliveryCard
          key={a.deliveryId}
          selectedDate={props.selectedDate}
          firstName={a.patientFirstName}
          lastName={a.patientLastName}
          address={a.deliveryAddress}
          deliveredTime={dayjs(a.deliveredDateTime).format('h:mm A')}
          deliveredDate={new Date(a.deliveredDateTime ?? '')}
          deliveryStatus={'incomplete'}
          recordId={a.deliveryId}
          recordType="delivery"
          onOpen={() => {
            setRecordId(a.deliveryId);
            setRecordType('delivery');
            setIsRecordDelivery(true);
            setDetailModalOpen(true);
          }}
        />
      );
    });

  const incompletePMCards = (incomplete.data ?? [])
    .filter((a) => !a.isAMDelivery)
    .map((a) => {
      return (
        <DeliveryCard
          key={a.deliveryId}
          selectedDate={props.selectedDate}
          firstName={a.patientFirstName}
          lastName={a.patientLastName}
          address={a.deliveryAddress}
          deliveredTime={dayjs(a.deliveredDateTime).format('h:mm A')}
          deliveredDate={new Date(a.deliveredDateTime ?? '')}
          deliveryStatus={'incomplete'}
          recordId={a.deliveryId}
          recordType="delivery"
          onOpen={() => {
            setRecordId(a.deliveryId);
            setRecordType('delivery');
            setIsRecordDelivery(true);
            setDetailModalOpen(true);
          }}
        />
      );
    });

  useEffect(() => {
    setTrackedEquipment([]);
    setEquipmentId('0');
    setTrackedEquipmentIndex(0);
  }, [recordId, props.selectedDate]);

  useEffect(() => {
    pendingOrders.remove();
    pendingStandingOrders.remove();
    delivered.remove();
    undelivered.remove();
    incomplete.remove();

    pendingOrders.refetch();
    pendingStandingOrders.refetch();
    delivered.refetch();
    undelivered.refetch();
    incomplete.refetch();
  }, [props.selectedDate]);

  return (
    <Stack spacing={0}>
      <Group spacing={0} position="apart" ml={5} mr={5}>
        <Group spacing={5} noWrap>
          <Text weight={500} style={{ whiteSpace: 'nowrap' }}>
            Selected Date:
          </Text>
          <Text style={{ whiteSpace: 'nowrap' }}>
            {`${dayjs(props.selectedDate).format('YYYY-MM-DD')} ${
              dayjs(props.selectedDate).format('YYYY-MM-DD') ===
              dayjs(new Date()).format('YYYY-MM-DD')
                ? ' (Today)'
                : ''
            }`}
          </Text>
        </Group>
        <Group mb={5}>
          <Chip.Group multiple value={filterValues} onChange={setFilterValues}>
            <Group spacing={`calc(${theme.spacing.xs} / 2)`}>
              <Chip variant="filled" value="urgent">
                {!isExtraSmallMobile ? (
                  'Urgent'
                ) : (
                  <ExclamationMark size={20} style={{ marginBottom: -5 }} />
                )}
              </Chip>
              <Chip variant="filled" value="pending">
                {!isExtraSmallMobile ? 'Pending' : <Clock size={20} style={{ marginBottom: -5 }} />}
              </Chip>
              <Chip variant="filled" value="undelivered">
                {!isExtraSmallMobile ? 'Undelivered' : <X size={20} style={{ marginBottom: -5 }} />}
              </Chip>
              <Chip variant="filled" value="incomplete">
                {!isExtraSmallMobile ? (
                  'Incomplete'
                ) : (
                  <BoxOff size={20} style={{ marginBottom: -5 }} />
                )}
              </Chip>
              <Chip variant="filled" value="delivered">
                {!isExtraSmallMobile ? (
                  'Delivered'
                ) : (
                  <Check size={20} style={{ marginBottom: -5 }} />
                )}
              </Chip>
            </Group>
          </Chip.Group>
        </Group>
      </Group>
      <Box className={classes.detailDay}>
        <Group spacing={0} noWrap>
          <Stack spacing={0}>
            <Box className={classes.detailTime}>
              <Text className={classes.detailTimeLabel}>AM</Text>
            </Box>
            <Box className={classes.detailTime}>
              <Text className={classes.detailTimeLabel}>PM</Text>
            </Box>
          </Stack>
          <Stack
            spacing={0}
            style={{
              flexGrow: 1,
              width: 0,
            }}
          >
            <Box className={classes.detailTimeContent} style={{ position: 'relative' }}>
              <ScrollArea className={classes.cardScroll}>
                {!pendingOrders.isLoading &&
                  !pendingStandingOrders.isLoading &&
                  !delivered.isLoading &&
                  !undelivered.isLoading && (
                    <Group spacing={0} noWrap>
                      {filterValues.find!((a) => a === 'urgent') && (
                        <Group spacing={0} noWrap>
                          {urgentOrderAMCards}
                        </Group>
                      )}
                      {filterValues.find((a) => a === 'pending') && (
                        <Group spacing={0} noWrap>
                          {pendingOrderAMCards}
                          {pendingStandingOrderAMCards}
                        </Group>
                      )}
                      {filterValues.find((a) => a === 'undelivered') && (
                        <Group spacing={0} noWrap>
                          {undeliveredAMCards}
                        </Group>
                      )}
                      {filterValues.find((a) => a === 'incomplete') && (
                        <Group spacing={0} noWrap>
                          {incompleteAMCards}
                        </Group>
                      )}
                      {filterValues.find((a) => a === 'delivered') && (
                        <Group spacing={0} noWrap>
                          {deliveredAMCards}
                        </Group>
                      )}
                    </Group>
                  )}
                <LoadingOverlay
                  visible={
                    pendingOrders.isFetching &&
                    pendingStandingOrders.isFetching &&
                    delivered.isFetching &&
                    undelivered.isFetching
                  }
                  zIndex={1}
                />
              </ScrollArea>
            </Box>
            <Box className={classes.detailTimeContent}>
              <ScrollArea className={classes.cardScroll}>
                {!pendingOrders.isLoading &&
                  !pendingStandingOrders.isLoading &&
                  !delivered.isLoading &&
                  !undelivered.isLoading && (
                    <Group spacing={0} noWrap>
                      {filterValues.find!((a) => a === 'urgent') && (
                        <Group spacing={0} noWrap>
                          {urgentOrderPMCards}
                        </Group>
                      )}
                      {filterValues.find((a) => a === 'pending') && (
                        <Group spacing={0} noWrap>
                          {pendingOrderPMCards}
                          {pendingStandingOrderPMCards}
                        </Group>
                      )}
                      {filterValues.find((a) => a === 'undelivered') && (
                        <Group spacing={0} noWrap>
                          {undeliveredPMCards}
                        </Group>
                      )}
                      {filterValues.find((a) => a === 'incomplete') && (
                        <Group spacing={0} noWrap>
                          {incompletePMCards}
                        </Group>
                      )}
                      {filterValues.find((a) => a === 'delivered') && (
                        <Group spacing={0} noWrap>
                          {deliveredPMCards}
                        </Group>
                      )}
                    </Group>
                  )}
                <LoadingOverlay
                  visible={
                    pendingOrders.isFetching &&
                    pendingStandingOrders.isFetching &&
                    delivered.isFetching &&
                    undelivered.isFetching &&
                    incomplete.isFetching
                  }
                  zIndex={1}
                />
              </ScrollArea>
            </Box>
          </Stack>
        </Group>
      </Box>
      {recordId && (
        <Box>
          <DeliveryDetailModal
            selectedDate={props.selectedDate}
            recordId={recordId}
            recordType={recordType}
            flashMessage={flashMessage}
            opened={detailModalOpen}
            onClose={() => {
              setFlashMessage('');
              setDetailModalOpen(false);
            }}
            onViewEquipment={() => {
              setFlashMessage('');
              setDetailModalOpen(false);
              setEquipmentModalOpen(true);
            }}
            onDeliveryEdit={() => {
              setFlashMessage('');
              setDetailModalOpen(false);
              setEditModalOpen(true);
            }}
            onDeliveryConvert={() => {
              setFlashMessage('');
              setDetailModalOpen(false);
              setConvertModalOpen(true);
            }}
            onOrderDelivered={() => {
              setFlashMessage('');
              setDetailModalOpen(false);
              setIsDelivered(true);
              setIsNewDeliveryStandingOrder(false);
              setCreateModalOpen(true);
            }}
            onOrderUndelivered={() => {
              setFlashMessage('');
              setDetailModalOpen(false);
              setIsDelivered(false);
              setIsNewDeliveryStandingOrder(false);
              setCreateModalOpen(true);
            }}
            onStandingOrderDelivered={() => {
              setFlashMessage('');
              setDetailModalOpen(false);
              setIsDelivered(true);
              setIsNewDeliveryStandingOrder(true);
              setCreateModalOpen(true);
            }}
            onStandingOrderUndelivered={() => {
              setFlashMessage('');
              setDetailModalOpen(false);
              setIsDelivered(false);
              setIsNewDeliveryStandingOrder(true);
              setCreateModalOpen(true);
            }}
            onUndeliveredReschedule={() => {
              setDetailModalOpen(false);
              setUndeliveredRescheduleModalOpen(true);
            }}
            onIncompleteReschedule={() => {
              setDetailModalOpen(false);
              setIncompleteRescheduleModalOpen(true);
            }}
          />
          <DeliveryCreateModal
            opened={createModalOpen}
            recordId={recordId}
            isStandingOrder={isNewDeliveryStandingOrder}
            isDelivered={isDelivered}
            selectedDate={props.selectedDate}
            selectedTrackedEquipment={trackedEquipment}
            undeliveredEquipment={undeliveredEquipment}
            onClose={() => {
              setCreateModalOpen(false);
              setDetailModalOpen(true);
            }}
            onEquipmentSelect={(equipmentId, index) => {
              setEquipmentId(equipmentId);
              setTrackedEquipmentIndex(index);
              setOpenedByCreateModal(true);
              setCreateModalOpen(false);
              setTrackedEquipmentSelectModalOpen(true);
            }}
            onTrackedEquipmentRemoved={(trackedEquipmentId) => {
              setTrackedEquipment((a) =>
                a.filter((b) => b.trackedEquipment.trackedEquipmentId !== trackedEquipmentId)
              );
            }}
            onUndeliveredRemoved={(index) => {
              setUndeliveredEquipment((a) => a.filter((b) => b.index !== index));
            }}
            onCreate={(deliveryId) => {
              setRecordId(deliveryId);
              setRecordType('delivery');
              setIsRecordDelivery(true);
              setCreateModalOpen(false);
              setFlashMessage('Order Successfully Updated!');
              setDetailModalOpen(true);
            }}
          />
          <TrackedEquipmentSelectModal
            opened={trackedEquipmentSelectModalOpen}
            equipmentId={equipmentId}
            excludedTrackedEquipment={trackedEquipment.map((a) => {
              return a.trackedEquipment;
            })}
            hasUndelivered
            onSelect={(selectedTrackedEquipment) => {
              setTrackedEquipment((a) =>
                a.concat({
                  trackedEquipment: selectedTrackedEquipment,
                  index: trackedEquipmentIndex,
                })
              );
              setTrackedEquipmentSelectModalOpen(false);
              if (openedByCreateModal) setCreateModalOpen(true);
              else setConvertModalOpen(true);
            }}
            onUndelivered={(equipment) => {
              setUndeliveredEquipment((a) =>
                a.concat({ equipment: equipment, index: trackedEquipmentIndex })
              );
              setTrackedEquipmentSelectModalOpen(false);
              if (openedByCreateModal) setCreateModalOpen(true);
              else setConvertModalOpen(true);
            }}
            onClose={() => {
              setTrackedEquipmentSelectModalOpen(false);
              if (openedByCreateModal) setCreateModalOpen(true);
              else setConvertModalOpen(true);
            }}
          />
          <DeliveryConvertModal
            opened={convertModalOpen}
            deliveryId={recordId}
            isDelivery={isRecordDelivery}
            selectedTrackedEquipment={trackedEquipment}
            undeliveredEquipment={undeliveredEquipment}
            onClose={() => {
              setConvertModalOpen(false);
              setDetailModalOpen(true);
            }}
            onEquipmentSelect={(equipmentId, index) => {
              setEquipmentId(equipmentId);
              setTrackedEquipmentIndex(index);
              setOpenedByCreateModal(false);
              setConvertModalOpen(false);
              setTrackedEquipmentSelectModalOpen(true);
            }}
            onTrackedEquipmentRemoved={(trackedEquipmentId) => {
              setTrackedEquipment((a) =>
                a.filter((b) => b.trackedEquipment.trackedEquipmentId !== trackedEquipmentId)
              );
            }}
            onUndeliveredRemoved={(index) => {
              setUndeliveredEquipment((a) => a.filter((b) => b.index !== index));
            }}
            onConverted={() => {
              setConvertModalOpen(false);
              setFlashMessage('Order Status Successfully Updated!');
              setDetailModalOpen(true);
            }}
          />
          <DeliveryEditModal
            opened={editModalOpen}
            deliveryId={recordId}
            isDelivery={isRecordDelivery}
            onClose={() => {
              setEditModalOpen(false);
              setDetailModalOpen(true);
            }}
            onEdited={() => {
              setFlashMessage('Delivery Notes Successfully Updated!');
              setEditModalOpen(false);
              setDetailModalOpen(true);
            }}
          />
          <DeliveryEquipmentModal
            recordId={recordId}
            recordType={recordType}
            opened={equipmentModalOpen}
            onClose={() => {
              setEquipmentModalOpen(false);
              setDetailModalOpen(true);
            }}
          />
          <UndeliveredDeliveryRescheduleModal
            deliveryId={recordId}
            selectedDate={props.selectedDate}
            opened={undeliveredRescheduleModalOpen}
            onClose={() => {
              setUndeliveredRescheduleModalOpen(false);
              setDetailModalOpen(true);
            }}
            onRescheduled={(orderId) => {
              setRecordId(orderId);
              setRecordType('order');
              setIsRecordDelivery(false);
              setUndeliveredRescheduleModalOpen(false);
              setFlashMessage('Order Successfully Updated!');
              setDetailModalOpen(true);
            }}
          />
          <IncompleteDeliveryRescheduleModal
            deliveryId={recordId}
            selectedDate={props.selectedDate}
            opened={incompleteRescheduleModalOpen}
            onClose={() => {
              setIncompleteRescheduleModalOpen(false);
              setDetailModalOpen(true);
            }}
            onRescheduled={(orderId) => {
              setRecordId(orderId);
              setRecordType('order');
              setIsRecordDelivery(false);
              setIncompleteRescheduleModalOpen(false);
              setFlashMessage('Order Successfully Updated!');
              setDetailModalOpen(true);
            }}
          />
        </Box>
      )}
    </Stack>
  );
};

export default DetailDay;
