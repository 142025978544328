import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type CompleteTrackedEquipmentRepairRequest = {
  trackedEquipmentRepairId: string;
  returnedDateTime: Date;
  notes?: string;
  lastUpdatedUserId: string;
};

type CompleteTrackedEquipmentRepairResponse = {
  trackedEquipmentRepairId: number;
};

function editTrackedEquipmentRepair(
  request: CompleteTrackedEquipmentRepairRequest
): Promise<CompleteTrackedEquipmentRepairResponse> {
  return externalApi.patch(`TrackedEquipmentRepair/patch/complete`, { json: request }).json();
}

function useCompleteTrackedEquipmentRepair() {
  const queryClient = useQueryClient();
  return useMutation(
    (request: CompleteTrackedEquipmentRepairRequest) => editTrackedEquipmentRepair(request),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('TrackedEquipmentRepairByTrackedEquipment');
        queryClient.invalidateQueries('AllTrackedEquipment');
      },
    }
  );
}
export { useCompleteTrackedEquipmentRepair };
