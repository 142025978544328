import React from 'react';
import { useQuery } from 'react-query';
import externalApi from '../../api';
import { ITrackedEquipment } from '../../models';

function fetchReadyTrackedEquipmentByEquipment(equipmentId: string): Promise<ITrackedEquipment[]> {
  return externalApi.get(`TrackedEquipment/ready/equipment/${equipmentId}`).json();
}

export function useReadyTrackedEquipmentByEquipment(equipmentId: string) {
  return useQuery(['ReadyTrackedEquipmentByEquipment'], () =>
    fetchReadyTrackedEquipmentByEquipment(equipmentId)
  );
}
