import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type EditStandingOrderEquipmentQuantityRequest = {
  standingOrderEquipmentId: string;
  quantity: number;
  lastUpdatedUserId: string;
};

type EditStandingOrderEquipmentQuantityResponse = {
  standingOrderEquipmentId: number;
};

function editStandingOrderEquipmentQuantity(
  request: EditStandingOrderEquipmentQuantityRequest
): Promise<EditStandingOrderEquipmentQuantityResponse> {
  return externalApi.patch(`StandingOrderEquipment/patch/quantity`, { json: request }).json();
}

function useEditStandingOrderEquipmentQuantity() {
  const queryClient = useQueryClient();
  return useMutation(
    (request: EditStandingOrderEquipmentQuantityRequest) =>
      editStandingOrderEquipmentQuantity(request),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['StandingOrderEquipmentByStandingOrder']);
      },
    }
  );
}
export { useEditStandingOrderEquipmentQuantity };
