import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';
import { ITrackedPatientEquipment } from '../../models';

function fetchTrackedPatientEquipmentByDelivery(
  deliveryId: string
): Promise<ITrackedPatientEquipment[]> {
  return externalApi.get(`TrackedPatientEquipment/ByDelivery/${deliveryId}`).json();
}

export function useTrackedPatientEquipmentByDeliveryMutate() {
  const queryClient = useQueryClient();
  return useMutation((deliveryId: string) => fetchTrackedPatientEquipmentByDelivery(deliveryId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['TrackedPatientEquipmentByDelivery']);
    },
  });
}
