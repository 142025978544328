import { ActionIcon, Box, Group, ScrollArea, Stack, Text, useMantineTheme } from '@mantine/core';
import React, { useState } from 'react';
import { Eye, EyeOff } from 'tabler-icons-react';

type PatientNotesProps = {
  notes: string;
};

const PatientNotes = (props: PatientNotesProps) => {
  const theme = useMantineTheme();

  const [patientNotesVisible, setPatientNotesVisible] = useState<boolean>(false);

  return (
    <Box>
      <Stack spacing={0}>
        <Group spacing={0}>
          <Text style={{ color: theme.colors.gray[5], fontSize: theme.fontSizes.xs }}>
            Patient Notes
          </Text>
          <ActionIcon onClick={() => setPatientNotesVisible(!patientNotesVisible)}>
            {!patientNotesVisible && <Eye style={{ color: theme.colors.gray[5] }} size={20} />}
            {patientNotesVisible && <EyeOff style={{ color: theme.colors.gray[5] }} size={20} />}
          </ActionIcon>
        </Group>
        {patientNotesVisible && (
          <ScrollArea
            style={{
              border: `1px solid ${theme.colors.gray[4]}`,
              borderRadius: theme.radius.sm,
              paddingLeft: theme.spacing.sm,
              paddingRight: theme.spacing.sm,
              paddingTop: 3,
              width: '100%',
              height: 125,
            }}
          >
            <Text>{`${props.notes}`}</Text>
          </ScrollArea>
        )}
      </Stack>
    </Box>
  );
};

export default PatientNotes;
