import {
  Box,
  Center,
  createStyles,
  useMantineTheme,
  Text,
  Group,
  Checkbox,
  ScrollArea,
  Button,
  Divider,
  Stack,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React, { useEffect } from 'react';
import { Check, ClipboardList, FileInvoice, X } from 'tabler-icons-react';
import { usePageStore, useStandingOrder } from '../../../../hooks';
import { InfoButton, PatientNotes } from '../../../../components';
import { useNavigate } from 'react-router-dom';

const useStyles = createStyles((theme) => ({
  standingOrderBox: {
    color: `${theme.colors.gray[6]}`,
    border: `1px solid ${theme.colors.gray[6]}`,
    borderRadius: theme.radius.lg,
    width: 'fit-content',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  notesCard: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    paddingLeft: theme.spacing.sm,
    paddingRight: theme.spacing.sm,
    paddingTop: 3,
    width: '100%',
    height: '125px',
  },
  label: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
  },
  detail: {
    fontSize: theme.fontSizes.md,
    paddingLeft: 10,
  },
  fieldGroup: {
    width: '48%',
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

type DeliveryStandingOrderDetailModalContentProps = {
  selectedDate: Date;
  standingOrderId: string;
  onClose: () => void;
  onViewEquipment: () => void;
  onDelivered: () => void;
  onUndelivered: () => void;
};

const DeliveryStandingOrderDetailModalContent = (
  props: DeliveryStandingOrderDetailModalContentProps
) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const setOrdersSubPage = usePageStore((state) => state.setOrdersSubPage);
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const standingOrder = useStandingOrder();

  useEffect(() => {
    standingOrder.mutate(props.standingOrderId);
  }, [props.standingOrderId]);

  return (
    <Box>
      {standingOrder.data && (
        <Box>
          <Center>
            <Text size="xl" weight={500} align="center">
              Pending Delivery Information
            </Text>
            <InfoButton
              userId={standingOrder.data.lastUpdatedUserId}
              date={standingOrder.data.lastUpdatedDateTime}
              ml={5}
            />
          </Center>
          <Center>
            <Group className={classes.standingOrderBox} position="center" spacing="xs" my={5}>
              <Text weight={500} size="xs" align="center">
                This is a Standing Order
              </Text>
            </Group>
          </Center>

          <Stack align="stretch" style={{ padding: 5 }}>
            <Group>
              <Stack spacing={0} style={{ width: '50%' }}>
                <Text className={classes.label}>Patient Name</Text>
                <Group spacing={5} noWrap>
                  <Text
                    className={classes.detail}
                  >{`${standingOrder.data.patientLastName}, ${standingOrder.data.patientFirstName}`}</Text>
                </Group>
              </Stack>
              <Stack spacing={0}>
                <Text className={classes.label}>Phone number</Text>
                <Text className={classes.detail}>{`${standingOrder.data.patientPhoneNumber}`}</Text>
              </Stack>
            </Group>
            <Group>
              <Stack spacing={0} style={{ width: '50%' }}>
                <Text className={classes.label}>Delivery Address</Text>
                <Text className={classes.detail}>{`${standingOrder.data.patientAddress}`}</Text>
              </Stack>
              <Stack spacing={0}>
                <Text className={classes.label}>City</Text>
                <Text className={classes.detail}>{`${standingOrder.data.patientCity}`}</Text>
              </Stack>
            </Group>
            <PatientNotes notes={standingOrder.data.patientNotes} />

            <Group>
              <Stack spacing={0} style={{ width: '50%' }}>
                <Text className={classes.label}>Delivery Weekday</Text>
                <Text className={classes.detail}>{`${standingOrder.data.weekdayName}`}</Text>
              </Stack>
              <Stack spacing={0}>
                <Text className={classes.label}>Delivery Time</Text>
                <Text className={classes.detail}>{`${
                  standingOrder.data.isAMDelivery ? 'AM' : 'PM'
                }`}</Text>
              </Stack>
            </Group>

            <Group spacing="xs" pl="xs">
              <Checkbox checked={standingOrder.data.liquid} readOnly />
              <Text className={classes.label}>Liquid</Text>
            </Group>

            <Stack spacing={0}>
              <Text className={classes.label}>Order Notes</Text>
              <ScrollArea className={classes.notesCard}>
                <Text>{`${standingOrder.data.notes}`}</Text>
              </ScrollArea>
            </Stack>
          </Stack>

          <Stack align="stretch" spacing={5} mt="md">
            <Button
              variant="outline"
              onClick={() => {
                props.onViewEquipment();
              }}
            >
              <FileInvoice style={{ marginRight: 5 }} />{' '}
              <Text className={classes.buttonText}>View Equipment</Text>
            </Button>
            <Group position="apart" spacing={5} grow noWrap>
              <Button
                onClick={() => {
                  props.onDelivered();
                }}
              >
                <Check style={{ marginRight: 5 }} />{' '}
                <Text className={classes.buttonText}>Delivered</Text>
              </Button>
              <Button
                color="red"
                onClick={() => {
                  props.onUndelivered();
                }}
              >
                <X style={{ marginRight: 5 }} />{' '}
                <Text className={classes.buttonText}>Undelivered</Text>
              </Button>
            </Group>
            <Divider my="xs" />
            <Button
              variant="outline"
              onClick={() => {
                setOrdersSubPage('standingOrders');
                navigate('../orders', {
                  state: {
                    standingOrder: standingOrder.data,
                  },
                });
              }}
            >
              <ClipboardList style={{ marginRight: 5 }} />{' '}
              <Text className={classes.buttonText}>Go to Standing Order</Text>
            </Button>
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default DeliveryStandingOrderDetailModalContent;
