import React from 'react';
import { useQuery } from 'react-query';
import externalApi from '../../api';
import { ITrackedPatientEquipment } from '../../models';

function fetchTrackedPatientEquipmentByPatient(
  patientId: string
): Promise<ITrackedPatientEquipment[]> {
  return externalApi.get(`TrackedPatientEquipment/ByPatient/${patientId}`).json();
}

export function useTrackedPatientEquipmentByPatient(patientId: string) {
  return useQuery(['TrackedPatientEquipmentByPatient'], () =>
    fetchTrackedPatientEquipmentByPatient(patientId)
  );
}
