import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type RescheduleIncompleteDeliveryRequest = {
  patientId: string;
  isAMDelivery: boolean;
  requestedDeliveryDate: Date;
  liquid: boolean;
  notes: string;
  deliveryId: string;
  lastUpdatedUserId: string;
};

type RescheduleIncompleteDeliveryResponse = {
  orderId: number;
};

function rescheduleIncompleteDelivery(
  request: RescheduleIncompleteDeliveryRequest
): Promise<RescheduleIncompleteDeliveryResponse> {
  return externalApi.post(`Order/Reschedule/Incomplete`, { json: request }).json();
}

function useRescheduleIncompleteDelivery() {
  const queryClient = useQueryClient();
  return useMutation(
    (request: RescheduleIncompleteDeliveryRequest) => rescheduleIncompleteDelivery(request),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('Orders');
        queryClient.invalidateQueries('DeliveredByDate');
        queryClient.invalidateQueries('PendingOrdersByDate');
        queryClient.invalidateQueries('PendingStandingOrdersByDate');
        queryClient.invalidateQueries('IncompleteByDate');
        queryClient.invalidateQueries('UndeliveredByDate');
        queryClient.invalidateQueries('DeliveriesCountByDate');
        queryClient.invalidateQueries('DeliveriesCountByDateRange');
      },
    }
  );
}
export { useRescheduleIncompleteDelivery };
