import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type UnretireTrackedEquipmentRequest = {
  trackedEquipmentId: string;
  lastUpdatedUserId: string;
};

type UnretireTrackedEquipmentResponse = {
  trackedEquipmentId: number;
};

function unretireTrackedEquipment(
  request: UnretireTrackedEquipmentRequest
): Promise<UnretireTrackedEquipmentResponse> {
  return externalApi.patch(`TrackedEquipment/unretire`, { json: request }).json();
}

function useUnretireTrackedEquipment() {
  const queryClient = useQueryClient();
  return useMutation(
    (request: UnretireTrackedEquipmentRequest) => unretireTrackedEquipment(request),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['AllTrackedEquipment']);
      },
    }
  );
}
export { useUnretireTrackedEquipment };
