import React from 'react';
import { Box, createStyles, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

const useStyles = createStyles((theme, _params) => {
  return {
    headerWrapperMobile: {
      background: 'white',
      borderBottom: `1px solid ${theme.colors.gray[3]}`,
      position: 'relative',
      top: 0,
      zIndex: 11,
      width: '100%',
      opacity: 1,
      marginBottom: theme.spacing.md,
    },

    headerWrapperDesktop: {
      background: 'white',
      borderBottom: `1px solid ${theme.colors.gray[3]}`,
      position: 'sticky',
      top: 0,
      zIndex: 11,
      width: '100%',
      opacity: 1,
      marginBottom: theme.spacing.md,
    },

    header: {},

    content: {
      height: 600,
      [`@media (max-width: 410)`]: {
        height: 650,
      },
      paddingLeft: 20,
      paddingRight: 20,
      marginBottom: 375,
    },
  };
});

type PageProps = {
  header: React.ReactNode;
  headerStyle?: React.CSSProperties;
  children: React.ReactNode;
};

export const Page = (props: PageProps) => {
  const { header, headerStyle, children } = props;
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const collapsed = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

  return (
    <div>
      <Box mb={0}>
        {collapsed && (
          <Box className={classes.headerWrapperMobile}>
            <Box style={headerStyle} className={classes.header}>
              {header}
            </Box>
          </Box>
        )}
        {!collapsed && (
          <Box className={classes.headerWrapperDesktop}>
            <Box style={headerStyle} className={classes.header}>
              {header}
            </Box>
          </Box>
        )}
        <Box className={classes.content}>{children}</Box>
      </Box>
    </div>
  );
};
