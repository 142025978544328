import {
  Box,
  Button,
  Center,
  Checkbox,
  Chip,
  createStyles,
  Divider,
  Group,
  Loader,
  ScrollArea,
  Stack,
  Table,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { FilePlus, Search } from 'tabler-icons-react';
import {
  Th,
  PatientSelectModal,
  StandingOrderDetailModal,
  StandingOrderEditModal,
  StandingOrderCreateModal,
  StandingOrderRescheduleModal,
  StandingOrderCancellationModal,
  StandingOrderModificationsModal,
  StandingOrderEquipmentModal,
  OrderEditModal,
  StandingOrderCancellationEditModal,
  InfiniteScrollArea,
} from '../../components';
import { usePageStore, useStandingOrdersByWeekday } from '../../hooks';
import { IFilteredPatient, ILocationState, IOrder, IStandingOrder } from '../../models';

const useStyles = createStyles((theme, _params) => ({
  card: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.md,
    padding: `${theme.spacing.sm} ${theme.spacing.lg}`,
  },
}));

function filterData(data: IStandingOrder[], search: string, visibleKeys: string[]) {
  const keys = visibleKeys;
  const query = search.toLowerCase().trim();
  return data.filter((item) =>
    keys.some((key) => (item[key] ?? '-').toString().toLowerCase().includes(query))
  );
}

function sortData(
  data: IStandingOrder[],
  payload: { sortBy: keyof IStandingOrder; reversed: boolean; search: string },
  visibleKeys: string[]
) {
  if (!payload.sortBy) {
    return filterData(data, payload.search, visibleKeys);
  }

  return filterData(
    [...data].sort((a, b) => {
      if (payload.reversed) {
        return (b[payload.sortBy] ?? '000ForceToBottom')
          .toString()
          .localeCompare((a[payload.sortBy] ?? '000ForceToBottom').toString());
      }

      return (a[payload.sortBy] ?? 'zzzForceToBottom')
        .toString()
        .localeCompare((b[payload.sortBy] ?? 'zzzForceToBottom').toString());
    }),
    payload.search,
    visibleKeys
  );
}

type Props = {};

const StandingOrdersSubPage = (props: Props) => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const state = useLocation().state as ILocationState;

  const displayWeekday = usePageStore((state) => state.standingOrdersWeekdayValue);
  const setDisplayWeekday = usePageStore((state) => state.setStandingOrdersWeekdayValue);

  const [selectedPatient, setSelectedPatient] = useState<IFilteredPatient>();
  const [patientSelected, setPatientSelected] = useState<boolean>(false);
  const [selectedRescheduledOrder, setSelectedRescheduledOrder] = useState<IOrder>({
    orderId: '',
    patientId: '',
    patientFirstName: '',
    patientLastName: '',
    patientFullName: '',
    patientFullNameFormatted: '',
    patientPhoneNumber: '',
    patientAddress: '',
    patientCity: '',
    patientNotes: '',
    isAMDelivery: false,
    deliveryTimeName: '',
    requestedDeliveryDate: new Date(),
    liquid: false,
    notes: '',
    isUrgent: false,
    standingOrderId: undefined,
    standingOrderReschedule: false,
    deliveryReschedule: false,
    fulfilled: false,
    lastUpdatedUserId: '',
    lastUpdatedDateTime: new Date(),
  });
  const [selectedCancellationId, setSelectedCancellationId] = useState<string>('');

  const [isError, setIsError] = useState<boolean>(false);
  const [flashMessage, setFlashMessage] = useState<string>('');

  const defaultStandingOrder = {
    standingOrderId: '',
    patientId: '',
    patientFirstName: '',
    patientLastName: '',
    patientFullName: '',
    patientFullNameFormatted: '',
    patientPhoneNumber: '',
    patientAddress: '',
    patientCity: '',
    patientNotes: '',
    isAMDelivery: false,
    deliveryTimeName: '',
    weekdayId: '',
    weekdayName: '',
    liquid: false,
    notes: '',
    lastUpdatedUserId: '',
    lastUpdated: new Date(),
  };

  const [detailOrder, setDetailOrder] = useState<IStandingOrder>(
    state
      ? state.standingOrder
        ? state.standingOrder
        : defaultStandingOrder
      : defaultStandingOrder
  );
  const [standingOrderCreated, setStandingOrderCreated] = useState<boolean>(false);
  const [standingOrderUpdated, setStandingOrderUpdated] = useState<boolean>(false);
  const [standingOrderDeleted, setStandingOrderDeleted] = useState<boolean>(false);
  const [standingOrderUpdateError, setStandingOrderUpdateError] = useState<boolean>(false);
  const [standingOrderRescheduled, setStandingOrderRescheduled] = useState<boolean>(false);
  const [standingOrderCancelled, setStandingOrderCancelled] = useState<boolean>(false);
  const [modificationsModalOpen, setModificationsModalOpen] = useState<boolean>(false);
  const [cancellationModalOpen, setCancellationModalOpen] = useState<boolean>(false);
  const [cancellationEditModalOpen, setCancellationEditModalOpen] = useState<boolean>(false);
  const [rescheduleModalOpen, setRescheduleModalOpen] = useState<boolean>(false);
  const [rescheduledOrderEditModalOpen, setRescheduledOrderEditModalOpen] =
    useState<boolean>(false);
  const [patientSelectModalOpen, setPatientSelectModalOpen] = useState<boolean>(false);
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [equipmentModalOpen, setEquipmentModalOpen] = useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [detailModalOpen, setDetailModalOpen] = useState<boolean>(
    detailOrder.standingOrderId !== ''
  );

  const defaultKeys: string[] = [
    'patientFirstName',
    'patientLastName',
    'patientFullName',
    'patientFullNameFormatted',
    'deliveryTimeName',
    'patientAddress',
    'patientCity',
    'weekdayName',
    'liquid',
  ];

  let standingOrders = useStandingOrdersByWeekday(displayWeekday);

  const [search, setSearch] = useState('');
  const [sortedData, setSortedData] = useState(standingOrders.data);
  const [sortBy, setSortBy] = useState<keyof IStandingOrder>(null!);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);

  const setSorting = (field: keyof IStandingOrder) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setSortedData(sortData(standingOrders.data!, { sortBy: field, reversed, search }, defaultKeys));
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSearch(value);
    setSortedData(
      sortData(
        standingOrders.data!,
        { sortBy, reversed: reverseSortDirection, search: value },
        defaultKeys
      )
    );
  };

  const rows = sortedData?.map((row) => (
    <tr
      key={row.standingOrderId}
      style={{ cursor: 'pointer' }}
      onClick={() => {
        setDetailOrder(row);
        setDetailModalOpen(true);
      }}
    >
      <td
        style={{ width: 120, wordWrap: 'break-word' }}
      >{`${row.patientLastName}, ${row.patientFirstName}`}</td>
      <td style={{ width: 120, wordWrap: 'break-word' }}>{row.weekdayName}</td>
      <td style={{ width: 120, wordWrap: 'break-word' }}>{`${row.deliveryTimeName}`}</td>
      <td style={{ width: 120, wordWrap: 'break-word' }}>{`${row.patientAddress}`}</td>
      <td style={{ width: 120, wordWrap: 'break-word' }}>{`${row.patientCity}`}</td>
      <td style={{ width: 120, wordWrap: 'break-word' }}>
        <Checkbox checked={row.liquid} readOnly />
      </td>
    </tr>
  ));

  useEffect(() => {
    if (!standingOrders.data) {
      setSortedData(standingOrders.data);
    } else {
      setSortedData(
        sortData(
          standingOrders.data,
          { sortBy, reversed: reverseSortDirection, search },
          defaultKeys
        )
      );
    }
  }, [standingOrders.data, reverseSortDirection, search, sortBy]);

  useEffect(() => {
    standingOrders.refetch();
  }, [displayWeekday]);

  return (
    <Box style={{ height: '100%' }}>
      <Box style={{ height: '100%' }}>
        <Center mb="sm">
          <Group
            position="apart"
            p="sm"
            className={classes.card}
            mb={`calc(${theme.spacing.md} - 1)`}
          >
            <Text style={{ width: 'fit-content' }}>Weekday:</Text>
            <Center>
              <Chip.Group
                value={displayWeekday}
                onChange={(e) => {
                  setDisplayWeekday(e[0]);
                }}
              >
                <Group spacing={`calc(${theme.spacing.xs} / 2)`}>
                  <Chip variant="filled" value="0">
                    All
                  </Chip>
                  <Chip variant="filled" value="1">
                    Monday
                  </Chip>
                  <Chip variant="filled" value="2">
                    Tuesday
                  </Chip>
                  <Chip variant="filled" value="3">
                    Wednesday
                  </Chip>
                  <Chip variant="filled" value="4">
                    Thursday
                  </Chip>
                  <Chip variant="filled" value="5">
                    Friday
                  </Chip>
                  <Chip variant="filled" value="6">
                    Saturday
                  </Chip>
                  <Chip variant="filled" value="7">
                    Sunday
                  </Chip>
                </Group>
              </Chip.Group>
            </Center>
          </Group>
        </Center>
        <TextInput
          placeholder="Search by any field"
          mb="md"
          icon={<Search size={14} />}
          value={search}
          onChange={handleSearchChange}
        />
        <Divider />
        <ScrollArea type="hover" style={{ width: '100%' }}>
          <Table style={{ width: '100%', tableLayout: 'fixed' }}>
            <thead>
              <tr>
                <Th
                  sorted={sortBy === 'patientLastName'}
                  reversed={reverseSortDirection}
                  onSort={() => setSorting('patientLastName')}
                  style={{ width: 120 }}
                >
                  Patient Name
                </Th>
                <Th
                  sorted={sortBy === 'weekdayName'}
                  reversed={reverseSortDirection}
                  onSort={() => setSorting('weekdayName')}
                  style={{ width: 120 }}
                >
                  Delivery Weekday
                </Th>
                <Th
                  sorted={sortBy === 'deliveryTimeName'}
                  reversed={reverseSortDirection}
                  onSort={() => setSorting('deliveryTimeName')}
                  style={{ width: 120 }}
                >
                  Delivery Time
                </Th>
                <Th
                  sorted={sortBy === 'patientAddress'}
                  reversed={reverseSortDirection}
                  onSort={() => setSorting('patientAddress')}
                  style={{ width: 120 }}
                >
                  Address
                </Th>
                <Th
                  sorted={sortBy === 'patientCity'}
                  reversed={reverseSortDirection}
                  onSort={() => setSorting('patientCity')}
                  style={{ width: 120 }}
                >
                  City
                </Th>
                <Th
                  sorted={sortBy === 'liquid'}
                  reversed={reverseSortDirection}
                  onSort={() => setSorting('liquid')}
                  style={{ width: 120 }}
                >
                  Liquid
                </Th>
              </tr>
            </thead>
          </Table>
          {standingOrders.data && <InfiniteScrollArea rows={rows} height={350} />}
          {!standingOrders.data && standingOrders.isLoading && (
            <Stack style={{ height: '350px' }} align="center" justify="center">
              <Loader size={84} />
              <Text size="lg" weight={500}>
                Loading...
              </Text>
            </Stack>
          )}
        </ScrollArea>
        <Divider />
        <Group noWrap grow position="apart" mt="lg">
          <Button
            variant="outline"
            mb="lg"
            onClick={() => {
              setCreateModalOpen(true);
            }}
          >
            <FilePlus style={{ marginRight: 5 }} />
            Add a New Standing Order
          </Button>
        </Group>
      </Box>

      <StandingOrderCreateModal
        opened={createModalOpen}
        selectedPatient={selectedPatient}
        onSelectPatient={() => {
          setCreateModalOpen(false);
          setPatientSelectModalOpen(true);
        }}
        onDeSelectPatient={() => {
          setSelectedPatient(undefined);
        }}
        onClose={() => {
          setCreateModalOpen(false);
          setSelectedPatient(undefined);
        }}
        onCreate={(isCreated, newOrder) => {
          setStandingOrderCreated(isCreated);
          setDetailOrder(newOrder);
          setCreateModalOpen(false);
          setSelectedPatient(undefined);
          setDetailModalOpen(true);
        }}
      />
      <PatientSelectModal
        opened={patientSelectModalOpen}
        onClose={() => {
          setPatientSelectModalOpen(false);
          setCreateModalOpen(true);
        }}
        onSelect={(isSelected, selectedPatient) => {
          setPatientSelected(isSelected);
          setSelectedPatient(selectedPatient);
          setPatientSelectModalOpen(false);
          setCreateModalOpen(true);
        }}
      />
      <StandingOrderDetailModal
        standingOrder={detailOrder}
        opened={detailModalOpen}
        isCreated={standingOrderCreated}
        isUpdated={standingOrderUpdated}
        isDeleted={standingOrderDeleted}
        isError={standingOrderUpdateError}
        isRescheduled={standingOrderRescheduled}
        isCancelled={standingOrderCancelled}
        onClose={() => {
          setStandingOrderUpdated(false);
          setStandingOrderUpdateError(false);
          setStandingOrderCreated(false);
          setStandingOrderDeleted(false);
          setStandingOrderRescheduled(false);
          setStandingOrderCancelled(false);
          setDetailModalOpen(false);
        }}
        onEdit={() => {
          setEditModalOpen(true);
          setDetailModalOpen(false);
        }}
        onViewEquipment={() => {
          setDetailModalOpen(false);
          setEquipmentModalOpen(true);
        }}
        onModifications={() => {
          setStandingOrderUpdated(false);
          setStandingOrderUpdateError(false);
          setStandingOrderCreated(false);
          setStandingOrderRescheduled(false);
          setStandingOrderCancelled(false);
          setModificationsModalOpen(true);
          setDetailModalOpen(false);
        }}
      />
      <StandingOrderEditModal
        standingOrder={detailOrder}
        opened={editModalOpen}
        onClose={() => {
          setEditModalOpen(false);
          setDetailModalOpen(true);
        }}
        onEdited={(isUpdated, isError, updatedOrder) => {
          setEditModalOpen(false);
          setDetailModalOpen(true);
          setStandingOrderUpdated(isUpdated);
          setStandingOrderUpdateError(isError);
          setDetailOrder(updatedOrder);
        }}
        onDeleted={() => {
          setEditModalOpen(false);
          setDetailModalOpen(true);
          setStandingOrderDeleted(true);
        }}
      />
      <StandingOrderModificationsModal
        standingOrder={detailOrder}
        flashMessage={flashMessage}
        isError={isError}
        opened={modificationsModalOpen}
        onClose={() => {
          setIsError(false);
          setFlashMessage('');
          setModificationsModalOpen(false);
          setDetailModalOpen(true);
        }}
        onReschedule={() => {
          setIsError(false);
          setFlashMessage('');
          setModificationsModalOpen(false);
          setRescheduleModalOpen(true);
        }}
        onEditReschedule={(order) => {
          setIsError(false);
          setFlashMessage('');
          setSelectedRescheduledOrder(order);
          setModificationsModalOpen(false);
          setRescheduledOrderEditModalOpen(true);
        }}
        onCancellation={() => {
          setIsError(false);
          setFlashMessage('');
          setModificationsModalOpen(false);
          setCancellationModalOpen(true);
        }}
        onEditCancellation={(standingOrderCancellationId) => {
          setIsError(false);
          setFlashMessage('');
          setSelectedCancellationId(standingOrderCancellationId);
          setModificationsModalOpen(false);
          setCancellationEditModalOpen(true);
        }}
      />
      <StandingOrderRescheduleModal
        standingOrder={detailOrder}
        opened={rescheduleModalOpen}
        onClose={() => {
          setRescheduleModalOpen(false);
          setModificationsModalOpen(true);
        }}
        onRescheduled={(isRescheduled, isError) => {
          setStandingOrderRescheduled(isRescheduled);
          setStandingOrderUpdateError(isError);
          setRescheduleModalOpen(false);
          setDetailModalOpen(true);
        }}
      />
      <OrderEditModal
        order={selectedRescheduledOrder}
        opened={rescheduledOrderEditModalOpen}
        onClose={() => {
          setRescheduledOrderEditModalOpen(false);
          setModificationsModalOpen(true);
        }}
        onEdited={(isUpdated, isError) => {
          if (isUpdated) {
            setIsError(false);
            setFlashMessage('Order Reschedule Successfully Updated!');
          }
          if (isError) {
            setIsError(true);
            setFlashMessage('Something went wrong, Order Reschedule not Updated. Please try again');
          }
          setRescheduledOrderEditModalOpen(false);
          setModificationsModalOpen(true);
        }}
        onDeleted={() => {
          setRescheduledOrderEditModalOpen(false);
          setModificationsModalOpen(true);
        }}
      />
      <StandingOrderCancellationModal
        standingOrder={detailOrder}
        opened={cancellationModalOpen}
        onClose={() => {
          setCancellationModalOpen(false);
          setModificationsModalOpen(true);
        }}
        onCancellation={(isCancelled, isError) => {
          setStandingOrderCancelled(isCancelled);
          setStandingOrderUpdateError(isError);
          setCancellationModalOpen(false);
          setDetailModalOpen(true);
        }}
      />
      <StandingOrderCancellationEditModal
        standingOrder={detailOrder}
        standingOrderCancellationId={selectedCancellationId}
        opened={cancellationEditModalOpen}
        onClose={() => {
          setCancellationEditModalOpen(false);
          setModificationsModalOpen(true);
        }}
        onEdited={(isError) => {
          if (isError) {
            setIsError(true);
            setFlashMessage('Something went wrong, Suspension not Updated. Please try again');
          } else {
            setIsError(false);
            setFlashMessage('Suspension Successfully Updated!');
          }
          setCancellationEditModalOpen(false);
          setModificationsModalOpen(true);
        }}
        onDeleted={() => {
          setFlashMessage('Suspension has been Successfully Deleted!');
          setCancellationEditModalOpen(false);
          setModificationsModalOpen(true);
        }}
      />
      <StandingOrderEquipmentModal
        standingOrder={detailOrder}
        opened={equipmentModalOpen}
        onClose={() => {
          setEquipmentModalOpen(false);
          setDetailModalOpen(true);
        }}
      />
    </Box>
  );
};

export default StandingOrdersSubPage;
