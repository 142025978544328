import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';
import { IOrder } from '../../models';

function fetchOrders(request: {
  pageNumber: number;
  pageSize: number;
  filters: number[];
  search: string;
}): Promise<IOrder[]> {
  return externalApi.post(`Order/all`, { json: request }).json();
}

export function useOrders() {
  const queryClient = useQueryClient();
  return useMutation(
    (request: { pageNumber: number; pageSize: number; filters: number[]; search: string }) =>
      fetchOrders(request),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('Orders');
      },
    }
  );
}
