import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type DeleteEquipmentTypeRequest = {
  equipmentTypeId: string;
};

type DeleteEquipmentTypeResponse = {
  equipmentTypeId: number;
};

function deleteEquipmentType(
  request: DeleteEquipmentTypeRequest
): Promise<DeleteEquipmentTypeResponse> {
  return externalApi.delete(`EquipmentType/delete/${request.equipmentTypeId}`).json();
}

function useDeleteEquipmentType() {
  const queryClient = useQueryClient();
  return useMutation((request: DeleteEquipmentTypeRequest) => deleteEquipmentType(request), {
    onSuccess: () => {
      queryClient.invalidateQueries(['EquipmentTypes']);
    },
  });
}
export { useDeleteEquipmentType };
