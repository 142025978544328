import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type NewEquipmentTypeRequest = {
  equipmentTypeId: string;
  name: string;
  lastUpdatedUserId: string;
};

type NewEquipmentTypeResponse = {
  equipmentTypeId: number;
};

function newEquipmentType(request: NewEquipmentTypeRequest): Promise<NewEquipmentTypeResponse> {
  return externalApi.post(`EquipmentType/new`, { json: request }).json();
}

function useNewEquipmentType() {
  const queryClient = useQueryClient();
  return useMutation((request: NewEquipmentTypeRequest) => newEquipmentType(request), {
    onSuccess: () => {
      queryClient.invalidateQueries(['EquipmentTypes']);
    },
  });
}
export { useNewEquipmentType };
