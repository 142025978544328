import {
  Box,
  createStyles,
  Modal,
  useMantineTheme,
  Text,
  Group,
  Center,
  Button,
  Stack,
  ActionIcon,
  Divider,
  Textarea,
} from '@mantine/core';
import React, { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from '@mantine/hooks';
import { DatePickerInput, TimeInput } from '@mantine/dates';
import { Check, Clock, Note, X } from 'tabler-icons-react';
import { useForm } from '@mantine/form';
import {
  useNewCompleteDelivery,
  useNewIncompleteDelivery,
  useOrder,
  useStandingOrder,
  useTrackedOrderEquipmentByOrder,
  useTrackedStandingOrderEquipmentByStandingOrder,
  useUserId,
} from '../../../hooks';
import {
  DialogModal,
  TrackedEquipmentDeliverySelect,
  DeliveryNotesModal,
} from '../../../components';
import dayjs from 'dayjs';
import { IEquipment, ITrackedEquipment } from '../../../models';

const useStyles = createStyles((theme) => ({
  label: {
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
    fontWeight: 500,
  },
  fieldGroup: {
    width: '48%',
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
  clickableIcon: {
    color: `${theme.colors.blue[6]}`,
    padding: '0 !important',
  },
}));

type DeliveryCreateModalProps = {
  recordId: string;
  selectedTrackedEquipment: { trackedEquipment: ITrackedEquipment; index: number }[];
  undeliveredEquipment: { equipment: IEquipment; index: number }[];
  isStandingOrder: boolean;
  isDelivered: boolean;
  selectedDate: Date;
  opened: boolean;
  onClose: () => void;
  onEquipmentSelect: (equipmentId: string, index: number) => void;
  onTrackedEquipmentRemoved: (trackedEquipmentId: string) => void;
  onUndeliveredRemoved: (index: number) => void;
  onCreate: (deliveryId: string) => void;
};

const DeliveryCreateModal = (props: DeliveryCreateModalProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const timeInputRef = useRef<any | null>(null);

  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [notesModalOpen, setNotesModalOpen] = useState<boolean>(false);

  const [notesSaved, setNotesSaved] = useState<boolean>(false);

  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [buttonsLocked, setButtonsLocked] = useState<boolean>(false);

  const [preTrackedEquipment, setPreTrackedEquipment] = useState<
    {
      equipmentId: string;
      equipmentName: string;
      equipmentTypeName: string;
    }[]
  >([]);
  const [trackedEquipmentNotes, setTrackedEquipmentNotes] = useState<
    { trackedEquipmentId: string; notes: string }[]
  >([]);
  const [undeliveredNotes, setUndeliveredNotes] = useState<{ index: number; notes: string }[]>([]);

  const currentUserId = useUserId();

  const trackedOrderEquipment = useTrackedOrderEquipmentByOrder();
  const trackedStandingOrderEquipment = useTrackedStandingOrderEquipmentByStandingOrder();

  const order = useOrder();
  const standingOrder = useStandingOrder();

  const newCompleteDelivery = useNewCompleteDelivery();
  const newIncompleteDelivery = useNewIncompleteDelivery();

  const handleCreate = () => {
    setIsError(false);
    setErrorMessage('');

    if (currentUserId.isSuccess) {
      if (props.isDelivered) {
        if (deliveryForm.values.deliveredTime) {
          if (
            preTrackedEquipment.length ===
            props.selectedTrackedEquipment.length + props.undeliveredEquipment.length
          ) {
            if (!props.isStandingOrder && order.isSuccess) {
              newCompleteDelivery.mutate({
                patientId: order.data.patientId,
                orderId: props.recordId,
                standingOrderId: undefined,
                deliveredDate: deliveryForm.values.deliveredDate,
                deliveredTime: new Date(
                  Date.UTC(
                    (deliveryForm.values.deliveredTime as Date).getFullYear(),
                    (deliveryForm.values.deliveredTime as Date).getMonth(),
                    (deliveryForm.values.deliveredTime as Date).getDate(),
                    (deliveryForm.values.deliveredTime as Date).getHours(),
                    (deliveryForm.values.deliveredTime as Date).getMinutes(),
                    (deliveryForm.values.deliveredTime as Date).getSeconds()
                  )
                ),
                notes: deliveryForm.values.notes,
                forDate: props.selectedDate,
                trackedEquipmentIds: props.selectedTrackedEquipment.map(
                  (a) => a.trackedEquipment.trackedEquipmentId
                ),
                trackedEquipmentNotes: trackedEquipmentNotes,
                undeliveredEquipmentIds: props.undeliveredEquipment.map((a) => ({
                  equipmentId: a.equipment.equipmentId,
                  index: a.index,
                })),
                undeliveredEquipmentNotes: undeliveredNotes.map((a) => ({
                  index: a.index,
                  notes: a.notes,
                })),
                deliveringUserId: currentUserId.data.userId,
              });
            } else if (standingOrder.isSuccess) {
              newCompleteDelivery.mutate({
                patientId: standingOrder.data.patientId,
                orderId: undefined,
                standingOrderId: props.recordId,
                deliveredDate: deliveryForm.values.deliveredDate,
                deliveredTime: new Date(
                  Date.UTC(
                    (deliveryForm.values.deliveredTime as Date).getFullYear(),
                    (deliveryForm.values.deliveredTime as Date).getMonth(),
                    (deliveryForm.values.deliveredTime as Date).getDate(),
                    (deliveryForm.values.deliveredTime as Date).getHours(),
                    (deliveryForm.values.deliveredTime as Date).getMinutes(),
                    (deliveryForm.values.deliveredTime as Date).getSeconds()
                  )
                ),
                notes: deliveryForm.values.notes,
                forDate: props.selectedDate,
                trackedEquipmentIds: props.selectedTrackedEquipment.map(
                  (a) => a.trackedEquipment.trackedEquipmentId
                ),
                trackedEquipmentNotes: trackedEquipmentNotes,
                undeliveredEquipmentIds: props.undeliveredEquipment.map((a) => ({
                  equipmentId: a.equipment.equipmentId,
                  index: a.index,
                })),
                undeliveredEquipmentNotes: undeliveredNotes.map((a) => ({
                  index: a.index,
                  notes: a.notes,
                })),
                deliveringUserId: currentUserId.data.userId,
              });
            } else {
              setIsError(true);
              setErrorMessage('Something went wrong, Order not Updated. Please try again');
              setButtonsLocked(false);
            }
          } else {
            setIsError(true);
            setErrorMessage('Must Select all Tracked Equipment');
            setButtonsLocked(false);
          }
        } else {
          setIsError(true);
          setErrorMessage('Must enter a Delivery Time');
          setButtonsLocked(false);
        }
      } else {
        if (!props.isStandingOrder) {
          newIncompleteDelivery.mutate({
            orderId: props.recordId,
            standingOrderId: undefined,
            notes: deliveryForm.values.notes,
            forDate: props.selectedDate,
            deliveringUserId: currentUserId.data.userId,
          });
        } else {
          newIncompleteDelivery.mutate({
            orderId: undefined,
            standingOrderId: props.recordId,
            notes: deliveryForm.values.notes,
            forDate: props.selectedDate,
            deliveringUserId: currentUserId.data.userId,
          });
        }
      }
    } else {
      setIsError(true);
      setErrorMessage('Something went wrong, Order not Updated. Please try again');
      setButtonsLocked(false);
    }
  };

  const deliveryForm = useForm({
    initialValues: {
      deliveredDate: new Date(),
      deliveredTime: new Date(),
      notes: '',
    },
    validate: {},
  });

  async function submitDelivery() {
    setConfirmModalOpen(true);
  }

  useEffect(() => {
    if (props.opened) {
      if (props.isStandingOrder) {
        standingOrder.mutate(props.recordId);
      } else {
        order.mutate(props.recordId);
      }
    }
  }, [props.opened]);

  useEffect(() => {
    if (newCompleteDelivery.isSuccess) {
      props.onCreate(newCompleteDelivery.data.deliveryId.toString());
    }
    if (newCompleteDelivery.isError) {
      setIsError(true);
      setErrorMessage('Something went wrong, Order not Updated. Please try again');
      setButtonsLocked(false);
    }
  }, [newCompleteDelivery.status]);

  useEffect(() => {
    if (newIncompleteDelivery.isSuccess) {
      props.onCreate(newIncompleteDelivery.data.deliveryId.toString());
    }
    if (newIncompleteDelivery.isError) {
      setIsError(true);
      setErrorMessage('Something went wrong, Order not Updated. Please try again');
      setButtonsLocked(false);
    }
  }, [newIncompleteDelivery.status]);

  useEffect(() => {
    if (props.opened) {
      setIsError(false);
      deliveryForm.reset();
      setButtonsLocked(false);

      if (props.isDelivered) {
        if (!props.isStandingOrder) {
          trackedOrderEquipment.mutate(props.recordId);
        } else {
          trackedStandingOrderEquipment.mutate(props.recordId);
        }
      }
    }
  }, [props.opened]);

  useEffect(() => {
    if (trackedOrderEquipment.isSuccess) {
      setPreTrackedEquipment(
        trackedOrderEquipment.data.map((a) => ({
          equipmentId: a.equipmentId,
          equipmentName: a.equipmentName,
          equipmentTypeName: a.equipmentTypeName,
        }))
      );
    }
  }, [trackedOrderEquipment.status]);

  useEffect(() => {
    if (trackedStandingOrderEquipment.isSuccess) {
      setPreTrackedEquipment(
        trackedStandingOrderEquipment.data.map((a) => ({
          equipmentId: a.equipmentId,
          equipmentName: a.equipmentName,
          equipmentTypeName: a.equipmentTypeName,
        }))
      );
    }
  }, [trackedStandingOrderEquipment.status]);

  return (
    <Modal
      title={isError ? <Text color="red">{errorMessage}</Text> : ''}
      opened={props.opened}
      size={isSmallMobile ? 'xs' : 'md'}
      onClose={() => {
        setIsError(false);
        setErrorMessage('');
        setNotesSaved(false);
        deliveryForm.reset();
        props.onClose();
      }}
      styles={{
        content: {
          border: `${
            !props.isDelivered
              ? `3px solid ${theme.colors.red[5]}`
              : `3px solid ${theme.colors.blue[6]}`
          }`,
        },
      }}
    >
      <Box>
        <Center>
          <Text size="xl" weight={500} align="center">
            {`Mark Order as ${props.isDelivered ? 'Delivered' : 'Undelivered'}`}
          </Text>
        </Center>
        <form
          onSubmit={deliveryForm.onSubmit(() => {
            setButtonsLocked(true);
            submitDelivery();
          })}
          autoComplete="off"
        >
          <Stack align="stretch" style={{ padding: 5 }} mt={10}>
            {props.isDelivered && (
              <Stack align="stretch">
                <Group spacing={0} position="apart">
                  <Stack spacing={0} className={classes.fieldGroup}>
                    <Text className={classes.label}>Date of Delivery</Text>
                    <DatePickerInput
                      valueFormat="YYYY-MM-DD"
                      firstDayOfWeek={0}
                      required
                      dropdownType="modal"
                      modalProps={{
                        title: 'Date of Delivery',
                        withCloseButton: true,
                        closeButtonProps: { style: { position: 'fixed', right: 10 } },
                        yOffset: '15vh',
                        styles: { title: { fontWeight: 500, textAlign: 'center', width: '100%' } },
                      }}
                      ml={5}
                      {...deliveryForm.getInputProps('deliveredDate')}
                    />
                  </Stack>
                  <Stack spacing={0} className={classes.fieldGroup}>
                    <Text className={classes.label}>Time of Delivery</Text>
                    <TimeInput
                      ref={timeInputRef}
                      rightSection={
                        <ActionIcon onClick={() => timeInputRef.current!.showPicker()}>
                          <Clock size="1rem" />
                        </ActionIcon>
                      }
                      required
                      ml={5}
                      value={dayjs(deliveryForm.values.deliveredTime).format('HH:mm')}
                      onChange={(e) => {
                        let inputDateTime = new Date();
                        inputDateTime.setHours(parseInt(e.currentTarget.value.split(':')[0]));
                        inputDateTime.setMinutes(parseInt(e.currentTarget.value.split(':')[1]));
                        deliveryForm.setFieldValue('deliveredTime', inputDateTime);
                      }}
                    />
                  </Stack>
                </Group>
                {preTrackedEquipment[0] && (
                  <Stack align="stretch" spacing={0}>
                    <Text className={classes.label}>Tracked Equipment</Text>
                    <Box ml={5}>
                      <TrackedEquipmentDeliverySelect
                        preTrackedEquipment={preTrackedEquipment}
                        selectedTrackedEquipment={props.selectedTrackedEquipment}
                        trackedEquipmentNotes={trackedEquipmentNotes}
                        undeliveredEquipment={props.undeliveredEquipment}
                        undeliveredEquipmentNotes={undeliveredNotes}
                        onSelectCardClicked={(equipmentId, index) => {
                          props.onEquipmentSelect(equipmentId, index);
                        }}
                        onTrackedEquipmentRemoved={(trackedEquipmentId) => {
                          props.onTrackedEquipmentRemoved(trackedEquipmentId);
                          setTrackedEquipmentNotes((a) =>
                            a.filter((b) => b.trackedEquipmentId !== trackedEquipmentId)
                          );
                        }}
                        onUndeliveredRemoved={(index) => {
                          props.onUndeliveredRemoved(index);
                          setUndeliveredNotes((a) => a.filter((b) => b.index !== index));
                        }}
                        onTrackedNotesSaved={(trackedEquipmentId, notes) => {
                          setTrackedEquipmentNotes((a) => {
                            if (a.some((b) => b.trackedEquipmentId === trackedEquipmentId)) {
                              return a
                                .filter((b) => b.trackedEquipmentId !== trackedEquipmentId)
                                .concat({
                                  trackedEquipmentId: trackedEquipmentId,
                                  notes: notes ?? '',
                                });
                            } else {
                              return a.concat({
                                trackedEquipmentId: trackedEquipmentId,
                                notes: notes ?? '',
                              });
                            }
                          });
                        }}
                        onUndeliveredNotesSaved={(index, notes) => {
                          setUndeliveredNotes((a) => {
                            if (a.some((b) => b.index === index)) {
                              return a
                                .filter((b) => b.index !== index)
                                .concat({ index: index, notes: notes ?? '' });
                            } else {
                              return a.concat({
                                index: index,
                                notes: notes ?? '',
                              });
                            }
                          });
                        }}
                      />
                    </Box>
                  </Stack>
                )}
              </Stack>
            )}

            {props.isDelivered && (
              <Group spacing={0}>
                <ActionIcon
                  className={classes.clickableIcon}
                  onClick={() => {
                    setNotesModalOpen(true);
                  }}
                >
                  <Note />
                </ActionIcon>
                <Text size="sm" weight={500}>
                  Add Notes
                </Text>
                {notesSaved && (
                  <Group ml={theme.spacing.xs} spacing={0} mb={-2}>
                    <Text size={`calc(${theme.fontSizes.xs} * 0.9)`} color="dimmed">
                      Saved
                    </Text>
                    <Check size={12} color={theme.colors.gray[6]} />
                  </Group>
                )}
              </Group>
            )}
            {props.isDelivered && <Divider />}

            {!props.isDelivered && (
              <Stack align="stretch" spacing={0}>
                <Text className={classes.label}>Notes</Text>
                <Textarea minRows={4} ml={5} {...deliveryForm.getInputProps('notes')} />
              </Stack>
            )}

            <Group position="apart" grow noWrap>
              <Button variant="outline" type="submit" disabled={buttonsLocked}>
                <Check style={{ marginRight: 5 }} />{' '}
                <Text className={classes.buttonText}>Confirm</Text>
              </Button>
              <Button
                variant="outline"
                color="red"
                onClick={() => {
                  setIsError(false);
                  setErrorMessage('');
                  setNotesSaved(false);
                  deliveryForm.reset();
                  props.onClose();
                }}
              >
                <X style={{ marginRight: 5 }} /> <Text className={classes.buttonText}>Discard</Text>
              </Button>
            </Group>
          </Stack>
        </form>
      </Box>
      <DeliveryNotesModal
        opened={notesModalOpen}
        header="Delivery Notes"
        currentNotes={deliveryForm.values.notes}
        onSave={(updatedNotes) => {
          setNotesModalOpen(false);
          deliveryForm.setFieldValue('notes', updatedNotes);
          setNotesSaved(true);
        }}
        onClose={() => {
          setNotesModalOpen(false);
        }}
      />
      <DialogModal
        isOpen={confirmModalOpen}
        titleText={props.isDelivered ? 'Deliver Order?' : 'Mark Order as Undelivered?'}
        isNegative={!props.isDelivered}
        body={
          <Box>
            {props.isStandingOrder && standingOrder.data && (
              <Box>
                {props.isDelivered && (
                  <Box>
                    <Text size="sm">
                      Are you sure you want to make this delivery to{' '}
                      <b>{standingOrder.data.patientFullName}</b> at{' '}
                      <b>{standingOrder.data.patientAddress}</b>?
                    </Text>
                  </Box>
                )}
                {!props.isDelivered && (
                  <Box>
                    <Text size="sm">
                      Are you sure you want to mark the order for{' '}
                      <b>{standingOrder.data.patientFullName}</b> at{' '}
                      <b>{standingOrder.data.patientAddress}</b> as Undelivered?
                    </Text>
                  </Box>
                )}
              </Box>
            )}
            {!props.isStandingOrder && order.data && (
              <Box>
                {props.isDelivered && (
                  <Box>
                    <Text size="sm">
                      Are you sure you want to make this delivery to{' '}
                      <b>{order.data.patientFullName}</b> at <b>{order.data.patientAddress}</b>?
                    </Text>
                  </Box>
                )}
                {!props.isDelivered && (
                  <Box>
                    <Text size="sm">
                      Are you sure you want to mark the order for {order.data.patientFullName} at{' '}
                      {order.data.patientAddress} as Undelivered?
                    </Text>
                  </Box>
                )}
              </Box>
            )}
          </Box>
        }
        confirmText={props.isDelivered ? 'Yes, Deliver' : 'Yes, Mark Undelivered'}
        onConfirm={() => {
          setConfirmModalOpen(false);
          handleCreate();
        }}
        onClose={() => {
          setConfirmModalOpen(false);
          setButtonsLocked(false);
        }}
      />
    </Modal>
  );
};

export default DeliveryCreateModal;
