import {
  ActionIcon,
  Box,
  Button,
  Center,
  Checkbox,
  createStyles,
  Group,
  Menu,
  Modal,
  SegmentedControl,
  Stack,
  Text,
  Textarea,
  useMantineTheme,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { DatePickerInput } from '@mantine/dates';
import React, { useEffect, useState } from 'react';
import { Dots, Pencil, Trash, X } from 'tabler-icons-react';
import { useMediaQuery } from '@mantine/hooks';
import { IOrder } from '../../../models';
import { useDeleteOrder, useEditOrder, useUserId } from '../../../hooks';
import { useModals } from '@mantine/modals';
import { DialogModal, PatientNotes } from '../../../components';

const useStyles = createStyles((theme) => ({
  label: {
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
    fontWeight: 500,
  },
  fieldGroup: {
    width: '48%',
  },
  detailLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
  },
  detail: {
    fontSize: theme.fontSizes.md,
    paddingLeft: 10,
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
  notesCard: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    paddingLeft: theme.spacing.sm,
    paddingRight: theme.spacing.sm,
    paddingTop: 3,
    width: '100%',
    height: '125px',
  },
}));

type OrderEditModalProps = {
  order: IOrder;
  opened: boolean;
  onClose: () => void;
  onEdited: (isUpdated: boolean, isError: boolean, initialOrder: IOrder) => void;
  onDeleted: () => void;
};

const OrderEditModal = (props: OrderEditModalProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const modals = useModals();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const currentUserId = useUserId();

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [isAMDelivery, setIsAMDelivery] = useState<boolean>(props.order.isAMDelivery);
  const initialOrder = {
    orderId: props.order.orderId,
    patientId: props.order.patientId,
    patientFirstName: props.order.patientFirstName,
    patientLastName: props.order.patientLastName,
    patientFullName: `${props.order.patientFirstName} ${props.order.patientLastName}`,
    patientFullNameFormatted: `${props.order.patientLastName}, ${props.order.patientFirstName}`,
    patientPhoneNumber: props.order.patientPhoneNumber,
    patientAddress: props.order.patientAddress,
    patientCity: props.order.patientCity,
    patientNotes: props.order.patientNotes,
    isAMDelivery: props.order.isAMDelivery,
    deliveryTimeName: props.order.deliveryTimeName,
    requestedDeliveryDate: new Date(props.order.requestedDeliveryDate),
    liquid: props.order.liquid,
    notes: props.order.notes,
    isUrgent: props.order.isUrgent,
    standingOrderId: props.order.standingOrderId,
    standingOrderReschedule: props.order.standingOrderReschedule,
    deliveryReschedule: props.order.deliveryReschedule,
    fulfilled: props.order.fulfilled,
    lastUpdatedUserId: props.order.lastUpdatedUserId,
    lastUpdatedDateTime: props.order.lastUpdatedDateTime,
  };

  const [submitClicked, setSubmitClicked] = useState<boolean>(false);

  const editOrder = useEditOrder();
  const deleteOrder = useDeleteOrder();

  const handleDelete = () => {
    deleteOrder.mutate({ orderId: props.order.orderId });
  };

  const editForm = useForm<IOrder>({
    initialValues: initialOrder,
    validate: {},
  });

  async function submitOrderUpdate() {
    setSubmitClicked(true);

    editOrder.mutate({
      orderId: editForm.values.orderId,
      requestedDeliveryDate: editForm.values.requestedDeliveryDate,
      isAMDelivery: isAMDelivery,
      liquid: editForm.values.liquid,
      notes: editForm.values.notes,
      isUrgent: editForm.values.isUrgent,
      lastUpdatedUserId: currentUserId.data!.userId,
    });
  }

  useEffect(() => {
    editForm.setValues(props.order);
  }, [props.order, props.opened]);

  useEffect(() => {
    if (submitClicked) {
      if (editOrder.isSuccess) {
        setSubmitClicked(false);
        setIsError(false);
        setErrorMessage('');
        props.onEdited(true, false, {
          orderId: props.order.orderId,
          patientId: props.order.patient,
          patientFirstName: props.order.patientFirstName,
          patientLastName: props.order.patientLastName,
          patientFullName: `${props.order.patientFirstName} ${props.order.patientLastName}`,
          patientFullNameFormatted: `${props.order.patientLastName}, ${props.order.patientFirstName}`,
          patientPhoneNumber: props.order.patientPhoneNumber,
          patientAddress: props.order.patientAddress,
          patientCity: props.order.patientCity,
          patientNotes: props.order.patientNotes,
          isAMDelivery: isAMDelivery,
          deliveryTimeName: isAMDelivery ? 'AM' : 'PM',
          requestedDeliveryDate: editForm.values.requestedDeliveryDate,
          liquid: editForm.values.liquid,
          notes: editForm.values.notes,
          isUrgent: editForm.values.isUrgent,
          standingOrderId: props.order.standingOrderId,
          standingOrderReschedule: props.order.standingOrderReschedule,
          deliveryReschedule: props.order.deliveryReschedule,
          fulfilled: props.order.fulfilled,
          lastUpdatedUserId: currentUserId.data!.userId,
          lastUpdatedDateTime: new Date(),
        });
      } else if (editOrder.isError) {
        setSubmitClicked(false);
        setIsError(false);
        setErrorMessage('');
        props.onEdited(false, true, initialOrder);
      }
    }
  }, [editOrder.status]);

  useEffect(() => {
    if (deleteOrder.isSuccess) {
      setIsError(false);
      setErrorMessage('');
      props.onDeleted();
    }
    if (deleteOrder.isError) {
      setIsError(true);
      setErrorMessage('Something went wrong, Order not Deleted. Please try again');
    }
  }, [deleteOrder.status]);

  return (
    <Modal
      title={isError ? <Text color="red">{errorMessage}</Text> : ''}
      opened={props.opened}
      size={isSmallMobile ? 'xs' : 'md'}
      onClose={() => {
        setIsError(false);
        setErrorMessage('');
        props.onClose();
      }}
      style={{ overflow: 'visible' }}
    >
      <Box>
        <Center>
          <Group noWrap ml={!isSmallMobile ? 25 : 0}>
            <Text size="xl" weight={500} align="center">
              Edit Order Information
            </Text>
            <Menu>
              <Menu.Target>
                <ActionIcon>
                  <Dots />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item
                  icon={<Trash color={`${theme.colors.red[6]}`} />}
                  onClick={() => {
                    setDeleteModalOpen(true);
                  }}
                >
                  Delete Order
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>
        </Center>
        <form onSubmit={editForm.onSubmit(() => submitOrderUpdate())} autoComplete="off">
          <Stack align="stretch" style={{ padding: 5 }}>
            <Group>
              <Stack spacing={0} style={{ width: '50%' }}>
                <Text className={classes.detailLabel}>Patient Name</Text>
                <Group spacing={5} noWrap>
                  <Text
                    className={classes.detail}
                  >{`${props.order.patientLastName}, ${props.order.patientFirstName}`}</Text>
                </Group>
              </Stack>
              <Stack spacing={0}>
                <Text className={classes.detailLabel}>Phone number</Text>
                <Text className={classes.detail}>{`${props.order.patientPhoneNumber}`}</Text>
              </Stack>
            </Group>
            <Group>
              <Stack spacing={0} style={{ width: '50%' }}>
                <Text className={classes.detailLabel}>Delivery Address</Text>
                <Text className={classes.detail}>{`${props.order.patientAddress}`}</Text>
              </Stack>
              <Stack spacing={0}>
                <Text className={classes.detailLabel}>City</Text>
                <Text className={classes.detail}>{`${props.order.patientCity}`}</Text>
              </Stack>
            </Group>
            <PatientNotes notes={props.order.patientNotes} />

            <Group noWrap>
              <Stack spacing={0} className={classes.fieldGroup}>
                <Text className={classes.label}>Requested Delivery Date</Text>
                <DatePickerInput
                  valueFormat="YYYY-MM-DD"
                  firstDayOfWeek={0}
                  defaultValue={
                    props.order.requestedDeliveryDate
                      ? new Date(props.order.requestedDeliveryDate)
                      : undefined
                  }
                  required
                  dropdownType="modal"
                  modalProps={{
                    title: 'Requested Delivery Date',
                    withCloseButton: true,
                    closeButtonProps: { style: { position: 'fixed', right: 10 } },
                    yOffset: '15vh',
                    styles: { title: { fontWeight: 500, textAlign: 'center', width: '100%' } },
                  }}
                  style={{ width: '125px' }}
                  onChange={(a) => {
                    editForm.setFieldValue('requestedDeliveryDate', a as Date);
                  }}
                />
              </Stack>
              <Stack spacing={0} className={classes.fieldGroup} ml={15}>
                <Text className={classes.label}>Delivery Time</Text>
                <SegmentedControl
                  color="blue"
                  data={[
                    { label: 'AM', value: 'AM' },
                    { label: 'PM', value: 'PM' },
                  ]}
                  defaultValue={props.order.isAMDelivery ? 'AM' : 'PM'}
                  ml={10}
                  onChange={(e) => {
                    e === 'AM' ? setIsAMDelivery(true) : setIsAMDelivery(false);
                  }}
                />
              </Stack>
            </Group>

            <Group position="left" spacing="xs" pl="xs">
              <Checkbox defaultChecked={props.order.liquid} {...editForm.getInputProps('liquid')} />
              <Text className={classes.label}>Liquid</Text>
            </Group>

            <Stack align="stretch" spacing={0}>
              <Text className={classes.label}>Notes</Text>
              <Textarea minRows={4} {...editForm.getInputProps('notes')} />
            </Stack>

            <Box
              style={{
                border: `1px solid ${theme.colors.gray[4]}`,
                borderRadius: theme.radius.sm,
                padding: 10,
              }}
            >
              <Checkbox
                label="Is this Order Urgent?"
                defaultChecked={props.order.isUrgent}
                {...editForm.getInputProps('isUrgent')}
              />
            </Box>

            <Group position="apart" grow noWrap mt="xs">
              <Button variant="outline" type="submit">
                <Pencil style={{ marginRight: 5 }} />{' '}
                <Text className={classes.buttonText}>{`Update ${
                  !isSmallMobile ? 'Order' : ''
                }`}</Text>
              </Button>
              <Button
                variant="outline"
                color="red"
                onClick={() => {
                  setIsError(false);
                  setErrorMessage('');
                  props.onClose();
                }}
              >
                <X style={{ marginRight: 5 }} /> <Text className={classes.buttonText}>Cancel</Text>
              </Button>
            </Group>
          </Stack>
        </form>
      </Box>
      <DialogModal
        isOpen={deleteModalOpen}
        isNegative
        titleText="Delete Order?"
        body={
          <Text align="center" color="dimmed" size="lg" mb="xs">
            Are you sure you want to delete this Order?
          </Text>
        }
        confirmText="Delete Order"
        onConfirm={() => {
          setDeleteModalOpen(false);
          handleDelete();
        }}
        onClose={() => {
          setDeleteModalOpen(false);
        }}
      />
    </Modal>
  );
};

export default OrderEditModal;
