import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type DeleteOrderEquipmentRequest = {
  orderEquipmentId: string;
};

type DeleteOrderEquipmentResponse = {
  orderEquipmentId: number;
};

function deleteOrderEquipment(
  request: DeleteOrderEquipmentRequest
): Promise<DeleteOrderEquipmentResponse> {
  return externalApi.delete(`OrderEquipment/delete/${request.orderEquipmentId}`).json();
}

function useDeleteOrderEquipment() {
  const queryClient = useQueryClient();
  return useMutation((request: DeleteOrderEquipmentRequest) => deleteOrderEquipment(request), {
    onSuccess: () => {
      queryClient.invalidateQueries(['OrderEquipmentByOrder']);
    },
  });
}
export { useDeleteOrderEquipment };
