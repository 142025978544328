import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type EditTrackedEquipmentServiceRequest = {
  trackedEquipmentServiceId: string;
  technicianUserId: string;
  serviceDateTime: Date;
  lastUpdatedUserId: string;
};

type EditTrackedEquipmentServiceResponse = {
  trackedEquipmentServiceId: number;
};

function editTrackedEquipmentService(
  request: EditTrackedEquipmentServiceRequest
): Promise<EditTrackedEquipmentServiceResponse> {
  return externalApi.patch(`TrackedEquipmentService/patch/details`, { json: request }).json();
}

function useEditTrackedEquipmentServiceDetails() {
  const queryClient = useQueryClient();
  return useMutation(
    (request: EditTrackedEquipmentServiceRequest) => editTrackedEquipmentService(request),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('TrackedEquipmentServiceByTrackedEquipment');
        queryClient.invalidateQueries('AllTrackedEquipment');
      },
    }
  );
}
export { useEditTrackedEquipmentServiceDetails };
