import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type DeleteOrderRequest = {
  orderId: string;
};

type DeleteOrderResponse = {
  orderId: number;
};

function deleteOrder(request: DeleteOrderRequest): Promise<DeleteOrderResponse> {
  return externalApi.delete(`Order/delete/${request.orderId}`).json();
}

function useDeleteOrder() {
  const queryClient = useQueryClient();
  return useMutation((request: DeleteOrderRequest) => deleteOrder(request), {
    onSuccess: () => {
      queryClient.invalidateQueries(['Orders']);
    },
  });
}
export { useDeleteOrder };
