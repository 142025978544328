import { Box, Button, Group, Modal, Stack, Text, Title, useMantineTheme } from '@mantine/core';
import { ReactNode } from 'react';

type Props = {
  isOpen: boolean;
  isNegative?: boolean;
  titleText: string;
  body: ReactNode;
  confirmText: string;
  singleButton?: boolean;
  onConfirm: () => void;
  onClose: () => void;
};

const DialogModal = (props: Props) => {
  const theme = useMantineTheme();

  return (
    <Modal
      padding={0}
      size="xs"
      opened={props.isOpen}
      onClose={() => {
        props.onClose();
      }}
      withCloseButton={false}
      closeOnClickOutside
      centered
      zIndex={999}
    >
      <Box>
        <Stack spacing={4} pt="xl" px="xl">
          <Title align="center" order={2}>
            {props.titleText}
          </Title>
          {props.body}
        </Stack>
        <Group
          mt="lg"
          position="right"
          p="lg"
          sx={{ background: theme.colors.gray[1], borderRadius: theme.radius.md }}
        >
          {!props.singleButton && (
            <Button
              variant="default"
              onClick={() => {
                props.onClose();
              }}
            >
              Cancel
            </Button>
          )}
          <Button
            color={props.isNegative ? 'red' : ''}
            onClick={() => {
              props.onConfirm();
            }}
          >
            {props.confirmText}
          </Button>
        </Group>
      </Box>
    </Modal>
  );
};

export default DialogModal;
