import { ActionIcon, Box, createStyles, Group, TextInput, Text, Stack } from '@mantine/core';
import React, { useState } from 'react';
import { DeviceFloppy, X } from 'tabler-icons-react';

const useStyles = createStyles((theme) => ({
  equipmentBox: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    paddingLeft: 3,
    paddingTop: 3,
    paddingBottom: 3,
    paddingRight: theme.spacing.md,
    width: '100%',
    height: '70',
  },
}));

type EquipmentEditSerialNumberCardProps = {
  equipmentName: string;
  equipmentTypeName: string;
  serialNumber: string;
  onClose: () => void;
  onSave: (newSerialNumber: string) => void;
};

const EquipmentEditSerialNumberCard = (props: EquipmentEditSerialNumberCardProps) => {
  const { classes } = useStyles();

  const [serialNumber, setSerialNumber] = useState<string>(props.serialNumber);

  return (
    <Box className={classes.equipmentBox} mb={5}>
      <Group position="apart" noWrap spacing={0}>
        <Stack pl="sm" spacing={0}>
          <Text weight={500}>{props.equipmentName}</Text>
          <Group spacing={5}>
            <Text size="xs" color="dimmed">
              {props.equipmentTypeName}
            </Text>
          </Group>
        </Stack>
        <Stack align="stretch" spacing={0} style={{ width: '45%' }}>
          <Text size="xs" color="dimmed">
            Serial Number
          </Text>
          <TextInput
            placeholder="Serial Number"
            value={serialNumber}
            onChange={(e) => {
              setSerialNumber(e.currentTarget.value);
            }}
          />
        </Stack>
        <Stack spacing={0}>
          <ActionIcon
            onClick={() => {
              props.onSave(serialNumber);
            }}
          >
            <DeviceFloppy />
          </ActionIcon>
          <ActionIcon
            onClick={() => {
              props.onClose();
            }}
          >
            <X />
          </ActionIcon>
        </Stack>
      </Group>
    </Box>
  );
};

export default EquipmentEditSerialNumberCard;
