import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type NewPatientEquipmentRequest = {
  patientId: string;
  equipmentId: string;
  quantity: number;
  lastUpdatedUserId: string;
};

type NewPatientEquipmentResponse = {
  patientEquipmentId: number;
};

function newPatientEquipment(
  request: NewPatientEquipmentRequest
): Promise<NewPatientEquipmentResponse> {
  return externalApi.post(`PatientEquipment/new`, { json: request }).json();
}

function useNewPatientEquipment() {
  const queryClient = useQueryClient();
  return useMutation((request: NewPatientEquipmentRequest) => newPatientEquipment(request), {
    onSuccess: () => {
      queryClient.invalidateQueries(['PatientEquipmentByPatient']);
    },
  });
}
export { useNewPatientEquipment };
