import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type DeleteTrackedEquipmentRepairRequest = {
  trackedEquipmentRepairId: string;
};

type DeleteTrackedEquipmentRepairResponse = {
  trackedEquipmentRepairId: number;
};

function deleteTrackedEquipmentRepair(
  request: DeleteTrackedEquipmentRepairRequest
): Promise<DeleteTrackedEquipmentRepairResponse> {
  return externalApi
    .delete(`TrackedEquipmentRepair/delete/${request.trackedEquipmentRepairId}`)
    .json();
}

function useDeleteTrackedEquipmentRepair() {
  const queryClient = useQueryClient();
  return useMutation(
    (request: DeleteTrackedEquipmentRepairRequest) => deleteTrackedEquipmentRepair(request),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('TrackedEquipmentRepairByTrackedEquipment');
        queryClient.invalidateQueries('AllTrackedEquipment');
      },
    }
  );
}
export { useDeleteTrackedEquipmentRepair };
