import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';
import { ITrackedEquipmentFilter } from '../../models';

function fetchAllTrackedEquipmentCount(request: {
  filter: ITrackedEquipmentFilter;
  search: string;
}): Promise<{ count: number }> {
  return externalApi.post(`TrackedEquipment/all/count`, { json: request }).json();
}

export function useAllTrackedEquipmentCount() {
  const queryClient = useQueryClient();
  return useMutation(
    (request: { filter: ITrackedEquipmentFilter; search: string }) =>
      fetchAllTrackedEquipmentCount(request),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('AllTrackedEquipmentCount');
      },
    }
  );
}
