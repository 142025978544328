import { Box, useMantineTheme, createStyles, Text, Center, Group, Stack } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import dayjs from 'dayjs';
import React from 'react';
import {
  DeliveriesReportDeliveredItem,
  DeliveriesReportHeader,
  DeliveriesReportPendingItem,
  DeliveriesReportSummary,
  DeliveriesReportUndeliveredItem,
} from '..';
import {
  useReportDeliveredByDate,
  useReportPendingByDate,
  useReportUndeliveredByDate,
} from '../../hooks';

const useStyles = createStyles((theme, _params) => ({
  reportWrapper: {
    border: `1px solid ${theme.colors.gray[5]}`,
    borderRadius: theme.radius.sm,
    width: 1200,
  },
  reportBody: {
    paddingLeft: 10,
    paddingRight: 10,
  },
}));

type DeliveriesReportProps = {
  reportedDate: Date;
  timeFrame: 'AM' | 'PM' | 'Full Day';
};

const DeliveriesReport = (props: DeliveriesReportProps) => {
  const { classes, cx } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const completedDeliveries = useReportDeliveredByDate({
    date: props.reportedDate,
    isAMTimeframe: props.timeFrame == 'AM' ? true : false,
    isFullDayTimeframe: props.timeFrame == 'Full Day' ? true : false,
  });
  const incompleteDeliveries = useReportUndeliveredByDate({
    date: props.reportedDate,
    isAMTimeframe: props.timeFrame == 'AM' ? true : false,
    isFullDayTimeframe: props.timeFrame == 'Full Day' ? true : false,
  });
  const pendingDeliveries = useReportPendingByDate({
    date: props.reportedDate,
    isAMTimeframe: props.timeFrame == 'AM' ? true : false,
    isFullDayTimeframe: props.timeFrame == 'Full Day' ? true : false,
  });

  const completedDeliveryItems = (completedDeliveries.data ?? []).map((a, i) => {
    if (completedDeliveries.data && incompleteDeliveries.data && pendingDeliveries.data) {
      let item = (
        <DeliveriesReportDeliveredItem
          deliveryId={a.deliveryId}
          orderId={a.orderId}
          standingOrderId={a.standingOrderId}
          patientFirstName={a.patientFirstName}
          patientLastName={a.patientLastName}
          patientAddress={a.deliveryAddress}
          patientCityName={a.deliveryCity}
          patientPhoneNumber={a.patientPhoneNumber}
          requestedDeliveryDate={dayjs(a.requestedDeliveryDate).format('YYYY-MM-DD')}
          requestedDeliveryTimeName={a.isAMRequestedDelivery ? 'AM' : 'PM'}
          isStandingOrder={a.isStandingOrder}
          isRescheduledStandingOrder={a.isStandingOrderReschedule}
          isLiquid={a.liquid}
          orderNotes={a.orderNotes}
          deliveredAt={dayjs(a.deliveredAt).format('h:mm A')}
          deliveryNotes={a.deliveryNotes}
        />
      );

      let pageTotal =
        (completedDeliveries.data.length % 7 > 0
          ? Math.floor(completedDeliveries.data.length / 7) + 1
          : Math.floor(completedDeliveries.data.length / 7)) +
        (incompleteDeliveries.data.length % 7 > 0
          ? Math.floor(incompleteDeliveries.data.length / 7) + 1
          : Math.floor(incompleteDeliveries.data.length / 7)) +
        (pendingDeliveries.data.length % 7 > 0
          ? Math.floor(pendingDeliveries.data.length / 7) + 1
          : Math.floor(pendingDeliveries.data.length / 7));

      if (i % 7 === 0) {
        if (completedDeliveries.data?.length === 1) {
          return (
            <Box key={a.deliveryId} mt={-Math.floor(i / 7) + 1}>
              <DeliveriesReportHeader
                reportedDate={props.reportedDate}
                timeFrame={props.timeFrame}
                pageNumber={Math.floor(i / 7) + 1}
                pageTotal={pageTotal}
              />
              <DeliveriesReportSummary
                reportedDate={props.reportedDate}
                timeFrame={props.timeFrame}
              />
              <Center style={{ backgroundColor: theme.colors.gray[4] }} mb={10}>
                <Text size="lg" weight={500} my={5}>
                  Completed Deliveries
                </Text>
              </Center>
              {item}
              <Box style={{ height: 190 * (7 - (completedDeliveries.data.length % 7)) }} />
            </Box>
          );
        } else {
          if (
            i === completedDeliveries.data.length - 1 &&
            (incompleteDeliveries.data.length > 0 || pendingDeliveries.data.length > 0)
          ) {
            return (
              <Box key={a.deliveryId} mt={-Math.floor(i / 7) + 1}>
                <DeliveriesReportHeader
                  reportedDate={props.reportedDate}
                  timeFrame={props.timeFrame}
                  pageNumber={Math.floor(i / 7) + 1}
                  pageTotal={pageTotal}
                />
                <DeliveriesReportSummary
                  reportedDate={props.reportedDate}
                  timeFrame={props.timeFrame}
                />
                <Center style={{ backgroundColor: theme.colors.gray[4] }} mb={10}>
                  <Text size="lg" weight={500} my={5}>
                    Completed Deliveries
                  </Text>
                </Center>
                {item}
                <Box style={{ height: 190 * (7 - (completedDeliveries.data.length % 7)) }} />
              </Box>
            );
          } else {
            return (
              <Box key={a.deliveryId} mt={-Math.floor(i / 7) + 1}>
                <DeliveriesReportHeader
                  reportedDate={props.reportedDate}
                  timeFrame={props.timeFrame}
                  pageNumber={Math.floor(i / 7) + 1}
                  pageTotal={pageTotal}
                />
                <DeliveriesReportSummary
                  reportedDate={props.reportedDate}
                  timeFrame={props.timeFrame}
                />
                <Center style={{ backgroundColor: theme.colors.gray[4] }} mb={10}>
                  <Text size="lg" weight={500} my={5}>
                    Completed Deliveries
                  </Text>
                </Center>
                {item}
              </Box>
            );
          }
        }
      } else if (
        i === completedDeliveries.data.length - 1 &&
        i % 7 > 0 &&
        completedDeliveries.data.length % 7 > 0
      ) {
        if (
          i === completedDeliveries.data.length - 1 &&
          !(incompleteDeliveries.data ?? [])[0] &&
          !(pendingDeliveries.data ?? [])[0]
        ) {
          return <Box key={a.deliveryId}>{item}</Box>;
        } else {
          return (
            <Box key={a.deliveryId}>
              {item}
              <Box style={{ height: 190 * (7 - (completedDeliveries.data.length % 7)) }} />
            </Box>
          );
        }
      } else {
        return <Box key={a.deliveryId}>{item}</Box>;
      }
    }
  });

  const incompleteDeliveryItems = (incompleteDeliveries.data ?? []).map((a, i) => {
    if (completedDeliveries.data && incompleteDeliveries.data && pendingDeliveries.data) {
      let item = (
        <DeliveriesReportUndeliveredItem
          key={a.deliveryId}
          orderId={a.orderId}
          standingOrderId={a.standingOrderId}
          patientFirstName={a.patientFirstName}
          patientLastName={a.patientLastName}
          patientAddress={a.deliveryAddress}
          patientCityName={a.deliveryCity}
          patientPhoneNumber={a.patientPhoneNumber}
          requestedDeliveryDate={dayjs(a.requestedDeliveryDate).format('YYYY-MM-DD')}
          requestedDeliveryTimeName={a.isAMRequestedDelivery ? 'AM' : 'PM'}
          isStandingOrder={a.isStandingOrder}
          isRescheduledStandingOrder={a.isStandingOrderReschedule}
          isLiquid={a.liquid}
          orderNotes={a.orderNotes}
          deliveryNotes={a.deliveryNotes}
        />
      );

      let pageNumberBase =
        completedDeliveries.data.length % 7 > 0
          ? Math.floor(completedDeliveries.data.length / 7) + 1
          : Math.floor(completedDeliveries.data.length / 7);

      let pageTotal =
        (completedDeliveries.data.length % 7 > 0
          ? Math.floor(completedDeliveries.data.length / 7) + 1
          : Math.floor(completedDeliveries.data.length / 7)) +
        (incompleteDeliveries.data.length % 7 > 0
          ? Math.floor(incompleteDeliveries.data.length / 7) + 1
          : Math.floor(incompleteDeliveries.data.length / 7)) +
        (pendingDeliveries.data.length % 7 > 0
          ? Math.floor(pendingDeliveries.data.length / 7) + 1
          : Math.floor(pendingDeliveries.data.length / 7));

      if (i % 7 === 0) {
        if (incompleteDeliveries.data.length === 1) {
          return (
            <Box key={a.deliveryId} mt={-pageNumberBase}>
              <DeliveriesReportHeader
                reportedDate={props.reportedDate}
                timeFrame={props.timeFrame}
                pageNumber={Math.floor(i / 7) + 1 + pageNumberBase}
                pageTotal={pageTotal}
              />
              <DeliveriesReportSummary
                reportedDate={props.reportedDate}
                timeFrame={props.timeFrame}
              />
              <Center style={{ backgroundColor: theme.colors.gray[4] }} mb={10}>
                <Text size="lg" weight={500} my={5}>
                  Incomplete Deliveries
                </Text>
              </Center>
              {item}
              <Box style={{ height: 190 * (7 - (incompleteDeliveries.data.length % 7)) }} />
            </Box>
          );
        } else {
          if (i === incompleteDeliveries.data.length - 1 && pendingDeliveries.data.length > 0) {
            return (
              <Box key={a.deliveryId} mt={-pageNumberBase}>
                <DeliveriesReportHeader
                  reportedDate={props.reportedDate}
                  timeFrame={props.timeFrame}
                  pageNumber={Math.floor(i / 7) + 1 + pageNumberBase}
                  pageTotal={pageTotal}
                />
                <DeliveriesReportSummary
                  reportedDate={props.reportedDate}
                  timeFrame={props.timeFrame}
                />
                <Center style={{ backgroundColor: theme.colors.gray[4] }} mb={10}>
                  <Text size="lg" weight={500} my={5}>
                    Incomplete Deliveries
                  </Text>
                </Center>
                {item}
                <Box style={{ height: 190 * (7 - (completedDeliveries.data.length % 7)) }} />
              </Box>
            );
          } else {
            return (
              <Box key={a.deliveryId} mt={-pageNumberBase}>
                <DeliveriesReportHeader
                  reportedDate={props.reportedDate}
                  timeFrame={props.timeFrame}
                  pageNumber={Math.floor(i / 7) + 1 + pageNumberBase}
                  pageTotal={pageTotal}
                />
                <DeliveriesReportSummary
                  reportedDate={props.reportedDate}
                  timeFrame={props.timeFrame}
                />
                <Center style={{ backgroundColor: theme.colors.gray[4] }} mb={10}>
                  <Text size="lg" weight={500} my={5}>
                    Incomplete Deliveries
                  </Text>
                </Center>
                {item}
              </Box>
            );
          }
        }
      } else if (
        i === incompleteDeliveries.data.length - 1 &&
        i % 7 > 0 &&
        incompleteDeliveries.data.length % 7 > 0
      ) {
        if (i === incompleteDeliveries.data.length - 1 && !(pendingDeliveries.data ?? [])[0]) {
          return <Box key={a.deliveryId}>{item}</Box>;
        } else {
          return (
            <Box key={a.deliveryId}>
              {item}
              <Box style={{ height: 190 * (7 - (incompleteDeliveries.data.length % 7)) }} />
            </Box>
          );
        }
      } else {
        return <Box key={a.deliveryId}>{item}</Box>;
      }
    }
  });

  const pendingDeliveryItems = (pendingDeliveries.data ?? []).map((a, i) => {
    if (completedDeliveries.data && incompleteDeliveries.data && pendingDeliveries.data) {
      let item = (
        <DeliveriesReportPendingItem
          orderId={a.orderId}
          standingOrderId={a.standingOrderId}
          patientFirstName={a.patientFirstName}
          patientLastName={a.patientLastName}
          patientAddress={a.deliveryAddress}
          patientCityName={a.deliveryCity}
          patientPhoneNumber={a.patientPhoneNumber}
          requestedDeliveryDate={dayjs(a.requestedDeliveryDate).format('YYYY-MM-DD')}
          requestedDeliveryTimeName={a.isAMRequestedDelivery ? 'AM' : 'PM'}
          isStandingOrder={a.isStandingOrder}
          isRescheduledStandingOrder={a.isStandingOrderReschedule}
          isLiquid={a.liquid}
          orderNotes={a.orderNotes}
        />
      );

      let pageNumberBase =
        (completedDeliveries.data.length % 7 > 0
          ? Math.floor(completedDeliveries.data.length / 7) + 1
          : Math.floor(completedDeliveries.data.length / 7)) +
        (incompleteDeliveries.data.length % 7 > 0
          ? Math.floor(incompleteDeliveries.data.length / 7) + 1
          : Math.floor(incompleteDeliveries.data.length / 7));

      let pageTotal =
        (completedDeliveries.data.length % 7 > 0
          ? Math.floor(completedDeliveries.data.length / 7) + 1
          : Math.floor(completedDeliveries.data.length / 7)) +
        (incompleteDeliveries.data.length % 7 > 0
          ? Math.floor(incompleteDeliveries.data.length / 7) + 1
          : Math.floor(incompleteDeliveries.data.length / 7)) +
        (pendingDeliveries.data.length % 7 > 0
          ? Math.floor(pendingDeliveries.data.length / 7) + 1
          : Math.floor(pendingDeliveries.data.length / 7));

      if (i % 7 === 0) {
        if (pendingDeliveries.data?.length === 1) {
          return (
            <Box
              key={`${a.orderId ?? ''}${a.standingOrderId ?? ''}${a.isStandingOrder ? 'S' : 'O'}`}
              mt={-pageNumberBase}
            >
              <DeliveriesReportHeader
                reportedDate={props.reportedDate}
                timeFrame={props.timeFrame}
                pageNumber={Math.floor(i / 7) + 1 + pageNumberBase}
                pageTotal={pageTotal}
              />
              <DeliveriesReportSummary
                reportedDate={props.reportedDate}
                timeFrame={props.timeFrame}
              />
              <Center style={{ backgroundColor: theme.colors.gray[4] }} mb={10}>
                <Text size="lg" weight={500} my={5}>
                  Pending Deliveries
                </Text>
              </Center>
              {item}
              <Box style={{ height: 190 * (7 - (pendingDeliveries.data.length % 7)) }} />
            </Box>
          );
        } else {
          return (
            <Box
              key={`${a.orderId ?? ''}${a.standingOrderId ?? ''}${a.isStandingOrder ? 'S' : 'O'}`}
              mt={-pageNumberBase}
            >
              <DeliveriesReportHeader
                reportedDate={props.reportedDate}
                timeFrame={props.timeFrame}
                pageNumber={Math.floor(i / 7) + 1 + pageNumberBase}
                pageTotal={pageTotal}
              />
              <DeliveriesReportSummary
                reportedDate={props.reportedDate}
                timeFrame={props.timeFrame}
              />
              <Center style={{ backgroundColor: theme.colors.gray[4] }} mb={10}>
                <Text size="lg" weight={500} my={5}>
                  Pending Deliveries
                </Text>
              </Center>
              {item}
            </Box>
          );
        }
      } else if (
        i === pendingDeliveries.data.length - 1 &&
        i % 7 > 0 &&
        pendingDeliveries.data.length % 7 > 0
      ) {
        if (i === pendingDeliveries.data.length - 1) {
          return (
            <Box
              key={`${a.orderId ?? ''}${a.standingOrderId ?? ''}${a.isStandingOrder ? 'S' : 'O'}`}
            >
              {item}
            </Box>
          );
        } else {
          return (
            <Box
              key={`${a.orderId ?? ''}${a.standingOrderId ?? ''}${a.isStandingOrder ? 'S' : 'O'}`}
            >
              {item}
              <Box style={{ height: 190 * (7 - (pendingDeliveries.data.length % 7)) }} />
            </Box>
          );
        }
      } else {
        return (
          <Box key={`${a.orderId ?? ''}${a.standingOrderId ?? ''}${a.isStandingOrder ? 'S' : 'O'}`}>
            {item}
          </Box>
        );
      }
    }
  });

  return (
    <Box className={classes.reportWrapper} mb={35}>
      <Box id="printArea">
        <Box className={classes.reportBody}>
          {completedDeliveryItems[0] && (
            <Stack spacing={0}>{completedDeliveryItems[0] && completedDeliveryItems}</Stack>
          )}
          {incompleteDeliveryItems[0] && (
            <Stack spacing={0}>{incompleteDeliveryItems[0] && incompleteDeliveryItems}</Stack>
          )}
          {pendingDeliveryItems[0] && (
            <Stack spacing={0}>{pendingDeliveryItems[0] && pendingDeliveryItems}</Stack>
          )}
          {!completedDeliveryItems[0] &&
            !incompleteDeliveryItems[0] &&
            !pendingDeliveryItems[0] && (
              <Stack spacing={0}>
                <DeliveriesReportHeader
                  reportedDate={props.reportedDate}
                  timeFrame={props.timeFrame}
                  pageNumber={1}
                  pageTotal={1}
                />
                <DeliveriesReportSummary
                  reportedDate={props.reportedDate}
                  timeFrame={props.timeFrame}
                />
                <Box style={{ height: 190 * 7 + 50 }} />
              </Stack>
            )}
        </Box>
      </Box>
    </Box>
  );
};

export default DeliveriesReport;
