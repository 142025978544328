import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type NewEquipmentRequest = {
  equipmentTypeId: string;
  name: string;
  notes: string;
  hasSerialNumber: boolean;
  lastUpdatedUserId: string;
};

type NewEquipmentResponse = {
  equipmentId: number;
};

function newEquipment(request: NewEquipmentRequest): Promise<NewEquipmentResponse> {
  return externalApi.post(`Equipment/new`, { json: request }).json();
}

function useNewEquipment() {
  const queryClient = useQueryClient();
  return useMutation((request: NewEquipmentRequest) => newEquipment(request), {
    onSuccess: () => {
      queryClient.invalidateQueries(['AllEquipment']);
    },
  });
}
export { useNewEquipment };
