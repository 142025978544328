import {
  ActionIcon,
  Box,
  Button,
  Group,
  Stack,
  Text,
  createStyles,
  useMantineTheme,
} from '@mantine/core';
import { Note, Plus, X } from 'tabler-icons-react';
import { IEquipment, ITrackedEquipment } from '../../../../models';
import { DeliveryNotesModal } from '../../../../components';
import { useEffect, useState } from 'react';

const useStyles = createStyles((theme) => ({
  equipmentBox: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    width: '100%',
    height: 45,
  },
}));

type TrackedEquipmentSelectCardProps = {
  index: number;
  equipmentId: string;
  equipmentName: string;
  equipmentTypeName: string;
  trackedEquipment?: ITrackedEquipment;
  equipment?: IEquipment;
  notes?: string;
  onClick: () => void;
  onRemoveTrackedEquipment: (trackedEquipmentId: string) => void;
  onRemoveUndelivered: (equipmentId: string) => void;
  onTrackedNotesSaved: (trackedEquipmentId: string, notes: string | undefined) => void;
  onUndeliveredNotesSaved: (index: number, notes: string | undefined) => void;
};

const TrackedEquipmentSelectCard = (props: TrackedEquipmentSelectCardProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();

  const [notesModalOpen, setNotesModalOpen] = useState<boolean>(false);
  const [notes, setNotes] = useState<string | undefined>(props.notes);

  useEffect(() => {
    if (notes && props.trackedEquipment)
      props.onTrackedNotesSaved(props.trackedEquipment?.trackedEquipmentId ?? '0', notes);
    if (notes && props.equipment) props.onUndeliveredNotesSaved(props.index ?? 0, notes);
  }, [notes]);

  return (
    <Stack align="stretch">
      {!props.trackedEquipment && !props.equipment && (
        <Button
          variant="outline"
          pl={theme.spacing.xs}
          style={{ borderStyle: 'dotted', height: 45 }}
          onClick={() => {
            props.onClick();
          }}
          styles={{ label: { width: '100%' } }}
        >
          <Group
            spacing={`calc(${theme.spacing.xs} / 2)`}
            position="left"
            style={{ width: '100%' }}
          >
            <Plus size={26} color={theme.colors.blue[4]} />
            <Stack spacing={0}>
              <Text size="sm" mb={-3} mt={-3} color={theme.colors.blue[4]} weight="lighter">
                {props.equipmentName}
              </Text>
              <Text
                size={`calc(${theme.fontSizes.xs} * 0.9)`}
                color={theme.colors.blue[4]}
                weight="lighter"
              >
                {props.equipmentTypeName}
              </Text>
            </Stack>
          </Group>
        </Button>
      )}
      {props.trackedEquipment && (
        <Box className={classes.equipmentBox}>
          <Group spacing={0} position="apart" style={{ width: '100%' }}>
            <Stack spacing={0}>
              <Text size="sm" mb={-3}>
                {props.trackedEquipment.equipmentName}
              </Text>
              <Text size={`calc(${theme.fontSizes.xs} * 0.9)`} color="dimmed" weight="lighter">
                {props.trackedEquipment.equipmentTypeName}
              </Text>
            </Stack>
            <Text size="sm" weight={500}>
              {props.trackedEquipment.serialNumber}
            </Text>
            <Group spacing={0}>
              <ActionIcon
                variant="transparent"
                onClick={() => {
                  setNotesModalOpen(true);
                }}
              >
                <Note color={theme.colors.gray[5]} />
              </ActionIcon>
              <ActionIcon
                variant="transparent"
                onClick={() => {
                  props.onRemoveTrackedEquipment(props.trackedEquipment?.trackedEquipmentId ?? '');
                }}
              >
                <X color={theme.colors.gray[5]} />
              </ActionIcon>
            </Group>
          </Group>
        </Box>
      )}
      {props.equipment && (
        <Box className={classes.equipmentBox} style={{ borderColor: theme.colors.red[6] }}>
          <Group spacing={0} position="apart" style={{ width: '100%' }}>
            <Stack spacing={0}>
              <Text size="sm" mb={-3} color={theme.colors.red[6]}>
                {props.equipment.name}
              </Text>
              <Text
                size={`calc(${theme.fontSizes.xs} * 0.9)`}
                color={theme.colors.red[6]}
                weight="lighter"
              >
                {props.equipment.equipmentTypeName}
              </Text>
            </Stack>
            <Text size="sm" weight={500} color={theme.colors.red[6]}>
              <i>Unable to Deliver</i>
            </Text>
            <Group spacing={0}>
              <ActionIcon
                variant="transparent"
                onClick={() => {
                  setNotesModalOpen(true);
                }}
              >
                <Note color={theme.colors.red[3]} />
              </ActionIcon>
              <ActionIcon
                variant="transparent"
                onClick={() => {
                  props.onRemoveUndelivered(props.equipment?.equipmentId ?? '');
                }}
              >
                <X color={theme.colors.red[3]} />
              </ActionIcon>
            </Group>
          </Group>
        </Box>
      )}
      <DeliveryNotesModal
        opened={notesModalOpen}
        header="Equipment Delivery Notes"
        currentNotes={notes ?? ''}
        onSave={(updatedNotes) => {
          setNotes(updatedNotes);
          setNotesModalOpen(false);
        }}
        onClose={() => {
          setNotesModalOpen(false);
        }}
      />
    </Stack>
  );
};

export default TrackedEquipmentSelectCard;
