import React from 'react';
import { useQuery } from 'react-query';
import externalApi from '../../api';
import { ITrackedPatientEquipment } from '../../models';

function fetchTrackedPatientEquipment(
  trackedPatientEquipmentId: string
): Promise<ITrackedPatientEquipment> {
  return externalApi.get(`TrackedPatientEquipment/${trackedPatientEquipmentId}`).json();
}

export function useTrackedPatientEquipment(trackedPatientEquipmentId: string) {
  return useQuery(['TrackedPatientEquipment'], () =>
    fetchTrackedPatientEquipment(trackedPatientEquipmentId)
  );
}
