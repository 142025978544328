import React from 'react';
import { useQuery } from 'react-query';
import externalApi from '../../api';

function fetchIsTechnician(): Promise<{ isTechnician: boolean }> {
  return externalApi.post(`User/IsTechnician`).json();
}

function useIsTechnician() {
  return useQuery(['IsTechnician'], () => fetchIsTechnician());
}
export { useIsTechnician };
