import React from 'react';
import { useQuery } from 'react-query';
import externalApi from '../../api';
import { IPatient } from '../../models';

function fetchPatients(): Promise<IPatient[]> {
  return externalApi.get(`Patient/all`).json();
}

export function usePatients() {
  return useQuery(['Patients'], () => fetchPatients());
}
