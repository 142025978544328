import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type NewFundingTypeRequest = {
  name: string;
};

type NewFundingTypeResponse = {
  fundingTypeId: number;
};

function newFundingType(request: NewFundingTypeRequest): Promise<NewFundingTypeResponse> {
  return externalApi.post(`FundingType/new`, { json: request }).json();
}

function useNewFundingType() {
  const queryClient = useQueryClient();
  return useMutation((request: NewFundingTypeRequest) => newFundingType(request), {
    onSuccess: () => {
      queryClient.invalidateQueries(['FundingTypes']);
    },
  });
}
export { useNewFundingType };
