import { useQuery } from 'react-query';
import externalApi from '../../api';
import { IUserType } from '../../models';

function fetchUserTypes(): Promise<IUserType[]> {
  return externalApi.get(`User/Types`).json();
}

export function useUserTypes() {
  return useQuery(['UserTypes'], () => fetchUserTypes());
}
