import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type EditUndeliveredEquipmentNotesRequest = {
  undeliveredEquipmentId: string;
  notes: string;
  lastUpdatedUserId: string;
};

type EditUndeliveredEquipmentNotesResponse = {
  undeliveredEquipmentId: number;
};

function editUndeliveredEquipmentNotes(
  request: EditUndeliveredEquipmentNotesRequest
): Promise<EditUndeliveredEquipmentNotesResponse> {
  return externalApi.patch(`UndeliveredEquipment/patch/notes`, { json: request }).json();
}

function useEditUndeliveredEquipmentNotes() {
  const queryClient = useQueryClient();
  return useMutation(
    (request: EditUndeliveredEquipmentNotesRequest) => editUndeliveredEquipmentNotes(request),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['UndeliveredEquipmentBy']);
      },
    }
  );
}
export { useEditUndeliveredEquipmentNotes };
