import {
  ActionIcon,
  Box,
  createStyles,
  Divider,
  Group,
  Stack,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React, { useEffect, useState } from 'react';
import { Edit, DeviceFloppy, X } from 'tabler-icons-react';
import { ITrackedEquipment } from '../../../models';
import { useEditTrackedEquipmentHours, useUserId } from '../../../hooks';
import NumberFormat from 'react-number-format';

const useStyles = createStyles((theme) => ({
  notesCard: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    paddingLeft: theme.spacing.sm,
    paddingRight: theme.spacing.sm,
    paddingTop: 3,
    width: '100%',
    height: '125px',
  },
  infoBox: {
    color: `${theme.colors.gray[6]}`,
    border: `1px solid ${theme.colors.gray[6]}`,
    borderRadius: theme.radius.lg,
    width: 'fit-content',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  groupLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.sm,
    whiteSpace: 'pre',
  },
  editGroupLabel: {
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,
    whiteSpace: 'pre',
  },
  fieldLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
  },
  editFieldLabel: {
    fontSize: theme.fontSizes.xs,
    fontWeight: 500,
    whiteSpace: 'pre',
  },
  detail: {
    fontSize: theme.fontSizes.md,
    paddingLeft: 10,
  },
  fieldGroup: {
    width: '48%',
  },
  clickableIcon: {
    color: `${theme.colors.blue[6]}`,
    padding: '0 !important',
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

type TrackedEquipmentHourSectionProps = {
  trackedEquipment: ITrackedEquipment;
  onEdited: () => void;
  onError: () => void;
};

const TrackedEquipmentHourSection = (props: TrackedEquipmentHourSectionProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const currentUserId = useUserId();

  const editTrackedEquipmentHours = useEditTrackedEquipmentHours();

  const [isEditing, setIsEditing] = useState<boolean>(false);

  // display values
  const [onHours, setOnHours] = useState<number | undefined>(props.trackedEquipment.onHours);
  const [offHours, setOffHours] = useState<number | undefined>(props.trackedEquipment.offHours);

  // controlled editing values
  const [editOnHours, setEditOnHours] = useState<number | undefined>(
    props.trackedEquipment.onHours
  );
  const [editOffHours, setEditOffHours] = useState<number | undefined>(
    props.trackedEquipment.offHours
  );

  function SaveChanges() {
    editTrackedEquipmentHours.mutate({
      trackedEquipmentId: props.trackedEquipment.trackedEquipmentId,
      onHours: editOnHours ?? 0,
      offHours: editOffHours ?? 0,
      lastUpdatedUserId: currentUserId.data!.userId,
    });
  }

  useEffect(() => {
    if (editTrackedEquipmentHours.isSuccess) {
      setOnHours(editOnHours ?? 0);
      setOffHours(editOffHours ?? 0);

      props.onEdited();
    }
    if (editTrackedEquipmentHours.isError) {
      props.onError();
    }
  }, [editTrackedEquipmentHours.status]);

  return (
    <Box>
      {!isEditing && (
        <Stack spacing={0}>
          <Group spacing={0} position="apart">
            <Text className={classes.groupLabel}>Hours Active</Text>
            {!props.trackedEquipment.retired && (
              <ActionIcon
                variant="transparent"
                className={classes.clickableIcon}
                onClick={() => {
                  setEditOnHours(onHours);
                  setEditOffHours(offHours);
                  setIsEditing(true);
                }}
              >
                <Edit style={{ marginBottom: 4 }} />
              </ActionIcon>
            )}
          </Group>
          <Divider mb={`calc(${theme.spacing.xs} / 2)`} />
          <Group spacing={0} ml={theme.spacing.md}>
            <Stack spacing={0} style={{ width: '50%' }}>
              <Text className={classes.fieldLabel}>On Hours</Text>
              <Text className={classes.detail}>{onHours}</Text>
            </Stack>
            <Stack spacing={0}>
              <Text className={classes.fieldLabel}>Off Hours</Text>
              <Text className={classes.detail}>{offHours}</Text>
            </Stack>
          </Group>
        </Stack>
      )}
      {isEditing && (
        <Stack spacing={0}>
          <Group spacing={0} position="apart">
            <Text className={classes.editGroupLabel}>Hours Active</Text>
            <Group spacing={0}>
              <ActionIcon
                variant="transparent"
                className={classes.clickableIcon}
                onClick={() => {
                  setIsEditing(false);
                  SaveChanges();
                }}
              >
                <DeviceFloppy style={{ marginBottom: 4 }} />
              </ActionIcon>
              <ActionIcon
                variant="transparent"
                className={classes.clickableIcon}
                onClick={() => {
                  setIsEditing(false);
                }}
              >
                <X style={{ marginBottom: 4 }} />
              </ActionIcon>
            </Group>
          </Group>
          <Divider mb={`calc(${theme.spacing.xs} / 2)`} />
          <Group spacing={5} ml={theme.spacing.md} noWrap>
            <Stack spacing={0}>
              <Text className={classes.editFieldLabel}>On Hours</Text>
              <NumberFormat
                allowEmptyFormatting
                customInput={TextInput}
                decimalScale={0}
                autoComplete="nope"
                value={editOnHours}
                onValueChange={(a) => {
                  setEditOnHours(a.floatValue);
                }}
              />
            </Stack>
            <Stack spacing={0}>
              <Text className={classes.editFieldLabel}>Off Hours</Text>
              <NumberFormat
                allowEmptyFormatting
                customInput={TextInput}
                decimalScale={0}
                autoComplete="nope"
                value={editOffHours}
                onValueChange={(a) => {
                  setEditOffHours(a.floatValue);
                }}
              />
            </Stack>
          </Group>
        </Stack>
      )}
    </Box>
  );
};

export default TrackedEquipmentHourSection;
