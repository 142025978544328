import ky from 'ky';
import { acquireAccessToken, msalInstance } from '../common/AzureAD';

const externalApi = ky.create({ prefixUrl: `${process.env.REACT_APP_API}` }).extend({
  hooks: {
    beforeRequest: [
      async (request) => {
        const token = await acquireAccessToken(msalInstance, request.url); // Pass URL request.URL
        request.headers.set('Authorization', `Bearer ${token}`);
      },
    ],
  },
});

export default externalApi;