import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import externalApi from '../../api';
import { IStandingOrderCancellation } from '../../models';

function fetchStandingOrderCancellations(
  standingOrderId: string
): Promise<IStandingOrderCancellation[]> {
  return externalApi
    .get(`StandingOrderCancellation/CancellationsByOrder/${standingOrderId}`)
    .json();
}

export function useStandingOrderCancellations(standingOrderId: string) {
  return useQuery(['StandingOrderCancellationsByStandingOrder'], () =>
    fetchStandingOrderCancellations(standingOrderId)
  );
}
