import React from 'react';
import { useMutation, useQuery } from 'react-query';
import externalApi from '../../api';

type TrackedStandingOrderEquipmentResponse = {
  equipmentId: string;
  equipmentName: string;
  equipmentTypeName: string;
};

function fetchTrackedStandingOrderEquipmentByStandingOrder(
  standingOrderId: string
): Promise<TrackedStandingOrderEquipmentResponse[]> {
  return externalApi
    .get(`StandingOrderEquipment/Tracked/ByStandingOrder/${standingOrderId}`)
    .json();
}

export function useTrackedStandingOrderEquipmentByStandingOrder() {
  return useMutation(
    (standingOrderId: string) => fetchTrackedStandingOrderEquipmentByStandingOrder(standingOrderId),
    {
      onSuccess: () => {},
    }
  );
}
