import {
  ActionIcon,
  Box,
  Button,
  Center,
  Checkbox,
  createStyles,
  Group,
  Menu,
  Modal,
  Select,
  Stack,
  Text,
  Textarea,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { useEffect, useState } from 'react';
import { Dots, Pencil, Trash, X } from 'tabler-icons-react';
import { useMediaQuery } from '@mantine/hooks';
import { IEquipment } from '../../../models';
import { useDeleteEquipment, useEditEquipment, useEquipmentTypes, useUserId } from '../../../hooks';
import DialogModal from '../DialogModal';

const useStyles = createStyles((theme) => ({
  label: {
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
    fontWeight: 500,
  },
  fieldGroup: {
    width: '48%',
  },
  detailLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
  },
  detail: {
    fontSize: theme.fontSizes.md,
    paddingLeft: 10,
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

type EquipmentEditModalProps = {
  equipment: IEquipment;
  opened: boolean;
  onClose: () => void;
  onEdited: (isUpdated: boolean, isError: boolean, initialEquipment: IEquipment) => void;
  onDeleted: () => void;
};

const EquipmentEditModal = (props: EquipmentEditModalProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const initialEquipment = {
    equipmentId: props.equipment.equipmentId,
    equipmentTypeId: props.equipment.equipmentTypeId,
    equipmentTypeName: props.equipment.equipmentTypeName,
    name: props.equipment.name,
    notes: props.equipment.notes,
    hasSerialNumber: props.equipment.hasSerialNumber,
    lastUpdatedUserId: props.equipment.lastUpdatedUserId,
    lastUpdatedDateTime: props.equipment.lastUpdatedDateTime,
  };

  const currentUserId = useUserId();

  const [submitClicked, setSubmitClicked] = useState<boolean>(false);

  const editEquipment = useEditEquipment();
  const equipmentTypes = useEquipmentTypes();

  const deleteEquipment = useDeleteEquipment();

  const handleDelete = () => {
    deleteEquipment.mutate({ equipmentId: props.equipment.equipmentId });
  };

  const editForm = useForm<IEquipment>({
    initialValues: initialEquipment,
    validate: {
      name: (a) => (a.length > 50 ? 'Equipment Name must be a maximum of 50 characters' : null),
    },
  });

  async function submitEquipmentUpdate() {
    setSubmitClicked(true);

    editEquipment.mutate({
      equipmentId: editForm.values.equipmentId,
      equipmentTypeId: editForm.values.equipmentTypeId,
      name: editForm.values.name,
      notes: editForm.values.notes,
      hasSerialNumber: editForm.values.hasSerialNumber,
      lastUpdatedUserId: currentUserId.data!.userId,
    });
  }

  useEffect(() => {
    editForm.setValues(props.equipment);
  }, [props.equipment, props.opened]);

  useEffect(() => {
    if (submitClicked) {
      if (editEquipment.isSuccess) {
        setSubmitClicked(false);
        setIsError(false);
        setErrorMessage('');
        props.onEdited(true, false, {
          equipmentId: props.equipment.equipmentId,
          equipmentTypeId: editForm.values.equipmentTypeId,
          equipmentTypeName: equipmentTypes.data!.find(
            (a) => a.equipmentTypeId.toString() === editForm.values.equipmentTypeId.toString()
          )!.name,
          name: editForm.values.name,
          notes: editForm.values.notes,
          hasSerialNumber: editForm.values.hasSerialNumber,
          lastUpdatedUserId: currentUserId.data!.userId,
          lastUpdatedDateTime: new Date(),
        });
      } else if (editEquipment.isError) {
        setSubmitClicked(false);
        setIsError(false);
        setErrorMessage('');
        props.onEdited(false, true, initialEquipment);
      }
    }
  }, [editEquipment.status]);

  useEffect(() => {
    if (deleteEquipment.isSuccess) {
      setIsError(false);
      setErrorMessage('');
      props.onDeleted();
    }
    if (deleteEquipment.isError) {
      setIsError(true);
      setErrorMessage('Something went wrong, Equipment not Deleted. Please try again');
    }
  }, [deleteEquipment.status]);

  return (
    <Modal
      title={isError ? <Text color="red">{errorMessage}</Text> : ''}
      opened={props.opened}
      size={isSmallMobile ? 'xs' : 'md'}
      onClose={() => {
        setIsError(false);
        setErrorMessage('');
        props.onClose();
      }}
    >
      <Box>
        <Center>
          <Group noWrap spacing={!isSmallMobile ? 'md' : 0} ml={25}>
            <Text size="xl" weight={500} align="center">
              Edit Equipment Information
            </Text>
            <Menu>
              <Menu.Target>
                <ActionIcon>
                  <Dots />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item
                  icon={<Trash color={`${theme.colors.red[6]}`} />}
                  onClick={() => {
                    setDeleteModalOpen(true);
                  }}
                >
                  Delete Equipment
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>
        </Center>
        <form onSubmit={editForm.onSubmit(() => submitEquipmentUpdate())} autoComplete="off">
          <Stack align="stretch" style={{ padding: 5 }}>
            <Stack align="stretch" spacing={0}>
              <Text className={classes.label}>Equipment Name</Text>
              <TextInput
                placeholder="Equipment Name"
                required
                {...editForm.getInputProps('name')}
              />
            </Stack>

            <Stack align="stretch" spacing={0}>
              <Text className={classes.label}>Category</Text>
              <Select
                placeholder="Select a Category"
                data={
                  equipmentTypes.data?.map((a) => ({
                    value: `${a.equipmentTypeId}`,
                    label: `${a.name}`,
                  })) ?? []
                }
                defaultValue={props.equipment.equipmentTypeId.toString()}
                searchable={!isSmallMobile}
                required
                {...editForm.getInputProps('equipmentTypeId')}
              />
            </Stack>

            <Stack align="stretch" spacing={0}>
              <Text className={classes.label}>Notes</Text>
              <Textarea minRows={4} {...editForm.getInputProps('notes')} />
            </Stack>

            <Group noWrap spacing={10}>
              <Checkbox
                checked={editForm.values.hasSerialNumber}
                onChange={(e) => {
                  editForm.setFieldValue('hasSerialNumber', !editForm.values.hasSerialNumber);
                }}
              />
              <Text className={classes.label}>
                Does This Equipment have a Tracked Serial Number?
              </Text>
            </Group>

            <Group position="apart" grow noWrap mt="xs">
              <Button variant="outline" type="submit" px={10}>
                <Pencil style={{ marginRight: 5 }} />{' '}
                <Text className={classes.buttonText}>{`Update ${
                  !isSmallMobile ? 'Equipment' : ''
                }`}</Text>
              </Button>
              <Button
                variant="outline"
                color="red"
                onClick={() => {
                  setIsError(false);
                  setErrorMessage('');
                  props.onClose();
                }}
              >
                <X style={{ marginRight: 5 }} /> <Text className={classes.buttonText}>Cancel</Text>
              </Button>
            </Group>
          </Stack>
        </form>
      </Box>
      <DialogModal
        isOpen={deleteModalOpen}
        isNegative
        titleText="Delete Equipment?"
        body={
          <Text align="center" color="dimmed" size="lg" mb="xs">
            Are you sure you want to delete this Equipment?
          </Text>
        }
        confirmText="Delete Equipment"
        onConfirm={() => {
          setDeleteModalOpen(false);
          handleDelete();
        }}
        onClose={() => {
          setDeleteModalOpen(false);
        }}
      />
    </Modal>
  );
};

export default EquipmentEditModal;
