import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type NewOrderRequest = {
  patientId: string;
  isAMDelivery: boolean;
  requestedDeliveryDate: Date;
  liquid: boolean;
  notes: string;
  isUrgent: boolean;
  lastUpdatedUserId: string;
};

type NewOrderResponse = {
  orderId: number;
};

function newOrder(request: NewOrderRequest): Promise<NewOrderResponse> {
  return externalApi.post(`Order/new`, { json: request }).json();
}

function useNewOrder() {
  const queryClient = useQueryClient();
  return useMutation((request: NewOrderRequest) => newOrder(request), {
    onSuccess: () => {
      queryClient.invalidateQueries(['Orders']);
    },
  });
}
export { useNewOrder };
