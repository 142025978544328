import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type DeleteStandingOrderRequest = {
  standingOrderId: string;
};

type DeleteStandingOrderResponse = {
  standingOrderId: number;
};

function deleteStandingOrder(
  request: DeleteStandingOrderRequest
): Promise<DeleteStandingOrderResponse> {
  return externalApi.delete(`StandingOrder/delete/${request.standingOrderId}`).json();
}

function useDeleteStandingOrder() {
  const queryClient = useQueryClient();
  return useMutation((request: DeleteStandingOrderRequest) => deleteStandingOrder(request), {
    onSuccess: () => {
      queryClient.invalidateQueries(['StandingOrdersByWeekday']);
    },
  });
}
export { useDeleteStandingOrder };
