import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type NewGeneralUserRequest = {
  userFirstName: string;
  userLastName: string;
  userEmailAddress: string;
};

type NewGeneralUserResponse = {
  userId: number;
};

function newUser(request: NewGeneralUserRequest): Promise<NewGeneralUserResponse> {
  return externalApi.post(`User/new/General`, { json: request }).json();
}

function useNewGeneralUser() {
  const queryClient = useQueryClient();
  return useMutation((request: NewGeneralUserRequest) => newUser(request), {
    onSuccess: () => {
      queryClient.invalidateQueries(['Users']);
    },
  });
}
export { useNewGeneralUser };
