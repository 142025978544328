import React from 'react';
import { useMutation } from 'react-query';
import externalApi from '../../api';

type TrackedOrderEquipmentResponse = {
  equipmentId: string;
  equipmentName: string;
  equipmentTypeName: string;
};

function fetchTrackedOrderEquipmentByOrder(
  orderId: string
): Promise<TrackedOrderEquipmentResponse[]> {
  return externalApi.get(`OrderEquipment/Tracked/ByOrder/${orderId}`).json();
}

export function useTrackedOrderEquipmentByOrder() {
  return useMutation((orderId: string) => fetchTrackedOrderEquipmentByOrder(orderId), {
    onSuccess: () => {},
  });
}
