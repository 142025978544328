import React from 'react';
import { useQuery } from 'react-query';
import externalApi from '../../api';
import { IOrderEquipment } from '../../models';

function fetchOrderEquipmentByOrder(orderId: string): Promise<IOrderEquipment[]> {
  return externalApi.get(`OrderEquipment/ByOrder/${orderId}`).json();
}

export function useOrderEquipmentByOrder(orderId: string) {
  return useQuery(['OrderEquipmentByOrder'], () => fetchOrderEquipmentByOrder(orderId));
}
