import React from 'react';
import { useQuery } from 'react-query';
import externalApi from '../../api';
import { ITrackedEquipmentService } from '../../models';
import { useIsAdmin, useIsTechnician } from '..';

function fetchTrackedEquipmentServiceByTrackedEquipment(
  trackedEquipmentId: string
): Promise<ITrackedEquipmentService[]> {
  return externalApi.get(`TrackedEquipmentService/ByTrackedEquipment/${trackedEquipmentId}`).json();
}

export function useTrackedEquipmentServiceByTrackedEquipment(trackedEquipmentId: string) {
  const isAdmin = useIsAdmin().data?.isAdmin;
  const isTechnician = useIsTechnician().data?.isTechnician;

  return useQuery(
    ['TrackedEquipmentServiceByTrackedEquipment'],
    () => fetchTrackedEquipmentServiceByTrackedEquipment(trackedEquipmentId),
    {
      enabled: isTechnician || isAdmin,
    }
  );
}
