import { createStyles, Stack, Text, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React from 'react';
import { HelpBox } from '../../../components';

const useStyles = createStyles((theme, _params) => ({
  card: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.md,
    padding: `${theme.spacing.sm} ${theme.spacing.lg}`,
  },
}));

type Props = {};

const OrdersHelpSubPage = (props: Props) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  return (
    <Stack align="stretch" p={25}>
      <Text color="dimmed" weight={500} size="lg" mb={-10}>
        On-Demand Orders
      </Text>
      <HelpBox title="Selecting On-Demand Orders">
        <Text style={{ whiteSpace: 'nowrap' }}>
          To view and manage on-demand orders, ensure that the "On-Demand Orders" button at the top
          of the page is selected.
        </Text>
      </HelpBox>
      <HelpBox title="Order Filters">
        <Text style={{ whiteSpace: 'nowrap' }}>
          Selecting options from the "Filters" selector will filter the order records on the table
          to match selected criteria.
        </Text>
      </HelpBox>
      <HelpBox title="Table Naviagtion">
        <Text style={{ whiteSpace: 'nowrap' }}>
          There are several ways to find the records you're looking for in the patients table.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          By default the table is sorted by "Requested Delivery Date", descending (largest to
          smallest, Z to A, 9 to 0), meaning that the orders with the latest requested dates will
          come first.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          (2022-01-02 would be above 2022-01-01, and 2023-01-01 would be above them both).
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          You can change the table sort by clicking on any of the column headers.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          When a column header has two arrows, one pointing up and one pointing down, it is not
          currently the column records are being sorted by.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          When a column header contains one arrow and it's pointing down, records are being sorted
          by that column, ascending (smallest to largest, A to Z, 0 to 9).
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          When a column header contains one arrow and it's pointing up, records are being sorted by
          that column, descending (largest to smallest, Z to A, 9 to 0).
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          You can also use the search bar above the table to search for a record that contains your
          search term in any of it's visible columns.
        </Text>
      </HelpBox>
      <HelpBox title="Veiwing Order Details">
        <Text style={{ whiteSpace: 'nowrap' }}>
          To view order details, click on the row of the table containing the record you wish to
          view the details of.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Clicking the "i" button next to the title of the "Complete Order Information" screen will
          display the last person to edit this record, and when it was edited.
        </Text>
      </HelpBox>
      <HelpBox title="Add a New Order">
        <Text style={{ whiteSpace: 'nowrap' }}>
          Clicking the "Add a New Order" button below the table will bring up a form allowing you to
          create a new order record.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Fill out each of the fields as required and click "Create Order".
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          In addition to more standard inputs there is an input to select the patient requesting the
          order.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Click the "Select a Patient" space on the "New Order Information" screen, and it will open
          the "Filter Patients" screen.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Enter any details of the order you wish to create the order for and click "Filter
          Patients". This will provide a list of all patients matching the provided details.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Click on the patient you wish to create the order for and you will be returned to the "New
          Order Information" screen with a patient now visibly selected.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          To instead select another patient, click the "x" button on the right of the patient card
          (clearing the selected patient), and click the "Select a Patient" button again.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          If you've missed any required fields you should recieve a prompt to include them. After
          filling them in, try clicking "Create Order" again.
        </Text>
      </HelpBox>
      <HelpBox title="Edit an Order">
        <Text style={{ whiteSpace: 'nowrap' }}>
          If an order is not marked as fulfilled (meaning it has been delivered), you can edit it.
          If an order is fulfilled it can only be viewed, not edited.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          To edit order information, click on the "Edit Order" button on the "Complete Order
          Information" screen.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          This will bring up a form containing the details of the order you've selected to edit.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          You cannot change the patient the order is for, only the details of the order itself.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Change the values as needed, then click "Update Order".
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          If you've missed any required fields you should recieve a prompt to include them. After
          filling them in, try clicking "Update Order" again.
        </Text>
      </HelpBox>
      <HelpBox title="Managing Order Equipment">
        <Text style={{ whiteSpace: 'nowrap' }}>
          To view and manage the equipment assigned to an order, click the "Order Equipment" button
          on the "Complete Order Information" screen.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          This will bring up a list of all equipment that has been assigned as part of the selected
          order.
        </Text>
      </HelpBox>
      <HelpBox title="Adding Order Equipment">
        <Text style={{ whiteSpace: 'nowrap' }}>
          To add equipment to a order, select the equipment from the dropdown menu below the
          equipment list. Then click "Add Equipment".
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          You will then be prompted to enter either a quantity.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          When you are satisfied with your input (quantity), click the "+" button on the top-right
          of the input card.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          If you do not wish to add the equipment, click the "x" button on the bottom-right of the
          input card.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Upon clicking the "+" button, your new equipment will be added.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          If it is the first time this equipment is appearing on this order's inventory list, a new
          card will be created at the bottom of the list.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          If the equipment was previously on the list, the new equipment will instead be added to
          the quantity of the existing record.
        </Text>
      </HelpBox>
      <HelpBox title="Editing Order Equipment">
        <Text style={{ whiteSpace: 'nowrap' }}>
          To edit equipment for an order, click the "..." button on the top-right of the card
          containing the equipment you wish to edit. This will open a menu with three options.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          You can then select to edit the quantity of the equipment.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          You can also delete the equipment if equipment was added to the order in error.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Clicking the "i" button on the bottom-right of any equipment card will display the last
          person to edit this record, and when it was edited.
        </Text>
      </HelpBox>
      <HelpBox title="Deleting Order Equipment">
        <Text style={{ whiteSpace: 'nowrap' }}>
          To delete and order, click the "..." button next to the title of the "Edit Order
          Information" screen. This will open a menu with one option.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Click the "Delete Order" option and then "Delete Order" on the popup to delete the Order.
        </Text>
      </HelpBox>
      <HelpBox title="Order Fulfillment">
        <Text style={{ whiteSpace: 'nowrap' }}>
          When a pending order or an order marked as undelivered on the "Delivery Schedule" page is
          delivered, the corresponding on-demand order record will be marked as fulfilled.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          This removes the ability to edit the order or associated equipment, but is still available
          for viewing.
        </Text>
      </HelpBox>
      <Text color="dimmed" weight={500} size="lg" mb={-10}>
        Standing Orders
      </Text>
      <HelpBox title="Selecting Standing Orders">
        <Text style={{ whiteSpace: 'nowrap' }}>
          To view and manage standing orders, ensure that the "Standing Orders" button at the top of
          the page is selected.
        </Text>
      </HelpBox>
      <HelpBox title="Weekday Filter">
        <Text style={{ whiteSpace: 'nowrap' }}>
          Standing orders are scheduled by weekday. To view the standing orders for only a specific
          weekday, select one of the weekdays from the "Weekday" selector.
        </Text>
      </HelpBox>
      <HelpBox title="Table Navigation">
        <Text style={{ whiteSpace: 'nowrap' }}>
          There are several ways to find the records you're looking for in the patients table.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          By default the table is sorted by the most recently updated records first.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          You can change the table sort by clicking on any of the column headers.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          When a column header has two arrows, one pointing up and one pointing down, it is not
          currently the column records are being sorted by.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          When a column header contains one arrow and it's pointing down, records are being sorted
          by that column, ascending (smallest to largest, A to Z, 0 to 9).
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          When a column header contains one arrow and it's pointing up, records are being sorted by
          that column, descending (largest to smallest, Z to A, 9 to 0).
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          You can also use the search bar above the table to search for a record that contains your
          search term in any of it's visible columns.
        </Text>
      </HelpBox>
      <HelpBox title="Viewing Standing Order Details">
        <Text style={{ whiteSpace: 'nowrap' }}>
          To view standing order details, click on the row of the table containing the record you
          wish to view the details of.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Clicking the "i" button next to the title of the "Complete Standing Order Information"
          screen will display the last person to edit this record, and when it was edited.
        </Text>
      </HelpBox>
      <HelpBox title="Add a New Standing Order">
        <Text style={{ whiteSpace: 'nowrap' }}>
          Clicking the "Add a New Standing Order" button below the table will bring up a form
          allowing you to create a new standing order record.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Fill out each of the fields as required and click "Create Order".
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          In addition to more standard inputs there is an input to select the patient requesting the
          standing order.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Click the "Select a Patient" space on the "New Standing Order Information" screen, and it
          will open the "Filter Patients" screen.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Enter any details of the order you wish to create the order for and click "Filter
          Patients". This will provide a list of all patients matching the provided details.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Click on the patient you wish to create the order for and you will be returned to the "New
          Order Information" screen with a patient now visibly selected.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          To instead select another patient, click the "x" button on the right of the patient card
          (clearing the selected patient), and click the "Select a Patient" button again.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          If you've missed any required fields you should recieve a prompt to include them. After
          filling them in, try clicking "Create Order" again.
        </Text>
      </HelpBox>
      <HelpBox title="Edit a Standing Order">
        <Text style={{ whiteSpace: 'nowrap' }}>
          To edit order information, click on the "Edit Order" button on the "Complete Standing
          Order Information" screen.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          This will bring up a form containing the details of the standing order you've selected to
          edit.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          You cannot change the patient the standing order is for, only the details of the order
          itself.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Change the values as needed, then click "Update Order".
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          If you've missed any required fields you should recieve a prompt to include them. After
          filling them in, try clicking "Update Order" again.
        </Text>
      </HelpBox>
      <HelpBox title="Managing Standing Order Equipment">
        <Text style={{ whiteSpace: 'nowrap' }}>
          To view and manage the equipment assigned to a standing order, click the "Order Equipment"
          button on the "Complete Standing Order Information" screen.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          This will bring up a list of all equipment that has been assigned as part of the selected
          standing order.
        </Text>
      </HelpBox>
      <HelpBox title="Adding Standing Order Equipment">
        <Text style={{ whiteSpace: 'nowrap' }}>
          To add equipment to a standing order, select the equipment from the dropdown menu below
          the equipment list. Then click "Add Equipment".
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          You will then be prompted to enter either a quantity.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          When you are satisfied with your input (quantity), click the "+" button on the top-right
          of the input card.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          If you do not wish to add the equipment, click the "x" button on the bottom-right of the
          input card.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Upon clicking the "+" button, your new equipment will be added.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          If it is the first time this equipment is appearing on this standing order's inventory
          list, a new card will be created at the bottom of the list.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          If the equipment was previously on the list, the new equipment will instead be added to
          the quantity of the existing record.
        </Text>
      </HelpBox>
      <HelpBox title="Standing Order Flexible Scheduling">
        <Text style={{ whiteSpace: 'nowrap' }}>
          Standing orders can be rescheduled to another day in the week or suspended for a given
          duration.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          To access this functionality, click on the "calendar" button to the right of the
          "Rescheduled" and "Suspended" counters.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Clicking the "calendar" button will open the "Standing Order Schedule Changes" screen.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          This screen displays information on the selected standing order, as well as to tables
          containing the recheduled orders and suspensions associated with this standing order.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          These tables are filterable similarly to elsewhere in the application.
        </Text>
      </HelpBox>
      <HelpBox title="Rescheduled and Suspended Count">
        <Text style={{ whiteSpace: 'nowrap' }}>
          The counters for "Rescheduled" and "Suspended" on the "Complete Standing Order
          Information" screen indicate the number of pending/undelivered reschedulings and
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          any ongoing or upcoming suspension windows for this standing order, respectively.
        </Text>
      </HelpBox>
      <HelpBox title="Rescheduling a Standing Order">
        <Text style={{ whiteSpace: 'nowrap' }}>
          To reschedule a standing order, click the "Reschedule Order" button on the "Standing Order
          Schedule Changes" screen.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          This will open an on-demand order creation form with the patient information pre-filled.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Fill out each of the fields as required and click "Reschedule".
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Equipment assigned to the stanfding order will be converted to the new on-demand order.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          For the week that this new on-demand order is scheduled, it will supersede the standing
          order it was created from.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Meaning that if the standing order being rescheduled is scheduled for Mondays, and the new
          on-demand order is scheduled for Wednesday, September 28th, 2022,
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          then the instance of the standing order that would normally be appear on the schedule for
          Monday, September 26th, 2022, will no longer appear on the schedule.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          On-demand orders will only supersede standing orders in this fashion if created using the
          rescheduling option as described here.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Otherwise they will coexist with standing orders normally, even if sharing a patient.
        </Text>
      </HelpBox>
      <HelpBox title="Editing a Rescheduled Standing Order">
        <Text style={{ whiteSpace: 'nowrap' }}>
          You can either find the record among the on-demand orders on the on-demand orders page, or
          you ca find them on the
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          "Standing Order Schedule Changes" on the table titled "Rescheduled Orders".
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          To edit the details of these rescheduled orders, click the record in either table.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          This will bring up a form containing the details of the rescheduled order you've selected
          to edit.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          To manage the equipment assigned to the rescheduled order, this must be done through the
          on-demand orders page (see the help section for "On-Demand Orders" for more information).
        </Text>
      </HelpBox>
      <HelpBox title="Suspending a Standing Order">
        <Text style={{ whiteSpace: 'nowrap' }}>
          To suspend a standing order, click the "Suspend Order" button on the "Standing Order
          Schedule Changes" screen.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          This will open a calendar, on which you can select the duration you'd like to suspend the
          standing order for.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          The default suspension range is the next 7 days.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          To select a new range, click two dates on the calendar and click the "Suspend Duration"
          button.
        </Text>
      </HelpBox>
      <HelpBox title="Editing a Standing Order Suspension">
        <Text style={{ whiteSpace: 'nowrap' }}>
          To edit a standing order suspension, click on the record you wish to edit on the "Standing
          Order Schedule Changes" screen on the table titled "Order Suspensions".
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          This will open a caldenar with the suspended duration selected.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Select the start and end dates of your new suspension range, then click "Update".
        </Text>
      </HelpBox>
    </Stack>
  );
};

export default OrdersHelpSubPage;
