import React from 'react';
import { useQuery } from 'react-query';
import externalApi from '../../../api';

type ReportDeliveredByDateRequest = {
  date: Date;
  isAMTimeframe: boolean;
  isFullDayTimeframe: boolean;
};

type ReportDeliveredByDateResponse = {
  deliveryId: string;
  orderId: string;
  standingOrderId: string;
  deliveredAt: Date;
  requestedDeliveryDate: Date;
  isAMRequestedDelivery: boolean;
  patientFirstName: string;
  patientLastName: string;
  deliveryAddress: string;
  deliveryCity: string;
  patientPhoneNumber: string;
  isStandingOrder: boolean;
  isStandingOrderReschedule: boolean;
  liquid: boolean;
  deliveryNotes: string;
  orderNotes: string;
};

function reportReportDeliveredByDate(
  request: ReportDeliveredByDateRequest
): Promise<ReportDeliveredByDateResponse[]> {
  return externalApi.post(`Delivery/Report/ByDate/Delivered`, { json: request }).json();
}

function useReportDeliveredByDate(request: ReportDeliveredByDateRequest) {
  return useQuery(['ReportDeliveredByDate'], () => reportReportDeliveredByDate(request));
}
export { useReportDeliveredByDate };
