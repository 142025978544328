import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type EditEquipmentRequest = {
  equipmentId: string;
  equipmentTypeId: string;
  name: string;
  notes: string;
  hasSerialNumber: boolean;
  lastUpdatedUserId: string;
};

type EditEquipmentResponse = {
  equipmentId: number;
};

function editEquipment(request: EditEquipmentRequest): Promise<EditEquipmentResponse> {
  return externalApi.put(`Equipment/put`, { json: request }).json();
}

function useEditEquipment() {
  const queryClient = useQueryClient();
  return useMutation((request: EditEquipmentRequest) => editEquipment(request), {
    onSuccess: () => {
      queryClient.invalidateQueries(['AllEquipment']);
    },
  });
}
export { useEditEquipment };
