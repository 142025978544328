import {
  ActionIcon,
  Box,
  Button,
  Center,
  Checkbox,
  createStyles,
  Group,
  Modal,
  SegmentedControl,
  Select,
  Stack,
  Text,
  Textarea,
  useMantineTheme,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { useEffect, useState } from 'react';
import { FilePlus, User, X } from 'tabler-icons-react';
import { useMediaQuery } from '@mantine/hooks';
import { IFilteredPatient, IStandingOrder } from '../../../models';
import { useNewStandingOrder, useUserId, useWeekdays } from '../../../hooks';

const useStyles = createStyles((theme) => ({
  patientSelectBox: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    paddingLeft: 3,
    paddingTop: 3,
    paddingBottom: 3,
    paddingRight: theme.spacing.xs,
    width: '100%',
    height: '70',
  },
  label: {
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
    fontWeight: 500,
  },
  fieldGroup: {
    width: '48%',
  },
  detailLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

type StandingOrderCreateModalProps = {
  opened: boolean;
  selectedPatient: IFilteredPatient | undefined;
  onClose: () => void;
  onSelectPatient: () => void;
  onDeSelectPatient: () => void;
  onCreate: (isCreated: boolean, newPatient: IStandingOrder) => void;
};

const StandingOrderCreateModal = (props: StandingOrderCreateModalProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const newStandingOrder = useNewStandingOrder();

  const weekdays = useWeekdays();

  const currentUserId = useUserId();

  const [isAMDelivery, setIsAMDelivery] = useState<boolean>(true);

  const [submitClicked, setSubmitClicked] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [noPatientError, setNoPatientError] = useState<boolean>(false);

  const createForm = useForm<IStandingOrder>({
    initialValues: {
      standingOrderId: '',
      patientId: '',
      patientFirstName: '',
      patientLastName: '',
      patientFullName: '',
      patientFullNameFormatted: '',
      patientPhoneNumber: '',
      patientAddress: '',
      patientCity: '',
      patientNotes: '',
      isAMDelivery: true,
      deliveryTimeName: 'AM',
      weekdayId: '',
      weekdayName: '',
      liquid: false,
      notes: '',
      lastUpdatedUserId: '',
      lastUpdated: new Date(),
    },
    validate: {},
  });

  async function submitStandingOrderUpdate() {
    if (props.selectedPatient) {
      setSubmitClicked(true);

      newStandingOrder.mutate({
        patientId: props.selectedPatient.patientId,
        isAMDelivery: isAMDelivery,
        weekdayId: createForm.values.weekdayId,
        liquid: createForm.values.liquid,
        notes: createForm.values.notes,
        lastUpdatedUserId: currentUserId.data!.userId,
      });
    } else {
      setNoPatientError(true);
    }
  }

  useEffect(() => {
    if (submitClicked) {
      if (newStandingOrder.isSuccess && props.selectedPatient) {
        setSubmitClicked(false);
        createForm.reset();
        setIsError(false);
        setNoPatientError(false);
        props.onCreate(true, {
          standingOrderId: newStandingOrder.data.standingOrderId.toString(),
          patientId: props.selectedPatient.patientId,
          patientFirstName: props.selectedPatient.firstName,
          patientLastName: props.selectedPatient.lastName,
          patientFullName: `${props.selectedPatient.firstName} ${props.selectedPatient.lastName}`,
          patientFullNameFormatted: `${props.selectedPatient.lastName}, ${props.selectedPatient.firstName}`,
          patientPhoneNumber: props.selectedPatient.phoneNumber,
          patientAddress: props.selectedPatient.streetAddress,
          patientCity: props.selectedPatient.cityName,
          patientNotes: props.selectedPatient.notes,
          isAMDelivery: isAMDelivery,
          deliveryTimeName: isAMDelivery ? 'AM' : 'PM',
          weekdayId: createForm.values.weekdayId,
          weekdayName: weekdays.data!.find(
            (a) => a.weekdayId.toString() === createForm.values.weekdayId.toString()
          )!.name,
          liquid: createForm.values.liquid,
          notes: createForm.values.notes,
          lastUpdatedUserId: currentUserId.data!.userId,
          lastUpdated: new Date(),
        });
      } else if (newStandingOrder.isError) {
        setSubmitClicked(false);
        setIsError(true);
      }
    }
  }, [newStandingOrder.status]);

  return (
    <Modal
      title={
        isError ? (
          <Text color="red">
            Something went wrong, Standing Order not Created. Please try again
          </Text>
        ) : (
          ''
        )
      }
      opened={props.opened}
      size={isSmallMobile ? 'xs' : 'md'}
      onClose={() => {
        setIsError(false);
        setNoPatientError(false);
        createForm.reset();
        props.onClose();
      }}
    >
      <Box>
        <Center>
          <Text size="xl" weight={500} align="center">
            New Standing Order Information
          </Text>
        </Center>
        <form
          onSubmit={createForm.onSubmit(() => {
            setIsError(false);
            submitStandingOrderUpdate();
          })}
          autoComplete="off"
        >
          <Stack align="stretch" style={{ padding: 5 }}>
            {!props.selectedPatient && (
              <Stack align="stretch" spacing={0}>
                <Button
                  variant="outline"
                  style={{ height: 70, border: '1px dashed' }}
                  onClick={() => {
                    props.onSelectPatient();
                  }}
                >
                  <User style={{ marginRight: 5 }} />
                  Select a Patient
                </Button>
                {noPatientError && (
                  <Text align="center" color="red">
                    Must select a Patient before creating a Standing Order
                  </Text>
                )}
              </Stack>
            )}
            {props.selectedPatient && (
              <Box className={classes.patientSelectBox}>
                <Group position="apart" noWrap>
                  <Stack spacing={0}>
                    <Text
                      pl="sm"
                      weight={500}
                    >{`${props.selectedPatient.lastName}, ${props.selectedPatient.firstName}`}</Text>
                    <Group pl="sm" spacing={5}>
                      <Text size="xs" color="dimmed">
                        Last Four HCN
                      </Text>
                      <Text size="xs">{`#${props.selectedPatient.lastFourHCN}`}</Text>
                    </Group>
                    <Group pl="sm" spacing={5}>
                      <Text size="xs" color="dimmed">
                        Unique Number
                      </Text>
                      <Text size="xs">{`#${props.selectedPatient.claimNumber}`}</Text>
                    </Group>
                  </Stack>
                  <ActionIcon
                    onClick={() => {
                      props.onDeSelectPatient();
                    }}
                  >
                    <X color="gray" />
                  </ActionIcon>
                </Group>
              </Box>
            )}
            <Group noWrap>
              <Stack spacing={0} className={classes.fieldGroup}>
                <Text className={classes.label}>Delivery Weekday</Text>
                <Select
                  placeholder="Select a Weekday"
                  data={
                    weekdays.data?.map((a) => ({
                      value: `${a.weekdayId}`,
                      label: `${a.name}`,
                    })) ?? []
                  }
                  searchable={!isSmallMobile}
                  required
                  {...createForm.getInputProps('weekdayId')}
                />
              </Stack>
              <Stack spacing={0} className={classes.fieldGroup} ml={15}>
                <Text className={classes.label}>Delivery Time</Text>
                <SegmentedControl
                  color="blue"
                  data={[
                    { label: 'AM', value: 'AM' },
                    { label: 'PM', value: 'PM' },
                  ]}
                  defaultValue={isAMDelivery ? 'AM' : 'PM'}
                  ml={10}
                  onChange={(e) => {
                    e === 'AM' ? setIsAMDelivery(true) : setIsAMDelivery(false);
                  }}
                />
              </Stack>
            </Group>

            <Group position="left" spacing="xs" pl="xs">
              <Checkbox {...createForm.getInputProps('liquid')} />
              <Text className={classes.label}>Liquid</Text>
            </Group>

            <Stack align="stretch" spacing={0}>
              <Text className={classes.label}>Notes</Text>
              <Textarea minRows={4} {...createForm.getInputProps('notes')} />
            </Stack>

            <Group position="apart" grow noWrap mt="xs">
              <Button variant="outline" type="submit">
                <FilePlus style={{ marginRight: 5 }} />{' '}
                <Text className={classes.buttonText}>{`Create ${
                  !isSmallMobile ? 'Order' : ''
                }`}</Text>
              </Button>
              <Button
                variant="outline"
                color="red"
                onClick={() => {
                  setIsError(false);
                  setNoPatientError(false);
                  createForm.reset();
                  props.onClose();
                }}
              >
                <X style={{ marginRight: 5 }} /> <Text className={classes.buttonText}>Cancel</Text>
              </Button>
            </Group>
          </Stack>
        </form>
      </Box>
    </Modal>
  );
};

export default StandingOrderCreateModal;
