import {
  ActionIcon,
  Box,
  Button,
  Center,
  createStyles,
  Divider,
  Flex,
  Group,
  Menu,
  Modal,
  ScrollArea,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React, { useEffect, useState } from 'react';
import {
  Archive,
  ArchiveOff,
  Check,
  Dots,
  Hammer,
  Tool,
  Trash,
  User,
  Users,
  Wash,
} from 'tabler-icons-react';
import { ITrackedEquipment } from '../../../models';
import dayjs from 'dayjs';
import {
  InfoButton,
  TrackedEquipmentDetailSection,
  TrackedEquipmentMetricSection,
  TrackedEquipmentHourSection,
  TrackedEquipmentNoteSection,
  DialogModal,
  TrackedEquipmentPatientSection,
} from '../../../components';
import {
  useDeleteTrackedEquipment,
  usePatient,
  useUnretireTrackedEquipment,
  useUserId,
} from '../../../hooks';
import { useNavigate } from 'react-router-dom';

const useStyles = createStyles((theme) => ({
  notesCard: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    paddingLeft: theme.spacing.sm,
    paddingRight: theme.spacing.sm,
    paddingTop: 3,
    width: '100%',
    height: '125px',
  },
  infoBox: {
    color: `${theme.colors.gray[6]}`,
    border: `1px solid ${theme.colors.gray[6]}`,
    borderRadius: theme.radius.lg,
    width: 'fit-content',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  groupLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.sm,
    whiteSpace: 'pre',
  },
  fieldLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
  },
  detail: {
    fontSize: theme.fontSizes.md,
    paddingLeft: 10,
  },
  fieldGroup: {
    width: '48%',
  },
  clickableIcon: {
    color: `${theme.colors.blue[6]}`,
    padding: '0 !important',
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

type TrackedEquipmentDetailModalProps = {
  trackedEquipment: ITrackedEquipment;
  opened: boolean;
  isCreated?: boolean;
  isRetired?: boolean;
  onViewPatientHistory: () => void;
  onDelete: () => void;
  onRetire: () => void;
  onUnretired: () => void;
  onServicing: () => void;
  onCleaning: () => void;
  onRepair: () => void;
  onClose: () => void;
};

const TrackedEquipmentDetailModal = (props: TrackedEquipmentDetailModalProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const currentUserId = useUserId();

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [unretireModalOpen, setUnretireModalOpen] = useState<boolean>(false);

  const [isError, setIsError] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');

  const deleteTrackedEquipment = useDeleteTrackedEquipment();
  const unretireTrackedEquipment = useUnretireTrackedEquipment();
  const patient = usePatient(props.trackedEquipment.patientId ?? '0');

  const handleDelete = () => {
    deleteTrackedEquipment.mutate({
      trackedEquipmentId: props.trackedEquipment.trackedEquipmentId,
    });
  };

  const handleUnretire = () => {
    unretireTrackedEquipment.mutate({
      trackedEquipmentId: props.trackedEquipment.trackedEquipmentId,
      lastUpdatedUserId: currentUserId.data!.userId,
    });
  };

  useEffect(() => {
    if (deleteTrackedEquipment.isSuccess) {
      props.onDelete();
    }
    if (deleteTrackedEquipment.isError) {
      setIsError(true);
      setMessage('Something went wrong, Equipment not Deleted');
    }
  }, [deleteTrackedEquipment.status]);

  useEffect(() => {
    if (unretireTrackedEquipment.isSuccess) {
      setIsError(false);
      setMessage('Equipment Succesfully Unretired!');
      props.onUnretired();
    }
    if (unretireTrackedEquipment.isError) {
      setIsError(true);
      setMessage('Something went wrong, Equipment not Unretired');
    }
  }, [unretireTrackedEquipment.status]);

  useEffect(() => {
    if (props.opened) {
      patient.refetch();
      if (props.isCreated) {
        setMessage('Equipment Successfully Created!');
      } else if (props.isRetired) {
        setMessage('Equipment Successfully Retired!');
      } else {
        setMessage('');
      }
      setIsError(false);
    }
  }, [props.opened]);

  return (
    <Modal
      title={<Text color={isError ? 'red' : 'green'}>{message}</Text>}
      opened={props.opened}
      size={isSmallMobile ? 'xs' : 'md'}
      onClose={() => {
        props.onClose();
      }}
    >
      <Stack spacing={0} style={{ position: 'relative' }}>
        <Center pr={0}>
          <Text size="xl" weight={500} align="center">
            Complete Equipment Information
          </Text>
          <Flex
            direction={isSmallMobile ? 'column' : 'row'}
            align="center"
            style={{ position: 'absolute', right: -5 }}
          >
            <InfoButton
              userId={props.trackedEquipment?.lastUpdatedUserId ?? ''}
              date={props.trackedEquipment?.lastUpdatedDateTime ?? new Date()}
            />
            <Menu>
              <Menu.Target>
                <ActionIcon style={{}}>
                  <Dots />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown>
                {!props.trackedEquipment.retired && (
                  <Menu.Item
                    icon={<Archive color={`${theme.colors.blue[6]}`} />}
                    onClick={() => {
                      props.onRetire();
                    }}
                  >
                    Retire Equipment
                  </Menu.Item>
                )}
                {props.trackedEquipment.retired && (
                  <Menu.Item
                    icon={<ArchiveOff color={`${theme.colors.blue[6]}`} />}
                    onClick={() => {
                      setUnretireModalOpen(true);
                    }}
                  >
                    Unretire Equipment
                  </Menu.Item>
                )}
                <Menu.Item
                  icon={<Trash color={`${theme.colors.red[6]}`} />}
                  onClick={() => {
                    setDeleteModalOpen(true);
                  }}
                >
                  Delete Equipment
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Flex>
        </Center>
        {props.trackedEquipment?.ready && !props.trackedEquipment.retired && (
          <Center>
            <Group
              className={classes.infoBox}
              position="center"
              spacing="xs"
              my={5}
              style={{ borderColor: theme.colors.blue[6] }}
            >
              <Text weight={500} size="xs" style={{ color: theme.colors.blue[6] }}>
                Equipment Ready{' '}
                <Check style={{ marginBottom: -2 }} size={theme.fontSizes.sm} strokeWidth={3} />
              </Text>
            </Group>
          </Center>
        )}
        {props.trackedEquipment?.inNeedOfRepair &&
          !props.trackedEquipment?.outForRepairs &&
          !props.trackedEquipment.retired &&
          !props.trackedEquipment?.ready && (
            <Center>
              <Group
                className={classes.infoBox}
                position="center"
                spacing="xs"
                my={5}
                style={{ borderColor: theme.colors.red[6] }}
              >
                <Text weight={500} size="xs" style={{ color: theme.colors.red[6] }}>
                  Equipment in Need of Repair{' '}
                  <Hammer style={{ marginBottom: -2 }} size={theme.fontSizes.sm} strokeWidth={2} />
                </Text>
              </Group>
            </Center>
          )}
        {props.trackedEquipment?.outForRepairs &&
          !props.trackedEquipment.retired &&
          !props.trackedEquipment?.ready && (
            <Center>
              <Group
                className={classes.infoBox}
                position="center"
                spacing="xs"
                my={5}
                style={{ borderColor: theme.colors.blue[6] }}
              >
                <Text weight={500} size="xs" style={{ color: theme.colors.blue[6] }}>
                  Equipment Out For Repairs{' '}
                  <Hammer style={{ marginBottom: -2 }} size={theme.fontSizes.sm} strokeWidth={2} />
                </Text>
              </Group>
            </Center>
          )}
        {props.trackedEquipment.retired && (
          <Center>
            <Group
              className={classes.infoBox}
              position="center"
              spacing="xs"
              my={5}
              style={{ borderColor: theme.colors.red[6] }}
            >
              <Text weight={500} size="xs" style={{ color: theme.colors.red[6] }}>
                Equipment Retired{' '}
                <Archive style={{ marginBottom: -2 }} size={theme.fontSizes.sm} strokeWidth={2} />
              </Text>
            </Group>
          </Center>
        )}
        {props.trackedEquipment.checkedOutDate && !props.trackedEquipment.outForRepairs && (
          <TrackedEquipmentPatientSection trackedEquipment={props.trackedEquipment} />
        )}
        <TrackedEquipmentDetailSection
          trackedEquipment={props.trackedEquipment}
          onEdited={() => {
            setIsError(false);
            setMessage('Equipment Details Successfully Updated!');
          }}
          onError={() => {
            setIsError(true);
            setMessage('Something went wrong, Equipment Details not Updated');
          }}
        />
        <Group spacing={theme.spacing.xl} align="top" grow noWrap>
          <TrackedEquipmentMetricSection
            trackedEquipment={props.trackedEquipment}
            onEdited={() => {
              setIsError(false);
              setMessage('Equipment Metrics Successfully Updated!');
            }}
            onError={() => {
              setIsError(true);
              setMessage('Something went wrong, Equipment Metrics not Updated');
            }}
          />
          {/**Hours Active */}
          <TrackedEquipmentHourSection
            trackedEquipment={props.trackedEquipment}
            onEdited={() => {
              setIsError(false);
              setMessage('Hours Active Successfully Updated!');
            }}
            onError={() => {
              setIsError(true);
              setMessage('Something went wrong, Hours Active not Updated');
            }}
          />
        </Group>
        {/**Last Returned*/}
        {props.trackedEquipment.lastReturnedDate && !props.trackedEquipment.retired && (
          <Stack spacing={0}>
            <Group spacing={0} position="apart" noWrap>
              <Text className={classes.groupLabel}>Last Returned</Text>
              <ActionIcon
                disabled={patient.data?.patientId === '0'}
                variant="transparent"
                className={classes.clickableIcon}
                onClick={() => {
                  if (patient.isSuccess) {
                    navigate('../patients', {
                      state: {
                        patient: patient.data,
                      },
                    });
                  }
                }}
              >
                <User style={{ marginBottom: 4 }} />
              </ActionIcon>
            </Group>
            <Divider mb={`calc(${theme.spacing.xs} / 3)`} />
            <Group
              ml={`calc(${theme.spacing.xl} * 2)`}
              spacing={theme.spacing.sm}
              mb={theme.spacing.xs}
              noWrap
            >
              <Stack spacing={0} style={{ width: '45%' }}>
                <Text className={classes.fieldLabel}>Date Returned</Text>
                <Text className={classes.detail}>
                  {props.trackedEquipment.lastServicedDateTime
                    ? dayjs(props.trackedEquipment.lastReturnedDate).format('YYYY-MM-DD')
                    : '-'}
                </Text>
              </Stack>
              <Stack spacing={0}>
                <Text className={classes.fieldLabel}>Returned From</Text>
                <Text className={classes.detail}>{props.trackedEquipment.patientName ?? '-'}</Text>
              </Stack>
            </Group>
          </Stack>
        )}
        {/**Last Service */}
        {!props.trackedEquipment.retired && (
          <Stack spacing={0}>
            <Text className={classes.groupLabel}>Last Service</Text>
            <Divider mb={`calc(${theme.spacing.xs} / 3)`} />
            <Group
              ml={`calc(${theme.spacing.xl} * 2)`}
              spacing={theme.spacing.sm}
              mb={theme.spacing.xs}
              noWrap
            >
              <Stack spacing={0} style={{ width: '45%' }}>
                <Text className={classes.fieldLabel}>Date Serviced</Text>
                <Text className={classes.detail}>
                  {props.trackedEquipment.lastServicedDateTime
                    ? dayjs(props.trackedEquipment.lastServicedDateTime).format('YYYY-MM-DD')
                    : '-'}
                </Text>
              </Stack>
              <Stack spacing={0}>
                <Text className={classes.fieldLabel}>Serviced By</Text>
                <Text className={classes.detail}>
                  {props.trackedEquipment.lastServicedTechnicianName ?? '-'}
                </Text>
              </Stack>
            </Group>
          </Stack>
        )}
        {/**Last Clean */}
        {!props.trackedEquipment.retired && (
          <Stack spacing={0}>
            <Text className={classes.groupLabel}>Last Clean</Text>
            <Divider mb={`calc(${theme.spacing.xs} / 3)`} />
            <Group
              ml={`calc(${theme.spacing.xl} * 2)`}
              spacing={theme.spacing.sm}
              mb={theme.spacing.xs}
              noWrap
            >
              <Stack spacing={0} style={{ width: '45%' }}>
                <Text className={classes.fieldLabel}>Date Cleaned</Text>
                <Text className={classes.detail}>
                  {props.trackedEquipment.lastCleanedDateTime
                    ? dayjs(props.trackedEquipment.lastCleanedDateTime).format('YYYY-MM-DD')
                    : '-'}
                </Text>
              </Stack>
              <Stack spacing={0}>
                <Text className={classes.fieldLabel}>Cleaned By</Text>
                <Text className={classes.detail}>
                  {props.trackedEquipment.lastCleanedTechnicianName ?? '-'}
                </Text>
              </Stack>
            </Group>
          </Stack>
        )}
        {/**Notes */}
        {!props.trackedEquipment.retired && (
          <Box mb={`calc(${theme.spacing.xs} / 2)`}>
            <TrackedEquipmentNoteSection
              trackedEquipment={props.trackedEquipment}
              onEdited={() => {
                setIsError(false);
                setMessage('Service Notes Successfully Updated!');
              }}
              onError={() => {
                setIsError(true);
                setMessage('Something went wrong, Service Notes not Updated');
              }}
            />
          </Box>
        )}
        {/**Retired Notes */}
        {props.trackedEquipment.retired && (
          <Stack spacing={0} mb={theme.spacing.xs}>
            <Text className={classes.fieldLabel}>Retirement Notes</Text>
            <Box style={{ position: 'relative' }} className={classes.notesCard}>
              <ScrollArea>
                <Text>{props.trackedEquipment.retiredNotes}</Text>
              </ScrollArea>
            </Box>
          </Stack>
        )}
        {/**Buttons */}
        <Stack mt={`calc(${theme.spacing.xs} / 2)`} spacing={theme.spacing.xs}>
          <Group
            grow
            noWrap
            spacing={isSmallMobile ? `calc(${theme.spacing.xs} / 2)` : theme.spacing.xs}
          >
            <Button
              variant="outline"
              onClick={() => {
                props.onServicing();
              }}
              p={0}
            >
              <Tool style={{ marginRight: 5 }} />
              <Text className={classes.buttonText}>Servicing</Text>
            </Button>
            <Button
              variant="outline"
              onClick={() => {
                props.onCleaning();
              }}
              p={0}
            >
              <Wash style={{ marginRight: 5 }} />
              <Text className={classes.buttonText}>Cleaning</Text>
            </Button>
            <Button
              variant="outline"
              onClick={() => {
                props.onRepair();
              }}
              p={0}
            >
              <Hammer style={{ marginRight: 5 }} />
              <Text className={classes.buttonText}>Repairs</Text>
            </Button>
          </Group>
          <Divider />
          <Button
            variant="outline"
            onClick={() => {
              props.onViewPatientHistory();
            }}
          >
            <Users style={{ marginRight: 5 }} />
            <Text className={classes.buttonText}>Patient History</Text>
          </Button>
        </Stack>
      </Stack>
      <DialogModal
        isOpen={deleteModalOpen}
        isNegative
        titleText="Delete Equipment?"
        body={
          <Text align="center" color="dimmed" size="lg" mb="xs">
            Are you sure you want to delete this Equipment?
          </Text>
        }
        confirmText="Delete Equipment"
        onConfirm={() => {
          setDeleteModalOpen(false);
          handleDelete();
        }}
        onClose={() => {
          setDeleteModalOpen(false);
        }}
      />
      {props.trackedEquipment.retired && (
        <DialogModal
          isOpen={unretireModalOpen}
          titleText="Unretire Equipment?"
          body={
            <Text align="center" color="dimmed" size="lg" mb="xs">
              Are you sure you want to Unretire this Equipment?
            </Text>
          }
          confirmText="Unretire Equipment"
          onConfirm={() => {
            setUnretireModalOpen(false);
            handleUnretire();
          }}
          onClose={() => {
            setUnretireModalOpen(false);
          }}
        />
      )}
    </Modal>
  );
};

export default TrackedEquipmentDetailModal;
