import {
  ActionIcon,
  Box,
  createStyles,
  Divider,
  Group,
  Stack,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React, { useEffect, useState } from 'react';
import { Edit, DeviceFloppy, X } from 'tabler-icons-react';
import { ITrackedEquipment } from '../../../models';
import { useEditTrackedEquipmentMetrics, useUserId } from '../../../hooks';
import NumberFormat from 'react-number-format';

const useStyles = createStyles((theme) => ({
  notesCard: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    paddingLeft: theme.spacing.sm,
    paddingRight: theme.spacing.sm,
    paddingTop: 3,
    width: '100%',
    height: '125px',
  },
  infoBox: {
    color: `${theme.colors.gray[6]}`,
    border: `1px solid ${theme.colors.gray[6]}`,
    borderRadius: theme.radius.lg,
    width: 'fit-content',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  groupLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.sm,
    whiteSpace: 'pre',
  },
  editGroupLabel: {
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,
    whiteSpace: 'pre',
  },
  fieldLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
  },
  editFieldLabel: {
    fontSize: theme.fontSizes.xs,
    fontWeight: 500,
    whiteSpace: 'pre',
  },
  detail: {
    fontSize: theme.fontSizes.md,
    paddingLeft: 10,
  },
  fieldGroup: {
    width: '48%',
  },
  clickableIcon: {
    color: `${theme.colors.blue[6]}`,
    padding: '0 !important',
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

type TrackedEquipmentMetricSectionProps = {
  trackedEquipment: ITrackedEquipment;
  onEdited: () => void;
  onError: () => void;
};

const TrackedEquipmentMetricSection = (props: TrackedEquipmentMetricSectionProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const currentUserId = useUserId();

  const editTrackedEquipmentMetrics = useEditTrackedEquipmentMetrics();

  const [isEditing, setIsEditing] = useState<boolean>(false);

  // display values
  const [percentOxygen, setPercentOxygen] = useState<number>(props.trackedEquipment.percentOxygen);
  const [flowRate, setFlowRate] = useState<number>(props.trackedEquipment.flowRate);

  // controlled editing values
  const [editPercentOxygen, setEditPercentOxygen] = useState<number | undefined>(
    props.trackedEquipment.percentOxygen
  );
  const [editFlowRate, setEditFlowRate] = useState<number | undefined>(
    props.trackedEquipment.flowRate
  );

  function SaveChanges() {
    editTrackedEquipmentMetrics.mutate({
      trackedEquipmentId: props.trackedEquipment.trackedEquipmentId,
      percentOxygen: editPercentOxygen ?? 0,
      flowRate: editFlowRate ?? 0,
      lastUpdatedUserId: currentUserId.data!.userId,
    });
  }

  useEffect(() => {
    if (editTrackedEquipmentMetrics.isSuccess) {
      setPercentOxygen(editPercentOxygen ?? 0);
      setFlowRate(editFlowRate ?? 0);

      props.onEdited();
    }
    if (editTrackedEquipmentMetrics.isError) {
      props.onError();
    }
  }, [editTrackedEquipmentMetrics.status]);

  return (
    <Box>
      {!isEditing && (
        <Stack spacing={0}>
          <Group spacing={0} position="apart">
            <Text className={classes.groupLabel}>Metrics</Text>
            {!props.trackedEquipment.retired && (
              <ActionIcon
                variant="transparent"
                className={classes.clickableIcon}
                onClick={() => {
                  setEditPercentOxygen(percentOxygen);
                  setEditFlowRate(flowRate);
                  setIsEditing(true);
                }}
              >
                <Edit style={{ marginBottom: 4 }} />
              </ActionIcon>
            )}
          </Group>
          <Divider mb={`calc(${theme.spacing.xs} / 2)`} />
          <Group spacing={0} ml={theme.spacing.md}>
            <Stack spacing={0} style={{ width: '50%' }}>
              <Text className={classes.fieldLabel}>
                %O<sub>2</sub>
              </Text>
              <Group spacing={0} noWrap>
                <Text className={classes.detail}>{percentOxygen ?? '-'}</Text>
                <Text color={theme.colors.gray[5]}>%</Text>
              </Group>
            </Stack>
            <Stack spacing={0} style={{ width: '50%' }}>
              <Text className={classes.fieldLabel}>Flow</Text>
              <Group spacing={0} noWrap>
                <Text className={classes.detail}>{flowRate ?? '-'}</Text>
                <Text color={theme.colors.gray[5]}>L/min</Text>
              </Group>
            </Stack>
          </Group>
        </Stack>
      )}
      {isEditing && (
        <Stack spacing={0}>
          <Group spacing={0} position="apart">
            <Text className={classes.editGroupLabel}>Metrics</Text>
            <Group spacing={0}>
              <ActionIcon
                variant="transparent"
                className={classes.clickableIcon}
                onClick={() => {
                  setIsEditing(false);
                  SaveChanges();
                }}
              >
                <DeviceFloppy style={{ marginBottom: 4 }} />
              </ActionIcon>
              <ActionIcon
                variant="transparent"
                className={classes.clickableIcon}
                onClick={() => {
                  setIsEditing(false);
                }}
              >
                <X style={{ marginBottom: 4 }} />
              </ActionIcon>
            </Group>
          </Group>
          <Divider mb={`calc(${theme.spacing.xs} / 2)`} />
          <Group spacing={5} ml={theme.spacing.md} noWrap>
            <Stack spacing={0}>
              <Text className={classes.editFieldLabel}>
                %O<sub>2</sub>
              </Text>
              <NumberFormat
                allowEmptyFormatting
                customInput={TextInput}
                isAllowed={({ floatValue }) => (floatValue ?? 0) <= 100}
                autoComplete="nope"
                value={editPercentOxygen}
                onValueChange={(a) => {
                  setEditPercentOxygen(a.floatValue);
                }}
                rightSection="%"
                styles={{
                  rightSection: {
                    fontSize: `${theme.fontSizes.sm}`,
                    color: `${theme.colors.gray[5]}`,
                    marginRight: `calc((${theme.spacing.xs} / 2) * -1)`,
                    marginBottom: -2,
                  },
                }}
              />
            </Stack>
            <Stack spacing={0}>
              <Text className={classes.editFieldLabel}>Flow</Text>
              <NumberFormat
                allowEmptyFormatting
                customInput={TextInput}
                autoComplete="nope"
                value={editFlowRate}
                onValueChange={(a) => {
                  setEditFlowRate(a.floatValue);
                }}
                rightSection="L/min"
                styles={{
                  rightSection: {
                    fontSize: `${theme.fontSizes.sm}`,
                    color: `${theme.colors.gray[5]}`,
                    marginRight: `calc(${theme.spacing.xs} / 2)`,
                    marginBottom: -2,
                  },
                }}
              />
            </Stack>
          </Group>
        </Stack>
      )}
    </Box>
  );
};

export default TrackedEquipmentMetricSection;
