import {
  Box,
  createStyles,
  Group,
  Modal,
  useMantineTheme,
  Text,
  Textarea,
  Button,
  Center,
  Stack,
  ActionIcon,
} from '@mantine/core';
import React, { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from '@mantine/hooks';
import {
  useDelivery,
  useEditDeliveryStatus,
  useTrackedOrderEquipmentByOrder,
  useTrackedStandingOrderEquipmentByStandingOrder,
  useUserId,
} from '../../../hooks';
import { Check, Clock, X } from 'tabler-icons-react';
import { DatePickerInput, TimeInput } from '@mantine/dates';
import { DialogModal, TrackedEquipmentDeliverySelect } from '../../../components';
import dayjs from 'dayjs';
import { IEquipment, ITrackedEquipment } from '../../../models';

const useStyles = createStyles((theme) => ({
  label: {
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
    fontWeight: 500,
  },
  fieldGroup: {
    width: '48%',
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

type DeliveryConvertModalProps = {
  opened: boolean;
  deliveryId: string;
  isDelivery: boolean;
  selectedTrackedEquipment: { trackedEquipment: ITrackedEquipment; index: number }[];
  undeliveredEquipment: { equipment: IEquipment; index: number }[];
  onClose: () => void;
  onEquipmentSelect: (equipmentId: string, index: number) => void;
  onTrackedEquipmentRemoved: (trackedEquipmentId: string) => void;
  onUndeliveredRemoved: (index: number) => void;
  onConverted: () => void;
};

const DeliveryConvertModal = (props: DeliveryConvertModalProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const timeInputRef = useRef<any | null>(null);

  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);

  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [deliveredDate, setDeliveredDate] = useState<Date>(new Date());
  const [deliveredTime, setDeliveredTime] = useState<Date>(new Date());
  const [notes, setNotes] = useState<string>('');

  const [trackedEquipment, setTrackedEquipment] = useState<ITrackedEquipment[]>([]);
  const [preTrackedEquipment, setPreTrackedEquipment] = useState<
    {
      equipmentId: string;
      equipmentName: string;
      equipmentTypeName: string;
    }[]
  >([]);
  const [trackedEquipmentNotes, setTrackedEquipmentNotes] = useState<
    { trackedEquipmentId: string; notes: string }[]
  >([]);
  const [undeliveredNotes, setUndeliveredNotes] = useState<{ index: number; notes: string }[]>([]);

  const currentUserId = useUserId();

  const trackedOrderEquipment = useTrackedOrderEquipmentByOrder();
  const trackedStandingOrderEquipment = useTrackedStandingOrderEquipmentByStandingOrder();

  const delivery = useDelivery();
  const editDeliveryStatus = useEditDeliveryStatus();

  const handleEdit = () => {
    if (delivery.data && currentUserId.isSuccess) {
      if (
        preTrackedEquipment.length ===
        props.selectedTrackedEquipment.length + props.undeliveredEquipment.length
      ) {
        editDeliveryStatus.mutate({
          deliveryId: delivery.data.deliveryId,
          deliveredDate: deliveredDate,
          deliveredTime: new Date(
            Date.UTC(
              deliveredTime.getFullYear(),
              deliveredTime.getMonth(),
              deliveredTime.getDate(),
              deliveredTime.getHours(),
              deliveredTime.getMinutes(),
              deliveredTime.getSeconds()
            )
          ),
          notes: notes,
          trackedEquipmentIds: props.selectedTrackedEquipment.map(
            (a) => a.trackedEquipment.trackedEquipmentId
          ),
          trackedEquipmentNotes: trackedEquipmentNotes,
          undeliveredEquipmentIds: props.undeliveredEquipment.map((a) => ({
            equipmentId: a.equipment.equipmentId,
            index: a.index,
          })),
          undeliveredEquipmentNotes: undeliveredNotes.map((a) => ({
            index: a.index,
            notes: a.notes,
          })),
          deliveringUserId: currentUserId.data.userId,
        });
      } else {
        setIsError(true);
        setErrorMessage('Must Select all Tracked Equipment');
      }
    } else {
      setIsError(true);
      setErrorMessage('Something went wrong, Delivery not Updated. Please try again');
    }
  };

  async function submitDeliveryEdit() {
    setConfirmModalOpen(true);
  }

  useEffect(() => {
    if (editDeliveryStatus.isSuccess) {
      setIsError(false);
      setErrorMessage('');
      props.onConverted();
    }
    if (editDeliveryStatus.isError) {
      setIsError(true);
      setErrorMessage('Something went wrong, Delivery Status not Updated. Please try again');
    }
  }, [editDeliveryStatus.status]);

  useEffect(() => {
    setDeliveredDate(new Date());
    setDeliveredTime(new Date());
  }, [props.opened]);

  useEffect(() => {
    if (props.isDelivery) {
      delivery.mutate(props.deliveryId);
    }
  }, [props.deliveryId, props.opened]);

  useEffect(() => {
    if (delivery.isSuccess) {
      setNotes(delivery.data.notes ?? '');

      if (delivery.data.standingOrderId) {
        trackedStandingOrderEquipment.mutate(delivery.data.standingOrderId);
      }
      if (delivery.data.orderId) {
        trackedOrderEquipment.mutate(delivery.data.orderId);
      }
    }
  }, [delivery.status]);

  useEffect(() => {
    if (trackedOrderEquipment.isSuccess) {
      setPreTrackedEquipment(
        trackedOrderEquipment.data.map((a) => ({
          equipmentId: a.equipmentId,
          equipmentName: a.equipmentName,
          equipmentTypeName: a.equipmentTypeName,
          serialNumber: '',
        }))
      );
    }
  }, [trackedOrderEquipment.status]);

  useEffect(() => {
    if (trackedStandingOrderEquipment.isSuccess) {
      setPreTrackedEquipment(
        trackedStandingOrderEquipment.data.map((a) => ({
          equipmentId: a.equipmentId,
          equipmentName: a.equipmentName,
          equipmentTypeName: a.equipmentTypeName,
          serialNumber: '',
        }))
      );
    }
  }, [trackedStandingOrderEquipment.status]);

  return (
    <Modal
      title={isError ? <Text color="red">{errorMessage}</Text> : ''}
      opened={props.opened}
      size={isSmallMobile ? 'xs' : 'md'}
      onClose={() => {
        setIsError(false);
        setErrorMessage('');
        props.onClose();
      }}
      styles={{
        content: {
          border: `3px solid ${theme.colors.red[6]}`,
        },
      }}
    >
      <Box>
        {delivery.data && props.isDelivery && (
          <Box>
            <Center>
              <Text size="xl" weight={500} align="center">
                Update Order Status to Delivered
              </Text>
            </Center>
            <Stack align="stretch" style={{ padding: 5 }} mt={10}>
              <Group spacing={0} position="apart">
                <Stack spacing={0} className={classes.fieldGroup}>
                  <Text className={classes.label}>Date of Delivery</Text>
                  <DatePickerInput
                    valueFormat="YYYY-MM-DD"
                    firstDayOfWeek={0}
                    required
                    dropdownType="modal"
                    modalProps={{
                      title: 'Requested Delivery Date',
                      withCloseButton: true,
                      closeButtonProps: { style: { position: 'fixed', right: 10 } },
                      yOffset: '15vh',
                      styles: { title: { fontWeight: 500, textAlign: 'center', width: '100%' } },
                    }}
                    ml={5}
                    defaultValue={deliveredDate}
                    onChange={(e) => setDeliveredDate(e!)}
                  />
                </Stack>
                <Stack spacing={0} className={classes.fieldGroup}>
                  <Text className={classes.label}>Time of Delivery</Text>
                  <TimeInput
                    ref={timeInputRef}
                    rightSection={
                      <ActionIcon onClick={() => timeInputRef.current!.showPicker()}>
                        <Clock size="1rem" />
                      </ActionIcon>
                    }
                    required
                    ml={5}
                    value={dayjs(deliveredTime).format('HH:mm')}
                    onChange={(e) => {
                      let inputDateTime = new Date();
                      inputDateTime.setHours(parseInt(e.currentTarget.value.split(':')[0]));
                      inputDateTime.setMinutes(parseInt(e.currentTarget.value.split(':')[1]));
                      setDeliveredTime(inputDateTime);
                    }}
                  />
                </Stack>
              </Group>

              {preTrackedEquipment[0] && (
                <Stack align="stretch" spacing={0}>
                  <Text className={classes.label}>Tracked Equipment</Text>
                  <Box ml={5}>
                    <TrackedEquipmentDeliverySelect
                      preTrackedEquipment={preTrackedEquipment}
                      selectedTrackedEquipment={props.selectedTrackedEquipment}
                      trackedEquipmentNotes={trackedEquipmentNotes}
                      undeliveredEquipment={props.undeliveredEquipment}
                      undeliveredEquipmentNotes={undeliveredNotes}
                      onSelectCardClicked={(equipmentId, index) => {
                        props.onEquipmentSelect(equipmentId, index);
                      }}
                      onTrackedEquipmentRemoved={(trackedEquipmentId) => {
                        props.onTrackedEquipmentRemoved(trackedEquipmentId);
                        setTrackedEquipmentNotes((a) =>
                          a.filter((b) => b.trackedEquipmentId !== trackedEquipmentId)
                        );
                      }}
                      onUndeliveredRemoved={(index) => {
                        props.onUndeliveredRemoved(index);
                        setUndeliveredNotes((a) => a.filter((b) => b.index !== index));
                      }}
                      onTrackedNotesSaved={(trackedEquipmentId, notes) => {
                        setTrackedEquipmentNotes((a) => {
                          if (a.some((b) => b.trackedEquipmentId === trackedEquipmentId)) {
                            return a
                              .filter((b) => b.trackedEquipmentId !== trackedEquipmentId)
                              .concat({
                                trackedEquipmentId: trackedEquipmentId,
                                notes: notes ?? '',
                              });
                          } else {
                            return a.concat({
                              trackedEquipmentId: trackedEquipmentId,
                              notes: notes ?? '',
                            });
                          }
                        });
                      }}
                      onUndeliveredNotesSaved={(index, notes) => {
                        setUndeliveredNotes((a) => {
                          if (a.some((b) => b.index === index)) {
                            return a
                              .filter((b) => b.index !== index)
                              .concat({ index: index, notes: notes ?? '' });
                          } else {
                            return a.concat({
                              index: index,
                              notes: notes ?? '',
                            });
                          }
                        });
                      }}
                    />
                  </Box>
                </Stack>
              )}

              <Stack align="stretch" spacing={0}>
                <Text className={classes.label}>Notes</Text>
                <Textarea
                  minRows={4}
                  ml={5}
                  defaultValue={delivery.data.notes}
                  onChange={(e) => setNotes(e.target.value)}
                />
              </Stack>

              <Group position="apart" grow noWrap>
                <Button
                  variant="outline"
                  type="submit"
                  onClick={() => {
                    submitDeliveryEdit();
                  }}
                >
                  <Check style={{ marginRight: 5 }} />{' '}
                  <Text className={classes.buttonText}>Confirm</Text>
                </Button>
                <Button
                  variant="outline"
                  color="red"
                  onClick={() => {
                    setIsError(false);
                    setErrorMessage('');
                    props.onClose();
                  }}
                >
                  <X style={{ marginRight: 5 }} />{' '}
                  <Text className={classes.buttonText}>Discard</Text>
                </Button>
              </Group>
            </Stack>
          </Box>
        )}
      </Box>
      <DialogModal
        isOpen={confirmModalOpen}
        titleText="Deliver Order?"
        body={
          <Box>
            {delivery.data && (
              <Box>
                <Text size="sm">
                  Are you sure you want to make this delivery to{' '}
                  <b>
                    {delivery.data.patientFirstName} {delivery.data.patientLastName}
                  </b>{' '}
                  at <b>{delivery.data.deliveryAddress}</b>?
                </Text>
              </Box>
            )}
          </Box>
        }
        confirmText="Yes, Deliver"
        onConfirm={() => {
          setConfirmModalOpen(false);
          handleEdit();
        }}
        onClose={() => {
          setConfirmModalOpen(false);
        }}
      />
    </Modal>
  );
};

export default DeliveryConvertModal;
