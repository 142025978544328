import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type NewCityRequest = {
  name: string;
};

type NewCityResponse = {
  cityId: number;
};

function newCity(request: NewCityRequest): Promise<NewCityResponse> {
  return externalApi.post(`City/new`, { json: request }).json();
}

function useNewCity() {
  const queryClient = useQueryClient();
  return useMutation((request: NewCityRequest) => newCity(request), {
    onSuccess: () => {
      queryClient.invalidateQueries(['Cities']);
    },
  });
}
export { useNewCity };
