import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

function getStandingOrderCancelledCount(standingOrderId: string): Promise<{ count: string }> {
  return externalApi
    .get(`StandingOrderCancellation/CancelledStandingOrderCount/${standingOrderId}`)
    .json();
}

function useStandingOrderCancelledCount() {
  const queryClient = useQueryClient();
  return useMutation((standingOrderId: string) => getStandingOrderCancelledCount(standingOrderId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['StandingOrderCancelledCount']);
    },
  });
}

export { useStandingOrderCancelledCount };
