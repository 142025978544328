import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type EditOrderEquipmentQuantityRequest = {
  orderEquipmentId: string;
  quantity: number;
  lastUpdatedUserId: string;
};

type EditOrderEquipmentQuantityResponse = {
  orderEquipmentId: number;
};

function editOrderEquipmentQuantity(
  request: EditOrderEquipmentQuantityRequest
): Promise<EditOrderEquipmentQuantityResponse> {
  return externalApi.patch(`OrderEquipment/patch/quantity`, { json: request }).json();
}

function useEditOrderEquipmentQuantity() {
  const queryClient = useQueryClient();
  return useMutation(
    (request: EditOrderEquipmentQuantityRequest) => editOrderEquipmentQuantity(request),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['OrderEquipmentByOrder']);
      },
    }
  );
}
export { useEditOrderEquipmentQuantity };
