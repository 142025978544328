import {
  ActionIcon,
  Box,
  Button,
  Center,
  Checkbox,
  createStyles,
  Group,
  Menu,
  Modal,
  Select,
  Stack,
  Text,
  Textarea,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { DatePickerInput } from '@mantine/dates';
import NumberFormat from 'react-number-format';
import React, { useEffect, useState } from 'react';
import { Dots, Plus, UserPlus, X } from 'tabler-icons-react';
import { useCities, useFundingTypes, useNewPatient, useUserId } from '../../../hooks';
import { IPatient } from '../../../models';
import { useMediaQuery } from '@mantine/hooks';

const useStyles = createStyles((theme) => ({
  label: {
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
    fontWeight: 500,
  },
  fieldGroup: {
    width: '48%',
  },
  checkFieldGroup: {
    width: 80,
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      '&:last-of-type': {
        marginTop: 5,
      },
      '&:nth-last-of-type(2n)': {
        marginTop: 5,
      },
    },
    [`@media (min-width: ${theme.breakpoints.sm})`]: {
      '&:first-of-type': {
        marginRight: 25,
      },
    },
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

type PatientCreateModalProps = {
  opened: boolean;
  onClose: () => void;
  onCreate: (isCreated: boolean, newPatient: IPatient) => void;
  onAddCity: () => void;
  onAddFundingType: () => void;
};

const PatientCreateModal = (props: PatientCreateModalProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);
  const is333px = useMediaQuery(`(max-width: 333px)`);

  const cities = useCities();
  const fundingTypes = useFundingTypes();

  const currentUserId = useUserId();

  const newPatient = useNewPatient();

  const [isError, setIsError] = useState<boolean>(false);
  const [submitClicked, setSubmitClicked] = useState<boolean>(false);
  const [buttonsLocked, setButtonsLocked] = useState<boolean>(false);

  const createForm = useForm<IPatient>({
    initialValues: {
      patientId: '',
      firstName: '',
      lastName: '',
      fullName: '',
      fullNameFormatted: '',
      phoneNumber: '',
      phoneNumberFormatted: '',
      streetAddress: '',
      cityId: '-1',
      cityName: '',
      lastFourHCN: '',
      claimNumber: '',
      yearlyAppointment: false,
      covid: false,
      lakeridgeGardens: false,
      hospicePT: false,
      fundingTypeId: '-1',
      fundingTypeName: '',
      startDate: new Date(),
      dischargeDate: undefined,
      deathDate: undefined,
      notes: '',
      active: true,
      lastUpdatedUserId: '',
      lastUpdatedDateTime: new Date(),
    },
    validate: {
      firstName: (a) =>
        a.length > 75 ? "Patient's First Name must be a maximum of 75 characters" : null,
      lastName: (a) =>
        a.length > 75 ? "Patient's Last Name must be a maximum of 75 characters" : null,
      phoneNumber: (a) => (a.includes('_') ? "Patient's Phone Number must be 10 digits" : null),
      streetAddress: (a) =>
        a.length > 150 ? "Patient's Street Address must be a maximum of 150 characters" : null,
      lastFourHCN: (a) =>
        a.length !== 4
          ? "Must only be the Last Four Digits of the Patient's Health Card Number"
          : null,
      claimNumber: (a) =>
        a.length > 9 ? "Patient's Unique Number must be a maximum of 9 characters" : null,
    },
  });

  async function submitPatientCreate() {
    setSubmitClicked(true);

    newPatient.mutate({
      firstName: createForm.values.firstName,
      lastName: createForm.values.lastName,
      phoneNumber: createForm.values.phoneNumber.replace(/\D/g, ''),
      streetAddress: createForm.values.streetAddress,
      cityId: createForm.values.cityId,
      lastFourHCN: createForm.values.lastFourHCN,
      claimNumber: createForm.values.claimNumber,
      yearlyAppointment: createForm.values.yearlyAppointment,
      covid: createForm.values.covid,
      lakeridgeGardens: createForm.values.lakeridgeGardens,
      hospicePT: createForm.values.hospicePT,
      fundingTypeId: createForm.values.fundingTypeId,
      startDate: createForm.values.startDate,
      dischargeDate: createForm.values.dischargeDate,
      deathDate: createForm.values.deathDate,
      notes: createForm.values.notes,
      lastUpdatedUserId: currentUserId.data!.userId,
    });
  }

  useEffect(() => {
    if (submitClicked) {
      if (newPatient.isSuccess) {
        setSubmitClicked(false);
        props.onCreate(true, {
          patientId: newPatient.data.patientId.toString(),
          firstName: createForm.values.firstName,
          lastName: createForm.values.lastName,
          fullName: `${createForm.values.firstName} ${createForm.values.lastName}`,
          fullNameFormatted: `${createForm.values.lastName}, ${createForm.values.firstName}`,
          phoneNumber: createForm.values.phoneNumber.replace(/\D/g, ''),
          phoneNumberFormatted: '',
          streetAddress: createForm.values.streetAddress,
          cityId: createForm.values.cityId,
          cityName: cities.data!.find(
            (a) => a.cityId.toString() === createForm.values.cityId.toString()
          )!.name,
          lastFourHCN: createForm.values.lastFourHCN,
          claimNumber: createForm.values.claimNumber,
          yearlyAppointment: createForm.values.yearlyAppointment,
          covid: createForm.values.covid,
          lakeridgeGardens: createForm.values.lakeridgeGardens,
          hospicePT: createForm.values.hospicePT,
          fundingTypeId: createForm.values.fundingTypeId,
          fundingTypeName: fundingTypes.data!.find(
            (a) => a.fundingTypeId.toString() === createForm.values.fundingTypeId.toString()
          )!.name,
          startDate: createForm.values.startDate,
          dischargeDate: createForm.values.dischargeDate,
          deathDate: createForm.values.deathDate,
          notes: createForm.values.notes,
          active: true,
          lastUpdatedUserId: currentUserId.data!.userId,
          lastUpdatedDateTime: new Date(),
        });
        createForm.reset();
      } else if (newPatient.isError) {
        setSubmitClicked(false);
        setIsError(true);
        setButtonsLocked(false);
      }
    }
  }, [newPatient.status]);

  useEffect(() => {
    setButtonsLocked(false);
  }, [props.opened]);

  useEffect(() => {}, []);

  return (
    <Modal
      title={
        isError ? (
          <Text color="red">Something went wrong, Patient not Created. Please try again</Text>
        ) : (
          ''
        )
      }
      opened={props.opened}
      size={isSmallMobile ? 'xs' : 'md'}
      onClose={() => {
        setIsError(false);
        createForm.reset();
        props.onClose();
      }}
    >
      <Box>
        <Center>
          <Group noWrap spacing={!is333px ? 'md' : 0} ml={!isSmallMobile ? 25 : 0}>
            <Text size="xl" weight={500} align="center">
              New Patient Information
            </Text>
            <Menu>
              <Menu.Target>
                <ActionIcon>
                  <Dots />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item
                  icon={<Plus color={`${theme.colors.blue[6]}`} />}
                  onClick={() => {
                    props.onAddCity();
                  }}
                >
                  Add City
                </Menu.Item>
                <Menu.Item
                  icon={<Plus color={`${theme.colors.blue[6]}`} />}
                  onClick={() => {
                    props.onAddFundingType();
                  }}
                >
                  Add Funding Type
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>
        </Center>
        <form
          onSubmit={createForm.onSubmit(() => {
            setButtonsLocked(true);
            setIsError(false);
            submitPatientCreate();
          })}
          autoComplete="off"
        >
          <Stack align="stretch" spacing={5} style={{ padding: 5 }}>
            <Group noWrap>
              <Stack spacing={0} className={classes.fieldGroup}>
                <Text className={classes.label}>Patient Last Name</Text>
                <TextInput
                  placeholder={!isSmallMobile ? "Patient's Last Name" : 'Last Name'}
                  required
                  autoComplete="nope"
                  {...createForm.getInputProps('lastName')}
                />
              </Stack>
              <Stack spacing={0} className={classes.fieldGroup}>
                <Text className={classes.label}>Patient First Name</Text>
                <TextInput
                  placeholder={!isSmallMobile ? "Patient's First Name" : 'First Name'}
                  required
                  autoComplete="nope"
                  {...createForm.getInputProps('firstName')}
                />
              </Stack>
            </Group>

            <Group noWrap>
              <Stack spacing={0} className={classes.fieldGroup}>
                <Text className={classes.label}>Last 4 Digits of{`\n`}Health Card Number</Text>
                <Group noWrap spacing={1}>
                  <Text size="md" color="dimmed">
                    #
                  </Text>
                  <TextInput
                    placeholder={!isSmallMobile ? 'Last Four HCN Digits' : 'Last 4 HCN'}
                    required
                    {...createForm.getInputProps('lastFourHCN')}
                  />
                </Group>
              </Stack>
              <Stack spacing={0} className={classes.fieldGroup}>
                <Text className={classes.label}>{`\n`}Unique Number</Text>
                <Group noWrap spacing={1}>
                  <Text size="md" color="dimmed">
                    #
                  </Text>
                  <TextInput
                    placeholder={!isSmallMobile ? "Patient's Unique Number" : 'Unique #'}
                    required
                    {...createForm.getInputProps('claimNumber')}
                  />
                </Group>
              </Stack>
            </Group>

            <Stack spacing={0} className={classes.fieldGroup}>
              <Text className={classes.label}>Phone Number</Text>
              <NumberFormat
                format="(###) ###-####"
                allowEmptyFormatting
                mask="_"
                customInput={TextInput}
                autoComplete="nope"
                {...createForm.getInputProps('phoneNumber')}
              />
            </Stack>

            <Group noWrap>
              <Stack spacing={0} className={classes.fieldGroup}>
                <Text className={classes.label}>Street Address</Text>
                <TextInput
                  placeholder={!isSmallMobile ? "Patient's Street Address" : 'Address'}
                  required
                  autoComplete="nope"
                  {...createForm.getInputProps('streetAddress')}
                />
              </Stack>
              <Stack spacing={0} className={classes.fieldGroup}>
                <Text className={classes.label}>City</Text>
                <Select
                  data={
                    cities.data?.map((a) => ({
                      value: `${a.cityId}`,
                      label: `${a.name}`,
                    })) ?? []
                  }
                  defaultValue={'-1'}
                  placeholder={!isSmallMobile ? "Select Patient's City" : 'City'}
                  searchable={!isSmallMobile}
                  required
                  {...createForm.getInputProps('cityId')}
                  autoComplete="nope"
                />
              </Stack>
            </Group>

            <Stack spacing={0} className={classes.fieldGroup}>
              <Text className={classes.label}>Funding Type</Text>
              <Select
                data={
                  fundingTypes.data?.map((a) => ({
                    value: `${a.fundingTypeId}`,
                    label: `${a.name}`,
                  })) ?? []
                }
                defaultValue={'-1'}
                placeholder={!isSmallMobile ? 'Select Funding Type' : 'Funding'}
                searchable={!isSmallMobile}
                required
                {...createForm.getInputProps('fundingTypeId')}
              />
            </Stack>

            <Group position="center" noWrap={!isSmallMobile} spacing={!isSmallMobile ? 25 : 0}>
              <Group position="center" grow noWrap spacing={!isSmallMobile ? 0 : 25}>
                <Stack align="stretch" spacing={5} className={classes.checkFieldGroup}>
                  <Text
                    className={classes.label}
                    align="center"
                    style={{ marginLeft: `${!isSmallMobile ? '' : '-4px'}` }}
                  >
                    Yearly{!isSmallMobile ? `\n` : ' '}Appointment
                  </Text>
                  <Center>
                    <Checkbox
                      defaultChecked={false}
                      {...createForm.getInputProps('yearlyAppointment')}
                    />
                  </Center>
                </Stack>
                <Stack align="stretch" spacing={5} className={classes.checkFieldGroup}>
                  <Text className={classes.label} align="center">
                    {!isSmallMobile ? `\n` : ''}Covid
                  </Text>
                  <Center>
                    <Checkbox defaultChecked={false} {...createForm.getInputProps('covid')} />
                  </Center>
                </Stack>
              </Group>
              <Group position="center" grow noWrap spacing={!isSmallMobile ? 0 : 25}>
                <Stack align="stretch" spacing={5} className={classes.checkFieldGroup}>
                  <Text className={classes.label} align="center">
                    Lakeridge{!isSmallMobile ? `\n` : ' '}Gardens
                  </Text>
                  <Center>
                    <Checkbox
                      defaultChecked={false}
                      {...createForm.getInputProps('lakeridgeGardens')}
                    />
                  </Center>
                </Stack>
                <Stack align="stretch" spacing={5} className={classes.checkFieldGroup}>
                  <Text className={classes.label} align="center">
                    {!isSmallMobile ? `\n` : ''}Hospice PT
                  </Text>
                  <Center>
                    <Checkbox defaultChecked={false} {...createForm.getInputProps('hospicePT')} />
                  </Center>
                </Stack>
              </Group>
            </Group>

            <Group position="apart" spacing={0}>
              <Stack spacing={0} className={classes.fieldGroup}>
                <Text className={classes.label}>Start Date</Text>
                <DatePickerInput
                  valueFormat="YYYY-MM-DD"
                  firstDayOfWeek={0}
                  defaultValue={new Date()}
                  required
                  dropdownType="modal"
                  modalProps={{
                    title: 'Start Date',
                    withCloseButton: true,
                    closeButtonProps: { style: { position: 'fixed', right: 10 } },
                    yOffset: '15vh',
                    styles: { title: { fontWeight: 500, textAlign: 'center', width: '100%' } },
                  }}
                  {...createForm.getInputProps('startDate')}
                />
              </Stack>
              <Stack spacing={0} className={classes.fieldGroup}>
                <Text className={classes.label}>Discharge Date</Text>
                <DatePickerInput
                  valueFormat="YYYY-MM-DD"
                  firstDayOfWeek={0}
                  dropdownType="modal"
                  modalProps={{
                    title: 'Discharge Date',
                    withCloseButton: true,
                    closeButtonProps: { style: { position: 'fixed', right: 10 } },
                    yOffset: '15vh',
                    styles: { title: { fontWeight: 500, textAlign: 'center', width: '100%' } },
                  }}
                  {...createForm.getInputProps('dischargeDate')}
                />
              </Stack>
              <Stack spacing={0} className={classes.fieldGroup}>
                <Text className={classes.label}>Death Date</Text>
                <DatePickerInput
                  valueFormat="YYYY-MM-DD"
                  firstDayOfWeek={0}
                  dropdownType="modal"
                  modalProps={{
                    title: 'Death Date',
                    withCloseButton: true,
                    closeButtonProps: { style: { position: 'fixed', right: 10 } },
                    yOffset: '15vh',
                    styles: { title: { fontWeight: 500, textAlign: 'center', width: '100%' } },
                  }}
                  {...createForm.getInputProps('deathDate')}
                />
              </Stack>
            </Group>

            <Stack align="stretch" spacing={0}>
              <Text className={classes.label}>Notes</Text>
              <Textarea minRows={4} {...createForm.getInputProps('notes')} />
            </Stack>
          </Stack>

          <Group position="apart" grow noWrap mt="md">
            <Button variant="outline" disabled={buttonsLocked} type="submit">
              <UserPlus style={{ marginRight: 5 }} />{' '}
              <Text className={classes.buttonText}>{`Create ${
                !isSmallMobile ? 'Patient' : ''
              }`}</Text>
            </Button>
            <Button
              variant="outline"
              color="red"
              onClick={() => {
                setIsError(false);
                createForm.reset();
                props.onClose();
              }}
            >
              <X style={{ marginRight: 5 }} /> <Text className={classes.buttonText}>Cancel</Text>
            </Button>
          </Group>
        </form>
      </Box>
    </Modal>
  );
};

export default PatientCreateModal;
