import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type NewOrderEquipmentFromStandingOrderEquipmentRequest = {
  orderId: string;
  standingOrderId: string;
  lastUpdatedUserId: string;
};

type NewOrderEquipmentFromStandingOrderEquipmentResponse = {
  orderId: number;
};

function newOrderEquipmentFromStandingOrderEquipment(
  request: NewOrderEquipmentFromStandingOrderEquipmentRequest
): Promise<NewOrderEquipmentFromStandingOrderEquipmentResponse> {
  return externalApi.post(`OrderEquipment/FromStandingOrderEquipment`, { json: request }).json();
}

function useNewOrderEquipmentFromStandingOrderEquipment() {
  const queryClient = useQueryClient();
  return useMutation(
    (request: NewOrderEquipmentFromStandingOrderEquipmentRequest) =>
      newOrderEquipmentFromStandingOrderEquipment(request),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['OrderEquipmentByOrder']);
      },
    }
  );
}
export { useNewOrderEquipmentFromStandingOrderEquipment };
