import { createStyles, Stack, Text, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React from 'react';
import { HelpBox } from '../../../components';

const useStyles = createStyles((theme, _params) => ({
  card: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.md,
    padding: `${theme.spacing.sm} ${theme.spacing.lg}`,
  },
}));

type Props = {};

const EquipmentHelpSubPage = (props: Props) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  return (
    <Stack align="stretch" p={25}>
      <Text color="dimmed" weight={500} size="lg" mb={-10}>
        Tracked Equipment
      </Text>
      <HelpBox title="Selecting Tracked Equipment">
        <Text style={{ whiteSpace: 'nowrap' }}>
          To view and manage tracked equipment, ensure that the "Tracked Equipment" button at the
          top of the page is selected.
        </Text>
      </HelpBox>
      <HelpBox title="Table Navigation">
        <Text style={{ whiteSpace: 'nowrap' }}>
          There are several ways to find the records you're looking for in the tracked equipment
          table.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          By default the table is sorted by most recently updated records first.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          You can change the table sort by clicking on any of the column headers.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          When a column header has two arrows, one pointing up and one pointing down, it is not
          currently the column records are being sorted by.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          When a column header contains one arrow and it's pointing down, records are being sorted
          by that column, ascending (smallest to largest, A to Z, 0 to 9).
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          When a column header contains one arrow and it's pointing up, records are being sorted by
          that column, descending (largest to smallest, Z to A, 9 to 0).
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          You can also use the search bar above the table to search for a record that contains your
          search term in any of it's visible columns.
        </Text>
      </HelpBox>
      <HelpBox title="Viewing Tracked Equipment Details">
        <Text style={{ whiteSpace: 'nowrap' }}>
          To view tracked equipment details, click on the row of the table containing the record you
          wish to view the details of.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Clicking the "i" button next to the title of the "Complete Tracked Equipment Type
          Information" screen will display the last person to edit this record, and when it was
          edited.
        </Text>
      </HelpBox>
      <HelpBox title="Add New Tracked Equipment">
        <Text style={{ whiteSpace: 'nowrap' }}>
          Clicking the "Add New Tracked Equipment" button below the table will bring up a form
          allowing you to create a new tracked equipment record.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Fill out each of the fields as required and click "Create Tracked Equipment Type". This
          will add your new tracked equipment to the table.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Of note is the checkbox labelled "Does This Tracked Equipment have a tracked Serial
          Number?" If checked, this tracked equipment will refer to Tracked Tracked Equipment
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          If you've missed any required fields you should recieve a prompt to include them. After
          filling them in, try clicking "Create Tracked Equipment Type" again.
        </Text>
      </HelpBox>
      <HelpBox title="Edit Tracked Equipment">
        <Text style={{ whiteSpace: 'nowrap' }}>
          To edit tracked equipment information, click on any of the icons that look like a pencil
          and paper on the detail view to edit that section.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          This will make all displayed fields in that section editable.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Change the values as needed, then click the save icon for that section to update the
          values, or the "X" icon next to the save button to cancel any changes.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          If you've missed any required fields you should recieve a prompt to include them. After
          filling them in, try clicking the save button again.
        </Text>
      </HelpBox>
      <Text color="dimmed" weight={500} size="lg" mb={-10}>
        Equipment Types
      </Text>
      <HelpBox title="Selecting Equipment">
        <Text style={{ whiteSpace: 'nowrap' }}>
          To view and manage equipment types, ensure that the "Equipment Types" button at the top of
          the page is selected.
        </Text>
      </HelpBox>
      <HelpBox title="Table Navigation">
        <Text style={{ whiteSpace: 'nowrap' }}>
          There are several ways to find the records you're looking for in the equipment types
          table.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          By default the table is sorted Alphabetically by equipment type name.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          You can change the table sort by clicking on any of the column headers.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          When a column header has two arrows, one pointing up and one pointing down, it is not
          currently the column records are being sorted by.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          When a column header contains one arrow and it's pointing down, records are being sorted
          by that column, ascending (smallest to largest, A to Z, 0 to 9).
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          When a column header contains one arrow and it's pointing up, records are being sorted by
          that column, descending (largest to smallest, Z to A, 9 to 0).
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          You can also use the search bar above the table to search for a record that contains your
          search term in any of it's visible columns.
        </Text>
      </HelpBox>
      <HelpBox title="Viewing Equipment Details">
        <Text style={{ whiteSpace: 'nowrap' }}>
          To view equipment details, click on the row of the table containing the record you wish to
          view the details of.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Clicking the "i" button next to the title of the "Complete Equipment Type Information"
          screen will display the last person to edit this record, and when it was edited.
        </Text>
      </HelpBox>
      <HelpBox title="Add New Equipment">
        <Text style={{ whiteSpace: 'nowrap' }}>
          Clicking the "Add New Equipment" button below the table will bring up a form allowing you
          to create a new equipment type record.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Fill out each of the fields as required and click "Create Equipment Type". This will add
          your new equipment to the table.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Of note is the checkbox labelled "Does This Equipment have a tracked Serial Number?" If
          checked, this equipment type will refer to Tracked Equipment
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          If you've missed any required fields you should recieve a prompt to include them. After
          filling them in, try clicking "Create Equipment Type" again.
        </Text>
      </HelpBox>
      <HelpBox title="Edit Equipment">
        <Text style={{ whiteSpace: 'nowrap' }}>
          To edit equipment information, click on the "Edit Equipment Type" button on the "Complete
          Equipment Type Information" screen.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          This will bring up a form containing the details of the equipment type you've selected to
          edit.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Change the values as needed, then click "Update Equipment Type".
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          If you've missed any required fields you should recieve a prompt to include them. After
          filling them in, try clicking "Update Equipment Type" again.
        </Text>
      </HelpBox>
      <Text color="dimmed" weight={500} size="lg" mb={-10}>
        Equipment Categories
      </Text>
      <HelpBox title="Selecting Equipment Categories">
        <Text style={{ whiteSpace: 'nowrap' }}>
          To view and manage equipment categories, ensure that the "Equipment Categories" button at
          the top of the page is selected.
        </Text>
      </HelpBox>
      <HelpBox title="Table Navigation">
        <Text style={{ whiteSpace: 'nowrap' }}>
          There are several ways to find the records you're looking for in the equipment categories
          table.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          By default the table is sorted Alphabetically by equipment category name.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          You can change the table sort by clicking on any of the column headers.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          When a column header has two arrows, one pointing up and one pointing down, it is not
          currently the column records are being sorted by.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          When a column header contains one arrow and it's pointing down, records are being sorted
          by that column, ascending (smallest to largest, A to Z, 0 to 9).
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          When a column header contains one arrow and it's pointing up, records are being sorted by
          that column, descending (largest to smallest, Z to A, 9 to 0).
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          You can also use the search bar above the table to search for a record that contains your
          search term in any of its visible columns.
        </Text>
      </HelpBox>
      <HelpBox title="Viewing Equipment Type Details">
        <Text style={{ whiteSpace: 'nowrap' }}>
          To view equipment category details, click on the row of the table containing the record
          you wish to view the details of.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Clicking the "i" button next to the title of the "Complete Equipment Category Information"
          screen will display the last person to edit this record, and when it was edited.
        </Text>
      </HelpBox>
      <HelpBox title="Add a New Equipment Type">
        <Text style={{ whiteSpace: 'nowrap' }}>
          Clicking the "Add New Equipment Category" button below the table will bring up a form
          allowing you to create a new equipment record.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Fill the "Equipment Name" field and click "Create Category". This will add your new
          patient to the table.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          If you've missed any required fields you should recieve a prompt to include them. After
          filling them in, try clicking "Create Category" again.
        </Text>
      </HelpBox>
      <HelpBox title="Edit an Equipment Type">
        <Text style={{ whiteSpace: 'nowrap' }}>
          To edit equipment type information, click on the "Edit Category" button on the "Complete
          Equipment Category Information" screen.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          This will bring up a form containing the details of the equipment category you've selected
          to edit.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Change the values as needed, then click "Update Category".
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          If you've missed any required fields you should recieve a prompt to include them. After
          filling them in, try clicking "Update Category" again.
        </Text>
      </HelpBox>
    </Stack>
  );
};

export default EquipmentHelpSubPage;
