import {
  ActionIcon,
  Box,
  Button,
  Center,
  createStyles,
  Divider,
  Group,
  Modal,
  ScrollArea,
  SegmentedControl,
  Select,
  Stack,
  Text,
  Textarea,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useMediaQuery } from '@mantine/hooks';
import { ITrackedEquipment, ITrackedEquipmentService } from '../../../../models';
import { TrackedEquipmentPatientSection, TrackedEquipmentDetailSection } from '../../..';
import { Clock, Tool, X } from 'tabler-icons-react';
import { useEffect, useRef, useState } from 'react';
import NumberFormat from 'react-number-format';
import { DatePickerInput, TimeInput } from '@mantine/dates';
import {
  useIsAdmin,
  useIsTechnician,
  useNewTrackedEquipmentService,
  useTechnicians,
  useUserId,
} from '../../../../hooks';
import dayjs from 'dayjs';

const useStyles = createStyles((theme) => ({
  notesCard: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    paddingLeft: theme.spacing.sm,
    paddingRight: theme.spacing.sm,
    paddingTop: 3,
    width: '100%',
    height: '125px',
  },
  infoBox: {
    color: `${theme.colors.gray[6]}`,
    border: `1px solid ${theme.colors.gray[6]}`,
    borderRadius: theme.radius.lg,
    width: 'fit-content',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  groupLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.sm,
    whiteSpace: 'pre',
  },
  fieldLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
  },
  editLabel: {
    fontSize: theme.fontSizes.xs,
    fontWeight: 500,
    whiteSpace: 'pre',
  },
  detail: {
    fontSize: theme.fontSizes.md,
    paddingLeft: 10,
  },
  fieldGroup: {
    width: '48%',
  },
  clickableIcon: {
    color: `${theme.colors.blue[6]}`,
    padding: '0 !important',
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

type TrackedEquipmentServiceCreateModalProps = {
  trackedEquipment: ITrackedEquipment;
  opened: boolean;
  onClose: () => void;
  onCreate: (
    percentOxygen: number,
    flowRate: number,
    onHours: number,
    offHours: number,
    readyForClean: boolean
  ) => void;
};

const TrackedEquipmentServiceCreateModal = (props: TrackedEquipmentServiceCreateModalProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const timeInputRef = useRef<any | null>(null);

  const currentUserId = useUserId();
  const isTechnician = useIsTechnician();
  const isAdmin = useIsAdmin();
  const technicians = useTechnicians();

  const newService = useNewTrackedEquipmentService();

  const [isError, setIsError] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [submitClicked, setSubmitClicked] = useState<boolean>(false);
  const [buttonsLocked, setButtonsLocked] = useState<boolean>(false);

  const [serviceDate, setServiceDate] = useState<Date>(new Date());
  const [serviceTime, setServiceTime] = useState<Date>(new Date());

  const [percentOxygenInput, setPecrcentOxygenInput] = useState<number | undefined>();
  const [flowRateInput, setFlowRateInput] = useState<number | undefined>();
  const [onHoursInput, setOnHoursInput] = useState<number | undefined>();
  const [offHoursInput, setOffHoursInput] = useState<number | undefined>();

  function ResetInputs() {
    setPecrcentOxygenInput(props.trackedEquipment.percentOxygen);
    setFlowRateInput(props.trackedEquipment.flowRate);
    setOnHoursInput(props.trackedEquipment.onHours);
    setOffHoursInput(props.trackedEquipment.offHours);
  }

  const createForm = useForm<ITrackedEquipmentService>({
    initialValues: {
      trackedEquipmentServiceId: '',
      trackedEquipmentId: '',
      technicianUserId: '',
      technicianNameFormatted: '',
      serviceDateTime: new Date(),
      percentOxygen: props.trackedEquipment.percentOxygen,
      flowRate: props.trackedEquipment.flowRate,
      onHours: props.trackedEquipment.onHours,
      offHours: props.trackedEquipment.offHours,
      notes: '',
      readyForClean: true,
    },
    validate: {},
  });

  async function submitServiceCreate() {
    setSubmitClicked(true);
    let newServiceDate: Date = new Date(serviceDate);
    let newServiceTime: Date = new Date(serviceTime);
    let serviceDateTime: Date = new Date(
      new Date(
        Date.UTC(
          newServiceDate.getFullYear(),
          newServiceDate.getMonth(),
          newServiceDate.getDate(),
          newServiceTime.getHours(),
          newServiceTime.getMinutes(),
          newServiceTime.getSeconds()
        )
      )
    );

    newService.mutate({
      trackedEquipmentId: props.trackedEquipment.trackedEquipmentId,
      technicianUserId: createForm.values.technicianUserId,
      serviceDateTime: serviceDateTime,
      percentOxygen: percentOxygenInput ?? 0,
      flowRate: flowRateInput ?? 0,
      onHours: onHoursInput ?? 0,
      offHours: offHoursInput ?? 0,
      notes: createForm.values.notes,
      readyForClean: createForm.values.readyForClean,
      lastUpdatedUserId: currentUserId.data!.userId,
    });
  }

  useEffect(() => {
    if (submitClicked) {
      if (newService.isSuccess) {
        setSubmitClicked(false);
        createForm.reset();
        ResetInputs();
        props.onCreate(
          percentOxygenInput ?? 0,
          flowRateInput ?? 0,
          onHoursInput ?? 0,
          offHoursInput ?? 0,
          createForm.values.readyForClean
        );
      } else if (newService.isError) {
        setMessage('Something went wrong, Service record not added. Please try again');
        setSubmitClicked(false);
        setIsError(true);
        setButtonsLocked(false);
      }
    }
  }, [newService.status]);

  useEffect(() => {
    if (props.opened) {
      setIsError(false);
      setMessage('');
      setButtonsLocked(false);
      setServiceDate(new Date());
      setServiceTime(new Date());
      if (isTechnician.data?.isTechnician && currentUserId.data?.userId) {
        createForm.setFieldValue('technicianUserId', currentUserId.data.userId);
      }
    }
  }, [props.opened]);

  useEffect(() => {
    if (isTechnician.isSuccess && currentUserId.isSuccess) {
      if (isTechnician.data?.isTechnician) {
        createForm.setFieldValue('technicianUserId', currentUserId.data.userId);
      }
    }
  }, [isTechnician.status, currentUserId.status]);

  useEffect(() => {
    if (props.opened) {
      setPecrcentOxygenInput(props.trackedEquipment.percentOxygen);
      setFlowRateInput(props.trackedEquipment.flowRate);
      setOnHoursInput(props.trackedEquipment.onHours);
      setOffHoursInput(props.trackedEquipment.offHours);
    }
  }, [props.opened]);

  return (
    <Modal
      title={<Text color={isError ? 'red' : 'green'}>{message}</Text>}
      opened={props.opened}
      size={isSmallMobile ? 'xs' : 'md'}
      onClose={() => {
        props.onClose();
      }}
    >
      <Stack spacing={0} style={{ position: 'relative' }}>
        <Center>
          <Text size="xl" weight={500} align="center">
            Equipment Servicing
          </Text>
        </Center>
        {props.trackedEquipment.patientId && (
          <TrackedEquipmentPatientSection
            trackedEquipment={props.trackedEquipment}
            header="Current Patient"
          />
        )}
        <TrackedEquipmentDetailSection trackedEquipment={props.trackedEquipment} readOnly />
        <form
          onSubmit={createForm.onSubmit(() => {
            setButtonsLocked(true);
            setIsError(false);
            submitServiceCreate();
          })}
          autoComplete="off"
        >
          <Stack spacing={0}>
            <Text className={classes.groupLabel}>Service Details</Text>
            <Divider mb={`calc(${theme.spacing.xs} / 2)`} />

            <Stack spacing={0}>
              <Text className={classes.editLabel}>Technician</Text>
              {isAdmin.data?.isAdmin && (
                <Select
                  data={(technicians.data ?? []).map((a) => ({
                    value: a.userId,
                    label: a.userFullNameFormatted,
                  }))}
                  placeholder="Select Technician"
                  searchable={!isSmallMobile}
                  {...createForm.getInputProps('technicianUserId')}
                />
              )}
              {!isAdmin.data?.isAdmin && (
                <Text className={classes.detail}>
                  {
                    technicians.data?.find((a) => a.userId === createForm.values.technicianUserId)
                      ?.userFullNameFormatted
                  }
                </Text>
              )}
            </Stack>
            <Group
              spacing={theme.spacing.sm}
              mt={`calc(${theme.spacing.xs} / 2)`}
              mb={theme.spacing.xs}
              grow
              noWrap
            >
              <Stack spacing={0} align="stretch">
                <Text className={classes.editLabel}>Service Date</Text>
                <DatePickerInput
                  dropdownType="modal"
                  valueFormat="YYYY-MM-DD"
                  firstDayOfWeek={0}
                  clearable
                  modalProps={{
                    title: 'Service Date',
                    withCloseButton: true,
                    closeButtonProps: { style: { position: 'fixed', right: 10 } },
                    yOffset: '15vh',
                    styles: { title: { fontWeight: 500, textAlign: 'center', width: '100%' } },
                  }}
                  value={serviceDate}
                  onChange={(e) => {
                    setServiceDate(e as Date);
                  }}
                />
              </Stack>
              <Stack spacing={0} align="stretch">
                <Text className={classes.editLabel}>Service Time</Text>
                <TimeInput
                  ref={timeInputRef}
                  rightSection={
                    <ActionIcon onClick={() => timeInputRef.current!.showPicker()}>
                      <Clock size="1rem" />
                    </ActionIcon>
                  }
                  required
                  ml={5}
                  value={dayjs(serviceTime).format('HH:mm')}
                  onChange={(e) => {
                    let inputTime = new Date();
                    inputTime.setHours(parseInt(e.currentTarget.value.split(':')[0]));
                    inputTime.setMinutes(parseInt(e.currentTarget.value.split(':')[1]));
                    setServiceTime(inputTime);
                  }}
                />
              </Stack>
            </Group>
          </Stack>
          <Group spacing={theme.spacing.xl} align="top" grow noWrap>
            <Stack spacing={0}>
              <Text className={classes.groupLabel}>Equipment Metrics</Text>
              <Divider mb={`calc(${theme.spacing.xs} / 2)`} />
              <Group spacing={5} noWrap>
                <Stack spacing={0}>
                  <Text className={classes.editLabel}>
                    %O<sub>2</sub>
                  </Text>
                  <NumberFormat
                    allowEmptyFormatting
                    customInput={TextInput}
                    isAllowed={({ floatValue }) => (floatValue ?? 0) <= 100}
                    autoComplete="nope"
                    rightSection="%"
                    styles={{
                      rightSection: {
                        fontSize: `${theme.fontSizes.sm}`,
                        color: `${theme.colors.gray[5]}`,
                        marginRight: `calc((${theme.spacing.xs} / 2) * -1)`,
                        marginBottom: -2,
                      },
                    }}
                    value={percentOxygenInput}
                    onValueChange={(a) => {
                      setPecrcentOxygenInput(a.floatValue);
                      createForm.setFieldValue('percentOxygen', a.floatValue ?? 0);
                    }}
                  />
                </Stack>
                <Stack spacing={0}>
                  <Text className={classes.editLabel}>Flow</Text>
                  <NumberFormat
                    allowEmptyFormatting
                    customInput={TextInput}
                    autoComplete="nope"
                    rightSection="L/min"
                    styles={{
                      rightSection: {
                        fontSize: `${theme.fontSizes.sm}`,
                        color: `${theme.colors.gray[5]}`,
                        marginRight: `calc(${theme.spacing.xs} / 2)`,
                        marginBottom: -2,
                      },
                    }}
                    value={flowRateInput}
                    onValueChange={(a) => {
                      setFlowRateInput(a.floatValue);
                      createForm.setFieldValue('flowRate', a.floatValue ?? 0);
                    }}
                  />
                </Stack>
              </Group>
            </Stack>
            <Stack spacing={0}>
              <Text className={classes.groupLabel}>Hours Active</Text>
              <Divider mb={`calc(${theme.spacing.xs} / 2)`} />
              <Group spacing={5} noWrap>
                <Stack spacing={0}>
                  <Text className={classes.editLabel}>On Hours</Text>
                  <NumberFormat
                    allowEmptyFormatting
                    customInput={TextInput}
                    decimalScale={0}
                    autoComplete="nope"
                    value={onHoursInput}
                    onValueChange={(a) => {
                      setOnHoursInput(a.floatValue);
                      createForm.setFieldValue('onHours', a.floatValue ?? 0);
                    }}
                  />
                </Stack>
                <Stack spacing={0}>
                  <Text className={classes.editLabel}>Off Hours</Text>
                  <NumberFormat
                    allowEmptyFormatting
                    customInput={TextInput}
                    decimalScale={0}
                    autoComplete="nope"
                    value={offHoursInput}
                    onValueChange={(a) => {
                      setOffHoursInput(a.floatValue);
                      createForm.setFieldValue('offHours', a.floatValue ?? 0);
                    }}
                  />
                </Stack>
              </Group>
            </Stack>
          </Group>
          <Stack spacing={0} mt="xs">
            <Text className={classes.editLabel}>Service Notes</Text>
            <Box style={{ position: 'relative', padding: 0 }} className={classes.notesCard}>
              <ScrollArea style={{ height: '100%' }}>
                <Textarea
                  variant="unstyled"
                  mx="sm"
                  autosize
                  minRows={4}
                  styles={{ input: { padding: 0 } }}
                  {...createForm.getInputProps('notes')}
                />
              </ScrollArea>
            </Box>
          </Stack>

          <Stack spacing={0} mt="xs">
            <Text className={classes.editLabel}>Equipment Status</Text>
            <Center>
              <SegmentedControl
                color="blue"
                style={{ border: `1px solid ${theme.colors.gray[4]}` }}
                data={[
                  { label: 'Ready for Cleaning', value: 'clean' },
                  { label: 'In Need of Repair', value: 'repair' },
                ]}
                defaultValue={createForm.values.readyForClean ? 'clean' : 'repair'}
                ml={10}
                onChange={(e) => {
                  createForm.setFieldValue('readyForClean', e === 'clean');
                }}
              />
            </Center>
          </Stack>

          <Divider my={`calc(${theme.spacing.xs} / 2)`} />

          <Group position="apart" grow noWrap mt="md">
            <Button variant="outline" disabled={buttonsLocked} type="submit">
              <Tool style={{ marginRight: 5 }} />{' '}
              <Text className={classes.buttonText}>{`Service ${
                !isSmallMobile ? 'Equipment' : ''
              }`}</Text>
            </Button>
            <Button
              variant="outline"
              color="red"
              onClick={() => {
                setIsError(false);
                createForm.reset();
                ResetInputs();
                props.onClose();
              }}
            >
              <X style={{ marginRight: 5 }} /> <Text className={classes.buttonText}>Cancel</Text>
            </Button>
          </Group>
        </form>
      </Stack>
    </Modal>
  );
};

export default TrackedEquipmentServiceCreateModal;
