import { Box, createStyles, Stack, Text, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PageHeader } from '../common/Page/Header/PageHeader';
import { Page } from '../common/Page/Page';
import { Calendar, DetailDay } from '../components';
import { ILocationState } from '../models';
import { usePageStore } from '../hooks';

const useStyles = createStyles((theme, _params) => ({
  pageBoundary: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      marginLeft: -10,
      marginRight: -10,
    },
  },
  calendar: {
    border: `1px solid ${theme.colors.dark[8]}`,
    borderRadius: theme.radius.md,
  },
  date: {
    borderTop: `1px solid ${theme.colors.dark[8]}`,
    height: '100px',
    width: '15%',
    '&:not(:last-of-type)': {
      borderRight: `1px solid ${theme.colors.dark[8]}`,
    },
    '&:first-of-type': {
      background: theme.colors.gray[3],
      borderBottomLeftRadius: 'inherit',
    },
    '&:last-of-type': {
      background: theme.colors.gray[3],
      borderBottomRightRadius: 'inherit',
    },
  },
  date_selected: {
    border: `2px solid ${theme.colors.blue[6]} !important`,
    height: '100%',
    width: '100%',
  },
  date_today: {
    background: `${theme.colors.blue[1]} !important`,
  },
  dateLabel: {
    paddingLeft: '5px',
    fontWeight: 500,
    fontSize: 12,
  },
  weekday: {
    background: theme.colors.blue[1],
    height: '35px',
    width: '15%',
    '&:not(:last-of-type)': {
      borderRight: `1px solid ${theme.colors.dark[8]}`,
    },
  },
  weekdayLabel: {
    paddingTop: '5px',
    textAlign: 'center',
    fontWeight: 500,
  },
  week: {
    '&:last-of-type': {
      borderBottomLeftRadius: theme.radius.md,
      borderBottomRightRadius: theme.radius.md,
    },
  },
  month: {
    background: theme.colors.blue[5],
    borderTopLeftRadius: `calc(${theme.radius.md} - 1)`,
    borderTopRightRadius: `calc(${theme.radius.md} - 1)`,
    borderBottom: `1px solid ${theme.colors.dark[8]}`,
    width: '100%',
  },
  monthIcon: {
    color: 'white',
  },
}));

type Props = {};

const DeliveriesPage = (props: Props) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const state = useLocation().state as ILocationState;
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const selectedCalendarDate = usePageStore((state) => state.deliveryScheduleDate);
  const setSelectedCalendarDate = usePageStore((state) => state.setDeliveryScheduleDate);

  useEffect(() => {
    if (state) {
      if (state.order) {
        setSelectedCalendarDate(new Date(state.order.requestedDeliveryDate));
      }
      if (state.delivery) {
        setSelectedCalendarDate(new Date(state.delivery.deliveredDateTime!));
      }
    }
  }, [state]);

  return (
    <Page
      header={
        <PageHeader
          title="Manage Deliveries"
          subtitle="View and Manage Deliveries"
          stickyContent={null}
        />
      }
    >
      <Box className={classes.pageBoundary}>
        <Text size="xl" weight={500} align="center" mb={isSmallMobile ? 'xs' : 'lg'}>
          Click a Date on the Calendar to view the day's Deliveries
        </Text>
        <Stack align="stretch">
          <DetailDay selectedDate={selectedCalendarDate} />
          <Calendar
            initialDate={selectedCalendarDate}
            onDateSelected={(selectedDate) => {
              setSelectedCalendarDate(selectedDate);
            }}
          />
        </Stack>
      </Box>
    </Page>
  );
};

export default DeliveriesPage;
