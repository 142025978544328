import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type EditTrackedEquipmentRequest = {
  trackedEquipmentId: string;
  percentOxygen: number;
  flowRate: number;
  lastUpdatedUserId: string;
};

type EditTrackedEquipmentResponse = {
  trackedEquipmentId: number;
};

function editTrackedEquipment(
  request: EditTrackedEquipmentRequest
): Promise<EditTrackedEquipmentResponse> {
  return externalApi.patch(`TrackedEquipment/patch/metrics`, { json: request }).json();
}

function useEditTrackedEquipmentMetrics() {
  const queryClient = useQueryClient();
  return useMutation((request: EditTrackedEquipmentRequest) => editTrackedEquipment(request), {
    onSuccess: () => {
      queryClient.invalidateQueries(['AllTrackedEquipment']);
    },
  });
}
export { useEditTrackedEquipmentMetrics };
