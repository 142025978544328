import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig, loginRequest } from '../../authConfig';

export const msalInstance = new PublicClientApplication(msalConfig);

// To add: scope/url parameter
export const acquireAccessToken = async (msalInstance: PublicClientApplication, url: string) => {
  const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
  const accounts = msalInstance.getAllAccounts();

  if (!activeAccount && accounts.length === 0) {
    /*
     * User is not signed in. Throw error or wait for user to login.
     * Do not attempt to log a user in outside of the context of MsalProvider
     */
  }
  const request = {
    scopes: loginRequest.scopes,
    //scopes: loginRequest.scopes, // Insert request to getScope (interceptor -> utils -> endpoints) (start at: src/api/index.ts this is interceptor)
    account: activeAccount || accounts[0],
  };

  try {
    const authResult = await msalInstance.acquireTokenSilent(request);
    return authResult.accessToken;
  } catch {
    //This will fix an issue where we can't silently acquire the token, like new permissions being requested.
    msalInstance.acquireTokenRedirect(request);
    return null;
  }
};
