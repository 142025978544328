import React from 'react';
import { useQuery } from 'react-query';
import externalApi from '../../../api';

type ReportPendingByDateRequest = {
  date: Date;
  isAMTimeframe: boolean;
  isFullDayTimeframe: boolean;
};

type ReportPendingByDateResponse = {
  deliveryId: string;
  orderId: string;
  standingOrderId: string;
  requestedDeliveryDate: Date;
  isAMRequestedDelivery: boolean;
  patientFirstName: string;
  patientLastName: string;
  deliveryAddress: string;
  deliveryCity: string;
  patientPhoneNumber: string;
  isStandingOrder: boolean;
  isStandingOrderReschedule: boolean;
  liquid: boolean;
  orderNotes: string;
};

function reportReportPendingByDate(
  request: ReportPendingByDateRequest
): Promise<ReportPendingByDateResponse[]> {
  return externalApi.post(`Delivery/Report/ByDate/Pending`, { json: request }).json();
}

function useReportPendingByDate(request: ReportPendingByDateRequest) {
  return useQuery(['ReportPendingByDate'], () => reportReportPendingByDate(request));
}
export { useReportPendingByDate };
