import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import externalApi from '../../api';
import { IStandingOrder } from '../../models';

type PendingStandingOrdersByDateRequest = {
  date: Date;
};

function pendingStandingOrdersByDate(
  request: PendingStandingOrdersByDateRequest
): Promise<IStandingOrder[]> {
  return externalApi.post(`StandingOrder/PendingByDate`, { json: request }).json();
}

function usePendingStandingOrdersByDate(request: PendingStandingOrdersByDateRequest) {
  // const queryClient = useQueryClient();
  // return useMutation(
  //   (request: PendingStandingOrdersByDateRequest) => pendingStandingOrdersByDate(request),
  //   {
  //     onSuccess: () => {
  //       queryClient.invalidateQueries(['PendingStandingOrdersByDate']);
  //     },
  //   }
  // );
  return useQuery(['PendingStandingOrdersByDate'], () => pendingStandingOrdersByDate(request));
}
export { usePendingStandingOrdersByDate };
