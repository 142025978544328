import {
  Box,
  Button,
  Center,
  createStyles,
  Group,
  Modal,
  Stack,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { useEffect, useState } from 'react';
import { Pencil, X } from 'tabler-icons-react';
import { useMediaQuery } from '@mantine/hooks';
import { IEquipmentType } from '../../../models';
import { useNewEquipmentType, useUserId } from '../../../hooks';

const useStyles = createStyles((theme) => ({
  label: {
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
    fontWeight: 500,
  },
  fieldGroup: {
    width: '48%',
  },
  detailLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
  },
  detail: {
    fontSize: theme.fontSizes.md,
    paddingLeft: 10,
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

type EquipmentTypeCreateModalProps = {
  opened: boolean;
  onClose: () => void;
  onCreate: (isCreated: boolean, newEquipment: IEquipmentType) => void;
};

const EquipmentTypeCreateModal = (props: EquipmentTypeCreateModalProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const initialEquipment = {
    equipmentTypeId: '',
    name: '',
    lastUpdatedUserId: '',
    lastUpdatedDateTime: new Date(),
  };

  const currentUserId = useUserId();

  const [isError, setIsError] = useState<boolean>(false);
  const [submitClicked, setSubmitClicked] = useState<boolean>(false);
  const [buttonsLocked, setButtonsLocked] = useState<boolean>(false);

  const newEquipmentType = useNewEquipmentType();

  const createForm = useForm<IEquipmentType>({
    initialValues: initialEquipment,
    validate: {
      name: (a) => (a.length > 50 ? 'Category Name must be a maximum of 50 characters' : null),
    },
  });

  async function submitEquipmentType() {
    setSubmitClicked(true);
    setIsError(false);

    newEquipmentType.mutate({
      equipmentTypeId: createForm.values.equipmentTypeId,
      name: createForm.values.name,
      lastUpdatedUserId: currentUserId.data!.userId,
    });
  }

  useEffect(() => {
    if (submitClicked) {
      if (newEquipmentType.isSuccess) {
        setSubmitClicked(false);
        props.onCreate(true, {
          equipmentTypeId: newEquipmentType.data.equipmentTypeId.toString(),
          name: createForm.values.name,
          lastUpdatedUserId: currentUserId.data!.userId,
          lastUpdatedDateTime: new Date(),
        });
        createForm.reset();
      } else if (newEquipmentType.isError) {
        setSubmitClicked(false);
        setIsError(true);
        setButtonsLocked(false);
      }
    }
  }, [newEquipmentType.status]);

  useEffect(() => {
    setButtonsLocked(false);
  }, [props.opened]);

  return (
    <Modal
      title={
        isError ? (
          <Text color="red">Something went wrong, Equipment not Added. Please try again</Text>
        ) : (
          ''
        )
      }
      opened={props.opened}
      size={isSmallMobile ? 'xs' : 'md'}
      onClose={() => {
        setIsError(false);
        createForm.reset();
        props.onClose();
      }}
    >
      <Box>
        <Center>
          <Text size="xl" weight={500} align="center">
            New Equipment Category Information
          </Text>
        </Center>
        <form
          onSubmit={createForm.onSubmit(() => {
            setIsError(false);
            setButtonsLocked(true);
            submitEquipmentType();
          })}
          autoComplete="off"
        >
          <Stack align="stretch" style={{ padding: 5 }}>
            <Stack align="stretch" spacing={0}>
              <Text className={classes.label}>Equipment Name</Text>
              <TextInput
                placeholder="Category Name"
                required
                {...createForm.getInputProps('name')}
              />
            </Stack>

            <Group position="apart" grow noWrap mt="xs">
              <Button variant="outline" disabled={buttonsLocked} type="submit">
                <Pencil style={{ marginRight: 5 }} />{' '}
                <Text className={classes.buttonText}>{`Create ${
                  !isSmallMobile ? 'Category' : ''
                }`}</Text>
              </Button>
              <Button
                variant="outline"
                color="red"
                onClick={() => {
                  setIsError(false);
                  createForm.reset();
                  props.onClose();
                }}
              >
                <X style={{ marginRight: 5 }} /> <Text className={classes.buttonText}>Cancel</Text>
              </Button>
            </Group>
          </Stack>
        </form>
      </Box>
    </Modal>
  );
};

export default EquipmentTypeCreateModal;
