import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type DeactivatePatientRequest = {
  patientId: string;
  lastUpdatedUserId: string;
};

type DeactivatePatientResponse = {
  patientId: number;
};

function deactivatePatient(request: DeactivatePatientRequest): Promise<DeactivatePatientResponse> {
  return externalApi.patch(`Patient/deactivate/`, { json: request }).json();
}

function useDeactivatePatient() {
  const queryClient = useQueryClient();
  return useMutation((request: DeactivatePatientRequest) => deactivatePatient(request), {
    onSuccess: () => {
      queryClient.invalidateQueries(['Patients']);
    },
  });
}
export { useDeactivatePatient };
