import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type EditStandingOrderRequest = {
  standingOrderId: string;
  weekdayId: string;
  isAMDelivery: boolean;
  liquid: boolean;
  notes: string;
  lastUpdatedUserId: string;
};

type EditStandingOrderResponse = {
  standingOrderId: number;
};

function editStandingOrder(request: EditStandingOrderRequest): Promise<EditStandingOrderResponse> {
  return externalApi.patch(`StandingOrder/patch`, { json: request }).json();
}

function useEditStandingOrder() {
  const queryClient = useQueryClient();
  return useMutation((request: EditStandingOrderRequest) => editStandingOrder(request), {
    onSuccess: () => {
      queryClient.invalidateQueries(['StandingOrdersByWeekday']);
    },
  });
}
export { useEditStandingOrder };
