import React, { Fragment, useState } from 'react';
import {
  AppShell,
  Box,
  Overlay,
  Stack,
  useMantineTheme,
  Text,
  Loader,
  createStyles,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import { NavbarSimple } from './NavbarSimple';
import { HeaderSimple } from './HeaderSimple';
import { useIsUser } from '../../hooks';
import { InvalidUserPage } from '../../pages';

const useStyles = createStyles((theme) => ({
  content: {
    height: '100%',
  },
}));

type Props = {
  children: React.ReactNode;
};

const Layout = (props: Props) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const collapsed = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const [open, toggleOpen] = useState(false);

  const isUser = useIsUser();

  return (
    <AppShell
      navbarOffsetBreakpoint="md"
      padding={0}
      fixed
      navbar={
        <NavbarSimple
          p="md"
          isOpen={open}
          setOpen={() => toggleOpen((a) => !a)}
          width={{ xs: 300 }}
        />
      }
      header={
        collapsed ? (
          <HeaderSimple isOpen={open} setOpen={() => toggleOpen((a) => !a)} height={60} />
        ) : (
          <Fragment />
        )
      }
    >
      {open && collapsed && (
        <Overlay
          opacity={0.5}
          color={theme.colors.dark[9]}
          sx={{ position: 'fixed', zIndex: 98 }}
        />
      )}
      <Box className={classes.content}>
        {!isUser.data && isUser.isLoading && (
          <Stack style={{ height: '100%' }} align="center" justify="center">
            <Loader size={84} />
            <Text size="lg" weight={500}>
              Loading...
            </Text>
          </Stack>
        )}
        {isUser.data?.isUser && props.children}
        {!isUser.data?.isUser && !isUser.isLoading && <InvalidUserPage />}
      </Box>
    </AppShell>
  );
};

export default Layout;
