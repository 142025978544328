import {
  ActionIcon,
  Box,
  Button,
  Center,
  Checkbox,
  createStyles,
  Group,
  Modal,
  Popover,
  ScrollArea,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React, { useEffect } from 'react';
import { Edit, FileInvoice, CalendarEvent } from 'tabler-icons-react';
import { useStandingOrderCancelledCount, useStandingOrderRescheduledCount } from '../../../hooks';
import { IStandingOrder } from '../../../models';
import { PatientNotes, InfoButton } from '../../../components';

const useStyles = createStyles((theme) => ({
  notesCard: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    paddingLeft: theme.spacing.sm,
    paddingRight: theme.spacing.sm,
    paddingTop: 3,
    width: '100%',
    height: '125px',
  },
  standingOrderBox: {
    color: `${theme.colors.gray[6]}`,
    border: `1px solid ${theme.colors.gray[6]}`,
    borderRadius: theme.radius.lg,
    width: '35%',
  },
  label: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
  },
  detail: {
    fontSize: theme.fontSizes.md,
    paddingLeft: 10,
  },
  fieldGroup: {
    width: '48%',
  },
  clickableIcon: {
    color: `${theme.colors.blue[6]}`,
    padding: '0 !important',
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

type StandingOrderDetailModalProps = {
  standingOrder: IStandingOrder;
  opened: boolean;
  isCreated: boolean;
  isUpdated: boolean;
  isDeleted: boolean;
  isError: boolean;
  isRescheduled: boolean;
  isCancelled: boolean;
  onClose: () => void;
  onEdit: () => void;
  onViewEquipment: () => void;
  onModifications: () => void;
};

const StandingOrderDetailModal = (props: StandingOrderDetailModalProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  let rescheduledCount = useStandingOrderRescheduledCount();
  let cancelledCount = useStandingOrderCancelledCount();

  useEffect(() => {
    if (props.standingOrder.standingOrderId) {
      rescheduledCount.mutate(props.standingOrder.standingOrderId);
      cancelledCount.mutate(props.standingOrder.standingOrderId);
    }
  }, [props.standingOrder, props.isRescheduled, props.isCancelled]);

  return (
    <Modal
      title={
        props.isUpdated ? (
          <Text color="green">Standing Order Successfully Updated!</Text>
        ) : props.isError ? (
          <Text color="red">Something went wrong, Standing Order not Updated</Text>
        ) : props.isCreated ? (
          <Text color="green">Standing Order Successfully Created!</Text>
        ) : props.isRescheduled ? (
          <Text color="green">Standing Order Successfully Rescheduled!</Text>
        ) : props.isCancelled ? (
          <Text color="green">Standing Order Successfully Suspended for Duration!</Text>
        ) : props.isDeleted ? (
          <Text color="green">This Standing Order has been Successfully Deleted!</Text>
        ) : (
          ''
        )
      }
      opened={props.opened}
      size={isSmallMobile ? 'xs' : 'md'}
      onClose={() => {
        props.onClose();
      }}
    >
      <Box>
        <Center>
          <Text size="xl" weight={500} align="center">
            Complete Standing Order Information
          </Text>
          <InfoButton
            userId={props.standingOrder.lastUpdatedUserId}
            date={props.standingOrder.lastUpdated}
            ml={5}
          />
        </Center>
        <Center>
          <Group
            className={classes.standingOrderBox}
            position="center"
            spacing="xs"
            my={5}
            mx={5}
            noWrap
          >
            {rescheduledCount.data && (
              <Text weight={500} size="xs">
                {`Rescheduled: ${rescheduledCount.data.count}`}
              </Text>
            )}
          </Group>
          <Group
            className={classes.standingOrderBox}
            position="center"
            spacing="xs"
            my={5}
            mx={5}
            noWrap
          >
            {cancelledCount.data && (
              <Text weight={500} size="xs">
                {`Suspended: ${cancelledCount.data.count}`}
              </Text>
            )}
          </Group>
          <ActionIcon
            className={classes.clickableIcon}
            onClick={() => {
              props.onModifications();
            }}
          >
            <CalendarEvent />
          </ActionIcon>
        </Center>

        <Stack align="stretch" style={{ padding: 5 }}>
          <Group>
            <Stack spacing={0} style={{ width: '50%' }}>
              <Text className={classes.label}>Patient Name</Text>
              <Group spacing={5} noWrap>
                <Text
                  className={classes.detail}
                >{`${props.standingOrder.patientLastName}, ${props.standingOrder.patientFirstName}`}</Text>
              </Group>
            </Stack>
            <Stack spacing={0}>
              <Text className={classes.label}>Phone number</Text>
              <Text className={classes.detail}>{`${props.standingOrder.patientPhoneNumber}`}</Text>
            </Stack>
          </Group>
          <Group>
            <Stack spacing={0} style={{ width: '50%' }}>
              <Text className={classes.label}>Delivery Address</Text>
              <Text className={classes.detail}>{`${props.standingOrder.patientAddress}`}</Text>
            </Stack>
            <Stack spacing={0}>
              <Text className={classes.label}>City</Text>
              <Text className={classes.detail}>{`${props.standingOrder.patientCity}`}</Text>
            </Stack>
          </Group>
          <PatientNotes notes={props.standingOrder.patientNotes} />

          <Group>
            <Stack spacing={0} style={{ width: '50%' }}>
              <Text className={classes.label}>Delivery Weekday</Text>
              <Text className={classes.detail}>{`${props.standingOrder.weekdayName}`}</Text>
            </Stack>
            <Stack spacing={0}>
              <Text className={classes.label}>Delivery Time</Text>
              <Text className={classes.detail}>{`${
                props.standingOrder.isAMDelivery ? 'AM' : 'PM'
              }`}</Text>
            </Stack>
          </Group>

          <Group spacing="xs" pl="xs">
            <Checkbox checked={props.standingOrder.liquid} readOnly />
            <Text className={classes.label}>Liquid</Text>
          </Group>

          <Stack spacing={0}>
            <Text className={classes.label}>Notes</Text>
            <ScrollArea className={classes.notesCard}>
              <Text>{`${props.standingOrder.notes}`}</Text>
            </ScrollArea>
          </Stack>
        </Stack>

        {!props.isDeleted && (
          <Group position="apart" grow noWrap mt="md">
            <Button
              variant="outline"
              onClick={() => {
                props.onEdit();
              }}
            >
              <Edit style={{ marginRight: 5 }} />{' '}
              <Text className={classes.buttonText}>Edit Order</Text>
            </Button>
            <Button
              variant="outline"
              onClick={() => {
                props.onViewEquipment();
              }}
            >
              <FileInvoice style={{ marginRight: 5 }} />{' '}
              <Text className={classes.buttonText}>{`${
                !isSmallMobile ? 'Order' : ''
              } Equipment`}</Text>
            </Button>
          </Group>
        )}
      </Box>
    </Modal>
  );
};

export default StandingOrderDetailModal;
