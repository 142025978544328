import {
  Box,
  Button,
  Center,
  Checkbox,
  createStyles,
  Group,
  Modal,
  Stack,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { useForm } from '@mantine/form';
import { useEffect, useState } from 'react';
import { Pencil, X } from 'tabler-icons-react';
import { IUser } from '../../../models';
import { useEditUser, useUserTypes } from '../../../hooks';

const useStyles = createStyles((theme) => ({
  label: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
  },
  detail: {
    fontSize: theme.fontSizes.md,
    paddingLeft: 10,
  },
  fieldGroup: {
    width: '48%',
  },
  checkFieldGroup: {
    width: 80,
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      '&:last-of-type': {
        marginTop: 5,
      },
      '&:nth-last-of-type(2n)': {
        marginTop: 5,
      },
    },
    [`@media (min-width: ${theme.breakpoints.sm})`]: {
      '&:first-of-type': {
        marginRight: 25,
      },
    },
  },
  notesCard: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    paddingLeft: theme.spacing.sm,
    paddingRight: theme.spacing.sm,
    paddingTop: 3,
    width: '100%',
    height: '125px',
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

type UserEditModalProps = {
  user: IUser;
  opened: boolean;
  onClose: () => void;
  onEdited: (isUpdated: boolean, initialUser: IUser) => void;
};

const UserEditModal = (props: UserEditModalProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const [userTypeIds, setUserTypeIds] = useState<string[]>(
    props.user.userTypeIds.map((a) => a.toString())
  );

  const [submitClicked, setSubmitClicked] = useState<boolean>(false);
  const initialUser = {
    userId: props.user.userId,
    userFirstName: props.user.userFirstName,
    userLastName: props.user.userLastName,
    userFullName: props.user.userFullName,
    userFullNameFormatted: props.user.userFullNameFormatted,
    userEmailAddress: props.user.userEmailAddress,
    userTypeName: props.user.userTypeName,
    userTypeIds: props.user.userTypeIds,
    isAdmin: props.user.isAdmin,
  };

  const userTypes = useUserTypes();
  const editUser = useEditUser();

  const userTypeCheckboxes = (userTypes.data ?? []).map((a) => (
    <Checkbox
      key={a.userTypeId}
      value={a.userTypeId.toString()}
      label={a.userTypeName}
      mb={`calc(${theme.spacing.xs} /2 )`}
    />
  ));

  const editForm = useForm<IUser>({
    initialValues: initialUser,
    validate: {
      userFirstName: (a) =>
        a.length > 75 ? "User's First Name must be a maximum of 75 characters" : null,
      userLastName: (a) =>
        a.length > 75 ? "User's Last Name must be a maximum of 75 characters" : null,
      userEmailAddress: (a) =>
        /^\S+@\S+$/.test(a) ? null : 'Email Address (Login) must be a valid Email Address',
    },
  });

  async function submitUserUpdate() {
    setSubmitClicked(true);

    editUser.mutate({
      userId: editForm.values.userId,
      userFirstName: editForm.values.userFirstName,
      userLastName: editForm.values.userLastName,
      userEmailAddress: editForm.values.userEmailAddress,
      userTypeIds: userTypeIds,
    });
  }

  useEffect(() => {
    editForm.setValues(props.user);
    setUserTypeIds(props.user.userTypeIds.map((a) => a.toString()));
  }, [props.user, props.opened]);

  useEffect(() => {
    if (submitClicked) {
      if (editUser.isSuccess) {
        setSubmitClicked(false);
        props.onEdited(false, {
          userId: props.user.userId,
          userFirstName: editForm.values.userFirstName,
          userLastName: editForm.values.userLastName,
          userFullName: `${editForm.values.userFirstName} ${editForm.values.userLastName}`,
          userFullNameFormatted: ` ${editForm.values.userLastName}, ${editForm.values.userFirstName}`,
          userEmailAddress: editForm.values.userEmailAddress,
          userTypeName: props.user.userTypeName,
          userTypeIds: userTypeIds,
          isAdmin: props.user.isAdmin,
        });
      }
      if (editUser.isError) {
        setSubmitClicked(false);
        props.onEdited(true, initialUser);
      }
    }
  }, [editUser.status]);

  return (
    <Modal
      opened={props.opened}
      size={isSmallMobile ? 'xs' : 'md'}
      onClose={() => {
        props.onClose();
      }}
    >
      <Box>
        <Center>
          <Group noWrap>
            <Text size="xl" weight={500} align="center">
              Edit Patient Information
            </Text>
          </Group>
        </Center>
        <form onSubmit={editForm.onSubmit(() => submitUserUpdate())} autoComplete="off">
          <Stack align="stretch" spacing={5} style={{ padding: 5 }}>
            <Group noWrap>
              <Stack spacing={0} className={classes.fieldGroup}>
                <Text className={classes.label}>User Last Name</Text>
                <TextInput
                  placeholder={!isSmallMobile ? "User's Last Name" : 'Last Name'}
                  required
                  autoComplete="nope"
                  {...editForm.getInputProps('userLastName')}
                />
              </Stack>
              <Stack spacing={0} className={classes.fieldGroup}>
                <Text className={classes.label}>User First Name</Text>
                <TextInput
                  placeholder={!isSmallMobile ? "User's First Name" : 'First Name'}
                  required
                  autoComplete="nope"
                  {...editForm.getInputProps('userFirstName')}
                />
              </Stack>
            </Group>

            <Stack spacing={0} className={classes.fieldGroup}>
              <Text className={classes.label}>Email Address (Login)</Text>
              <TextInput
                placeholder={!isSmallMobile ? 'Email Address' : 'Email'}
                required
                {...editForm.getInputProps('userEmailAddress')}
              />
            </Stack>

            <Stack spacing={0} className={classes.fieldGroup}>
              <Text className={classes.label}>User Type</Text>
              <Checkbox.Group value={userTypeIds} onChange={setUserTypeIds}>
                {userTypeCheckboxes}
              </Checkbox.Group>
            </Stack>
          </Stack>

          <Group position="apart" grow noWrap mt="md">
            <Button variant="outline" type="submit">
              <Pencil style={{ marginRight: 5 }} />{' '}
              <Text className={classes.buttonText}>{`Update ${!isSmallMobile ? 'User' : ''}`}</Text>
            </Button>
            <Button
              variant="outline"
              color="red"
              onClick={() => {
                props.onClose();
              }}
            >
              <X style={{ marginRight: 5 }} /> <Text className={classes.buttonText}>Cancel</Text>
            </Button>
          </Group>
        </form>
      </Box>
    </Modal>
  );
};

export default UserEditModal;
