import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type EditCityRequest = {
  cityId: string;
  name: string;
};

type EditCityResponse = {
  cityId: number;
};

function editCity(request: EditCityRequest): Promise<EditCityResponse> {
  return externalApi.put(`City/put`, { json: request }).json();
}

function useEditCity() {
  const queryClient = useQueryClient();
  return useMutation((request: EditCityRequest) => editCity(request), {
    onSuccess: () => {
      queryClient.invalidateQueries(['Cities']);
    },
  });
}
export { useEditCity };
