import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { IOrderFilter, ITrackedEquipmentFilter } from '../../models';

type OrdersSubPage = 'orders' | 'standingOrders';
type EquipmentSubPage = 'trackedEquipment' | 'equipment' | 'equipmentTypes';
type HelpSubPage = 'patients' | 'orders' | 'equipment' | 'deliverySchedule' | 'reports' | undefined;

type PageState = {
  ordersSubPage: OrdersSubPage;
  equipmentSubPage: EquipmentSubPage;
  helpSubPage: HelpSubPage;
  patientsSelectedKeys: string[];
  patientsFilterValues: string[];
  onDemandOrdersFilterValues: IOrderFilter[];
  standingOrdersWeekdayValue: string;
  trackedEquipmentFilterValue: ITrackedEquipmentFilter;
  deliveryScheduleDate: Date;
  deliveryScheduleFilterValues: string[];

  setOrdersSubPage: (selectedSubPage: string) => void;
  setEquipmentSubPage: (selectedSubPage: string) => void;
  setHelpSubPage: (selectedSubPage: string | undefined) => void;
  setPatientsSelectedKeys: (selectedKeys: string[]) => void;
  setPatientsFilterValues: (filterValues: string[]) => void;
  setOnDemandOrdersFilterValues: (filterValues: IOrderFilter[]) => void;
  setStandingOrdersWeekdayValue: (weekdayValue: string) => void;
  setTrackedEquipmentFilterValue: (filterValue: ITrackedEquipmentFilter) => void;
  setDeliveryScheduleDate: (selectedDate: Date) => void;
  setDeliveryScheduleFilterValues: (filterValues: string[]) => void;
};

export const usePageStore = create(
  persist<PageState>(
    (set) => ({
      ordersSubPage: 'orders',
      equipmentSubPage: 'trackedEquipment',
      helpSubPage: undefined,
      patientsSelectedKeys: [],
      patientsFilterValues: ['active'],
      onDemandOrdersFilterValues: [IOrderFilter.Unfulfilled],
      standingOrdersWeekdayValue: '0',
      trackedEquipmentFilterValue: 0,
      deliveryScheduleDate: new Date(),
      deliveryScheduleFilterValues: ['pending', 'urgent', 'delivered', 'undelivered', 'incomplete'],

      setOrdersSubPage: (selectedSubPage: string) => {
        set(() => ({
          ordersSubPage: selectedSubPage as OrdersSubPage,
        }));
      },
      setEquipmentSubPage: (selectedSubPage: string) => {
        set(() => ({
          equipmentSubPage: selectedSubPage as EquipmentSubPage,
        }));
      },
      setHelpSubPage: (selectedSubPage: string | undefined) => {
        set(() => ({
          helpSubPage: selectedSubPage as HelpSubPage,
        }));
      },
      setPatientsSelectedKeys: (selectedKeys: string[]) => {
        set(() => ({
          patientsSelectedKeys: selectedKeys,
        }));
      },
      setPatientsFilterValues: (filterValues: string[]) => {
        set(() => ({
          patientsFilterValues: filterValues,
        }));
      },
      setOnDemandOrdersFilterValues: (filterValues: IOrderFilter[]) => {
        set(() => ({
          onDemandOrdersFilterValues: filterValues,
        }));
      },
      setStandingOrdersWeekdayValue: (weekdayValue: string) => {
        set(() => ({
          standingOrdersWeekdayValue: weekdayValue,
        }));
      },
      setTrackedEquipmentFilterValue: (filterValue: ITrackedEquipmentFilter) => {
        set(() => ({
          trackedEquipmentFilterValue: filterValue,
        }));
      },
      setDeliveryScheduleDate: (selectedDate: Date) => {
        set(() => ({
          deliveryScheduleDate: selectedDate,
        }));
      },
      setDeliveryScheduleFilterValues: (filterValues: string[]) => {
        set(() => ({
          deliveryScheduleFilterValues: filterValues,
        }));
      },
    }),
    {
      name: 'odm-page-store',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
