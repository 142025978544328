import {
  Box,
  useMantineTheme,
  createStyles,
  Text,
  Grid,
  Group,
  ScrollArea,
  Checkbox,
  Divider,
  Stack,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React, { useEffect } from 'react';
import { useDeliveryEquipmentByDeliveryMutate } from '../../hooks';

const useStyles = createStyles((theme, _params) => ({
  bodyLabel: {
    fontWeight: 500,
  },
  notesBox: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    padding: 5,
    height: 50,
    width: '100%',
  },
}));

type DeliveriesReportDeliveredItemProps = {
  deliveryId: string;
  orderId: string;
  standingOrderId: string;
  patientFirstName: string;
  patientLastName: string;
  patientAddress: string;
  patientCityName: string;
  patientPhoneNumber: string;
  requestedDeliveryDate: string;
  requestedDeliveryTimeName: string;
  isStandingOrder: boolean;
  isRescheduledStandingOrder: boolean;
  isLiquid: boolean;
  orderNotes: string;
  deliveredAt: string;
  deliveryNotes: string;
};

const DeliveriesReportDeliveredItem = (props: DeliveriesReportDeliveredItemProps) => {
  const { classes, cx } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const deliveryEquipment = useDeliveryEquipmentByDeliveryMutate();

  useEffect(() => {
    deliveryEquipment.mutate(props.deliveryId);
  }, []);

  const deliveryEquipmentItems = (deliveryEquipment.data ?? []).map((a) => {
    return (
      <Grid key={a.deliveryEquipmentId}>
        <Grid.Col span={1}>
          <Text size="xs" align="center" ml={1}>
            {`x${a.quantity}`}
          </Text>
        </Grid.Col>
        <Grid.Col span={3}>
          <Text size="xs" align="center">
            {a.equipmentName}
          </Text>
        </Grid.Col>
        <Grid.Col span={3}>
          <Text size="xs" align="center">
            {a.serialNumber ?? 'N/A'}
          </Text>
        </Grid.Col>
        <Grid.Col span={5}>
          <Text size="xs" align="center">
            {a.equipmentTypeName}
          </Text>
        </Grid.Col>
      </Grid>
    );
  });

  return (
    <Box style={{ height: 190, overflow: 'hidden' }}>
      <Grid mb={10}>
        <Grid.Col span={4}>
          <Stack align="right" spacing={0} mr={30} ml={15}>
            <Text size="md" align="right">
              {`${props.patientLastName}, ${props.patientFirstName}`}
            </Text>
            <Text size="md" align="right">
              {`${props.patientAddress}, ${props.patientCityName}`}
            </Text>
            <Text size="md" align="right">
              {props.patientPhoneNumber}
            </Text>
            <Stack align="right" spacing={0}>
              <Text size="md" align="left" className={classes.bodyLabel} ml={5}>
                Delivery Notes:
              </Text>
              <ScrollArea className={classes.notesBox}>
                <Text size="md">{props.deliveryNotes}</Text>
              </ScrollArea>
            </Stack>
          </Stack>
        </Grid.Col>
        <Grid.Col span={4}>
          <Stack align="stretch" spacing={0}>
            <Group position="left">
              <Text
                size="md"
                className={classes.bodyLabel}
                style={{ width: 200, textAlign: 'right' }}
              >
                Requested Delivery Date:
              </Text>
              <Text size="md">{`${props.requestedDeliveryDate} (${props.requestedDeliveryTimeName})`}</Text>
            </Group>
            <Group position="left" spacing={0}>
              <Text
                size="md"
                className={classes.bodyLabel}
                style={{ width: 200, textAlign: 'right' }}
              >
                Standing Order:
              </Text>
              {props.isStandingOrder && (
                <Checkbox
                  checked
                  readOnly
                  styles={{
                    input: {
                      backgroundColor: `${theme.colors.gray[5]} !important`,
                      border: `1px solid ${theme.colors.gray[5]} !important`,
                    },
                  }}
                  ml={16}
                />
              )}
              {!props.isStandingOrder && !props.isRescheduledStandingOrder && (
                <Checkbox
                  checked={false}
                  readOnly
                  styles={{
                    input: {
                      backgroundColor: `${theme.colorScheme} !important`,
                      border: `1px solid ${theme.colors.gray[5]} !important`,
                    },
                  }}
                  ml={16}
                />
              )}
              {props.isRescheduledStandingOrder && (
                <Group position="left" spacing={0}>
                  <Checkbox
                    checked
                    readOnly
                    styles={{
                      input: {
                        backgroundColor: `${theme.colors.gray[5]} !important`,
                        border: `1px solid ${theme.colors.gray[5]} !important`,
                      },
                    }}
                    ml={16}
                  />
                  <Text size="md" ml={5}>
                    <sup>*Rescheduled</sup>
                  </Text>
                </Group>
              )}
            </Group>
            <Group position="left">
              <Text
                size="md"
                className={classes.bodyLabel}
                style={{ width: 200, textAlign: 'right' }}
              >
                Liquid:
              </Text>
              {props.isLiquid && (
                <Checkbox
                  checked
                  readOnly
                  styles={{
                    input: {
                      backgroundColor: `${theme.colors.gray[5]} !important`,
                      border: `1px solid ${theme.colors.gray[5]} !important`,
                    },
                  }}
                />
              )}
              {!props.isLiquid && (
                <Checkbox
                  checked={false}
                  readOnly
                  styles={{
                    input: {
                      backgroundColor: `${theme.colorScheme} !important`,
                      border: `1px solid ${theme.colors.gray[5]} !important`,
                    },
                  }}
                />
              )}
            </Group>
            <Stack align="stretch" spacing={0} px={20}>
              <Text size="md" align="left" className={classes.bodyLabel} ml={5}>
                Order Notes:
              </Text>
              <ScrollArea className={classes.notesBox}>
                <Text size="md">{props.orderNotes}</Text>
              </ScrollArea>
            </Stack>
          </Stack>
        </Grid.Col>
        <Grid.Col span={4}>
          <Group position="left" spacing={5}>
            <Text size="md" className={classes.bodyLabel}>
              Delivered At:
            </Text>
            <Text size="md">{props.deliveredAt}</Text>
          </Group>
          <Divider />
          <Stack align="stretch" spacing={0} px={0} ml={5}>
            <Text
              size="md"
              weight={500}
              align="center"
              style={{ borderBottom: `1px solid ${theme.colors.gray[4]}` }}
            >
              Equipment Delivered
            </Text>
            <Box
              style={{
                borderBottom: `1px solid ${theme.colors.gray[4]}`,
                backgroundColor: theme.colors.gray[4],
              }}
              mb={15}
            >
              <Grid>
                <Grid.Col span={1} style={{ height: 17 }}>
                  <Text size="xs" weight={500} align="center">
                    #
                  </Text>
                </Grid.Col>
                <Grid.Col span={3} style={{ height: 17 }}>
                  <Text size="xs" weight={500} align="center">
                    Name
                  </Text>
                </Grid.Col>
                <Grid.Col span={3} style={{ height: 17 }}>
                  <Text size="xs" weight={500} align="center">
                    Serial #
                  </Text>
                </Grid.Col>
                <Grid.Col span={5} style={{ height: 17 }}>
                  <Text size="xs" weight={500} align="center">
                    Type
                  </Text>
                </Grid.Col>
              </Grid>
            </Box>
            <Stack spacing={0} align="stretch">
              {props.deliveryId && deliveryEquipmentItems}
              {!deliveryEquipmentItems[0] && (
                <Text size="xs" align="center">
                  <i>No Equipment Delivered</i>
                </Text>
              )}
            </Stack>
          </Stack>
        </Grid.Col>
      </Grid>
      <Divider mb={10} />
    </Box>
  );
};

export default DeliveriesReportDeliveredItem;
