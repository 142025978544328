import { createStyles, Modal, useMantineTheme, Text } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React, { useEffect, useState } from 'react';
import { useDelivery } from '../../../hooks';
import {
  DeliveryDetailModalContent,
  DeliveryOrderDetailModalContent,
  DeliveryStandingOrderDetailModalContent,
} from '../../../components';

const useStyles = createStyles((theme) => ({
  label: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
  },
  detail: {
    fontSize: theme.fontSizes.md,
    paddingLeft: 10,
  },
  fieldGroup: {
    width: '48%',
  },
  notesCard: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    paddingLeft: theme.spacing.sm,
    paddingRight: theme.spacing.sm,
    paddingTop: 3,
    width: '100%',
    height: '125px',
  },
}));

type DeliveryDetailModalProps = {
  selectedDate: Date;
  recordId: string;
  recordType: 'delivery' | 'order' | 'standingOrder' | undefined;
  flashMessage?: string;
  opened: boolean;
  onClose: () => void;
  onViewEquipment: () => void;
  onDeliveryEdit: () => void;
  onDeliveryConvert: () => void;
  onOrderDelivered: () => void;
  onOrderUndelivered: () => void;
  onStandingOrderDelivered: () => void;
  onStandingOrderUndelivered: () => void;
  onUndeliveredReschedule: () => void;
  onIncompleteReschedule: () => void;
};

const DeliveryDetailModal = (props: DeliveryDetailModalProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const [isClosing, setIsClosing] = useState<boolean>(false);

  const delivery = useDelivery();

  const setBorderStyle = () => {
    let borderStyle: string = '';

    if (props.recordType === 'delivery' && !isClosing && delivery.data) {
      if (delivery.data.hasUndeliveredEquipment) {
        borderStyle = `3px solid ${theme.colors.orange[4]}`;
      } else if (delivery.data.isDelivered) {
        borderStyle = `3px solid ${theme.colors.blue[6]}`;
      } else {
        borderStyle = `3px solid ${theme.colors.red[5]}`;
      }

      return borderStyle;
    }
  };

  useEffect(() => {
    setIsClosing(false);
  }, [props.opened]);

  useEffect(() => {
    if (props.recordType === 'delivery') {
      delivery.mutate(props.recordId);
    }
  }, [props.recordId, props.opened]);

  return (
    <Modal
      title={<Text color="green">{props.flashMessage ?? ''}</Text>}
      opened={props.opened}
      size={isSmallMobile ? 'xs' : 'md'}
      onClose={() => {
        setIsClosing(true);
        props.onClose();
      }}
      styles={{
        content: {
          border: setBorderStyle(),
        },
      }}
    >
      {props.recordType === 'delivery' && (
        <DeliveryDetailModalContent
          selectedDate={props.selectedDate}
          deliveryId={props.recordId}
          onClose={() => {
            props.onClose();
          }}
          onViewEquipment={() => {
            props.onViewEquipment();
          }}
          onEdit={() => {
            props.onDeliveryEdit();
          }}
          onConvert={() => {
            props.onDeliveryConvert();
          }}
          onRescheduleUndelivered={() => {
            props.onUndeliveredReschedule();
          }}
          onRescheduleIncomplete={() => {
            props.onIncompleteReschedule();
          }}
        />
      )}
      {props.recordType === 'order' && (
        <DeliveryOrderDetailModalContent
          selectedDate={props.selectedDate}
          orderId={props.recordId}
          onClose={() => {
            props.onClose();
          }}
          onViewEquipment={() => {
            props.onViewEquipment();
          }}
          onDelivered={() => {
            props.onOrderDelivered();
          }}
          onUndelivered={() => {
            props.onOrderUndelivered();
          }}
        />
      )}
      {props.recordType === 'standingOrder' && (
        <DeliveryStandingOrderDetailModalContent
          selectedDate={props.selectedDate}
          standingOrderId={props.recordId}
          onClose={() => {
            props.onClose();
          }}
          onViewEquipment={() => {
            props.onViewEquipment();
          }}
          onDelivered={() => {
            props.onStandingOrderDelivered();
          }}
          onUndelivered={() => {
            props.onStandingOrderUndelivered();
          }}
        />
      )}
    </Modal>
  );
};

export default DeliveryDetailModal;
