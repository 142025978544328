import React from 'react';
import { useQuery } from 'react-query';
import externalApi from '../../api';
import { IStandingOrder } from '../../models';

function fetchStandingOrdersByWeekday(weekdayId: string): Promise<IStandingOrder[]> {
  return externalApi.get(`StandingOrder/ByWeekday/${weekdayId}`).json();
}

export function useStandingOrdersByWeekday(weekdayId: string) {
  return useQuery(['StandingOrdersByWeekday'], () => fetchStandingOrdersByWeekday(weekdayId));
}
