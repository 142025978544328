import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import externalApi from '../../api';
import { IFundingType, ISelectData } from '../../models';

function fetchFundingTypes(): Promise<IFundingType[]> {
  return externalApi.get(`FundingType/all`).json();
}

export function useFundingTypes() {
  return useQuery(['FundingTypes'], () => fetchFundingTypes());
}
