import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type NewTechnicianUserRequest = {
  userFirstName: string;
  userLastName: string;
  userEmailAddress: string;
};

type NewTechnicianUserResponse = {
  userId: number;
};

function newUser(request: NewTechnicianUserRequest): Promise<NewTechnicianUserResponse> {
  return externalApi.post(`User/new/Technician`, { json: request }).json();
}

function useNewTechnicianUser() {
  const queryClient = useQueryClient();
  return useMutation((request: NewTechnicianUserRequest) => newUser(request), {
    onSuccess: () => {
      queryClient.invalidateQueries(['Users']);
    },
  });
}
export { useNewTechnicianUser };
