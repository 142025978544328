import React from 'react';
import { useQuery } from 'react-query';
import externalApi from '../../../api';

type ReportUndeliveredByDateRequest = {
  date: Date;
  isAMTimeframe: boolean;
  isFullDayTimeframe: boolean;
};

type ReportUndeliveredByDateResponse = {
  deliveryId: string;
  orderId: string;
  standingOrderId: string;
  requestedDeliveryDate: Date;
  isAMRequestedDelivery: boolean;
  patientFirstName: string;
  patientLastName: string;
  deliveryAddress: string;
  deliveryCity: string;
  patientPhoneNumber: string;
  isStandingOrder: boolean;
  isStandingOrderReschedule: boolean;
  liquid: boolean;
  deliveryNotes: string;
  orderNotes: string;
};

function reportReportUndeliveredByDate(
  request: ReportUndeliveredByDateRequest
): Promise<ReportUndeliveredByDateResponse[]> {
  return externalApi.post(`Delivery/Report/ByDate/Undelivered`, { json: request }).json();
}

function useReportUndeliveredByDate(request: ReportUndeliveredByDateRequest) {
  return useQuery(['ReportUndeliveredByDate'], () => reportReportUndeliveredByDate(request));
}
export { useReportUndeliveredByDate };
