import {
  Box,
  Button,
  Center,
  createStyles,
  Divider,
  Group,
  Modal,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { DatePicker, DatesRangeValue } from '@mantine/dates';
import React, { useEffect, useState } from 'react';
import { CalendarTime, X } from 'tabler-icons-react';
import { useMediaQuery } from '@mantine/hooks';
import { IStandingOrderCancellation, IStandingOrder } from '../../../models';
import { useNewStandingOrderCancellation, useUserId } from '../../../hooks';

const useStyles = createStyles((theme) => ({
  label: {
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
    fontWeight: 500,
  },
  fieldGroup: {
    width: '48%',
  },
  detailLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
  },
  detail: {
    fontSize: theme.fontSizes.md,
    paddingLeft: 10,
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

type OrderEditModalProps = {
  standingOrder: IStandingOrder;
  opened: boolean;
  onClose: () => void;
  onCancellation: (isCancelled: boolean, isError: boolean) => void;
};

const StandingOrderCancellationModal = (props: OrderEditModalProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const currentUserId = useUserId();

  const today: Date = new Date();
  const rangeEnd: Date = new Date();
  const defaultDateRange: DatesRangeValue = [
    new Date(),
    new Date(rangeEnd.setDate(today.getDate() + 7)),
  ];
  const [dateRange, setDateRange] = useState<DatesRangeValue>(defaultDateRange);
  const initialCancellation = {
    standingOrderCancellationId: '',
    standingOrderId: props.standingOrder.standingOrderId,
    startDate: new Date(),
    endDate: new Date(today.setDate(today.getDate() + 7)),
    lastUpdatedUserId: '',
    lastUpdatedDateTime: new Date(),
  };

  const [submitClicked, setSubmitClicked] = useState<boolean>(false);

  const newStandingOrderCancellation = useNewStandingOrderCancellation();

  const cancellationForm = useForm<IStandingOrderCancellation>({
    initialValues: initialCancellation,
    validate: {},
  });

  async function submitOrderUpdate() {
    setSubmitClicked(true);

    newStandingOrderCancellation.mutate({
      standingOrderId: props.standingOrder.standingOrderId,
      startDate: dateRange[0] ?? new Date(),
      endDate: dateRange[1] ?? new Date(),
      lastUpdatedUserId: currentUserId.data!.userId,
    });
  }

  useEffect(() => {
    cancellationForm.setValues({
      standingOrderCancellationId: '',
      standingOrderId: props.standingOrder.standingOrderId,
      startDate: new Date(),
      endDate: new Date(),
      lastUpdatedUserId: '',
      lastUpdatedDateTime: new Date(),
    });
  }, [props.standingOrder]);

  useEffect(() => {
    if (submitClicked) {
      if (newStandingOrderCancellation.isSuccess) {
        setSubmitClicked(false);
        setDateRange(defaultDateRange);
        props.onCancellation(true, false);
      } else if (newStandingOrderCancellation.isError) {
        setSubmitClicked(false);
        props.onCancellation(false, true);
      }
    }
  }, [newStandingOrderCancellation.status]);

  return (
    <Modal
      opened={props.opened}
      size={isSmallMobile ? 'xs' : 'md'}
      onClose={() => {
        setDateRange(defaultDateRange);
        props.onClose();
      }}
    >
      <Box>
        <Center>
          <Text size="xl" weight={500} align="center">
            Set Standing Order Suspension
          </Text>
        </Center>
        <form onSubmit={cancellationForm.onSubmit(() => submitOrderUpdate())} autoComplete="off">
          <Stack align="stretch" style={{ padding: 5 }}>
            <Stack spacing={0} style={{ width: '50%' }}>
              <Text className={classes.detailLabel}>Patient Name</Text>
              <Group spacing={5} noWrap>
                <Text className={classes.detail}>
                  {props.standingOrder.patientFullNameFormatted}
                </Text>
              </Group>
            </Stack>
            <Group>
              <Stack spacing={0} style={{ width: '50%' }}>
                <Text className={classes.detailLabel}>Delivery Address</Text>
                <Text className={classes.detail}>{`${props.standingOrder.patientAddress}`}</Text>
              </Stack>
              <Stack spacing={0}>
                <Text className={classes.detailLabel}>City</Text>
                <Text className={classes.detail}>{`${props.standingOrder.patientCity}`}</Text>
              </Stack>
            </Group>

            <Group>
              <Stack spacing={0} style={{ width: '50%' }}>
                <Text className={classes.detailLabel}>Delivery Weekday</Text>
                <Text className={classes.detail}>{`${props.standingOrder.weekdayName}`}</Text>
              </Stack>
              <Stack spacing={0}>
                <Text className={classes.detailLabel}>Delivery Time</Text>
                <Text className={classes.detail}>{`${
                  props.standingOrder.isAMDelivery ? 'AM' : 'PM'
                }`}</Text>
              </Stack>
            </Group>

            <Divider />
            <Stack spacing={0}>
              <Text weight={200} size="md" align="center">
                Select a Date Range for the Standing Order to be Suspended
              </Text>
              <Text size="xs" align="center">
                (Default is the next 7 days)
              </Text>
              <Center>
                <DatePicker
                  firstDayOfWeek={0}
                  type="range"
                  value={dateRange}
                  onChange={(e) => {
                    setDateRange(e);
                  }}
                  renderDay={(a) => {
                    const testDate: Date = new Date();
                    const clearDate: Date = new Date(
                      testDate.setDate((dateRange[1] ?? new Date()).getDate() + 1)
                    );
                    if (a.getDate() === clearDate.getDate()) {
                      return (
                        <Center style={{ height: '100%', width: '100%', background: 'white' }}>
                          <Text mt={-2}>{a.getDate()}</Text>
                        </Center>
                      );
                    }
                  }}
                />
              </Center>
            </Stack>
            <Divider />

            <Group position="apart" grow noWrap mt="xs">
              <Button variant="outline" type="submit" px={10}>
                <CalendarTime style={{ marginRight: 5 }} />{' '}
                <Text className={classes.buttonText}>{`Suspend ${
                  !isSmallMobile ? 'Duration' : ''
                }`}</Text>
              </Button>
              <Button
                variant="outline"
                color="red"
                onClick={() => {
                  setDateRange(defaultDateRange);
                  props.onClose();
                }}
              >
                <X style={{ marginRight: 5 }} /> <Text className={classes.buttonText}>Cancel</Text>
              </Button>
            </Group>
          </Stack>
        </form>
      </Box>
    </Modal>
  );
};

export default StandingOrderCancellationModal;
