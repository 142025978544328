import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type NewOrderEquipmentRequest = {
  orderId: string;
  equipmentId: string;
  quantity: number;
  lastUpdatedUserId: string;
};

type NewOrderEquipmentResponse = {
  orderEquipmentId: number;
};

function newOrderEquipment(request: NewOrderEquipmentRequest): Promise<NewOrderEquipmentResponse> {
  return externalApi.post(`OrderEquipment/new`, { json: request }).json();
}

function useNewOrderEquipment() {
  const queryClient = useQueryClient();
  return useMutation((request: NewOrderEquipmentRequest) => newOrderEquipment(request), {
    onSuccess: () => {
      queryClient.invalidateQueries(['OrderEquipmentByOrder']);
    },
  });
}
export { useNewOrderEquipment };
