import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type RescheduleStandingOrderRequest = {
  patientId: string;
  isAMDelivery: boolean;
  requestedDeliveryDate: Date;
  liquid: boolean;
  notes: string;
  standingOrderId: string;
};

type RescheduleStandingOrderResponse = {
  orderId: number;
};

function rescheduleStandingOrder(
  request: RescheduleStandingOrderRequest
): Promise<RescheduleStandingOrderResponse> {
  return externalApi.post(`Order/RescheduleStandingOrder`, { json: request }).json();
}

function useRescheduleStandingOrder() {
  const queryClient = useQueryClient();
  return useMutation(
    (request: RescheduleStandingOrderRequest) => rescheduleStandingOrder(request),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          'Orders',
          'RescheduledStandingOrdersByStandingOrder',
          'StandingOrderRescheduledCount',
        ]);
      },
    }
  );
}
export { useRescheduleStandingOrder };
