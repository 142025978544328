import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';
import { IDeliveryEquipment } from '../../models';

function fetchDeliveryEquipmentByDelivery(deliveryId: string): Promise<IDeliveryEquipment[]> {
  return externalApi.get(`DeliveryEquipment/ByDelivery/${deliveryId}`).json();
}

export function useDeliveryEquipmentByDeliveryMutate() {
  const queryClient = useQueryClient();
  return useMutation((deliveryId: string) => fetchDeliveryEquipmentByDelivery(deliveryId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['DeliveryEquipmentByDelivery']);
    },
  });
}
