import {
  Box,
  Button,
  Center,
  createStyles,
  Divider,
  Group,
  Modal,
  ScrollArea,
  Select,
  Stack,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import React, { useEffect, useRef, useState } from 'react';
import { IEquipment, IPatient } from '../../../models';
import { useMediaQuery } from '@mantine/hooks';
import { FileInvoice, Plus, Search } from 'tabler-icons-react';
import { PatientEquipmentCard } from '../../../components';
import {
  useAllEquipment,
  usePatientEquipmentByPatient,
  useTrackedPatientEquipmentByPatient,
} from '../../../hooks';
import { JsxElement } from 'typescript';
import dayjs from 'dayjs';

const useStyles = createStyles((theme) => ({
  equipmentBox: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    paddingLeft: 3,
    paddingTop: 3,
    paddingBottom: 3,
    paddingRight: theme.spacing.xs,
    width: '100%',
    height: '70',
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
  groupLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.sm,
    whiteSpace: 'pre',
  },
  detailLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
  },
  detail: {
    fontSize: theme.fontSizes.sm,
    paddingLeft: 10,
  },
}));

type PatientEquipmentModalProps = {
  patient: IPatient;
  opened: boolean;
  equipmentReturned: boolean;
  onViewNotes: (trackedPatientEquipmentId: string) => void;
  onNewTrackedEquipment: (selectedEquipment: IEquipment) => void;
  onReturnTrackedEquipment: (trackedPatientEquipmentId: string) => void;
  onClose: () => void;
  onViewEquipmentHistory: () => void;
};

const PatientEquipmentModal = (props: PatientEquipmentModalProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);
  const viewport = useRef<HTMLDivElement | null>(null);

  const [selectedEquipment, setSelectedEquipment] = useState<IEquipment>();
  const [addEquipment, setAddEquipment] = useState<IEquipment>();
  const [addingNewCard, setAddingNewCard] = useState<boolean>(false);
  const [isFetched, setIsFetched] = useState<boolean>();

  const [selectValue, setSelectValue] = useState<string>('');

  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(props.equipmentReturned);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>(
    props.equipmentReturned ? 'Equipment Successfully Marked as Returned!' : ''
  );
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [search, setSearch] = useState<string>('');
  const [filteredCards, setFilteredCards] = useState<JSX.Element[]>([]);

  const scrollToBottom = () =>
    viewport.current!.scrollTo({ top: viewport.current!.scrollHeight, behavior: 'smooth' });

  const patientEquipment = usePatientEquipmentByPatient(
    props.patient.patientId !== '' ? props.patient.patientId : '0'
  );
  const trackedPatientEquipment = useTrackedPatientEquipmentByPatient(
    props.patient.patientId !== '' ? props.patient.patientId : '0'
  );
  const equipment = useAllEquipment();

  const untrackedCards = (patientEquipment.data ?? []).map((a) => (
    <PatientEquipmentCard
      key={`${a.patientEquipmentId}u`}
      patientEquipmentId={a.patientEquipmentId}
      patientId={a.patientId}
      equipmentId={a.equipmentId}
      equipmentName={a.equipmentName}
      equipmentTypeName={a.equipmentTypeName}
      quantity={a.quantity}
      serialNumber={a.serialNumber}
      lastUpdatedUserId={a.lastUpdatedUserId}
      lastUpdatedDateTime={a.lastUpdatedDateTime}
      onEditSerialNumber={(isEdited, isError) => {
        setShowSuccessMessage(isEdited);
        setShowErrorMessage(isError);
        setSuccessMessage('Equipment Serial Number Successfully Updated!');
        setErrorMessage('Something went wrong, Equipment Serial Number not Updated');
      }}
      onEditQuantity={(isEdited, isError) => {
        setShowSuccessMessage(isEdited);
        setShowErrorMessage(isError);
        setSuccessMessage('Equipment Quantity Successfully Updated!');
        setErrorMessage('Something went wrong, Equipment Quantity not Updated');
      }}
      onDelete={(isDeleted, isError) => {
        setShowSuccessMessage(isDeleted);
        setShowErrorMessage(isError);
        setSuccessMessage('Equipment Successfully Deleted from Patient!');
        setErrorMessage('Something went wrong, Equipment not Deleted');
      }}
      onClear={(isCleared, isError) => {
        setShowSuccessMessage(isCleared);
        setShowErrorMessage(isError);
        setSuccessMessage('Equipment Successfully Marked as Returned!');
        setErrorMessage('Something went wrong, Equipment not Marked as Returned');
      }}
    />
  ));

  const trackedCards = (trackedPatientEquipment.data ?? []).map((a) => (
    <PatientEquipmentCard
      key={`${a.trackedPatientEquipmentId}t`}
      patientEquipmentId={a.trackedPatientEquipmentId}
      patientId={a.patientId}
      equipmentName={a.equipmentName}
      equipmentTypeName={a.equipmentTypeName}
      quantity={1}
      serialNumber={a.serialNumber}
      deliveredDate={a.deliveredDateTime}
      lastUpdatedUserId={a.lastUpdatedUserId}
      lastUpdatedDateTime={a.lastUpdatedDateTime}
      isTracked
      onViewNotes={(trackedPatientEquipmentId) => {
        props.onViewNotes(trackedPatientEquipmentId);
      }}
      onDelete={(isDeleted, isError) => {
        setShowSuccessMessage(isDeleted);
        setShowErrorMessage(isError);
        setSuccessMessage('Equipment Successfully Deleted from Patient!');
        setErrorMessage('Something went wrong, Equipment not Deleted');
      }}
      onReturn={() => {
        props.onReturnTrackedEquipment(a.trackedPatientEquipmentId.toString());
      }}
    />
  ));

  const cards = [...untrackedCards, ...trackedCards].sort((a, b) => {
    return (
      new Date(b.props.lastUpdatedDateTime as string).valueOf() -
      new Date(a.props.lastUpdatedDateTime as string).valueOf()
    );
  });

  const filterCards = (value: string) => {
    setFilteredCards(
      cards.filter((a) =>
        `${a.props.equipmentName} ${a.props.equipmentTypeName} ${a.props.serialNumber ?? ''} ${
          a.props.deliveredDate ? dayjs(a.props.deliveredDate).format('YYYY-MM-DD') : ''
        } ${!a.props.isTracked ? a.props.quantity : ''}`
          .toLowerCase()
          .includes(value.toLowerCase())
      )
    );
  };

  useEffect(() => {
    patientEquipment.refetch();
    trackedPatientEquipment.refetch();
    if (props.opened) {
      setSelectedEquipment(undefined);
    }
  }, [props.patient, props.opened]);

  useEffect(() => {
    if (isFetched) filterCards(search);
  }, [isFetched]);

  return (
    <Modal
      title={
        showSuccessMessage ? (
          <Text color="green">{successMessage}</Text>
        ) : showErrorMessage ? (
          <Text color="red">{errorMessage}</Text>
        ) : (
          ''
        )
      }
      opened={props.opened}
      size={isSmallMobile ? 'xs' : 'md'}
      onClose={() => {
        setShowSuccessMessage(false);
        setShowErrorMessage(false);
        setSuccessMessage('');
        setErrorMessage('');
        setSelectedEquipment(undefined);
        setAddEquipment(undefined);
        setAddingNewCard(false);
        props.onClose();
      }}
    >
      <Box>
        <Center>
          <Text size="xl" weight={500} align="center">
            Outstanding Patient Equipment
          </Text>
        </Center>
        <Stack align="stretch" style={{ padding: 5 }}>
          <Group>
            <Stack spacing={0} style={{ width: '50%' }}>
              <Text className={classes.detailLabel}>Patient Name</Text>
              <Text
                className={classes.detail}
              >{`${props.patient.lastName}, ${props.patient.firstName}`}</Text>
            </Stack>
            <Stack spacing={0}>
              <Text className={classes.detailLabel}>Street Address, City</Text>
              <Text
                className={classes.detail}
              >{`${props.patient.streetAddress}, ${props.patient.cityName}`}</Text>
            </Stack>
          </Group>

          <Group>
            <Stack spacing={0} style={{ width: '50%' }}>
              <Text className={classes.detailLabel}>Last 4 Digits of{`\n`}Health Card Number</Text>
              <Text className={classes.detail}>{`#${props.patient.lastFourHCN}`}</Text>
            </Stack>
            <Stack spacing={0}>
              <Text className={classes.detailLabel}>{`\n`}Unique Number</Text>
              <Text className={classes.detail}>{`#${props.patient.claimNumber}`}</Text>
            </Stack>
          </Group>
        </Stack>
        <Text className={classes.groupLabel} mt="xs">
          Patient Equipment
        </Text>
        <Divider mb="xs" />
        <TextInput
          placeholder="Search by any field"
          mb="xs"
          icon={<Search size={14} />}
          value={search}
          onChange={(e) => {
            setSearch(e.currentTarget.value);
            filterCards(e.currentTarget.value);
          }}
        />
        <Stack align="stretch" style={{ width: '100%' }} mt="sm">
          <ScrollArea style={{ height: '225px' }} viewportRef={viewport}>
            {cards[0] && search.length > 0 && filteredCards}
            {cards[0] && search.length === 0 && cards}
            {addingNewCard && (
              <PatientEquipmentCard
                patientEquipmentId={''}
                patientId={props.patient.patientId}
                equipmentId={addEquipment?.equipmentId ?? ''}
                equipmentName={addEquipment?.name ?? ''}
                equipmentTypeName={addEquipment?.equipmentTypeName ?? ''}
                quantity={1}
                hasSerialNumber={addEquipment?.hasSerialNumber}
                isCreate
                onCreateCancel={() => {
                  setAddingNewCard(false);
                }}
                onCreate={(isCreated, isError) => {
                  setShowSuccessMessage(isCreated);
                  setShowErrorMessage(isError);
                  setSuccessMessage('Equipment Successfully Added to Patient!');
                  setErrorMessage('Something went wrong, Equipment not Added to Patient');
                  setAddingNewCard(false);
                  setIsFetched(false);
                  patientEquipment.refetch().then(() => {
                    setIsFetched(true);
                  });
                }}
                onOpen={() => {
                  scrollToBottom();
                }}
              />
            )}
            {!cards[0] && search.length === 0 && !addingNewCard && (
              <Text weight={500} align="center">
                Nothing Found
              </Text>
            )}
            {!filteredCards[0] && search.length > 0 && !addingNewCard && (
              <Text weight={500} align="center">
                Nothing Found
              </Text>
            )}
          </ScrollArea>
        </Stack>
        <Divider mt="xs" />
        <Stack align="stretch" spacing={5} mt="sm">
          <Select
            data={
              equipment.data
                ?.sort((a, b) => parseInt(a.equipmentTypeId) - parseInt(b.equipmentTypeId))
                .map((a) => ({
                  value: `${a.equipmentId}`,
                  label: `${a.equipmentTypeName} - ${a.name}`,
                })) ?? []
            }
            placeholder="Select Equipment to Add"
            searchable={!isSmallMobile}
            clearable
            value={selectValue}
            onChange={(e) => {
              setSelectValue(e?.toString() ?? '');
              setSelectedEquipment(
                equipment.data?.find((a) => a.equipmentId.toString() === e?.toString()) ?? undefined
              );
            }}
          />
          <Button
            variant="outline"
            disabled={!selectedEquipment}
            onClick={() => {
              if (selectedEquipment) {
                setSelectValue('');
                if (!selectedEquipment.hasSerialNumber) {
                  setAddEquipment(selectedEquipment);
                  setAddingNewCard(true);
                } else {
                  props.onNewTrackedEquipment(selectedEquipment);
                }
              }
            }}
          >
            <Plus style={{ marginRight: 5 }} />
            <Text className={classes.buttonText}>Add Equipment</Text>
          </Button>
          <Divider mt={`calc(${theme.spacing.xs} /2)`} />
          <Button
            variant="outline"
            onClick={() => {
              props.onViewEquipmentHistory();
            }}
            mt={`calc(${theme.spacing.xs} /2)`}
          >
            <FileInvoice style={{ marginRight: 5 }} />
            <Text className={classes.buttonText}>Equipment History</Text>
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default PatientEquipmentModal;
