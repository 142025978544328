import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type DeleteStandingOrderEquipmentRequest = {
  standingOrderEquipmentId: string;
};

type DeleteStandingOrderEquipmentResponse = {
  standingOrderEquipmentId: number;
};

function deleteStandingOrderEquipment(
  request: DeleteStandingOrderEquipmentRequest
): Promise<DeleteStandingOrderEquipmentResponse> {
  return externalApi
    .delete(`StandingOrderEquipment/delete/${request.standingOrderEquipmentId}`)
    .json();
}

function useDeleteStandingOrderEquipment() {
  const queryClient = useQueryClient();
  return useMutation(
    (request: DeleteStandingOrderEquipmentRequest) => deleteStandingOrderEquipment(request),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['StandingOrderEquipmentByStandingOrder']);
      },
    }
  );
}
export { useDeleteStandingOrderEquipment };
