import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type DeleteTrackedEquipmentCleanRequest = {
  trackedEquipmentCleanId: string;
};

type DeleteTrackedEquipmentCleanResponse = {
  trackedEquipmentCleanId: number;
};

function deleteTrackedEquipmentClean(
  request: DeleteTrackedEquipmentCleanRequest
): Promise<DeleteTrackedEquipmentCleanResponse> {
  return externalApi
    .delete(`TrackedEquipmentClean/delete/${request.trackedEquipmentCleanId}`)
    .json();
}

function useDeleteTrackedEquipmentClean() {
  const queryClient = useQueryClient();
  return useMutation(
    (request: DeleteTrackedEquipmentCleanRequest) => deleteTrackedEquipmentClean(request),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('TrackedEquipmentCleanByTrackedEquipment');
        queryClient.invalidateQueries('AllTrackedEquipment');
      },
    }
  );
}
export { useDeleteTrackedEquipmentClean };
