import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import externalApi from '../../api';
import { IStandingOrderEquipment } from '../../models';

function fetchStandingOrderEquipmentByStandingOrder(
  standingOrderId: string
): Promise<IStandingOrderEquipment[]> {
  return externalApi.get(`StandingOrderEquipment/ByStandingOrder/${standingOrderId}`).json();
}

export function useStandingOrderEquipmentByStandingOrderMutate() {
  const queryClient = useQueryClient();
  return useMutation(
    (standingOrderId: string) => fetchStandingOrderEquipmentByStandingOrder(standingOrderId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['StandingOrderEquipmentByStandingOrder']);
      },
    }
  );
}
