import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type EditTrackedEquipmentRepairRequest = {
  trackedEquipmentRepairId: string;
  repairLocationId?: string;
  expectedReturnDate?: Date;
  sentDateTime: Date;
  lastUpdatedUserId: string;
};

type EditTrackedEquipmentRepairResponse = {
  trackedEquipmentRepairId: number;
};

function editTrackedEquipmentRepair(
  request: EditTrackedEquipmentRepairRequest
): Promise<EditTrackedEquipmentRepairResponse> {
  return externalApi.patch(`TrackedEquipmentRepair/patch/details`, { json: request }).json();
}

function useEditTrackedEquipmentRepairDetails() {
  const queryClient = useQueryClient();
  return useMutation(
    (request: EditTrackedEquipmentRepairRequest) => editTrackedEquipmentRepair(request),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('TrackedEquipmentRepairByTrackedEquipment');
        queryClient.invalidateQueries('AllTrackedEquipment');
      },
    }
  );
}
export { useEditTrackedEquipmentRepairDetails };
