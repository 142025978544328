import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type NewTrackedEquipmentCleanRequest = {
  trackedEquipmentId: string;
  technicianUserId: string;
  cleanDateTime: Date;
  notes: string;
  lastUpdatedUserId: string;
};

type NewTrackedEquipmentCleanResponse = {
  trackedEquipmentCleanId: number;
};

function newTrackedEquipmentClean(
  request: NewTrackedEquipmentCleanRequest
): Promise<NewTrackedEquipmentCleanResponse> {
  return externalApi.post(`TrackedEquipmentClean/new`, { json: request }).json();
}

function useNewTrackedEquipmentClean() {
  const queryClient = useQueryClient();
  return useMutation(
    (request: NewTrackedEquipmentCleanRequest) => newTrackedEquipmentClean(request),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('TrackedEquipmentCleanByTrackedEquipment');
        queryClient.invalidateQueries('AllTrackedEquipment');
      },
    }
  );
}
export { useNewTrackedEquipmentClean };
