import { useQuery } from 'react-query';
import externalApi from '../../api';
import { IEquipment } from '../../models';

function fetchEquipment(equipmentId: string): Promise<IEquipment> {
  return externalApi.get(`Equipment/${equipmentId}`).json();
}

export function useEquipment(equipmentId: string) {
  return useQuery(['Equipments', 'Equipment'], () => fetchEquipment(equipmentId));
}
