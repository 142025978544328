import { Box, createStyles, Group, Stack, Text, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ClipboardList, Repeat } from 'tabler-icons-react';
import { PageHeader } from '../common/Page/Header/PageHeader';
import { Page } from '../common/Page/Page';
import {
  OnDemandOrdersSubPage,
  PageButton,
  PageButtonMobile,
  StandingOrdersSubPage,
} from '../components';
import { usePageStore } from '../hooks';

const useStyles = createStyles((theme, _params) => ({}));

type Props = {};

const OrdersPage = (props: Props) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);
  const selectedSubPage = usePageStore((state) => state.ordersSubPage);
  const setOrdersSubPage = usePageStore((state) => state.setOrdersSubPage);

  return (
    <Page
      header={
        <PageHeader
          title="Manage Orders"
          subtitle="View, Add, and Edit Orders"
          stickyContent={null}
        />
      }
    >
      <Box style={{ height: '50vh' }}>
        <Text size="xl" weight={500} align="center" mb={isSmallMobile ? 'xs' : 'lg'}>
          Click on a record to view it in full
        </Text>
        {!isSmallMobile && (
          <Group position="center" grow noWrap mb="md">
            <PageButton
              icon={ClipboardList}
              prompt="On-Demand Orders"
              clicked={selectedSubPage === 'orders'}
              height="150px"
              onClick={() => {
                navigate('/orders');
                setOrdersSubPage('orders');
              }}
            />
            <PageButton
              icon={Repeat}
              prompt="Standing Orders"
              clicked={selectedSubPage === 'standingOrders'}
              height="150px"
              onClick={() => {
                navigate('/orders');
                setOrdersSubPage('standingOrders');
              }}
            />
          </Group>
        )}
        {isSmallMobile && (
          <Stack align="stretch" spacing={5} mb="md">
            <PageButtonMobile
              icon={ClipboardList}
              prompt="On-Demand Orders"
              clicked={selectedSubPage === 'orders'}
              width="90vw"
              onClick={() => {
                setOrdersSubPage('orders');
              }}
            />
            <PageButtonMobile
              icon={Repeat}
              prompt="Standing Orders"
              clicked={selectedSubPage === 'standingOrders'}
              width="90vw"
              onClick={() => {
                setOrdersSubPage('standingOrders');
              }}
            />
          </Stack>
        )}
        {selectedSubPage === 'orders' && <OnDemandOrdersSubPage />}
        {selectedSubPage === 'standingOrders' && <StandingOrdersSubPage />}
      </Box>
    </Page>
  );
};

export default OrdersPage;
