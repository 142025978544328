import {
  Box,
  createStyles,
  Group,
  Modal,
  useMantineTheme,
  Text,
  Textarea,
  Button,
  Center,
  Stack,
} from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from '@mantine/hooks';
import { useDelivery, useEditDeliveryNotes } from '../../../hooks';
import { Check, X } from 'tabler-icons-react';
import { DatePicker, TimeInput } from '@mantine/dates';

const useStyles = createStyles((theme) => ({
  label: {
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
    fontWeight: 500,
  },
  fieldGroup: {
    width: '48%',
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

type DeliveryEditModalProps = {
  opened: boolean;
  deliveryId: string;
  isDelivery: boolean;
  onClose: () => void;
  onEdited: () => void;
};

const DeliveryEditModal = (props: DeliveryEditModalProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [notes, setNotes] = useState<string>('');

  const delivery = useDelivery();
  const editDeliveryNotes = useEditDeliveryNotes();

  async function submitDeliveryEdit() {
    if (delivery.data) {
      editDeliveryNotes.mutate({ deliveryId: delivery.data.deliveryId, notes: notes });
    }
  }

  useEffect(() => {
    if (editDeliveryNotes.isSuccess) {
      setIsError(false);
      setErrorMessage('');
      props.onEdited();
    }
    if (editDeliveryNotes.isError) {
      setIsError(true);
      setErrorMessage('Something went wrong, Delivery Notes not Updated. Please try again');
    }
  }, [editDeliveryNotes.status]);

  useEffect(() => {
    if (props.isDelivery) {
      delivery.mutate(props.deliveryId);
    }
  }, [props.deliveryId, props.opened]);

  useEffect(() => {
    setNotes(delivery.data?.notes ?? '');
  }, [delivery.isSuccess]);

  return (
    <Modal
      title={isError ? <Text color="red">{errorMessage}</Text> : ''}
      opened={props.opened}
      size={isSmallMobile ? 'xs' : 'md'}
      onClose={() => {
        props.onClose();
      }}
      styles={{
        content: {
          border: `${
            delivery.data?.isDelivered
              ? `3px solid ${theme.colors.blue[6]}`
              : `3px solid ${theme.colors.red[5]}`
          }`,
        },
      }}
    >
      <Box>
        {delivery.data && (
          <Box>
            <Center>
              <Text size="xl" weight={500} align="center">
                Update Delivery Notes
              </Text>
            </Center>
            <Stack align="stretch" style={{ padding: 5 }} mt={10}>
              <Stack align="stretch" spacing={0}>
                <Text className={classes.label}>Notes</Text>
                <Textarea
                  minRows={4}
                  ml={5}
                  defaultValue={delivery.data.notes}
                  onChange={(e) => setNotes(e.target.value)}
                />
              </Stack>

              <Group position="apart" grow noWrap>
                <Button
                  variant="outline"
                  type="submit"
                  onClick={() => {
                    submitDeliveryEdit();
                  }}
                >
                  <Check style={{ marginRight: 5 }} />{' '}
                  <Text className={classes.buttonText}>Confirm</Text>
                </Button>
                <Button
                  variant="outline"
                  color="red"
                  onClick={() => {
                    setIsError(false);
                    setErrorMessage('');
                    props.onClose();
                  }}
                >
                  <X style={{ marginRight: 5 }} />{' '}
                  <Text className={classes.buttonText}>Discard</Text>
                </Button>
              </Group>
            </Stack>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default DeliveryEditModal;
