import { Box, Button, Stack, Text } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from 'tabler-icons-react';

type PageButtonProps = {
  icon: Icon;
  prompt: string;
  clicked?: boolean;
  allowToggle?: boolean;
  height?: string;
  width?: string;
  link?: string;
  onClick?: () => void;
  onUnClick?: () => void;
};

const PageButton = (props: PageButtonProps) => {
  const navigate = useNavigate();
  const [isClicked, setIsClicked] = useState<boolean>(props.clicked ?? false);

  useEffect(() => {
    if (props.clicked !== undefined) {
      setIsClicked(props.clicked);
    }
  }, [props.clicked]);

  return (
    <Box style={{ flexGrow: 1 }}>
      {!isClicked && (
        <Button
          style={{
            height: props.height ?? 200,
            flexGrow: 1,
            width: props.width ?? '100%',
            padding: 0,
          }}
          variant="outline"
          onClick={() => {
            if (props.link !== undefined) {
              navigate(props.link);
            }

            if (props.allowToggle) {
              setIsClicked(!isClicked);
            }

            if (props.onClick !== undefined) {
              props.onClick();
            }
          }}
        >
          <Stack align="center">
            <props.icon size={80} />
            <Text weight={500} size="xl" align="center" style={{ width: 200, whiteSpace: 'pre' }}>
              {props.prompt}
            </Text>
          </Stack>
        </Button>
      )}
      {isClicked && (
        <Button
          style={{
            height: props.height ?? 200,
            flexGrow: 1,
            width: props.width ?? '100%',
            padding: 0,
          }}
          onClick={() => {
            if (props.link !== undefined) {
              navigate(props.link);
            }

            if (props.allowToggle) {
              setIsClicked(!isClicked);
            }

            if (props.onUnClick !== undefined) {
              props.onUnClick();
            } else if (props.onClick !== undefined) {
              props.onClick();
            }
          }}
        >
          <Stack align="center">
            <props.icon size={80} />
            <Text weight={500} size="xl" align="center" style={{ width: 200, whiteSpace: 'pre' }}>
              {props.prompt}
            </Text>
          </Stack>
        </Button>
      )}
    </Box>
  );
};

export default PageButton;
