import {
  ActionIcon,
  Box,
  createStyles,
  Group,
  Menu,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { Check, Dots, Edit, Note, Trash, X } from 'tabler-icons-react';
import { EquipmentDeliveryNotesModal, InfoButton } from '../../components';
import dayjs from 'dayjs';
import { useState } from 'react';

const useStyles = createStyles((theme) => ({
  equipmentBox: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    paddingLeft: 3,
    paddingTop: 3,
    paddingBottom: 3,
    paddingRight: theme.spacing.md,
    width: '100%',
    height: '70',
  },
}));

type EquipmentDetailCardProps = {
  equipmentName: string;
  equipmentTypeName: string;
  quantity: number;
  serialNumber?: string;
  notes?: string;
  trackedPatientEquipmentId?: string;
  undeliveredEquipmentId?: string;
  showSerialNumber?: boolean;
  showInfoButton?: boolean;
  showNotesButton?: boolean;
  deliveredDate?: Date;
  lastUpdatedUserId?: string;
  lastUpdatedDateTime?: Date;
  readOnly?: boolean;
  isTracked?: boolean;
  isUndelivered?: boolean;
  onEditQuantity?: () => void;
  onEditSerialNumber?: () => void;
  onViewNotes?: () => void;
  onClear?: () => void;
  onDelete?: () => void;
};

const EquipmentDetailCard = (props: EquipmentDetailCardProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();

  const [notesModalOpen, setNotesModalOpen] = useState<boolean>(false);

  return (
    <Box
      className={classes.equipmentBox}
      style={props.isUndelivered ? { borderColor: theme.colors.red[6] } : {}}
      mb={5}
    >
      <Group position="apart" noWrap spacing={0}>
        <Stack pl="sm" spacing={0} style={{ width: '100%' }}>
          <Text weight={500} color={props.isUndelivered ? theme.colors.red[6] : ''}>
            {props.equipmentName}
          </Text>
          <Group spacing={5}>
            <Text size="xs" color={props.isUndelivered ? theme.colors.red[6] : 'dimmed'}>
              {props.equipmentTypeName}
            </Text>
          </Group>
        </Stack>
        {!props.serialNumber && (
          <Group
            position={!props.readOnly ? 'center' : 'left'}
            noWrap
            spacing={0}
            style={{ width: `${!props.readOnly ? '100%' : '40%'}` }}
          >
            <X size="20px" color="gray" />
            <Text weight={500} style={{ fontSize: '25px' }} pb={2}>
              {props.quantity.toString()}
            </Text>
          </Group>
        )}
        {props.serialNumber && props.showSerialNumber && (
          <Stack align="stretch" spacing={0} style={{ width: `100%` }}>
            <Text
              align="center"
              size="md"
              weight={500}
              color={props.isUndelivered ? theme.colors.red[6] : ''}
            >
              {props.isUndelivered && <i>{props.serialNumber}</i>}
              {!props.isUndelivered && props.serialNumber}
            </Text>
            {props.deliveredDate && (
              <Group position="center" spacing={`calc(${theme.spacing.xs} / 2)`}>
                <Text align="right" size="xs" color="dimmed">
                  Delivered:
                </Text>
                <Text align="left" size="xs" weight={500}>
                  {dayjs(props.deliveredDate).format('YYYY-MM-DD')}
                </Text>
              </Group>
            )}
          </Stack>
        )}

        <Stack align="stretch" spacing={0}>
          {!props.readOnly && (
            <Menu withinPortal>
              <Menu.Target>
                <ActionIcon>
                  <Dots />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown>
                {!props.serialNumber && (
                  <Menu.Item
                    icon={<Edit color={theme.colors.blue[6]} />}
                    onClick={() => {
                      if (props.onEditQuantity) {
                        props.onEditQuantity();
                      }
                    }}
                  >
                    Edit Quantity
                  </Menu.Item>
                )}
                {props.serialNumber && !props.isTracked && (
                  <Menu.Item
                    icon={<Edit color={theme.colors.blue[6]} />}
                    onClick={() => {
                      if (props.onEditSerialNumber) {
                        props.onEditSerialNumber();
                      }
                    }}
                  >
                    Edit Serial Number
                  </Menu.Item>
                )}
                {props.isTracked && (
                  <Menu.Item
                    icon={<Note color={theme.colors.blue[6]} />}
                    onClick={() => {
                      if (props.onViewNotes) {
                        props.onViewNotes();
                      }
                    }}
                  >
                    View Delivery Notes
                  </Menu.Item>
                )}
                {props.onClear && props.isTracked && (
                  <Menu.Item
                    icon={<Check color={theme.colors.blue[6]} />}
                    onClick={() => {
                      if (props.onClear) {
                        props.onClear();
                      }
                    }}
                  >
                    Mark as Returned
                  </Menu.Item>
                )}
                <Menu.Item
                  icon={<Trash color="red" />}
                  onClick={() => {
                    if (props.onDelete) {
                      props.onDelete();
                    }
                  }}
                >
                  Delete
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          )}
          {props.showInfoButton && (
            <InfoButton userId={props.lastUpdatedUserId} date={props.lastUpdatedDateTime} />
          )}
        </Stack>
        {props.showNotesButton && (
          <ActionIcon
            onClick={() => {
              setNotesModalOpen(true);
            }}
          >
            <Note
              style={{ marginRight: 5, color: props.isUndelivered ? theme.colors.red[3] : '' }}
            />
          </ActionIcon>
        )}
      </Group>
      {props.showNotesButton && (
        <EquipmentDeliveryNotesModal
          opened={notesModalOpen}
          trackedPatientEquipmentId={props.trackedPatientEquipmentId}
          undeliveredEquipmentId={props.undeliveredEquipmentId}
          notes={props.notes ?? ''}
          onClose={() => {
            setNotesModalOpen(false);
          }}
        />
      )}
    </Box>
  );
};

export default EquipmentDetailCard;
