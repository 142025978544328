import { ActionIcon, Box, createStyles, Group, NumberInput, Stack, Text } from '@mantine/core';
import React, { useState } from 'react';
import { DeviceFloppy, X } from 'tabler-icons-react';

const useStyles = createStyles((theme) => ({
  equipmentBox: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    paddingLeft: 3,
    paddingTop: 3,
    paddingBottom: 3,
    paddingRight: theme.spacing.md,
    width: '100%',
    height: '70',
  },
}));

type EquipmentEditQuantityCardProps = {
  equipmentName: string;
  equipmentTypeName: string;
  quantity: number;
  onClose: () => void;
  onSave: (newQuantity: number) => void;
};

const EquipmentEditQuantityCard = (props: EquipmentEditQuantityCardProps) => {
  const { classes } = useStyles();

  const [quantity, setQuantity] = useState<number>(props.quantity);

  return (
    <Box className={classes.equipmentBox} mb={5}>
      <Group position="apart" noWrap spacing={0}>
        <Stack pl="sm" spacing={0}>
          <Text weight={500}>{props.equipmentName}</Text>
          <Group spacing={5}>
            <Text size="xs" color="dimmed">
              {props.equipmentTypeName}
            </Text>
          </Group>
        </Stack>
        <Group noWrap spacing={0}>
          <X size="20px" color="gray" />
          <NumberInput
            style={{ width: '65px' }}
            min={1}
            value={quantity}
            onChange={(e) => {
              setQuantity(e === '' ? 1 : e);
            }}
          />
        </Group>
        <Stack spacing={0}>
          <ActionIcon
            onClick={() => {
              props.onSave(quantity);
            }}
          >
            <DeviceFloppy />
          </ActionIcon>
          <ActionIcon
            onClick={() => {
              props.onClose();
            }}
          >
            <X />
          </ActionIcon>
        </Stack>
      </Group>
    </Box>
  );
};

export default EquipmentEditQuantityCard;
