import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type EditTrackedEquipmentCleanRequest = {
  trackedEquipmentCleanId: string;
  technicianUserId: string;
  cleanDateTime: Date;
  lastUpdatedUserId: string;
};

type EditTrackedEquipmentCleanResponse = {
  trackedEquipmentCleanId: number;
};

function editTrackedEquipmentClean(
  request: EditTrackedEquipmentCleanRequest
): Promise<EditTrackedEquipmentCleanResponse> {
  return externalApi.patch(`TrackedEquipmentClean/patch/details`, { json: request }).json();
}

function useEditTrackedEquipmentCleanDetails() {
  const queryClient = useQueryClient();
  return useMutation(
    (request: EditTrackedEquipmentCleanRequest) => editTrackedEquipmentClean(request),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('TrackedEquipmentCleanByTrackedEquipment');
        queryClient.invalidateQueries('AllTrackedEquipment');
      },
    }
  );
}
export { useEditTrackedEquipmentCleanDetails };
