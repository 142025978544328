import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type DeleteStandingOrderCancellationRequest = {
  standingOrderCancellationId: string;
};

type DeleteStandingOrderCancellationResponse = {
  standingOrderCancellationId: number;
};

function deleteStandingOrderCancellation(
  request: DeleteStandingOrderCancellationRequest
): Promise<DeleteStandingOrderCancellationResponse> {
  return externalApi
    .delete(`StandingOrderCancellation/delete/${request.standingOrderCancellationId}`)
    .json();
}

function useDeleteStandingOrderCancellation() {
  const queryClient = useQueryClient();
  return useMutation(
    (request: DeleteStandingOrderCancellationRequest) => deleteStandingOrderCancellation(request),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['StandingOrderCancellationsByStandingOrder']);
      },
    }
  );
}
export { useDeleteStandingOrderCancellation };
