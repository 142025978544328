import React from 'react';
import { useQuery } from 'react-query';
import externalApi from '../../api';
import { IRepairLocation } from '../../models';

function fetchRepairLocations(): Promise<IRepairLocation[]> {
  return externalApi.get(`RepairLocation/all`).json();
}

export function useRepairLocations() {
  return useQuery(['RepairLocations'], () => fetchRepairLocations());
}
