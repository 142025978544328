import React from 'react';
import { useQuery } from 'react-query';
import externalApi from '../../api';
import { ITrackedPatientEquipment } from '../../models';

function fetchTrackedPatientEquipmentByTrackedEquipment(
  trackedEquipmentId: string
): Promise<ITrackedPatientEquipment[]> {
  return externalApi.get(`TrackedPatientEquipment/ByTrackedEquipment/${trackedEquipmentId}`).json();
}

export function useTrackedPatientEquipmentByTrackedEquipment(trackedEquipmentId: string) {
  return useQuery(['TrackedPatientEquipmentByTrackedEquipment'], () =>
    fetchTrackedPatientEquipmentByTrackedEquipment(trackedEquipmentId)
  );
}
