import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';
import { IStandingOrder } from '../../models';

function getStandingOrder(standingOrderId: string): Promise<IStandingOrder> {
  return externalApi.get(`StandingOrder/${standingOrderId}`).json();
}

function useStandingOrder() {
  const queryClient = useQueryClient();
  return useMutation((standingOrderId: string) => getStandingOrder(standingOrderId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['StandingOrder']);
    },
  });
}

export { useStandingOrder };
