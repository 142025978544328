import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type DeleteTrackedEquipmentRequest = {
  trackedEquipmentId: string;
};

type DeleteTrackedEquipmentResponse = {
  trackedEquipmentId: number;
};

function deleteTrackedEquipment(
  request: DeleteTrackedEquipmentRequest
): Promise<DeleteTrackedEquipmentResponse> {
  return externalApi.delete(`TrackedEquipment/delete/${request.trackedEquipmentId}`).json();
}

function useDeleteTrackedEquipment() {
  const queryClient = useQueryClient();
  return useMutation((request: DeleteTrackedEquipmentRequest) => deleteTrackedEquipment(request), {
    onSuccess: () => {
      queryClient.invalidateQueries(['TrackedEquipmentByOrder']);
    },
  });
}
export { useDeleteTrackedEquipment };
