import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type NewRepairLocationRequest = {
  name: string;
};

type NewRepairLocationResponse = {
  repairLocationId: number;
};

function newRepairLocation(request: NewRepairLocationRequest): Promise<NewRepairLocationResponse> {
  return externalApi.post(`RepairLocation/new`, { json: request }).json();
}

function useNewRepairLocation() {
  const queryClient = useQueryClient();
  return useMutation((request: NewRepairLocationRequest) => newRepairLocation(request), {
    onSuccess: () => {
      queryClient.invalidateQueries(['RepairLocations']);
    },
  });
}
export { useNewRepairLocation };
