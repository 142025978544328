import React from 'react';
import { useQuery } from 'react-query';
import externalApi from '../../api';

function fetchIsAdmin(): Promise<{ isAdmin: boolean }> {
  return externalApi.post(`User/IsAdmin`).json();
}

function useIsAdmin() {
  return useQuery(['IsAdmin'], () => fetchIsAdmin());
}
export { useIsAdmin };
