import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';
import { IUndeliveredEquipment } from '../../models';

function fetchUndeliveredEquipmentByDelivery(deliveryId: string): Promise<IUndeliveredEquipment[]> {
  return externalApi.get(`UndeliveredEquipment/ByDelivery/${deliveryId}`).json();
}

export function useUndeliveredEquipmentByDeliveryMutate() {
  const queryClient = useQueryClient();
  return useMutation((deliveryId: string) => fetchUndeliveredEquipmentByDelivery(deliveryId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['UndeliveredEquipmentByDelivery']);
    },
  });
}
