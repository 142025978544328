import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type DeletePatientEquipmentRequest = {
  patientEquipmentId: string;
};

type DeletePatientEquipmentResponse = {
  patientEquipmentId: number;
};

function deletePatientEquipment(
  request: DeletePatientEquipmentRequest
): Promise<DeletePatientEquipmentResponse> {
  return externalApi.delete(`PatientEquipment/delete/${request.patientEquipmentId}`).json();
}

function useDeletePatientEquipment() {
  const queryClient = useQueryClient();
  return useMutation((request: DeletePatientEquipmentRequest) => deletePatientEquipment(request), {
    onSuccess: () => {
      queryClient.invalidateQueries(['PatientEquipmentByPatient']);
    },
  });
}
export { useDeletePatientEquipment };
