import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';
import { IFilteredPatient } from '../../models';

type FilterPatientsRequest = {
  firstName?: string | null;
  lastName?: string | null;
  lastFourHCN?: string | null;
  claimNumber?: string | null;
};

function getPatientsFiltered(request: FilterPatientsRequest): Promise<IFilteredPatient[]> {
  return externalApi.post(`Patient/filtered`, { json: request }).json();
}

function usePatientsFiltered() {
  const queryClient = useQueryClient();
  return useMutation((request: FilterPatientsRequest) => getPatientsFiltered(request), {
    onSuccess: () => {
      queryClient.invalidateQueries(['Patients', 'FilteredPatients']);
    },
  });
}

export { usePatientsFiltered };
