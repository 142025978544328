import {
  Box,
  Button,
  Center,
  Checkbox,
  createStyles,
  Divider,
  Group,
  Modal,
  SegmentedControl,
  Stack,
  Text,
  Textarea,
  useMantineTheme,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { DatePickerInput } from '@mantine/dates';
import React, { useEffect, useState } from 'react';
import { CalendarEvent, X } from 'tabler-icons-react';
import { useMediaQuery } from '@mantine/hooks';
import { IStandingOrder, IOrder } from '../../../models';
import {
  useNewOrderEquipmentFromStandingOrderEquipment,
  useRescheduleStandingOrder,
  useUserId,
} from '../../../hooks';

const useStyles = createStyles((theme) => ({
  label: {
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
    fontWeight: 500,
  },
  fieldGroup: {
    width: '48%',
  },
  detailLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
  },
  detail: {
    fontSize: theme.fontSizes.md,
    paddingLeft: 10,
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

type OrderEditModalProps = {
  standingOrder: IStandingOrder;
  opened: boolean;
  onClose: () => void;
  onRescheduled: (isRescheduled: boolean, isError: boolean) => void;
};

const StandingOrderRescheduleModal = (props: OrderEditModalProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const [isAMDelivery, setIsAMDelivery] = useState<boolean>(props.standingOrder.isAMDelivery);
  const initialOrder = {
    orderId: '',
    patientId: props.standingOrder.patientId,
    patientFirstName: props.standingOrder.patientFirstName,
    patientLastName: props.standingOrder.patientLastName,
    patientFullName: props.standingOrder.patientFullName,
    patientFullNameFormatted: props.standingOrder.patientFullNameFormatted,
    patientPhoneNumber: props.standingOrder.patientPhoneNumber,
    patientAddress: props.standingOrder.patientAddress,
    patientCity: props.standingOrder.patientCity,
    patientNotes: '',
    isAMDelivery: props.standingOrder.isAMDelivery,
    deliveryTimeName: props.standingOrder.deliveryTimeName,
    requestedDeliveryDate: new Date(),
    liquid: props.standingOrder.liquid,
    notes: props.standingOrder.notes,
    isUrgent: false,
    standingOrderId: props.standingOrder.standingOrderId,
    standingOrderReschedule: true,
    deliveryReschedule: false,
    fulfilled: false,
    lastUpdatedUserId: props.standingOrder.lastUpdatedUserId,
    lastUpdatedDateTime: props.standingOrder.lastUpdated,
  };

  const currentUserId = useUserId();

  const [submitClicked, setSubmitClicked] = useState<boolean>(false);

  const newOrder = useRescheduleStandingOrder();
  const newOrderEquipment = useNewOrderEquipmentFromStandingOrderEquipment();

  const rescheduleForm = useForm<IOrder>({
    initialValues: initialOrder,
    validate: {},
  });

  async function submitOrderUpdate() {
    setSubmitClicked(true);

    newOrder.mutate({
      patientId: props.standingOrder.patientId,
      isAMDelivery: isAMDelivery,
      requestedDeliveryDate: rescheduleForm.values.requestedDeliveryDate,
      liquid: rescheduleForm.values.liquid,
      notes: rescheduleForm.values.notes,
      standingOrderId: props.standingOrder.standingOrderId,
    });
  }

  useEffect(() => {
    rescheduleForm.setValues({
      orderId: '',
      patientId: props.standingOrder.patientId,
      patientFirstName: props.standingOrder.patientFirstName,
      patientLastName: props.standingOrder.patientLastName,
      patientFullName: props.standingOrder.patientFullName,
      patientFullNameFormatted: props.standingOrder.patientFullNameFormatted,
      patientPhoneNumber: props.standingOrder.patientPhoneNumber,
      patientAddress: props.standingOrder.patientAddress,
      patientCity: props.standingOrder.patientCity,
      patientNotes: props.standingOrder.patientNotes,
      isAMDelivery: props.standingOrder.isAMDelivery,
      deliveryTimeName: props.standingOrder.deliveryTimeName,
      requestedDeliveryDate: new Date(),
      liquid: props.standingOrder.liquid,
      notes: props.standingOrder.notes,
      standingOrderId: props.standingOrder.standingOrderId,
      standingOrderReschedule: true,
      deliveryReschedule: false,
      fulfilled: false,
      lastUpdatedUserId: props.standingOrder.lastUpdatedUserId,
      lastUpdatedDateTime: props.standingOrder.lastUpdated,
    });
  }, [props.standingOrder]);

  useEffect(() => {
    if (submitClicked) {
      if (newOrder.isSuccess) {
        setSubmitClicked(false);

        newOrderEquipment.mutate({
          orderId: newOrder.data.orderId.toString(),
          standingOrderId: props.standingOrder.standingOrderId,
          lastUpdatedUserId: currentUserId.data!.userId,
        });
      } else if (newOrder.isError) {
        setSubmitClicked(false);
        props.onRescheduled(false, true);
      }
    }
  }, [newOrder.status]);

  useEffect(() => {
    if (newOrderEquipment.isSuccess) {
      props.onRescheduled(true, false);
    } else if (newOrderEquipment.isError) {
      props.onRescheduled(false, true);
    }
  }, [newOrderEquipment.status]);

  return (
    <Modal
      opened={props.opened}
      size={isSmallMobile ? 'xs' : 'md'}
      onClose={() => {
        props.onClose();
      }}
    >
      <Box>
        <Center>
          <Text size="xl" weight={500} align="center">
            Confirm Rescheduled Order Information
          </Text>
        </Center>
        <form onSubmit={rescheduleForm.onSubmit(() => submitOrderUpdate())} autoComplete="off">
          <Stack align="stretch" style={{ padding: 5 }}>
            <Group>
              <Stack spacing={0} style={{ width: '50%' }}>
                <Text className={classes.label}>Patient Name</Text>
                <Group spacing={5} noWrap>
                  <Text
                    className={classes.detail}
                  >{`${props.standingOrder.patientLastName}, ${props.standingOrder.patientFirstName}`}</Text>
                </Group>
              </Stack>
              <Stack spacing={0}>
                <Text className={classes.label}>Phone number</Text>
                <Text
                  className={classes.detail}
                >{`${props.standingOrder.patientPhoneNumber}`}</Text>
              </Stack>
            </Group>
            <Group>
              <Stack spacing={0} style={{ width: '50%' }}>
                <Text className={classes.detailLabel}>Delivery Address</Text>
                <Text className={classes.detail}>{`${props.standingOrder.patientAddress}`}</Text>
              </Stack>
              <Stack spacing={0}>
                <Text className={classes.detailLabel}>City</Text>
                <Text className={classes.detail}>{`${props.standingOrder.patientCity}`}</Text>
              </Stack>
            </Group>
            <Group>
              <Stack spacing={0} style={{ width: '50%' }}>
                <Text className={classes.detailLabel}>Normal Delivery Weekday</Text>
                <Text className={classes.detail}>{`${props.standingOrder.weekdayName}`}</Text>
              </Stack>
              <Stack spacing={0}>
                <Text className={classes.detailLabel}>Normal Delivery Time</Text>
                <Text className={classes.detail}>{`${
                  props.standingOrder.isAMDelivery ? 'AM' : 'PM'
                }`}</Text>
              </Stack>
            </Group>

            <Divider />

            <Group noWrap>
              <Stack spacing={0} className={classes.fieldGroup}>
                <Text className={classes.label}>Requested Delivery Date</Text>
                <DatePickerInput
                  valueFormat="YYYY-MM-DD"
                  firstDayOfWeek={0}
                  required
                  dropdownType="modal"
                  modalProps={{
                    title: 'Requested Delivery Date',
                    withCloseButton: true,
                    closeButtonProps: { style: { position: 'fixed', right: 10 } },
                    yOffset: '15vh',
                    styles: { title: { fontWeight: 500, textAlign: 'center', width: '100%' } },
                  }}
                  style={{ width: '125px' }}
                  {...rescheduleForm.getInputProps('requestedDeliveryDate')}
                />
              </Stack>
              <Stack spacing={0} className={classes.fieldGroup} ml={15}>
                <Text className={classes.label}>Delivery Time</Text>
                <SegmentedControl
                  color="blue"
                  data={[
                    { label: 'AM', value: 'AM' },
                    { label: 'PM', value: 'PM' },
                  ]}
                  defaultValue={props.standingOrder.isAMDelivery ? 'AM' : 'PM'}
                  ml={10}
                  onChange={(e) => {
                    e === 'AM' ? setIsAMDelivery(true) : setIsAMDelivery(false);
                  }}
                />
              </Stack>
            </Group>

            <Group position="left" spacing="xs" pl="xs">
              <Checkbox
                defaultChecked={props.standingOrder.liquid}
                {...rescheduleForm.getInputProps('liquid')}
              />
              <Text className={classes.label}>Liquid</Text>
            </Group>

            <Stack align="stretch" spacing={0}>
              <Text className={classes.label}>Notes</Text>
              <Textarea minRows={4} {...rescheduleForm.getInputProps('notes')} />
            </Stack>

            <Group position="apart" grow noWrap mt="xs">
              <Button variant="outline" type="submit">
                <CalendarEvent style={{ marginRight: 5 }} />{' '}
                <Text className={classes.buttonText}>Reschedule</Text>
              </Button>
              <Button
                variant="outline"
                color="red"
                onClick={() => {
                  props.onClose();
                }}
              >
                <X style={{ marginRight: 5 }} /> <Text className={classes.buttonText}>Cancel</Text>
              </Button>
            </Group>
          </Stack>
        </form>
      </Box>
    </Modal>
  );
};

export default StandingOrderRescheduleModal;
