import { Box, createStyles, Flex, Group, Stack, Text, useMantineTheme } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import StatusCounter from './StatusCounter';
import { useMediaQuery } from '@mantine/hooks';
import { ExclamationMark } from 'tabler-icons-react';

const useStyles = createStyles((theme, _params) => ({
  calendar: {
    border: `1px solid ${theme.colors.dark[8]}`,
    borderRadius: theme.radius.md,
  },
  date: {
    cursor: 'pointer',
    userSelect: 'none',
    borderTop: `1px solid ${theme.colors.dark[8]}`,
    height: '95px',
    width: '15%',
    '&:not(:last-of-type)': {
      borderRight: `1px solid ${theme.colors.dark[8]}`,
    },
    '&:first-of-type': {
      background: theme.colors.gray[2],
      borderBottomLeftRadius: 'inherit',
    },
    '&:last-of-type': {
      background: theme.colors.gray[2],
      borderBottomRightRadius: 'inherit',
    },
    overflow: 'hidden',
  },
  date_selected: {
    border: `2px solid ${theme.colors.blue[6]} !important`,
    height: '100%',
    width: '100%',
    borderBottomLeftRadius: 'inherit',
    borderBottomRightRadius: 'inherit',
    overflow: 'hidden',
  },
  date_today: {
    background: `${theme.colors.blue[1]} !important`,
  },
  dateLabel: {
    paddingLeft: '5px',
    fontWeight: 500,
    fontSize: 12,

    [`@media (min-width: ${theme.breakpoints.xs})`]: {
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
    },
  },
}));

type CalendarDateProps = {
  date: number;
  month: number;
  year: number;
  pendingCount?: number;
  deliveredCount?: number;
  undeliveredCount?: number;
  incompleteCount?: number;
  anyUrgent?: boolean;
  selectedDate?: Date | undefined;
  isSelected?: boolean;
  isToday?: boolean;
  onClick: () => void;
};

const CalendarDate = (props: CalendarDateProps) => {
  const { classes, cx } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const [selected, setSelected] = useState<boolean>(props.isSelected ?? false);

  useEffect(() => {
    if (props.selectedDate) {
      setSelected(
        props.selectedDate.getUTCDate() === props.date &&
          props.selectedDate.getUTCMonth() === props.month &&
          props.selectedDate.getUTCFullYear() === props.year
      );
    }
  }, [props.selectedDate]);

  return (
    <Box
      className={cx(classes.date, props.isToday ? classes.date_today : '')}
      onClick={() => {
        setSelected(!selected);
        props.onClick();
      }}
    >
      <Box
        className={selected ? classes.date_selected : ''}
        style={{ height: '100%', position: 'relative' }}
      >
        <Flex
          direction={isSmallMobile ? 'column' : 'row'}
          align={isSmallMobile ? '' : 'center'}
          gap={0}
          style={{ height: '100%', flexGrow: 1 }}
        >
          <Text className={classes.dateLabel}>{props.date.toString()}</Text>
          <Stack align="center" spacing={isSmallMobile ? 2 : 5} style={{ flexGrow: 1 }}>
            {props.pendingCount !== undefined && props.pendingCount > 0 && (
              <Box style={{ position: 'relative' }}>
                <StatusCounter count={props.pendingCount} statusType="pending" />
                {props.anyUrgent && (
                  <ExclamationMark
                    size={20}
                    style={{
                      marginLeft: -5,
                      color: theme.colors.red[6],
                      position: 'absolute',
                      right: -13,
                      top: -1,
                    }}
                  />
                )}
              </Box>
            )}
            {props.deliveredCount !== undefined && props.deliveredCount > 0 && (
              <StatusCounter count={props.deliveredCount} statusType="delivered" />
            )}
            {props.incompleteCount !== undefined && props.incompleteCount > 0 && (
              <StatusCounter count={props.incompleteCount} statusType="incomplete" />
            )}
            {props.undeliveredCount !== undefined && props.undeliveredCount > 0 && (
              <StatusCounter count={props.undeliveredCount} statusType="undelivered" />
            )}
          </Stack>
        </Flex>
      </Box>
    </Box>
  );
};

export default CalendarDate;
