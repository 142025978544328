import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import externalApi from '../../api';
import { IDelivery } from '../../models';

type UndeliveredByDateRequest = {
  date: Date;
};

function undeliveredByDate(request: UndeliveredByDateRequest): Promise<IDelivery[]> {
  return externalApi.post(`Delivery/ByDate/Undelivered`, { json: request }).json();
}

function useUndeliveredByDate(request: UndeliveredByDateRequest) {
  return useQuery(['UndeliveredByDate'], () => undeliveredByDate(request));
}
export { useUndeliveredByDate };
