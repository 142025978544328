import React from 'react';
import { useQuery } from 'react-query';
import externalApi from '../../api';
import { ITrackedEquipment } from '../../models';

function fetchTrackedEquipment(trackedEquipmentId: string): Promise<ITrackedEquipment> {
  return externalApi.get(`TrackedEquipment/${trackedEquipmentId}`).json();
}

export function useTrackedEquipment(trackedEquipmentId: string) {
  return useQuery(['TrackedEquipment'], () => fetchTrackedEquipment(trackedEquipmentId));
}
