import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type DeleteEquipmentRequest = {
  equipmentId: string;
};

type DeleteEquipmentResponse = {
  equipmentId: number;
};

function deleteEquipment(request: DeleteEquipmentRequest): Promise<DeleteEquipmentResponse> {
  return externalApi.delete(`Equipment/delete/${request.equipmentId}`).json();
}

function useDeleteEquipment() {
  const queryClient = useQueryClient();
  return useMutation((request: DeleteEquipmentRequest) => deleteEquipment(request), {
    onSuccess: () => {
      queryClient.invalidateQueries(['AllEquipment']);
    },
  });
}
export { useDeleteEquipment };
