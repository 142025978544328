import {
  ActionIcon,
  Box,
  Button,
  Center,
  createStyles,
  Divider,
  Group,
  Modal,
  ScrollArea,
  Stack,
  Table,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from '@mantine/hooks';
import { useCities, useEditCity, useNewCity } from '../../../hooks';
import { ICity } from '../../../models';
import { Edit, Plus, X } from 'tabler-icons-react';

const useStyles = createStyles((theme) => ({
  label: {
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
    fontWeight: 500,
  },
  fieldGroup: {
    width: '48%',
  },
  tableTitle: {
    borderTop: `1px solid ${theme.colors.gray[4]}`,
    borderBottom: `1px solid ${theme.colors.gray[4]}`,
    backgroundColor: `${theme.colors.gray[1]}`,
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

type CityDisplayModalProps = {
  opened: boolean;
  onClose: () => void;
};

const CityDisplayModal = (props: CityDisplayModalProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const [isError, setIsError] = useState<boolean>(false);
  const [flashMessage, setFlashMessage] = useState<string>('');

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [cityId, setCityId] = useState<string>('');
  const [cityName, setCityName] = useState<string>('');

  const cities = useCities();

  const newCity = useNewCity();
  const editCity = useEditCity();

  const cityRows = (cities.data ?? []).map((a: ICity) => {
    return (
      <tr key={a.cityId}>
        <td style={{ width: '50%', wordWrap: 'break-word', textAlign: 'center' }}>{a.name}</td>
        <td>
          <Group noWrap position="center" spacing={35}>
            <ActionIcon
              onClick={() => {
                setIsError(false);
                setFlashMessage('');
                setIsEditing(true);
                setCityId(a.cityId);
                setCityName(a.name);
              }}
            >
              <Edit style={{ color: theme.colors.blue[6] }} />
            </ActionIcon>
          </Group>
        </td>
      </tr>
    );
  });

  useEffect(() => {
    if (newCity.isSuccess) {
      setIsError(false);
      setFlashMessage('City Successfully Added!');
      setIsEditing(false);
      setCityId('');
      setCityName('');
    }
    if (newCity.isError) {
      setIsError(true);
      setFlashMessage('Something went wrong, City not Added. Please try again');
    }
  }, [newCity.status]);

  useEffect(() => {
    if (editCity.isSuccess) {
      setIsError(false);
      setFlashMessage('City Successfully Updated!');
      setIsEditing(false);
      setCityId('');
      setCityName('');
    }
    if (editCity.isError) {
      setIsError(true);
      setFlashMessage('Something went wrong, City not Updated. Please try again');
    }
  }, [editCity.status]);

  return (
    <Modal
      title={<Text style={{ color: `${isError ? 'red' : 'green'}` }}>{flashMessage}</Text>}
      opened={props.opened}
      size={isSmallMobile ? 'xs' : 'md'}
      onClose={() => {
        setIsError(false);
        setFlashMessage('');
        setIsEditing(false);
        setCityId('');
        setCityName('');
        props.onClose();
      }}
    >
      <Box>
        <Center>
          <Text size="xl" weight={500} align="center">
            Cities
          </Text>
        </Center>
        <Divider mt={10} />
        <ScrollArea type="hover" style={{ height: '250px', tableLayout: 'fixed' }}>
          {cityRows[0] && (
            <Table style={{ tableLayout: 'fixed', width: '100%' }}>
              <tbody>{cityRows}</tbody>
            </Table>
          )}
          {!cityRows[0] && (
            <Text weight={500} align="center">
              Nothing Found
            </Text>
          )}
        </ScrollArea>
        <Divider mb={10} />
        <Stack align="stretch" spacing={5}>
          <TextInput
            value={cityName}
            onChange={(e) => {
              setCityName(e.target.value);
            }}
            rightSection={
              <ActionIcon
                size={20}
                onClick={() => {
                  setIsEditing(false);
                  setCityId('');
                  setCityName('');
                  setIsError(false);
                  setFlashMessage('');
                }}
              >
                <X />
              </ActionIcon>
            }
          />
          {!isEditing && (
            <Button
              variant="outline"
              onClick={() => {
                if (cityName.length > 0) newCity.mutate({ name: cityName });
              }}
            >
              <Plus /> Add a New City
            </Button>
          )}
          {isEditing && (
            <Button
              variant="outline"
              onClick={() => {
                if (cityName.length > 0) editCity.mutate({ cityId: cityId, name: cityName });
              }}
            >
              <Edit /> Update City Name
            </Button>
          )}
        </Stack>
      </Box>
    </Modal>
  );
};

export default CityDisplayModal;
