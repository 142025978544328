import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type EditRepairLocationRequest = {
  repairLocationId: string;
  name: string;
};

type EditRepairLocationResponse = {
  repairLocationId: number;
};

function editRepairLocation(
  request: EditRepairLocationRequest
): Promise<EditRepairLocationResponse> {
  return externalApi.put(`RepairLocation/put`, { json: request }).json();
}

function useEditRepairLocation() {
  const queryClient = useQueryClient();
  return useMutation((request: EditRepairLocationRequest) => editRepairLocation(request), {
    onSuccess: () => {
      queryClient.invalidateQueries(['RepairLocations']);
    },
  });
}
export { useEditRepairLocation };
