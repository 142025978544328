import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type EditOrderRequest = {
  orderId: string;
  requestedDeliveryDate: Date;
  isAMDelivery: boolean;
  liquid: boolean;
  notes: string;
  isUrgent: boolean;
  lastUpdatedUserId: string;
};

type EditOrderResponse = {
  orderId: number;
};

function editOrder(request: EditOrderRequest): Promise<EditOrderResponse> {
  return externalApi.patch(`Order/patch`, { json: request }).json();
}

function useEditOrder() {
  const queryClient = useQueryClient();
  return useMutation((request: EditOrderRequest) => editOrder(request), {
    onSuccess: () => {
      queryClient.invalidateQueries(['Orders']);
    },
  });
}
export { useEditOrder };
