import {
  ActionIcon,
  Box,
  Button,
  Center,
  createStyles,
  Divider,
  Group,
  Modal,
  ScrollArea,
  Stack,
  Table,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from '@mantine/hooks';
import { useRepairLocations, useEditRepairLocation, useNewRepairLocation } from '../../../hooks';
import { IRepairLocation } from '../../../models';
import { Edit, Plus, X } from 'tabler-icons-react';

const useStyles = createStyles((theme) => ({
  label: {
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
    fontWeight: 500,
  },
  fieldGroup: {
    width: '48%',
  },
  tableTitle: {
    borderTop: `1px solid ${theme.colors.gray[4]}`,
    borderBottom: `1px solid ${theme.colors.gray[4]}`,
    backgroundColor: `${theme.colors.gray[1]}`,
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

type RepairLocationDisplayModalProps = {
  opened: boolean;
  onClose: () => void;
};

const RepairLocationDisplayModal = (props: RepairLocationDisplayModalProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const [isError, setIsError] = useState<boolean>(false);
  const [flashMessage, setFlashMessage] = useState<string>('');

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [repairLocationId, setRepairLocationId] = useState<string>('');
  const [repairLocationName, setRepairLocationName] = useState<string>('');

  const repairLocations = useRepairLocations();

  const newRepairLocation = useNewRepairLocation();
  const editRepairLocation = useEditRepairLocation();

  const repairLocationRows = (repairLocations.data ?? []).map((a: IRepairLocation) => {
    return (
      <tr key={a.repairLocationId}>
        <td style={{ width: '50%', wordWrap: 'break-word', textAlign: 'center' }}>{a.name}</td>
        <td>
          <Group noWrap position="center" spacing={35}>
            <ActionIcon
              onClick={() => {
                setIsError(false);
                setFlashMessage('');
                setIsEditing(true);
                setRepairLocationId(a.repairLocationId);
                setRepairLocationName(a.name);
              }}
            >
              <Edit style={{ color: theme.colors.blue[6] }} />
            </ActionIcon>
          </Group>
        </td>
      </tr>
    );
  });

  useEffect(() => {
    if (newRepairLocation.isSuccess) {
      setIsError(false);
      setFlashMessage('Repair Location Successfully Added!');
      setIsEditing(false);
      setRepairLocationId('');
      setRepairLocationName('');
    }
    if (newRepairLocation.isError) {
      setIsError(true);
      setFlashMessage('Something went wrong, Repair Location not Added. Please try again');
    }
  }, [newRepairLocation.status]);

  useEffect(() => {
    if (editRepairLocation.isSuccess) {
      setIsError(false);
      setFlashMessage('Repair Location Successfully Updated!');
      setIsEditing(false);
      setRepairLocationId('');
      setRepairLocationName('');
    }
    if (editRepairLocation.isError) {
      setIsError(true);
      setFlashMessage('Something went wrong, Repair Location not Updated. Please try again');
    }
  }, [editRepairLocation.status]);

  return (
    <Modal
      title={<Text style={{ color: `${isError ? 'red' : 'green'}` }}>{flashMessage}</Text>}
      opened={props.opened}
      size={isSmallMobile ? 'xs' : 'md'}
      onClose={() => {
        setIsError(false);
        setFlashMessage('');
        setIsEditing(false);
        setRepairLocationId('');
        setRepairLocationName('');
        props.onClose();
      }}
    >
      <Box>
        <Center>
          <Text size="xl" weight={500} align="center">
            Repair Locations
          </Text>
        </Center>
        <Divider mt={10} />
        <ScrollArea type="hover" style={{ height: '250px', tableLayout: 'fixed' }}>
          {repairLocationRows[0] && (
            <Table style={{ tableLayout: 'fixed', width: '100%' }}>
              <tbody>{repairLocationRows}</tbody>
            </Table>
          )}
          {!repairLocationRows[0] && (
            <Text weight={500} align="center">
              Nothing Found
            </Text>
          )}
        </ScrollArea>
        <Divider mb={10} />
        <Stack align="stretch" spacing={5}>
          <TextInput
            value={repairLocationName}
            onChange={(e) => {
              setRepairLocationName(e.target.value);
            }}
            rightSection={
              <ActionIcon
                size={20}
                onClick={() => {
                  setIsEditing(false);
                  setRepairLocationId('');
                  setRepairLocationName('');
                  setIsError(false);
                  setFlashMessage('');
                }}
              >
                <X />
              </ActionIcon>
            }
          />
          {!isEditing && (
            <Button
              variant="outline"
              onClick={() => {
                if (repairLocationName.length > 0)
                  newRepairLocation.mutate({ name: repairLocationName });
              }}
            >
              <Plus /> Add a New Repair Location
            </Button>
          )}
          {isEditing && (
            <Button
              variant="outline"
              onClick={() => {
                if (repairLocationName.length > 0)
                  editRepairLocation.mutate({
                    repairLocationId: repairLocationId,
                    name: repairLocationName,
                  });
              }}
            >
              <Edit /> Update Repair Location Name
            </Button>
          )}
        </Stack>
      </Box>
    </Modal>
  );
};

export default RepairLocationDisplayModal;
