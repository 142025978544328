import {
  Center,
  Text,
  Modal,
  useMantineTheme,
  Stack,
  createStyles,
  Group,
  Box,
  ScrollArea,
  ActionIcon,
  Textarea,
  Button,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import {
  useEditTrackedPatientEquipmentDeliveryNotes,
  useEditUndeliveredEquipmentNotes,
  useUserId,
} from '../../../../hooks';
import { useEffect, useState } from 'react';
import { ArrowLeft, DeviceFloppy, Edit, X } from 'tabler-icons-react';

const useStyles = createStyles((theme) => ({
  notesCard: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    paddingLeft: theme.spacing.sm,
    paddingRight: theme.spacing.sm,
    paddingTop: 3,
    width: '100%',
    height: '125px',
  },
  infoBox: {
    color: `${theme.colors.gray[6]}`,
    border: `1px solid ${theme.colors.gray[6]}`,
    borderRadius: theme.radius.lg,
    width: 'fit-content',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  groupLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.sm,
    whiteSpace: 'pre',
  },
  fieldLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
  },
  editLabel: {
    fontSize: theme.fontSizes.xs,
    fontWeight: 500,
    whiteSpace: 'pre',
  },
  detail: {
    fontSize: theme.fontSizes.sm,
    paddingLeft: 10,
  },
  fieldGroup: {
    width: '48%',
  },
  clickableIcon: {
    color: `${theme.colors.blue[6]}`,
    padding: '0 !important',
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

type Props = {
  opened: boolean;
  trackedPatientEquipmentId?: string;
  undeliveredEquipmentId?: string;
  notes: string;
  onClose: () => void;
};

const EquipmentDeliveryNotesModal = (props: Props) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const currentUserId = useUserId();
  const editTrackedPatientEquipmentDeliveryNotes = useEditTrackedPatientEquipmentDeliveryNotes();
  const editUndeliveredEquipmentNotes = useEditUndeliveredEquipmentNotes();

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');

  const [notes, setNotes] = useState<string>('');
  const [editNotes, setEditNotes] = useState<string>('');

  useEffect(() => {
    setNotes(props.notes);
  }, []);

  useEffect(() => {
    setIsError(false);
    setMessage('');
  }, [props.opened]);

  function SaveChanges() {
    if (props.trackedPatientEquipmentId) {
      editTrackedPatientEquipmentDeliveryNotes.mutate({
        trackedPatientEquipmentId: props.trackedPatientEquipmentId,
        deliveryNotes: editNotes,
        lastUpdatedUserId: currentUserId.data!.userId,
      });
    }
    if (props.undeliveredEquipmentId) {
      editUndeliveredEquipmentNotes.mutate({
        undeliveredEquipmentId: props.undeliveredEquipmentId,
        notes: editNotes,
        lastUpdatedUserId: currentUserId.data!.userId,
      });
    }
  }

  useEffect(() => {
    if (editTrackedPatientEquipmentDeliveryNotes.isError || editUndeliveredEquipmentNotes.isError) {
      setIsError(true);
      setMessage('Something went wrong, Equipment Delivery Notes not Updated');
    } else if (
      editTrackedPatientEquipmentDeliveryNotes.isSuccess ||
      editUndeliveredEquipmentNotes.isSuccess
    ) {
      setNotes(editNotes);
      setIsError(false);
      setMessage('Equipment Delivery Notes Successfully Updated!');
    }
  }, [editTrackedPatientEquipmentDeliveryNotes.status, editUndeliveredEquipmentNotes.status]);

  return (
    <Modal
      title={<Text color={isError ? 'red' : 'green'}>{message}</Text>}
      opened={props.opened}
      size="xs"
      yOffset="15vh"
      onClose={() => {
        props.onClose();
      }}
    >
      <Stack spacing={0}>
        <Center>
          <Text size="xl" weight={500} align="center" mb={`calc(${theme.spacing.xs} / 2)`}>
            Equipment Delivery Notes
          </Text>
        </Center>
        <Box mb={theme.spacing.xs}>
          {!isEditing && (
            <Stack spacing={0}>
              <Box style={{ position: 'relative' }} className={classes.notesCard}>
                <ScrollArea>
                  <Text size="sm">{notes}</Text>
                </ScrollArea>
                <ActionIcon
                  variant="transparent"
                  className={classes.clickableIcon}
                  style={{ position: 'absolute', bottom: 0, right: 0 }}
                  onClick={() => {
                    setEditNotes(notes);
                    setIsEditing(true);
                  }}
                >
                  <Edit />
                </ActionIcon>
              </Box>
            </Stack>
          )}
          {isEditing && (
            <Stack spacing={0}>
              <Box style={{ position: 'relative', padding: 0 }} className={classes.notesCard}>
                <ScrollArea style={{ height: '100%' }}>
                  <Textarea
                    variant="unstyled"
                    mx="sm"
                    autosize
                    minRows={4}
                    value={editNotes}
                    onChange={(a) => {
                      setEditNotes(a.target.value);
                    }}
                    styles={{ input: { padding: 0 } }}
                  />
                </ScrollArea>
                <Group spacing={0} style={{ position: 'absolute', bottom: 0, right: 0 }}>
                  <ActionIcon
                    variant="transparent"
                    className={classes.clickableIcon}
                    onClick={() => {
                      setIsEditing(false);
                      SaveChanges();
                    }}
                  >
                    <DeviceFloppy style={{ marginBottom: 4 }} />
                  </ActionIcon>
                  <ActionIcon
                    variant="transparent"
                    className={classes.clickableIcon}
                    onClick={() => {
                      setIsEditing(false);
                    }}
                  >
                    <X style={{ marginBottom: 4 }} />
                  </ActionIcon>
                </Group>
              </Box>
            </Stack>
          )}
        </Box>
      </Stack>
    </Modal>
  );
};

export default EquipmentDeliveryNotesModal;
