import {
  Center,
  Text,
  Modal,
  useMantineTheme,
  Stack,
  Divider,
  createStyles,
  Group,
  ActionIcon,
  Button,
  Textarea,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useMediaQuery } from '@mantine/hooks';
import { Clock, Check } from 'tabler-icons-react';
import { useCompleteTrackedEquipmentRepair, useUserId } from '../../../../hooks';
import { useEffect, useRef, useState } from 'react';
import { DatePicker, TimeInput } from '@mantine/dates';
import dayjs from 'dayjs';
import { ITrackedEquipment, ITrackedEquipmentRepair } from '../../../../models';
import TrackedEquipmentDetailSection from '../TrackedEquipmentDetailSection';

const useStyles = createStyles((theme) => ({
  equipmentBox: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    paddingLeft: 3,
    paddingTop: 3,
    paddingBottom: 3,
    paddingRight: theme.spacing.md,
    width: '100%',
    height: '70',
  },
  label: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
  },
  detail: {
    fontSize: theme.fontSizes.sm,
    paddingLeft: 10,
  },
}));

type Props = {
  opened: boolean;
  trackedEquipment: ITrackedEquipment;
  repair: ITrackedEquipmentRepair;
  onComplete: (returnedDateTime?: Date) => void;
  onClose: () => void;
};

const TrackedEquipmentRepairCompleteModal = (props: Props) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const timeInputRef = useRef<any | null>(null);

  const currentUserId = useUserId();
  const completeRepair = useCompleteTrackedEquipmentRepair();

  const [isError, setIsError] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [submitClicked, setSubmitClicked] = useState<boolean>(false);
  const [buttonsLocked, setButtonsLocked] = useState<boolean>(false);

  const [returnedDate, setReturnedDate] = useState<Date | undefined>();
  const [returnedTime, setReturnedTime] = useState<Date | undefined>();
  const [returnedDateTime, setReturnedDateTime] = useState<Date | undefined>();
  const [notes, setNotes] = useState<string | undefined>(props.repair.notes);

  const createForm = useForm({
    initialValues: {
      trackedEquipmentRepairId: props.repair.trackedEquipmentRepairId,
      returnedDateTime: new Date(),
      lastUpdatedUserId: '',
    },
    validate: {},
  });

  async function submitTrackedPatientEquipmentReturn() {
    setSubmitClicked(true);

    if (returnedDate && returnedTime) {
      let newReturnedDate: Date = new Date(returnedDate);
      let newReturnedTime: Date = new Date(returnedTime);
      let returnedDateTime: Date = new Date(
        new Date(
          Date.UTC(
            newReturnedDate.getFullYear(),
            newReturnedDate.getMonth(),
            newReturnedDate.getDate(),
            newReturnedTime.getHours(),
            newReturnedTime.getMinutes(),
            newReturnedTime.getSeconds()
          )
        )
      );

      setReturnedDateTime(returnedDateTime);
      completeRepair.mutate({
        trackedEquipmentRepairId: props.repair.trackedEquipmentRepairId,
        returnedDateTime: returnedDateTime,
        notes: notes,
        lastUpdatedUserId: currentUserId.data!.userId,
      });
    }
  }

  useEffect(() => {
    if (submitClicked) {
      if (completeRepair.isSuccess) {
        setSubmitClicked(false);
        props.onComplete(returnedDateTime);
        createForm.reset();
      } else if (completeRepair.isError) {
        setMessage('Something went wrong, Equipment not Returned. Please try again');
        setSubmitClicked(false);
        setIsError(true);
        setButtonsLocked(false);
      }
    }
  }, [completeRepair.status]);

  useEffect(() => {
    if (props.opened) {
      setReturnedDate(new Date());
      setReturnedTime(new Date());
      setReturnedDateTime(new Date());
      setNotes(props.repair.notes);
    }
  }, [props.opened]);

  return (
    <Modal
      title={<Text color={isError ? 'red' : 'green'}>{message}</Text>}
      opened={props.opened}
      size={isSmallMobile ? 'xs' : 'md'}
      onClose={() => {
        props.onClose();
      }}
    >
      <form
        onSubmit={createForm.onSubmit(() => {
          setButtonsLocked(true);
          setIsError(false);
          submitTrackedPatientEquipmentReturn();
        })}
        autoComplete="off"
      >
        <Stack spacing={0}>
          <Center>
            <Text size="xl" weight={500} align="center" mb={`calc(${theme.spacing.xs} / 2)`}>
              Mark Repair as Completed
            </Text>
          </Center>
          <TrackedEquipmentDetailSection trackedEquipment={props.trackedEquipment} readOnly />

          <Divider my={theme.spacing.xs} />
          <Text size="md" weight={500} align="center" mb={`calc(${theme.spacing.xs} / 2)`}>
            Date Returned
          </Text>
          <Stack align="stretch" mx={`calc(${theme.spacing.xl} * 2)`} spacing={0}>
            <Center>
              <DatePicker
                firstDayOfWeek={0}
                value={returnedDate}
                onChange={(e) => {
                  setReturnedDate(e as Date);
                }}
              />
            </Center>
            <Text className={classes.label} style={{ color: 'black', fontWeight: 500 }}>
              Time Returned
            </Text>
            <TimeInput
              ref={timeInputRef}
              rightSection={
                <ActionIcon onClick={() => timeInputRef.current!.showPicker()}>
                  <Clock size="1rem" />
                </ActionIcon>
              }
              required
              ml={5}
              value={dayjs(returnedTime).format('HH:mm')}
              onChange={(e) => {
                let inputDateTime = new Date();
                inputDateTime.setHours(parseInt(e.currentTarget.value.split(':')[0]));
                inputDateTime.setMinutes(parseInt(e.currentTarget.value.split(':')[1]));
                setReturnedTime(inputDateTime);
              }}
            />
          </Stack>
          <Stack align="stretch" spacing={0} mt={theme.spacing.xs}>
            <Text className={classes.label} style={{ color: 'black', fontWeight: 500 }}>
              Notes
            </Text>
            <Textarea
              minRows={4}
              value={notes}
              onChange={(e) => {
                if (notes && notes.length === 0) {
                  setNotes(undefined);
                } else {
                  setNotes(e.currentTarget.value);
                }
              }}
            />
          </Stack>
          <Divider my={theme.spacing.sm} />
          <Button variant="outline" disabled={!returnedDate && !returnedTime} type="submit">
            <Check style={{ marginRight: 5 }} />
            Return Selected Equipment
          </Button>
        </Stack>
      </form>
    </Modal>
  );
};

export default TrackedEquipmentRepairCompleteModal;
