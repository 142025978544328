import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type EditTrackedEquipmentCleanRequest = {
  trackedEquipmentCleanId: string;
  notes: string;
  lastUpdatedUserId: string;
};

type EditTrackedEquipmentCleanResponse = {
  trackedEquipmentCleanId: number;
};

function editTrackedEquipmentClean(
  request: EditTrackedEquipmentCleanRequest
): Promise<EditTrackedEquipmentCleanResponse> {
  return externalApi.patch(`TrackedEquipmentClean/patch/notes`, { json: request }).json();
}

function useEditTrackedEquipmentCleanNotes() {
  const queryClient = useQueryClient();
  return useMutation(
    (request: EditTrackedEquipmentCleanRequest) => editTrackedEquipmentClean(request),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['AllTrackedEquipmentClean']);
      },
    }
  );
}
export { useEditTrackedEquipmentCleanNotes };
