import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

function fetchOrdersCount(request: {
  filters: number[];
  search: string;
}): Promise<{ count: number }> {
  return externalApi.post(`Order/all/count`, { json: request }).json();
}

export function useOrdersCount() {
  const queryClient = useQueryClient();
  return useMutation(
    (request: { filters: number[]; search: string }) => fetchOrdersCount(request),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('OrdersCount');
      },
    }
  );
}
