import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type NewTrackedEquipmentRequest = {
  equipmentId: string;
  serialNumber: string;
  warrantyExpiryDate?: Date;
  percentOxygen: number;
  flowRate: number;
  onHours: number;
  offHours: number;
  notes: string;
  lastUpdatedUserId: string;
};

type NewTrackedEquipmentResponse = {
  trackedEquipmentId: number;
};

function newTrackedEquipment(
  request: NewTrackedEquipmentRequest
): Promise<NewTrackedEquipmentResponse> {
  return externalApi.post(`TrackedEquipment/new`, { json: request }).json();
}

function useNewTrackedEquipment() {
  const queryClient = useQueryClient();
  return useMutation((request: NewTrackedEquipmentRequest) => newTrackedEquipment(request), {
    onSuccess: () => {
      queryClient.invalidateQueries(['AllTrackedEquipment']);
    },
  });
}
export { useNewTrackedEquipment };
