import {
  ActionIcon,
  createStyles,
  Divider,
  Group,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React, { useEffect } from 'react';
import { User } from 'tabler-icons-react';
import { ITrackedEquipment } from '../../../models';
import dayjs from 'dayjs';
import { usePatient } from '../../../hooks';
import { useNavigate } from 'react-router-dom';

const useStyles = createStyles((theme) => ({
  groupLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.sm,
    whiteSpace: 'pre',
  },
  fieldLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
  },
  detail: {
    fontSize: theme.fontSizes.md,
    paddingLeft: 10,
  },
  fieldGroup: {
    width: '48%',
  },
  clickableIcon: {
    color: `${theme.colors.blue[6]}`,
    padding: '0 !important',
  },
}));

type TrackedEquipmentPatientSectionProps = {
  trackedEquipment: ITrackedEquipment;
  header?: string;
};

const TrackedEquipmentPatientSection = (props: TrackedEquipmentPatientSectionProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const patient = usePatient(props.trackedEquipment.patientId ?? '0');

  useEffect(() => {
    patient.refetch();
  }, [props.trackedEquipment]);

  return (
    <Stack spacing={0}>
      <Group spacing={0} position="apart" noWrap>
        <Text className={classes.groupLabel}>{props.header ?? 'Delivered To'}</Text>
        <ActionIcon
          disabled={patient.data?.patientId === '0'}
          variant="transparent"
          className={classes.clickableIcon}
          onClick={() => {
            if (patient.isSuccess) {
              navigate('../patients', {
                state: {
                  patient: patient.data,
                },
              });
            }
          }}
        >
          <User style={{ marginBottom: 4 }} />
        </ActionIcon>
      </Group>
      <Divider mb={`calc(${theme.spacing.xs} / 2)`} />
      <Group spacing={theme.spacing.sm} mb={theme.spacing.xs} noWrap>
        <Stack spacing={0} style={{ width: '50%' }}>
          <Text className={classes.fieldLabel}>Patient Name</Text>
          <Text className={classes.detail}>{props.trackedEquipment.patientName}</Text>
        </Stack>
        <Stack spacing={0}>
          <Text className={classes.fieldLabel}>Delivered On</Text>
          <Text className={classes.detail}>
            {props.trackedEquipment.checkedOutDate
              ? dayjs(props.trackedEquipment.checkedOutDate).format('YYYY-MM-DD')
              : '-'}
          </Text>
        </Stack>
      </Group>
    </Stack>
  );
};

export default TrackedEquipmentPatientSection;
