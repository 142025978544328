export const msalConfig = {
  auth: {
    clientId: '59307cf5-7229-4897-91fb-c782b5a817ac',
    authority: 'https://login.microsoftonline.com/ea73f753-6976-419e-952a-d3fd7aae0ba4',
    redirectUri: '/',
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to 'true' if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ['api://59307cf5-7229-4897-91fb-c782b5a817ac/access_as_user'],
};
