import {
  Center,
  Text,
  Modal,
  useMantineTheme,
  Stack,
  Divider,
  createStyles,
  Group,
  Anchor,
  ActionIcon,
  Button,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useMediaQuery } from '@mantine/hooks';
import { ArrowLeft, Clock, Plus } from 'tabler-icons-react';
import { useNewTrackedPatientEquipment, useTrackedEquipment, useUserId } from '../../../../hooks';
import { useEffect, useRef, useState } from 'react';
import { DatePicker, TimeInput } from '@mantine/dates';
import dayjs from 'dayjs';

const useStyles = createStyles((theme) => ({
  equipmentBox: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    paddingLeft: 3,
    paddingTop: 3,
    paddingBottom: 3,
    paddingRight: theme.spacing.md,
    width: '100%',
    height: '70',
  },
  label: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
  },
  detail: {
    fontSize: theme.fontSizes.sm,
    paddingLeft: 10,
  },
}));

type Props = {
  opened: boolean;
  trackedEquipmentId: string;
  patientId: string;
  onCreate: () => void;
  onClose: () => void;
};

const TrackedEquipmentPatientAssignModal = (props: Props) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const timeInputRef = useRef<any | null>(null);

  const currentUserId = useUserId();
  const trackedEquipment = useTrackedEquipment(props.trackedEquipmentId);
  const newTrackedPatientEquipment = useNewTrackedPatientEquipment();

  const [isError, setIsError] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [submitClicked, setSubmitClicked] = useState<boolean>(false);
  const [buttonsLocked, setButtonsLocked] = useState<boolean>(false);

  const [deliveredDate, setDeliveredDate] = useState<Date | undefined>();
  const [deliveredTime, setDeliveredTime] = useState<Date | undefined>();

  const createForm = useForm({
    initialValues: {
      trackedEquipmentId: props.trackedEquipmentId,
      patientId: props.patientId,
      deliveredDateTime: new Date(),
      lastUpdatedUserId: '',
    },
    validate: {},
  });

  async function submitTrackedPatientEquipmentCreate() {
    setSubmitClicked(true);

    if (deliveredDate && deliveredTime) {
      let combinedDate = deliveredDate;
      combinedDate.setTime(deliveredTime.getTime());

      newTrackedPatientEquipment.mutate({
        trackedEquipmentId: props.trackedEquipmentId,
        patientId: props.patientId,
        deliveredDateTime: combinedDate,
        lastUpdatedUserId: currentUserId.data!.userId,
      });
    }
  }

  useEffect(() => {
    if (submitClicked) {
      if (newTrackedPatientEquipment.isSuccess) {
        setSubmitClicked(false);
        props.onCreate();
        createForm.reset();
      } else if (newTrackedPatientEquipment.isError) {
        setMessage('Something went wrong, Equipment not Added. Please try again');
        setSubmitClicked(false);
        setIsError(true);
        setButtonsLocked(false);
      }
    }
  }, [newTrackedPatientEquipment.status]);

  useEffect(() => {
    trackedEquipment.refetch();
    if (props.opened) {
      setDeliveredDate(new Date());
      setDeliveredTime(new Date());
    }
  }, [props.trackedEquipmentId, props.opened]);

  return (
    <Modal
      title={<Text color={isError ? 'red' : 'green'}>{message}</Text>}
      opened={props.opened}
      size={isSmallMobile ? 'xs' : 'md'}
      onClose={() => {
        props.onClose();
      }}
    >
      <form
        onSubmit={createForm.onSubmit(() => {
          setButtonsLocked(true);
          setIsError(false);
          submitTrackedPatientEquipmentCreate();
        })}
        autoComplete="off"
      >
        <Stack spacing={0}>
          <Center>
            <Text size="xl" weight={500} align="center" mb={`calc(${theme.spacing.xs} / 2)`}>
              Equipment Delivery Details
            </Text>
          </Center>
          <Group>
            <Stack spacing={0} style={{ width: '50%' }}>
              <Text className={classes.label}>Equipment Name</Text>
              <Text className={classes.detail}>{trackedEquipment.data?.equipmentName ?? '-'}</Text>
            </Stack>
            <Stack spacing={0}>
              <Text className={classes.label}>Equipment Category</Text>
              <Text className={classes.detail}>
                {trackedEquipment.data?.equipmentTypeName ?? '-'}
              </Text>
            </Stack>
          </Group>

          <Stack spacing={0}>
            <Text className={classes.label}>Serial Number</Text>
            <Text className={classes.detail}>{trackedEquipment.data?.serialNumber ?? '-'}</Text>
          </Stack>
          <Center mb={`calc(${theme.spacing.xs} / 2)`}>
            <Anchor
              size="xs"
              align="left"
              onClick={() => {
                props.onClose();
              }}
            >
              <ArrowLeft size={15} style={{ marginRight: 3, marginBottom: -3 }} />
              <u>Return to Equipment Selection</u>
            </Anchor>
          </Center>
          <Divider mb={theme.spacing.xs} />
          <Text size="md" weight={500} align="center" mb={`calc(${theme.spacing.xs} / 2)`}>
            Date Delivered
          </Text>
          <Stack align="stretch" mx={`calc(${theme.spacing.xl} * 2)`} spacing={0}>
            <Center>
              <DatePicker
                firstDayOfWeek={0}
                value={deliveredDate}
                onChange={(e) => {
                  setDeliveredDate(e as Date);
                }}
              />
            </Center>
            <Text className={classes.label} style={{ color: 'black', fontWeight: 500 }}>
              Time Delivered
            </Text>
            <TimeInput
              ref={timeInputRef}
              rightSection={
                <ActionIcon onClick={() => timeInputRef.current!.showPicker()}>
                  <Clock size="1rem" />
                </ActionIcon>
              }
              required
              ml={5}
              value={dayjs(deliveredTime).format('HH:mm')}
              onChange={(e) => {
                let inputDateTime = new Date();
                inputDateTime.setHours(parseInt(e.currentTarget.value.split(':')[0]));
                inputDateTime.setMinutes(parseInt(e.currentTarget.value.split(':')[1]));
                setDeliveredTime(inputDateTime);
              }}
            />
          </Stack>
          <Divider my={theme.spacing.xs} />
          <Button variant="outline" disabled={!deliveredDate && !deliveredTime} type="submit">
            <Plus style={{ marginRight: 5 }} />
            Add Selected Equipment
          </Button>
        </Stack>
      </form>
    </Modal>
  );
};

export default TrackedEquipmentPatientAssignModal;
