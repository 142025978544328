import React from 'react';
import { useMutation, useQuery } from 'react-query';
import externalApi from '../../api';
import { IUser } from '../../models';

function fetchUser(userId: string): Promise<IUser> {
  return externalApi.get(`User/${userId}`).json();
}

export function useUser() {
  return useMutation((userId: string) => fetchUser(userId), {
    onSuccess: () => {},
  });
}
