import { Box, useMantineTheme, createStyles, Text, Grid, Group } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import dayjs from 'dayjs';
import React from 'react';
import { useReportDeliveriesCountByDate } from '../../hooks';

const useStyles = createStyles((theme, _params) => ({
  reportSummary: {
    borderBottom: `1px solid ${theme.colors.gray[5]}`,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    marginBottom: 10,
  },
  summaryLabel: {
    width: 175,
    fontWeight: 500,
    textAlign: 'right',
  },
}));

type DeliveriesReportSummaryProps = {
  reportedDate: Date;
  timeFrame: 'AM' | 'PM' | 'Full Day';
};

const DeliveriesReportSummary = (props: DeliveriesReportSummaryProps) => {
  const { classes, cx } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const deliveriesCount = useReportDeliveriesCountByDate({
    date: props.reportedDate,
    isAMTimeframe: props.timeFrame == 'AM' ? true : false,
    isFullDayTimeframe: props.timeFrame == 'Full Day' ? true : false,
  });

  return (
    <Box className={classes.reportSummary}>
      <Grid>
        <Grid.Col span={4}>
          <Group position="left">
            <Text size="md" className={classes.summaryLabel}>
              Reported Date:
            </Text>
            <Text size="md">{dayjs(props.reportedDate).format('YYYY-MM-DD')}</Text>
          </Group>
          <Group position="left">
            <Text size="md" className={classes.summaryLabel}>
              Reported Timeframe:
            </Text>
            <Text size="md">{props.timeFrame}</Text>
          </Group>
        </Grid.Col>
        <Grid.Col span={4}>
          <Group position="left">
            <Text size="md" className={classes.summaryLabel} ml={80}>
              Completed Deliveries:
            </Text>
            <Text size="md">{deliveriesCount.data?.deliveredCount}</Text>
          </Group>
          <Group position="left">
            <Text size="md" className={classes.summaryLabel} ml={80}>
              Incomplete Deliveries:
            </Text>
            <Text size="md">{deliveriesCount.data?.undeliveredCount}</Text>
          </Group>
          <Group position="left">
            <Text size="md" className={classes.summaryLabel} ml={80}>
              Pending Deliveries:
            </Text>
            <Text size="md">{deliveriesCount.data?.pendingCount}</Text>
          </Group>
        </Grid.Col>
        <Grid.Col span={4}>
          <Group position="right">
            <Text size="md" className={classes.summaryLabel}>
              Report Generated On:
            </Text>
            <Text size="md" mr={15}>
              {dayjs(new Date()).format('YYYY-MM-DD hh:mm A')}
            </Text>
          </Group>
        </Grid.Col>
      </Grid>
    </Box>
  );
};

export default DeliveriesReportSummary;
