import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';
import { IDelivery } from '../../models';

function getDelivery(deliveryId: string): Promise<IDelivery> {
  return externalApi.get(`Delivery/${deliveryId}`).json();
}

function useDelivery() {
  const queryClient = useQueryClient();
  return useMutation((deliveryId: string) => getDelivery(deliveryId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['Delivery']);
    },
  });
}

export { useDelivery };
