import { ActionIcon, Box, Group, Popover, Stack, Text } from '@mantine/core';
import { useClickOutside } from '@mantine/hooks';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { InfoCircle } from 'tabler-icons-react';
import { useUser } from '../../hooks';

type InfoButtonProps = {
  userId?: string;
  date?: Date;
  ml?: number | string;
  transparent?: boolean;
};

const InfoButton = (props: InfoButtonProps) => {
  const [infoOpen, setInfoOpen] = useState<boolean>(false);
  const ref = useClickOutside(() => {
    setInfoOpen(false);
  });

  const user = useUser();

  useEffect(() => {
    user.mutate(props.userId ?? '0');
  }, [props.userId]);

  return (
    <Box ml={props.ml}>
      <Popover opened={infoOpen} withArrow position="bottom" withinPortal>
        <Popover.Target>
          <ActionIcon
            variant={props.transparent ? 'transparent' : 'subtle'}
            ref={ref}
            onClick={() => {
              setInfoOpen(!infoOpen);
            }}
          >
            <InfoCircle />
          </ActionIcon>
        </Popover.Target>
        <Popover.Dropdown>
          <Stack align="center" spacing={0}>
            <Text size="xs" color="dimmed">
              Last Updated By:
            </Text>
            {props.userId && (
              <Text size="sm">
                {user.data?.userLastName ?? '-'}, {user.data?.userFirstName ?? '-'}
              </Text>
            )}
            {!props.userId && <Text size="sm">-, -</Text>}
            {props.date && <Text size="xs">{dayjs(props.date).format('YYYY-MM-DD')}</Text>}
            {props.date && <Text size="xs">{dayjs(props.date).format('hh:mm A')}</Text>}
          </Stack>
        </Popover.Dropdown>
      </Popover>
    </Box>
  );
};

export default InfoButton;
