import {
  Box,
  Button,
  Center,
  Checkbox,
  createStyles,
  Group,
  Modal,
  ScrollArea,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import dayjs from 'dayjs';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ClipboardList, Edit, FileInvoice } from 'tabler-icons-react';
import { IPatient } from '../../../models';
import { InfoButton } from '../../../components';

const useStyles = createStyles((theme) => ({
  label: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
  },
  detail: {
    fontSize: theme.fontSizes.md,
    paddingLeft: 10,
  },
  fieldGroup: {
    width: '48%',
  },
  checkFieldGroup: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      '&:last-of-type': {
        marginTop: 5,
      },
      '&:nth-last-of-type(2n)': {
        marginTop: 5,
      },
      width: 100,
    },
    [`@media (min-width: ${theme.breakpoints.sm})`]: {
      '&:first-of-type': {
        marginRight: 25,
      },
      width: 80,
    },
  },
  notesCard: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    paddingLeft: theme.spacing.sm,
    paddingRight: theme.spacing.sm,
    paddingTop: 3,
    width: '100%',
    height: '125px',
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

type PatientDetailModalProps = {
  patient: IPatient;
  opened: boolean;
  isCreated: boolean;
  isUpdated: boolean;
  isReactivated: boolean;
  isDeactivated: boolean;
  isError: boolean;
  onClose: () => void;
  onEdit: () => void;
  onViewEquipment: () => void;
};

const PatientDetailModal = (props: PatientDetailModalProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);
  const navigate = useNavigate();

  return (
    <Modal
      title={
        props.isUpdated ? (
          <Text color="green">Patient Successfully Updated!</Text>
        ) : props.isError ? (
          <Text color="red">Something went wrong, Patient not Updated</Text>
        ) : props.isCreated ? (
          <Text color="green">Patient Successfully Created!</Text>
        ) : props.isReactivated ? (
          <Text color="green">This Patient has been Successfully Reactivated!</Text>
        ) : props.isDeactivated ? (
          <Text color="green">This Patient has been Successfully Deactivated!</Text>
        ) : (
          ''
        )
      }
      opened={props.opened}
      size={isSmallMobile ? 'xs' : 'md'}
      onClose={() => {
        props.onClose();
      }}
    >
      <Box>
        <Center>
          <Text size="xl" weight={500} align="center">
            Complete Patient Information
          </Text>
          <InfoButton
            userId={props.patient.lastUpdatedUserId}
            date={props.patient.lastUpdatedDateTime}
            ml={5}
          />
        </Center>
        <ScrollArea></ScrollArea>

        <Stack align="stretch" style={{ padding: 5 }}>
          <Stack spacing={0}>
            <Text className={classes.label}>Patient Name</Text>
            <Text
              className={classes.detail}
            >{`${props.patient.lastName}, ${props.patient.firstName}`}</Text>
          </Stack>

          <Group>
            <Stack spacing={0} style={{ width: '50%' }}>
              <Text className={classes.label}>Last 4 Digits of{`\n`}Health Card Number</Text>
              <Text className={classes.detail}>{`#${props.patient.lastFourHCN}`}</Text>
            </Stack>
            <Stack spacing={0}>
              <Text className={classes.label}>{`\n`}Unique Number</Text>
              <Text className={classes.detail}>{`#${props.patient.claimNumber}`}</Text>
            </Stack>
          </Group>

          <Stack spacing={0}>
            <Text className={classes.label}>Phone Number</Text>
            <Text
              className={classes.detail}
            >{`(${props.patient.phoneNumber[0]}${props.patient.phoneNumber[1]}${props.patient.phoneNumber[2]}) ${props.patient.phoneNumber[3]}${props.patient.phoneNumber[4]}${props.patient.phoneNumber[5]}-${props.patient.phoneNumber[6]}${props.patient.phoneNumber[7]}${props.patient.phoneNumber[8]}${props.patient.phoneNumber[9]}`}</Text>
          </Stack>

          <Group>
            <Stack spacing={0} style={{ width: '50%' }}>
              <Text className={classes.label}>Street Address</Text>
              <Text className={classes.detail}>{props.patient.streetAddress}</Text>
            </Stack>
            <Stack spacing={0}>
              <Text className={classes.label}>City</Text>
              <Text className={classes.detail}>{props.patient.cityName}</Text>
            </Stack>
          </Group>

          <Stack spacing={0}>
            <Text className={classes.label}>Funding Type</Text>
            <Text className={classes.detail}>{props.patient.fundingTypeName}</Text>
          </Stack>

          <Group position="center" spacing={!isSmallMobile ? 25 : 0}>
            <Group position="center" grow noWrap spacing={!isSmallMobile ? 0 : 25}>
              <Stack align="stretch" spacing={5} className={classes.checkFieldGroup}>
                <Text
                  className={classes.label}
                  align="center"
                  style={{ marginLeft: `${!isSmallMobile ? '' : '-4px'}` }}
                >
                  Yearly{!isSmallMobile ? `\n` : ' '}Appointment
                </Text>
                <Center>
                  <Checkbox checked={props.patient.yearlyAppointment} readOnly />
                </Center>
              </Stack>
              <Stack align="stretch" spacing={5} className={classes.checkFieldGroup}>
                <Text className={classes.label} align="center">
                  {!isSmallMobile ? `\n` : ''}Covid
                </Text>
                <Center>
                  <Checkbox checked={props.patient.covid} readOnly />
                </Center>
              </Stack>
            </Group>
            <Group position="center" grow noWrap spacing={!isSmallMobile ? 0 : 25}>
              <Stack align="stretch" spacing={5} className={classes.checkFieldGroup}>
                <Text className={classes.label} align="center">
                  Lakeridge{!isSmallMobile ? `\n` : ' '}Gardens
                </Text>
                <Center>
                  <Checkbox checked={props.patient.lakeridgeGardens} readOnly />
                </Center>
              </Stack>
              <Stack align="stretch" spacing={5} className={classes.checkFieldGroup}>
                <Text className={classes.label} align="center">
                  {!isSmallMobile ? `\n` : ''}Hospice PT
                </Text>
                <Center>
                  <Checkbox checked={props.patient.hospicePT} readOnly />
                </Center>
              </Stack>
            </Group>
          </Group>

          <Group position={!isSmallMobile ? 'apart' : 'left'}>
            <Stack spacing={0} className={!isSmallMobile ? '' : classes.fieldGroup}>
              <Text className={classes.label}>Start Date</Text>
              <Text className={classes.detail}>
                {dayjs(props.patient.startDate).format('YYYY-MM-DD')}
              </Text>
            </Stack>
            <Stack spacing={0}>
              <Text className={classes.label}>Discharge Date</Text>
              {props.patient.dischargeDate && (
                <Text className={classes.detail}>
                  {dayjs(props.patient.dischargeDate).format('YYYY-MM-DD')}
                </Text>
              )}
              {!props.patient.dischargeDate && <Text className={classes.detail}>-</Text>}
            </Stack>
            <Stack spacing={0}>
              <Text className={classes.label}>Death Date</Text>
              {props.patient.deathDate && (
                <Text className={classes.detail}>
                  {dayjs(props.patient.deathDate).format('YYYY-MM-DD')}
                </Text>
              )}
              {!props.patient.deathDate && <Text className={classes.detail}>-</Text>}
            </Stack>
          </Group>

          <Stack spacing={0}>
            <Text className={classes.label}>Notes</Text>
            <ScrollArea className={classes.notesCard}>
              <Text>{`${props.patient.notes}`}</Text>
            </ScrollArea>
          </Stack>
        </Stack>

        <Group position="apart" grow noWrap mt="md">
          <Button variant="outline" onClick={() => props.onEdit()} style={{ padding: 0 }}>
            <Edit style={{ marginRight: 5 }} />{' '}
            <Text className={classes.buttonText}>Edit Patient</Text>
          </Button>
          <Button variant="outline" onClick={() => props.onViewEquipment()} px={10}>
            <FileInvoice style={{ marginRight: 5 }} />{' '}
            <Text className={classes.buttonText}>{`${
              !isSmallMobile ? 'Patient' : ''
            } Equipment`}</Text>
          </Button>
        </Group>
        {props.patient.active && !props.isDeactivated && (
          <Group grow noWrap mt={5}>
            <Button
              variant="outline"
              onClick={() => {
                navigate('../orders', {
                  state: {
                    filteredPatient: {
                      patientId: props.patient.patientId,
                      firstName: props.patient.firstName,
                      lastName: props.patient.lastName,
                      phoneNumber: props.patient.phoneNumberFormatted,
                      streetAddress: props.patient.streetAddress,
                      cityName: props.patient.cityName,
                      lastFourHCN: props.patient.lastFourHCN,
                      claimNumber: props.patient.claimNumber,
                    },
                  },
                });
              }}
            >
              <ClipboardList style={{ marginRight: 5 }} />{' '}
              <Text className={classes.buttonText}>Create Order</Text>
            </Button>
          </Group>
        )}
        {props.isReactivated && (
          <Group grow noWrap mt={5}>
            <Button
              variant="outline"
              onClick={() => {
                navigate('../orders', {
                  state: {
                    filteredPatient: {
                      patientId: props.patient.patientId,
                      firstName: props.patient.firstName,
                      lastName: props.patient.lastName,
                      phoneNumber: props.patient.phoneNumber,
                      streetAddress: props.patient.streetAddress,
                      cityName: props.patient.cityName,
                      lastFourHCN: props.patient.lastFourHCN,
                      claimNumber: props.patient.claimNumber,
                    },
                  },
                });
              }}
            >
              <ClipboardList style={{ marginRight: 5 }} />{' '}
              <Text className={classes.buttonText}>Create Order</Text>
            </Button>
          </Group>
        )}
      </Box>
    </Modal>
  );
};

export default PatientDetailModal;
