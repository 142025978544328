import {
  Box,
  createStyles,
  Group,
  useMantineTheme,
  Text,
  ScrollArea,
  Center,
  Button,
  Divider,
  Stack,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { CalendarEvent, Check, Edit, FileInvoice } from 'tabler-icons-react';
import { useDelivery } from '../../../../hooks';
import { PatientNotes } from '../../../../components';

const useStyles = createStyles((theme) => ({
  label: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
  },
  detail: {
    fontSize: theme.fontSizes.md,
    paddingLeft: 10,
  },
  fieldGroup: {
    width: '48%',
  },
  notesCard: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    paddingLeft: theme.spacing.sm,
    paddingRight: theme.spacing.sm,
    paddingTop: 3,
    width: '100%',
    height: '125px',
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

type DeliveryDetailModalContentProps = {
  selectedDate: Date;
  deliveryId: string;
  onClose: () => void;
  onViewEquipment: () => void;
  onEdit: () => void;
  onConvert: () => void;
  onRescheduleUndelivered: () => void;
  onRescheduleIncomplete: () => void;
};

const DeliveryDetailModalContent = (props: DeliveryDetailModalContentProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const delivery = useDelivery();

  useEffect(() => {
    delivery.mutate(props.deliveryId);
  }, [props.deliveryId]);

  return (
    <Box>
      {delivery.data && (
        <Box>
          <Center>
            <Text size="xl" weight={500} align="center">
              {`Delivery Information (${
                delivery.data.hasUndeliveredEquipment
                  ? 'Incomplete'
                  : delivery.data.isDelivered
                  ? 'Delivered'
                  : 'Undelivered'
              })`}
            </Text>
          </Center>

          <Stack align="stretch" style={{ padding: 5 }}>
            <Group>
              <Stack spacing={0} style={{ width: '50%' }}>
                <Text className={classes.label}>Patient Name</Text>
                <Group spacing={5} noWrap>
                  <Text
                    className={classes.detail}
                  >{`${delivery.data.patientLastName}, ${delivery.data.patientFirstName}`}</Text>
                </Group>
              </Stack>
              <Stack spacing={0}>
                <Text className={classes.label}>Phone number</Text>
                <Text className={classes.detail}>{`${delivery.data.patientPhoneNumber}`}</Text>
              </Stack>
            </Group>
            <Group>
              <Stack spacing={0} style={{ width: '50%' }}>
                <Text className={classes.label}>Delivery Address</Text>
                <Text className={classes.detail}>{`${delivery.data.deliveryAddress}`}</Text>
              </Stack>
              <Stack spacing={0}>
                <Text className={classes.label}>City</Text>
                <Text className={classes.detail}>{`${delivery.data.deliveryCity}`}</Text>
              </Stack>
            </Group>
            <PatientNotes notes={delivery.data.patientNotes} />

            {delivery.data.deliveredDateTime && (
              <Group>
                <Stack spacing={0} style={{ width: '50%' }}>
                  <Text className={classes.label}>Delivered On</Text>
                  <Text className={classes.detail}>{`${dayjs(
                    delivery.data.deliveredDateTime
                  ).format('YYYY-MM-DD')}`}</Text>
                </Stack>
                <Stack spacing={0}>
                  <Text className={classes.label}>Time of Delivery</Text>
                  <Text className={classes.detail}>{`${dayjs(
                    delivery.data.deliveredDateTime
                  ).format('h:mm A')}`}</Text>
                </Stack>
              </Group>
            )}

            <Stack spacing={0} style={{ width: '100%' }}>
              {delivery.data.deliveredDateTime && (
                <Text className={classes.label}>Delivered By</Text>
              )}
              {!delivery.data.deliveredDateTime && (
                <Text className={classes.label}>Marked Undelivered By</Text>
              )}
              <Text
                className={classes.detail}
              >{`${delivery.data.deliveringUserLastName}, ${delivery.data.deliveringUserFirstName}`}</Text>
            </Stack>

            <Stack spacing={0}>
              <Text className={classes.label}>Delivery Notes</Text>
              <ScrollArea className={classes.notesCard}>
                <Text>{`${delivery.data.notes}`}</Text>
              </ScrollArea>
            </Stack>

            {delivery.data && !delivery.data.isDelivered && (
              <Stack align="stretch" spacing={5}>
                <Button
                  variant="outline"
                  onClick={() => {
                    props.onViewEquipment();
                  }}
                >
                  <FileInvoice style={{ marginRight: 5 }} />{' '}
                  <Text className={classes.buttonText}>View Equipment</Text>
                </Button>

                <Group position="apart" spacing={5} grow noWrap>
                  <Button
                    onClick={() => {
                      props.onConvert();
                    }}
                  >
                    <Check style={{ marginRight: 5 }} />{' '}
                    <Text className={classes.buttonText}>Delivered</Text>
                  </Button>
                  <Button
                    variant="outline"
                    onClick={() => {
                      props.onEdit();
                    }}
                  >
                    <Edit style={{ marginRight: 5 }} />{' '}
                    <Text className={classes.buttonText}>Edit Notes</Text>
                  </Button>
                </Group>
                <Divider my="xs" />
                <Button
                  variant="outline"
                  onClick={() => {
                    props.onRescheduleUndelivered();
                  }}
                >
                  <CalendarEvent style={{ marginRight: 5 }} />{' '}
                  <Text className={classes.buttonText}>Reschedule</Text>
                </Button>
              </Stack>
            )}
            {delivery.data && delivery.data.isDelivered && (
              <Stack align="stretch" spacing={5}>
                <Button
                  variant="outline"
                  onClick={() => {
                    props.onViewEquipment();
                  }}
                >
                  <FileInvoice style={{ marginRight: 5 }} />{' '}
                  <Text className={classes.buttonText}>View Equipment</Text>
                </Button>
                <Button
                  variant="outline"
                  onClick={() => {
                    props.onEdit();
                  }}
                >
                  <Edit style={{ marginRight: 5 }} />{' '}
                  <Text className={classes.buttonText}>Edit Notes</Text>
                </Button>
                {delivery.data.hasUndeliveredEquipment && (
                  <Stack align="stretch" spacing={5}>
                    <Divider my="xs" />
                    <Button
                      variant="outline"
                      onClick={() => {
                        props.onRescheduleIncomplete();
                      }}
                    >
                      <CalendarEvent style={{ marginRight: 5 }} />{' '}
                      <Text className={classes.buttonText}>Reschedule</Text>
                    </Button>
                  </Stack>
                )}
              </Stack>
            )}
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default DeliveryDetailModalContent;
