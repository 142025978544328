import {
  Box,
  createStyles,
  Modal,
  useMantineTheme,
  Text,
  SegmentedControl,
  Group,
  Button,
  Stack,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { useMediaQuery } from '@mantine/hooks';
import React, { useState } from 'react';
import { FileAnalytics, X } from 'tabler-icons-react';

const useStyles = createStyles((theme) => ({
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

type ReportPeriodModalProps = {
  opened: boolean;
  selectedDate: Date;
  selectedTimeframe: 'AM' | 'PM' | 'Full Day';
  onClose: () => void;
  onUpdate: (newDate: Date, newTimeframe: 'AM' | 'PM' | 'Full Day') => void;
};

const ReportPeriodModal = (props: ReportPeriodModalProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const [reportedDate, setReportedDate] = useState<Date>(props.selectedDate);
  const [reportedTimeframe, setReportedTimeframe] = useState<'AM' | 'PM' | 'Full Day'>(
    props.selectedTimeframe
  );

  return (
    <Modal
      opened={props.opened}
      size={isSmallMobile ? 'xs' : 'md'}
      onClose={() => {
        props.onClose();
      }}
    >
      <Box>
        <Stack spacing={5}>
          <Text size="xl" weight={500} align="center">
            Select a Date and Timeframe to Generate a Report For
          </Text>
          <Group spacing={isSmallMobile ? 10 : 20} noWrap>
            <Text align="right" style={{ width: 80 }}>
              Date:
            </Text>
            <DatePickerInput
              valueFormat="YYYY-MM-DD"
              firstDayOfWeek={0}
              defaultValue={reportedDate}
              clearable={false}
              dropdownType="modal"
              modalProps={{
                title: 'Date to Report',
                withCloseButton: true,
                closeButtonProps: { style: { position: 'fixed', right: 10 } },
                yOffset: '15vh',
                styles: { title: { fontWeight: 500, textAlign: 'center', width: '100%' } },
              }}
              onChange={(e) => {
                setReportedDate(e!);
              }}
            />
          </Group>
          <Group spacing={isSmallMobile ? 0 : 10}>
            <Text align="right" style={{ width: 80 }}>
              Timeframe:
            </Text>
            <SegmentedControl
              color="blue"
              data={[
                { label: 'AM', value: 'AM' },
                { label: 'PM', value: 'PM' },
                { label: 'Full Day', value: 'Full Day' },
              ]}
              defaultValue={reportedTimeframe}
              ml={isSmallMobile ? 3 : 10}
              onChange={(e) => {
                setReportedTimeframe(e as 'AM' | 'PM' | 'Full Day');
              }}
              mr={isSmallMobile ? 0 : 20}
            />
          </Group>
        </Stack>
        <Group position="apart" grow noWrap mt="xs">
          <Button
            variant="outline"
            onClick={() => {
              props.onUpdate(reportedDate, reportedTimeframe);
            }}
          >
            <FileAnalytics style={{ marginRight: 5 }} />
            <Text className={classes.buttonText}>Generate</Text>
          </Button>
          <Button
            variant="outline"
            color="red"
            onClick={() => {
              props.onClose();
            }}
          >
            <X style={{ marginRight: 5 }} />
            <Text className={classes.buttonText}>Cancel</Text>
          </Button>
        </Group>
      </Box>
    </Modal>
  );
};

export default ReportPeriodModal;
