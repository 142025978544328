import {
  Button,
  Center,
  createStyles,
  Divider,
  Group,
  Modal,
  ScrollArea,
  Stack,
  Table,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { ITrackedEquipment, ITrackedEquipmentService } from '../../../../models';
import {
  TrackedEquipmentPatientSection,
  TrackedEquipmentDetailSection,
  Th,
  InfiniteScrollArea,
} from '../../../../components';
import { ArrowLeft, Search, Tool } from 'tabler-icons-react';
import { useEffect, useState } from 'react';
import {
  useIsAdmin,
  useIsTechnician,
  useTrackedEquipmentServiceByTrackedEquipment,
} from '../../../../hooks';
import dayjs from 'dayjs';

const useStyles = createStyles((theme) => ({
  notesCard: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    paddingLeft: theme.spacing.sm,
    paddingRight: theme.spacing.sm,
    paddingTop: 3,
    width: '100%',
    height: '125px',
  },
  infoBox: {
    color: `${theme.colors.gray[6]}`,
    border: `1px solid ${theme.colors.gray[6]}`,
    borderRadius: theme.radius.lg,
    width: 'fit-content',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  groupLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.sm,
    whiteSpace: 'pre',
  },
  fieldLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
  },
  detail: {
    fontSize: theme.fontSizes.md,
    paddingLeft: 10,
  },
  fieldGroup: {
    width: '48%',
  },
  clickableIcon: {
    color: `${theme.colors.blue[6]}`,
    padding: '0 !important',
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

function filterData(data: ITrackedEquipmentService[], search: string) {
  if (data && data.length > 0) {
    const keys = Object.keys(data[0]);
    const query = search.toLowerCase().trim();
    return data.filter((item) =>
      keys.some((key) => (item[key] ?? '-').toString().toLowerCase().includes(query))
    );
  } else {
    return [];
  }
}

function sortData(
  data: ITrackedEquipmentService[],
  payload: { sortBy: keyof ITrackedEquipmentService; reversed: boolean; search: string }
) {
  if (!payload.sortBy) {
    return filterData(data, payload.search);
  }

  return filterData(
    [...data].sort((a, b) => {
      if (payload.reversed) {
        return (b[payload.sortBy] ?? '000ForceToBottom')
          .toString()
          .localeCompare((a[payload.sortBy] ?? '000ForceToBottom').toString());
      }

      return (a[payload.sortBy].toString() ?? '!!!ForceToTop').localeCompare(
        (b[payload.sortBy].toString() ?? '!!!ForceToTop').toString()
      );
    }),
    payload.search
  );
}

type TrackedEquipmentServiceHistoryModalProps = {
  trackedEquipment: ITrackedEquipment;
  serviceCreated: boolean;
  serviceDeleted: boolean;
  opened: boolean;
  onSelect: (service: ITrackedEquipmentService) => void;
  onService: () => void;
  onClose: () => void;
};

const TrackedEquipmentServiceHistoryModal = (props: TrackedEquipmentServiceHistoryModalProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const isAdmin = useIsAdmin().data?.isAdmin;
  const isTechnician = useIsTechnician().data?.isTechnician;

  const serviceRecords = useTrackedEquipmentServiceByTrackedEquipment(
    props.trackedEquipment.trackedEquipmentId
  );

  const [search, setSearch] = useState('');
  const [sortedData, setSortedData] = useState(serviceRecords.data);
  const [sortBy, setSortBy] = useState<keyof ITrackedEquipmentService>(null!);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);

  const setSorting = (field: keyof ITrackedEquipmentService) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setSortedData(sortData(serviceRecords.data!, { sortBy: field, reversed, search }));
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSearch(value);
    setSortedData(
      sortData(serviceRecords.data!, {
        sortBy,
        reversed: reverseSortDirection,
        search: value,
      })
    );
  };

  const rows = (sortedData ?? []).map((a: ITrackedEquipmentService) => {
    return (
      <tr
        key={a.trackedEquipmentServiceId}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          props.onSelect(a);
        }}
      >
        <td style={{ wordWrap: 'break-word', width: '25%' }}>{a.technicianNameFormatted}</td>
        <td style={{ wordWrap: 'break-word', width: '25%' }}>
          {dayjs(a.serviceDateTime).format('YYYY-MM-DD')}
        </td>
        <td style={{ wordWrap: 'break-word', width: '20%' }}>
          <Text ml={5}>{a.onHours}</Text>
        </td>
        <td style={{ wordWrap: 'break-word', width: '20%' }}>
          <Text ml={5}>{a.offHours}</Text>
        </td>
      </tr>
    );
  });

  useEffect(() => {
    if (!serviceRecords.data) {
      setSortedData(serviceRecords.data);
    } else {
      setSortedData(
        sortData(serviceRecords.data, { sortBy, reversed: reverseSortDirection, search })
      );
    }
  }, [serviceRecords.data, reverseSortDirection, search, sortBy]);

  useEffect(() => {
    if (props.opened) {
      serviceRecords.refetch();
    }
  }, [props.trackedEquipment, props.opened]);

  return (
    <Modal
      opened={props.opened}
      title={
        <Text style={{ color: 'green' }}>
          {props.serviceCreated
            ? 'Service Record Successfully Created!'
            : props.serviceDeleted
            ? 'Service Record Successfully Deleted!'
            : ''}
        </Text>
      }
      size={isSmallMobile ? 'xs' : 'md'}
      onClose={() => {
        props.onClose();
      }}
    >
      <Stack spacing={0} style={{ position: 'relative' }}>
        <Center>
          <Text size="xl" weight={500} align="center">
            Equipment Servicing
          </Text>
        </Center>
        {props.trackedEquipment.checkedOutDate && !props.trackedEquipment.outForRepairs && (
          <TrackedEquipmentPatientSection
            trackedEquipment={props.trackedEquipment}
            header="Current Patient"
          />
        )}
        <TrackedEquipmentDetailSection trackedEquipment={props.trackedEquipment} readOnly />
        <Center mt={theme.spacing.xs}>
          <Text size="md" weight={500} align="center">
            Service History
          </Text>
        </Center>
        <Divider />
        <TextInput
          placeholder="Search by any field"
          my="xs"
          icon={<Search size={14} />}
          value={search}
          onChange={handleSearchChange}
        />
        <Divider />
        <Table style={{ width: '100%', tableLayout: 'fixed' }}>
          <thead>
            <tr>
              <Th
                sorted={sortBy === 'technicianNameFormatted'}
                reversed={reverseSortDirection}
                onSort={() => setSorting('technicianNameFormatted')}
                style={{ width: '25%' }}
              >
                Technician
              </Th>
              <Th
                sorted={sortBy === 'serviceDateTime'}
                reversed={reverseSortDirection}
                onSort={() => setSorting('serviceDateTime')}
                style={{ width: '25%' }}
              >
                Serviced On
              </Th>
              <Th
                sorted={sortBy === 'onHours'}
                reversed={reverseSortDirection}
                onSort={() => setSorting('onHours')}
                style={{ width: '20%' }}
              >
                On Hours
              </Th>
              <Th
                sorted={sortBy === 'offHours'}
                reversed={reverseSortDirection}
                onSort={() => setSorting('offHours')}
                style={{ width: '20%' }}
              >
                Off Hours
              </Th>
            </tr>
          </thead>
        </Table>
        <InfiniteScrollArea rows={rows} height={250} />
        <Divider />
        <Group spacing="xs" grow noWrap mt="xs">
          <Button
            variant="outline"
            p={0}
            onClick={() => {
              props.onClose();
            }}
          >
            <ArrowLeft style={{ marginRight: 5 }} />
            Return to Details
          </Button>
          {(isAdmin || isTechnician) && (
            <Button
              variant="outline"
              disabled={
                props.trackedEquipment?.checkedOutDate === undefined ||
                props.trackedEquipment.retired
              }
              p={0}
              onClick={() => {
                props.onService();
              }}
            >
              <Tool style={{ marginRight: 5 }} />
              Service Equipment
            </Button>
          )}
        </Group>
      </Stack>
    </Modal>
  );
};

export default TrackedEquipmentServiceHistoryModal;
