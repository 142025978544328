import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import externalApi from '../../api';
import { ICity } from '../../models';

function fetchCities(): Promise<ICity[]> {
  return externalApi.get(`City/all`).json();
}

export function useCities() {
  return useQuery(['Cities'], () => fetchCities());
}
