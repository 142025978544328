import {
  ActionIcon,
  Box,
  Button,
  Center,
  Checkbox,
  createStyles,
  Group,
  Modal,
  SegmentedControl,
  Stack,
  Text,
  Textarea,
  useMantineTheme,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { DatePickerInput } from '@mantine/dates';
import React, { useEffect, useState } from 'react';
import { FilePlus, User, X } from 'tabler-icons-react';
import { useMediaQuery } from '@mantine/hooks';
import { IFilteredPatient, IOrder } from '../../../models';
import { useNewOrder, useUserId } from '../../../hooks';

const useStyles = createStyles((theme) => ({
  patientSelectBox: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    paddingLeft: 3,
    paddingTop: 3,
    paddingBottom: 3,
    paddingRight: theme.spacing.xs,
    width: '100%',
    height: '70',
  },
  label: {
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
    fontWeight: 500,
  },
  fieldGroup: {
    width: '48%',
  },
  detailLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

type OrderCreateModalProps = {
  opened: boolean;
  selectedPatient: IFilteredPatient | undefined;
  onClose: () => void;
  onSelectPatient: () => void;
  onDeSelectPatient: () => void;
  onCreate: (isCreated: boolean, newPatient: IOrder) => void;
};

const OrderCreateModal = (props: OrderCreateModalProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const newOrder = useNewOrder();

  const currentUserId = useUserId();

  const [isAMDelivery, setIsAMDelivery] = useState<boolean>(true);

  const [submitClicked, setSubmitClicked] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [noPatientError, setNoPatientError] = useState<boolean>(false);
  const [buttonsLocked, setButtonsLocked] = useState<boolean>(false);

  const createForm = useForm<IOrder>({
    initialValues: {
      orderId: '',
      patientId: '',
      patientFirstName: '',
      patientLastName: '',
      patientFullName: '',
      patientFullNameFormatted: '',
      patientPhoneNumber: '',
      patientAddress: '',
      patientCity: '',
      patientNotes: '',
      isAMDelivery: true,
      deliveryTimeName: 'AM',
      requestedDeliveryDate: new Date(),
      liquid: false,
      notes: '',
      isUrgent: false,
      standingOrderId: '',
      standingOrderReschedule: false,
      deliveryReschedule: false,
      fulfilled: false,
      lastUpdatedUserId: '',
      lastUpdatedDateTime: new Date(),
    },
    validate: {},
  });

  async function submitOrder() {
    if (props.selectedPatient) {
      setSubmitClicked(true);

      newOrder.mutate({
        patientId: props.selectedPatient.patientId,
        isAMDelivery: isAMDelivery,
        requestedDeliveryDate: createForm.values.requestedDeliveryDate,
        liquid: createForm.values.liquid,
        notes: createForm.values.notes,
        isUrgent: createForm.values.isUrgent,
        lastUpdatedUserId: currentUserId.data!.userId,
      });
    } else {
      setNoPatientError(true);
    }
  }

  useEffect(() => {
    if (submitClicked) {
      if (newOrder.isSuccess && props.selectedPatient) {
        setSubmitClicked(false);
        createForm.reset();
        setIsError(false);
        setNoPatientError(false);
        props.onCreate(true, {
          orderId: newOrder.data.orderId.toString(),
          patientId: props.selectedPatient.patientId,
          patientFirstName: props.selectedPatient.firstName,
          patientLastName: props.selectedPatient.lastName,
          patientFullName: `${props.selectedPatient.firstName} ${props.selectedPatient.lastName}`,
          patientFullNameFormatted: `${props.selectedPatient.lastName}, ${props.selectedPatient.firstName}`,
          patientPhoneNumber: props.selectedPatient.phoneNumber,
          patientAddress: props.selectedPatient.streetAddress,
          patientCity: props.selectedPatient.cityName,
          patientNotes: props.selectedPatient.notes,
          isAMDelivery: isAMDelivery,
          deliveryTimeName: isAMDelivery ? 'AM' : 'PM',
          requestedDeliveryDate: createForm.values.requestedDeliveryDate,
          liquid: createForm.values.liquid,
          notes: createForm.values.notes,
          isUrgent: createForm.values.isUrgent,
          standingOrderId: '',
          standingOrderReschedule: false,
          deliveryReschedule: false,
          fulfilled: false,
          lastUpdatedUserId: currentUserId.data!.userId,
          lastUpdatedDateTime: new Date(),
        });
      } else if (newOrder.isError) {
        setSubmitClicked(false);
        setIsError(true);
        setButtonsLocked(false);
      }
    }
  }, [newOrder.status]);

  useEffect(() => {
    setButtonsLocked(false);
  }, [props.opened]);

  return (
    <Modal
      title={
        isError ? (
          <Text color="red">Something went wrong, Order not Created. Please try again</Text>
        ) : (
          ''
        )
      }
      opened={props.opened}
      size={isSmallMobile ? 'xs' : 'md'}
      onClose={() => {
        setIsError(false);
        setNoPatientError(false);
        createForm.reset();
        props.onClose();
      }}
    >
      <Box>
        <Center>
          <Text size="xl" weight={500} align="center">
            New Order Information
          </Text>
        </Center>
        <form
          onSubmit={createForm.onSubmit(() => {
            setButtonsLocked(true);
            setIsError(false);
            submitOrder();
          })}
          autoComplete="off"
        >
          <Stack align="stretch" style={{ padding: 5 }}>
            {!props.selectedPatient && (
              <Stack align="stretch" spacing={0}>
                <Button
                  variant="outline"
                  style={{ height: 70, border: '1px dashed' }}
                  onClick={() => {
                    props.onSelectPatient();
                  }}
                >
                  <User style={{ marginRight: 5 }} />
                  Select a Patient
                </Button>
                {noPatientError && (
                  <Text align="center" color="red">
                    Must select a Patient before creating an Order
                  </Text>
                )}
              </Stack>
            )}
            {props.selectedPatient && (
              <Box className={classes.patientSelectBox}>
                <Group position="apart" noWrap>
                  <Stack spacing={0}>
                    <Text
                      pl="sm"
                      weight={500}
                    >{`${props.selectedPatient.lastName}, ${props.selectedPatient.firstName}`}</Text>
                    <Group pl="sm" spacing={5}>
                      <Text size="xs" color="dimmed">
                        Last Four HCN
                      </Text>
                      <Text size="xs">{`#${props.selectedPatient.lastFourHCN}`}</Text>
                    </Group>
                    <Group pl="sm" spacing={5}>
                      <Text size="xs" color="dimmed">
                        Unique Number
                      </Text>
                      <Text size="xs">{`#${props.selectedPatient.claimNumber}`}</Text>
                    </Group>
                  </Stack>
                  <ActionIcon
                    onClick={() => {
                      props.onDeSelectPatient();
                    }}
                  >
                    <X color="gray" />
                  </ActionIcon>
                </Group>
              </Box>
            )}
            <Group noWrap>
              <Stack spacing={0} className={classes.fieldGroup}>
                <Text className={classes.label}>Requested Delivery Date</Text>
                <DatePickerInput
                  valueFormat="YYYY-MM-DD"
                  firstDayOfWeek={0}
                  required
                  dropdownType="modal"
                  modalProps={{
                    title: 'Reqested Delivery Date',
                    withCloseButton: true,
                    closeButtonProps: { style: { position: 'fixed', right: 10 } },
                    yOffset: '15vh',
                    styles: { title: { fontWeight: 500, textAlign: 'center', width: '100%' } },
                  }}
                  style={{ width: '125px' }}
                  {...createForm.getInputProps('requestedDeliveryDate')}
                />
              </Stack>
              <Stack spacing={0} className={classes.fieldGroup} ml={15}>
                <Text className={classes.label}>Delivery Time</Text>
                <SegmentedControl
                  color="blue"
                  data={[
                    { label: 'AM', value: 'AM' },
                    { label: 'PM', value: 'PM' },
                  ]}
                  defaultValue={isAMDelivery ? 'AM' : 'PM'}
                  ml={10}
                  onChange={(e) => {
                    e === 'AM' ? setIsAMDelivery(true) : setIsAMDelivery(false);
                  }}
                />
              </Stack>
            </Group>

            <Group position="left" spacing="xs" pl="xs">
              <Checkbox {...createForm.getInputProps('liquid')} />
              <Text className={classes.label}>Liquid</Text>
            </Group>

            <Stack align="stretch" spacing={0}>
              <Text className={classes.label}>Notes</Text>
              <Textarea minRows={4} {...createForm.getInputProps('notes')} />
            </Stack>

            <Box
              style={{
                border: `1px solid ${theme.colors.gray[4]}`,
                borderRadius: theme.radius.sm,
                padding: 10,
              }}
            >
              <Checkbox label="Is this Order Urgent?" {...createForm.getInputProps('isUrgent')} />
            </Box>

            <Group position="apart" grow noWrap mt="xs">
              <Button variant="outline" disabled={buttonsLocked} type="submit">
                <FilePlus style={{ marginRight: 5 }} />{' '}
                <Text className={classes.buttonText}>{`Create ${
                  !isSmallMobile ? 'Order' : ''
                }`}</Text>
              </Button>
              <Button
                variant="outline"
                color="red"
                onClick={() => {
                  setIsError(false);
                  setNoPatientError(false);
                  createForm.reset();
                  props.onClose();
                }}
              >
                <X style={{ marginRight: 5 }} /> <Text className={classes.buttonText}>Cancel</Text>
              </Button>
            </Group>
          </Stack>
        </form>
      </Box>
    </Modal>
  );
};

export default OrderCreateModal;
