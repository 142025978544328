import {
  Box,
  Button,
  Divider,
  Group,
  Loader,
  ScrollArea,
  Stack,
  Table,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { FilePlus, Search } from 'tabler-icons-react';
import {
  Th,
  EquipmentTypeDetailModal,
  EquipmentTypeEditModal,
  EquipmentTypeCreateModal,
  InfiniteScrollArea,
} from '../../components';
import { useEquipmentTypes } from '../../hooks';
import { IFilteredPatient, IEquipmentType } from '../../models';

function filterData(data: IEquipmentType[], search: string, visibleKeys: string[]) {
  const keys = visibleKeys;
  const query = search.toLowerCase().trim();
  return data.filter((item) =>
    keys.some((key) => (item[key] ?? '-').toString().toLowerCase().includes(query))
  );
}

function sortData(
  data: IEquipmentType[],
  payload: { sortBy: keyof IEquipmentType; reversed: boolean; search: string },
  visibleKeys: string[]
) {
  if (!payload.sortBy) {
    return filterData(data, payload.search, visibleKeys);
  }

  return filterData(
    [...data].sort((a, b) => {
      if (payload.reversed) {
        return (b[payload.sortBy] ?? '000ForceToBottom')
          .toString()
          .localeCompare((a[payload.sortBy] ?? '000ForceToBottom').toString());
      }

      return (a[payload.sortBy] ?? 'zzzForceToBottom')
        .toString()
        .localeCompare((b[payload.sortBy] ?? 'zzzForceToBottom').toString());
    }),
    payload.search,
    visibleKeys
  );
}

type Props = {};

const EquipmentTypesSubPage = (props: Props) => {
  const theme = useMantineTheme();

  const [selectedPatient, setSelectedPatient] = useState<IFilteredPatient>();
  const [patientSelected, setPatientSelected] = useState<boolean>(false);

  const [equipmentTypeCreated, setEquipmentTypeCreated] = useState<boolean>(false);
  const [equipmentTypeUpdated, setEquipmentTypeUpdated] = useState<boolean>(false);
  const [equipmentTypeUpdateError, setEquipmentTypeUpdateError] = useState<boolean>(false);
  const [equipmentTypeDeleted, setEquipmentTypeDeleted] = useState<boolean>(false);
  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [detailModalOpen, setDetailModalOpen] = useState<boolean>(false);
  const [detailEquipmentType, setDetailEquipmentType] = useState<IEquipmentType>({
    equipmentTypeId: '',
    name: '',
    lastUpdatedUserId: '',
    lastUpdatedDateTime: new Date(),
  });

  const defaultKeys: string[] = ['name'];

  let equipmentTypes = useEquipmentTypes();

  const [search, setSearch] = useState('');
  const [sortedData, setSortedData] = useState(equipmentTypes.data);
  const [sortBy, setSortBy] = useState<keyof IEquipmentType>(null!);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);

  const setSorting = (field: keyof IEquipmentType) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setSortedData(sortData(equipmentTypes.data!, { sortBy: field, reversed, search }, defaultKeys));
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSearch(value);
    setSortedData(
      sortData(
        equipmentTypes.data!,
        { sortBy, reversed: reverseSortDirection, search: value },
        defaultKeys
      )
    );
  };

  const rows = sortedData?.map((row) => (
    <tr
      key={row.equipmentTypeId}
      style={{ cursor: 'pointer' }}
      onClick={() => {
        setDetailEquipmentType(row);
        setDetailModalOpen(true);
      }}
    >
      <td style={{ width: '100%', wordWrap: 'break-word' }}>{`${row.name}`}</td>
    </tr>
  ));

  useEffect(() => {
    if (!equipmentTypes.data) {
      setSortedData(equipmentTypes.data);
    } else {
      setSortedData(
        sortData(
          equipmentTypes.data,
          { sortBy, reversed: reverseSortDirection, search },
          defaultKeys
        )
      );
    }
  }, [equipmentTypes.data, reverseSortDirection, search, sortBy]);

  return (
    <Box style={{ height: '100%' }}>
      <Box style={{ height: '100%' }}>
        <TextInput
          placeholder="Search by any field"
          mb="md"
          icon={<Search size={14} />}
          value={search}
          onChange={handleSearchChange}
        />
        <Divider />
        <ScrollArea type="hover" style={{ width: '100%' }}>
          <Table style={{ width: '100%', tableLayout: 'fixed' }}>
            <thead>
              <tr>
                <Th
                  sorted={sortBy === 'name'}
                  reversed={reverseSortDirection}
                  onSort={() => setSorting('name')}
                  style={{ width: '100%' }}
                >
                  Category Name
                </Th>
              </tr>
            </thead>
          </Table>
          {equipmentTypes.data && <InfiniteScrollArea rows={rows} height={350} />}
          {!equipmentTypes.data && equipmentTypes.isLoading && (
            <Stack style={{ height: '350px' }} align="center" justify="center">
              <Loader size={84} />
              <Text size="lg" weight={500}>
                Loading...
              </Text>
            </Stack>
          )}
        </ScrollArea>
        <Divider />
        <Group noWrap grow position="apart" mt="lg">
          <Button
            variant="outline"
            mb="lg"
            onClick={() => {
              setCreateModalOpen(true);
            }}
          >
            <FilePlus style={{ marginRight: 5 }} />
            Add a New Equipment Type Category
          </Button>
        </Group>
      </Box>

      <EquipmentTypeCreateModal
        opened={createModalOpen}
        onClose={() => {
          setCreateModalOpen(false);
          setSelectedPatient(undefined);
        }}
        onCreate={(isCreated, newEquipmentType) => {
          setEquipmentTypeCreated(isCreated);
          setDetailEquipmentType(newEquipmentType);
          setCreateModalOpen(false);
          setSelectedPatient(undefined);
          setDetailModalOpen(true);
        }}
      />
      <EquipmentTypeDetailModal
        equipmentType={detailEquipmentType}
        opened={detailModalOpen}
        isCreated={equipmentTypeCreated}
        isUpdated={equipmentTypeUpdated}
        isDeleted={equipmentTypeDeleted}
        isError={equipmentTypeUpdateError}
        onClose={() => {
          setEquipmentTypeUpdated(false);
          setEquipmentTypeUpdateError(false);
          setEquipmentTypeCreated(false);
          setEquipmentTypeDeleted(false);
          setDetailModalOpen(false);
        }}
        onEdit={() => {
          setEditModalOpen(true);
          setDetailModalOpen(false);
        }}
      />
      <EquipmentTypeEditModal
        equipmentType={detailEquipmentType}
        opened={editModalOpen}
        onClose={() => {
          setEditModalOpen(false);
          setDetailModalOpen(true);
        }}
        onEdited={(isUpdated, isError, updatedEquipmentType) => {
          setEditModalOpen(false);
          setDetailModalOpen(true);
          setEquipmentTypeUpdated(isUpdated);
          setEquipmentTypeUpdateError(isError);
          setDetailEquipmentType(updatedEquipmentType);
        }}
        onDeleted={() => {
          setEditModalOpen(false);
          setDetailModalOpen(true);
          setEquipmentTypeDeleted(true);
        }}
      />
    </Box>
  );
};

export default EquipmentTypesSubPage;
