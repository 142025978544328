import {
  ActionIcon,
  Box,
  Divider,
  Group,
  Select,
  Stack,
  Text,
  createStyles,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React, { useEffect, useRef, useState } from 'react';
import { ITrackedEquipmentRepair } from '../../../../models';
import {
  useEditTrackedEquipmentRepairDetails,
  useIsAdmin,
  useIsTechnician,
  useRepairLocations,
  useUserId,
} from '../../../../hooks';
import { Clock, DeviceFloppy, Edit, X } from 'tabler-icons-react';
import dayjs from 'dayjs';
import { DatePickerInput, TimeInput } from '@mantine/dates';

const useStyles = createStyles((theme) => ({
  notesCard: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    paddingLeft: theme.spacing.sm,
    paddingRight: theme.spacing.sm,
    paddingTop: 3,
    width: '100%',
    height: '125px',
  },
  infoBox: {
    color: `${theme.colors.gray[6]}`,
    border: `1px solid ${theme.colors.gray[6]}`,
    borderRadius: theme.radius.lg,
    width: 'fit-content',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  groupLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.sm,
    whiteSpace: 'pre',
  },
  editGroupLabel: {
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,
    whiteSpace: 'pre',
  },
  fieldLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
  },
  editFieldLabel: {
    fontSize: theme.fontSizes.xs,
    fontWeight: 500,
    whiteSpace: 'pre',
  },
  detail: {
    fontSize: theme.fontSizes.md,
    paddingLeft: 10,
  },
  fieldGroup: {
    width: '48%',
  },
  clickableIcon: {
    color: `${theme.colors.blue[6]}`,
    padding: '0 !important',
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

type TrackedEquipmentRepairDetailSectionProps = {
  repair?: ITrackedEquipmentRepair;
  readOnly?: boolean;
  onEdited?: () => void;
  onError?: () => void;
};

const TrackedEquipmentRepairDetailSection = (props: TrackedEquipmentRepairDetailSectionProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const isAdmin = useIsAdmin().data?.isAdmin;
  const isTechnician = useIsTechnician().data?.isTechnician;

  const timeInputRef = useRef<any | null>(null);

  const currentUserId = useUserId();
  const repairLocations = useRepairLocations();
  const editRepairDetails = useEditTrackedEquipmentRepairDetails();

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [invalidSentDate, setInvalidSentDate] = useState<boolean>(false);
  const [invalidSentTime, setInvalidSentTime] = useState<boolean>(false);

  // display values
  const [selectedRepairLocationId, setSelectedRepairLocationId] = useState<string | undefined>(
    props.repair?.repairLocationId ?? ''
  );
  const [repairLocationName, setRepairLocationName] = useState<string>(
    props.repair?.repairLocationName ?? ''
  );
  const [sentDate, setSentDate] = useState<Date>(props.repair?.sentDateTime ?? new Date());
  const [sentTime, setSentTime] = useState<Date>(props.repair?.sentDateTime ?? new Date());
  const [expectedReturnDate, setExpectedReturnDate] = useState<Date | undefined>(
    props.repair?.expectedReturnDate
  );

  // controlled editing values
  const [editSelectedRepairLocationId, setEditSelectedRepairLocationId] = useState<
    string | undefined
  >(props.repair?.repairLocationId ?? '');
  const [editSentDate, setEditSentDate] = useState<Date>(props.repair?.sentDateTime ?? new Date());
  const [editSentTime, setEditSentTime] = useState<Date>(props.repair?.sentDateTime ?? new Date());
  const [editExpectedReturnDate, setEditExpectedReturnDate] = useState<Date | undefined>(
    props.repair?.expectedReturnDate
  );

  function SaveChanges() {
    if (props.onEdited) {
      let valid: boolean = true;

      if (!editSentDate) {
        valid = false;
        setInvalidSentDate(true);
      }
      if (!editSentTime) {
        valid = false;
        setInvalidSentTime(true);
      }

      if (valid) {
        let newSentDate: Date = new Date(editSentDate);
        let newSentTime: Date = new Date(editSentTime);
        let sentDateTime: Date = new Date(
          new Date(
            Date.UTC(
              newSentDate.getFullYear(),
              newSentDate.getMonth(),
              newSentDate.getDate(),
              newSentTime.getHours(),
              newSentTime.getMinutes(),
              newSentTime.getSeconds()
            )
          )
        );

        editRepairDetails.mutate({
          trackedEquipmentRepairId: props.repair!.trackedEquipmentRepairId,
          repairLocationId: editSelectedRepairLocationId,
          expectedReturnDate: editExpectedReturnDate,
          sentDateTime: sentDateTime,
          lastUpdatedUserId: currentUserId.data!.userId,
        });
      }
    }
  }

  useEffect(() => {
    if (editRepairDetails.isSuccess) {
      setSelectedRepairLocationId(editSelectedRepairLocationId);
      setRepairLocationName(
        repairLocations.data?.find(
          (a) => a.repairLocationId.toString() === editSelectedRepairLocationId?.toString()
        )?.name ?? ''
      );
      setSentDate(editSentDate);
      setSentTime(editSentTime);

      setIsEditing(false);

      if (props.onEdited) {
        props.onEdited();
      }
    }
    if (editRepairDetails.isError) {
      if (props.onError) {
        props.onError();
      }
    }
  }, [editRepairDetails.status]);

  return (
    <Box>
      {!isEditing && (
        <Stack spacing={0}>
          <Group spacing={0} position="apart" noWrap>
            <Text className={classes.groupLabel}>Repair Details</Text>
            {!props.readOnly && (isAdmin || isTechnician) && (
              <ActionIcon
                variant="transparent"
                className={classes.clickableIcon}
                onClick={() => {
                  setEditSelectedRepairLocationId(selectedRepairLocationId);
                  setEditExpectedReturnDate(expectedReturnDate);
                  setEditSentDate(sentDate);
                  setEditSentTime(sentTime);
                  setIsEditing(true);
                }}
              >
                <Edit style={{ marginBottom: 4 }} />
              </ActionIcon>
            )}
          </Group>
          <Divider mb={`calc(${theme.spacing.xs} / 2)`} />
          <Stack spacing={0} mb={theme.spacing.xs} style={{ width: '50%' }}>
            <Text className={classes.fieldLabel}>Repair Location</Text>
            <Text className={classes.detail}>{`${
              repairLocationName.length !== 0 ? repairLocationName : '-'
            }`}</Text>
          </Stack>
          <Group spacing={theme.spacing.sm} noWrap>
            <Stack spacing={0} style={{ width: '50%' }}>
              <Text className={classes.fieldLabel}>Sent for Repair On</Text>
              <Text className={classes.detail}>{dayjs(sentDate).format('YYYY-MM-DD')}</Text>
            </Stack>
            <Stack spacing={0}>
              <Text className={classes.fieldLabel}>Sent for Repair At</Text>
              <Text className={classes.detail}>{dayjs(sentTime).format('h:mm A')}</Text>
            </Stack>
          </Group>
          <Stack spacing={0} style={{ width: '50%' }}>
            <Text className={classes.fieldLabel}>Expected Return Date</Text>
            <Text className={classes.detail}>
              {expectedReturnDate ? dayjs(expectedReturnDate).format('YYYY-MM-DD') : '-'}
            </Text>
          </Stack>
          {props.repair?.returnedDateTime && (
            <Group spacing={theme.spacing.sm} noWrap>
              <Stack spacing={0} style={{ width: '50%' }}>
                <Text className={classes.fieldLabel}>Returned On</Text>
                <Text className={classes.detail}>
                  {dayjs(props.repair.returnedDateTime).format('YYYY-MM-DD')}
                </Text>
              </Stack>
              <Stack spacing={0}>
                <Text className={classes.fieldLabel}>Returned At</Text>
                <Text className={classes.detail}>
                  {dayjs(props.repair.returnedDateTime).format('h:mm A')}
                </Text>
              </Stack>
            </Group>
          )}
        </Stack>
      )}
      {isEditing && (isAdmin || isTechnician) && (
        <Stack spacing={0}>
          <Group spacing={0} position="apart" noWrap>
            <Text className={classes.editGroupLabel}>Repair Details</Text>
            <Group spacing={0}>
              <ActionIcon
                variant="transparent"
                className={classes.clickableIcon}
                onClick={() => {
                  SaveChanges();
                }}
              >
                <DeviceFloppy style={{ marginBottom: 4 }} />
              </ActionIcon>
              <ActionIcon
                variant="transparent"
                className={classes.clickableIcon}
                onClick={() => {
                  setIsEditing(false);
                }}
              >
                <X style={{ marginBottom: 4 }} />
              </ActionIcon>
            </Group>
          </Group>
          <Divider mb={`calc(${theme.spacing.xs} / 2)`} />
          <Stack spacing={0} mb={theme.spacing.xs}>
            <Text className={classes.editFieldLabel}>Repair Location</Text>
            <Select
              data={(repairLocations.data ?? []).map((a) => ({
                value: a.repairLocationId,
                label: a.name,
              }))}
              placeholder="Select a Location"
              searchable={!isSmallMobile}
              value={editSelectedRepairLocationId}
              onChange={(a) => {
                setEditSelectedRepairLocationId(a ?? '');
              }}
            />
          </Stack>
          <Group spacing={theme.spacing.sm} mb={theme.spacing.xs} align="top" grow noWrap>
            <Stack spacing={0} align="stretch">
              <Text className={classes.editFieldLabel}>Date Sent for Repair</Text>
              <DatePickerInput
                dropdownType="modal"
                valueFormat="YYYY-MM-DD"
                firstDayOfWeek={0}
                modalProps={{
                  title: 'Date Sent for Repair',
                  withCloseButton: true,
                  closeButtonProps: { style: { position: 'fixed', right: 10 } },
                  yOffset: '15vh',
                  styles: { title: { fontWeight: 500, textAlign: 'center', width: '100%' } },
                }}
                value={new Date(editSentDate)}
                onChange={(a) => {
                  setEditSentDate(new Date(a!.toISOString()));
                }}
                error={invalidSentDate ? 'You must select a Date Sent for Repair' : undefined}
              />
            </Stack>
            <Stack spacing={0} align="stretch">
              <Text className={classes.editFieldLabel}>Time Sent for Repair</Text>
              <TimeInput
                ref={timeInputRef}
                rightSection={
                  <ActionIcon onClick={() => timeInputRef.current!.showPicker()}>
                    <Clock size="1rem" />
                  </ActionIcon>
                }
                required
                ml={5}
                value={dayjs(editSentTime).format('HH:mm')}
                onChange={(a) => {
                  let inputTime = new Date();
                  inputTime.setHours(parseInt(a.currentTarget.value.split(':')[0]));
                  inputTime.setMinutes(parseInt(a.currentTarget.value.split(':')[1]));
                  setEditSentTime(inputTime);
                }}
                error={invalidSentTime ? 'You must select a Time Sent for Repair' : undefined}
              />
            </Stack>
          </Group>
          <Stack spacing={0} align="stretch" style={{ width: '50%' }}>
            <Text className={classes.editFieldLabel}>Expected Return Date</Text>
            <DatePickerInput
              dropdownType="modal"
              valueFormat="YYYY-MM-DD"
              firstDayOfWeek={0}
              clearable
              modalProps={{
                title: 'Repair Date',
                withCloseButton: true,
                closeButtonProps: { style: { position: 'fixed', right: 10 } },
                yOffset: '15vh',
                styles: { title: { fontWeight: 500, textAlign: 'center', width: '100%' } },
              }}
              mr={`calc(${theme.spacing.xs} / 2)`}
              value={editExpectedReturnDate}
              onChange={(a) => {
                setEditExpectedReturnDate(new Date(a!.toISOString()));
              }}
            />
          </Stack>
        </Stack>
      )}
    </Box>
  );
};

export default TrackedEquipmentRepairDetailSection;
