import React from 'react';
import {
  createStyles,
  Navbar,
  Group,
  Text,
  NavbarProps,
  useMantineTheme,
  Box,
  Image,
  Stack,
  Loader,
} from '@mantine/core';
import {
  Home,
  Users,
  ClipboardList,
  FileAnalytics,
  BuildingWarehouse,
  CalendarEvent,
  UserCircle,
  Help,
} from 'tabler-icons-react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useIsAdmin, useIsUser } from '../../hooks';
import { UserButton } from '../../components';

const useStyles = createStyles((theme, _params) => {
  return {
    navbar: {
      backgroundColor: theme.colors.dark[5],
      borderRight: 'none',
      overflow: 'auto',
    },

    version: {
      backgroundColor: theme.colors.gray[5],
      color: theme.white,
      fontWeight: 700,
    },

    header: {
      paddingBottom: theme.spacing.md,
      marginBottom: `calc(${theme.spacing.md} * 1.5)`,
      borderBottom: `1px solid ${theme.white}`,

      [theme.fn.smallerThan('md')]: {
        display: 'none',
      },

      '&:hover': {
        cursor: 'pointer',
      },
    },

    navsection: {
      borderTop: `1px solid ${theme.white}`,
      marginTop: theme.spacing.md,
      paddingTop: theme.spacing.md,
    },

    footer: {
      paddingTop: theme.spacing.md,
      marginTop: theme.spacing.md,
      borderTop: `1px solid ${theme.white}`,
    },

    link: {
      ...theme.fn.focusStyles(),
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      fontSize: theme.fontSizes.sm,
      color: theme.white,
      padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
      borderRadius: theme.radius.sm,
      fontWeight: 500,

      '&:hover': {
        backgroundColor: theme.colors[theme.primaryColor][5],
      },
    },

    linkIcon: {
      color: theme.white,
      opacity: 0.75,
      marginRight: theme.spacing.sm,
    },

    linkActive: {
      '&, &:hover': {
        backgroundColor: theme.colors[theme.primaryColor][7],
      },
    },
  };
});

const data = [
  { link: '/', label: 'Home', icon: Home },
  { link: '/patients', label: 'Patients', icon: Users },
  { link: '/orders', label: 'Orders', icon: ClipboardList },
  { link: '/equipment', label: 'Equipment', icon: BuildingWarehouse },
  { link: '/deliveries', label: 'Delivery Schedule', icon: CalendarEvent },
  { link: '/reports', label: 'Reports', icon: FileAnalytics },
  { link: '/help', label: 'Help', icon: Help },
];

const addData = [{ link: '/manage/users', label: 'Manage Users', icon: UserCircle }];

type NavbarSimpleProps = Omit<NavbarProps, 'children'> & {
  isOpen: boolean;
  setOpen(): void;
};

export const NavbarSimple = (props: NavbarSimpleProps) => {
  const { isOpen, setOpen, ...rest } = props;
  const navigate = useNavigate();

  const { classes, cx } = useStyles();
  const theme = useMantineTheme();

  const isUser = useIsUser();
  const isAdmin = useIsAdmin();

  const links = data.map((item) => (
    <NavLink
      className={({ isActive }) => cx(classes.link, { [classes.linkActive]: isActive })}
      to={item.link}
      key={item.label}
      onClick={() => setOpen()}
    >
      <item.icon className={classes.linkIcon} />
      <span>{item.label}</span>
    </NavLink>
  ));

  const addLinks = addData.map((item) => (
    <NavLink
      className={({ isActive }) => cx(classes.link, { [classes.linkActive]: isActive })}
      to={item.link}
      key={item.label}
      onClick={() => setOpen()}
    >
      <item.icon className={classes.linkIcon} />
      <span>{item.label}</span>
    </NavLink>
  ));

  return (
    <Navbar {...rest} hiddenBreakpoint="md" hidden={!isOpen} className={classes.navbar}>
      <Navbar.Section>
        <Group
          className={classes.header}
          onClick={() => {
            navigate('/');
          }}
        >
          <Image src="../logo192.png" width="45px" alt="Lakeridge Health logo" />
          <Box>
            <Text weight={500} size="xl" color={theme.white}>
              O<sub>2</sub> Delivery
            </Text>
            <Text weight={500} size="xl" color={theme.white}>
              Management
            </Text>
          </Box>
        </Group>
        <Box>
          <Navbar.Section
            style={{
              borderBottom: `1px solid ${theme.white}`,
              marginBottom: theme.spacing.md,
              paddingBottom: theme.spacing.xs,
              marginTop: `calc(${theme.spacing.sm} * -1)`,
              width: '100%',
            }}
          >
            <UserButton />
          </Navbar.Section>
        </Box>
      </Navbar.Section>

      <Navbar.Section grow>
        {isUser.data?.isUser && <Box>{links}</Box>}
        {!isUser.data && isUser.isLoading && (
          <Stack align="center" justify="center">
            <Loader size={54} />
            <Text size="lg" weight={500} color="white">
              Loading...
            </Text>
          </Stack>
        )}
      </Navbar.Section>

      {isAdmin.data?.isAdmin && (
        <Box>
          <Navbar.Section className={classes.navsection}>
            <Text weight={400} size="xl" color={theme.white}>
              App Management
            </Text>
          </Navbar.Section>
          <Navbar.Section className={classes.navsection}>{addLinks}</Navbar.Section>
        </Box>
      )}
    </Navbar>
  );
};
