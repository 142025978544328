import React from 'react';
import { useQuery } from 'react-query';
import externalApi from '../../api';
import { ITrackedEquipmentClean } from '../../models';
import { useIsAdmin, useIsTechnician } from '..';

function fetchTrackedEquipmentCleanByTrackedEquipment(
  trackedEquipmentId: string
): Promise<ITrackedEquipmentClean[]> {
  return externalApi.get(`TrackedEquipmentClean/ByTrackedEquipment/${trackedEquipmentId}`).json();
}

export function useTrackedEquipmentCleanByTrackedEquipment(trackedEquipmentId: string) {
  const isAdmin = useIsAdmin().data?.isAdmin;
  const isTechnician = useIsTechnician().data?.isTechnician;

  return useQuery(
    ['TrackedEquipmentCleanByTrackedEquipment'],
    () => fetchTrackedEquipmentCleanByTrackedEquipment(trackedEquipmentId),
    {
      enabled: isTechnician || isAdmin,
    }
  );
}
