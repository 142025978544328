import { Box, Button, Group, Text } from '@mantine/core';
import { useClickOutside } from '@mantine/hooks';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from 'tabler-icons-react';

type PageButtonMobileProps = {
  icon: Icon;
  prompt: string;
  clicked?: boolean;
  allowToggle?: boolean;
  height?: string;
  width?: string;
  link?: string;
  onClick?: () => void;
  onUnClick?: () => void;
};

const PageButtonMobile = (props: PageButtonMobileProps) => {
  const navigate = useNavigate();
  const [isClicked, setIsClicked] = useState<boolean>(props.clicked ?? false);

  useEffect(() => {
    if (props.clicked !== undefined) {
      setIsClicked(props.clicked);
    }
  }, [props.clicked]);

  return (
    <Box style={{ flexGrow: 1, width: '100%', alignContent: 'center' }}>
      {!isClicked && (
        <Button
          style={{
            height: props.height ?? 50,
            flexGrow: 1,
            width: props.width ?? '100%',
          }}
          variant="outline"
          onClick={() => {
            if (props.link !== undefined) {
              navigate(props.link);
            }

            if (props.allowToggle) {
              setIsClicked(!isClicked);
            }

            if (props.onClick !== undefined) {
              props.onClick();
            }
          }}
        >
          <props.icon size={25} style={{ marginRight: 5, marginTop: 2 }} />
          <Text weight={500} size="xl" align="center" style={{ width: '100%', whiteSpace: 'pre' }}>
            {props.prompt}
          </Text>
        </Button>
      )}
      {isClicked && (
        <Button
          style={{
            height: props.height ?? 50,
            flexGrow: 1,
            width: props.width ?? 'fit-content',
          }}
          onClick={() => {
            if (props.link !== undefined) {
              navigate(props.link);
            }

            if (props.allowToggle) {
              setIsClicked(!isClicked);
            }

            if (props.onUnClick !== undefined) {
              props.onUnClick();
            } else if (props.onClick !== undefined) {
              props.onClick();
            }
          }}
        >
          <props.icon size={25} style={{ marginRight: 5, marginTop: 2 }} />
          <Text weight={500} size="xl" align="center" style={{ width: '100%', whiteSpace: 'pre' }}>
            {props.prompt}
          </Text>
        </Button>
      )}
    </Box>
  );
};

export default PageButtonMobile;
