import {
  ActionIcon,
  Box,
  Button,
  Center,
  createStyles,
  Flex,
  Group,
  Menu,
  Modal,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { ITrackedEquipment, ITrackedEquipmentClean } from '../../../../models';
import TrackedEquipmentPatientSection from '../TrackedEquipmentPatientSection';
import TrackedEquipmentDetailSection from '../TrackedEquipmentDetailSection';
import { ArrowLeft, Dots, Trash } from 'tabler-icons-react';
import { useEffect, useState } from 'react';
import {
  DialogModal,
  InfoButton,
  TrackedEquipmentCleanDetailSection,
  TrackedEquipmentCleanNoteSection,
} from '../../..';
import { useDeleteTrackedEquipmentClean, useIsAdmin, useIsTechnician } from '../../../../hooks';

const useStyles = createStyles((theme) => ({
  notesCard: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    paddingLeft: theme.spacing.sm,
    paddingRight: theme.spacing.sm,
    paddingTop: 3,
    width: '100%',
    height: '125px',
  },
  infoBox: {
    color: `${theme.colors.gray[6]}`,
    border: `1px solid ${theme.colors.gray[6]}`,
    borderRadius: theme.radius.lg,
    width: 'fit-content',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  groupLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.sm,
    whiteSpace: 'pre',
  },
  fieldLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
  },
  editLabel: {
    fontSize: theme.fontSizes.xs,
    fontWeight: 500,
    whiteSpace: 'pre',
  },
  detail: {
    fontSize: theme.fontSizes.md,
    paddingLeft: 10,
  },
  fieldGroup: {
    width: '48%',
  },
  clickableIcon: {
    color: `${theme.colors.blue[6]}`,
    padding: '0 !important',
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

type TrackedEquipmentCleanDetailModalProps = {
  trackedEquipment: ITrackedEquipment;
  clean: ITrackedEquipmentClean;
  opened: boolean;
  onClose: () => void;
  onDelete: () => void;
};

const TrackedEquipmentCleanDetailModal = (props: TrackedEquipmentCleanDetailModalProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const isAdmin = useIsAdmin().data?.isAdmin;
  const isTechnician = useIsTechnician().data?.isTechnician;

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const [isError, setIsError] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');

  const deleteTrackedEquipmentClean = useDeleteTrackedEquipmentClean();

  const handleDelete = () => {
    deleteTrackedEquipmentClean.mutate({
      trackedEquipmentCleanId: props.clean.trackedEquipmentCleanId,
    });
  };

  useEffect(() => {
    if (deleteTrackedEquipmentClean.isSuccess) {
      props.onDelete();
    }
    if (deleteTrackedEquipmentClean.isError) {
      setIsError(true);
      setMessage('Something went wrong, Clean not Deleted');
    }
  }, [deleteTrackedEquipmentClean.status]);

  return (
    <Modal
      title={<Text color={isError ? 'red' : 'green'}>{message}</Text>}
      opened={props.opened}
      size={isSmallMobile ? 'xs' : 'md'}
      onClose={() => {
        props.onClose();
      }}
    >
      <Stack spacing={0} style={{ position: 'relative' }}>
        <Center>
          <Text size="xl" weight={500} align="center">
            Equipment Cleaning Record
          </Text>
          <Flex
            direction={isSmallMobile ? 'column' : 'row'}
            align="center"
            style={{ position: 'absolute', right: -5 }}
          >
            <InfoButton
              userId={props.clean?.lastUpdatedUserId ?? ''}
              date={props.clean?.lastUpdatedDateTime ?? new Date()}
              ml={5}
            />
            {(isAdmin || isTechnician) && (
              <Menu>
                <Menu.Target>
                  <ActionIcon>
                    <Dots />
                  </ActionIcon>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item
                    icon={<Trash color={`${theme.colors.red[6]}`} />}
                    onClick={() => {
                      setDeleteModalOpen(true);
                    }}
                  >
                    Delete Cleaning Record
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            )}
          </Flex>
        </Center>
        <TrackedEquipmentDetailSection trackedEquipment={props.trackedEquipment} readOnly />

        <TrackedEquipmentCleanDetailSection
          clean={props.clean}
          readOnly={props.trackedEquipment.retired}
          onEdited={() => {
            setIsError(false);
            setMessage('Clean Details Successfully Updated!');
          }}
          onError={() => {
            setIsError(true);
            setMessage('Something went wrong, Clean Details not Updated');
          }}
        />
        <Box mb={5}>
          <TrackedEquipmentCleanNoteSection
            trackedEquipmentClean={props.clean}
            readOnly={props.trackedEquipment.retired}
            onEdited={() => {
              setIsError(false);
              setMessage('Equipment Notes Successfully Updated!');
            }}
            onError={() => {
              setIsError(true);
              setMessage('Something went wrong, Equipment Notes not Updated');
            }}
          />
        </Box>
        <Button
          variant="outline"
          mt="md"
          onClick={() => {
            props.onClose();
          }}
        >
          <ArrowLeft style={{ marginRight: 5 }} />
          Return to Clean History
        </Button>
      </Stack>
      <DialogModal
        isOpen={deleteModalOpen}
        isNegative
        titleText="Delete Cleaning Record?"
        body={
          <Text align="center" color="dimmed" size="lg" mb="xs">
            Are you sure you want to delete this Equipment?
          </Text>
        }
        confirmText="Delete Clean"
        onConfirm={() => {
          setDeleteModalOpen(false);
          handleDelete();
        }}
        onClose={() => {
          setDeleteModalOpen(false);
        }}
      />
    </Modal>
  );
};

export default TrackedEquipmentCleanDetailModal;
