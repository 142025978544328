import {
  Box,
  createStyles,
  Group,
  Menu,
  Stack,
  Text,
  UnstyledButton,
  useMantineTheme,
} from '@mantine/core';
import { useState } from 'react';
import { ChevronDown, ChevronUp, Logout, UserCircle } from 'tabler-icons-react';
import { msalInstance } from '../../common/AzureAD';
import { DialogModal } from '../../components';
import { useMsal } from '@azure/msal-react';

const useStyles = createStyles((theme) => ({
  user: {
    padding: theme.spacing.md,
    color: theme.colors.gray[3],
    borderRadius: 5,
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 5,
    paddingRight: 5,
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: theme.colors.blue[4],
      color: theme.white,
    },
  },
  menuItem: {
    fontWeight: 500,

    '&:hover': {
      backgroundColor: theme.colors.blue[4],
      color: theme.white,
    },
  },
}));

interface UserButtonProps {}

const UserButton = (props: UserButtonProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();

  const user = msalInstance.getAllAccounts()[0];
  const { instance } = useMsal();

  const [clicked, setClicked] = useState<boolean>(false);
  const [logoutModalOpen, setLogoutModalOpen] = useState<boolean>(false);

  return (
    <Menu
      width="target"
      onClose={() => {
        setClicked(false);
      }}
    >
      <Menu.Target>
        <UnstyledButton
          style={{ width: '100%' }}
          className={classes.user}
          onClick={() => {
            setClicked((a) => !a);
          }}
        >
          <Group position="apart" spacing="xs">
            <Group spacing="xs">
              <UserCircle size={30} />
              <Stack spacing={0}>
                <Text size="sm" weight={500}>
                  {user.name}
                </Text>

                <Text color="dimmed" size="xs">
                  {user.username}
                </Text>
              </Stack>
            </Group>

            <Box style={{ marginTop: 3 }}>
              {!clicked && <ChevronDown />}
              {clicked && <ChevronUp />}
            </Box>
          </Group>
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown style={{ background: theme.colors.dark[5], borderWidth: 0 }}>
        <Menu.Item
          className={classes.menuItem}
          color={theme.colors.gray[3]}
          icon={<Logout size={18} color={theme.colors.red[6]} />}
          onClick={() => {
            setLogoutModalOpen(true);
          }}
        >
          Logout
        </Menu.Item>
      </Menu.Dropdown>
      <DialogModal
        isOpen={logoutModalOpen}
        isNegative
        onClose={() => {
          setLogoutModalOpen(false);
          setClicked(false);
        }}
        titleText="Logout?"
        body={
          <Text align="center" color="dimmed" size="lg" mb="xs">
            Are you sure you want to Logout?
          </Text>
        }
        confirmText="Logout"
        onConfirm={() => {
          instance.logoutRedirect({ postLogoutRedirectUri: '/' });
        }}
      />
    </Menu>
  );
};

export default UserButton;
