import {
  ActionIcon,
  Box,
  createStyles,
  Group,
  NumberInput,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { CirclePlus, X } from 'tabler-icons-react';

const useStyles = createStyles((theme) => ({
  equipmentBox: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    paddingLeft: 3,
    paddingTop: 3,
    paddingBottom: 3,
    paddingRight: theme.spacing.md,
    width: '100%',
    height: '70',
  },
}));

type EquipmentCreateCardProps = {
  equipmentName: string;
  equipmentTypeName: string;
  quantity: number;
  hasSerialNumber?: boolean;
  onClose: () => void;
  onSave: (newQuantity: number, newSerialNumber?: string) => void;
};

const EquipmentCreateCard = (props: EquipmentCreateCardProps) => {
  const { classes } = useStyles();

  const [quantity, setQuantity] = useState<number>(props.quantity);
  const [serialNumber, setSerialNumber] = useState<string>('');

  return (
    <Box className={classes.equipmentBox}>
      <Group position="apart" noWrap spacing={0}>
        <Stack pl="sm" spacing={0}>
          <Text weight={500}>{props.equipmentName}</Text>
          <Group spacing={5}>
            <Text size="xs" color="dimmed">
              {props.equipmentTypeName}
            </Text>
          </Group>
        </Stack>
        {!props.hasSerialNumber && (
          <Group noWrap spacing={0}>
            <X size="20px" color="gray" />
            <NumberInput
              style={{ width: '65px' }}
              min={1}
              value={quantity}
              onChange={(e) => {
                setQuantity(e === '' ? 1 : e);
              }}
            />
          </Group>
        )}
        {props.hasSerialNumber && (
          <Stack align="stretch" spacing={0} style={{ width: '45%' }}>
            <Text size="xs" color="dimmed">
              Serial Number
            </Text>
            <TextInput
              placeholder="Serial Number"
              value={serialNumber}
              onChange={(e) => {
                setSerialNumber(e.currentTarget.value);
              }}
            />
          </Stack>
        )}
        <Stack spacing={0}>
          <ActionIcon
            onClick={() => {
              props.onSave(quantity, serialNumber);
            }}
          >
            <CirclePlus />
          </ActionIcon>
          <ActionIcon
            onClick={() => {
              props.onClose();
            }}
          >
            <X />
          </ActionIcon>
        </Stack>
      </Group>
    </Box>
  );
};

export default EquipmentCreateCard;
