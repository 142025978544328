import { Box, Text, createStyles, useMantineTheme, Group, Stack } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React, { useState } from 'react';
import { ListDetails, Packages, BuildingWarehouse } from 'tabler-icons-react';
import { PageHeader } from '../common/Page/Header/PageHeader';
import { Page } from '../common/Page/Page';
import {
  EquipmentSubPage,
  TrackedEquipmentSubPage,
  EquipmentTypesSubPage,
  PageButton,
  PageButtonMobile,
} from '../components';
import { usePageStore } from '../hooks';

const useStyles = createStyles((theme, _params) => ({}));

type Props = {};

const EquipmentPage = (props: Props) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);
  const selectedSubPage = usePageStore((state) => state.equipmentSubPage);
  const setEquipmentSubPage = usePageStore((state) => state.setEquipmentSubPage);

  return (
    <Page
      header={
        <PageHeader
          title="Manage Equipment"
          subtitle="View, Add, and Edit Equipment"
          stickyContent={null}
        />
      }
    >
      <Box style={{ height: '50vh' }}>
        <Text size="xl" weight={500} align="center" mb={isSmallMobile ? 'xs' : 'lg'}>
          Click on a record to view it in full
        </Text>
        {!isSmallMobile && (
          <Group position="center" grow noWrap mb="md">
            <PageButton
              icon={BuildingWarehouse}
              prompt="Tracked Equipment"
              clicked={selectedSubPage === 'trackedEquipment'}
              height="150px"
              onClick={() => {
                setEquipmentSubPage('trackedEquipment');
              }}
            />
            <PageButton
              icon={Packages}
              prompt="Equipment Types"
              clicked={selectedSubPage === 'equipment'}
              height="150px"
              onClick={() => {
                setEquipmentSubPage('equipment');
              }}
            />
            <PageButton
              icon={ListDetails}
              prompt={`Type Categories`}
              clicked={selectedSubPage === 'equipmentTypes'}
              height="150px"
              onClick={() => {
                setEquipmentSubPage('equipmentTypes');
              }}
            />
          </Group>
        )}
        {isSmallMobile && (
          <Stack align="stretch" spacing={5} mb="md">
            <PageButtonMobile
              icon={BuildingWarehouse}
              prompt="Tracked Equipment"
              clicked={selectedSubPage === 'trackedEquipment'}
              width="90vw"
              onClick={() => {
                setEquipmentSubPage('trackedEquipment');
              }}
            />
            <PageButtonMobile
              icon={Packages}
              prompt="Equipment"
              clicked={selectedSubPage === 'equipment'}
              width="90vw"
              onClick={() => {
                setEquipmentSubPage('equipment');
              }}
            />
            <PageButtonMobile
              icon={ListDetails}
              prompt="Equipment Types"
              clicked={selectedSubPage === 'equipmentTypes'}
              width="90vw"
              onClick={() => {
                setEquipmentSubPage('equipmentTypes');
              }}
            />
          </Stack>
        )}
        {selectedSubPage === 'equipment' && <EquipmentSubPage />}
        {selectedSubPage === 'equipmentTypes' && <EquipmentTypesSubPage />}
        {selectedSubPage === 'trackedEquipment' && <TrackedEquipmentSubPage />}
      </Box>
    </Page>
  );
};

export default EquipmentPage;
