import React from 'react';
import { useQuery } from 'react-query';
import externalApi from '../../api';

function fetchIsUser(): Promise<{ isUser: boolean }> {
  return externalApi.post(`User/IsUser`).json();
}

function useIsUser() {
  return useQuery(['IsUser'], () => fetchIsUser());
}
export { useIsUser };
