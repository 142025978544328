import { Text, createStyles, Group, useMantineTheme, Box, Stack } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React, { useEffect, useState } from 'react';
import { TruckDelivery } from 'tabler-icons-react';
import { PageHeader } from '../common/Page/Header/PageHeader';
import { Page } from '../common/Page/Page';
import { PageButton, PageButtonMobile } from '../components';

const useStyles = createStyles((theme) => ({
  wrapper: {
    textAlign: 'center',
  },

  subtitle: {
    fontWeight: 600,
    color: theme.colors.blue[5],
    fontSize: theme.spacing.xl,
    marginBottom: -10,
  },
}));

type Props = {};

const ReportsPage = (props: Props) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const [horizontalMargin, setHorizontalMargin] = useState<string>('20%');

  useEffect(() => {
    if (isMobile) {
      setHorizontalMargin('10%');
    }
  }, [isMobile]);

  return (
    <Page header={<PageHeader title="Reports" subtitle="Select an Option" stickyContent={null} />}>
      <Box style={{ height: '50%' }}>
        <Stack
          align="stretch"
          className={classes.wrapper}
          mr={horizontalMargin}
          ml={horizontalMargin}
        >
          <Text size="xl" weight={500} align="center" mb={isSmallMobile ? 'xs' : 'lg'}>
            Select an Option to Generate a Report
          </Text>

          {!isSmallMobile && (
            <Stack align="stretch">
              <Group position="center" grow noWrap>
                <PageButton
                  icon={TruckDelivery}
                  prompt="Deliveries Report"
                  link="../reports/deliveries"
                />
              </Group>
            </Stack>
          )}

          {isSmallMobile && (
            <Stack align="stretch">
              <PageButtonMobile
                icon={TruckDelivery}
                prompt="Deliveries Report"
                link="../reports/deliveries"
              />
            </Stack>
          )}
        </Stack>
      </Box>
    </Page>
  );
};

export default ReportsPage;
