import React from 'react';
import { useQuery } from 'react-query';
import externalApi from '../../api';

function fetchUserId(): Promise<{ userId: string }> {
  return externalApi.post(`User/Id`).json();
}

function useUserId() {
  return useQuery(['UserId'], () => fetchUserId());
}
export { useUserId };
