import {
  Box,
  Button,
  Center,
  createStyles,
  Divider,
  Group,
  Modal,
  ScrollArea,
  Select,
  Stack,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import React, { useEffect, useRef, useState } from 'react';
import { IEquipment, IOrder } from '../../../models';
import { useMediaQuery } from '@mantine/hooks';
import { Plus, Search, X } from 'tabler-icons-react';
import { OrderEquipmentCard } from '../../../components';
import { useAllEquipment, useOrderEquipmentByOrder } from '../../../hooks';

const useStyles = createStyles((theme) => ({
  equipmentBox: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    paddingLeft: 3,
    paddingTop: 3,
    paddingBottom: 3,
    paddingRight: theme.spacing.xs,
    width: '100%',
    height: '70',
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

type OrderEquipmentModalProps = {
  order: IOrder;
  opened: boolean;
  onClose: () => void;
};

const OrderEquipmentModal = (props: OrderEquipmentModalProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);
  const viewport = useRef<HTMLDivElement | null>(null);

  const [selectedEquipment, setSelectedEquipment] = useState<IEquipment>();
  const [addEquipment, setAddEquipment] = useState<IEquipment>();
  const [addingNewCard, setAddingNewCard] = useState<boolean>(false);
  const [isFetched, setIsFetched] = useState<boolean>();

  const [selectValue, setSelectValue] = useState<string>('');

  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [search, setSearch] = useState<string>('');
  const [filteredCards, setFilteredCards] = useState<JSX.Element[]>([]);

  const scrollToBottom = () =>
    viewport.current!.scrollTo({ top: viewport.current!.scrollHeight, behavior: 'smooth' });

  const orderEquipment = useOrderEquipmentByOrder(
    props.order.orderId !== '' ? props.order.orderId : '0'
  );
  const equipment = useAllEquipment();

  const cards = (orderEquipment.data ?? []).map((a) => (
    <OrderEquipmentCard
      key={a.orderEquipmentId}
      orderEquipmentId={a.orderEquipmentId}
      orderId={a.orderId}
      equipmentId={a.equipmentId}
      equipmentName={a.equipmentName}
      equipmentTypeName={a.equipmentTypeName}
      quantity={a.quantity}
      lastUpdatedUserId={a.lastUpdatedUserId}
      lastUpdatedDateTime={a.lastUpdatedDateTime}
      onEdit={(isEdited, isError) => {
        setShowSuccessMessage(isEdited);
        setShowErrorMessage(isError);
        setSuccessMessage('Equipment Quantity Successfully Updated!');
        setErrorMessage('Something went wrong, Equipment Quantity not Updated');
      }}
      onDelete={(isDeleted, isError) => {
        setShowSuccessMessage(isDeleted);
        setShowErrorMessage(isError);
        setSuccessMessage('Equipment Successfully Deleted from Order!');
        setErrorMessage('Something went wrong, Equipment not Deleted');
      }}
      readOnly={props.order.fulfilled}
    />
  ));

  const filterCards = (value: string) => {
    setFilteredCards(
      cards.filter((a) =>
        `${a.props.equipmentName} ${a.props.equipmentTypeName} ${a.props.quantity}`
          .toLowerCase()
          .includes(value.toLowerCase())
      )
    );
  };

  useEffect(() => {
    orderEquipment.refetch();
  }, [props.order, props.opened]);

  useEffect(() => {
    if (isFetched) filterCards(search);
  }, [isFetched]);

  return (
    <Modal
      title={
        showSuccessMessage ? (
          <Text color="green">{successMessage}</Text>
        ) : showErrorMessage ? (
          <Text color="red">{errorMessage}</Text>
        ) : (
          ''
        )
      }
      opened={props.opened}
      size={isSmallMobile ? 'sm' : 'md'}
      onClose={() => {
        setShowSuccessMessage(false);
        setShowErrorMessage(false);
        setSuccessMessage('');
        setErrorMessage('');
        setSelectedEquipment(undefined);
        setAddEquipment(undefined);
        setAddingNewCard(false);
        props.onClose();
      }}
    >
      <Box>
        <Center>
          <Text size="xl" weight={500} align="center">
            Order Equipment
          </Text>
        </Center>
        <Divider my="xs" />
        <TextInput
          placeholder="Search by any field"
          mb="xs"
          icon={<Search size={14} />}
          value={search}
          onChange={(e) => {
            setSearch(e.currentTarget.value);
            filterCards(e.currentTarget.value);
          }}
        />
        <Stack align="stretch" style={{ width: '100%' }} mt="sm">
          <ScrollArea style={{ height: '300px' }} viewportRef={viewport}>
            {cards[0] && search.length > 0 && filteredCards}
            {cards[0] && search.length === 0 && cards}
            {addingNewCard && (
              <OrderEquipmentCard
                orderEquipmentId={''}
                orderId={props.order.orderId}
                equipmentId={addEquipment?.equipmentId ?? ''}
                equipmentName={addEquipment?.name ?? ''}
                equipmentTypeName={addEquipment?.equipmentTypeName ?? ''}
                quantity={1}
                isCreate
                onCreateCancel={() => {
                  setAddingNewCard(false);
                }}
                onCreate={(isCreated, isError) => {
                  setShowSuccessMessage(isCreated);
                  setShowErrorMessage(isError);
                  setSuccessMessage('Equipment Successfully Added to Order!');
                  setErrorMessage('Something went wrong, Equipment not Added to Order');
                  setAddingNewCard(false);
                  setIsFetched(false);
                  orderEquipment.refetch().then(() => {
                    setIsFetched(true);
                  });
                }}
                onOpen={() => {
                  scrollToBottom();
                }}
                readOnly={false}
              />
            )}
            {!cards[0] && search.length === 0 && !addingNewCard && (
              <Text weight={500} align="center">
                Nothing Found
              </Text>
            )}
            {!filteredCards[0] && search.length > 0 && !addingNewCard && (
              <Text weight={500} align="center">
                Nothing Found
              </Text>
            )}
          </ScrollArea>
        </Stack>
        <Divider mt="xs" />
        {!props.order.fulfilled && (
          <Stack align="stretch" spacing={5} mt="sm">
            <Select
              data={
                equipment.data
                  ?.sort((a, b) => parseInt(a.equipmentTypeId) - parseInt(b.equipmentTypeId))
                  .map((a) => ({
                    value: `${a.equipmentId}`,
                    label: `${a.equipmentTypeName} - ${a.name}`,
                  })) ?? []
              }
              placeholder="Select Equipment to Add"
              searchable={!isSmallMobile}
              clearable
              nothingFound="Nothing Found"
              dropdownPosition="top"
              value={selectValue}
              onChange={(e) => {
                setSelectValue(e?.toString() ?? '');
                setSelectedEquipment(
                  equipment.data?.find((a) => a.equipmentId.toString() === e?.toString()) ??
                    undefined
                );
              }}
            />
            <Button
              variant="outline"
              onClick={() => {
                if (selectedEquipment) {
                  setSelectValue('');
                  setAddEquipment(selectedEquipment);
                  setAddingNewCard(true);
                }
              }}
            >
              <Plus style={{ marginRight: 5 }} />
              <Text className={classes.buttonText}>Add Equipment</Text>
            </Button>
          </Stack>
        )}
        {props.order.fulfilled && (
          <Stack align="stretch" spacing={5} mt="sm">
            <Button
              variant="outline"
              onClick={() => {
                props.onClose();
              }}
            >
              <X style={{ marginRight: 5 }} />
              <Text className={classes.buttonText}>Close</Text>
            </Button>
          </Stack>
        )}
      </Box>
    </Modal>
  );
};

export default OrderEquipmentModal;
