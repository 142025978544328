import { ScrollArea, Stack, useMantineTheme } from '@mantine/core';
import { TrackedEquipmentSelectCard } from '../../../components';
import { IEquipment, ITrackedEquipment } from '../../../models';

type TrackedEquipmentDeliverySelectProps = {
  preTrackedEquipment: {
    equipmentId: string;
    equipmentName: string;
    equipmentTypeName: string;
  }[];
  selectedTrackedEquipment: { trackedEquipment: ITrackedEquipment; index: number }[];
  trackedEquipmentNotes: { trackedEquipmentId: string; notes: string }[];
  undeliveredEquipment: { equipment: IEquipment; index: number }[];
  undeliveredEquipmentNotes: { index: number; notes: string }[];
  onTrackedEquipmentRemoved: (trackedEquipmentId: string) => void;
  onUndeliveredRemoved: (index: number) => void;
  onSelectCardClicked: (equipmentId: string, index: number) => void;
  onTrackedNotesSaved: (trackedEquipmentId: string, notes: string | undefined) => void;
  onUndeliveredNotesSaved: (index: number, notes: string | undefined) => void;
};

const TrackedEquipmentDeliverySelect = (props: TrackedEquipmentDeliverySelectProps) => {
  const theme = useMantineTheme();

  const selectCards = props.preTrackedEquipment.map((a, i) => {
    let trackedEquipment =
      props.selectedTrackedEquipment.find((b) => b.index === i)?.trackedEquipment ?? undefined;
    let undeliveredEquipment =
      props.undeliveredEquipment.find((b) => b.index === i)?.equipment ?? undefined;

    return (
      <TrackedEquipmentSelectCard
        key={i}
        index={i}
        equipmentId={a.equipmentId}
        equipmentName={a.equipmentName}
        equipmentTypeName={a.equipmentTypeName}
        trackedEquipment={trackedEquipment}
        equipment={undeliveredEquipment}
        notes={
          props.trackedEquipmentNotes.find(
            (b) => b.trackedEquipmentId === trackedEquipment?.trackedEquipmentId
          )?.notes ?? props.undeliveredEquipmentNotes.find((b) => b.index === i)?.notes
        }
        onClick={() => {
          props.onSelectCardClicked(a.equipmentId, i);
        }}
        onRemoveTrackedEquipment={(trackedEquipmentId) => {
          props.onTrackedEquipmentRemoved(trackedEquipmentId);
        }}
        onRemoveUndelivered={() => {
          props.onUndeliveredRemoved(i);
        }}
        onTrackedNotesSaved={(trackedEquipmentId, notes) => {
          props.onTrackedNotesSaved(trackedEquipmentId, notes);
        }}
        onUndeliveredNotesSaved={(index, notes) => {
          props.onUndeliveredNotesSaved(index, notes);
        }}
      />
    );
  });

  return (
    <Stack
      align="stretch"
      spacing={5}
      style={{
        border: `1px solid ${theme.colors.gray[4]}`,
        borderRadius: theme.radius.sm,
        padding: 5,
      }}
    >
      <ScrollArea style={{ height: 210 }}>
        <Stack align="stretch" spacing={5}>
          {selectCards}
        </Stack>
      </ScrollArea>
    </Stack>
  );
};

export default TrackedEquipmentDeliverySelect;
