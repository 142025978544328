import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

function getStandingOrderRescheduledCount(standingOrderId: string): Promise<{ count: string }> {
  return externalApi.get(`Order/RescheduledStandingOrderCount/${standingOrderId}`).json();
}

function useStandingOrderRescheduledCount() {
  const queryClient = useQueryClient();
  return useMutation(
    (standingOrderId: string) => getStandingOrderRescheduledCount(standingOrderId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['StandingOrderRescheduledCount']);
      },
    }
  );
}

export { useStandingOrderRescheduledCount };
