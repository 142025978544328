import {
  Box,
  Button,
  Center,
  createStyles,
  Divider,
  Group,
  Modal,
  ScrollArea,
  Stack,
  Table,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { useState } from 'react';
import { Filter, FilterOff } from 'tabler-icons-react';
import { IFilteredPatient } from '../../../models';
import { useQueryClient } from 'react-query';
import { useMediaQuery } from '@mantine/hooks';
import { usePatientsFiltered } from '../../../hooks';
import { InfiniteScrollArea } from '../../../components';

const useStyles = createStyles((theme) => ({
  label: {
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
    fontWeight: 500,
  },
  fieldGroup: {
    width: '48%',
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

type PatientSelectModalProps = {
  opened: boolean;
  onClose: () => void;
  onSelect: (isSelected: boolean, selectedPatient: IFilteredPatient) => void;
};

const PatientSelectModal = (props: PatientSelectModalProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const filteredPatients = usePatientsFiltered();

  const [isFiltering, setIsFiltering] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const filterForm = useForm({
    initialValues: {
      firstName: '',
      lastName: '',
      lastFourHCN: '',
      claimNumber: '',
    },
    validate: {},
  });

  async function submitPatientSelect() {
    if (isFiltering) {
      filteredPatients.mutate(filterForm.values);
      if (filteredPatients.isSuccess) {
        setIsError(false);
      } else if (filteredPatients.isError) {
        setIsError(true);
      }
    } else {
      filteredPatients.reset();
    }
  }

  const patientRows = (filteredPatients.data ?? []).map((a: IFilteredPatient) => {
    return (
      <tr
        key={a.patientId}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          props.onSelect(true, a);
          setIsFiltering(false);
          filteredPatients.reset();
          filterForm.setFieldValue('firstName', '');
          filterForm.setFieldValue('lastName', '');
          filterForm.setFieldValue('lastFourHCN', '');
          filterForm.setFieldValue('claimNumber', '');
          props.onClose();
        }}
      >
        <td
          style={{ width: '120px', wordWrap: 'break-word' }}
        >{`${a.lastName}, ${a.firstName}`}</td>
        <td style={{ width: '60px', wordWrap: 'break-word' }}>{`#${a.lastFourHCN}`}</td>
        <td style={{ width: '120px', wordWrap: 'break-word' }}>{`#${a.claimNumber}`}</td>
      </tr>
    );
  });

  return (
    <Modal
      title={
        isError ? (
          <Text color="red">Something went wrong, Patients not Filtered. Please try again</Text>
        ) : (
          ''
        )
      }
      opened={props.opened}
      size={isSmallMobile ? 'xs' : 'md'}
      onClose={() => {
        setIsFiltering(false);
        filteredPatients.reset();
        filterForm.setFieldValue('firstName', '');
        filterForm.setFieldValue('lastName', '');
        filterForm.setFieldValue('lastFourHCN', '');
        filterForm.setFieldValue('claimNumber', '');
        props.onClose();
      }}
    >
      <Box>
        <Center>
          <Text size="xl" weight={500} align="center">
            Filter Patients
          </Text>
        </Center>
        <form onSubmit={filterForm.onSubmit(() => submitPatientSelect())} autoComplete="off">
          <Stack align="stretch" spacing={5} style={{ padding: 5 }}>
            <Group noWrap>
              <Stack spacing={0} className={classes.fieldGroup}>
                <Text className={classes.label}>Patient Last Name</Text>
                <TextInput
                  placeholder="Patient's Last Name"
                  {...filterForm.getInputProps('lastName')}
                />
              </Stack>
              <Stack spacing={0} className={classes.fieldGroup}>
                <Text className={classes.label}>Patient First Name</Text>
                <TextInput
                  placeholder="Patient's First Name"
                  {...filterForm.getInputProps('firstName')}
                />
              </Stack>
            </Group>

            <Group noWrap>
              <Stack spacing={0} className={classes.fieldGroup}>
                <Text className={classes.label}>Last 4 Digits of{`\n`}Health Card Number</Text>
                <TextInput
                  placeholder="Last Four HCN Digits"
                  {...filterForm.getInputProps('lastFourHCN')}
                />
              </Stack>
              <Stack spacing={0} className={classes.fieldGroup}>
                <Text className={classes.label}>{`\n`}Unique Number</Text>
                <TextInput
                  placeholder="Patient's Unique Number"
                  {...filterForm.getInputProps('claimNumber')}
                />
              </Stack>
            </Group>
            <Group grow noWrap mt="sm" mb="sm">
              <Button
                type="submit"
                variant="outline"
                mt="sm"
                onClick={() => {
                  setIsError(false);
                  setIsFiltering(true);
                }}
              >
                <Filter />
                <Text className={classes.buttonText}>{`Filter ${
                  !isSmallMobile ? 'Patients' : ''
                }`}</Text>
              </Button>
              <Button
                type="submit"
                variant="outline"
                color="red"
                mt="sm"
                onClick={() => {
                  setIsError(false);
                  setIsFiltering(false);
                  filterForm.setFieldValue('firstName', '');
                  filterForm.setFieldValue('lastName', '');
                  filterForm.setFieldValue('lastFourHCN', '');
                  filterForm.setFieldValue('claimNumber', '');
                }}
              >
                <FilterOff />
                <Text className={classes.buttonText}>{`Clear ${
                  !isSmallMobile ? 'Filters' : ''
                }`}</Text>
              </Button>
            </Group>
            <Divider mt="sm" />
          </Stack>
        </form>
        <Center>
          <Text size="xl" weight={500} align="center">
            Select a Patient
          </Text>
        </Center>
        <Stack align="stretch" spacing={5} style={{ padding: 5 }}>
          <Divider />
          <Table style={{ width: '100%', tableLayout: 'fixed' }}>
            <thead>
              <tr>
                <th style={{ width: '120px' }}>
                  <Text weight={500} size="sm" color={'#000000'}>
                    Patient Name
                  </Text>
                </th>
                <th style={{ width: '60px' }}>
                  <Text weight={500} size="sm" color={'#000000'}>
                    Last 4 HCN
                  </Text>
                </th>
                <th style={{ width: '120px' }}>
                  <Text weight={500} size="sm" color={'#000000'}>
                    Unique Number
                  </Text>
                </th>
              </tr>
            </thead>
          </Table>
          <InfiniteScrollArea rows={patientRows} height={200} />
          <Divider />
        </Stack>
      </Box>
    </Modal>
  );
};

export default PatientSelectModal;
