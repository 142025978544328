import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';

type NewPatientRequest = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  streetAddress: string;
  cityId: string;
  lastFourHCN: string;
  claimNumber: string;
  yearlyAppointment: boolean;
  covid: boolean;
  lakeridgeGardens: boolean;
  hospicePT: boolean;
  fundingTypeId: string;
  startDate: Date | undefined;
  dischargeDate: Date | undefined;
  deathDate: Date | undefined;
  notes: string;
  lastUpdatedUserId: string;
};

type NewPatientResponse = {
  patientId: number;
};

function newPatient(request: NewPatientRequest): Promise<NewPatientResponse> {
  return externalApi.post(`Patient/new`, { json: request }).json();
}

function useNewPatient() {
  const queryClient = useQueryClient();
  return useMutation((request: NewPatientRequest) => newPatient(request), {
    onSuccess: () => {
      queryClient.invalidateQueries(['Patients']);
    },
  });
}
export { useNewPatient };
