import { createStyles, Stack, Text, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React from 'react';
import { HelpBox } from '../../../components';

const useStyles = createStyles((theme, _params) => ({
  card: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.md,
    padding: `${theme.spacing.sm} ${theme.spacing.lg}`,
  },
}));

type Props = {};

const DeliveryScheduleHelpSubPage = (props: Props) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  return (
    <Stack align="stretch" p={25}>
      <HelpBox title="Navigating the Calendar">
        <Text style={{ whiteSpace: 'nowrap' }}>
          The Delivery Schedule calendar can be navigated using the {`"<" and ">"`} buttons to cycle
          through each month, or you can click the "calendar" button to select a
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>specific month and year to target.</Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          The default month displayed will be the current month, with today being highlighted in
          light blue.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          The selected date, the date displaying its scheduled items in the upper card display, will
          have a darker blue outline.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>The default selected date is today.</Text>
      </HelpBox>
      <HelpBox title="Pending vs. Undelivered vs. Delivered vs. Incomplete">
        <Text style={{ whiteSpace: 'nowrap' }}>
          There are 3 states a delivery can be in on the Delivery Schedule.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }} ml={25}>
          Pending - Not yet addressed. Reflects an order.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }} ml={25}>
          Undelivered - Delivery was attempted, but unable to be completed. Marked to be readdressed
          later.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }} ml={25}>
          Delivered - Delivery was completed.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }} ml={25}>
          Incomplete - Delivery was completed, but some tracked equipment was not delivered. Marked
          to allow creation of new order with undelivered equipment via "Reshedule" option.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          These can be seen in the individual calendar days as a count of how many deliveries in
          each state were originally scheduled for that day.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Upon selecting a date, the card display will populate with the delivery records for that
          day.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>The different states are color coded:</Text>
        <Text style={{ whiteSpace: 'nowrap' }} ml={25}>
          Pending = white, blue outline
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }} ml={25}>
          Undelivered = red, solid fill
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }} ml={25}>
          Delivered = blue, solid fill
        </Text>
      </HelpBox>
      <HelpBox title="Viewing a Delivery Record">
        <Text style={{ whiteSpace: 'nowrap' }}>
          To view a delivery record, you must first select the relevant date on the calendar.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Once you have selected the date and the card display has populated, find the card for the
          record you wish to view and click the "expand" icon.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          This will open a screen displaying all the information relevant to the delivery at its
          respective stage.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Clicking the "i" button next to the title of this screen will display the last person to
          edit this record, and when it was edited.
        </Text>
      </HelpBox>
      <HelpBox title="Delivering an Order">
        <Text style={{ whiteSpace: 'nowrap' }}>
          To deliver a pending delivery, open a pending delivery record to view it.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          You then need to click the "Delivered" button. This will open a form requiring details of
          the delivery.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          If the order you are delivering has any tracked equipment assigned to it, you will need to
          select available tracked equipment of the given types to complete the delivery.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Fill out each of the fields as required and click "Confirm". This will mark the pending
          order as delivered, and move the appropriate equipment to the patient being delivered to.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          The card will update, changing from "Pending" to "Delivered", and being resorted in the
          card display accordingly.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          If you've missed any required fields you should recieve a prompt to include them. After
          filling them in, try clicking "Confirm" again.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          If any required tracked equipment was marked as undelivered, but the order was still
          completed, the card will instead by changes to "Incomplete".
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          "Incomplete" deliveries can be rescheduled, applying the missing items to a new order.
        </Text>
      </HelpBox>
      <HelpBox title="Marking an Order as Undelivered">
        <Text style={{ whiteSpace: 'nowrap' }}>
          To mark a pending delivery as undelivered, open a pending delivery record to view it.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          You then need to click the "Undelivered" button. This will open a form requiring notes for
          why you are marking this as undelivered.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Click the "Confirm" button when you are satisfied with your description. This will mark
          the pending order as undelivered.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          The ard will update, changing from "Pending" to "Undelivered", and will be resorted in the
          card display accordingly.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          If you've missed any required fields you should recieve a prompt to include them. After
          filling them in, try clicking "Confirm" again.
        </Text>
      </HelpBox>
      <HelpBox title="Converting from Undelivered to Delivered">
        <Text style={{ whiteSpace: 'nowrap' }}>
          To convert an undelivered delivery into a completed deliver, open an undelivered delivery
          record to view it.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          You then need to click the "Delivered" button. This will open a form requiring details of
          the delivery.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          If the order you are delivering has any equipment assigned to it with tracked serial
          numbers, you will need to enter those serial numbers to complete the delivery.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Fill out each of the fields as required and click "Confirm". This will mark the
          undelivered order as delivered, and move the appropriate equipment to the patient being
          delivered to.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          The card will update, changing from "Undelivered" to "Delivered", and being resorted in
          the card display accordingly.
        </Text>
        <Text style={{ whiteSpace: 'nowrap' }}>
          If you've missed any required fields you should recieve a prompt to include them. After
          filling them in, try clicking "Confirm" again.
        </Text>
      </HelpBox>
      <HelpBox title={`Deliveries cannot be \"Undelivered\"`}>
        <Text style={{ whiteSpace: 'nowrap' }}>
          Important to note is that a completed delivery cannot be "Undelivered", so take care when
          finalizing a completed delivery.
        </Text>
      </HelpBox>
    </Stack>
  );
};

export default DeliveryScheduleHelpSubPage;
