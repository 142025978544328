import React from 'react';
import { useQuery } from 'react-query';
import externalApi from '../../api';
import { IEquipment } from '../../models';

function fetchAllEquipment(): Promise<IEquipment[]> {
  return externalApi.get(`Equipment/all`).json();
}

export function useAllEquipment() {
  return useQuery(['AllEquipment'], () => fetchAllEquipment());
}
