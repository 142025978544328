import {
  Box,
  Button,
  Center,
  Checkbox,
  Chip,
  createStyles,
  Group,
  Modal,
  ScrollArea,
  Stack,
  Table,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { CalendarEvent, CalendarTime, InfoCircle } from 'tabler-icons-react';
import {
  useOrder,
  useRescheduledStandingOrders,
  useStandingOrderCancellations,
} from '../../../hooks';
import { IOrder, IStandingOrder, IStandingOrderCancellation } from '../../../models';
import { PatientNotes, InfoButton, InfiniteScrollArea } from '../../../components';

const useStyles = createStyles((theme, _params) => ({
  notesCard: {
    border: `1px solid ${theme.colors.gray[4]}`,
    borderRadius: theme.radius.sm,
    paddingLeft: theme.spacing.sm,
    paddingRight: theme.spacing.sm,
    paddingTop: 3,
    width: '100%',
    height: '125px',
  },
  standingOrderBox: {
    color: `${theme.colors.gray[6]}`,
    border: `1px solid ${theme.colors.gray[6]}`,
    borderRadius: theme.radius.lg,
    width: '35%',
  },
  tableTitle: {
    borderTop: `1px solid ${theme.colors.gray[4]}`,
    borderBottom: `1px solid ${theme.colors.gray[4]}`,
    backgroundColor: `${theme.colors.gray[1]}`,
  },
  label: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
  },
  detail: {
    fontSize: theme.fontSizes.md,
    paddingLeft: 10,
  },
  fieldGroup: {
    width: '48%',
  },
  clickableIcon: {
    color: `${theme.colors.blue[6]}`,
    padding: '0 !important',
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

type StandingOrderModificationsModalProps = {
  standingOrder: IStandingOrder;
  flashMessage: string;
  isError: boolean;
  opened: boolean;
  onClose: () => void;
  onReschedule: () => void;
  onEditReschedule: (order: IOrder) => void;
  onCancellation: () => void;
  onEditCancellation: (standingOrderCancellationId: string) => void;
};

const StandingOrderModificationsModal = (props: StandingOrderModificationsModalProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const [isError, setIsError] = useState<boolean>(props.isError);
  const [flashMessage, setFlashMessage] = useState<string>(props.flashMessage);

  const [rescheduledFilterValues, setRescheduledFilterValues] = useState<string[]>(['unfulfilled']);
  const [cancellationFilterValues, setCancellationFilterValues] = useState<string[]>([
    'current',
    'future',
  ]);

  const rescheduledOrders = useRescheduledStandingOrders(
    props.standingOrder.standingOrderId !== '' ? props.standingOrder.standingOrderId : '0'
  );
  const cancelledOrders = useStandingOrderCancellations(
    props.standingOrder.standingOrderId !== '' ? props.standingOrder.standingOrderId : '0'
  );

  const order = useOrder();

  const rescheduledOrderRows = (rescheduledOrders.data ?? []).map((a: IOrder, i) => {
    let newRow = (
      <tr key={a.orderId} style={{ cursor: 'pointer' }}>
        <td
          style={{ width: '25%', wordWrap: 'break-word', textAlign: 'center' }}
          onClick={() => {
            if (!a.fulfilled) order.mutate(a.orderId);
          }}
        >{`${dayjs(a.requestedDeliveryDate).format('YYYY-MM-DD')}`}</td>
        <td
          style={{ width: '25%', wordWrap: 'break-word', textAlign: 'center' }}
          onClick={() => {
            if (!a.fulfilled) order.mutate(a.orderId);
          }}
        >{`${a.deliveryTimeName}`}</td>
        <td
          style={{ width: '25%', wordWrap: 'break-word' }}
          onClick={() => {
            if (!a.fulfilled) order.mutate(a.orderId);
          }}
        >
          <Center>
            <Checkbox checked={a.liquid} readOnly />
          </Center>
        </td>
        <td style={{ width: '15%', wordWrap: 'break-word', textAlign: 'center' }}>
          <InfoButton userId={a.lastUpdatedUserId} date={a.lastUpdatedDateTime} />
        </td>
      </tr>
    );

    if (rescheduledFilterValues.find((a) => a === 'fulfilled')) {
      if (a.fulfilled) {
        return newRow;
      }
    }
    if (rescheduledFilterValues.find((a) => a === 'unfulfilled')) {
      if (!a.fulfilled) {
        return newRow;
      }
    }
    if (rescheduledFilterValues.length === 0) {
      return newRow;
    }
  });

  const cancelledOrderRows = (cancelledOrders.data ?? []).map((a: IStandingOrderCancellation) => {
    let newRow = (
      <tr key={a.standingOrderCancellationId} style={{ cursor: 'pointer' }}>
        <td
          style={{ width: '30%', wordWrap: 'break-word', textAlign: 'center' }}
          onClick={() => {
            props.onEditCancellation(a.standingOrderCancellationId);
          }}
        >{`${dayjs(a.startDate).format('YYYY-MM-DD')}`}</td>
        <td
          style={{ width: '30%', wordWrap: 'break-word', textAlign: 'center' }}
          onClick={() => {
            props.onEditCancellation(a.standingOrderCancellationId);
          }}
        >{`${dayjs(a.endDate).format('YYYY-MM-DD')}`}</td>
        <td style={{ width: '15%', wordWrap: 'break-word', textAlign: 'center' }}>
          <InfoButton userId={a.lastUpdatedUserId} date={a.lastUpdatedDateTime} />
        </td>
      </tr>
    );

    let now = new Date();
    let today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    let startDate = new Date(a.startDate);
    let endDate = new Date(a.endDate);

    if (cancellationFilterValues.find((a) => a === 'past')) {
      if (endDate.valueOf() < today.valueOf()) {
        return newRow;
      }
    }
    if (cancellationFilterValues.find((a) => a === 'current')) {
      if (startDate.valueOf() <= today.valueOf() && endDate.valueOf() >= today.valueOf()) {
        return newRow;
      }
    }
    if (cancellationFilterValues.find((a) => a === 'future')) {
      if (startDate.valueOf() > today.valueOf()) {
        return newRow;
      }
    }
    if (cancellationFilterValues.length === 0) {
      return newRow;
    }
  });

  useEffect(() => {
    rescheduledOrders.refetch();
    cancelledOrders.refetch();
    setIsError(props.isError);
    setFlashMessage(props.flashMessage);
  }, [props.standingOrder, props.opened]);

  useEffect(() => {
    if (order.isSuccess) {
      props.onEditReschedule(order.data);
    }
    if (order.isError) {
      setIsError(true);
      setFlashMessage('Something went wrong, Order not retrieved. Please try again');
    }
  }, [order.status]);

  return (
    <Modal
      title={<Text style={{ color: `${isError ? 'red' : 'green'}` }}>{flashMessage}</Text>}
      opened={props.opened}
      size={isSmallMobile ? 'xs' : 'md'}
      onClose={() => {
        setIsError(false);
        setFlashMessage('');
        props.onClose();
      }}
    >
      <Box>
        <Center>
          <Text size="xl" weight={500} align="center">
            Standing Order Schedule Changes
          </Text>
        </Center>

        <Stack align="stretch" style={{ padding: 5 }}>
          <Group>
            <Stack spacing={0} style={{ width: '50%' }}>
              <Text className={classes.label}>Patient Name</Text>
              <Group spacing={5} noWrap>
                <Text
                  className={classes.detail}
                >{`${props.standingOrder.patientLastName}, ${props.standingOrder.patientFirstName}`}</Text>
              </Group>
            </Stack>
            <Stack spacing={0}>
              <Text className={classes.label}>Phone number</Text>
              <Text className={classes.detail}>{`${props.standingOrder.patientPhoneNumber}`}</Text>
            </Stack>
          </Group>
          <Group>
            <Stack spacing={0} style={{ width: '50%' }}>
              <Text className={classes.label}>Delivery Address</Text>
              <Text className={classes.detail}>{`${props.standingOrder.patientAddress}`}</Text>
            </Stack>
            <Stack spacing={0}>
              <Text className={classes.label}>City</Text>
              <Text className={classes.detail}>{`${props.standingOrder.patientCity}`}</Text>
            </Stack>
          </Group>
          <PatientNotes notes={props.standingOrder.patientNotes} />

          <Group>
            <Stack spacing={0} style={{ width: '50%' }}>
              <Text className={classes.label}>Delivery Weekday</Text>
              <Text className={classes.detail}>{`${props.standingOrder.weekdayName}`}</Text>
            </Stack>
            <Stack spacing={0}>
              <Text className={classes.label}>Delivery Time</Text>
              <Text className={classes.detail}>{`${
                props.standingOrder.isAMDelivery ? 'AM' : 'PM'
              }`}</Text>
            </Stack>
          </Group>
        </Stack>

        <Box
          style={{ border: `1px solid ${theme.colors.gray[4]}`, borderRadius: theme.radius.md }}
          mt={10}
        >
          <Center mt={5}>
            <Group position="apart" spacing={5}>
              {!isSmallMobile && <Text>Filters: </Text>}
              <Chip.Group
                multiple
                value={rescheduledFilterValues}
                onChange={setRescheduledFilterValues}
              >
                <Chip variant="filled" value="fulfilled">
                  Fulfilled
                </Chip>
                <Chip variant="filled" value="unfulfilled">
                  Unfulfilled
                </Chip>
              </Chip.Group>
            </Group>
          </Center>
          <Center className={classes.tableTitle} mt={5}>
            <Text weight={200} size="md" align="center">
              Rescheduled Orders
            </Text>
          </Center>
          <Table style={{ width: '100%', tableLayout: 'fixed' }}>
            <thead>
              <tr>
                <th style={{ width: '25%', textAlign: 'center' }}>
                  <Text weight={500} size="sm" color={'#000000'}>
                    Requested Delivery Date
                  </Text>
                </th>
                <th style={{ width: '25%', textAlign: 'center' }}>
                  <Text weight={500} size="sm" color={'#000000'}>
                    Delivery Time
                  </Text>
                </th>
                <th style={{ width: '25%', textAlign: 'center' }}>
                  <Text weight={500} size="sm" color={'#000000'}>
                    Liquid
                  </Text>
                </th>
                <th style={{ width: '15%', textAlign: 'center' }}>
                  <InfoCircle />
                </th>
              </tr>
            </thead>
          </Table>
          <InfiniteScrollArea rows={rescheduledOrderRows} height={125} />
        </Box>

        <Box
          style={{ border: `1px solid ${theme.colors.gray[4]}`, borderRadius: theme.radius.md }}
          mt={15}
        >
          <Center mt={5}>
            <Group position="apart" spacing={5}>
              {!isSmallMobile && <Text>Filters: </Text>}
              <Chip.Group
                multiple
                value={cancellationFilterValues}
                onChange={setCancellationFilterValues}
              >
                <Chip variant="filled" value="past">
                  Past
                </Chip>
                <Chip variant="filled" value="current">
                  Current
                </Chip>
                <Chip variant="filled" value="future">
                  Future
                </Chip>
              </Chip.Group>
            </Group>
          </Center>
          <Center className={classes.tableTitle} mt={5}>
            <Text weight={200} size="md" align="center">
              Order Suspensions
            </Text>
          </Center>
          <Table style={{ width: '100%', tableLayout: 'fixed' }}>
            <thead>
              <tr>
                <th style={{ width: '30%', textAlign: 'center' }}>
                  <Text weight={500} size="sm" color={'#000000'}>
                    Start Date
                  </Text>
                </th>
                <th style={{ width: '30%', textAlign: 'center' }}>
                  <Text weight={500} size="sm" color={'#000000'}>
                    End Date
                  </Text>
                </th>
                <th style={{ width: '15%', textAlign: 'center' }}>
                  <InfoCircle />
                </th>
              </tr>
            </thead>
          </Table>
          <InfiniteScrollArea rows={cancelledOrderRows} height={125} />
        </Box>

        <Group position="apart" grow noWrap mt={15}>
          <Button
            variant="outline"
            onClick={() => {
              props.onReschedule();
            }}
            px={10}
          >
            <CalendarEvent style={{ marginRight: 5 }} />{' '}
            <Text className={classes.buttonText}>{`Reschedule ${
              !isSmallMobile ? 'Order' : ''
            }`}</Text>
          </Button>
          <Button
            variant="outline"
            onClick={() => {
              props.onCancellation();
            }}
          >
            <CalendarTime style={{ marginRight: 5 }} />{' '}
            <Text className={classes.buttonText}>{`Suspend ${!isSmallMobile ? 'Order' : ''}`}</Text>
          </Button>
        </Group>
      </Box>
    </Modal>
  );
};

export default StandingOrderModificationsModal;
