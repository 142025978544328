import {
  ActionIcon,
  Box,
  Button,
  Center,
  Checkbox,
  createStyles,
  Group,
  Menu,
  Modal,
  SegmentedControl,
  Select,
  Stack,
  Text,
  Textarea,
  useMantineTheme,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { useEffect, useState } from 'react';
import { Dots, Pencil, Trash, X } from 'tabler-icons-react';
import { useMediaQuery } from '@mantine/hooks';
import { IStandingOrder } from '../../../models';
import {
  useDeleteStandingOrder,
  useEditStandingOrder,
  useUserId,
  useWeekdays,
} from '../../../hooks';
import { useModals } from '@mantine/modals';
import { DialogModal, PatientNotes } from '../../../components';

const useStyles = createStyles((theme) => ({
  label: {
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
    fontWeight: 500,
  },
  fieldGroup: {
    width: '48%',
  },
  detailLabel: {
    color: theme.colors.gray[5],
    fontSize: theme.fontSizes.xs,
    whiteSpace: 'pre',
  },
  detail: {
    fontSize: theme.fontSizes.md,
    paddingLeft: 10,
  },
  buttonText: {
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      fontSize: 12,
    },
  },
}));

type StandingOrderEditModalProps = {
  standingOrder: IStandingOrder;
  opened: boolean;
  onClose: () => void;
  onEdited: (isUpdated: boolean, isError: boolean, initialStandingOrder: IStandingOrder) => void;
  onDeleted: () => void;
};

const StandingOrderEditModal = (props: StandingOrderEditModalProps) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const modals = useModals();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const currentUserId = useUserId();

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [isAMDelivery, setIsAMDelivery] = useState<boolean>(props.standingOrder.isAMDelivery);
  const initialStandingOrder = {
    standingOrderId: props.standingOrder.standingOrderId,
    patientId: props.standingOrder.patientId,
    patientFirstName: props.standingOrder.patientFirstName,
    patientLastName: props.standingOrder.patientLastName,
    patientFullName: `${props.standingOrder.patientFirstName} ${props.standingOrder.patientLastName}`,
    patientFullNameFormatted: `${props.standingOrder.patientLastName}, ${props.standingOrder.patientFirstName}`,
    patientPhoneNumber: props.standingOrder.patientPhoneNumber,
    patientAddress: props.standingOrder.patientAddress,
    patientCity: props.standingOrder.patientCity,
    patientNotes: props.standingOrder.patientNotes,
    isAMDelivery: props.standingOrder.isAMDelivery,
    deliveryTimeName: props.standingOrder.deliveryTimeName,
    weekdayId: props.standingOrder.weekdayId,
    weekdayName: props.standingOrder.weekdayName,
    liquid: props.standingOrder.liquid,
    notes: props.standingOrder.notes,
    lastUpdatedUserId: props.standingOrder.lastUpdatedUserId,
    lastUpdated: props.standingOrder.lastUpdated,
  };

  const [submitClicked, setSubmitClicked] = useState<boolean>(false);

  const weekdays = useWeekdays();
  const editStandingOrder = useEditStandingOrder();

  const deleteStandingOrder = useDeleteStandingOrder();

  const handleDelete = () => {
    deleteStandingOrder.mutate({ standingOrderId: props.standingOrder.standingOrderId });
  };

  const editForm = useForm<IStandingOrder>({
    initialValues: initialStandingOrder,
    validate: {},
  });

  async function submitStandingOrderUpdate() {
    setSubmitClicked(true);

    editStandingOrder.mutate({
      standingOrderId: editForm.values.standingOrderId,
      weekdayId: editForm.values.weekdayId,
      isAMDelivery: isAMDelivery,
      liquid: editForm.values.liquid,
      notes: editForm.values.notes,
      lastUpdatedUserId: currentUserId.data!.userId,
    });
  }

  useEffect(() => {
    editForm.setValues(props.standingOrder);
  }, [props.standingOrder, props.opened]);

  useEffect(() => {
    if (submitClicked) {
      if (editStandingOrder.isSuccess) {
        setSubmitClicked(false);
        setIsError(false);
        setErrorMessage('');
        props.onEdited(true, false, {
          standingOrderId: props.standingOrder.standingOrderId,
          patientId: props.standingOrder.patient,
          patientFirstName: props.standingOrder.patientFirstName,
          patientLastName: props.standingOrder.patientLastName,
          patientFullName: `${props.standingOrder.patientFirstName} ${props.standingOrder.patientLastName}`,
          patientFullNameFormatted: `${props.standingOrder.patientLastName}, ${props.standingOrder.patientFirstName}`,
          patientPhoneNumber: props.standingOrder.patientPhoneNumber,
          patientAddress: props.standingOrder.patientAddress,
          patientCity: props.standingOrder.patientCity,
          patientNotes: props.standingOrder.patientNotes,
          isAMDelivery: isAMDelivery,
          deliveryTimeName: isAMDelivery ? 'AM' : 'PM',
          weekdayId: editForm.values.weekdayId,
          weekdayName: weekdays.data!.find(
            (a) => a.weekdayId.toString() === editForm.values.weekdayId.toString()
          )!.name,
          liquid: editForm.values.liquid,
          notes: editForm.values.notes,
          lastUpdatedUserId: currentUserId.data!.userId,
          lastUpdated: new Date(),
        });
      } else if (editStandingOrder.isError) {
        setSubmitClicked(false);
        setIsError(false);
        setErrorMessage('');
        props.onEdited(false, true, initialStandingOrder);
      }
    }
  }, [editStandingOrder.status]);

  useEffect(() => {
    if (deleteStandingOrder.isSuccess) {
      setIsError(false);
      setErrorMessage('');
      props.onDeleted();
    }

    if (deleteStandingOrder.isError) {
      setIsError(true);
      setErrorMessage('Something went wrong, Standing Order not Deleted. Please try again');
    }
  }, [deleteStandingOrder.status]);

  return (
    <Modal
      title={isError ? <Text color="red">{errorMessage}</Text> : ''}
      opened={props.opened}
      size={isSmallMobile ? 'xs' : 'md'}
      onClose={() => {
        setIsError(false);
        setErrorMessage('');
        props.onClose();
      }}
    >
      <Box>
        <Center>
          <Group noWrap spacing={!isSmallMobile ? 'md' : 0} ml={!isSmallMobile ? 25 : 0}>
            <Text size="xl" weight={500} align="center">
              Edit Standing Order Information
            </Text>
            <Menu>
              <Menu.Target>
                <ActionIcon>
                  <Dots />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item
                  icon={<Trash color={`${theme.colors.red[6]}`} />}
                  onClick={() => {
                    setDeleteModalOpen(true);
                  }}
                >
                  Delete Standing Order
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>
        </Center>
        <form onSubmit={editForm.onSubmit(() => submitStandingOrderUpdate())} autoComplete="off">
          <Stack align="stretch" style={{ padding: 5 }}>
            <Group>
              <Stack spacing={0} style={{ width: '50%' }}>
                <Text className={classes.label}>Patient Name</Text>
                <Group spacing={5} noWrap>
                  <Text
                    className={classes.detail}
                  >{`${props.standingOrder.patientLastName}, ${props.standingOrder.patientFirstName}`}</Text>
                </Group>
              </Stack>
              <Stack spacing={0}>
                <Text className={classes.label}>Phone number</Text>
                <Text
                  className={classes.detail}
                >{`${props.standingOrder.patientPhoneNumber}`}</Text>
              </Stack>
            </Group>
            <Group>
              <Stack spacing={0} style={{ width: '50%' }}>
                <Text className={classes.detailLabel}>Delivery Address</Text>
                <Text className={classes.detail}>{`${props.standingOrder.patientAddress}`}</Text>
              </Stack>
              <Stack spacing={0}>
                <Text className={classes.detailLabel}>City</Text>
                <Text className={classes.detail}>{`${props.standingOrder.patientCity}`}</Text>
              </Stack>
            </Group>
            <PatientNotes notes={props.standingOrder.patientNotes} />

            <Group noWrap>
              <Stack spacing={0} className={classes.fieldGroup}>
                <Text className={classes.label}>Delivery Weekday</Text>
                <Select
                  placeholder="Select a Weekday"
                  data={
                    weekdays.data?.map((a) => ({
                      value: `${a.weekdayId}`,
                      label: `${a.name}`,
                    })) ?? []
                  }
                  defaultValue={props.standingOrder.weekdayId.toString()}
                  searchable={!isSmallMobile}
                  required
                  {...editForm.getInputProps('weekdayId')}
                />
              </Stack>
              <Stack spacing={0} className={classes.fieldGroup} ml={15}>
                <Text className={classes.label}>Delivery Time</Text>
                <SegmentedControl
                  color="blue"
                  data={[
                    { label: 'AM', value: 'AM' },
                    { label: 'PM', value: 'PM' },
                  ]}
                  defaultValue={props.standingOrder.isAMDelivery ? 'AM' : 'PM'}
                  ml={10}
                  onChange={(e) => {
                    e === 'AM' ? setIsAMDelivery(true) : setIsAMDelivery(false);
                  }}
                />
              </Stack>
            </Group>

            <Group position="left" spacing="xs" pl="xs">
              <Checkbox
                defaultChecked={props.standingOrder.liquid}
                {...editForm.getInputProps('liquid')}
              />
              <Text className={classes.label}>Liquid</Text>
            </Group>

            <Stack align="stretch" spacing={0}>
              <Text className={classes.label}>Notes</Text>
              <Textarea minRows={4} {...editForm.getInputProps('notes')} />
            </Stack>

            <Group position="apart" grow noWrap mt="xs">
              <Button variant="outline" type="submit">
                <Pencil style={{ marginRight: 5 }} />{' '}
                <Text className={classes.buttonText}>{`Update ${
                  !isSmallMobile ? 'Order' : ''
                }`}</Text>
              </Button>
              <Button
                variant="outline"
                color="red"
                onClick={() => {
                  setIsError(false);
                  setErrorMessage('');
                  props.onClose();
                }}
              >
                <X style={{ marginRight: 5 }} /> <Text className={classes.buttonText}>Cancel</Text>
              </Button>
            </Group>
          </Stack>
        </form>
      </Box>
      <DialogModal
        isOpen={deleteModalOpen}
        isNegative
        titleText="Delete Standing Order?"
        body={
          <Text align="center" color="dimmed" size="lg" mb="xs">
            Are you sure you want to delete this Standing Order?
          </Text>
        }
        confirmText="Delete Standing Order"
        onConfirm={() => {
          setDeleteModalOpen(false);
          handleDelete();
        }}
        onClose={() => {
          setDeleteModalOpen(false);
        }}
      />
    </Modal>
  );
};

export default StandingOrderEditModal;
