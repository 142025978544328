import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import externalApi from '../../api';

type DeliveriesCountByDateRangeRequest = {
  startDate: Date;
  endDate: Date;
};

type DeliveriesCountByDateRangeResponse = {
  currentDate: Date;
  pendingCount: number;
  deliveredCount: number;
  undeliveredCount: number;
  incompleteCount: number;
  anyUrgent: boolean;
};

function deliveriesCountByDate(
  request: DeliveriesCountByDateRangeRequest
): Promise<DeliveriesCountByDateRangeResponse[]> {
  return externalApi.post(`Delivery/CountByDateRange`, { json: request }).json();
}

function useDeliveriesCountByDateRange(request: DeliveriesCountByDateRangeRequest) {
  return useQuery(['DeliveriesCountByDateRange'], () => deliveriesCountByDate(request));
}
export { useDeliveriesCountByDateRange };
