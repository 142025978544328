import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import externalApi from '../../api';
import { IOrder } from '../../models';

function getOrder(orderId: string): Promise<IOrder> {
  return externalApi.get(`Order/${orderId}`).json();
}

function useOrder() {
  const queryClient = useQueryClient();
  return useMutation((orderId: string) => getOrder(orderId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['Order']);
    },
  });
}

export { useOrder };
