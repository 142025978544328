import { Text, createStyles, Group, useMantineTheme, Box, ScrollArea, Center } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React, { useEffect, useRef, useState } from 'react';
import {
  BuildingWarehouse,
  CalendarEvent,
  ClipboardList,
  FileAnalytics,
  Users,
} from 'tabler-icons-react';
import { PageHeader } from '../common/Page/Header/PageHeader';
import { Page } from '../common/Page/Page';
import {
  DeliveryScheduleHelpSubPage,
  EquipmentHelpSubPage,
  HelpBox,
  OrdersHelpSubPage,
  PageButton,
  PatientsHelpSubPage,
  ReportsHelpSubPage,
} from '../components';
import { usePageStore } from '../hooks';

const useStyles = createStyles((theme) => ({}));

type Props = {};

const HelpPage = (props: Props) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const isSmallMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const pageRef = useRef<HTMLDivElement | null>(null);
  const [pageWidth, setPageWidth] = useState<number>(0);

  const selectedSubPage = usePageStore((state) => state.helpSubPage);
  const setHelpSubPage = usePageStore((state) => state.setHelpSubPage);

  useEffect(() => {
    if (pageRef.current) setPageWidth(pageRef.current.clientWidth);
  }, [pageRef.current]);

  useEffect(() => {
    function handleResize() {
      if (pageRef.current) setPageWidth(pageRef.current.clientWidth);
    }
    window.addEventListener('resize', handleResize);
  });

  return (
    <Page
      header={
        <PageHeader title="Help" subtitle="How to Use the Application" stickyContent={null} />
      }
    >
      <Box ref={pageRef} style={{ width: '100%' }}>
        <ScrollArea
          py={10}
          style={{
            width: pageWidth,
            border: `1px solid ${theme.colors.gray[6]}`,
            borderRadius: theme.radius.sm,
          }}
        >
          <Group grow noWrap p={5}>
            <Box ml={15}>
              <PageButton
                icon={Users}
                prompt="Patients"
                clicked={selectedSubPage === 'patients'}
                onClick={() => {
                  setHelpSubPage(selectedSubPage === 'patients' ? undefined : 'patients');
                }}
              />
            </Box>
            <PageButton
              icon={ClipboardList}
              prompt="Orders"
              clicked={selectedSubPage === 'orders'}
              onClick={() => {
                setHelpSubPage(selectedSubPage === 'orders' ? undefined : 'orders');
              }}
            />
            <PageButton
              icon={BuildingWarehouse}
              prompt="Equipment"
              clicked={selectedSubPage === 'equipment'}
              onClick={() => {
                setHelpSubPage(selectedSubPage === 'equipment' ? undefined : 'equipment');
              }}
            />
            <PageButton
              icon={CalendarEvent}
              prompt={`Delivery\nSchedule`}
              clicked={selectedSubPage === 'deliverySchedule'}
              onClick={() => {
                setHelpSubPage(
                  selectedSubPage === 'deliverySchedule' ? undefined : 'deliverySchedule'
                );
              }}
            />
            <Box mr={15}>
              <PageButton
                icon={FileAnalytics}
                prompt="Reports"
                clicked={selectedSubPage === 'reports'}
                onClick={() => {
                  setHelpSubPage(selectedSubPage === 'reports' ? undefined : 'reports');
                }}
              />
            </Box>
          </Group>
        </ScrollArea>
        {selectedSubPage === 'patients' && <PatientsHelpSubPage />}
        {selectedSubPage === 'orders' && <OrdersHelpSubPage />}
        {selectedSubPage === 'equipment' && <EquipmentHelpSubPage />}
        {selectedSubPage === 'deliverySchedule' && <DeliveryScheduleHelpSubPage />}
        {selectedSubPage === 'reports' && <ReportsHelpSubPage />}
        {!selectedSubPage && (
          <Center mt={25}>
            <Text size="xl" weight={500} color="dimmed">
              Select a Category for Help
            </Text>
          </Center>
        )}
      </Box>
    </Page>
  );
};

export default HelpPage;
