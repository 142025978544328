import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import externalApi from '../../api';
import { IDelivery } from '../../models';

type DeliveredByDateRequest = {
  date: Date;
};

function deliveredByDate(request: DeliveredByDateRequest): Promise<IDelivery[]> {
  return externalApi.post(`Delivery/ByDate/Delivered`, { json: request }).json();
}

function useDeliveredByDate(request: DeliveredByDateRequest) {
  return useQuery(['DeliveredByDate'], () => deliveredByDate(request));
}
export { useDeliveredByDate };
